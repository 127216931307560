<div class="dialog-container">
  <h2 mat-dialog-title>{{data.title}}</h2>
  <mat-dialog-content
    [innerHTML]="data.message">
  </mat-dialog-content>
  <mat-dialog-actions>
    <button
      mat-raised-button
      class="alert-button primary"
      color="primary"
      (click)="close($event)"
      autofocus>OK
    </button>
  </mat-dialog-actions>
</div>
