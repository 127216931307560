import { Component, Input, Output, EventEmitter, OnChanges, ElementRef, OnInit } from '@angular/core';
import { MatrixDataModel } from './matrix.model';
import { Matrix } from './matrix';

@Component({
  selector: 'pl-matrix',
  templateUrl: './matrix.component.html',
  styleUrls: ['./matrix.component.scss']
})
export class MatrixComponent implements OnInit, OnChanges {
  @Input() data: MatrixDataModel;
  @Input() colHeader: string;
  @Input() colShowAddBtn = true;
  @Input() colPosition = 'position';
  @Input() rowHeader: string;
  @Input() rowShowAddBtn = true;
  @Input() rowPosition = 'position';
  @Input() height: number;
  @Input() width: number;
  @Input() relationPosition: 'col' | 'row' = 'col'; // to know col or row is main
  @Input() selectType: 'multi' | 'single' = 'multi'; // for many-to-many or one-to-many relation;
  @Input() event: EventEmitter<any> = new EventEmitter();

  @Output() colTitleClick: EventEmitter<any> = new EventEmitter();
  @Output() colAddClick: EventEmitter<any> = new EventEmitter();
  @Output() rowTitleClick: EventEmitter<any> = new EventEmitter();
  @Output() rowAddClick: EventEmitter<any> = new EventEmitter();
  @Output() cellClick: EventEmitter<any> = new EventEmitter();

  private renderer: Matrix;

  constructor(public el: ElementRef) {}

  public ngOnInit() {
    this.event.subscribe((event: { type: string; data: any }) => {
      switch (event.type) {
        case 'renderCol':
          {
            this.renderer.renderCol(event.data);
          }
          break;

        case 'renderRow':
          {
            this.renderer.renderRow(event.data);
          }
          break;

        case 'deleteCol':
          {
            this.renderer.deleteCol(event.data);
          }
          break;

        case 'deleteRow':
          {
            this.renderer.deleteRow(event.data);
          }
          break;
      }
    });
  }

  public ngOnChanges() {
    if (this.data) {
      this.renderer = new Matrix(this);
    }
  }
}
