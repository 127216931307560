export class MatrixGroupDataModel {

  constructor(public data: any,
              public type: string,
              public title: string,
              public key: string,
              public color: string = null,
              public relation: string = null) {
    this.data = data;
    this.type = type;
    this.title = title;
    this.key = key;
    this.color = color;
    this.relation = relation;
  }
}
