import { Apollo } from 'apollo-angular';
import { ApolloQueryResult } from '@apollo/client/core';
import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';

import { CompanyModel, MedicalSetting } from '@models';

import {
  createCompany,
  deleteCompany,
  loadCompanies,
  loadCompany,
  updateCompany,
  createMedicalSetting,
  updateMedicalSetting
} from '@shared/queries/company.queries';
import { IBaseCrudService } from '@interfaces';

@Injectable({
  providedIn: 'root'
})
export class CompanyService implements IBaseCrudService<CompanyModel> {
  private data: CompanyModel[];

  constructor(private apollo: Apollo) { }

  public load() {
    return this.apollo.query<any>({ query: loadCompanies }).pipe(
      map(response => {
        return response.data.companies.map(element => new CompanyModel(element));
      }),
      tap(mapped => {
        this.data = mapped;
      })
    );
  }

  public loadCompany(id: number) {
    return this.apollo
      .query<any>({
        query: loadCompany,
        variables: { id }
      })
      .pipe(map(response => new CompanyModel(response.data.company)));
  }

  public createMedicalSetting(data: MedicalSetting) {
    return this.apollo.mutate({
      mutation: createMedicalSetting,
      variables: {
        interval: data.interval,
        emails: data.emails,
        company: data.company
      }
    });
  }

  public updateMedicalSetting(data: MedicalSetting) {
    return this.apollo
      .mutate({
        mutation: updateMedicalSetting,
        variables: {
          id: data.id,
          interval: data.interval,
          emails: data.emails,
          company: data.company
        }
      })
      .subscribe((response: ApolloQueryResult<any>) => response.data.updateMedicalSetting);
  }

  public create(data: CompanyModel) {
    return this.apollo
      .mutate({
        mutation: createCompany,
        variables: {
          name: data.name,
          nameShort: data.nameShort
        }
      })
      .pipe(
        map((response: ApolloQueryResult<any>) => new CompanyModel(response.data.createCompany)),
        tap((model: CompanyModel) => {
          this.data = [...this.data, model];
        })
      );
  }

  public update(data: CompanyModel) {
    return this.apollo
      .mutate({
        mutation: updateCompany,
        variables: {
          id: data.id,
          name: data.name,
          nameShort: data.nameShort
        }
      })
      .pipe(
        map((response: ApolloQueryResult<any>) => new CompanyModel(response.data.updateCompany)),
        tap((model: CompanyModel) => {
          const company = this.data.find(el => el.id === model.id);
          Object.assign(company, model);
        })
      );
  }

  public delete(data: CompanyModel) {
    return this.apollo
      .mutate({
        mutation: deleteCompany,
        variables: {
          id: data.id
        }
      })
      .pipe(
        tap((response: ApolloQueryResult<any>) => {
          const res = response.data.deleteCompany;
          this.data = [...this.data.filter(el => el.id !== res.id)];
        }),
        map(response => response.data.deleteCompany)
      );
  }
}
