import { gql } from 'apollo-angular';

export const statsForSchedule = gql`
  query rostrStatsForSchedule(
    $schedule: Int!
    $categories: [String!]!
    $averageAssignedDateFrom: String
    $averageAssignedDateTo: String
    $employeesIds: [Int!]
  ) {
    rostrStatsForSchedule(
      schedule: $schedule
      categories: $categories
      averageAssignedDateFrom: $averageAssignedDateFrom
      averageAssignedDateTo: $averageAssignedDateTo
      employeesIds: $employeesIds
    )
  }
`;
