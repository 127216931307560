import { IMatrix } from '../matrix.interfaces';
import { ICols } from './cols.interface';
import { Cell } from '../grid/cell';
import { IConfig } from '../utils/interfaces';
import { Draggable } from '../draggable/draggable';

import * as d3 from 'd3';

export class Col {

  get data() {
    const group = this.matrix.data.cols.find((el: any) => el.type === this.configs.type);
    return group.data.find((el: any) => el[group.key] === this.configs.key);
  }

  get key() {
    return this.configs.key;
  }

  get index() {
    return this.configs.index;
  }

  get offset() {
    return this._offset;
  }

  get color() {
    return this.configs.color;
  }

  get type() {
    return this.configs.type;
  }

  get y() {
    return this.configs.index * this.matrix.cellSize + this._offset;
  }

  get offsetY() {
    return this.y + this.matrix.cellSize;
  }

  get overflowTitle() {
    if (this.configs.title && this.configs.title.length > this.matrix.maxTitleSize) {
      return `${this.configs.title.substring(0, 9)}...`;
    }
    return this.configs.title;
  }

  set title(value) {
    this.configs.title = value;
  }

  public $col: d3.Selection<any, any, any, any>;
  public $line: d3.Selection<any, any, any, any>;

  public cells: Cell[] = [];

  public isVisible = false;

  private _offset = 0;

  private draggable: Draggable;

  private hasClick = false;

  public constructor(private matrix: IMatrix,
                     private cols: ICols,
                     private configs: IConfig,
                     private options?: any) {
    this.initCol();
  }

  public render() {
    this.$col.select('*').remove();

    this.drawRect();
    this.drawLine();

    this.cells.forEach((cell: Cell) => {
      cell.updatePosition(this.configs.index, cell.rowIndex);
    });
    this.$col.call(this.dragging());
  }


  public rerender() {
    this.drawRect();
  }

  public drawLine() {
    this.$col.select('line').remove();

    this.$line = this.$col
      .append('line')
      .attr('x1', 0)
      .attr('y1', this.matrix.cellSize)
      .attr('x2', () => -this.matrix.availableRowsCount * this.matrix.cellSize)
      .attr('y2', this.matrix.cellSize)
      .style('stroke-width', 1)
      .style('stroke', 'black');
  }

  public addRelatedCell(cell: Cell) {
    this.cells.push(cell);
  }

  public updatePosition(position: number) {
    this.configs.index = position;

    this.$col
      .attr('class', `matrix-col col-${this.configs.index}`)
      .attr('transform', `translate(0, ${this.y})`);

    this.cells.forEach((cell: Cell) => {
      cell.updatePosition(this.configs.index, cell.rowIndex);
    });

    this.matrix.event.emit({type: 'dragCol', key: this.options.key, index: this.options.index});
  }

  public destroy() {
    this.cols.container.select(`.col-${this.configs.index}`).remove();

    this.cells.forEach((cell: Cell) => cell.destroy());

    // redraw rows lines
    this.matrix.rows.rows.forEach(row => row.drawLine());
  }

  /*
   * Destroy col with translation other cols
   */
  public destroyWithTranslation() {
    // if no next elem -> scroll by one elem
    if (!this.matrix.scrollCol.hasNext) {
      this.matrix.scrollCol.prev();
    }

    this.destroy();

    const i = this.cols.cols.findIndex((el: Col) => el.index === this.configs.index);
    this.cols.cols.splice(i, 1);

    this.cols.cols.forEach((col: Col) => {
      if (col.index > this.configs.index) {
        col.updatePosition(col.index - 1);
      }
    });
  }

  public setVisible(status: boolean) {
    this.isVisible = !!status;
    this.$col.classed('hide', !this.isVisible);

    this.cells.forEach((el: Cell) => {
      el.setVisible();
    });
  }

  public moveBy(offset: number) {
    this._offset += offset;

    this.$col.attr('transform', `translate(0, ${this.y})`);

    this.cells.forEach((el: Cell) => {
      el.moveBy(offset, 0);
    });
  }

  private initCol() {
    this.$col = this.cols.container
      .append('g')
      .attr('key', this.configs.key)
      .attr('class', `matrix-col col-${this.configs.index}`)
      .attr('transform', `translate(0, ${this.y})`);
  }

  private drawRect() {
    this.$col.select('rect').remove();
    this.$col.select('text').remove();

    this.$col
      .append('rect')
      .attr('id', 'main-rect')
      .attr('height', this.matrix.cellSize)
      .attr('width', this.matrix.titleSize);

    this.$col
      .append('text')
      .text(this.overflowTitle)
      .attr('x', 5)
      .attr('y', this.matrix.halfCellSize)
      .attr('alignment-baseline', 'central');

    if (this.configs.color) {
      this.drawColorBox();
    }

  }
  private drawColorBox() {
    this.$col.select('.color-box').remove();

    this.$col
      .append('rect')
      .style('fill', this.configs.color)
      .attr('x', this.matrix.titleSize - this.matrix.cellSize)
      .attr('height', this.matrix.cellSize)
      .attr('width', this.matrix.cellSize)
      .classed('color-box', true);
  }

  //// DRAG ////

  private dragging() {
    return d3.drag()
      .on('start', () => {
        this.hasClick = true;
        this.draggable = this.initDraggable();
        this.draggable.dragStart();
        this.matrix.grid.disableGrid();

        this.$col.attr('opacity', 0.6);
      })
      .on('drag', () => {
        this.hasClick = false;
        this.draggable.drag();
      })
      .on('end', () => {
        if (this.hasClick) {
          this.options.click.emit(this);
        } else {
          const position = this.draggable.guessPosition;
          this.updatePositions(position.index);
        }
        this.matrix.grid.enableGrid();
        this.draggable.dragEnd();

        this.$col.attr('opacity', 1);
      });
  }

  private initDraggable() {
    // set max possible x
    const maxX = (this.matrix.availableColsCount - 1 ) * this.matrix.cellSize;

    const draggable = this.matrix.draggable;
    draggable.setOffsets(this.matrix.offset, this.matrix.offset, - 90);

    // set main clone element
    draggable.content = this.dragRender();

    // set min and max possible coord
    // conversely because rotate (-90)
    draggable.dragArea(0, maxX);

    // conversely because rotate(-90)
    draggable.x = 0;
    draggable.y = this.y;

    // set current element, then draggable could know about class and methods
    draggable.element = this;

    return draggable;
  }

  private dragRender() {
    const clone = d3.select(this.$col.node().cloneNode(true));
    clone.select('line').remove();

    return clone.node();
  }

  private updatePositions(index: number) {
    let items: Col[] = [];

    // if draggable element move to the right
    if (index < this.index) {
      items = this.cols.cols.filter((col: Col) => {
        return col.index > index - 1 && col.index < this.index + 1 && col.index !== this.index;
      });
      items.forEach((item: Col) => { item.updatePosition(item.index + 1); });
    }

    // if draggable element move to the left
    if (index > this.index) {
      items = this.cols.cols.filter((col: Col) => {
        return col.index < index + 1 && col.index > this.index - 1 && col.index !== this.index;
      });
      items.forEach((item: Col) => { item.updatePosition(item.index - 1); });
    }

    // update position for current draggable element
    this.updatePosition(index);
  }

}
