interface IInputOptions {
  label?: string
  placeholder?: string
  changeRequest?: boolean
  clearCustom?: boolean
  loading?: boolean
  clearAfterSelect?: boolean
  showResults?: boolean
  debounce?: number;
  resultKey?: string;
  resultLineHandler?: (result: any) => string;
}

export class AutocompleteOptionsModel {
  public label = '';
  public placeholder = '';
  public changeRequest = false;
  public clearCustom = false;
  public loading = false;
  public clearAfterSelect = false;
  public showResults = true;
  public resultKey = void 0;
  public debounce = 400;

  constructor(private data: IInputOptions = {}) {
    Object.keys(this.data).forEach((key) => {
      if (this.hasOwnProperty(key)) {
        this[key] = data[key];
      }
    });
  }
  public resultLineHandler = (result: any) => {
    return result.name || '';
  }
}
