import { Injectable } from '@angular/core';
import { Observable, merge } from 'rxjs';
import { map } from 'rxjs/operators';
import { VacayScheduleService } from './vacay-schedule.service';
import { CommentsAPIService } from '@component/conversation-panel/services/comments-api.service';
import { CreateCommentPayload, DeleteCommentPayload, UpdateCommentPayload } from '@component/conversation-panel/models';
import { ScheduleKeyFormat, VacayScheduleModel } from '../models/vacay-schedule-model';
import { ScheduleDay } from '@app/vacay/interfaces';
import * as moment from 'moment-mini';

@Injectable({
  providedIn: 'root'
})
export class DayWidgetService {
  constructor(
    private readonly scheduleService: VacayScheduleService,
    private readonly commentsService: CommentsAPIService
  ) {}

  scheduleDay$(scheduleId: number, day: string): Observable<ScheduleDay> {
    return this.schedule$(scheduleId).pipe(map(schedule => schedule?.days.get(moment.utc(day).format(ScheduleKeyFormat))));
  }

  schedule$(scheduleId: number): Observable<VacayScheduleModel> {
    return this.scheduleService.schudules$.pipe(map(schedules => schedules.find(schedule => schedule.id === scheduleId)));
  }

  public createComment(payload: CreateCommentPayload) {
    return this.commentsService.createComment$(payload);
  }

  public updateComment(payload: UpdateCommentPayload) {
    return this.commentsService.updateComment$(payload);
  }

  public deleteComment(payload: DeleteCommentPayload) {
    return this.commentsService.deleteComment$(payload);
  }

  public markCommentsAsRead(ids: number[]) {
    return this.commentsService.markCommentsAsRead$(ids);
  }

  public commentsChanges$ = merge(
    this.commentsService.onCommentDeleted$(),
    this.commentsService.onCommentUpdated$(),
    this.commentsService.onCommentsCreated$()
  );
}
