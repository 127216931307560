import { CUSTOM_PERIOD_MODE, ICustomPeriod, IFormattingFunction, IStatistic } from '..';

export function minutesToHours(value?: number): string {
  if (!value && value !== 0) {
    return '';
  }

  let hours: number;
  let sign = '';

  if (value >= 0) {
    hours = Math.floor(value / 60);
  } else {
    hours = Math.ceil(value / 60);
    sign = '-';
  }
  hours = Math.abs(hours);

  const minutes: number = Math.abs(value % 60);

  return `${sign}${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
}

export function wishesGranted(stat: Partial<IStatistic>): string {
  if (!stat?.count) {
    return '';
  }
  return `${stat.satisfiedCount}/${stat.count}`;
}

export function minutesToHoursStatistic(stat: IStatistic): string {
  return minutesToHours(stat?.duration);
}

export function displayCustomPeriodStat(stat: ICustomPeriod, mode: CUSTOM_PERIOD_MODE): string {
  return stat?.[mode]?.toString() || '';
}

export const customPeriodFormattingFunctions: { [key in CUSTOM_PERIOD_MODE]?: IFormattingFunction } = {
  [CUSTOM_PERIOD_MODE.DaysOff]: (stat: ICustomPeriod) => displayCustomPeriodStat(stat, CUSTOM_PERIOD_MODE.DaysOff),
  [CUSTOM_PERIOD_MODE.DaysWorked]: (stat: ICustomPeriod) => displayCustomPeriodStat(stat, CUSTOM_PERIOD_MODE.DaysWorked),
  [CUSTOM_PERIOD_MODE.Deviation]: (stat: ICustomPeriod) => minutesToHours(stat?.[CUSTOM_PERIOD_MODE.Deviation]),
  [CUSTOM_PERIOD_MODE.Holidays]: (stat: ICustomPeriod) => displayCustomPeriodStat(stat, CUSTOM_PERIOD_MODE.Holidays),
  [CUSTOM_PERIOD_MODE.NightShifts]: (stat: ICustomPeriod) => displayCustomPeriodStat(stat, CUSTOM_PERIOD_MODE.NightShifts),
  [CUSTOM_PERIOD_MODE.PerWeek]: (stat: ICustomPeriod) => minutesToHours(stat?.[CUSTOM_PERIOD_MODE.PerWeek]),
  [CUSTOM_PERIOD_MODE.TotalMinutes]: (stat: ICustomPeriod) => minutesToHours(stat?.[CUSTOM_PERIOD_MODE.TotalMinutes]),
  [CUSTOM_PERIOD_MODE.weekendShifts]: (stat: ICustomPeriod) => displayCustomPeriodStat(stat, CUSTOM_PERIOD_MODE.weekendShifts),
  [CUSTOM_PERIOD_MODE.weekendsWorked]: (stat: ICustomPeriod) => displayCustomPeriodStat(stat, CUSTOM_PERIOD_MODE.weekendsWorked)
};

export function getKeyForIsoYearWeek(year: number | string, isoWeek: number | string): string {
  return `${year}-${isoWeek}`;
}
