import { default as version } from '../../package.json';

export const environment = {
  production: true,
  env: 'prod',
  clientVersion: version.version,
  sentry: {
    dsn: 'https://46788e1aab8c446aaa8eb426104e724d@sentry.io/1517372',
    environment: 'production',
    debug: false,
    release: `frontend@${version.version}`
  },
  piwik: {
    containerId: 'c8f839a0-f6a3-4a7c-bf18-a447e64544d3',
    containerUrl: 'https://avinor.containers.piwik.pro'
  }
};
