import * as d3 from 'd3';
import { merge } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { RandomColor } from '@classes';
import { pathForRoundedRect, translate, xmlns, lower } from '@helpers';

import { BaseInterval } from '../../intervals/base.interval';
import { IBaseTabsHeader } from './base-tabs-header.interface';
import { ROSTR_OVERVIEW_INITIAL_DATE } from '@shared/helpers/local-storage-keys.helper';

export class Tab {

  get index() {
    return this.header.tabs.indexOf(this);
  }

  get isActive() {
    return this.header.activeTab === this;
  }

  get offset() {
    const midIndex = Math.floor(this.header.tabs.length / 2);
    const index = this.index;

    return index - midIndex;
  }

  get properties() {
    return this._properties;
  }

  get title() {
    const interval = this.grid.interval();

    return interval.getTitleForTab(this.dateFrom, this.isActive);
  }

  get backgroundColor() {
    const offset = Math.abs(this.offset);

    if (offset === 0) {
      return '#f0f0f0';
    } else if (offset === 1) {
      return '#dcdee2';
    }

    return RandomColor.shadeBlendConvert(-offset / 30, '#dcdee2');
  }

  get dateFrom() {
    return this._dateFrom;
  }

  get dateTo() {
    return this._dateTo;
  }

  get startInterval() {
    return this._startInterval;
  }

  get endInterval() {
    return this._endInterval;
  }

  get timeline() {
    return this.header.timeline;
  }

  get grid() {
    return this.header.grid;
  }

  get menu() {
    return this.header.menu;
  }

  get element() {
    return this.$el;
  }

  get node() {
    return this.$el.node();
  }

  get backgroundNode() {
    return this.$background.node();
  }
  protected $el: d3.Selection<SVGGElement, any, any, any>;

  protected $left: d3.Selection<SVGPathElement, any, any, any>;
  protected $center: d3.Selection<SVGRectElement, any, any, any>;
  protected $right: d3.Selection<SVGPathElement, any, any, any>;

  protected $title: d3.Selection<SVGTextElement, any, any, any>;

  protected $background: d3.Selection<SVGPathElement, any, any, any>;

  private _dateFrom: Date;
  private _dateTo: Date;

  private _startInterval: BaseInterval;
  private _endInterval: BaseInterval;

  private _properties = {
    xPos: 0,
    width: 0,
    height: 0,
    cornerWidth: 55.6,
    cornerPrecision: 0.75,
    cornerHalfWidth: 0
  };

  constructor(protected header: IBaseTabsHeader) {
    this.initElements();
    this.events();
  }

  public init() {
    const offset = this.offset;
    const interval = this.grid.interval();

    this._dateFrom = interval.base(0, offset);
    this._dateTo = interval.base(0, offset + 1);

    this.lockupIntervals();
  }

  public render() {
    this.initProperties();

    const { width, height, xPos, cornerWidth, cornerHalfWidth, cornerPrecision } = this.properties;
    const bgColor = this.backgroundColor;

    this.$el.classed('active', this.isActive).attr('transform', translate(xPos, 0));

    this.$left
      .attr('transform', translate(-cornerHalfWidth, 0))
      .attr('d', 'M55.6,0c-6.1,0-14.2,3.7-18.3,8.2L16.6,31.7C13,35.9,5.5,39.2,0,39.2h55.6V0z')
      .style('fill', bgColor);

    this.$center
      .attr('transform', translate(cornerHalfWidth - cornerPrecision, 0))
      .attr('width', width - cornerWidth + cornerPrecision * 2)
      .attr('height', height)
      .style('fill', bgColor);

    this.$right
      .attr('transform', translate(width - cornerHalfWidth, 0))
      .attr('d', `M0,0c6.1,0,14.2,3.7,18.3,8.2L39,31.7c3.6,4.1,11.1,7.5,16.6,7.5H0L0,0z`)
      .style('fill', bgColor);

    this.$title
      .text(this.title)
      .attr('x', width / 2)
      .attr('y', this.isActive ? height / 2 + 6 : height / 2)
      .attr('dy', '0.5ex');

    this.renderBackground();
  }

  public subscribe() {
    merge(this.grid.onDragMove, this.grid.onDragEnd, this.timeline.onResize)
      .pipe(takeUntil(this.timeline.onDestroy))
      .subscribe(() => this.renderBackground());

    this.grid.onChangeBase.pipe(takeUntil(this.timeline.onDestroy)).subscribe(() => {
      this.init();
      this.$title.text(this.title);
    });
  }

  protected initElements() {
    const el = document.createElementNS(xmlns, 'g');
    this.$el = d3.select(el).classed('tab', true);

    this.$left = this.$el.append<SVGPathElement>('path');
    this.$center = this.$el.append<SVGRectElement>('rect');
    this.$right = this.$el.append<SVGPathElement>('path');

    this.$title = this.$el.append<SVGTextElement>('text');

    const background = document.createElementNS(xmlns, 'path');
    this.$background = d3.select(background).classed('tab-background', true);
  }

  protected initProperties() {
    this.properties.cornerHalfWidth = this.properties.cornerWidth / 2;
    this.properties.width = this.header.properties.tabWidth;
    this.properties.height = this.header.height;
    this.properties.xPos = this.index * this.properties.width;
  }

  public renderBackground() {
    const sizes = this.timeline.sizes();
    const intervalWidth = sizes.intervalWidth;

    const x1 = (this.startInterval && this.startInterval.x()) || -intervalWidth;
    const x2 = (this.endInterval && this.endInterval.x()) || sizes.width;

    let xPos = x1;
    const yPos = this.header.height;
    const height = sizes.height - yPos;
    let width = x2 - x1;
    const bgColor = this.backgroundColor;

    if (!this.isActive) {
      xPos -= intervalWidth;
      width += intervalWidth;
    }

    this.$background
      .attr('transform', translate(xPos, yPos))
      .attr('d', pathForRoundedRect(0, 0, width, height, 7, !!this.startInterval, !!this.endInterval, false, false))
      .style('fill', bgColor);
  }

  protected events() {
    this.$el.on('click', () => {
      localStorage.setItem(ROSTR_OVERVIEW_INITIAL_DATE, this.dateFrom.toString());
      this.grid.page(this.offset);
    });
  }

  private lockupIntervals() {
    const intervals = this.grid.allIntervals;

    const start = lower(intervals, this.dateFrom.getTime(), v => v.dateFrom().getTime());
    const end = lower(intervals, this.dateTo.getTime(), v => v.dateFrom().getTime());

    this._startInterval = (start && intervals[start]) || void 0;
    this._endInterval = (end < intervals.length && intervals[end]) || void 0;
  }
}
