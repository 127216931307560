import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { ColumnFilterResult } from '@interfaces';

@Component({
  selector: 'app-column-filter',
  templateUrl: './column-filter.component.html',
  styleUrls: ['./column-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColumnFilterComponent implements OnInit {
  _items: unknown[];
  @Input()
  set items(value: unknown[]) {
    this._items = value;
    this.addCheckboxes();
    this.initialized = true;
  }
  get items(): unknown[] {
    return this._items;
  }
  /**
   *Name of the field in items object to display on checkbox
   */
  @Input() nameField: string;

  /**
   *Name of the field in items object to be returned in the list
   */
  @Input() valueField: string;

  @Input() disabled = false;

  @ViewChild(MatMenu) menu: MatMenu;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  @Input() value: unknown;
  /**
   * Optional: This value will be shown as blank option checkbox on top of the list, if not provided - blank will not be an option.
   */
  @Input() blankDisplay: string;

  /**
   * Optional: This value must be a unique id for localStorage. If not provided, localStorage will not be used.
   */
  @Input() localStorageId: string;

  @Output() valueChange = new EventEmitter<ColumnFilterResult>();

  private snapshot: ColumnFilterResult;
  private initialized = false;

  checkboxFormGroup: FormGroup;

  constructor(
    private readonly fb: FormBuilder,
    private readonly cd: ChangeDetectorRef
  ) {
    this.checkboxFormGroup = this.fb.group({
      blank: false,
      items: this.fb.array([])
    });
  }

  ngOnInit(): void {
    if (this.localStorageId) {
      this.readLocalStorage();
      this.applyFilters();
    }
  }

  get itemsFormArray(): FormArray {
    return this.checkboxFormGroup.controls.items as FormArray;
  }

  get blankControl(): FormControl {
    return this.checkboxFormGroup.controls.blank as FormControl;
  }

  get selectedItemsCount(): number {
    return this.itemsFormArray.value.filter((itemValue: boolean) => itemValue).length + (this.blankControl.value ? 1 : 0);
  }

  set allSelected(value: boolean) {
    this.itemsFormArray.controls.forEach(item => {
      item.setValue(value);
    });
    if (this.blankDisplay) {
      this.blankControl.setValue(value);
    }
  }

  get allSelected(): boolean {
    return this.selectedItemsCount === this.itemsFormArray.value.length + (this.blankDisplay ? 1 : 0);
  }

  public selectAll(value: boolean): void {
    this.allSelected = value;
  }

  private initControls() {
    this.blankControl.setValue(false);
    this.itemsFormArray.clear();
    this._items.forEach(() => {
      this.itemsFormArray.push(new FormControl(false));
    });
  }

  private addCheckboxes() {
    if (this.initialized) {
      // USE CASE: Changed items when something was already checked
      const currentValues = this.itemsFormArray.controls
        .map((control, index) => (control.value ? this.items[index]?.[this.valueField] : null))
        .filter(value => value !== null);

      this.initControls();

      this.itemsFormArray.controls.forEach((control, index) => {
        if (currentValues.includes(this.items[index][this.valueField])) {
          control.setValue(true);
        }
      });
      this.applyFilters();
    } else {
      this.initControls();
      this.saveSnapshot();
      this.initialized = true;
    }
  }

  private getItemValue(item: unknown) {
    return item[this.valueField];
  }

  private getResult(): ColumnFilterResult {
    const result: ColumnFilterResult = {
      items: this.items.filter((_item, index) => this.itemsFormArray.at(index)?.value).map(item => this.getItemValue(item))
    };
    if (this.blankDisplay) {
      result.blank = this.blankControl.value;
    }
    return result;
  }

  private saveSnapshot(): void {
    this.snapshot = {
      items: this.itemsFormArray.value,
      blank: this.blankControl.value
    };
  }

  public applyFilters(): void {
    this.saveSnapshot();
    this.storeLocalStorage();
    this.valueChange.emit(this.getResult());
    this.close();
  }

  public restoreSnapshot(): void {
    this.itemsFormArray.patchValue(this.snapshot.items);
    this.blankControl.patchValue(this.snapshot.blank);
  }

  public clear(): void {
    this.selectAll(false);
    this.applyFilters();
  }

  public cancel(): void {
    this.restoreSnapshot();
    this.close();
  }

  public close(): void {
    this.trigger?.closeMenu();
  }

  private storeLocalStorage(): void {
    if (this.localStorageId) {
      localStorage.setItem(this.localStorageId, JSON.stringify(this.getResult()));
    }
  }

  private updateValue(result: ColumnFilterResult) {
    this.initControls();
    this.blankControl.setValue(result.blank);
    const itemsChecked = this.items.map(item => (result.items.includes(this.getItemValue(item)) ? true : false));
    this.itemsFormArray.setValue(itemsChecked);
  }

  private readLocalStorage(): void {
    const lsItem: ColumnFilterResult = JSON.parse(localStorage.getItem(this.localStorageId));
    if (!lsItem) {
      return;
    }

    this.updateValue(lsItem);
  }
}
