import { getKeyForIsoYearWeek } from '../../helpers';
import {
  IStatisticMode,
  IWeeklyStatistics,
  IFormattingFunction,
  IRawStatistics,
  IStatistic,
  IWeeklyStatFormatted,
  IMainStatistics,
  ICustomPeriodStatistics,
  IRawCustomPeriod
} from '../../interfaces';
import { EmployeeStatistic, WeeklyEmployeeStatistic, MainEmployeeStatistic } from './statistic.class';

/* Statistics containers for different modes */
export class WeeklyStatistic implements IStatisticMode {
  private statistic: IWeeklyStatistics = {};
  private readonly formattingFunction: IFormattingFunction;

  constructor(formattingFunction: IFormattingFunction) {
    this.formattingFunction = formattingFunction;
  }

  public setData(weeklyStats: IRawStatistics, ids?: number[]): void {
    const employeesIds = ids ?? Object.keys(weeklyStats).map((id) => parseInt(id));

    employeesIds.forEach((employeeId) => {
      const employeeStatsArr: IStatistic[] = weeklyStats[employeeId];
      const employeeStatsObj = {};
      employeeStatsArr?.forEach((stat: IStatistic) => {
        employeeStatsObj[getKeyForIsoYearWeek(stat.year, stat.isoWeek)] = this.formattingFunction(stat);
      });
      this.setEmployeeStat(employeeId, employeeStatsObj);
    });
  }

  public setEmployeeStat(employeeId: number, stat: IWeeklyStatFormatted): void {
    this.statistic[employeeId] = stat;
  }

  public getStatsForEmployee(employeeId: number): EmployeeStatistic {
    return new WeeklyEmployeeStatistic(this.statistic[employeeId]);
  }
}

export class MainStatistic implements IStatisticMode {
  private statistic: IMainStatistics = {};
  private readonly formattingFunction: IFormattingFunction;

  constructor(formattingFunction: IFormattingFunction) {
    this.formattingFunction = formattingFunction;
  }

  public setData(mainStats: IRawStatistics, ids?: number[]): void {
    const employeesIds = ids ?? Object.keys(mainStats).map((id) => parseInt(id));
    employeesIds.forEach((employeeId) => {
      this.setEmployeeStat(employeeId, mainStats[employeeId]?.[0]);
    });
  }

  public setEmployeeStat(employeeId: number, stat: IStatistic): void {
    this.statistic[employeeId] = this.formattingFunction(stat);
  }

  public getStatsForEmployee(employeeId: number): EmployeeStatistic {
    return new MainEmployeeStatistic(this.statistic[employeeId]);
  }
}

export class CustomPeriodStatistic implements IStatisticMode {
  private statistic: ICustomPeriodStatistics = {};
  public formattingFunction: IFormattingFunction;

  public setData(mainStats: IRawCustomPeriod, ids?: number[]): void {
    const employeesIds = ids ?? Object.keys(mainStats).map((id) => parseInt(id));
    employeesIds.forEach((employeeId) => {
      this.statistic[employeeId] = mainStats[employeeId]?.[0];
    });
  }

  public getStatsForEmployee(employeeId: number): MainEmployeeStatistic {
    return new MainEmployeeStatistic(this.formattingFunction(this.statistic[employeeId]));
  }
}
