import { fromEvent as observableFromEvent } from 'rxjs';
import { map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Component, Input, Output, EventEmitter, ElementRef, OnInit, forwardRef } from '@angular/core';

import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AutocompleteOptionsModel } from './autocomplete-options.model';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => AutocompleteComponent),
  multi: true
};

@Component({
  selector: 'sh-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class AutocompleteComponent implements OnInit, ControlValueAccessor {
  get value(): any {
    return this.localValue;
  }

  set value(v: any) {
    if (v !== this.value) {
      this.localValue = v;
      this.setComponentValue(this.localValue);
      this.onChangeCallback(v);
    } else if (v === null) {
      this.setComponentValue('');
    }
    this.selectValue.emit(v);
  }

  public focused;
  public searchedText;
  public searched = false;

  @Input() data = [];
  @Input() clear;
  @Input() options: AutocompleteOptionsModel;

  @Output() keyUp = new EventEmitter();
  @Output() selectValue = new EventEmitter();

  private localValue: any;

  private onTouchedCallback: (t: any) => void = () => {};
  private onChangeCallback: (t: any) => void = () => {};

  // private modal: Modal,
  // public overlay: Overlay,
  constructor(private elementRef: ElementRef) {
    this.focused = false;
    // overlay.defaultViewContainer = viewContainer;
  }

  public ngOnInit() {
    if (!this.options || !(this.options instanceof AutocompleteOptionsModel)) {
      this.options = new AutocompleteOptionsModel();
    }

    // subscribe to user keyup
    const eventStream = observableFromEvent(this.elementRef.nativeElement, 'keyup').pipe(
      map(() => this.searchedText),
      debounceTime(this.options.debounce),
      distinctUntilChanged()
    );

    eventStream.subscribe(input => {
      this.searched = true;
      this.keyUp.emit(input);
    });

    // subscribe to event
    if (this.clear) {
      this.clear.subscribe(() => {
        this.setComponentValue({});
      });
    }
  }

  // Set touched on blur
  public onTouched() {
    this.onTouchedCallback(undefined);
  }

  // From ControlValueAccessor interface
  public writeValue(value: any) {
    this.value = value;
  }

  // From ControlValueAccessor interface
  public registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  // From ControlValueAccessor interface
  public registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  /**
   * Do unfocus and show modal if we need with lazy delay
   */
  public lazyBlur() {
    setTimeout(() => {
      this.focused = false;

      // clear value
      if (this.searchedText === '') {
        this.setComponentValue({});
        return;
      }

      // we should open window if field can have custom value
      if (!this.options.clearCustom && this.options.changeRequest) {
        if ((!this.localValue && this.searchedText) || (this.localValue && this.localValue.name !== this.searchedText)) {
          this.openConfirmModal();
        }
      } else if (this.options.clearCustom) {
        // and if field can't have custom value we do cleaning all values
        if (!this.localValue || (this.localValue && this.localValue.name !== this.searchedText)) {
          this.setComponentValue({});
        }
      }
    }, 100);
  }

  /**
   * @param res {object} - selected value
   */
  public selectEl(res: any) {
    this.setComponentValue(res);
    this.focused = false;
  }

  /**
   * Function do open confirm modal where user can accept or reject changes
   */
  public openConfirmModal() {
    //
    // const builder = new BSModalContextBuilder<AdditionWindowData>(
    //   { text: this.searchedText } as any,
    //   undefined,
    //   AdditionWindowData
    // );
    // let overlayConfig: OverlayConfig = {
    //   context: builder.toJSON()
    // };
    //
    // let modal = this.modal.open(
    //   ConfirmModalComponent,
    //   overlayConfig
    // );
    //
    // modal.then(currentModal => {
    //   /**
    //    * When user did choice
    //    */
    //   currentModal.result.then(
    //     (res) => {
    //       if (res) {
    //         // set value from input to current selection and pass it out
    //         this.setComponentValue({name: this.searchedText});
    //       } else {
    //         /*
    //          If we have not empty current selection then we do update our input
    //          */
    //         if (this._value && this._value.name) {
    //           this.setComponentValue(this._value);
    //         } else {
    //           /*
    //            in another way flush all variables
    //            */
    //           this.setComponentValue({});
    //         }
    //       }
    //     }
    //   );
    // });
  }

  private setComponentValue(value: any) {
    if (this.options.clearAfterSelect) {
      this.searchedText = '';
    } else {
      this.searchedText = this.options.resultLineHandler(value) || '';
    }

    const resKey = this.options.resultKey;
    if (resKey && value.hasOwnProperty(resKey)) {
      this.value = value[resKey];
    } else if (!resKey) {
      this.value = value;
    } else {
      this.value = '';
    }
  }
}
