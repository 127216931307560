import * as d3 from 'd3';
import { Subject } from 'rxjs';

import { delay, takeUntil } from 'rxjs/operators';
import { translate, xmlns } from '@helpers';

import { Defs } from '../defs/defs';
import { IBaseGrid } from '../grid/base-grid.interface';
import { BaseItem } from '../items/base.item';
import { OpenableItem } from '../items/openable.item';
import { RootItem } from '../items/root.item';
import { IBaseTimeline } from '../utils/interfaces';
import { IBaseMenu } from './base-menu.interface';
import { IBaseItem } from '..';


export class Menu implements IBaseMenu {

  // events
  public onChange = new Subject<number>();

  protected _defs: Defs;

  private el: SVGGElement;
  private $el: d3.Selection<any, any, any, any>;

  private $underSysContainer: d3.Selection<SVGGElement, any, any, any>;
  private $overSysContainer: d3.Selection<SVGGElement, any, any, any>;

  private _visible: BaseItem[] = [];
  private _tree = [];

  private _timeline: IBaseTimeline;
  private _grid: IBaseGrid;

  constructor(private _items: BaseItem[] = []) {
    this.el = document.createElementNS(xmlns, 'g');
    this.$el = d3.select(this.el);

    const under = document.createElementNS(xmlns, 'g');
    this.$underSysContainer = d3.select(under)
      .classed('under-menu-sys-container', true);

    const over = document.createElementNS(xmlns, 'g');
    this.$overSysContainer = d3.select(over)
      .classed('over-menu-sys-container', true);

    _items.forEach((item: any) => { // fixme any
      item.menu(this);
      item.parent = item;
    });

    this.buildItemsTree(this._items, 0);

    this.lookupVisibleItems();
  }

  get element() {
    return this.$el;
  }

  get node() {
    return this.el;
  }

  get overSysContainer() {
    return this.$overSysContainer;
  }

  get underSysContainer() {
    return this.$underSysContainer;
  }

  get firstItem() {
    return this.items[0];
  }

  /**
   * Height of items
   * @returns {number}
   */
  get height(): number {
    let height = 0;
    const visible = this._visible;
    visible.forEach((vItem) => {
      height += vItem.totalHeight();
    });

    return height;
  }

  /**
   * All items
   * @returns {Array}
   */
  get items() {
    return this._items;
  }

  /**
   * All visible items
   * @returns {Array}
   */
  get visible() {
    return this._visible;
  }

  /**
   * Number of visible items
   * @returns {number}
   */
  get visibleItemsCount(): number {
    return this._visible.length;
  }

  /**
   * rendering
   * @returns {Element}
   */
  public render() {
    const sizes = this._timeline.sizes();
    const yPos = sizes.headerHeight;
    const gridOffset = this._grid.offset();

    this.$el
      .attr('transform', translate(0, yPos))
      .attr('id', 'menu');

    this.$overSysContainer.attr('transform', translate(gridOffset, yPos));
    this.$underSysContainer.attr('transform', translate(gridOffset, yPos));

    this.renderItems(this._items);

    return this.el;
  }

  /**
   * Event listeners
   * @returns {Menu}
   */
  public subscribe() {
    this._items.forEach((item: any) => {
      if (item.onOpen) {
        item.onOpen.pipe(takeUntil(this._timeline.onDestroy)).subscribe(() => {
          this.changed();
        });
        item.onClose.pipe(takeUntil(this._timeline.onDestroy)).subscribe(() => {
          this.changed();
        });
        item.onAddItem.pipe(takeUntil(this._timeline.onDestroy)).subscribe((newItem) => {
          this.changed();
        });
      }
      if (item.onChangeHeight) {
        item.onChangeHeight.pipe(takeUntil(this._timeline.onDestroy)).subscribe(() => {
          this.changed();
        });
      }
      item.subscribe();
    });

    this._grid.onZoom
      .pipe(takeUntil(this._timeline.onDestroy))
      .subscribe(() => {
        const sizes = this._timeline.sizes();
        const yPos = sizes.headerHeight;
        const gridOffset = this._grid.offset();

        this.$overSysContainer.attr('transform', translate(gridOffset, yPos));
        this.$underSysContainer.attr('transform', translate(gridOffset, yPos));
      });

    return this;
  }

  /**
   * Add item to top level
   * @param item
   */
  public addItem(item: any) {

    // add needed links to item object
    item.timeline(this._timeline);
    item.menu(this);
    item.parent = item;
    item.defs(this._defs);
    item.grid(this._grid);
    item.subscribe();

    if (item.onClose) {
      item.onClose.pipe(takeUntil(this._timeline.onDestroy)).subscribe(() => {
        this.changed();
      });
    }
    if (item.onOpen) {
      item.onOpen.pipe(takeUntil(this._timeline.onDestroy)).subscribe(() => {
        this.changed();
      });
    }
    if (item.onAddItem) {
      item.onAddItem.pipe(takeUntil(this._timeline.onDestroy), delay(0)).subscribe(() => {
        this.changed();
      });
    }
    if (item.onChangeHeight) {
      item.onChangeHeight.pipe(takeUntil(this._timeline.onDestroy)).subscribe(() => {
        this.changed();
      });
    }

    item.index = this._items.length;

    if (item.items) {
      this.buildItemsTree([item.items], 1);
    }

    // add item to items array
    this._items.push(item);

    // this.buildItemsTree(this._items, 0);

    // do rendering
    this.lookupVisibleItems();
    this.renderItems(this._items);
    this.changed();
  }

  /**
   * Some setup
   */
  public setup() { // fixme
    this.changed();
  }

  /**
   * return item by Y coordinate
   * @param y
   * @param targetHeight
   */
  public getNearItemByCoord(y: number, targetHeight: number) {
    const items = this._visible;

    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      const itemHalfHeight = targetHeight / 2;

      if (item.y() <= y + itemHalfHeight && item.offset() >= y + itemHalfHeight) {
        return item;
      }
    }
    return items[items.length - 1];
  }

  /**
   * return item by click
   * @param y
   */
  public getItemByCoord(y: number) {
    const items = this._visible;

    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.y() <= y && item.offset() >= y) {
        return item;
      }
    }
    return items[items.length - 1];
  }

  /**
   * All items for {level}
   * @param level
   * @returns {any}
   */
  public getItemsByLevel(level) {
    return this._tree[level];
  }

  /******************************************************************************/
  /**
   * Setter for grid property
   * @param grid
   * @returns {Menu}
   */
  public grid(grid: IBaseGrid): this {
    this._grid = grid;
    this._items.forEach((item: RootItem) => {
      item.grid(grid);
    });
    return this;
  }

  /**
   * Setter for timeline property
   * @param timeline
   * @returns {Menu}
   */
  public timeline(timeline: IBaseTimeline): this {
    this._timeline = timeline;
    this._items.forEach((item: RootItem) => {
      item.timeline(timeline);
    });
    return this;
  }

  /**
   * Defs setter
   * @param defs
   */
  public defs(defs: Defs): this {
    this._defs = defs;
    this._items.forEach((item: RootItem) => {
      item.defs(defs);
    });
    return this;
  }

  /**
   * Remove all items
   * @returns {Menu}
   */
  public clear() {
    this._items.forEach((item: OpenableItem) => {
      item.remove();
    });
    this._items = [];
    this._visible = [];
    this.changed();
    return this;
  }

  public updateTree() {
    this.buildItemsTree(this._items, 0);
    this.lookupVisibleItems();

    const opened = this._visible;
    const length = opened.length;
    const last = opened[length - 1];
    const height = last ? last.offset() : 0;

    this.onChange.next(height);
  }

  public updateItemsPosition(items: BaseItem[]) {
    this._items = items;
    this.updateTree();
    this.items.forEach((item) => item.updatePosition());
  }



  /**
   * Re-lookup visible items
   */
  private changed() {
    this._visible.forEach((item: BaseItem) => {
      item.hide();
    });

    this.updateTree();

    this._visible.forEach((item: BaseItem) => {
      item.show();
    });
  }

  /**
   * Some initial items rendering
   */
  private renderItems(items: BaseItem[], force = false) {
    const node = this.$el.node();
    const visible = this._visible;

    if (force) {
      this.$el.selectAll().remove();
    }

    for (let i = 0; i < items.length; i++) {
      const current = items[i];

      if (current.isRendered && !force) { continue; }

      node.appendChild(current.render());

      if (visible.indexOf(current) === -1) {
        current.hide();
      }

      if (current instanceof RootItem) {
        const subitems = current.subitems();
        subitems.forEach((sub) => {
          node.appendChild(sub.render());
          if (visible.indexOf(sub) === -1) {
            sub.hide();
          }
        });
      }
      if ((current instanceof RootItem || current instanceof OpenableItem)) {
        this.renderItems(current.items);
      }
    }
  }

  private lookupVisibleItems() {
    let visible = [];

    function open(items: IBaseItem[]) {
      for (let i = 0; i < items.length; i++) {
        const current = items[i];
        current.clearCache();
        visible.push(current);
        if (current instanceof RootItem) {
          const subitems = current.subitems();
          subitems.forEach((sub) => {
            sub.clearCache();
          });
          visible = visible.concat(current.subitems());
        }
        if ((current instanceof RootItem || current instanceof OpenableItem) && current.isOpen()) {
          open(current.items);
        }
      }
    }

    open(this._items);

    this._visible = visible;
    return this;
  }

  private buildItemsTree(items, level) {
    if (level === 0) {
      this._tree = [];
    }

    // item type: EmployeeItem | MasterPlanItem | UnassignedShiftsItem | GroupItem | VacayItem
    items.forEach((item, index) => {
      if (item.items) {
        this.buildItemsTree(item.items, level + 1);
      }

      item.index = index;
      item.level = level;
      const treeLength = this._tree.length;
      for (let i = treeLength; i <= level; i++) {
        this._tree.push([]);
      }

      if (this._tree[level].indexOf(item) === -1) {
        this._tree[level].push(item);
      }
    });
  }


}
