import { Component, OnDestroy, OnInit } from '@angular/core';
import { SideBarService } from '@shared/services/side-bar/side-bar.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'pl-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, OnDestroy {
  private sideBarLockedSubscription: Subscription;

  public isSideBarLocked = false;

  constructor(private readonly sideBarService: SideBarService) {}

  ngOnInit(): void {
    this.isSideBarLocked = this.sideBarService.isLocked;

    this.sideBarLockedSubscription = this.sideBarService.settingsChanged$.subscribe(x => {
      this.isSideBarLocked = x.isLocked;
    });
  }

  ngOnDestroy(): void {
    this.sideBarLockedSubscription.unsubscribe();
  }
}
