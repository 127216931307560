import { Component, Input, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent {

  @Input() icon: string;
  @Input() title: string;
  @Input() hideSorry = false;
  @ViewChild('content', { static: true }) content: ElementRef;

  get hasContent() {
    return this.content && this.content.nativeElement.childNodes.length !== 0;
  }

  backHome() {
    window.location.href = '/';
  }
}
