import { Injectable, ComponentFactoryResolver, ComponentRef, ViewContainerRef, Type, TemplateRef, Injector, StaticProvider } from '@angular/core';

import { ICustomTooltipConfig, ISimpleTooltipConfig, ITooltipConfig, TooltipConfig, TooltipContext } from './tooltip.config';
import { TooltipDynamicComponent } from './tooltip-dynamic.component';
import { TooltipContainerService } from './tooltip-container.service';
import { InternalTooltipReference, TooltipReference } from './tooltip.reference';
import { TooltipSimpleComponent } from './tooltip-simple.component';

@Injectable()
export class TooltipService {
  private _tooltips = new Set<InternalTooltipReference>();

  /**
   * @param {ComponentFactoryResolver} _componentFactoryResolver
   * @param {TooltipContainerService} _containerService
   */
  constructor(private _containerService: TooltipContainerService) {}

  /**
   * Open a tooltip.
   *
   * @param {ISimpleTooltipConfig} config
   *
   * @return {TooltipReference}
   */
  public openTooltip(config: ISimpleTooltipConfig): TooltipReference {
    const internalTooltipReference = new InternalTooltipReference(config);
    const tooltipComponent = this.createTooltip(internalTooltipReference);
    const providers: StaticProvider[] = [
      { provide: TooltipConfig, useValue: internalTooltipReference.config },
      { provide: TooltipReference, useValue: internalTooltipReference.tooltipRef }
    ];

    this.createComponentInstance(tooltipComponent.instance.tooltipTarget, TooltipSimpleComponent, providers);

    return internalTooltipReference.tooltipRef;
  }

  /**
   * Open a tooltip with custom component.
   *
   * @param {ICustomTooltipConfig} config
   *
   * @returns {TooltipReference}
   */
  public openCustomTooltip(config: ICustomTooltipConfig): TooltipReference {
    const internalTooltipReference = new InternalTooltipReference(config);
    const tooltipComponent = this.createTooltip(internalTooltipReference);
    const providers: StaticProvider[] = [
      { provide: TooltipContext, useValue: config.context },
      { provide: TooltipConfig, useValue: internalTooltipReference.config },
      { provide: TooltipReference, useValue: internalTooltipReference.tooltipRef }
    ];

    this.createComponentInstance(tooltipComponent.instance.tooltipTarget, config.component, providers);

    return internalTooltipReference.tooltipRef;
  }

  /**
   * Open a tooltip from template.
   *
   * @param {TemplateRef<any>} template
   * @param {ITooltipConfig} config
   *
   * @returns {TooltipReference}
   */
  public openTooltipTemplate(template: TemplateRef<any>, config: ITooltipConfig) {
    const internalTooltipReference = new InternalTooltipReference(config);
    const tooltipComponent = this.createTooltip(internalTooltipReference);

    tooltipComponent.instance.tooltipTarget.createEmbeddedView(template);

    return internalTooltipReference.tooltipRef;
  }

  /**
   * Close all tooltips
   */
  public closeAll() {
    this._tooltips.forEach(tooltip => {
      tooltip.close();
    });
  }

  /**
   * Create dynamic tooltip component.
   *
   * @param {InternalTooltipReference} internalTooltipReference
   *
   * @returns {ComponentRef<TooltipDynamicComponent>}
   */
  private createTooltip(internalTooltipReference: InternalTooltipReference) {
    this.closeAll();

    const providers: StaticProvider[] = [
      { provide: TooltipConfig, useValue: internalTooltipReference.config },
      { provide: InternalTooltipReference, useValue: internalTooltipReference }
    ];

    const tooltipComponent = this.createComponentInstance(this._containerService.viewContainerRef, TooltipDynamicComponent, providers);

    internalTooltipReference.closeCallback = () => {
      this._tooltips.delete(internalTooltipReference);
      tooltipComponent.destroy();
    };
    this._tooltips.add(internalTooltipReference);

    return tooltipComponent;
  }

  /**
   * Create component instance.
   *
   * @param {ViewContainerRef} viewContainerRef
   * @param {Type<C>} component
   * @param {Provider[]} providers
   *
   * @returns {ComponentRef<C>}
   */
  private createComponentInstance<C>(viewContainerRef: ViewContainerRef, component: Type<C>, providers: StaticProvider[] = []): ComponentRef<C> {
    const parentInjector = viewContainerRef.injector;
    const injector = Injector.create({ providers, parent: parentInjector });
    return viewContainerRef.createComponent(component, { index: viewContainerRef.length, injector });
  }
}
