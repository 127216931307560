import { Pipe, PipeTransform } from '@angular/core';
import { MINUTES_PER_DAY, MINUTES_PER_HOUR } from '@helpers';

@Pipe({name: 'minutesToTime'})

export class MinutesToTimePipe implements PipeTransform {
  transform(value: number) {
    // Negative values can be passed as input
    // Negative values meaning:
    // -120 -> 22:00 of previous day

    const minuteOfDay = this.getMinuteOfDay(value);
    const hours = Math.floor(minuteOfDay / MINUTES_PER_HOUR);
    const minutes = minuteOfDay % MINUTES_PER_HOUR;

    return `${hours}:${minutes.toString().padStart(2, '0')}`;
  }

  private getMinuteOfDay(value: number): number {
    const minutes = value % MINUTES_PER_DAY;
    return minutes < 0 ? MINUTES_PER_DAY + minutes : minutes;
  }
}
