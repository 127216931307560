import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { convertSeverityIntoColor, convertSourceIntoIcon } from '@component/notifications/helpers/notification-helper';
import { NotificationModel } from '@component/notifications/models';
import { NotificationsAPIService } from '@component/notifications/services/notifications-api.service';
import { Subscription, timer } from 'rxjs';
import { take } from 'rxjs/operators';
import { NotificationsDialogComponent } from '../notifications-dialog/notifications-dialog.component';
import { NotificationsSettingsService } from '@shared/services/notifications/notifications-settings.service';

export type NotificationWithTimer = NotificationModel & { timerSub: Subscription };

@Component({
  selector: 'app-notifications-popup',
  templateUrl: './notifications-popup.component.html',
  styleUrls: ['./notifications-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationsPopupComponent implements OnInit, OnDestroy {
  private newNotificationsSub: Subscription;

  public notifications: NotificationWithTimer[] = [];

  public convertSourceIntoIcon = convertSourceIntoIcon;
  public convertSeverityIntoColor = convertSeverityIntoColor;

  constructor(
    private readonly notificationsAPIService: NotificationsAPIService,
    private readonly dialog: MatDialog,
    private readonly notificationsSettingsService: NotificationsSettingsService,
    private readonly changeDetector: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.newNotificationsSub = this.notificationsAPIService.onNotificationCreated$().subscribe(notification => {
      if (notification.popupType === 'MINOR') {
        if (!this.notificationsSettingsService.isDisplayingOfPopupsEnabled) {
          return;
        }
        this.notifications.unshift({ ...notification, timerSub: this.setupFader() });
        this.changeDetector.markForCheck();
      } else {
        this.openDialog(notification);
      }
    });
  }

  openDialog(notification: NotificationModel) {
    this.dialog.open(NotificationsDialogComponent, {
      data: notification,
      disableClose: true
    });
  }

  private setupFader() {
    return timer(10000)
      .pipe(take(1))
      .subscribe(() => {
        this.notifications.pop();
        this.changeDetector.markForCheck();
      });
  }

  public ngOnDestroy(): void {
    this.newNotificationsSub.unsubscribe();
  }

  public closeNotification(notification: NotificationWithTimer) {
    notification.timerSub?.unsubscribe();
    this.notifications = this.notifications.filter(x => x.id !== notification.id);
    this.changeDetector.markForCheck();
  }
}
