import * as moment from 'moment-mini';

export function getWeekNumberClasses(date: Date | moment.Moment): string[] {
  const classes: string[] = [];
  const momentDate = moment(date);

  if (momentDate.isoWeekday() === 1) {
    const weekNumber = momentDate.isoWeek();
    classes.push(`start-of-week`);
    classes.push(`week-${weekNumber}`);
  }

  return classes;
}
