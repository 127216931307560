import * as d3 from 'd3';
import * as moment from 'moment-mini';
import { EventEmitter } from '@angular/core';
import { orderBy } from 'lodash-es';

import { GroupModel, ScheduleModel } from '@shared/models';
import { translate, url } from '@shared/helpers/d3';

import { Footer } from './footer';
import { ScheduleTimelineGroupItem } from './schedule-timeline-group-item';
import { Schedule } from './schedule';
import { ScheduleTimelinesComponent } from '@app/rostr/schedules/schedule-timelines/schedule-timelines.component';
import { ISchedulesTimelineSizes } from '@src/app/_shared/interfaces/schedule-timeline-sizes.interface';
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs';

export class SchedulesTimeline {
  public $groupLines: d3.Selection<any, any, any, any>; // groupItems g tag
  public $groupItems: d3.Selection<any, any, any, any>; // groupItems g tag
  public $schedules: d3.Selection<any, any, any, any>; // intervals g tag

  public $defs: d3.Selection<any, any, any, any>; // Axis g tag
  public hatchId = 'diagonalHatch';

  public timeScale: d3.ScaleTime<number, number>;

  public groupItems: ScheduleTimelineGroupItem[] = [];
  public groups: GroupModel[] = [];
  public schedules: Schedule[] = [];
  public footer: Footer;

  public now = moment();
  public endDate: moment.Moment;
  public startDate: moment.Moment;

  public onResize: EventEmitter<any> = new EventEmitter();

  public colors = ['#f6a623', '#7cb9ff', '#50e3c2', '#da65ce', '#B27EFF'];
  protected $el: d3.Selection<any, any, any, any>; // element
  protected $svg: d3.Selection<any, any, any, any>; // svg
  protected svg: Element; // svg node
  public scheduleRemovedSubscription: Subscription;

  constructor(
    protected element: Element,
    protected _context: ScheduleTimelinesComponent,
    protected _sizes: ISchedulesTimelineSizes
  ) {
    this.$el = d3.select(element);
    this.$svg = this.$el.append('svg').attr('height', _sizes.height).attr('width', window.innerWidth).classed('schedules-timeline', true);
    this.svg = <Element>this.$svg.node();

    this.$defs = this.$svg.append('defs').attr('class', 'defs');
    this.addMask(this.hatchId, 10, 4, 45, 'white'); // it works just with white but it works ok

    this.$groupLines = this.$svg.append('g').attr('class', 'group-lines').attr('transform', translate(0, 0));
    this.$schedules = this.$svg.append('g').attr('class', 'schedules').attr('transform', translate(_sizes.groupWidth, 0));
    this.$groupItems = this.$svg.append('g').attr('class', 'groupItems').attr('transform', translate(0, 0));

    this.footer = new Footer(this.$svg.append('g').attr('class', 'footer'), this);
    this.subscribe();

    this.resize = this.resize.bind(this);
  }

  /**
   * Timeline sizes
   * @returns {ISchedulesTimelineSizes}
   */
  get sizes(): ISchedulesTimelineSizes {
    return this._sizes;
  }

  get context() {
    return this._context;
  }

  /**
   * Rendering functionality
   */
  public render(groups: GroupModel[], schedules: ScheduleModel[]) {
    const groupIdsFromSchedules = schedules.flatMap(schedule => schedule.groups.map(group => group.id));
    const uniqueIdsOfGroupsThatHaveSchedule = new Set(groupIdsFromSchedules);
    const groupsThatHaveSchedule = groups.filter(group => uniqueIdsOfGroupsThatHaveSchedule.has(group.id));
    this.groups = orderBy(groupsThatHaveSchedule, ['unit.name', 'name']);

    this.renderGroups();
    this.setUpScale(this._context.selectedYear);
    this.renderSchedules(schedules);
    this._context.updateTodayArrow();
  }

  public renderGroups() {
    this.$groupItems.selectAll('*').remove();
    this.$groupLines.selectAll('*').remove();
    this.groupItems = this.groups.map((group, i) => new ScheduleTimelineGroupItem(group, i, this));
    this.groupItems.forEach((group: ScheduleTimelineGroupItem) => this.$groupItems.node().appendChild(group.render()));

    if (this.groupItems.length) {
      const lastItem = this.groupItems[this.groupItems.length - 1];
      this._sizes.groupsHeight = lastItem.yOffset + lastItem.height;
    }
  }

  public renderSchedules(scheduleModels: ScheduleModel[]) {
    if (this.schedules.length) {
      this.schedules.forEach((schedule: Schedule) => schedule.remove());
    }
    this.schedules = scheduleModels.flatMap(schedule => {
      return schedule.groups.map(group => {
        const groupIndex = this.groupItems.findIndex((groupItem: ScheduleTimelineGroupItem) => groupItem.model.id === group.id);
        return new Schedule(schedule, groupIndex, this);
      });
    });
    this.schedules.forEach((schedule: Schedule) => this.$schedules.node().appendChild(schedule.render()));
    this.groupItems.forEach(item => item.renderTodayArrow());

    this.resizeSvg();
    this.footer.render();
    this.footer.show();
  }

  public renderTodayArrow(firstDates: { groupId: number; dateFrom: string }[] = []) {
    this.groupItems.forEach(item => {
      const firstSchedule = firstDates.find(first => first.groupId === item.model.id);
      item.firstScheduleDateFrom = firstSchedule && moment(firstSchedule.dateFrom);
      item.renderTodayArrow();
    });
  }

  /**
   * Subscribe to event emitters
   * @returns {void}
   */
  public subscribe() {
    this.scheduleRemovedSubscription = this._context.deleteService.scheduleRemoved$.pipe(take(1)).subscribe((schedule: ScheduleModel) => {
      const rectangleSchedulesIndexes = this.schedules
        .map((item: Schedule, index) => {
          if (item.model.id === schedule.id) {
            return index;
          }
        })
        .filter(el => el !== undefined);

      // Note.
      // For current year schedule rectangleSchedulesIndexes will have 1 or 2 entries.
      // For future schedules rectangleSchedulesIndexes will be empty array.
      rectangleSchedulesIndexes.forEach(rectangleScheduleIndex => {
        const schRectangle = this.schedules[rectangleScheduleIndex];
        const group = this.groupItems[schRectangle.groupIndex];

        if (group.firstScheduleDateFrom.isSame(schedule.dateFromMoment)) {
          this.context.updateTodayArrow();
        }

        schRectangle.remove();
      });

      // Because we can't .splice() during the loop above, we need to do it after.
      rectangleSchedulesIndexes.forEach(index => this.schedules.splice(index, 1));
    });
  }

  public patternUrl(id: string) {
    return url(this._context.url, id);
  }

  public setUpScale(year: number) {
    this.endDate = moment([year + 1, 0, 31, 23, 59, 59]);
    this.startDate = moment([year, 0, 1, 0, 0]);

    this.timeScale = d3
      .scaleTime()
      .domain([this.startDate, this.endDate])
      .range([0, window.innerWidth - this._sizes.groupWidth - this._sizes.marginRight]);

    this.onResize.pipe(take(1)).subscribe(() => this.resizeSvg());
    window.addEventListener('resize', this.resize);
  }

  public getColor(index: number) {
    return this.colors[index % this.colors.length];
  }

  /**
   * Add mask pattern
   * @param id
   * @param width
   * @param height
   * @param angle
   * @param color
   * @returns {Selection<any>}
   */
  private addMask(id: string, width: number, height: number, angle: number, color: string) {
    const $pattern = this.$defs
      .append('pattern')
      .attr('id', id)
      .attr('patternUnits', 'userSpaceOnUse')
      .attr('width', width)
      .attr('height', height)
      .attr('patternTransform', `rotate(${angle})`);

    $pattern
      .append('rect')
      .attr('x', '0')
      .attr('y', '0')
      .attr('width', width / 2)
      .attr('height', height)
      .style('stroke', 'none')
      .style('fill', `${color}`);

    this.$defs
      .append('mask')
      .attr('id', `${id}-mask`)
      .append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', '100%')
      .attr('height', '100%')
      .attr('fill', this.patternUrl(id));

    return $pattern;
  }

  /**
   * Setup current sizes
   */
  private resize() {
    this.timeScale.range([0, window.innerWidth - this._sizes.groupWidth - this._sizes.marginRight]);
    this.onResize.next();
  }

  private resizeSvg() {
    this.$svg.attr('height', this._sizes.groupsHeight + this._sizes.footerHeight).attr('width', window.innerWidth);
  }

  destroy(): void {
    this.scheduleRemovedSubscription?.unsubscribe();
    this.onResize?.complete();
    window.removeEventListener('resize', this.resize);
  }
}
