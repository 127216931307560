<div class="date-list">
  <mat-chip-listbox>
    <mat-chip *ngFor="let date of dates" (removed)="removeDate(date)" color="primary">
      {{ date | date }}
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip>
  </mat-chip-listbox>
</div>

<div class="picker-toggle">
  <mat-datepicker-toggle (click)="onToggle()"></mat-datepicker-toggle>
</div>

<ng-template #calendarDialog>
  <h2 matDialogTitle>Select days</h2>
  <mat-calendar #matCalendar [dateClass]="getClassForDay()" (selectedChange)="onDateSelected($event)"></mat-calendar>
</ng-template>
