import { Injectable } from '@angular/core';
import { ValidationsState } from '../state/validations.state';
import { ScheduleValidation } from '../dto/schedule-validation';
import { ScheduleValidationsAPIService } from '../api/services/schedule-validations-api.service';
import { map, tap } from 'rxjs/operators';
import { Observable, combineLatest } from 'rxjs';
import { ScheduleValidationSeverity } from '@enums';
import { ShiftValidity, Validity } from '../dto/validity';
import { SettingsState } from '../state/settings.state';

@Injectable({ providedIn: 'root' })
export class ValidationsFacade {
  constructor(
    private readonly validationsState: ValidationsState,
    private readonly settingsState: SettingsState,
    private readonly scheduleValidationsApi: ScheduleValidationsAPIService
  ) {}

  public readonly areValidationsLoading$: Observable<boolean> = this.validationsState.areValidationsLoading$;
  public readonly excludedValidations$: Observable<string[]> = this.validationsState.excludedValidations$;

  public setValidations(validations: ScheduleValidation[]): void {
    this.validationsState.setValidations(validations);
  }

  public allValidations$ = this.validationsState.validations$;

  public getExcludedValidations$ = () =>
    combineLatest([this.validationsState.validations$, this.validationsState.excludedValidations$]).pipe(
      map(([validations, excluded]) => validations.filter(x => excluded.includes(x.code)))
    );

  public getFilteredValidations$ = (): Observable<ScheduleValidation[]> =>
    combineLatest([this.validationsState.validations$, this.validationsState.excludedValidations$]).pipe(
      map(([validations, excluded]) => validations.filter(x => !excluded.includes(x.code)))
    );

  public getFilteredValidationsByDay$ = (day: string) =>
    combineLatest([this.validationsState.getValidationsByDay(day), this.validationsState.excludedValidations$, this.settingsState.settingsChanged$]).pipe(
      map(([validations, excluded, settings]) => (settings.displayShiftsWarnings ? validations.filter(x => !excluded.includes(x.code)) : []))
    );

  public getFilteredValidationsByWeekForEmployee$ = (week: number, employeeId: number) =>
    combineLatest([
      this.validationsState.getValidationsByWeekAndEmployee(week, employeeId),
      this.validationsState.excludedValidations$,
      this.settingsState.settingsChanged$
    ]).pipe(map(([validations, excluded, settings]) => (settings.displayShiftsWarnings ? validations.filter(x => !excluded.includes(x.code)) : [])));

  public getFilteredValidationsByEmployee$ = (employeeId: number) =>
    combineLatest([
      this.validationsState.getValidationsByEmployee(employeeId),
      this.validationsState.excludedValidations$,
      this.settingsState.settingsChanged$
    ]).pipe(map(([validations, excluded, settings]) => (settings.displayShiftsWarnings ? validations.filter(x => !excluded.includes(x.code)) : [])));

  public getFilteredValidationsByShift$ = (shiftId: string) =>
    combineLatest([this.validationsState.getValidationsByShift(shiftId), this.validationsState.excludedValidations$, this.settingsState.settingsChanged$]).pipe(
      map(([validations, excluded, settings]) => (settings.displayShiftsWarnings ? validations.filter(x => !excluded.includes(x.code)) : []))
    );

  public getDayValidity = (day: string) => this.getFilteredValidationsByDay$(day).pipe(map(this.calcValidity()));
  public getWeekValidity = (week: number, employeeId: number) => this.getFilteredValidationsByWeekForEmployee$(week, employeeId).pipe(map(this.calcValidity()));
  public getEmployeeValidity = (employeeId: number) => this.getFilteredValidationsByEmployee$(employeeId).pipe(map(this.calcValidity()));
  public getShiftValidity = (shiftId: string) =>
    this.getFilteredValidationsByShift$(shiftId).pipe(map(validations => this.calcShiftValidity(validations, shiftId)));

  onScheduleValidationsUpdated$ = (scheduleId: number) =>
    this.scheduleValidationsApi.onScheduleValidationsUpdated$(scheduleId).pipe(
      tap(liveResult => {
        this.setValidations(liveResult);
      })
    );

  public toggleValidation(checksum: string, scheduleId: number) {
    return this.scheduleValidationsApi.toggleValidation(checksum, scheduleId);
  }

  public revalidate(scheduleId: number) {
    this.validationsState.setAreValidationsLoading(true);
    return this.scheduleValidationsApi.revalidate(scheduleId);
  }

  public setExcludedValidations(excludedValidations: string[]) {
    this.validationsState.setExcludedValidations(excludedValidations);
    this.settingsState.setExcludedValidations(excludedValidations);
  }

  public setExcludedValidationsFromSettings() {
    this.validationsState.setExcludedValidations(this.settingsState.excludedValidations);
  }

  public cleanUp() {
    this.validationsState.cleanUp();
  }

  private calcValidity(): (validations: ScheduleValidation[]) => Validity {
    return validations => {
      const hasAnyError = validations.some(x => x.severity === ScheduleValidationSeverity.ERROR);
      const hasAnyWarning = validations.some(x => x.severity === ScheduleValidationSeverity.WARNING);
      const allAccepted = validations.length > 0 && validations.every(x => x.isAccepted);

      return {
        isWarning: hasAnyWarning,
        isError: hasAnyError,
        allAccepted: allAccepted
      };
    };
  }

  private calcShiftValidity(validations: ScheduleValidation[], avaiKey: string): ShiftValidity {
    const hasConflict = validations.some(x => x.isCrossShifts && x.shifts.slice(1).includes(avaiKey));
    const conflicts = validations.filter(x => x.isCrossShifts);
    const allConflictsAccepted = conflicts.length > 0 && conflicts.every(x => x.isAccepted);

    return {
      ...this.calcValidity()(validations),
      hasConflict: hasConflict,
      allConflictsAccepted: allConflictsAccepted
    };
  }
}
