import { Component, OnInit, Input, ChangeDetectionStrategy, OnDestroy, ViewChild, ElementRef, EventEmitter, Output } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, NgControl, Validators } from '@angular/forms';
import { MatChipGrid } from '@angular/material/chips';
import { ErrorStateMatcher } from '@angular/material/core';
import { merge, Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, startWith } from 'rxjs/operators';

class LocalErrorStateMatcher implements ErrorStateMatcher {
  constructor(
    private otherMatcher: ErrorStateMatcher,
    private otherControl: AbstractControl
  ) {}
  isErrorState(): boolean {
    return this.otherMatcher?.isErrorState(this.otherControl, null);
  }
}

@Component({
  selector: 'app-autocomplete-wrapper',
  templateUrl: './autocomplete-wrapper.component.html',
  styleUrls: ['./autocomplete-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AutocompleteWrapperComponent<T = unknown> implements ControlValueAccessor, OnInit, OnDestroy {
  @ViewChild('multiInput') input: ElementRef;
  @ViewChild('chipList') chipList: MatChipGrid;

  @Input() stateMatcher: ErrorStateMatcher = undefined;
  @Input() placeholder: string = undefined;
  @Input() label = 'Search';

  @Output() changedValue = new EventEmitter<T | T[]>();

  @Input() multiple = false;
  @Input() readonly = false;

  public localStateMatcher: ErrorStateMatcher;
  private initialized = false;

  @Input()
  displayWith: (el: T) => string;

  @Input()
  compareByProperty = 'id';

  _items: T[];
  @Input()
  set items(value: T[]) {
    this._items = value || [];
    if (this.initialized) {
      this.assignExistingValue();
      if (this.isValueEmpty()) {
        this.localFormControl.patchValue('');
      }
    }
  }
  get items(): T[] {
    return this._items;
  }

  private _value: T | T[];
  set value(value: T | T[]) {
    if (value !== this._value) {
      this._value = value;
      this.onChange(this._value);
      if (this.initialized) {
        this.changedValue.emit(this._value);
      }
    }
  }
  get value(): T | T[] {
    return this._value;
  }

  @Input() invalidate: Subject<void>;

  public filteredItems$: Observable<T[]>;

  private searchClear$ = new Subject<string>();

  public localFormControl = new FormControl<string | T | T[]>('');

  public sub: Subscription;
  public invalidateSub: Subscription;

  private onChange = (value: T | T[]) => {};
  public onTouched = () => {};
  public disabled = false;

  get control(): AbstractControl {
    return this.ngControl.control;
  }

  constructor(private ngControl: NgControl) {
    ngControl.valueAccessor = this;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  writeValue(obj: any): void {
    this.value = obj;
    if (obj === null) {
      this.localFormControl.patchValue('');
      this.clearSearch();
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    if (isDisabled) {
      this.localFormControl.disable();
    } else {
      this.localFormControl.enable();
    }
  }

  isRequired(): boolean {
    return this.control.hasValidator(Validators.required);
  }

  ngOnInit(): void {
    this.localStateMatcher = new LocalErrorStateMatcher(this.stateMatcher, this.control);

    this.localFormControl.setValue(this.control.value);
    this.filteredItems$ = merge(this.localFormControl.valueChanges.pipe(startWith(''), debounceTime(500)), this.searchClear$).pipe(
      filter(input => typeof input === 'string'),
      map((input: string) => {
        return this._filter(input);
      })
    );

    this.sub = this.localFormControl.valueChanges
      .pipe(
        filter(x => typeof x !== 'string'),
        distinctUntilChanged()
      )
      .subscribe((value: T) => {
        if (this.multiple) {
          const currentValue = this.value as T[];
          this.value = [...currentValue, value];
          this.localFormControl.patchValue('');
          this.input.nativeElement.value = '';
        } else {
          this.value = value;
        }
        this.onTouched();
      });

    if (this.invalidate) {
      this.invalidateSub = this.invalidate.subscribe(() => {
        this.localFormControl.setValue(this.value);
      });
    }

    this.initialized = true;
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
    this.invalidateSub?.unsubscribe();
  }

  private isValueEmpty(): boolean {
    if (this.multiple) {
      return (this.value as T[]).length === 0;
    } else {
      return !this.value;
    }
  }

  private assignExistingValue() {
    if (this.multiple) {
      const currentValue = this.value as T[];
      const existingItems = this.items.filter(item => {
        return currentValue.findIndex(currentItem => this.compareItems(item, currentItem)) >= 0;
      });
      this.value = existingItems || [];
    } else {
      const currentValue = this.value as T;
      const existingItem = this.items.find(item => this.compareItems(item, currentValue));
      this.value = existingItem || null;
    }
  }

  private compareItems(item1: T, item2: T): boolean {
    return item1?.[this.compareByProperty] === item2?.[this.compareByProperty];
  }

  private _filter(name: string): T[] {
    const filterValue = name.toLocaleLowerCase();

    if (name.trim().length === 0) {
      return this.items;
    }

    return this.items.filter(option => filterValue.split(' ').every(filterWord => this.displayWith(option).toLocaleLowerCase().includes(filterWord)));
  }

  public reset(): void {
    this.localFormControl.patchValue(this.value);
  }

  public clearSearch(): void {
    this.searchClear$.next('');
  }

  public isValid(): boolean {
    return this.control.valid;
  }

  public remove(item: T): void {
    const val = this.value as T[];
    this.value = val.filter(selectedItem => selectedItem !== item);
  }

  public itemDisabled(item: T): boolean {
    if (this.multiple) {
      return (this.value as T[]).findIndex(otherItem => this.compareItems(item, otherItem)) >= 0;
    }

    return this.compareItems(this.value as T, item);
  }
}
