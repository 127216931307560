export class ConcurrentUserActionData {
  constructor(public author: string, public type: ConcurrentUserActionType, public icon: string) { }
}

export enum ConcurrentUserActionType {
  Default,
  Create,
  Update,
  Delete
}
