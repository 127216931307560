import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IAvaiJob } from '../../dto/avai/avai.job';
import { IAvaiApolloStopResponse } from '../../dto/avai/avai-apollo-stop-response';
import { IAvaiJobChanged, IAvaiJobCreated } from '../../dto/avai/avai-job-changed';
import { jobChanged, jobCreated, startJob, stopJob } from '../queries/avai.queries';
import { IAvaiApolloStartResponse } from '../../dto/avai/avai-apollo-start-response';
import { IAvaiIssueData } from '@app/rostr/_shared/interfaces/avai-issue-report.interface';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AvaiService {
  constructor(private readonly apollo: Apollo, private readonly http: HttpClient) {}

  public reportAvaiIssue(scheduleId: number, requestBody: IAvaiIssueData): Observable<IAvaiIssueData> {
    const endpoint = `/api/rostr/${scheduleId}/reportIssue`;
    return this.http.post<IAvaiIssueData>(endpoint, requestBody);
  }

  public startJob(scheduleId: number, algorithm: string): Observable<IAvaiJob> {
    return this.apollo
      .mutate({
        mutation: startJob,
        variables: {
          schedule: scheduleId,
          algorithm: algorithm
        }
      })
      .pipe(map(response => (response.data as IAvaiApolloStartResponse).rostrStartAvaiJob));
  }

  public stopAvaiJob(scheduleId: number): Observable<IAvaiJob> {
    return this.apollo
      .mutate({
        mutation: stopJob,
        variables: {
          schedule: scheduleId
        }
      })
      .pipe(map(response => (response.data as IAvaiApolloStopResponse).rostrStopAvaiJob));
  }

  public watchForUpdatedJobs(targetId: number): Observable<IAvaiJob> {
    return this.apollo
      .subscribe({
        query: jobChanged,
        variables: {
          targetId: targetId,
          targetType: 'Schedule'
        }
      })
      .pipe(map(response => (response.data as IAvaiJobChanged).jobUpdated));
  }

  public watchForNewJobs(targetId: number): Observable<IAvaiJob> {
    return this.apollo
      .subscribe({
        query: jobCreated,
        variables: {
          targetId: targetId,
          targetType: 'Schedule'
        }
      })
      .pipe(map(response => (response.data as IAvaiJobCreated).jobCreated));
  }
}
