import { property as _property } from 'lodash-es';

export function smartSearch(items, searchStr, key) {
  searchStr = String(searchStr).replace(/[\\^$*+?.()|[\]{}]/g, '\\$&');
  const regex = new RegExp(searchStr, 'i');

  const resultSearch = [];
  const getValueByKeyFn = _property(key);

  items.forEach(item => {
    const itemValue = getValueByKeyFn(item) as string;
    if (itemValue && regex.test(itemValue)) {
      const index = itemValue.toLowerCase().indexOf(searchStr.toLowerCase());
      resultSearch.push({ target: item, index });
    }
  });
  return resultSearch.sort((a, b) => a.index - b.index).map(resultItem => resultItem.target);
}
