import { BaseModel } from '../base.model';

export class ConstellationTemplate extends BaseModel {
  public id: number;
  public name: string;
  public shiftCodes: string[];

  constructor(data: any = {}) {
    super(data);
    this.id = data.id;
    this.name = data.name;
    this.shiftCodes = data.shiftCodes;
  }
}
