import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { GroupModel } from '@models';
import { GroupService } from '@services';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GroupsResolver {
  constructor(private readonly groupsService: GroupService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<GroupModel[]> {
    return this.groupsService.loadGroups();
  }
}
