import { IShift } from '../shifts/base.shift';
import { IItemConfig, IShiftableItem } from './interfaces';
import { Timeline } from '../timeline';
import { Menu } from '../menu/menu';
import { Defs } from '../defs/defs';
import { ShiftsBasedItem } from './shifts-based.item';
import { IBaseGrid } from '../grid/base-grid.interface';
import * as moment from 'moment-mini';

export class SimpleItem extends ShiftsBasedItem implements IShiftableItem {

  constructor(protected _title = '',
              protected _shifts: any[] = [],
              protected _itemConfig: IItemConfig = { height: 40 }) {
    super(_title, _shifts, _itemConfig);
    this.shifts(_shifts);
  }

  /**
   * Subscribe to event emitters
   */
  public subscribe() {

    this._grid.onManualRender.subscribe(() => {
      this.sortAssignedShifts();
    });

    this._shifts.forEach((shift: IShift) => {
      shift.subscribe();
    });
  }

  /****************************************** Some main functionality ***************************************/
  /**
   * Setter for grid property
   * @param grid
   * @returns {Baseitem}
   */
  public grid(grid: IBaseGrid): this {
    super.grid(grid);
    this._shifts.forEach((shift) => {
      shift.grid(grid);
    });
    return this;
  }

  /**
   * Setter for timeline property
   * @param timeline
   * @returns {Baseitem}
   */
  public timeline(timeline: Timeline): this {
    super.timeline(timeline);
    this._shifts.forEach((shift) => {
      shift.timeline(timeline);
    });
    return this;
  }

  /**
   * Menu setter
   * @param menu
   * @returns {Baseitem}
   */
  public menu(menu: Menu): this {
    super.menu(menu);
    this._shifts.forEach((shift) => {
      shift.menu(menu);
    });
    return this;
  }

  /**
   * Defs setter
   * @param defs
   * @returns {BaseItem}
   */
  public defs(defs: Defs): this {
    this._defs = defs;
    this._shifts.forEach((shift) => {
      shift.defs(defs);
    });
    return this;
  }
  /******************************* Shifts ************************************/
  /**
   * Getter and setter for shifts property
   * @param shifts
   * @returns {any}
   */
  public shifts(shifts?: IShift[]): this | IShift[] {
    if (!arguments.length) {
      return this._shifts;
    }
    this._shifts = shifts;
    shifts.forEach((shift: IShift) => {
      shift.item(this);
    });
    return this;
  }

  public findShift(dateFrom: Date, dateTo: Date) : IShift {
    return this._shifts.find(shift => moment.utc(shift.dateFrom()).isSame(dateFrom) && moment.utc(shift.dateTo()).isSame(dateTo));
  }

  /**
   * Add shift to item shifts
   * @param shift
   * @returns {OpenableItem}
   */
  public addShift(shift: IShift) {
    this.insertInOrderShift(shift);
    // if (this._shifts.indexOf(shift) === -1) {
    //   this._shifts.push(shift);
    // }
    // this._shifts.sort((a: IShift, b: IShift) => {
    //   return a.dateFrom().getTime() - b.dateFrom().getTime();
    // });
    return this;
  }

  /**
   * Add shift to item shifts
   * @param shift
   * @returns {OpenableItem}
   */
  public asyncAddShift(shift: IShift) {
    shift.item(this);
    shift.timeline(this._timeline);
    shift.defs(this._defs);
    shift.grid(this._grid);
    shift.menu(this._menu);
    shift.subscribe();

    if (this._shifts.indexOf(shift) === -1) {
      this.insertInOrderShift(shift);
    }

    this._grid.assignToAvailableInterval(shift);

    // this._shifts.sort((a: IShift, b: IShift) => {
    //   return a.dateFrom().getTime() - b.dateFrom().getTime();
    // });

    return this;
  }

  /**
   * Remove shift
   * @param shift
   * @returns {OpenableItem}
   */
  public removeShift(shift: IShift) {
    const index = this._shifts.indexOf(shift);
    if (index > -1) {
      this._shifts.splice(index, 1);
    }
    return this;
  }

  /**
   * Y position for shift
   * @param shift
   */
  public shiftPosition(shift: IShift): number {
    return this.y();
  }

  /*******************************************************************/
  /**
   * Remove functionality
   */
  public remove(dropShifts = true) {
    if (dropShifts) {
      this._shifts.forEach((shift) => {
        shift.remove();
      });
    }
    super.remove();
  }

  public renderBackground() {
    super.renderBackground();
    // this.$itemBackgroundsContainer.attr('fill', this.isOdd() ? '#d5d7da' : '#dcdee2');
  }

  // FIXME should be moved (repeats in each item)
  protected sortAssignedShifts() {
    this._shifts.sort((a: IShift, b: IShift) => {
      return a.dateFrom().getTime() - b.dateFrom().getTime();
    });
  }

}
