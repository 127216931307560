import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ElementRef,
  ViewChild,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  AfterViewInit
} from '@angular/core';
import { DATE_FORMAT_HUMAN_READABLE } from '@helpers';
import moment from 'moment-mini';
import { CommentType, ConversationPanelComment, CreateCommentPayload, DeleteCommentPayload, UpdateCommentPayload } from './models';
import { AuthenticationService } from '@app/_auth/services/authentication.service';

@Component({
  selector: 'sh-conversation-panel',
  templateUrl: './conversation-panel.component.html',
  styleUrls: ['./conversation-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConversationPanelComponent implements OnInit, OnChanges, AfterViewInit {
  // comment timestamp in YYYY-MM-DD format
  @Input()
  timestamp: string;

  @Input()
  employeeId?: number;

  @Input()
  scheduleId: number;

  // a list of comments to display
  private _comments: ConversationPanelComment[] = [];

  @Input()
  set comments(value: ConversationPanelComment[]) {
    this._comments = value;
    this.markUnreadOnesAsRead();
  }

  get comments(): ConversationPanelComment[] {
    return this._comments;
  }

  // notifies when comments are read
  @Output()
  commentsRead = new EventEmitter();

  // notifies when comment is updated
  @Output()
  commentUpdated = new EventEmitter();

  // notifies when comment is deleted
  @Output()
  commentDeleted = new EventEmitter();

  // notifies when comment is created
  @Output()
  commentCreated = new EventEmitter();

  @ViewChild('commentsScroll') private readonly commentsScroll: ElementRef;

  commentText = '';
  activeTab: string;
  commentBeingEditedId: number;

  userId: number;
  currentUserId: number;
  updatedText: string;
  confirmDeleteId: number;

  constructor(private readonly authService: AuthenticationService) {}

  ngAfterViewInit(): void {
    this.scrollToBottom();
  }

  public ngOnInit(): void {
    this.userId = this.employeeId || this.authService.user.id;
    this.currentUserId = this.authService.user.id;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.comments.previousValue?.length < changes.comments.currentValue?.length) {
      this.scrollToBottom();
    }
  }

  public isNotReadAndNotOwn(comment: ConversationPanelComment): boolean {
    return !comment.isRead && comment.authorId !== this.currentUserId;
  }

  public createComment(): void {
    if (!this.commentText) {
      return;
    }

    const payload = new CreateCommentPayload(this.timestamp, this.userId, this.commentText, this.scheduleId, CommentType.VACAY);
    this.commentCreated.emit(payload);

    this.commentText = '';
  }

  public promptDelete(id: number): void {
    this.confirmDeleteId = id;
    this.cancelChanges();
  }

  public cancelDelete(): void {
    this.confirmDeleteId = null;
  }

  public deleteComment(id: number): void {
    const payload = new DeleteCommentPayload(id);
    this.commentDeleted.emit(payload);
    this.confirmDeleteId = null;
  }

  public startEditing(comment: ConversationPanelComment): void {
    this.updatedText = comment.message;
    this.commentBeingEditedId = comment.id;
    this.cancelDelete();
  }

  public cancelChanges(): void {
    this.commentBeingEditedId = null;
  }

  public acceptChanges(comment: ConversationPanelComment): void {
    this.commentBeingEditedId = null;

    if (comment.message === this.updatedText) {
      return;
    }

    const payload = new UpdateCommentPayload(comment.id, comment.timestamp, this.userId, this.updatedText, this.scheduleId, comment.type);

    this.commentUpdated.emit(payload);
  }

  public toHumanReadableDate(date: string): string {
    return moment(date).format(DATE_FORMAT_HUMAN_READABLE);
  }

  private markUnreadOnesAsRead(): void {
    const entries = this.comments || [];

    const entriesToMark = entries.filter(c => this.isNotReadAndNotOwn(c));

    if (entriesToMark.length) {
      const ids = entriesToMark.map(entry => entry.id);

      this.commentsRead.emit({
        employee: this.userId,
        timestamp: this.timestamp,
        comments: ids
      });
    }
  }

  private scrollToBottom() {
    setTimeout(() => {
      if (this.commentsScroll?.nativeElement) {
        this.commentsScroll.nativeElement.scrollTop = this.commentsScroll.nativeElement.scrollHeight;
      }
    }, 0);
  }
}
