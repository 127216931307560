import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ConfigService } from '@app/_core/config.service';
import { AUTH_API } from '@helpers';
import { AppInsightsService } from '@services';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private readonly configService: ConfigService,
    private readonly appInsightsService: AppInsightsService
    ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => this.handleAuthError(err))
    );
  }

  private handleAuthError(err: HttpErrorResponse): Observable<HttpEvent<any>> {
    if (err.status === 401) {
      this.appInsightsService.clearUserId();
      window.location.href = `${this.configService.config.rootApiDomain}${AUTH_API.LOGIN}?rel=${window.location.toString()}`;

      return EMPTY;
    }
    return throwError(err);
  }
}
