import { OnInit, ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { HistoryFacade } from '../../facades/history.facade';
import { ScheduleHistoryEntry, ScheduleHistoryEntryAction } from '../../dto';
import _ from 'lodash';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-history-sidebar-widget',
  templateUrl: './history-sidebar-widget.component.html',
  styleUrls: ['./history-sidebar-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HistorySidebarWidgetComponent implements OnInit, OnDestroy {
  routeSub: Subscription;
  history$: Observable<{ batch: number; entries: ScheduleHistoryEntry[] }[]>;

  public readonly isHistoryLoading$: Observable<boolean> = this.historyFacade.isHistoryLoading$;

  // In every widget, constructors should not have complex dependencies as long
  // these would be loaded hierarchically from all modules that have widgets.
  // Copy this comment to every new widget
  constructor(
    private readonly historyFacade: HistoryFacade,
    private readonly route: ActivatedRoute
  ) {}

  public ngOnInit(): void {
    this.routeSub = this.route.params.subscribe(paramMap => {
      const scheduleId = paramMap.id === 'undefined' ? null : Number(paramMap.id);

      if (scheduleId) {
        this.history$ = this.historyFacade.historyEntries$.pipe(map(this.groupEntriesByBatch()));
      } else {
        this.history$ = null;
      }
    });
  }

  public ngOnDestroy(): void {
    this.routeSub?.unsubscribe();
  }

  public getIcon(entry: ScheduleHistoryEntry) {
    switch (entry.action) {
      case ScheduleHistoryEntryAction.ASSIGN:
        return 'note_add';
      case ScheduleHistoryEntryAction.UNASSIGN:
        return 'cancel_presentation';
      case ScheduleHistoryEntryAction.MOVE:
        return 'import_export';
      case ScheduleHistoryEntryAction.REPLACE:
        return 'shuffle';
      case ScheduleHistoryEntryAction.SWAP:
        return 'repeat';
      case ScheduleHistoryEntryAction.LOCK:
        return 'lock';
      case ScheduleHistoryEntryAction.UNLOCK:
        return 'lock_open';
      default:
        return '';
    }
  }

  public getMessage(entry: ScheduleHistoryEntry) {
    const author = `${entry.createdBy.firstName} ${entry.createdBy.lastName}`;

    switch (entry.action) {
      case ScheduleHistoryEntryAction.ASSIGN:
        return `${author} assigned shift`;
      case ScheduleHistoryEntryAction.UNASSIGN:
        return `${author} unassigned shift`;
      case ScheduleHistoryEntryAction.MOVE:
        return `${author} moved shift`;
      case ScheduleHistoryEntryAction.REPLACE:
        return `${author} replaced shift`;
      case ScheduleHistoryEntryAction.SWAP:
        return `${author} swapped shifts`;
      case ScheduleHistoryEntryAction.LOCK:
        return `${author} locked shift`;
      case ScheduleHistoryEntryAction.UNLOCK:
        return `${author} unlocked shift`;
      default:
        return '';
    }
  }

  public applyHistoryBatch(batch: number) {
    this.historyFacade.applyHistoryBatch(batch);
  }

  private groupEntriesByBatch(): (value: ScheduleHistoryEntry[], index: number) => { batch: number; entries: ScheduleHistoryEntry[] }[] {
    return x =>
      _(x)
        .groupBy(e => e.batch)
        .map((entries, batch) => this.mapEntries(entries, batch))
        .value();
  }

  private mapEntries(entries: ScheduleHistoryEntry[], batch: string) {
    const sorted = entries.sort((a, b) => a.id - b.id);
    const withFlattenedReplace = sorted.some(e => e.action === ScheduleHistoryEntryAction.REPLACE)
      ? sorted.filter(e => e.action === ScheduleHistoryEntryAction.REPLACE)
      : sorted;

    return {
      batch: +batch,
      entries: withFlattenedReplace
    };
  }
}
