import { gql } from 'apollo-angular';

export const upsertAssignedActivity = gql`
  mutation rostrUpsertAssignedActivity(
    $id: Int!
    $employee: Int!
    $parentType: String
    $parentId: Int
    $code: String!
    $dateFrom: String!
    $dateTo: String!
    $schedule: Int!
    $data: JSON
  ) {
    rostrUpsertAssignedActivity(
      id: $id
      schedule: $schedule
      employee: $employee
      parentType: $parentType
      parentId: $parentId
      code: $code
      dateFrom: $dateFrom
      dateTo: $dateTo
      data: $data
    ) {
      id
    }
  }
`;

export const createAssignedActivities = gql`
  mutation rostrCreateAssignedActivities($input: [AssignedActivityInput]) {
    rostrCreateAssignedActivities(input: $input, overrideLocked: true) {
      id
    }
  }
`;

export const updateAssignedActivities = gql`
  mutation rostrUpdateAssignedActivities($input: [AssignedActivityInput]) {
    rostrUpdateAssignedActivities(input: $input) {
      id
    }
  }
`;

export const deleteAssignedActivities = gql`
  mutation rostrDeleteAssignedActivities($input: [Int]) {
    rostrDeleteAssignedActivities(input: $input) {
      id
    }
  }
`;
