import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-mini';

@Pipe({name: 'humanizeDuration'})

export class HumanizeDurationPipe implements PipeTransform {
  transform(value: number) {
    const duration = moment.duration(value, 'minutes');
    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes().toString().padStart(2, '0');
    return `${hours}h ${minutes}m`;
  }
}
