import { BaseModel } from '../base.model';
import { MasterplanV2Model } from './masterplan.model';
import * as moment from 'moment-mini';

export class MasterplanEmployee extends BaseModel {
  public id: number;
  public masterplan: MasterplanV2Model;
  public startWeek: number;
  public dateFrom: Date;
  public dateFromMoment: moment.Moment;

  constructor(data: any = {}) {
    super(data);

    this.id = data?.id;
    this.masterplan = data.masterplan && new MasterplanV2Model(data.masterplan);
    this.startWeek = data.startWeek;
    this.dateFrom = data.dateFrom && new Date(data.dateFrom);
    this.dateFromMoment = moment(this.dateFrom).utc();
  }
}
