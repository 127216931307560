import * as moment from 'moment-mini';
import { PartialActivityType } from '@shared/types';
import { IPartialActivityAssignment } from '@interfaces';

export class PartialActivityModel {

  public start: number;
  public duration: number;
  public description: string;
  public type: PartialActivityType;
  public isLocked: boolean;

  public assignment: IPartialActivityAssignment;

  protected _dateFrom: moment.Moment;
  protected _dateTo: moment.Moment;

  constructor(data: any = {}, dateFrom: moment.Moment) {
    this.start = data.start || 0;
    this.duration = data.duration || 0;
    this.description = data.description || '';
    this.type = data.type || void 0;
    this.isLocked = data.isLocked;

    this.assignment = data.assignment || void 0;

    this._dateFrom = dateFrom.clone().add(this.start, 'minutes');
    this._dateTo = this._dateFrom.clone().add(this.duration, 'minutes');
  }

  get dateFrom() {
    return this._dateFrom;
  }

  get dateTo() {
    return this._dateTo;
  }

  get hasAssignment() {
    return !!this.assignment;
  }

  get isBreak() {
    if (!this.type) {
      console.warn('Type not found');
      return;
    }

    return this.type === 'break';
  }

  public toJSON() {
    return {
      start: this.start,
      duration: this.duration,
      description: this.description,
      assignment: this.assignment,
      type: this.type,
      isLocked: this.isLocked,
    };
  }

}
