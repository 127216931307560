import { NgModule, APP_INITIALIZER } from '@angular/core';
import { CookieService, CookieModule } from 'ngx-cookie';
import { MatDialogModule } from '@angular/material/dialog';
import { ConfigService } from './config.service';

@NgModule({
  imports: [CookieModule.forRoot()],
  exports: [],
  providers: [
    ConfigService,
    CookieService,
    MatDialogModule,
    {
      provide: APP_INITIALIZER,
      useFactory: configFactory,
      deps: [ConfigService],
      multi: true
    }
  ]
})
export class CoreModule {}

export function configFactory(config: ConfigService) {
  return () => config.load.apply(config);
}
