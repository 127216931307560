import { Injectable, EventEmitter } from '@angular/core';

@Injectable()
export class NavigationService {
  public onMenuNext: EventEmitter<any> = new EventEmitter();
  public onMenuPrev: EventEmitter<any> = new EventEmitter();
  public onMenuSelect: EventEmitter<any> = new EventEmitter();

  public onMenuValue: EventEmitter<any> = new EventEmitter();

  public menuValue(value: any) {
    this.onMenuValue.next(value);
  }

  public menuNext(value: any) {
    this.onMenuNext.next(value);
  }

  public menuPrev(value: any) {
    this.onMenuPrev.next(value);
  }

  public menuSelect(value: any) {
    this.onMenuSelect.next(value);
  }
}
