import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { ErrorService } from '@services';
import { agendaRequestWithShiftDetails, requiredShiftWithSkills } from '../queries/shift-details.queries';
import { AgendaRequestDetailsResult, RequiredShiftResult } from '../../dto';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ShiftDetailsService {
  constructor(
    private readonly apollo: Apollo,
    private readonly errorService: ErrorService
  ) {}

  public getAgendaRequestWithShiftDetails(requestId: number): Observable<AgendaRequestDetailsResult> {
    return this.apollo
      .query<{ agendaBaselineRequest: AgendaRequestDetailsResult }>({
        query: agendaRequestWithShiftDetails,
        variables: {
          id: requestId
        }
      })
      .pipe(
        map(response => {
          return response.data.agendaBaselineRequest;
        }),
        catchError(err => {
          return this.errorService.handle(err);
        })
      );
  }

  public getRequiredShiftDetails(requiredShiftId: number): Observable<RequiredShiftResult> {
    return this.apollo
      .query<{ requiredShift: RequiredShiftResult }>({
        query: requiredShiftWithSkills,
        variables: {
          id: requiredShiftId
        }
      })
      .pipe(
        map(response => {
          return new RequiredShiftResult(response.data.requiredShift);
        }),
        catchError(err => {
          return this.errorService.handle(err);
        })
      );
  }
}
