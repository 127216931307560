import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EmployeeDataService } from '@app/rostr/overview/api/services/employee-data.service';
import { ScheduleValidation } from '@app/rostr/overview/dto/schedule-validation';
import { EmployeeDataWithPreferencesModel } from '@app/rostr/overview/dto/employee-data-with-preferences.model';
import { ValidationsFacade } from '@app/rostr/overview/facades/validations.facade';
import { getSeverityColor, getSeverityIcon } from '@shared/helpers/validations-helper';
import { Observable, Subscription } from 'rxjs';
import { RostrFacade } from '@app/rostr/overview/facades/rostr.facade';
import { first, shareReplay } from 'rxjs/operators';
import * as moment from 'moment-mini';
import { StatisticsFacade } from '@app/rostr/overview/facades/statistics.facade';
import { ScheduleStatistic } from '@app/rostr/overview/dto/schedule-statistic';

@Component({
  selector: 'app-employee-sidebar-widget',
  templateUrl: './employee-sidebar-widget.component.html',
  styleUrls: ['./employee-sidebar-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmployeeSidebarWidgetComponent implements OnInit, OnDestroy {
  routeSub: Subscription;
  validations$: Observable<ScheduleValidation[]>;
  statistics$: Observable<ScheduleStatistic>;
  data: EmployeeDataWithPreferencesModel;
  loading = true;

  public getSeverityColor = getSeverityColor;
  public getSeverityIcon = getSeverityIcon;

  // In every widget, constructors should not have complex dependencies as long
  // these would be loaded hierarchically from all modules that have widgets.
  // Copy this comment to every new widget
  constructor(
    private readonly employeeDataService: EmployeeDataService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly validationsFacade: ValidationsFacade,
    private readonly statisticsFacade: StatisticsFacade,
    private readonly rostrFacade: RostrFacade,
    private readonly route: ActivatedRoute
  ) {}

  public ngOnInit(): void {
    this.routeSub = this.route.params.subscribe(paramMap => {
      const id = Number(paramMap.id);
      const dateFrom = paramMap.dateFrom;
      const dateTo = paramMap.dateTo;
      this.loadEmployeeData(id, dateFrom, dateTo);

      this.validations$ = this.validationsFacade.getFilteredValidationsByEmployee$(id).pipe(shareReplay({ bufferSize: 1, refCount: true }));
      this.statistics$ = this.statisticsFacade.getStatisticsByEmployee$(id).pipe(shareReplay({ bufferSize: 1, refCount: true }));

      this.changeDetector.markForCheck();
    });
  }

  public ngOnDestroy(): void {
    this.routeSub?.unsubscribe();
  }

  public toggleValidation(validation: ScheduleValidation): void {
    this.rostrFacade.schedule$.pipe(first()).subscribe(schedule => {
      this.validationsFacade.toggleValidation(validation.checksum, schedule.id).pipe(first()).subscribe();
    });
  }

  public editableGroups = () => this.data.groups.filter(x => !x.validTo || moment(x.validTo).isSameOrAfter(moment.utc()));

  private loadEmployeeData(employeeId: number, dateFrom: string, dateTo: string) {
    this.loading = true;
    this.employeeDataService.getEmployeeDataWithPreferences(employeeId, dateFrom, dateTo).subscribe(dataResult => {
      this.data = dataResult;
      this.loading = false;
      this.changeDetector.markForCheck();
    });
  }
}
