import { Component, Inject, OnInit } from '@angular/core';

import { ISimpleTooltipConfig, TooltipConfig } from './tooltip.config';

/**
 * @Component({
 *   selector: 'test-component',
 *   templateUrl: 'test-component.html',
 * })
 * export class TestComponent {
 *
 *   @ViewChild('button') button: ElementRef;
 *
 *   constructor(private _tooltipService: TooltipService) {}
 *
 *   public openTooltip() {
 *     this._tooltipService.openTooltip({
 *       targetElement: this.button.nativeElement.getBoundingClientRect(),
 *       placement: 'bottom',
 *       title: 'Header',
 *       message: 'Message',
 *     })
 *   }
 *
 * }
 */
@Component({
  selector: 'pl-tooltip-simple',
  template: `
    <div class="tooltip-header" *ngIf="!!title">{{title}}</div>
    <div class="tooltip-content">{{message}}</div>
  `,
})
export class TooltipSimpleComponent implements OnInit {

  public title: string;
  public message: string;

  constructor(@Inject(TooltipConfig) private _config: ISimpleTooltipConfig) {}

  public ngOnInit() {
    this.title = this._config.title;
    this.message = this._config.message;
  }

}
