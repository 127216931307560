import { gql } from 'apollo-angular';

export const myPreferences = gql`
  query myPreferences {
    myPreferences {
      id
      afterShiftBreak
      startWorktimeMin
      startWorktimeMax
    }
  }
`;

export const updateMyPreferencesMutation = gql`
  mutation updateMyPreferences($id: Int!, $startWorktimeMin: Int!, $startWorktimeMax: Int!, $afterShiftBreak: Int!) {
    updateMyPreferences(id: $id, startWorktimeMin: $startWorktimeMin, startWorktimeMax: $startWorktimeMax, afterShiftBreak: $afterShiftBreak) {
      id
      afterShiftBreak
      startWorktimeMin
      startWorktimeMax
    }
  }
`;
