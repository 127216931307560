import { ScheduleStatisticLevel } from '@enums';

export interface ScheduleStatistic {
  level: ScheduleStatisticLevel;
  week?: number;
  day?: string;
  employee?: number;
  shift?: string;
  values: { [key: string]: number };
}

export enum StatisticName {
  AgendaAssigned = 'agenda_assigned',
  AgendaPending = 'agenda_pending',
  AgendaUnassigned = 'agenda_unassigned',
  Day = 'd',
  Evening = 'e',
  Morning = 'm',
  Night = 'n',
  Off = 'off',
  TotalWorkTime = 'total_work_time',
  VacayAssigned = 'vacay_assigned',
  VacayDeclined = 'vacay_declined',
  VacayPending = 'vacay_pending',
  VacayUnassigned = 'vacay_unassigned',
  TimeWorked = 'time_worked',
  MaxTimeWorked = 'max_time_worked',
  Duration = 'duration',
  DurationMax = 'duration_user_preference_max',
  DurationMin = 'duration_user_preference_min',
  RequiredTimeOffAfter = 'required_time_off_after',
  RequiredTimeOffBefore = 'required_time_off_before',
  TimeOffAfter = 'time_off_after',
  TimeOffBefore = 'time_off_before'
}
