import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { mergeMap, takeUntil, tap } from 'rxjs/operators';
import { Schedule, ScheduleDay } from '@app/vacay/interfaces';
import { ScheduleState } from '@model/schedules/schedule-state.model';
import { DayWidgetService } from '../../services/day-widget.service';

@Component({
  selector: 'vc-day-widget',
  templateUrl: './day-widget.component.html',
  styleUrls: ['./day-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DayWidgetComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  scheduleId: null | number = null;

  day: null | string = null;

  scheduleDay: null | ScheduleDay = null;

  schedule: Schedule | null = null;

  loading = true;

  public shiftInfoVisible = false;

  ScheduleState = ScheduleState;

  constructor(
    private readonly dayWidgetService: DayWidgetService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly activeRoute: ActivatedRoute
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit() {
    this.activeRoute.params
      .pipe(
        takeUntil(this.destroy$),
        tap(params => {
          if (params.schedule && params.day) {
            this.scheduleId = +params.schedule;
            this.day = params.day;
          }
        }),
        mergeMap(() =>
          this.dayWidgetService.schedule$(this.scheduleId).pipe(
            tap(schedule => {
              this.schedule = schedule;
              this.shiftInfoVisible = this.schedule && (this.schedule.state === ScheduleState.DRAFT || this.schedule.state === ScheduleState.DISTRIBUTED);
            })
          )
        ),
        mergeMap(() =>
          this.dayWidgetService.scheduleDay$(this.scheduleId, this.day).pipe(
            tap(day => {
              this.scheduleDay = day;
            })
          )
        )
      )
      .subscribe(() => {
        if (this.scheduleDay && this.schedule) {
          this.loading = false;
        }
        this.changeDetector.markForCheck();
      });

    this.dayWidgetService.commentsChanges$.pipe(takeUntil(this.destroy$)).subscribe(() => this.changeDetector.markForCheck());
  }

  public createComment(event): void {
    this.dayWidgetService
      .createComment(event)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.changeDetector.markForCheck();
      });
  }

  public updateComment(event): void {
    this.dayWidgetService
      .updateComment(event)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.changeDetector.markForCheck();
      });
  }

  public deleteComment(event): void {
    this.dayWidgetService
      .deleteComment(event)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.changeDetector.markForCheck();
      });
  }

  public markCommentsAsRead(event: { employee: number; timestamp: string; comments: number[] }): void {
    this.dayWidgetService
      .markCommentsAsRead(event.comments)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.scheduleDay.commentList.setAllRead();
        this.changeDetector.markForCheck();
      });
  }
}
