import * as d3 from 'd3';
import { merge } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';


import { translate } from '@helpers';

import { Tab } from './tab';
import { BasePartialHeader } from '../base-partial.header';
import { IBaseTabsHeader } from './base-tabs-header.interface';

export class TabsHeader extends BasePartialHeader implements IBaseTabsHeader {

  get height() {
    return 39;
  }

  get tabs() {
    return this._tabs;
  }

  get activeTab() {
    const activeTabIndex = Math.floor(this.tabs.length / 2);
    return this.tabs[activeTabIndex];
  }

  public readonly properties = {
    margin: 115,
    tabWidth: 300,
    baseTabWidth: 250,
    countOutsideTabs: 1,
    countVisibleTabs: 0,
  };

  protected $tabs: d3.Selection<SVGGElement, any, any, any>;
  protected $backgrounds: d3.Selection<SVGGElement, any, any, any>;

  protected _tabs: Tab[];

  public init() {
    const { countVisibleTabs } = this.initProperties();

    this._tabs = Array(countVisibleTabs)
      .fill(void 0)
      .map(() => new Tab(this));

    this.tabs.forEach(tab => tab.init());
  }

  public render() {
    const tabsOffset = this.calculateTabsOffset();
    const gridOffset = this.grid.offset();

    this.$tabs.attr('transform', translate(tabsOffset, 0));
    this.$backgrounds.attr('transform', translate(gridOffset, 0));

    const activeTab = this.activeTab;
    const activeTabIndex = activeTab.index;

    const leftPartTabs = this.tabs.slice(0, activeTabIndex);
    const rightPartTabs = this.tabs.slice(-activeTabIndex).reverse();

    leftPartTabs.forEach((tab) => {
      tab.render();
      this.$tabs.node().appendChild(tab.node);
      this.$backgrounds.node().appendChild(tab.backgroundNode);
    });
    rightPartTabs.forEach((tab) => {
      tab.render();
      this.$tabs.node().appendChild(tab.node);
      this.$backgrounds.node().appendChild(tab.backgroundNode);
    });

    activeTab.render();
    this.$tabs.node().appendChild(activeTab.node);
    this.$backgrounds.node().appendChild(activeTab.backgroundNode);
  }

  public subscribe() {
    merge(this.grid.onDragMove, this.grid.onDragEnd)
      .pipe(takeUntil(this.timeline.onDestroy))
      .subscribe(() => this.move());

    this.timeline
      .onWindowResize
      .pipe(
        takeUntil(this.timeline.onDestroy),
        debounceTime(300)
      )
      .subscribe(() => this.resize());

    this.grid.onZoom.subscribe(() => this.render());

    this.tabs.forEach(tab => tab.subscribe());
  }

  protected initElements() {
    super.initElements();

    this.$el.classed('tabs-header', true);

    this.$tabs = this.$el.append<SVGGElement>('g').classed('tab-items', true);
    this.$backgrounds = this.$el.append<SVGGElement>('g').classed('tab-backgrounds', true);
  }

  protected initProperties() {
    const properties = this.properties;

    properties.countOutsideTabs = 4;
    properties.countVisibleTabs = this.calculateCountVisibleTabs();
    properties.tabWidth = this.calculateTabWidth(properties.countVisibleTabs);

    return properties;
  }

  protected resize() {
    this.$backgrounds.selectAll('*').remove();
    this.$tabs.selectAll('*').remove();

    this.init();
    this.render();

    this.tabs.forEach(tab => tab.subscribe());
  }

  protected move() {
    const offset = this.calculateTabsOffset();
    this.$tabs.attr('transform', translate(offset, 0));
  }

  protected calculateTabsOffset() {
    const { margin, tabWidth, countOutsideTabs } = this.properties;
    const halfCountOutsideTabs = countOutsideTabs / 2;
    let offset = margin - (tabWidth * halfCountOutsideTabs);

    const activeTab = this.activeTab;

    const sizes = this.timeline.sizes();
    const timelineWidth = sizes.width;
    const timelineHalfWidth = timelineWidth / 2;

    const halfTabWidth = tabWidth / 2;

    const leftSide = timelineHalfWidth - halfTabWidth;
    const rightSide = timelineHalfWidth + halfTabWidth;

    const sInterval = activeTab.startInterval;
    const eInterval = activeTab.endInterval;

    const intervalLeftSide = sInterval && sInterval.rightSide;
    const intervalRightSide = eInterval && eInterval.leftSide - eInterval.width;

    if (intervalLeftSide >= leftSide) {
      offset += sInterval.rightSide - leftSide;
    }
    else if (intervalRightSide <= rightSide) {
      offset -= rightSide - intervalRightSide;
    }

    return offset;
  }

  private calculateCountVisibleTabs() {
    const sizes = this.timeline.sizes();
    const { margin, baseTabWidth } = this.properties;
    const innerWidth = sizes.width - (margin * 2);

    let count = Math.floor(innerWidth / baseTabWidth);

    if (count % 2 === 0) {
      count -= 1;
    }

    if (count < 1) {
      count = 1;
    }

    return count + this.properties.countOutsideTabs;
  }

  private calculateTabWidth(countVisibleTabs: number) {
    countVisibleTabs -= this.properties.countOutsideTabs;

    const sizes = this.timeline.sizes();
    const { margin } = this.properties;
    const innerWidth = sizes.width - (margin * 2);

    return innerWidth / countVisibleTabs;
  }

}
