import { Apollo } from 'apollo-angular';
import { ApolloQueryResult } from '@apollo/client/core';
import { Injectable } from '@angular/core';
import { map, tap, catchError } from 'rxjs/operators';
import { EmployeeLicenseMedicalModel } from '@shared/models/';
import { loadQuery, sendEmailCommand } from '../queries/medical.queries';
import { MEDICAL_LICENSE_NAME } from '../const/medical.const';
import { ErrorService } from '@shared/services';

@Injectable()
export class MedicalService {
  public data: EmployeeLicenseMedicalModel[] = [];

  constructor(private apollo: Apollo, private errorService: ErrorService) {}

  public load() {
    return this.apollo.query<any>({ query: loadQuery }).pipe(
      tap(response => {
        this.data = response.data.employeeLicenses
          .filter(em => em.license && em.license.name.toUpperCase() === MEDICAL_LICENSE_NAME)
          .map(med => new EmployeeLicenseMedicalModel(med));
      }),
      map(() => this.data),
      catchError(err => {
        return this.errorService.handle(err);
      })
    );
  }

  public sendEmail(employeeId: number) {
    return this.apollo
      .mutate({
        mutation: sendEmailCommand,
        variables: {
          employeeId
        }
      })
      .subscribe((response: ApolloQueryResult<any>) => response),
      catchError(err => {
        return this.errorService.handle(err);
      });
  }
}
