<div class="widget" [class.editable]="isEditMode()">
  <div class="header" [class.minimized]="checkState('minimize')">
    <p>
      <span class="header-text" *ngIf="heading">{{ heading }}</span>
    </p>

    <div class="single-menu" *ngIf="!isEditMode()">
      <a #actionMenu class="menu-btn">
        <span class="circle idle">
          <span *ngIf="checkState('menu')" (click)="edit()">
            <span class="material-icons edit-content">edit</span>
          </span>
        </span>
      </a>
    </div>
  </div>
  <div class="widget-content">
    <ng-content></ng-content>
  </div>
  <div class="widget-footer" *ngIf="isEditMode()">
    <div class="row justify-end mr-1">
      <div class="col-auto">
        <button mat-flat-button (click)="cancel()" class="cancel-button">Cancel</button>
        <button mat-flat-button (click)="save()" color="primary" [disabled]="disableSaveBtn" class="primary-button">Save</button>
      </div>
    </div>
  </div>
</div>
