import * as d3 from 'd3';

import { translate, xmlns } from '@helpers';

import { Output, Directive } from '@angular/core';
import { IElement, IBaseTimeline } from '../utils/interfaces';
import { IBaseMenu } from '../menu/base-menu.interface';
import { Droppable } from './droppable';
import { IBaseGrid } from '../grid/base-grid.interface';
import { IArea } from '@src/app/_shared/interfaces/area.interface';

@Directive()
export class Draggable implements IElement {
  @Output() _width = 0;

  private el: Element;
  private $el: d3.Selection<any, any, any, any>;
  private _content: Node;

  private _offsetX = 0;
  private _offsetY = 0;
  private _height = 0;

  private _minX: number;
  private _minY: number;

  private _maxX: number;
  private _maxY: number;

  private _x = 0;
  private _y = 0;

  private _droppable: Droppable;

  constructor(protected _timeline: IBaseTimeline, protected _grid: IBaseGrid, protected _menu: IBaseMenu) {
    this.el = document.createElementNS(xmlns, 'g');
    this.$el = d3.select(this.el).classed('draggable', true);
    this._droppable = new Droppable(_timeline, _grid, _menu);
    this.hide();
  }

  get droppable() {
    return this._droppable;
  }

  /**
   * Rendering functionality
   */
  public render() {
    this.$el.selectAll('*').remove();
    this.$el.attr('transform', translate(this._x, this._y));
    this.el.appendChild(this._content);
    this.initDroppable();
    return this.el;
  }

  /**
   * Content to display
   * @param content
   * @returns {Draggable}
   */
  public content(content: Node) {
    this._content = content;
    return this;
  }

  /************************************ Engine ***********************************************************************/
  /**
   * Set all coordinates to zero
   */
  public clear() {
    this._x = 0;
    this._y = 0;
    this._minX = 0;
    this._maxX = 0;
    this._minY = 0;
    this._maxY = 0;
    this._offsetX = 0;
    this._offsetY = 0;
  }

  /**
   *
   * @returns {Draggable}
   */
  public x(): number {
    return this._x + this._offsetX;
  }

  /**
   *  Return Y without offset
   * @returns {Draggable}
   */
  public y(): number {
    return this._y + this._offsetY;
  }

  /**
   * Start coordinates
   * @param x
   * @param y
   */
  public coords(x: number, y: number) {
    this._x = x;
    this._y = y;
    return this;
  }

  /**
   * Offset coordinates
   * it will be zore point for draggable
   * @param x
   * @param y
   */
  public offset(x: number, y: number) {
    this._offsetX = x;
    this._offsetY = y;
    return this;
  }

  public sizes(width: number, height: number) {
    this._width = width;
    this._height = height;
  }

  /**
   * Lock draggable for some area
   * @param x1
   * @param y1
   * @param x2
   * @param y2
   * @returns {Draggable}
   */
  public area(area: IArea) {
    this._minX = this._offsetX + area.left;
    this._minY = this._offsetY + area.top;
    this._maxX = area.right;
    this._maxY = this._offsetY + area.bottom;
    return this;
  }

  /**
   * Move x only
   * @param dx
   */
  public moveX(dx: number) {
    let x = this._x + dx;
    if (x > this._maxX) {
      x = this._maxX;
    }
    if (x < this._minX) {
      x = this._minX;
    }
    this._x = x;
    this.$el.attr('transform', translate(this._x, this._y));
    this._droppable.move(this._x, this._y);
  }

  /**
   * move y only
   * @param dy
   */
  public moveY(dy: number) {
    let y = this._y + dy;
    if (y > this._maxY) {
      y = this._maxY;
    }
    if (y < this._minY) {
      y = this._minY;
    }

    this._y = y;
    this.$el.attr('transform', translate(this.x(), this.y()));
    this._droppable.move(this._x, this._y);
  }

  /**
   * Move element depending on delta x and y values
   * @param dx
   * @param dy
   */
  public move(dx: number, dy: number) {
    let x = this._x + dx;
    let y = this._y + dy;
    if (y > this._maxY) {
      y = this._maxY;
    }
    if (y < this._minY) {
      y = this._minY;
    }

    if (x + this.width > this._maxX) {
      x = this._maxX - this.width;
    }
    if (x < this._minX) {
      x = this._minX;
    }
    this._x = x;
    this._y = y;

    this.$el.attr('transform', translate(this._x, this._y));
    this._droppable.move(this._x, this._y);
  }

  get width() {
    const svgRect = (this.$el.select('rect').node() as SVGSVGElement).getBBox();
    return svgRect ? svgRect.width : 0;
  }

  get heigth() {
    return this._height;
  }

  public initDroppable() {
    this._droppable.setCoords(this._x, this._y, this._minX, this._minY);
    this._droppable.width = this.width;
    this._droppable.height = this.heigth;
    this._droppable.show().render();
  }

  /***************************************** $el functions ***********************************************************/
  /**
   * Show elemet
   */
  public show() {
    this.$el.attr('visibility', 'visible');
    return this;
  }

  /**
   * Hide element
   */
  public hide() {
    this.$el.attr('visibility', 'hidden');
    this.$el.selectAll('*').remove();
    this._droppable.hide();
    this.clear();
    return this;
  }

  /************************************ IElement interface ***********************************************************/
  /**
   * DOM element
   * @returns {Element}
   */
  public node() {
    return this.el;
  }

  /**
   * d3.Selection of DOM elemet
   * @returns {d3.Selection<any, any, any, any>}
   */
  public element() {
    return this.$el;
  }
}
