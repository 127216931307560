import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MyPreferencesService } from '../../services/my-preferences.service';
import { FormControl, FormGroup } from '@angular/forms';
import { SideBarService } from '@shared/services/side-bar/side-bar.service';
import { AFTER_SHIFT_BREAK_MAX_SOFT, AFTER_SHIFT_BREAK_MIN } from '@constants';
import { PreferenceModel } from '@models';
import { ErrorService } from '@services';

interface MyPreferencesForm {
  afterShiftBreak: FormControl<number>;
  startWorktimeMin: FormControl<number>;
  startWorktimeMax: FormControl<number>;
}

@Component({
  selector: 'app-my-preferences-widget',
  templateUrl: './my-preferences-widget.component.html',
  styleUrls: ['./my-preferences-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MyPreferencesWidgetComponent implements OnInit {
  loading = true;

  preferences: PreferenceModel = null;

  preferencesFormGroup: FormGroup<MyPreferencesForm>;

  minBreak = AFTER_SHIFT_BREAK_MIN;
  maxBreak = AFTER_SHIFT_BREAK_MAX_SOFT;

  constructor(
    private readonly preferencesService: MyPreferencesService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly sidebarService: SideBarService,
    private readonly errorService: ErrorService
  ) {}

  ngOnInit() {
    this.loadData();
  }

  save() {
    if (!this.preferencesFormGroup.valid || !this.preferencesFormGroup.dirty) {
      return;
    }

    const data = {
      id: this.preferences.id,
      afterShiftBreak: this.preferencesFormGroup.value.afterShiftBreak * 60,
      startWorktimeMin: this.preferencesFormGroup.value.startWorktimeMin,
      startWorktimeMax: this.preferencesFormGroup.value.startWorktimeMax
    };

    this.preferencesService.updateMyPreferences(data).subscribe(
      value => {
        this.preferences = value;
        this.updatePreferencesFormGroup();
        this.sidebarService.displayNotification('Preferences saved');
        this.changeDetector.markForCheck();
      },
      (error: any) => {
        return this.errorService.handle(`Error while saving preferences: ${error.message}`);
      }
    );
  }

  cancel() {
    if (this.sidebarService.isLocked) {
      this.loadData();
    } else {
      this.sidebarService.closeIfNotLocked();
    }
  }

  private loadData() {
    this.preferencesService.fetchMyPreferences().subscribe(result => {
      this.preferences = result;
      this.loading = false;
      this.setupPreferencesFormGroup();
      this.changeDetector.markForCheck();
    });
  }

  private setupPreferencesFormGroup() {
    this.preferencesFormGroup = new FormGroup({
      afterShiftBreak: new FormControl(this.preferences.afterShiftBreak / 60),
      startWorktimeMin: new FormControl(this.preferences.startWorktimeMin),
      startWorktimeMax: new FormControl(this.preferences.startWorktimeMax)
    });
  }

  private updatePreferencesFormGroup() {
    this.preferencesFormGroup.patchValue({
      afterShiftBreak: this.preferences.afterShiftBreak / 60,
      startWorktimeMin: this.preferences.startWorktimeMin,
      startWorktimeMax: this.preferences.startWorktimeMax
    });
  }
}
