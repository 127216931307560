import { Observable } from 'rxjs';
import { Apollo } from 'apollo-angular';
import { ApolloQueryResult } from '@apollo/client/core';
import { Injectable } from '@angular/core';
import { ErrorService } from '@services';
import { catchError, map } from 'rxjs/operators';
import { notificationCreatedSubscription, markNotificationAsRead, notificationsQuery, publishMaintenanceNotification } from '../queries/notifications.queries';
import { NotificationModel } from '../models';

@Injectable({ providedIn: 'root' })
export class NotificationsAPIService {
  constructor(private readonly apollo: Apollo, private readonly errorService: ErrorService) {}

  public fetchNotifications(limit: number): Observable<NotificationModel[]> {
    return this.apollo
      .query({
        query: notificationsQuery,
        variables: {
          limit: limit
        }
      })
      .pipe(
        map((result: ApolloQueryResult<{ notifications: NotificationModel[] }>) => {
          return result.data.notifications.map(x => {
            return { ...x };
          });
        }),
        catchError(err => {
          return this.errorService.handle(err);
        })
      );
  }

  public markNotificationsAsRead$(): Observable<void> {
    return this.apollo
      .mutate({
        mutation: markNotificationAsRead
      })
      .pipe(
        catchError((err: Error) => {
          return this.errorService.handle(err);
        })
      ) as Observable<void>;
  }

  public publishMaintenanceNotification$(title: string, content: string): Observable<void> {
    return this.apollo
      .mutate({
        mutation: publishMaintenanceNotification,
        variables: { title, content }
      })
      .pipe(
        catchError((err: Error) => {
          return this.errorService.handle(err);
        })
      ) as Observable<void>;
  }

  public onNotificationCreated$(): Observable<NotificationModel> {
    return this.apollo
      .subscribe({
        query: notificationCreatedSubscription
      })
      .pipe(map((response: { data: { notificationCreated: NotificationModel } }) => response.data.notificationCreated))
      .pipe(
        catchError((err: Error) => {
          return this.errorService.handle(err);
        })
      );
  }
}
