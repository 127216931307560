import {
  Component,
  Output,
  Input,
  EventEmitter,
  TemplateRef,
  ViewChild,
} from '@angular/core';

import { TooltipService } from './tooltip.service';
import { ITooltipConfig } from './tooltip.config';
import { TooltipReference } from './tooltip.reference';


/**
 * @Component({
 *   selector: 'test-component',
 *   templateUrl: 'test-component.html',
 * })
 * export class TestComponent implements OnInit {
 *
 *   @ViewChild('button') button: ElementRef;
 *   @ViewChild('tooltip') buttonTooltip: TooltipComponent;
 *
 *   public tooltipConfig: ITooltipConfig;
 *
 *   public ngOnInit() {
 *     this.tooltipConfig = {
 *       targetElement: this.button.nativeElement.getBoundingClientRect(),
 *       placement: 'bottom',
 *       width: 200,
 *     }
 *   }
 *
 * }
 *
 * <button #button (click)="buttonTooltip.open()">test</button>
 * <pl-tooltip #tooltip [config]="tooltipConfig">
 *   <div class="tooltip-header">Header</div>
 *   <div class="tooltip-content">Tooltip content</div>
 * </pl-tooltip>
 */
@Component({
  selector: 'pl-tooltip',
  template: `<ng-template><ng-content></ng-content></ng-template>`,
})
export class TooltipComponent {

  @ViewChild(TemplateRef) template: TemplateRef<any>;

  @Input() config: ITooltipConfig;

  @Output() openEmitter = new EventEmitter<TooltipReference>();
  @Output() closeEmitter = new EventEmitter<void>();

  private _tooltipReference: TooltipReference;

  constructor(private _tooltipService: TooltipService) {}

  public open() {
    this._tooltipReference = this._tooltipService.openTooltipTemplate(this.template, this.config);
    this.subscribe();
  }

  public close() {
    if (this._tooltipReference) {
      this._tooltipReference.close();
    }
  }

  private subscribe() {
    this._tooltipReference.onOpen.subscribe(() => {
      this.openEmitter.emit(this._tooltipReference);
    });

    this._tooltipReference.onClose.subscribe(() => {
      this.closeEmitter.emit();
    });
  }

}
