import { Injectable } from '@angular/core';
import { LiveUpdateBulkResult } from '@app/rostr/overview/dto/live-update-bulk-result.model';
import { IVacayRequestParams } from '@model/interfaces';
import { ScheduleModel, VacayRequestModel } from '@models';
import { ErrorService } from '@services';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
  assignedActivityChangedVacaySub,
  createVacayRequestBatch,
  deleteVacayRequestBatch,
  getVacayRequestInfoWithBatch,
  vacayRequestsForSchedule
} from './vacay-requests.queries';
import { ScheduleResponseData } from '@app/vacay/_shared/interfaces/schedule-response-data';

@Injectable({
  providedIn: 'root'
})
export class VacayRequestsService {
  constructor(
    private readonly apollo: Apollo,
    private readonly errorService: ErrorService
  ) {}

  createVacayRequestBatch(dateRange: { dateFrom: string; dateTo: string }, shiftCodeId: number, comment: string, employee: number = null): Observable<null> {
    return this.apollo
      .mutate<{ createVacayRequestBatch: { id: number } }>({
        mutation: createVacayRequestBatch,
        variables: {
          shiftCodeId,
          dateFrom: dateRange.dateFrom,
          dateTo: dateRange.dateTo,
          comment: comment,
          employee: employee
        }
      })
      .pipe(
        map(() => {
          return null;
        }),
        catchError(err => {
          return this.errorService.handle(err);
        })
      );
  }

  deleteVacayRequestBatch(batchId: number): Observable<number> {
    return this.apollo
      .mutate<{ deleteVacayRequestBatch: number }>({
        mutation: deleteVacayRequestBatch,
        variables: {
          id: batchId
        }
      })
      .pipe(
        map(response => {
          return response.data.deleteVacayRequestBatch;
        }),
        catchError(err => {
          return this.errorService.handle(err);
        })
      );
  }

  getVacayRequestInfoWithBatch(vacayRequestId: number): Observable<VacayRequestModel> {
    return this.apollo
      .query<{ vacayRequest: Partial<IVacayRequestParams> }>({
        query: getVacayRequestInfoWithBatch,
        variables: {
          vacayRequestId
        }
      })
      .pipe(
        map(response => new VacayRequestModel(response.data.vacayRequest)),
        catchError(err => {
          return this.errorService.handle(err);
        })
      );
  }

  getVacayRequestsForSchedule(scheduleId: number): Observable<{ requests: VacayRequestModel[]; schedule: ScheduleModel }> {
    return this.apollo
      .query<{ vacayRequestsForSchedule: { requests: IVacayRequestParams[]; schedule: ScheduleResponseData } }>({
        query: vacayRequestsForSchedule,
        variables: {
          scheduleId
        }
      })
      .pipe(
        map(response => {
          return {
            requests: response.data.vacayRequestsForSchedule.requests.map(request => new VacayRequestModel(request)),
            schedule: new ScheduleModel(response.data.vacayRequestsForSchedule.schedule)
          };
        }),
        catchError(err => {
          return this.errorService.handle(err);
        })
      );
  }

  public onActivitiesChanged$(scheduleId: number): Observable<LiveUpdateBulkResult> {
    return this.apollo
      .subscribe({
        query: assignedActivityChangedVacaySub,
        variables: {
          scheduleId
        }
      })
      .pipe(
        map((response: { data: { assignedActivitiesChanged: LiveUpdateBulkResult } }) => {
          return response.data.assignedActivitiesChanged;
        })
      )
      .pipe(
        catchError(err => {
          return this.errorService.handle(err);
        })
      );
  }
}
