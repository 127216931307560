import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { createScheduleFromExcel } from '../queries/schedule-create.queries';

@Injectable({
  providedIn: 'root'
})
export class ScheduleCreateService {
  constructor(private readonly apollo: Apollo) {}

  public create({ name, dateFrom, dateTo, lockedVacayDays, draftDeadline, publishDeadline, deadline, assignments }) {
    return this.apollo.mutate({
      mutation: createScheduleFromExcel,
      context: {
        useMultipart: true
      },
      variables: {
        name,
        dateFrom,
        dateTo,
        lockedVacayDays,
        assignments: assignments.map(assignment => ({
          group: Number(assignment.group)
        })),
        draftDeadline: draftDeadline || null,
        publishDeadline: publishDeadline || null,
        deadline
      }
    });
  }
}
