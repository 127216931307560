import * as d3 from 'd3';
import { EventEmitter } from '@angular/core';

import { translate, xmlns } from '@helpers';

import { IRenderable, IEventable, IElement } from '../utils/interfaces';


export interface IButton extends IRenderable, IEventable, IElement {
  onClick: EventEmitter<any>;
  x: (x?: number) => number | IButton;
  y: (y?: number) => number | IButton;
  width: (width?: number) => number | IButton;
}

export abstract class BaseButton implements IButton {

  public onClick: EventEmitter<any> = new EventEmitter();
  protected el: Element;
  protected $el: d3.Selection<any, any, any, any>;
  protected $rect: d3.Selection<any, any, any, any>;
  protected $title: d3.Selection<any, any, any, any>;

  constructor(protected title: string,
              protected widthValue: number,
              protected height: number,
              protected radius: number,
              protected xValue: number,
              protected yValue: number,
              protected params?: any) {
    this.el = document.createElementNS(xmlns, 'g');
    this.$el = d3.select(this.el);

    this.$rect = this.$el.append('rect');
    this.$title = this.$el.append('text');
  }

  abstract render(): Node;

  abstract events(): void;

  /**
   * Getter and setter for x position
   * @param x
   * @returns {any}
   */
  public x(x?: number): number | IButton {
    if (!arguments.length) {
      return this.xValue;
    }
    this.xValue = x;
    this.$el.attr('transform', translate(this.xValue, this.yValue));
    return this;
  }

  /**
   * Getter and setter of y position
   * @param y
   * @returns {any}
   */
  public y(y?: number): number | IButton {
    if (!arguments.length) {
      return this.yValue;
    }
    this.yValue = y;
    this.$el.attr('transform', translate(this.xValue, this.yValue));
    return this;
  }

  /**
   * Getter and setter for width
   * @param width
   * @returns {any}
   */
  public width(width?: number): number | IButton {
    if (!arguments.length) {
      return this.widthValue;
    }
    this.widthValue = width;
    this.$rect.attr('width', width);
    return this;
  }

  /**
   * Hide button
   */
  public hide() {
    this.$el.attr('visibility', 'hidden');
  }

  /**
   * Show button
   */
  public show() {
    this.$el.attr('visibility', 'visible');
  }

  /************************************ IElement interface ***********************************************************/
  /**
   * DOM element
   * @returns {Element}
   */
  public node() {
    return this.el;
  }

  /**
   * d3.Selection of DOM elemet
   * @returns {d3.Selection<any, any, any, any>}
   */
  public element() {
    return this.$el;
  }

}
