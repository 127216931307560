import { maxBy as _maxBy } from 'lodash-es';
import { Subject, throwError, MonoTypeOperatorFunction } from 'rxjs';
import { tap, takeUntil, catchError } from 'rxjs/operators';
import { Component, OnInit, OnDestroy, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

import { AuthenticationService } from '@auth';
import { CommentTypes } from '@enums';
import { ShiftAliasModel, VacayRequestModel, VacayRequestWish, WishStatus } from '@shared/models';
import { DialogService } from '@shared/services/dialog/dialog.service';
import { ScheduleDay } from '@app/vacay/interfaces/schedule-day';
import { CommentsAPIService } from '@component/conversation-panel/services/comments-api.service';
import { ScheduleService, VacayScheduleService } from '../../services';
import { IBarChartElement, ShiftStatistic } from '../../models';
import { Messages } from '../..';

@Component({
  selector: 'vc-schedule-wish-menu',
  templateUrl: 'wish-menu.component.html',
  styleUrls: ['wish-menu.component.scss'],
  providers: [ScheduleService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScheduleWishMenuComponent implements OnInit, OnDestroy {
  @Input() set day(value: ScheduleDay) {
    this.newDay = value;
    this.getShiftsAndWishes();
    this.updateBarChartElements();
  }

  @Input() isInfo: boolean;

  public newDay: ScheduleDay;

  public barChartElements: IBarChartElement[] = [];
  public isBlocked = false;

  public statisticsLoaded = false;
  public shiftsLoaded = false;

  public wishStatusClass = {
    [WishStatus.Wish]: 'wish-tile',
    [WishStatus.Available]: 'available-tile'
  };

  public commentType: CommentTypes = CommentTypes.VACAY;

  public userId: number;

  protected wishesArray: VacayRequestWish[] = [];
  protected onClickWish: Subject<VacayRequestWish>;
  protected destroy$ = new Subject();

  private shifts: ShiftAliasModel[] = [];

  constructor(
    protected readonly scheduleService: ScheduleService,
    protected readonly commentsService: CommentsAPIService,
    private readonly dialogService: DialogService,
    private readonly vacayScheduleService: VacayScheduleService,
    private readonly authService: AuthenticationService,
    private readonly cd: ChangeDetectorRef
  ) {
    this.userId = this.authService.user.id;
  }

  public ngOnInit(): void {
    this.onClickWish = new Subject();

    this.subscribes();
  }

  updateBarChartElements(): void {
    this.barChartElements = this.shifts?.map(shift => {
      const shiftStat = this.getShiftStatistics(shift);

      return {
        statistics: shiftStat,
        wish: this.wish(shift),
        shift
      } as IBarChartElement;
    });
  }

  // we set shifts and wishes together, because wishes setter depends on shifts
  getShiftsAndWishes(): void {
    this.shifts = this.vacayScheduleService.shiftAliases?.filter(alias => !alias.isVacation);
    this.wishes = this.newDay.vacayRequest?.wishes;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  set wishes(wishes: VacayRequestWish[]) {
    if (!wishes || wishes.length === 0) {
      wishes = [];

      this.shifts?.forEach(shift => {
        const wish = new VacayRequestWish({ shiftAlias: shift.id });
        wishes.push(wish);
      });
    }

    this.wishesArray = wishes;
  }

  get wishes(): VacayRequestWish[] {
    return this.wishesArray;
  }

  /**
   * Get statistic for shift
   *
   * @param {ShiftAliasModel} shift
   *
   * @return {ShiftStatistic|undefined}
   */
  public getShiftStatistics(shift: ShiftAliasModel): ShiftStatistic {
    const statisticShifts = null;

    return statisticShifts?.get(shift.id);
  }

  /**
   * Get classes for graph
   *
   * @param {ShiftStatistic} shiftStatistic
   *
   * @return {string}
   */
  public graphClasses(shiftStatistic: ShiftStatistic): string {
    const classes: string[] = [];
    const isCoveragePositive = shiftStatistic.coverage > 0;

    classes.push(isCoveragePositive ? 'positive' : 'negative');

    return classes.join(' ');
  }

  /**
   * Get classes for under-delivery shift
   *
   * @param {ShiftAliasModel} shift
   *
   * @return {{bordered: boolean, underDelivery: boolean}}
   */
  public underDeliveryClasses(shift: ShiftAliasModel): { bordered: boolean; underDelivery: boolean } {
    if (this.newDay) {
      return {
        bordered: this.newDay.aliasId !== shift.id,
        underDelivery: false
      };
    }
    const activity = this.newDay.activity;

    return {
      bordered: activity && activity.model.shiftAlias.id !== shift.id,
      underDelivery: false
    };
  }

  /**
   * Get background for health status
   *
   * @param {ShiftAliasModel} shift
   *
   * @return {{backgroundColor: string}}
   */
  public healthStyles(shift: ShiftAliasModel): { backgroundColor: string } {
    return { backgroundColor: '#fff' };
  }

  /**
   * Scale height of bar calculated on ratio of coverage of selected shift to maximal coverage for the day.
   * Max value is 30px
   *
   * @param {number} coverage - coverage of delivery for shift
   *
   * @return {string}
   */
  public scaleBarHeight(coverage: number): string {
    const maxValueForBar = 30;

    return `${maxValueForBar * 100}px`;
  }

  public wish(shift: ShiftAliasModel): VacayRequestWish {
    return this.wishes.find(el => el.shiftAlias === shift.id);
  }

  public changeWishStatus(wish: VacayRequestWish): void {
    const newWish: VacayRequestWish = new VacayRequestWish(wish);

    switch (newWish.status) {
      case WishStatus.Wish:
        newWish.status = WishStatus.Available;
        break;

      case WishStatus.Available:
        newWish.status = void 0;
        break;

      default:
        newWish.status = WishStatus.Wish;
    }

    this.onClickWish.next(newWish);
  }

  public underLinedMasterplan(shift: ShiftAliasModel): { underlined: boolean } {
    const masterplan = this.newDay.masterplanActivity;

    return {
      underlined: masterplan && masterplan.model.shiftAlias.id === shift.id
    };
  }

  /**
   * Create an array of wishes based on current tooltip and what user clicked
   *
   * @param wish - new wish
   * @returns - array of wishes for new/updated Vacay request
   */
  protected updateWish(wish: VacayRequestWish): void {
    const hadRequestForTheDay = this.wishes.some(el => el.status !== void 0);
    const newWishes = this.createWishesForRequest(wish);

    const hasWishes = newWishes.some(el => el.status !== void 0);
    const hasRequestWithWishes = hadRequestForTheDay && hasWishes;

    const wishWithHighPriority = _maxBy<VacayRequestWish>(newWishes, 'status');

    this.isBlocked = true;
    if (!hadRequestForTheDay) {
      this.scheduleService
        .createVacayRequest({
          date: this.newDay.date,
          shiftAlias: wishWithHighPriority.shiftAlias,
          wishes: newWishes
        })
        .pipe(this.unblockWishesAfterResponseReceived())
        .subscribe();
    } else {
      if (hasRequestWithWishes) {
        this.scheduleService
          .updateRequest({
            id: this.newDay.vacayRequest.id,
            date: this.newDay.date,
            shiftAlias: wishWithHighPriority.shiftAlias,
            wishes: newWishes
          })
          .pipe(this.unblockWishesAfterResponseReceived())
          .subscribe();
      } else {
        this.scheduleService.deleteRequest(this.newDay.vacayRequest.id).pipe(this.unblockWishesAfterResponseReceived()).subscribe();
      }
    }
  }

  protected subscribes(): void {
    // This is second setup of WebSocket subscriptions. Potential reason of HZN-1391
    this.onClickWish.pipe(takeUntil(this.destroy$)).subscribe(wish => this.updateWish(wish));

    this.scheduleService
      .subscriptionCreateRequest()
      .pipe(takeUntil(this.destroy$))
      .subscribe(request => {
        this.updateWishStatuses(request);
      });

    this.scheduleService
      .subscriptionUpdateRequest()
      .pipe(takeUntil(this.destroy$))
      .subscribe(request => {
        this.updateWishStatuses(request);
      });

    this.scheduleService
      .subscriptionDeleteRequest()
      .pipe(takeUntil(this.destroy$))
      .subscribe(requestId => {
        if (!this.newDay.vacayRequest || this.newDay.vacayRequest.id === requestId) {
          this.wishes.forEach(w => (w.status = undefined));
        }
      });
  }

  private unblockWishesAfterResponseReceived<T>(): MonoTypeOperatorFunction<T> {
    return input =>
      input.pipe(
        tap(() => {
          this.isBlocked = false;
          this.updateBarChartElements();
          this.cd.markForCheck();
        }),
        catchError(err => {
          this.dialogService.alert('', Messages.serviceUnavailable, 'error-modal');
          this.isBlocked = false;
          this.cd.markForCheck();
          return throwError(err);
        })
      );
  }

  private updateWishStatuses(request: VacayRequestModel): void {
    if (this.newDay.vacayRequest && this.newDay.vacayRequest.id === request.id) {
      request.wishes.forEach(wish => {
        this.wishes
          .filter(w => w.shiftAlias === wish.shiftAlias)
          .forEach(w => {
            w.status = wish.status;
          });
      });
      this.cd.markForCheck();
    }
  }

  private createWishesForRequest(wish: VacayRequestWish): VacayRequestWish[] {
    return this.wishes.map(w =>
      w.shiftAlias === wish.shiftAlias
        ? wish
        : new VacayRequestWish({
            shiftAlias: w.shiftAlias,
            status: w.status === wish.status ? undefined : w.status
          })
    );
  }
}
