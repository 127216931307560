import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { last as _last } from 'lodash-es';
import { SideBarService } from '@shared/services/side-bar/side-bar.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'pl-base-app',
  templateUrl: './base-app.component.html',
  styleUrls: ['./base-app.component.scss']
})
export class BaseAppComponent implements OnInit, OnDestroy {
  public mode = '';
  public classes: string[] = [];
  private readonly urlSegmentsSeparator = '/';
  private routerSub: Subscription;
  private routeChangeSubscription$: Subscription;

  constructor(
    private readonly router: Router,
    private readonly sideBarService: SideBarService
  ) {}

  ngOnInit() {
    this.routerSub = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      if (/health|wish|rostr\/schedules|argus\/list/.test(event.urlAfterRedirects)) {
        this.mode = 'full';
      } else if (/argus\/report|rostr\/(masterplan|schedule)\/create/.test(event.urlAfterRedirects)) {
        this.mode = 'argus-report';
      } else if (/rostr\/(masterplan|schedule)\/\d*\/(settings|masterplan)/.test(event.urlAfterRedirects)) {
        this.mode = 'masterplan-settings';
      } else if (/agenda\/overview/.test(event.urlAfterRedirects)) {
        this.mode = 'agenda';
      } else if (/entity\/group/.test(event.urlAfterRedirects)) {
        this.mode = 'group';
      } else {
        this.mode = '';
      }
      if (_last(event.urlAfterRedirects.split('?')[0].split('/')) === 'argus' || /(rostr\/schedules)/.test(event.urlAfterRedirects)) {
        this.mode = 'full overview';
      }
      if (/(breakr\/timer)/.test(event.urlAfterRedirects)) {
        this.mode = 'timer';
      }
    });

    this.subscribeToRouteChanges();
  }

  ngOnDestroy(): void {
    this.routerSub.unsubscribe();
    this.routeChangeSubscription$.unsubscribe();
  }

  public mainContainerClicked(): void {
    this.sideBarService.closeIfNotLocked();
  }

  private subscribeToRouteChanges() {
    this.routeChangeSubscription$ = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.classes = this.parseUrlToClasses(event.urlAfterRedirects);
      }
    });
  }

  private parseUrlToClasses(url: string): string[] {
    const urlWithoutQueryString = url.split('?')[0];
    return urlWithoutQueryString
      .substring(1)
      .split(this.urlSegmentsSeparator)
      .filter(segment => /[a-z\-_]/.test(segment));
  }
}
