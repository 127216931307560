import * as d3 from 'd3';
import { translate } from '@helpers';

import { OpenableItem } from '../items/openable.item';
import { IBaseTimeline } from '../utils/interfaces';
import { IBaseMenu } from '../menu/base-menu.interface';
import { IBaseGrid } from '../grid/base-grid.interface';


export class Droppable {

  public x: number;
  public y: number;
  public width: number;
  public height: number;

  private _minX: number;
  private _minY: number;
  private offset = { topOffset: 0, shiftOffset: 0 };

  private el: Element;
  private $el: d3.Selection<any, any, any, any>;
  private $rect: d3.Selection<any, any, any, any>;


  constructor(private timeline: IBaseTimeline,
    private grid: IBaseGrid,
    private menu: IBaseMenu) {
    this.$el = grid.appendDroppableElement();
    this.el = this.$el.node() as Element;
    this.hide();
  }

  /**
   * Rendering functionality
   */
  public render() {
    const item = this.menu.getNearItemByCoord(this.y, this.height) as OpenableItem;
    const sizes = this.timeline.sizes();
    const height = item.config.height * 0.825;
    const offset = item.config.height * 0.0925;

    this.offset.topOffset = sizes.headerHeight;
    this.offset.shiftOffset = offset;

    this.$rect = this.$el.append('rect')
      .attr('width', this.width)
      .attr('height', height)
      .attr('class', 'success')
      .attr('opacity', 0.6)
      .attr('rx', 3)
      .attr('ry', 3);

    this.$el.attr('transform', translate(this.x + sizes.shiftMargin, this.y + offset));
    return this.el;
  }


  /************************************ Engine ***********************************************************************/

  public setCoords(x, y, minX, minY) {
    this.x = x;
    this.y = y;
    this._minX = minX;
    this._minY = minY;
  }

  public move(x: number, y: number) {
    this.x = x;
    this.y = y;

    const shiftMargin = this.timeline.sizes().shiftMargin;

    const leftInterval = this.grid.getNearIntervalByCoord(this.x);
    const item = this.menu.getNearItemByCoord(this.y, this.height) as OpenableItem;
    const height = item.config.height * 0.825;
    const offset = item.config.height * 0.0925;

    this.$el
      .attr('transform',
        translate(leftInterval.x() + shiftMargin, item.y() + offset));

    this.$rect.attr('height', height);
  }

  public highlightDropArea(status) {
    this.$el.select('rect').classed('success', status);
    this.$el.select('rect').classed('error', !status);
  }

  /***************************************** $el functions ***********************************************************/
  /**
   * Show elemet
   */
  public show() {
    this.$el.attr('visibility', 'visible');
    return this;
  }

  /**
   * Hide element
   */
  public hide() {
    this.$el.attr('visibility', 'hidden');
    this.$el.selectAll('*').remove();
    return this;
  }
}
