export const QUALIFICATIONS = [
  { key: 'licenses', label: 'License', settings: false },
  // Unused fields hidded:
  // { key: 'ratings', label: 'Rating', settings: false },
  // { key: 'ratingEndorsements', label: 'Rating endorsement', settings: false },
  { key: 'unitEndorsements', label: 'Unit endorsements', settings: false }
  // { key: 'licenseEndorsements', label: 'License endorsement', settings: false },
  // { key: 'certificates', label: 'Certificate', settings: false },
  // { key: 'skills', label: 'Skills', settings: false },
];
