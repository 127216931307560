import * as d3 from 'd3';
import { Day } from './day';
import { IBaseRenderer } from './base-renderer.interface';

export class WeekDay {
  public dayOfWeek: d3.Selection<any, any, any, any>; // d3 selection for current day of week
  // array with links to Day objects which related with this week of day
  public relatedDays: Day[] = [];
  // selection indicator. Will be true only if all of the days selected
  public isSelected = false;
  /*
   We use days limits for count how many days related with current day of week.
   For example:
   If daysLimit = 5 and countSelectedDays = 5 then we set flag isSelected = true
   and highlights current weekDay in elements
   */
  public daysLimit = 0;

  public countSelectedDays = 0; // a counter selected days

  constructor(
    private renderer: IBaseRenderer,
    public index: number,
    public label: string
  ) {}

  /**
   * The main method to create and display current day of the week
   */
  public render() {
    const self = this;

    this.daysLimit = 0;

    this.dayOfWeek = this.renderer.daysContainer
      .append('text')
      .text(this.label)
      .attr('y', function () {
        const textWidth = this.getComputedTextLength();
        return self.renderer.cellSize * self.index + self.renderer.halfSize + textWidth / 2;
      })
      .attr('x', this.renderer.sizes.marginLeft + this.renderer.sizes.controlsWidth / 2)
      .on('click', function () {
        const relatedDays = self.relatedDays.filter(day => !day.disabled);
        relatedDays.forEach(day => {
          if (self.isSelected) {
            self.renderer.dropSelectedDate(day);
          } else {
            self.renderer.addSelectedDate(day);
          }
        });
        self.renderer.dateChanges.emit({
          type: self.isSelected ? 'group_remove' : 'group_add',
          changedDates: relatedDays.reduce((acc, day) => {
            acc.push(day.date);
            return acc;
          }, []),
          ranges: self.renderer.selectedRanges,
          activeUnit: self.renderer.activeUnit
        });
        self.selectAllDays(!self.isSelected && relatedDays.length > 0);
      });
  }

  /**
   * Memorization all related days
   * @param day { Day } - related day
   */
  public addRelatedDay(day: Day) {
    if (day.disabled) {
      return;
    }

    this.daysLimit++;
    this.relatedDays.push(day);
  }

  /**
   * Selecting/un-selecting all days of the related days
   * @param selectionStatus { boolean } true - select / false - un-select
   */
  public selectAllDays(selectionStatus: boolean) {
    if (!this.renderer.editMode) {
      return;
    }
    for (let i = 0; i < this.relatedDays.length; i++) {
      /*
       We do the set selection only if related day has the same year
       */
      if (this.relatedDays[i].date.getFullYear() === this.renderer.calendarYear) {
        const day = this.relatedDays[i];

        if (!day.disabled) {
          day.setSelection(selectionStatus, false);
        }
      }
    }
    this.isSelected = selectionStatus;
    this.dayOfWeek.classed('selected-label', this.isSelected);
  }

  /**
   * We are do update selected days counter and add/remove selected css-class
   * @param isSelected { boolean } - true - selected / false - un-selected
   */
  public updateSelection(isSelected: boolean) {
    this.countSelectedDays = isSelected ? this.countSelectedDays + 1 : this.countSelectedDays - 1;

    this.isSelected = this.countSelectedDays === this.daysLimit;

    this.dayOfWeek.classed('selected-label', this.isSelected);
  }
}
