import { AGENDA_ADMIN_COMPANY, AGENDA_ADMIN_DIVISION, SYSTEM_ADMIN, RESOURCE_PLANNER, ANALYST, EMPLOYEE_ROLE, UNION_REPRESENTATIVE } from '../const';
import { MODULES } from '@core';

export interface RoleRoute {
  module: string;
  pages?: string[]; // if defined then allow access to all pages of a module
}

export interface RoleRoutes {
  roles: string[];
  routes: RoleRoute[];
  defaultPage: string[];
}

// commented out modules or pages are not available in V1
export const roleRoutesConfiguration: RoleRoutes[] = [
  {
    roles: [AGENDA_ADMIN_COMPANY, AGENDA_ADMIN_DIVISION, SYSTEM_ADMIN],
    routes: [{ module: MODULES.AGENDA }],
    defaultPage: [MODULES.AGENDA]
  },
  {
    roles: [RESOURCE_PLANNER],
    routes: [{ module: MODULES.ROSTR }, { module: MODULES.PLANNING }, { module: MODULES.PLATFORM }],
    defaultPage: [MODULES.ROSTR]
  },
  {
    roles: [ANALYST],
    routes: [{ module: MODULES.ARGUS }, { module: MODULES.VACAY }],
    defaultPage: [MODULES.VACAY]
  },
  {
    roles: [UNION_REPRESENTATIVE],
    routes: [{ module: MODULES.ROSTR, pages: ['schedules'] }],
    defaultPage: [MODULES.ROSTR]
  },
  {
    roles: [EMPLOYEE_ROLE],
    routes: [
      {
        module: MODULES.VACAY
      }
    ],
    defaultPage: [MODULES.VACAY]
  },
  {
    roles: [SYSTEM_ADMIN],
    routes: [
      { module: MODULES.ARGUS },
      { module: MODULES.PLATFORM },
      { module: MODULES.ROSTR },
      { module: MODULES.SETTINGS },
      { module: MODULES.VACAY },
      { module: MODULES.PLANNING }
    ],
    defaultPage: [MODULES.PLATFORM]
  }
];
