import { IMasterplanAssignedActivityModelParams } from '@model/interfaces';
import { EmployeeModel } from '../auth';
import { ScheduleModel } from './schedule.model';
import { ShiftAliasModel } from '../activities';
import { RostrRequestBaseModel } from '../rostr/rostr-request-base.model';

export class MasterplanAssignedActivityModel extends RostrRequestBaseModel {
  public employee: EmployeeModel;
  public schedule: ScheduleModel;
  public shiftAlias: ShiftAliasModel;
  public scheduleId: number;

  constructor(
    data: IMasterplanAssignedActivityModelParams = {
      // minimal required
      employee: undefined,
      schedule: undefined,
      shiftAlias: undefined,
      // other from parent
      code: '',
      iDate: undefined,
      dateFrom: undefined,
      dateTo: undefined,
      scheduleId: undefined
    }
  ) {
    super(data);

    this.employee = (data.employee && new EmployeeModel(data.employee)) || undefined;
    this.schedule = (data.schedule && new ScheduleModel(data.schedule)) || undefined;
    this.shiftAlias = (data.shiftAlias && new ShiftAliasModel(data.shiftAlias)) || undefined;
    this.scheduleId = data.scheduleId || data.schedule?.id;
  }

  get duration() {
    return this.dateTo.diff(this.dateFrom, 'minutes');
  }

  get group() {
    return (this.employee && this.employee.group) || undefined;
  }
}
