import { gql } from 'apollo-angular';

export const createConstellationTemplateMutation = gql`
  mutation createConstellationTemplate($name: String!, $shiftCodes: [String]) {
    createConstellationTemplate(name: $name, shiftCodes: $shiftCodes) {
      id
      name
      shiftCodes
    }
  }
`;

export const deleteConstellationTemplateMutation = gql`
  mutation deleteConstellationTemplate($id: Int!) {
    deleteConstellationTemplate(id: $id)
  }
`;

export const fetchMyConstellationsQuery = gql`
  query myConstellationTemplates {
    myConstellationTemplates {
      id
      name
      shiftCodes
    }
  }
`;
