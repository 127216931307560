import { Component, ElementRef, HostListener, Inject, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, FormGroup } from '@angular/forms';
import { CommentModel } from '@models';
import { ServerStatus } from '@enums';
import { AuthenticationService } from '@app/_auth/services';

@Component({
  selector: 'sh-comment-list',
  templateUrl: './comment-list.component.html',
  styleUrls: ['./comment-list.component.scss']
})
export class CommentListComponent {
  @Input() comments: CommentModel[] = [];
  @Input() textColor = 'black';
  @Input() showColorSelection = false;
  @Input() callback;
  @Output() selectCommentToEdit = new EventEmitter();

  public commentToEdit: CommentModel;
  public serverStatuses = ServerStatus;
  private timeoutId = undefined;

  constructor(private fb: UntypedFormBuilder, private authService: AuthenticationService) {}

  public edit(comment: CommentModel): void {
    if (!this.canEditComment(comment)) {
      return;
    }

    this.commentToEdit = comment;
    this.selectCommentToEdit.emit();
    this.timeoutId = setTimeout(() => {
      const editTextArea = document.querySelector('textarea');
      if (editTextArea) {
        editTextArea.focus();
      }
    }, 0);
  }

  public canEditComment(comment: CommentModel): boolean {
    return this.authService.user.id === comment.createdBy.id;
  }

  public resetCommentToEdit() {
    this.commentToEdit = null;
    clearTimeout(this.timeoutId);
  }
}
