import { NotificationSeverity, NotificationSource } from '../models';

export function convertSourceIntoIcon(source: NotificationSource) {
  switch (source) {
    case 'ROSTR': {
      return 'dashboard';
    }
    case 'VACAY': {
      return 'airplanemode_active';
    }
    case 'ARGUS': {
      return 'camera';
    }
    case 'AGENDA': {
      return 'event_available';
    }
    case 'ORGANIZATION': {
      return 'language';
    }
    case 'SYSTEM': {
      return 'warning';
    }
    default: {
      const exhaustiveCheck: never = source;
      throw new Error(exhaustiveCheck);
    }
  }
}

export function convertSeverityIntoColor(severity: NotificationSeverity) {
  switch (severity) {
    case 'ERROR': {
      return 'red';
    }
    case 'WARNING': {
      return 'orange';
    }
    case 'INFO': {
      return 'blue';
    }
    default: {
      const exhaustiveCheck: never = severity;
      throw new Error(exhaustiveCheck);
    }
  }
}
