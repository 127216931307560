<mat-form-field appearance="fill" class="range-datepicker">
  <mat-label>{{ label }}</mat-label>
  <mat-date-range-input [rangePicker]="picker" [formGroup]="datesFormGroup" [disabled]="disabled" [dateFilter]="dateFilter" [required]="required">
    <input matStartDate placeholder="Start date" formControlName="dateFrom" readonly />
    <input matEndDate placeholder="End date" formControlName="dateTo" readonly />
  </mat-date-range-input>
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-date-range-picker [dateClass]="dateClass" #picker panelClass="range-datepicker" [startAt]="startAt">
    <mat-date-range-picker-actions>
      <div>
        <div id="range-datepicker-message">{{ customMessage }}</div>
        <div class="d-flex justify-end">
          <button mat-button matDateRangePickerCancel>Cancel</button>
          <button *ngIf="showClearButton" mat-button color="warn" (click)="clear()">Clear</button>
          <button mat-raised-button color="primary" matDateRangePickerApply>Apply</button>
        </div>
      </div>
    </mat-date-range-picker-actions>
  </mat-date-range-picker>
  <mat-error *ngFor="let e of errors | keyvalue">
    {{ e.value }}
  </mat-error>
</mat-form-field>
