import { Subscription, forkJoin } from 'rxjs';
import * as moment from 'moment-mini';
import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { ValidationErrors, ValidatorFn, Validators, AbstractControl, FormArray, FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { getISODateOnlyStringFromLocalMidnight, sortByDate, toUtcWithLocalOffset } from '@shared/index';
import { EntitySetting, SettingsKey, SettingValue } from '../../models/settings';
import { SHORT_DATE_FORMAT } from '@helpers';
import { uniq } from 'lodash-es';
import { tap, map } from 'rxjs/operators';
import { SettingsService } from '@platform/_shared/services/settings.service';

export interface SettingsFormSetup {
  id: FormControl<number>;
  dateFrom: FormControl<string>;
  dateTo?: FormControl<string>;
  value?: FormControl<string>;
}

@Component({
  selector: 'pl-settings',
  templateUrl: 'settings.component.html',
  styleUrls: ['settings.component.scss']
})
export class SettingsComponent implements OnChanges, OnDestroy {
  @Input() settingData: EntitySetting;
  @Input() entityId: number;
  @Input() settingsKey: SettingsKey;

  private settingValues: SettingValue[];
  private defaultValue: string;
  private _editMode = false;
  public settingsForm: FormArray<FormGroup<SettingsFormSetup>>;
  public saveButtonDisabled = false;
  public addButtonDisabled = true;
  public formStatusChange$: Subscription;
  public settingsToDelete = [];

  set editMode(mode: boolean) {
    this._editMode = mode;
    if (mode === true) {
      this.settingsFormGroups.forEach(formGroup => {
        this.enableControl(formGroup.get('dateFrom'), this._editMode);
        this.enableControl(formGroup.get('dateTo'), this._editMode);
      });
    }
  }

  get editMode() {
    return this._editMode;
  }

  private enableControl(control: AbstractControl, enable: boolean) {
    if (control) {
      if (enable) {
        control.enable();
      } else {
        control.disable();
      }
    }
  }

  get settingsFormGroups(): FormGroup<SettingsFormSetup>[] {
    return this.settingsForm?.controls.filter(control => control instanceof FormGroup).map(control => control);
  }

  constructor(
    private readonly settingsService: SettingsService,
    private readonly formBuilder: FormBuilder
  ) {}

  ngOnDestroy(): void {
    this.formStatusChange$?.unsubscribe();
  }

  private initializeSettingValues() {
    this.settingValues = this.settingData?.values;
    this.addDefaultValueIfEmpty();
  }

  private addDefaultValueIfEmpty() {
    if (!this.settingValues?.length) {
      this.settingValues = [
        new SettingValue({
          entityId: this.entityId,
          ...this.settingData?.setting
        })
      ];
    }
  }

  public ngOnChanges() {
    this.defaultValue = this.settingData?.setting?.defaultValue;
    this.initializeSettingValues();
    if (this.settingValues && this.entityId && !this.settingsForm) {
      this.initForm();
    }
  }

  public addSetting() {
    const settingValue = new SettingValue({ key: this.settingsKey });
    this.settingsForm.push(this.createSettingFormGroup(settingValue));
  }

  public removeSetting(formGroupElementId: number) {
    const rowIndex = this.settingsFormGroups.findIndex(row => row.value?.id === formGroupElementId);
    this.settingsForm.controls.splice(rowIndex, 1);
  }

  public onEdit(event) {
    this.editMode = event;
    event ? this.settingsForm.enable() : this.settingsForm.disable();
  }

  private resetButtonsState() {
    this.editMode = false;
    this.addButtonDisabled = true;
  }

  private updateButtonsState() {
    this.addButtonDisabled = this.settingsForm.invalid;
    this.saveButtonDisabled = this.settingsForm.pristine || this.settingsForm.invalid;
  }

  private validate() {
    this.settingsFormGroups.forEach(group => {
      Object.keys(group.controls).forEach(control => {
        group.controls[control].updateValueAndValidity();
      });
    });
    this.updateButtonsState();
  }

  public cancel() {
    this.settingsToDelete = [];
    this.initForm();
    this.resetButtonsState();
  }

  private updateSettingValuesAfterCreateRequests(newSettings) {
    this.removeEmptySettingObject();

    if (!newSettings?.length) {
      return;
    }

    this.settingValues = [...this.settingValues, ...newSettings];
  }

  private updateSettingValuesAfterUpdateRequests(updatedSettings) {
    if (!updatedSettings?.length) {
      return;
    }

    updatedSettings.forEach(updatedSetting => {
      this.findSettingAndReplace(updatedSetting);
    });
  }

  private updateSettingValuesAfterDeleteRequests(deletedSettings) {
    if (!deletedSettings?.length) {
      return;
    }

    deletedSettings.forEach(delSetting => {
      this.findSettingAndRemove(delSetting);
    });

    this.settingsToDelete = [];
  }

  private reloadForm() {
    this.addDefaultValueIfEmpty();
    this.initForm();
    this.settingsForm.markAsPristine();
    this.resetButtonsState();
  }

  public save(reloadForm = false) {
    const createRequests$ = this.createSettings();
    const updateRequests$ = this.updateSettings();
    const deleteRequests$ = this.deleteSettings();

    const allRequests$ = forkJoin([createRequests$, updateRequests$, deleteRequests$]).pipe(
      map(([createdSettings, updatedSettings, deletedSettings]) => ({
        createdSettings,
        updatedSettings,
        deletedSettings
      }))
    );

    if (reloadForm) {
      return allRequests$.pipe(
        tap(({ createdSettings, updatedSettings, deletedSettings }) => {
          this.updateSettingValuesAfterCreateRequests(createdSettings);
          this.updateSettingValuesAfterUpdateRequests(updatedSettings);
          this.updateSettingValuesAfterDeleteRequests(deletedSettings);
          this.reloadForm();
        })
      );
    }

    return allRequests$;
  }

  public delete(id: number) {
    this.settingsToDelete.push(id);
    this.removeSetting(id);
    this.settingsForm.markAsDirty();
    this.validate();
  }

  public clear(formGroupIndex: number) {
    this.settingsForm.controls.splice(formGroupIndex, 1);
    this.validate();
  }

  private mapSettingGroupValuesToSetting(settingGroupValues): SettingValue {
    const settingValue = new SettingValue({ key: this.settingsKey });

    settingValue.id = settingGroupValues.id;
    // settingGroupValues?.value can be boolean true or false or undefined (for dates range setting) or even 0 or any number (HZN-3246 use case with work percentage).
    if (settingGroupValues?.value || Number(settingGroupValues?.value) >= 0) {
      settingValue.value = settingGroupValues?.value.toString();
    }
    settingValue.dateFrom = getISODateOnlyStringFromLocalMidnight(settingGroupValues.dateFrom);
    if (settingGroupValues?.dateTo) {
      settingValue.dateTo = getISODateOnlyStringFromLocalMidnight(settingGroupValues.dateTo);
    }

    return settingValue;
  }

  private createSettings() {
    const newItems = this.settingsFormGroups
      .filter(settingGroup => !settingGroup.value.id)
      .map(settingGroup => this.mapSettingGroupValuesToSetting(settingGroup.value));

    return this.settingsService.createSetting(this.settingsKey, this.entityId, newItems);
  }

  private updateSettings() {
    const updatedItems = this.settingsFormGroups
      .filter(settingGroup => settingGroup.value.id && settingGroup.dirty)
      .map(settingGroup => this.mapSettingGroupValuesToSetting(settingGroup.value));

    return this.settingsService.updateSetting(this.settingsKey, updatedItems);
  }

  private deleteSettings() {
    return this.settingsService.deleteSetting(this.settingsToDelete || []);
  }

  private removeEmptySettingObject() {
    // It happens only first time when no settings values at all.
    const initialEmptySettingValueIndex = this.settingValues?.findIndex(sV => !sV.id);
    if (initialEmptySettingValueIndex > -1) {
      this.settingValues?.splice(initialEmptySettingValueIndex, 1);
    }
  }

  private findSettingAndRemove(searchSetting) {
    const foundItemIndex = this.settingValues?.findIndex(setting => searchSetting.id === setting.id);
    if (foundItemIndex > -1) {
      this.settingValues?.splice(foundItemIndex, 1);
    }
  }

  private findSettingAndReplace(searchSetting) {
    const foundItemIndex = this.settingValues?.findIndex(setting => searchSetting.id === setting.id);
    if (foundItemIndex > -1) {
      this.settingValues?.splice(foundItemIndex, 1, searchSetting);
    }
  }

  private initForm() {
    const isAsc = false;
    const settingsFormGroups = [...this.settingValues]
      .sort((a, b) => sortByDate(a.dateFrom, b.dateFrom, isAsc))
      .map(settingValue => {
        return this.createSettingFormGroup(settingValue);
      });

    const options = {
      validators: this.makeValidators()
    };

    this.settingsForm = this.formBuilder.array(settingsFormGroups, options);
    this.settingsForm.disable();
    this.formStatusChange$ = this.settingsForm.statusChanges.subscribe(() => {
      this.updateButtonsState();
    });
  }

  private makeValidators(): ValidatorFn[] {
    const validators = [this.duplicatedDatesValidator('dateFrom')];

    if (this.isDateToEditable()) {
      validators.push(this.duplicatedDatesValidator('dateTo'), this.overlapDatesValidator());
    }

    return validators;
  }

  private isDateToEditable(): boolean {
    return this.settingsKey === SettingsKey.GroupAveragingPeriod;
  }

  private isValueEditable(): boolean {
    return this.settingsKey !== SettingsKey.GroupAveragingPeriod;
  }

  private createSettingFormGroup(settingValue: SettingValue): FormGroup<SettingsFormSetup> {
    const dateValidators = this.makeDateValidators();

    const setup: SettingsFormSetup = {
      id: this.formBuilder.control(settingValue.id),
      dateFrom: this.formBuilder.control({ value: settingValue?.dateFrom || null, disabled: !this.editMode }, dateValidators)
    };

    if (this.isDateToEditable()) {
      setup.dateTo = this.formBuilder.control(settingValue?.dateTo, dateValidators);
    }

    if (this.isValueEditable()) {
      setup.value = this.formBuilder.control(this.getValueOrDefaultValue(settingValue), this.makeValueValidators());
    }

    return this.formBuilder.group(setup, { validators: this.makeDateRangeValidators() });
  }

  private makeDateValidators(): ValidatorFn[] {
    return [Validators.required];
  }

  private makeDateRangeValidators(): ValidatorFn[] {
    return this.isDateToEditable && [this.dateRangeValidator()];
  }

  private makeValueValidators(): ValidatorFn[] {
    const validators = [Validators.required, Validators.min(0)];

    if (this.settingsKey === SettingsKey.EmployeeWorkPercentage) {
      validators.push(Validators.max(100));
    }

    return validators;
  }

  private getValueOrDefaultValue(settingValue: SettingValue) {
    return settingValue?.value || this.defaultValue;
  }

  private duplicatedDatesValidator(fieldName: string): ValidatorFn {
    return (/* formControlInstance: AbstractControl */): ValidationErrors | null => {
      if (!this.settingsForm) {
        return null;
      }

      const dates = this.settingsFormGroups
        .map(group => {
          const dateValue = group.get(fieldName).value;
          const date = this.dateValueToMoment(dateValue);
          return date.format(SHORT_DATE_FORMAT);
        })
        .filter(date => date);

      const duplicatedDates = uniq(dates.filter((e, i) => dates.indexOf(e) !== i));
      return duplicatedDates.length ? { duplicatedDates: { duplicatedDates } } : null;
    };
  }

  private dateRangeValidator(): ValidatorFn {
    return (formControlInstance: AbstractControl): ValidationErrors | null => {
      if (!this.settingsForm) {
        return null;
      }

      const dateFromValue = formControlInstance.get('dateFrom')?.value;
      const dateToValue = formControlInstance.get('dateTo')?.value;
      const dateFrom = this.dateValueToMoment(dateFromValue);
      const dateTo = this.dateValueToMoment(dateToValue);

      if (dateFromValue && dateToValue && dateTo.isBefore(dateFrom)) {
        return { invalidDatePeriod: true };
      }

      return null;
    };
  }

  private overlapDatesValidator(): ValidatorFn {
    return (/* formControlInstance: AbstractControl */): ValidationErrors | null => {
      // Note. In fact no reason to use/rely on formControlInstance?.value as current targeted value,
      // because iterating this.settingsForm.controls[] value will have data already changed.
      // Note. Using setErrors() anywhere here causes deeper Angular form errors.
      if (!this.settingsForm) {
        return null;
      }

      const sortedDateRanges = this.settingsFormGroups
        .map(formGroup => {
          const dateFrom = formGroup.get('dateFrom').value;
          const dateTo = formGroup.get('dateTo').value;
          return {
            dateFrom,
            dateTo
          };
        })
        .filter(range => range.dateFrom && range.dateTo)
        .sort((a, b) => sortByDate(a?.dateFrom, b?.dateFrom));

      if (!sortedDateRanges.length) {
        return null;
      }

      const overlappingPeriods = [];
      // Assuming, that ranges has data sorted ASC by dateFrom
      for (let index = 0; index < sortedDateRanges.length - 1; index++) {
        const el = sortedDateRanges[index];
        const nextFromValue = sortedDateRanges[index + 1]?.dateFrom;
        const previousToValue = el?.dateTo;
        const nextFrom = this.dateValueToMoment(nextFromValue);
        const previousTo = this.dateValueToMoment(previousToValue);
        const fromIsBeforeTo = nextFrom.isSameOrBefore(previousTo);

        if (fromIsBeforeTo) {
          const nextToValue = sortedDateRanges[index + 1]?.dateTo;
          const previousFromValue = el?.dateFrom;
          const nextTo = this.dateValueToMoment(nextToValue);
          const previousFrom = this.dateValueToMoment(previousFromValue);

          overlappingPeriods.push(
            `${previousFrom.format(SHORT_DATE_FORMAT)}-${previousTo.format(SHORT_DATE_FORMAT)}`,
            `${nextFrom.format(SHORT_DATE_FORMAT)}-${nextTo.format(SHORT_DATE_FORMAT)}`
          );
        }
      }

      if (overlappingPeriods.length) {
        return { overlappingPeriods: { overlappingPeriods: uniq(overlappingPeriods) } };
      }

      return null;
    };
  }

  private dateValueToMoment(date): moment.Moment {
    return typeof date === 'string' ? moment.utc(date) : toUtcWithLocalOffset(date);
  }
}
