import { ApolloError } from '@apollo/client/core';
import { Injectable } from '@angular/core';
import { ComponentType } from '@angular/cdk/portal';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmDeleteModalComponent } from '@component/modal/confirmDeleteModal.component';
import { ModalDialogComponent } from '@common/components/modal-dialog/modal-dialog.component';
import { BULLET_POINT_CHAR } from '@constants';
import { AppDialogConfiguration } from './app-dialog-configuration';
import { SnackBarConfig } from './snack-bar-config';
import { AckDialogComponent } from '@component/ack-dialog/ack-dialog.component';

const DEFAULT_ERROR_MESSAGE = 'Unknown error.';
const DEFAULT_SNACKBAR_ACTION_CAPTION = 'Close';

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  public title: string;
  public body: string;
  public icon: string;
  public config: any;
  public twoButtons: boolean;
  public isAlert: any;
  public customClass: string;

  constructor(
    private matDialog: MatDialog,
    private matSnackBar: MatSnackBar
  ) {}

  public ack(title: string, body: string) {
    const dialogRef = this.matDialog.open(AckDialogComponent, {
      data: {
        title,
        message: body
      }
    });

    return dialogRef.afterClosed();
  }

  public confirm(title: string, body: string, icon?: string, config?, customClass?, isAlert = false, width = 400): Promise<boolean> {
    const dialogRef = this.matDialog.open(ConfirmDeleteModalComponent, {
      width: `${width}px`,
      data: {
        icon,
        config,
        title,
        body,
        isAlert,
        customClass
      }
    });
    return dialogRef.afterClosed().toPromise();
  }

  public alert(title: string, body: string, icon?: string, config?, customClass?): Promise<any> {
    this.isAlert = true;
    return this.confirm(title, body, icon, config, customClass, this.isAlert);
  }

  public modal<TDialog, TResult>(component: ComponentType<TDialog>, configuration: AppDialogConfiguration): Promise<TResult> {
    const matDialogConfig: MatDialogConfig = {
      data: { component, data: configuration.data },
      panelClass: configuration.panelClass,
      width: configuration.width
    };

    if (configuration.isFullScreen) {
      matDialogConfig.width = '100vw';
      matDialogConfig.height = '100vh';
      matDialogConfig.maxHeight = '100vh';
      matDialogConfig.maxWidth = '100vw';
    }

    const dialogRef = this.matDialog.open(ModalDialogComponent, matDialogConfig);
    return dialogRef.afterClosed().toPromise();
  }
  public snackBar(message: string, config: SnackBarConfig = {}) {
    this.matSnackBar.open(message, DEFAULT_SNACKBAR_ACTION_CAPTION, {
      panelClass: config.classes
    });
  }

  public apolloError(apolloError: ApolloError) {
    let messages =
      apolloError.graphQLErrors.length > 0
        ? apolloError.graphQLErrors.map(error => error.extensions?.messageList).flat()
        : !!apolloError.message && ([apolloError.message] as string[]);

    if (messages.length === 0) {
      const errorsFromMessage: string[] = apolloError.graphQLErrors.length > 0 && apolloError.graphQLErrors.map(error => error.message);
      messages = [...messages, ...errorsFromMessage] as string[];
    }

    const formattedMessage = this.formatMessage(messages as string[]);

    const config: SnackBarConfig = {
      classes: ['break-spaces']
    };

    this.snackBar(formattedMessage || DEFAULT_ERROR_MESSAGE, config);
  }

  private formatMessage(messages: string[]): string {
    if (messages.length > 1) {
      return messages.map(message => `${BULLET_POINT_CHAR} ${message}\n`).join('');
    }

    return messages[0];
  }
}
