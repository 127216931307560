import { Apollo } from 'apollo-angular';
import { ApolloQueryResult } from '@apollo/client/core';
import { Injectable } from '@angular/core';
import * as moment from 'moment-mini';
import { Observable } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';

import { ScheduleModel, GroupModel, UnitModel } from '@shared/models';

import { loadSchedules } from '../queries';
import {
  groupsByEntity,
  loadSchedulesByInterval,
  firstScheduleInGroups,
  bulkScheduleOperation,
  loadUnits,
  loadGroups
} from '../queries/schedules-overview.queries';
import { ErrorService } from '@services';

@Injectable({
  providedIn: 'root'
})
export class ScheduleOverviewService {
  public data: ScheduleModel[] = [];

  constructor(
    private readonly apollo: Apollo,
    private readonly errorService: ErrorService
  ) {}

  public loadGroups(): Observable<GroupModel[]> {
    return this.apollo
      .query({
        query: loadGroups
      })
      .pipe(
        map((response: ApolloQueryResult<any>) => {
          return response.data.groups.map(el => new GroupModel(el));
        }),
        catchError(err => {
          return this.errorService.handle(err);
        })
      );
  }

  public loadUnits(): Observable<UnitModel[]> {
    return this.apollo
      .query({
        query: loadUnits
      })
      .pipe(
        map((response: ApolloQueryResult<any>) => {
          return response.data.units.map(el => new UnitModel(el));
        }),
        catchError(err => {
          return this.errorService.handle(err);
        })
      );
  }

  public loadSchedules(dateFrom: Date): Observable<ScheduleModel[]> {
    return this.apollo
      .query({
        query: loadSchedules,
        variables: {
          dateFrom: dateFrom?.toISOString()
        }
      })
      .pipe(
        map((response: ApolloQueryResult<any>) => {
          return response.data.schedules.map(schedule => new ScheduleModel(schedule));
        }),
        tap((schedules: ScheduleModel[]) => {
          this.data = schedules;
        }),
        catchError((err: Error | string) => {
          return this.errorService.handle(err);
        })
      );
  }

  public groupsByEntity(rawEntityType: string, id: number) {
    const entityType = rawEntityType === 'group' ? 'id' : rawEntityType;

    return this.apollo
      .query({
        query: groupsByEntity(entityType),
        variables: { id }
      })
      .pipe(
        map((response: ApolloQueryResult<any>) => {
          return response.data.groups.map(el => new GroupModel(el));
        }),
        catchError(err => {
          return this.errorService.handle(err);
        })
      );
  }

  public schedulesByInterval(groupIds: GroupModel[], dateFrom: string, dateTo: string): Observable<void | any[]> {
    return this.apollo
      .query({
        query: loadSchedulesByInterval,
        variables: { groupIds: groupIds.map(item => item.id), dateFrom, dateTo }
      })
      .pipe(
        map((response: ApolloQueryResult<any>) => {
          this.data = response.data.schedulesByInterval.map(element => new ScheduleModel(element));
        }),
        catchError(err => {
          return this.errorService.handle(err);
        })
      );
  }

  public firstScheduleInGroups(groups: GroupModel[]): Observable<{ dateFrom: string; groupIds: number[] }[]> {
    const groupIds = groups.map(group => group.id);
    return this.apollo
      .query({
        query: firstScheduleInGroups,
        variables: { groupIds }
      })
      .pipe(
        map((response: ApolloQueryResult<any>) => {
          return response.data.firstScheduleInGroups;
        }),
        catchError(err => {
          return this.errorService.handle(err);
        })
      );
  }

  public fullUpdate(data: { created: ScheduleModel[]; updated: ScheduleModel[]; deleted: ScheduleModel[] }) {
    return this.apollo
      .mutate({
        mutation: bulkScheduleOperation,
        variables: {
          created: data.created.map(el => {
            return {
              name: el.name,
              dateFrom: el.dateFromMoment || moment.utc(),
              dateTo: el.dateToMoment || moment.utc(),
              group: el.groups[0].id
            };
          }),
          updated: data.updated.map(el => {
            return {
              id: el.id,
              name: el.name,
              dateFrom: el.dateFromMoment || moment.utc(),
              dateTo: el.dateToMoment || moment.utc(),
              group: el.groups[0] ? el.groups[0].id : 1
            };
          }),
          deleted: data.deleted.map(el => el.id)
        }
      })
      .pipe(
        map((response: ApolloQueryResult<any>) => {
          return response.data.bulkScheduleOperation;
        }),
        tap(bulkOperation => {
          this.data = this.data.filter(v => v.id !== null && !bulkOperation.deleted.some(item => item.id === v.id));
          this.data = [...this.data, ...bulkOperation.created.map(e => new ScheduleModel(e))];
        })
      );
  }
}
