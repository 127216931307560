import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { convertSeverityIntoColor, convertSourceIntoIcon } from '@component/notifications/helpers/notification-helper';
import { NotificationModel } from '@component/notifications/models';
import { NotificationsAPIService } from '@component/notifications/services/notifications-api.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-notifications-sidebar-widget',
  templateUrl: './notifications-sidebar-widget.component.html',
  styleUrls: ['./notifications-sidebar-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationsSidebarWidgetComponent implements OnInit, OnDestroy {
  private newNotificationsSub: Subscription;

  public notifications: NotificationModel[] = [];

  public convertSourceIntoIcon = convertSourceIntoIcon;
  public convertSeverityIntoColor = convertSeverityIntoColor;

  constructor(private readonly notificationsAPIService: NotificationsAPIService, private readonly changeDetector: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this.notificationsAPIService
      .fetchNotifications(200)
      .pipe(take(1))
      .subscribe(notifications => {
        this.notifications = notifications;
        this.changeDetector.markForCheck();
      });

    this.newNotificationsSub = this.notificationsAPIService.onNotificationCreated$().subscribe(notification => {
      this.notifications.unshift(notification);

      this.changeDetector.markForCheck();
    });
  }

  public ngOnDestroy(): void {
    this.newNotificationsSub.unsubscribe();
  }
}
