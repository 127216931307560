<mat-card>
  <mat-card-content>
    <canvas id="distributionChartCanvas">{{ distributionChart }}</canvas>
  </mat-card-content>
</mat-card>
<mat-card [style.display]="displayAgendaChart ? 'block' : 'none'">
  <mat-card-content>
    <canvas id="agendaChartCanvas">{{ agendaChart }}</canvas>
  </mat-card-content>
</mat-card>
<mat-card [style.display]="displayVacayChart ? 'block' : 'none'">
  <mat-card-content>
    <canvas id="vacayChartCanvas">{{ vacayChart }}</canvas>
  </mat-card-content>
</mat-card>
