import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { UnitModel } from '@models';
import { UnitService } from '@services';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UnitsResolver {
  constructor(private readonly unitService: UnitService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UnitModel[]> {
    return this.unitService.load();
  }
}
