import * as moment from 'moment-mini';
import { BaseModel } from '../base.model';
import { MasterplanShift } from './masterplanShift.model';

export class MasterplanV2Model extends BaseModel {
  public id: number;
  public name: string;
  public startDate: Date;
  public startDateMoment: moment.Moment;

  public masterplanShifts: MasterplanShift[];
  constructor(data: any = {}) {
    super(data);

    this.id = data.id;
    this.name = data.name;
    this.masterplanShifts = data.masterplanShifts?.map(mpShift => new MasterplanShift(mpShift)) || [];
    this.startDate = (data.startDate && new Date(data.startDate)) || null;
    this.startDateMoment = moment(this.startDate).utc();
  }

  public getNumWeeks(): number {
    return this.masterplanShifts.length / 7;
  }
}
