import { gql } from 'apollo-angular';

export const bulkQuery = gql`
  mutation bulkEmployeeOperation($deleted: [Int], $updated: [EmployeeInput]) {
    bulkEmployeeOperation(deleted: $deleted, updated: $updated) {
      updated {
        id
        email
        firstName
        middleName
        lastName
        deletedAt
        group {
          id
        }
      }
      deleted {
        id
      }
    }
  }
`;
