import { IMatrix } from '../matrix.interfaces';
import { IRows } from './rows.interface';
import { Cell } from '../grid/cell';
import { IConfig } from '../utils/interfaces';
import { Draggable } from '../draggable/draggable';

import * as d3 from 'd3';

export class Row {

  get data() {
    const group = this.matrix.data.rows.find((el: any) => el.type === this.configs.type);
    return group.data.find((el: any) => el[group.key] === this.configs.key);
  }

  get key() {
    return this.configs.key;
  }

  get index() {
    return this.configs.index;
  }

  get y() {
    return this.configs.index * this.matrix.cellSize + this._offset;
  }

  get offsetY() {
    return this.y + this.matrix.cellSize;
  }

  get offset() {
    return this._offset;
  }

  get color() {
    return this.configs.color;
  }

  get type() {
    return this.configs.type;
  }

  get title() {
    return this.configs.title;
  }

  get overflowTitle() {
    if (this.title && this.configs.title.length > this.matrix.maxTitleSize) {
      return `${this.configs.title.substring(0, 9)}...`;
    }
    return this.configs.title;
  }

  set title(value) {
    this.configs.title = value;
  }

  public $row: d3.Selection<any, any, any, any>;
  public $line: d3.Selection<any, any, any, any>;

  public cells: Cell[] = [];

  public isVisible = false;

  private _offset = 0;

  private draggable: Draggable;

  private hasClick = false;

  public constructor(private matrix: IMatrix,
                     private rows: IRows,
                     private configs: IConfig,
                     private options?: any) {
    this.initRow();
  }

  public render() {
    this.$row.selectAll('*').remove();

    this.drawRect();
    this.drawLine();

    this.cells.forEach((cell: Cell) => {
      cell.updatePosition(cell.colIndex, this.configs.index);
    });

    this.$row.call(this.dragging());
  }

  public drawLine() {
    this.$row.select('line').remove();

    this.$line = this.$row
      .append('line')
      .attr('x1', this.matrix.titleSize)
      .attr('y1', this.matrix.cellSize)
      .attr('x2', () => {
        return this.matrix.titleSize + this.matrix.availableColsCount * this.matrix.cellSize;
      })
      .attr('y2', this.matrix.cellSize)
      .style('stroke-width', 1)
      .style('stroke', 'black');
  }

  public rerender() {
    this.drawRect();
  }

  public addRelatedCell(cell: Cell) {
    this.cells.push(cell);
  }

  public updatePosition(position: number) {
    this.configs.index = position;

    this.$row
      .attr('class', `matrix-row row-${this.configs.index}`)
      .attr('transform', `translate(0, ${this.y})`);

    this.cells.forEach((cell: Cell) => {
      cell.updatePosition(cell.colIndex, this.configs.index);
    });

    this.matrix.event.emit({type: 'dragRow', key: this.options.key, index: this.options.index});
  }

  public destroy() {
    this.rows.container.select(`.row-${this.configs.index}`).remove();

    this.cells.forEach((cell: Cell) => {
      cell.destroy();
    });

    // redraw cols lines
    this.matrix.cols.cols.forEach(col => col.drawLine());
  }

  /*
   * Destroy row with translation other rows
   */
  public destroyWithTranslation() {
    // if no next elem -> scroll by one elem
    if (!this.matrix.scrollRow.hasNext) {
      this.matrix.scrollRow.prev();
    }

    this.destroy();

    const i = this.rows.rows.findIndex((el: Row) => el.index === this.configs.index);
    this.rows.rows.splice(i, 1);

    this.rows.rows.forEach((row: Row) => {
      if (row.configs.index > this.configs.index) {
        row.updatePosition(row.configs.index - 1);
      }
    });
  }

  public setVisible(status: boolean) {
    this.isVisible = !!status;
    this.$row.classed('hide', !this.isVisible);

    this.cells.forEach((el: Cell) => {
      el.setVisible();
    });
  }

  public moveBy(offset: number) {
    this._offset += offset;

    this.$row.attr('transform', `translate(0, ${this.y})`);

    this.cells.forEach((el: Cell) => {
      el.moveBy(0, offset);
    });
  }

  private initRow() {
    this.$row = this.rows.container
      .append('g')
      .attr('key', this.configs.key)
      .attr('class', `matrix-row row-${this.configs.index}`)
      .attr('transform', `translate(0, ${this.y})`);
  }

  private drawRect() {
    this.$row.select('rect').remove();
    this.$row.select('text').remove();

    this.$row
      .append('rect')
      .attr('id', 'main-rect')
      .attr('height', this.matrix.cellSize)
      .attr('width', this.matrix.titleSize);

    this.$row
      .append('text')
      .text(this.overflowTitle)
      .attr('x', this.matrix.titleSize - 5)
      .attr('y', this.matrix.halfCellSize)
      .attr('text-anchor', 'end')
      .attr('alignment-baseline', 'central');


    if (this.configs.color) {
      this.drawColorBox();
    }
  }

  private drawColorBox() {
    this.$row.select('.color-box').remove();

    this.$row
      .append('rect')
      .classed('color-box', true)
      .style('fill', this.configs.color)
      .attr('x', 0)
      .attr('height', this.matrix.cellSize)
      .attr('width', this.matrix.cellSize);
  }

  //// DRAG ////

  private dragging() {
    return d3.drag()
      .on('start', () => {
        this.hasClick = true;
        this.draggable = this.initDraggable();
        this.draggable.dragStart();
        this.matrix.grid.disableGrid();

        this.$row.attr('opacity', 0.6);
      })
      .on('drag', () => {
        this.hasClick = false;
        this.draggable.drag();
      })
      .on('end', () => {
        if (this.hasClick) {
          this.options.click.emit(this);
        } else {
          const position = this.draggable.guessPosition;
          this.updatePositions(position.index);
        }
        this.draggable.dragEnd();
        this.matrix.grid.enableGrid();

        this.$row.attr('opacity', 1);
      });
  }

  private initDraggable() {
    // set max possible y
    const maxY = (this.matrix.availableRowsCount - 1 ) * this.matrix.cellSize;

    const draggable = this.matrix.draggable;
    draggable.setOffsets(this.matrix.cellSize, this.matrix.offset);

    // set main clone element
    draggable.content = this.dragRender();

    // set min and max possible coord
    draggable.dragArea(0, maxY);

    draggable.x = 0;
    draggable.y = this.y;

    // set current element, then draggable could know about class and methods
    draggable.element = this;

    return draggable;
  }

  private dragRender() {
    const clone = d3.select(this.$row.node().cloneNode(true));
    clone.select('line').remove();

    return clone.node();
  }

  private updatePositions(index: number) {
    let items: Row[] = [];

    // if draggable element move to the top
    if (index < this.index) {
      items = this.rows.rows.filter((row: Row) => {
        return row.index > index - 1 && row.index < this.index + 1 && row.index !== this.index;
      });
      items.forEach((item: Row) => { item.updatePosition(item.index + 1); });
    }

    // if draggable element move to the bottom
    if (index > this.index) {
      items = this.rows.rows.filter((row: Row) => {
        return row.index < index + 1 && row.index !== this.index && row.index > this.index - 1;
      });
      items.forEach((item: Row) => { item.updatePosition(item.index - 1); });
    }

    // update position for current draggable element
    this.updatePosition(index);

  }

}
