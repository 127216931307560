import { gql } from 'apollo-angular';

export const employeeWithPreferences = gql`
  query employeeWithPreferences($id: Int!, $dateFrom: String!, $dateTo: String!) {
    employeeWithValidQualifications(id: $id, dateFrom: $dateFrom, dateTo: $dateTo) {
      id
      username
      firstName
      middleName
      lastName
      employeeGroups {
        validFrom
        validTo
        group {
          name
        }
      }
      employeeSkills {
        validFrom
        validTo
        skill {
          id
          name
        }
      }
    }
    preferencesForEmployee(employeeId: $id) {
      hardNightShiftOK
      hardAfterShiftBreak
      hardConsecutiveWeeklyOff
      hardWeeklyWorkMax
      hardWorkDurationMin
      hardWorkDurationMax
    }
    employeeWorkPercentage(id: $id, dateFrom: $dateFrom, dateTo: $dateTo) {
      dateFrom
      value
    }
  }
`;
