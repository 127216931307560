import { gql } from 'apollo-angular';

export const loadCurrentEmployee = gql`
  query loadCurrentEmployee {
    employee {
      id
      username
      firstName
      middleName
      lastName
      group {
        id
        name
        unit {
          id
          name
          site {
            id
            division {
              id
              companyId
            }
          }
        }
      }
      employeeGroups {
        id
        validFrom
        validTo
        group {
          id
          name
        }
      }
      title
    }
  }
`;
