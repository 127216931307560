<span #trigger [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()" class="trigger-button" [class.disabled]="disabled">
  <div [matBadge]="selectedItemsCount" [matBadgeHidden]="selectedItemsCount === 0" matBadgeSize="small">
    <mat-icon>filter_list</mat-icon>
  </div>
</span>

<mat-menu #menu (closed)="restoreSnapshot()" xPosition="before">
  <ng-template matMenuContent>
    <div class="menu-wrapper" (click)="$event.stopPropagation()">
      <div class="menu-header">
        <app-checkbox labelPosition="after" [value]="allSelected" (checkboxChange)="selectAll($event)"> Select all </app-checkbox>
      </div>
      <div class="menu-content">
        <form [formGroup]="checkboxFormGroup">
          <ul>
            <li>
              <app-checkbox labelPosition="after" formControlName="blank" *ngIf="blankDisplay">{{ blankDisplay }}</app-checkbox>
            </li>
            <ng-container formArrayName="items">
              <li *ngFor="let item of items; let i = index">
                <app-checkbox labelPosition="after" [formControlName]="i">{{ item[nameField] }}</app-checkbox>
              </li>
            </ng-container>
          </ul>
        </form>
      </div>
      <div class="menu-actions">
        <button mat-button color="primary" (click)="applyFilters()">Apply</button>
        <button mat-button (click)="clear()" color="warn">Clear</button>
        <button mat-button (click)="cancel()">Cancel</button>
      </div>
    </div>
  </ng-template>
</mat-menu>
