import * as d3 from 'd3';
import { Subject, merge } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { translate, xmlns, lower, upper, appendGElementWithClassAttribute, Multiplier } from '@helpers';

import { GridBackground } from '../defs/grid.background';
import { BaseInterval } from '../intervals/base.interval';
import { Menu } from '../menu/menu';
import { IShift } from '../shifts/base.shift';
import { IElement, IGridConfig, IRenderable, ISubscribable, IBaseTimeline } from '../utils/interfaces';
import { IBaseGrid } from './base-grid.interface';

// rename to base-timeline-grid.ts and BaseTimelineGrid
export class Grid implements IRenderable, ISubscribable, IElement, IBaseGrid {
  get allIntervals() {
    return this._intervals;
  }

  get intervalBackgroundsContainer() {
    return this.$intervalBackgroundsContainer;
  }

  get itemBackgroundsContainer() {
    return this.$itemBackgroundsContainer;
  }

  get shiftsContainer() {
    return this.$shiftsContainer;
  }

  get overShiftsSysContainer() {
    return this.$overShiftsSysContainer;
  }

  get underShiftsSysContainer() {
    return this.$underShiftsSysContainer;
  }

  get gridBackground() {
    return this._background;
  }

  // events
  public onZoom = new Subject<IGridConfig>();
  public onManualRender = new Subject<void>();

  public onDragMove = new Subject<number>();
  public onDragEnd = new Subject<void>();

  public onChangeBase = new Subject<BaseInterval>();
  public onNextInterval = new Subject<BaseInterval>();
  public onPrevInterval = new Subject<BaseInterval>();
  public onNewInterval = merge(this.onNextInterval, this.onPrevInterval);
  public onRenderIntervals = new Subject<BaseInterval[]>();
  public onIntervalRendered = new Subject<BaseInterval>();

  protected el: SVGGElement;
  protected $el: d3.Selection<any, any, any, any>;

  protected $intervalBackgroundsContainer: d3.Selection<SVGGElement, any, any, any>;
  protected $itemBackgroundsContainer: d3.Selection<SVGGElement, any, any, any>;

  protected $shiftsContainer: d3.Selection<SVGGElement, any, any, any>;
  protected $underShiftsSysContainer: d3.Selection<SVGGElement, any, any, any>;
  protected $overShiftsSysContainer: d3.Selection<SVGGElement, any, any, any>;

  protected _scale: d3.ScaleLinear<any, any>;
  protected _intervalScale: d3.ScaleLinear<any, any>;

  protected isRendered = false;

  protected _dateFrom: Date;
  protected _dateTo: Date;

  protected _current: IGridConfig;

  protected _timeline: IBaseTimeline;
  protected _menu: Menu;
  protected _background: GridBackground;

  protected _shifts: IShift[] = [];
  protected _visible: IShift[] = []; // current

  protected _intervals: BaseInterval[] = [];
  protected _interval: BaseInterval; // current middle interval

  protected _dx = 0;
  protected _dragged = true;
  protected _offset = 0;
  protected _offsetCount = 0;

  // FIXME somehow populate must know is it zoom in or zoom out
  protected _zoomOut = true;

  // filters
  protected _filters: number[] = []; // array for filter by days of week

  constructor(protected _config: IGridConfig[]) {
    this.el = document.createElementNS(xmlns, 'g');
    this.$el = d3.select(this.el).attr('id', 'grid');

    this._scale = d3.scaleLinear();
    this._intervalScale = d3.scaleLinear();

    for (let i = 0; i < this._config.length; i++) {
      const item = this._config[i];
      if (item.current) {
        this._current = item;
        break;
      }
    }

    if (!this._current) {
      this._current = this._config[0];
    }
  }

  /**
   * Some setup after all properties are set
   */
  public setup() {
    this.appendContainers();
    this.setDateFrom(this._timeline.config().startDate);
  }

  /**
   * Subscribe to event emitters
   */
  public subscribe() {
    this._background.onDrag.pipe(takeUntil(this._timeline.onDestroy)).subscribe(event => this.drag(event));

    this._background.onDragEnd.pipe(takeUntil(this._timeline.onDestroy)).subscribe(() => this.dragEnd());

    this._background.onDoubleClick.pipe(takeUntil(this._timeline.onDestroy)).subscribe(() => this.zoom());
  }

  /**
   * Setup background and render all intervals
   */
  public render(): Element {
    const headerHeight = this._timeline.header.height;
    const offset = this.offset();

    this.$el.attr('transform', translate(0, headerHeight));

    this.el.insertBefore(this._background.dragArea.node(), this.$underShiftsSysContainer.node());

    this.$underShiftsSysContainer.attr('transform', translate(offset, 0));
    this.$overShiftsSysContainer.attr('transform', translate(offset, 0));

    // shifts and intervals
    this.renderIntervals();

    this.isRendered = true;

    return this.el;
  }

  /**
   * Broadcast event when some parent interval is reached
   */
  public intervalBase() {
    const len = this.length();
    const middleInterval = this._intervals[Math.floor((len - 1) / 2)];
    const base = middleInterval.base();

    if (!this._interval || this._interval.base() !== base) {
      this._interval = middleInterval;
      this.onChangeBase.next(middleInterval);
    }
  }

  public canBeAssignedToAvailableIntervals(date: Date) {
    const currentDate = date.getTime();

    const intervals = this.intervals();
    const firstIntervalDate = intervals[0].dateFrom().getTime();
    const lastIntervalDate = intervals[intervals.length - 1].dateTo().getTime();
    return firstIntervalDate <= currentDate && currentDate <= lastIntervalDate;
  }

  public assignToAvailableInterval(shift: IShift) {
    if (!this.canBeAssignedToAvailableIntervals(shift.dateFrom())) {
      return false;
    }

    const intervals = this.intervals();
    const firstInterval = intervals[0];
    const config = this._current;
    const interval = intervals[Math.abs(config.interval.diff(firstInterval.dateFrom(), shift.dateFrom()))];
    if (interval) {
      shift.interval(interval);
      interval.addShift(shift);
      return true;
    } else {
      return false;
    }
  }

  public findAvailableIntervalForDate(date: Date) {
    if (!this.canBeAssignedToAvailableIntervals(date)) {
      return false;
    }
    const intervals = this.intervals();
    const firstInterval = intervals[0];
    const config = this._current;
    const interval = intervals[Math.floor(Math.abs(config.interval.diff(firstInterval.dateFrom(), date)))];
    if (interval) {
      return interval;
    } else {
      return false;
    }
  }

  public manualUpdateTimeline(intervals = this.intervals()) {
    this.lookup();
    const visible = this._visible;

    setTimeout(() => {
      intervals.forEach(item => {
        const date = item.dateFrom().getTime();
        const s = lower(visible, date, v => v.dateFrom().getTime());
        const e = upper(visible, item.dateTo().getTime(), v => v.dateFrom().getTime());
        const shifts = visible.slice(s, e);

        item.shifts(shifts);

        shifts.forEach(shift => {
          shift.attachShiftToInterval();
        });
      });
      this.onManualRender.next();
    }, 100);

    this.intervalBase();
    return this._visible;
  }

  /**
   * Get all visible shifts
   * @returns {IShift[]}
   */
  public visible() {
    return this._visible;
  }

  /**
   * It is possible to add shift or array of shifts to grid
   * @param shift
   */
  public addShift(shift: IShift) {
    // Anti-double check
    if (this._shifts.indexOf(shift) === -1) {
      this.insertInOrderShift(shift);
      this.lazyUpdateVisibleShifts(shift);
    }
    return this;
  }

  /**
   * Remove shift from array of shifts and current visible shifts
   * @param shift
   * @returns {BaseGrid}
   */
  public removeShift(shift: IShift): this {
    let i = this._shifts.indexOf(shift);
    if (i > -1) {
      this._shifts.splice(i, 1);
    }
    i = this._visible.indexOf(shift);
    if (i > -1) {
      this._visible.splice(i, 1);
    }
    return this;
  }

  /**
   * Move to start of current base
   */
  public page(number = 0) {
    this._dateFrom = this._interval.base(-this._offsetCount, number);
    this.populate();
    this.intervalBase();
    this.render();
  }

  public nextInterval(interval) {
    const index = this._intervals.indexOf(interval);
    return index > -1 && this._intervals.length > index + 1 ? this._intervals[index + 1] : void 0;
  }

  public prevInterval(interval) {
    const index = this._intervals.indexOf(interval);
    return index > 0 ? this._intervals[index - 1] : void 0;
  }

  public appendDroppableElement(): d3.Selection<any, any, any, any> {
    return this.underShiftsSysContainer.append('g').attr('class', 'droppable');
  }

  /********************************************* Main ******************************************8/
   /**
   * Set
   * @param timeline
   */
  public timeline(timeline: IBaseTimeline): this {
    this._timeline = timeline;
    return this;
  }

  /**
   * Setter for menu
   */
  public menu(menu: Menu) {
    this._menu = menu;
    return this;
  }

  /************************************ Some getters *****************************************************************/
  /**
   * Get start date
   * @returns {Date}
   */
  public dateFrom(): Date {
    return this._dateFrom;
  }

  public countIntervalsOutsideWindow() {
    return 2;
  }

  public countIntervalsUnderMenu() {
    const sizes = this._timeline.sizes();

    return Math.floor(sizes.menuWidth / sizes.intervalWidth);
  }

  public getFirstDateVisible(): Date {
    const date = new Date(this._dateFrom);
    this.addIntervalsUnderMenuToDate(date);

    return date;
  }

  private addIntervalsUnderMenuToDate(date: Date, multiplier: Multiplier = 1) {
    const dateOffset = this.countIntervalsUnderMenu();
    switch (this._current.interval.type) {
      case BaseInterval.TYPES.Hour:
        date.setHours(date.getHours() + multiplier * dateOffset);
        break;
      case BaseInterval.TYPES.HalfHour:
        date.setMinutes(date.getMinutes() + multiplier * (dateOffset * 30));
        break;
      case BaseInterval.TYPES.Day:
        date.setDate(date.getDate() + multiplier * dateOffset);
        break;
    }
  }

  // FIXME: need to fix date setting before render for hour/half-hour intervals
  public setDateFrom(date = new Date()) {
    this.addIntervalsUnderMenuToDate(date, -1);

    this._dateFrom = date;

    this.populate();
    this.intervalBase();

    if (this.isRendered) {
      this.render();
    }
  }

  /**
   * Get end date
   * @returns {Date}
   */
  public dateTo(): Date {
    return this._dateTo;
  }

  /**
   * Return current zoom config
   * @returns {IGridConfig}
   */
  public config() {
    return this._current;
  }

  /**
   * Getter for intervals property
   * @returns {BaseInterval}
   */
  public intervals() {
    return this._intervals;
  }

  public shifts() {
    return this._shifts;
  }

  /**
   * Getter for center interval
   * @returns {BaseInterval}
   */
  public interval() {
    return this._interval;
  }

  /**
   * return interval depending on x coordinate
   */
  public getNearIntervalByCoord(x: number) {
    const index = Math.round(this._intervalScale.invert(x));
    return this._intervals[index];
  }

  /**
   * return interval by click
   */
  public getIntervalByCoord(x: number) {
    const index = Math.round(this._intervalScale.invert(x));
    return this._intervals[index - 1];
  }

  /**
   * Return intervals between 2 dates
   */
  public getOverlapedIntervals(intervalFrom, intervalTo) {
    if (!intervalFrom) {
      intervalFrom = this._intervals[0];
    }
    if (!intervalTo) {
      intervalTo = this._intervals[this._intervals.length - 1];
    }

    const xStart = intervalFrom.x();
    const xEnd = intervalTo.x();

    const indexStart = Math.round(this._intervalScale.invert(xStart));
    const indexEnd = Math.round(this._intervalScale.invert(xEnd));

    if (indexStart >= 0 && indexEnd >= 0) {
      if (indexStart !== indexEnd) {
        return this._intervals.slice(indexStart, indexEnd);
      } else {
        return indexStart === 0 ? [intervalFrom] : [intervalTo];
      }
    } else {
      return false;
    }
  }

  /**
   * Return intervals between 2 dates
   */
  public getShiftsBetweenDates(dateFrom, dateTo) {
    if (!dateFrom || !dateTo) {
      return;
    }
    const s = upper(this._shifts, dateFrom.getTime(), v => v.dateFrom().getTime());
    const e = lower(this._shifts, dateTo.getTime() - 1, v => v.dateFrom().getTime());
    return this._shifts.slice(s, e);
  }

  /**
   * Current number of intervals
   * @returns {number}
   */
  public length(): number {
    return this._intervals.length;
  }

  /**
   * Date from date to scale
   * @returns {d3.scale.Linear<any, any>}
   */
  public scale() {
    return this._scale;
  }

  /**
   * Offset of intervals and shifts containers
   * @returns {number}
   */
  public offset() {
    return this._offset;
  }

  /**
   * Count of offset items
   * @returns {number}
   */
  public offsetCount() {
    return this._offsetCount;
  }

  /**
   * Array of day filters
   * @returns {Array}
   */
  public filters(filters?: number[]): this | number[] {
    if (!arguments.length) {
      return this._filters;
    }
    this._filters = filters;
    this.intervalBase();
    this.populate();
    this.renderIntervals();
    return this;
  }

  /************************************ Background *******************************************************************/
  /**
   * Setter for background
   * @param background
   * @returns {BaseGrid}
   */
  public background(background: GridBackground): this {
    this._background = background;
    return this;
  }

  /************************************ IElement interface ***********************************************************/
  /**
   * DOM element
   * @returns {SVGGElement}
   */
  public node() {
    return this.el;
  }

  /**
   * d3.Selection of DOM element
   * @returns {d3.Selection<any>}
   */
  public element() {
    return this.$el;
  }

  public destroy() {
    this.$intervalBackgroundsContainer.selectAll('*').remove();
    this.$shiftsContainer.selectAll('*').remove();
    this.$itemBackgroundsContainer.selectAll('*').remove();
    this.$underShiftsSysContainer.selectAll('*').remove();
    this.$el.selectAll('*').remove();

    this._shifts.length = 0;
    this._intervals.length = 0;
    this._visible.length = 0;
  }

  /**
   * Render intervals
   * Clears the DOM then iterates over the week intervals and pushes the
   * column (each day) row into the interval so that the DOM is setup.
   * @returns {Grid}
   */
  protected renderIntervals() {
    const intervals = this._intervals;
    const node = this.$intervalBackgroundsContainer.node();
    const shiftsNode = this.$shiftsContainer.node();

    this.$intervalBackgroundsContainer.selectAll('*').remove();
    this.$shiftsContainer.selectAll('*').remove();

    intervals.forEach((interval: BaseInterval) => {
      interval.renderBackground();

      node.appendChild(interval.backgroundNode);
      shiftsNode.appendChild(interval.shiftsNode);
    });

    this.onRenderIntervals.next(intervals);

    return this;
  }

  /************************************ Engine ***********************************************************************/
  /**
   * Will set/reset array of intervals
   * set from and to properties
   * @returns {Grid}
   */
  protected populate() {
    const sizes = this._timeline.sizes();
    const config = this._current;
    const iw = config.intervalWidth;
    const width = sizes.width;
    const offset = sizes.menuWidth % config.intervalWidth;
    const offsetCount = Math.floor(sizes.menuWidth / config.intervalWidth);
    const zoomOut = this._zoomOut;
    const count = Math.floor((width - offset) / iw);
    const filters = this._filters;

    this._offsetCount = offsetCount;
    this._offset = offset;

    this.$intervalBackgroundsContainer.attr('transform', translate(offset, 0));
    this.$underShiftsSysContainer.attr('transform', translate(offset, 0));
    this.$shiftsContainer.attr('transform', translate(offset, 0));
    this.$overShiftsSysContainer.attr('transform', translate(offset, 0));

    // round to some needed value, if needed
    this._dateFrom = config.interval.precision(this._dateFrom, zoomOut ? 0 : offsetCount);
    const iter = new Date(this._dateFrom.getTime());

    if (filters.length > 0 && filters.length < 7) {
      iter.setDate(iter.getDate() - iter.getDay() + this._filters[0]);
      this._dateFrom = new Date(iter.getTime());
    }

    const { intervals, endInterval } = this.initIntervals();

    this._dateTo = new Date(endInterval.dateToUnix);

    this._scale.domain([0, iw]).range([0, endInterval.dateToUnix - endInterval.dateFromUnix]);

    this._intervalScale.domain([0, count]).range([0, count * iw]);

    // fixing for activities not rendering after tab change
    // basis taken from onZoom in base.shift
    // see src/_shared/app/components/basetimeline/shifts/base.shift.ts:174
    this._shifts.forEach(shift => shift.detach());

    this.lookup();

    const visible = this._visible;

    setTimeout(() => {
      intervals.forEach(interval => {
        // visible is the activity prop here
        const s = lower(visible, interval.dateFromUnix, activity => {
          // night shifts need to be placed when the shift ends
          return activity.dateFrom().getTime();
        });
        const e = upper(visible, interval.dateToUnix, activity => {
          return activity.dateFrom().getTime();
        });
        // beginning (inclusive) to end (not inclusive)
        const activities = visible.slice(s, e);

        interval.shifts(activities);
        activities.forEach(activity => activity.attachShiftToInterval());
      });
    }, 100);

    this._intervals = intervals;

    return this;
  }

  protected scrollLeft(countIntervals: number) {
    const intervals = this._intervals;
    const colBackgroundsNode = this.$intervalBackgroundsContainer.node();
    const shiftsNode = this.$shiftsContainer.node();
    const newIntervals = [];

    for (let i = 0; i < countIntervals; i++) {
      const newInterval = intervals.shift();
      const lastInterval = intervals[intervals.length - 1];

      intervals.push(newInterval);

      newInterval.update(lastInterval.next(), lastInterval.x() + lastInterval.width);

      colBackgroundsNode.appendChild(newInterval.backgroundNode);
      shiftsNode.appendChild(newInterval.shiftsNode);

      newIntervals.push(newInterval);
      this.onNextInterval.next(newInterval);
    }

    return newIntervals;
  }

  protected scrollRight(countIntervals: number) {
    const intervals = this._intervals;
    const colBackgroundsNode = this.$intervalBackgroundsContainer.node();
    const shiftsNode = this.$shiftsContainer.node();
    const newIntervals = [];

    for (let i = 0; i < countIntervals; i++) {
      const newInterval = intervals.pop();
      const firstInterval = intervals[0];

      intervals.unshift(newInterval);

      newInterval.update(firstInterval.prev(), firstInterval.x() - firstInterval.width);

      colBackgroundsNode.insertBefore(newInterval.backgroundNode, firstInterval.backgroundNode);
      shiftsNode.insertBefore(newInterval.shiftsNode, firstInterval.shiftsNode);

      newIntervals.push(newInterval);
      this.onPrevInterval.next(newInterval);
    }

    return newIntervals;
  }

  protected initIntervals() {
    const sizes = this._timeline.sizes();
    const config = this._current;
    const iw = config.intervalWidth;
    const count = Math.floor((sizes.width - this._offset) / config.intervalWidth);

    let intervals = Array(count + this.countIntervalsOutsideWindow()).fill(null);
    let endInterval = void 0;
    let date = new Date(this._dateFrom.getTime());

    intervals = intervals.map((interval, index) => {
      endInterval = new config.interval(this._timeline, this, this._menu, new Date(date.getTime()), index * iw, config.params);

      date = endInterval.next();
      return endInterval;
    });

    return { intervals, endInterval };
  }

  /************************************ Drag *************************************************************************/
  /**
   * Will run drag once per 2 events to increase fps,
   * but if delta x is big we will do rendering anyway
   */
  protected drag(event: d3.D3DragEvent<any, any, any>) {
    const dragged = this._dragged;
    const iw = this._timeline.sizes().intervalWidth;

    this._dx += event.dx;

    if (!dragged || Math.abs(this._dx) > iw * 2) {
      this.dragMove();
      this._dragged = true;
    } else {
      this._dragged = false;
    }
  }

  /**
   * Drag rendering
   */
  protected dragMove() {
    const iw = this._timeline.sizes().intervalWidth;
    const hw = iw / 2;
    const dx = this._dx;
    const intervals = this._intervals;
    const first = intervals[0];

    intervals.forEach(interval => interval.move(dx));

    const x = first.x();
    const count = Math.floor(Math.abs(x) / iw) + 1;

    if (Math.abs(x) > hw) {
      this.carousel(count, x >= 0);
    }

    this.onDragMove.next(dx);
    this._dx = 0;
  }

  /**
   * Drag end handler
   */
  protected dragEnd() {
    const intervals = this._intervals;
    const iw = this._timeline.sizes().intervalWidth;
    const dragged = this._dragged;

    if (!dragged) {
      this.dragMove();
      this._dragged = true;
    }

    intervals.forEach((interval: BaseInterval, i) => {
      interval.snap(i * iw);
    });

    this.onDragEnd.next();
  }

  /************************************ Shifts ************************************************************************/
  /**
   * Must set current shifts and let intervals and shifts know about each other
   */
  protected lookup(): Grid {
    const shifts = this._shifts.sort((a, b) => {
      const diff = a.dateFrom().getTime() - b.dateFrom().getTime();
      if (!diff) {
        return a.dateTo().getTime() - b.dateTo().getTime();
      }
      return diff;
    });

    const start = lower(shifts, this._dateFrom.getTime(), s => s.dateFrom().getTime());
    const end = upper(shifts, this._dateTo.getTime(), s => s.dateFrom().getTime());

    this._visible = shifts.slice(start, end);

    return this;
  }

  /**
   *
   * Insert In Order Shift
   * @desc each shift needs to be in the correct order and night
   * shifts need to be read from the dateTo date otherwise they'll render
   * in the wrong slot (interval)
   *
   */
  protected insertInOrderShift(shift) {
    const nearShift = lower(this._shifts, shift.dateFrom().getTime(), v => {
      return v.dateFrom().getTime();
    });

    this._shifts.splice(nearShift, 0, shift);
  }

  /**************************************** Zoom, next, prev *************************************************/
  /**
   * Do some zooming stuff
   */
  protected zoom(): Grid {
    const current = this._current;
    let index = this._config.indexOf(current);
    const shift = d3.event.shiftKey;

    index = shift ? --index : ++index;

    const config = this._config[index];

    if (!config) {
      return this;
    }

    this._zoomOut = shift;
    this._current = config;
    this._timeline.sizes().intervalWidth = config.intervalWidth;

    this.populate();
    this.intervalBase();
    this.onZoom.next(config);

    this.renderIntervals();
    return this;
  }

  private appendContainers(): void {
    this.$itemBackgroundsContainer = appendGElementWithClassAttribute(this._background.dragArea, 'item-backgrounds-container');

    this.$intervalBackgroundsContainer = appendGElementWithClassAttribute(this._background.dragArea, 'interval-backgrounds-container');

    this.$shiftsContainer = appendGElementWithClassAttribute(this._background.dragArea, 'shifts-container');

    this.$underShiftsSysContainer = appendGElementWithClassAttribute(this.$el, 'under-shifts-sys-container');

    this.$overShiftsSysContainer = appendGElementWithClassAttribute(this.$el, 'over-shifts-sys-container');
  }

  /**
   * Depending on count of dragged interval we will handle
   * array of intervals and interval DOM nodes
   * Also function will render shifts which was out of timeline
   * @param count
   * @param direction
   */
  private carousel(count = 1, direction = true) {
    let newIntervals = [];

    if (direction) {
      newIntervals = this.scrollRight(count);
    } else {
      newIntervals = this.scrollLeft(count);
    }

    const intervals = this._intervals;

    this._dateFrom = new Date(intervals[0].dateFromUnix);
    this._dateTo = new Date(intervals[intervals.length - 1].dateToUnix);

    this.lookup();

    const visible = this._visible;

    this.detachShiftsFromInterval(newIntervals);

    setTimeout(() => {
      newIntervals.forEach(interval => {
        const s = lower(visible, interval.dateFromUnix, activity => activity.dateFrom().getTime());
        const e = upper(visible, interval.dateToUnix, activity => activity.dateFrom().getTime());
        const activities = visible.slice(s, e);

        interval.shifts(activities);
        activities.forEach(activity => activity.attachShiftToInterval());
      });
    }, 100);

    this.intervalBase();
  }

  private detachShiftsFromInterval(newIntervals: any[]) {
    newIntervals.forEach(interval => {
      interval.shifts().forEach(shift => shift.interval(null));
      interval.shifts([]);
    });
  }

  private lazyUpdateVisibleShifts(shift) {
    if (this._intervals.length > 0) {
      const leftBorder = this._intervals[0].dateFrom().getTime();
      const rightBorder = this._intervals[this._intervals.length - 1].dateTo().getTime();

      const shiftStart = shift._dateFrom.getTime();
      const shiftEnd = shift._dateTo.getTime();

      if (shiftStart >= leftBorder || shiftEnd <= rightBorder) {
        const lowerElemIndex = lower(this._visible, shift.dateFrom().getTime(), s => {
          return s.dateFrom().getTime();
        });
        this._visible.splice(lowerElemIndex, 0, shift);
      }
    }
  }
}
