import { gql } from 'apollo-angular';

export const scheduleHistoryEntries = gql`
  query scheduleHistoryEntries($scheduleId: Int!) {
    scheduleHistoryEntries(scheduleId: $scheduleId) {
      id
      createdAt
      createdBy {
        id
        firstName
        lastName
      }
      action
      batch
      isUndone
    }
  }
`;

export const applyHistoryBatch = gql`
  mutation applyHistoryBatch($batch: Int!, $scheduleId: Int!) {
    applyHistoryBatch(batch: $batch, scheduleId: $scheduleId) {
      id
    }
  }
`;

export const scheduleHistoryUpdatedSubscription = gql`
  subscription scheduleHistoryUpdated($scheduleId: Int) {
    scheduleHistoryUpdated(scheduleId: $scheduleId) {
      entries {
        id
        createdAt
        createdBy {
          id
          firstName
          lastName
        }
        action
        batch
        isUndone
      }
    }
  }
`;
