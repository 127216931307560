import { BaseModel } from '../base.model';
import { ConfigurationModel } from './sector-configuration.model';
import { SectorModel } from './sector.model';

export class CombinationModel extends BaseModel {

  public name: string;
  public abbreviation: string;
  public description: string;

  public configurations: ConfigurationModel[];
  public sectors: SectorModel[];
  public sectorIds: number[] = [];

  public position: number;
  public type: 'combination' = 'combination';

  constructor(data: any = {}) {
    super(data);

    this.name = data.name || '';
    this.abbreviation = data.abbreviation || '';
    this.description = data.description || '';

    this.configurations = (data.configurations && data.configurations.map(el => new ConfigurationModel(el))) || [];
    this.sectors = (data.sectors && data.sectors.map(el => new SectorModel(el))) || [];
    this.sectorIds = (data.sectorIds && data.sectorIds.slice()) || [];

    this.position = data.position || null;
  }

  get code() {
    return this.abbreviation;
  }

}
