import * as d3 from 'd3';

import { xmlns } from '@helpers';

import { Timeline } from '../timeline';
import { BasePartialHeader } from './base-partial.header';
import { OptionsHeader } from './options-header';
import { SchedulesHeader } from './schedules-header';
import { TabsHeader } from './tabs-header';
import { TimeHeader } from './time-header';


interface IHeaderConfig {
  tabsController: TabsHeader;
  timeController: TimeHeader;
  schedulesController?: SchedulesHeader;
  optionsController?: OptionsHeader;
}


export class Header {

  protected $el: d3.Selection<SVGGElement, any, any, any>;

  private _partials: IHeaderConfig = {
    tabsController: new TabsHeader(),
    timeController: new TimeHeader(),
  };
  private _orderedPartials: BasePartialHeader[] = [];

  private _timeline: Timeline;

  constructor(partials?: Partial<IHeaderConfig>) {
    this.initElements();
    this.initPartials(partials);
  }

  get tabsController() {
    return this._partials.tabsController;
  }

  get timeController() {
    return this._partials.timeController;
  }

  get schedulesController() {
    return this._partials.schedulesController;
  }

  get optionsController() {
    return this._partials.optionsController;
  }

  get orderedPartials() {
    return this._orderedPartials;
  }

  get height() {
    return this.orderedPartials.reduce((acc, partial) => {
      acc += partial.height;
      return acc;
    }, 0);
  }

  set timeline(timeline: Timeline) {
    this._timeline = timeline;
  }

  get timeline() {
    return this._timeline;
  }

  get grid() {
    return this.timeline.grid();
  }

  get menu() {
    return this.timeline.menu();
  }

  get element() {
    return this.$el;
  }

  get node() {
    return this.element.node();
  }

  public init() {
    this.orderedPartials.forEach(partial => partial.init());
  }

  public render() {
    this.orderedPartials.forEach((partial) => {
      partial.render();

      if (this.timeController === partial) {
        this.timeline.node.appendChild(partial.node);
        return;
      }

      this.node.appendChild(partial.node);
    });
  }

  public subscribe() {
    this.orderedPartials.forEach(partial => partial.subscribe());
  }

  protected initElements() {
    const el = document.createElementNS(xmlns, 'g');
    this.$el = d3.select(el).classed('header', true);
  }

  protected initPartials(partials?: Partial<IHeaderConfig>) {
    Object.assign(this._partials, partials);

    if (this.tabsController) {
      this.tabsController.header = this;
      this._orderedPartials.push(this.tabsController);
    }

    if (this.timeController) {
      this.timeController.header = this;
      this._orderedPartials.push(this.timeController);
    }

    if (this.schedulesController) {
      this.schedulesController.header = this;
      this._orderedPartials.push(this.schedulesController);
    }

    if (this.optionsController) {
      this.optionsController.header = this;
      this._orderedPartials.push(this.optionsController);
    }
  }

}
