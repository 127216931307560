import * as d3 from 'd3';

import { IBaseItem } from '../interfaces';
import { translate } from '@helpers';

enum OFFSET_TYPES {
  top = 0,
  bottom = 1
}

export interface IOffsetConfig {
  fill?: string,
  shadowClass?: string
}

export class OffsetItem {

  static OFFSET_TYPES = OFFSET_TYPES;

  // Offset
  protected $offset: d3.Selection<any, any, any, any>;
  protected $gridOffset: d3.Selection<any, any, any, any>;
  protected $shadowLine: d3.Selection<any, any, any, any>;

  private _props: {
    target?:      d3.Selection<any, any, any, any>,
    element?:     d3.Selection<any, any, any, any>,
    gridTarget?:  d3.Selection<any, any, any, any>,
    y?:           number,
    width?:       number,
    offset?:      number,
    menuWidth?:   number,
    menuGridOffset?:  number
    menuTopOffset?:  number
  } = {};

  constructor(private _parent: IBaseItem,
              private _type: OFFSET_TYPES,
              private _timeline,
              private _config: IOffsetConfig = {}) {

    const defaultConfig = {
      fill: '#dcdee2',
      shadowClass: 'line openable-shadow'
    };

    Object.assign(_config, defaultConfig);
  }

  /**
   * Getter for type of offset item.
   * 0 equals top
   * 1 equals bottom
   * @returns {OFFSET_TYPES}
   */
  get type() {
    return this._type;
  }

  /**
   * Setter for type of offset item
   * Must be value type of OFFSET_TYPES enum
   * @param value
   */
  set type(value) {
    this._type = value;
  }

  /**
   * Getter for style config
   * @returns {{}}
   */
  get styleConfig() {
    return this._config;
  }

  /**
   * Getter for style config
   * @param value
   */
  set styleConfig(value) {
    this._config = value;
  }

  /**
   * Getter for render props like element, width and etc.
   *
   * @returns {{element?: Selection<any, any, any, any>, y?: number, width?: number, menuWidth?: number, offset?: number}}
   */
  get renderProps() {
    return this._props;
  }

  /**
   * Render offset
   */
  public render() {
    if (!this._parent) { return; }

    this.calcProps();

    this.renderMenuOffset();

    this.renderGridOffset();

    this.renderShadowLine();

    this.subscribe();
  }

  /**
   * Subscribe to event emitters
   */
  public subscribe() {
    const grid = this._parent.getTimeline();
    const menu = grid.menu();
    menu.onChange.subscribe(() => {
      this.renderGridOffset();
    });

  }

  /**
   * Calculating props for render
   */
  public calcProps() {
    this._timeline = this._timeline || this._parent.getTimeline();
    const sizes = this._timeline.sizes();

    this.renderProps.element = this._parent.element();
    this.renderProps.target = this.renderProps.element.append('g').classed('offset', true);

    const grid = this._parent.getGrid();
    this.renderProps.gridTarget = grid.underShiftsSysContainer;

    this.renderProps.menuWidth = sizes.menuWidth;
    this.renderProps.width = sizes.gridWidth;
    this.renderProps.menuGridOffset = sizes.menuWidth % sizes.intervalWidth;
    this.renderProps.menuTopOffset = sizes.headerHeight;

    this.renderProps.offset = (this.type === OFFSET_TYPES.bottom)
      ? this._parent.config.offset.bottom
      : this._parent.config.offset.top;

    this.renderProps.y = (this.type === OFFSET_TYPES.bottom) ? this._parent.height : 0;
    this.renderProps.target.attr('transform', translate(0, this.renderProps.y));
  }

  /**
   * Show offset elements
   * Usually we need it for situation when parent was opened
   */
  public show() {
    this.$gridOffset.attr('visibility', 'visible');
    this.renderProps.target.attr('visibility', 'visible');
  }

  /**
   * Hide offset elements
   * Usually we need it for situation when parent was opened
   */
  public hide() {
    this.$gridOffset.attr('visibility', 'hidden');
    this.renderProps.target.attr('visibility', 'hidden');
  }

  /**
   * Render menu offset (leftside part)
   */
  protected renderMenuOffset() {
    const props = this.renderProps;
    this.$offset = props.target.append('rect');

    this.$offset
      .attr('width', props.menuWidth)
      .attr('height', props.offset)
      .attr('fill', this.styleConfig.fill);
  }

  /**
   * Render grid offset
   *
   * Grid part render is separated in one of sys containers
   */
  protected renderGridOffset() {
    const props = this.renderProps;

    if (!this.$gridOffset) {
      this.$gridOffset = props.gridTarget.append('rect');
    }

    const xPos = props.menuWidth - props.menuGridOffset;
    let yPos = this._parent.y();

    if (this.type === OFFSET_TYPES.bottom) {
      yPos += this._parent.height;
    }

    this.$gridOffset
      .attr('width', props.width)
      .attr('height', props.offset)
      .attr('x', xPos)
      .attr('y', yPos)
      .attr('fill', this.styleConfig.fill)
      .attr('opacity', 1);
  }

  /**
   * Render shadow line
   */
  protected renderShadowLine() {
    const props = this.renderProps;
    this.$shadowLine = props.target.append('line');

    this.$shadowLine
      .attr('x1', 0)
      .attr('x2', props.width + props.menuWidth)
      .attr('y1', 1)
      .attr('y2', 1);

    const classed = this.styleConfig.shadowClass;
    if (classed) {
      this.$shadowLine.classed(this.styleConfig.shadowClass, true);
    }
  }
}
