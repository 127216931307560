import { DivisionModel, SiteModel, GroupModel, TeamModel} from '../companies';

export class WorkAssignmentModel {
  public division: DivisionModel;
  public site: SiteModel;
  public group: GroupModel;
  public team: TeamModel;

  constructor(data: any = {}) {

    this.division = data.division && new DivisionModel(data.division) || new DivisionModel();
    this.site = data.site && new SiteModel(data.site) || new SiteModel();
    this.group = data.group && new GroupModel(data.group) || new GroupModel();
    this.team = data.team && new TeamModel(data.team) || new TeamModel();
  }
}
