import * as moment from 'moment-mini';
import { BaseModel } from '../base.model';
import { EmployeeModel } from '../auth';


export class EmployeeLicenseMedicalModel extends BaseModel {

  public approvalDate: Date;
  public expirationDate: Date;
  public interval: number;

  public employee: EmployeeModel;

  private WARNING_DAYS = 5;

  constructor(data: any = {}) {
    super(data);

    this.approvalDate = (data.obtained && new Date(data.obtained) || undefined);
    this.expirationDate = (data.validTo && new Date(data.validTo) || undefined);
    this.employee = (data.employee && new EmployeeModel(data.employee));
    this.interval = this.parseInterval(data.employee && data.employee.group || undefined);
  }

  public get status(): string {
    let status = 'green';
    if (!this.expirationDate) {
      return status;
    }
    const target = moment(this.expirationDate);
    const today = moment();
    const diff = target.diff(today, 'days');

    const daysOff: number = diff - this.interval;

    if (daysOff <= 0) {
      status = 'red';
    } else if (daysOff <= this.WARNING_DAYS) {
      status = 'orange';
    }

    return status;
  }

  private parseInterval(group): number {
    if (group && group.unit && group.unit.medicalSetting && group.unit.medicalSetting.length > 0) {
      return Number(group.unit.medicalSetting[0].interval);
    }
    return undefined;
  }

}

