import * as d3 from 'd3';

import { xmlns } from '@helpers';

import { IMatrix } from '../matrix.interfaces';
import { Col } from '../cols/col';
import { Row } from '../rows/row';
import { Droppable } from './droppable';

export class Draggable {

  get node() {
    return this.group;
  }

  set x(value) {
    this._x = value;
  }

  set y(value) {
    this._y = value;
  }

  // set node content
  set content(content) {
    this._content = content;
  }

  get content() {
    return this._content;
  }

  // set class element
  set element(value) {
    this._element = value;
  }

  // supposed position
  get guessPosition() {
    return this._droppable.guessPosition;
  }
  private group: Element;
  private el: Element;

  private $group: d3.Selection<any, any, any, any>;
  private $el: d3.Selection<any, any, any, any>;

  private _content: Node;
  private _droppable: Droppable;

  private _x = 0;
  private _y = 0;

  private _min = 0;
  private _max = 0;

  private _offsetX = 0;
  private _offsetY = 0;

  private _element: Col | Row;

  constructor(matrix: IMatrix) {
    this.group = document.createElementNS(xmlns, 'g');
    this.el = document.createElementNS(xmlns, 'g');

    this.$group = d3.select(this.group).classed('draggable', true);
    this.$el = d3.select(this.el);

    this._droppable = new Droppable(matrix);
  }

  public clean() {
    this._x = 0;
    this._y = 0;
    this._min = 0;
    this._max = 0;
    this._offsetX = 0;
    this._offsetY = 0;
  }

  public dragStart() {
    this.group.appendChild(this.el);
    this.el.appendChild(this._content);
    this.group.appendChild(this._droppable.node);
    this.initDroppable();
  }

  public drag() {
    this.move(d3.event.dx, d3.event.dy);
  }

  public dragEnd() {
    this.$group.selectAll('*').remove();
    this.clean();
  }

  public dragArea(v1: number, v2: number) {
    this._min = v1;
    this._max = v2;
  }

  public setOffsets(offsetX: number, offsetY: number, rotate = 0) {
    this._offsetX = offsetX;
    this._offsetY = offsetY;

    this._droppable.setOffsets(offsetX, offsetY, rotate);

    this.$el.attr('transform', `translate(${this._offsetX}, ${this._offsetY}) rotate(${rotate})`);
  }

  private move(dx: number, dy: number) {
    this.moveY(dy); // because col have rotate it uses Y too
    this.$el.select('g').attr('transform', `translate(${this._x}, ${this._y})`);
    this._droppable.move(this._x, this._y);
  }

  private moveY(dy: number) {
    let y = this._y + dy;
    if (y > this._max) {
      y = this._max;
    }
    if (y < this._min) {
      y = this._min;
    }
    this._y = y;
    this._x = 0;
  }

  private initDroppable() {
    this._droppable.setCoords(this._x, this._y);
    this._droppable.render();
    this._droppable.element = this._element;
  }
}
