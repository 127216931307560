import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService, IRole, Role, RoleService } from '@auth';
import { EmployeeModel, GroupModel, SettingsKey } from '@models';
import { AveragingPeriodsForGroups, EmployeeAveragingPeriodOffsetResult, SettingsService, SingleAveragingPeriodStatistic } from '@platform/_shared';
import { ProfileService } from '@platform/_shared/services/profile.service';
import { GroupService } from '@services';
import { preferredWorkDuration, preferredWorkTime } from '@shared/const/time.const';
import { EmployeeService } from '@shared/services/employee/employee.service';
import { SideBarService } from '@shared/services/side-bar/side-bar.service';
import { Subscription, combineLatest, forkJoin, of } from 'rxjs';
import { catchError, finalize, switchMap, take, tap } from 'rxjs/operators';
import { EmployeeWithAllReferences, IdAndTitlePair } from '@platform/_shared/models/employee-with-all-references';
import { ISO_DATE_ONLY_FORMAT, dateRangesOverlap } from '@helpers';
import * as preferences from '@shared/const/preferences.const';

import {
  DateRange,
  EmployeeGroupModel,
  SettingValue,
  ErrorService,
  RESOURCE_PLANNER,
  UNION_REPRESENTATIVE,
  ROSTR_READ_ACCESS,
  AGENDA_ADMIN_DIVISION,
  EmployeeRoleModel,
  CONSECUTIVE_WEEKLY_OFF_MIN,
  CONSECUTIVE_WEEKLY_OFF_MAX,
  AFTER_SHIFT_BREAK_MIN,
  AFTER_SHIFT_BREAK_MAX
} from '@shared/index';
import { SkillModel } from '@model/profile/skill.model';
import moment from 'moment-mini';
import { DateAndIdForm } from '@platform/_shared/models/date-and-id-form';
import { MessageBusService } from '@shared/services/message-bus/message-bus.service';
import { EmployeeUpdatedMessage } from '@shared/services/message-bus/messages.class';

@Component({
  selector: 'app-employee-editor-sidebar-widget',
  templateUrl: './employee-editor-sidebar-widget.component.html',
  styleUrls: ['./employee-editor-sidebar-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmployeeEditorSidebarWidgetComponent implements OnInit, OnDestroy {
  routeSub: Subscription;
  loading = true;
  isBusy = false;
  currentEmployeeId: number;

  licensesList: IdAndTitlePair[] = [];
  endorsementsList: IdAndTitlePair[] = [];
  skillList: IdAndTitlePair[] = [];
  employeeList: EmployeeModel[] = [new EmployeeModel({ id: null, firstName: 'NONE' })];
  unitList: string[] = [];
  roleList: IdAndTitlePair[] = [];
  groupList: GroupModel[] = [];
  filteredGroupList: GroupModel[] = [];

  preferredWorkTime: string[] = preferredWorkTime.map(w => w.title);
  preferredWorkDuration: string[] = preferredWorkDuration.map(w => w.title);

  averagingPeriods: AveragingPeriodsForGroups;

  statisticsForAveragingPeriods: {
    [periodId: number]: SingleAveragingPeriodStatistic;
  };

  preferences = preferences;

  rolesWithAssignments = [RESOURCE_PLANNER, AGENDA_ADMIN_DIVISION, UNION_REPRESENTATIVE, ROSTR_READ_ACCESS];

  minWeeklyOff = CONSECUTIVE_WEEKLY_OFF_MIN;
  maxWeeklyOff = CONSECUTIVE_WEEKLY_OFF_MAX;
  minAfterShiftBreak = AFTER_SHIFT_BREAK_MIN;
  maxAfterShiftBreak = AFTER_SHIFT_BREAK_MAX;

  public employeeForm: FormGroup<{
    id: FormControl<number>;
    userName: FormControl<string>;
    firstName: FormControl<string>;
    middleName: FormControl<string>;
    lastName: FormControl<string>;
    personalPresentation: FormControl<string>;
    workPhone: FormControl<string>;
    mobilePhone: FormControl<string>;
    workEmail: FormControl<string>;
    personalEmail: FormControl<string>;
    active: FormControl<boolean>;
    roles: FormControl<IdAndTitlePair[]>;
    roleAssignments: FormGroup<{
      [roleName: string]: FormControl<GroupModel[]>;
    }>;
    licenses: FormControl<IdAndTitlePair[]>;
    endorsements: FormControl<IdAndTitlePair[]>;
    skills: FormGroup<{
      [skillId: string]: FormArray<FormGroup<DateAndIdForm>>;
    }>;
    selectedSkills: FormControl<number>;
    groups: FormGroup<{
      [groupId: string]: FormArray<FormGroup<DateAndIdForm>>;
    }>;
    workPercentages: FormArray<
      FormGroup<{
        id: FormControl<number>;
        dateFrom: FormControl<string>;
        value: FormControl<number>;
      }>
    >;
    healthyRotation: FormControl<boolean>;
    hardWorkDurationMax: FormControl<number>;
    hardWeeklyWorkMax: FormControl<number>;
    hardConsecutiveWeeklyOff: FormControl<number>;
    standardWorkingHoursPerWeek: FormControl<number>;
    workingDaysInRow: FormControl<number>;
    hardAfterShiftBreak: FormControl<number>;
    hardNightShiftOK: FormControl<boolean>;
    unit: FormControl<string>;
    group: FormControl<number>;
    preferencesId: FormControl<number>;
    showLegacyGroups: FormControl<boolean>;
    showLegacyPeriods: FormControl<boolean>;
    averagingPeriodOffsets?: FormGroup<{
      [periodId: number]: FormControl<number>;
    }>;
  }>;

  // In every widget, constructors should not have complex dependencies as long
  // these would be loaded hierarchically from all modules that have widgets.
  // Copy this comment to every new widget
  constructor(
    private readonly profileService: ProfileService,
    private readonly settingsService: SettingsService,
    private readonly employeeService: EmployeeService,
    private readonly groupService: GroupService,
    private readonly roleService: RoleService,
    private readonly sideBarService: SideBarService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly route: ActivatedRoute,
    private readonly formBuilder: FormBuilder,
    private readonly errorService: ErrorService,
    private readonly messageBus: MessageBusService,
    public readonly auth: AuthenticationService
  ) {}

  public ngOnInit(): void {
    combineLatest([
      this.employeeService.loadEmployeesWithNamesOnly(),
      this.groupService.loadGroups(),
      this.roleService.loadRoles(),
      this.profileService.loadQualificationOptions()
    ])
      .pipe(
        take(1),
        tap(([employees, groups, roles, qualifications]) => {
          this.employeeList = [...this.employeeList, ...employees];

          this.unitList = Array.from(new Set(groups.flatMap(x => x.unit.name)));
          this.groupList = Array.from(new Set(groups));
          this.filteredGroupList = [];

          this.roleList = roles.map(x => new IdAndTitlePair(x.id, x.name));

          this.licensesList = qualifications.licenses.map(x => new IdAndTitlePair(x.id, x.title));
          this.endorsementsList = qualifications.unitEndorsements.map(x => new IdAndTitlePair(x.id, x.title));
          this.skillList = qualifications.skills.map(x => new IdAndTitlePair(x.id, x.title));

          this.routeSub = this.route.params.subscribe(paramMap => {
            this.currentEmployeeId = Number(paramMap.id);
            this.loadEmployeeData(this.currentEmployeeId);
          });
        })
      )
      .subscribe();
  }

  get workPercentages(): FormArray {
    return this.employeeForm.controls.workPercentages;
  }

  get skillsForm(): FormGroup {
    return this.employeeForm.controls.skills;
  }

  get groupsForm(): FormGroup {
    return this.employeeForm.controls.groups;
  }

  get isWorkPercentagesFormValid() {
    return this.employeeForm.controls.workPercentages.valid;
  }

  get allGroupsRangesFlat(): FormGroup<DateAndIdForm>[] {
    return this.employeeForm?.controls.groups.controls
      ? Object.values(this.employeeForm?.controls.groups.controls)
          .map(group => group.controls)
          .flat()
      : [];
  }

  public canHaveAssignments() {
    return this.rolesWithAssignments.some(role => this.hasRole(role));
  }

  public hasRole(role: string) {
    return this.employeeForm.value.roles?.some(r => r.title === role);
  }

  public addWorkPercentage(): void {
    this.workPercentages.push(
      this.formBuilder.group({
        dateFrom: [moment().format(ISO_DATE_ONLY_FORMAT), [Validators.required, Validators.minLength(10)]],
        value: [100, [Validators.required, Validators.min(1)]]
      })
    );
  }

  public removeWorkPercentage(index: number): void {
    this.workPercentages.removeAt(index);
  }

  public ngOnDestroy(): void {
    this.routeSub?.unsubscribe();
  }

  public save(): void {
    this.isBusy = true;
    const rolesData = new EmployeeModel({
      id: this.currentEmployeeId,
      roles: this.employeeForm.controls.roles.value?.map(x => new Role({ id: x.id } as IRole))
    });

    const updateEmployeeWithAllReferences$ = this.profileService.updateEmployeeWithAllReferences(this.employeeForm.value as EmployeeWithAllReferences);
    const saveRolesForEmployee$ = this.roleService.saveRolesForEmployee([rolesData]);
    const saveEmployeeRoleGroups$ = this.profileService.saveEmployeeRoleGroups(this.currentEmployeeId, this.getRoleAssignments());

    updateEmployeeWithAllReferences$
      .pipe(
        switchMap(() => saveRolesForEmployee$),
        switchMap(() => {
          if (this.auth.userRoles.isSystemAdmin || this.auth.userRoles.isResourcePlanner) {
            return saveEmployeeRoleGroups$;
          } else {
            return of(null);
          }
        }),
        catchError(err => {
          return this.errorService.handle(`Error while saving employee changes\n${err.message}`);
        }),
        finalize(() => {
          this.messageBus.publish(new EmployeeUpdatedMessage(this.currentEmployeeId));
          this.loadEmployeeData(this.currentEmployeeId);
        })
      )
      .subscribe(() => this.sideBarService.displayNotification('Changes saved'));
  }

  public cancel(): void {
    if (this.sideBarService.isLocked) {
      this.loadEmployeeData(this.currentEmployeeId);
    } else {
      this.sideBarService.closeIfNotLocked();
    }
  }

  public formatEmployee(employee: EmployeeModel): string {
    return `${employee.firstName} ${employee.lastName}`;
  }

  public getSkillName(skillId: number) {
    return this.skillList.find(skill => skill.id === +skillId)?.title;
  }

  public getGroupName(groupId: string) {
    const group = this.groupList.find(g => g.id === +groupId);
    return `${group.name} (${group.unit.name})`;
  }

  public groupDisplay(group: GroupModel) {
    return group.name;
  }

  public getUnavailableRangesForRange(skillId: number, index: number): DateRange[] {
    const skillsValue = this.employeeForm.controls.skills.getRawValue();
    return skillsValue[skillId]?.filter((_, i) => index !== i).map(value => value.dates);
  }

  public addSkill() {
    const skillId = this.employeeForm.value.selectedSkills;
    this.addNewSkillForm(skillId);
    this.addNewSkillRange(skillId);
    this.employeeForm.controls.selectedSkills.setValue(null);
  }

  public addGroup() {
    const group = this.employeeForm.value.group;
    const formArray = new FormArray<FormGroup<DateAndIdForm>>([]);
    this.employeeForm.controls.groups.addControl(group.toString(), formArray);
    this.addNewGroupRange(group);
    this.groupsChanged();
  }

  private addNewSkillForm(skillId: number) {
    const formArray = new FormArray<FormGroup<DateAndIdForm>>([]);
    this.employeeForm.controls.skills.addControl(skillId.toString(), formArray);

    this.skillsChanged(skillId);
  }

  public addNewSkillRange(skillId: number) {
    const control = this.formBuilder.group<DateAndIdForm>({
      id: null,
      dates: new FormControl(
        {
          dateFrom: new Date(),
          dateTo: null
        },
        [this.dateFromToValidatorForSkills(skillId)]
      )
    });

    this.employeeForm.controls.skills.controls[skillId].push(control);
    this.skillsChanged(skillId);
  }

  public addNewGroupRange(groupId: number) {
    const control = this.formBuilder.group<DateAndIdForm>({
      id: null,
      dates: new FormControl(
        {
          dateFrom: new Date(),
          dateTo: null
        },
        [this.dateFromToValidatorForGroups()]
      )
    });

    this.employeeForm.controls.groups.controls[groupId].push(control);
    this.groupsChanged();
  }

  public removeSkillRange(skillId: number, index: number) {
    const control = this.employeeForm.controls.skills.controls[skillId];

    control.removeAt(index);

    this.skillsChanged(skillId);
  }

  public removeGroupRange(groupId: number, index: number) {
    const control = this.employeeForm.controls.groups.controls[groupId];

    control.removeAt(index);

    this.groupsChanged();
  }

  public removeSkill(skillId: number) {
    this.employeeForm.controls.skills.removeControl(String(skillId));

    this.skillsChanged(skillId);
  }

  public skillsChanged(skillId: number) {
    const control = this.employeeForm.controls.skills.controls[skillId];

    control?.controls.forEach(c => {
      c.controls.dates.updateValueAndValidity({ emitEvent: true });
    });

    this.changeDetector.markForCheck();
  }

  public groupsChanged() {
    this.allGroupsRangesFlat.forEach(c => {
      c.controls.dates.updateValueAndValidity({ emitEvent: true });
    });

    this.loadAveragingPeriods();
    this.changeDetector.markForCheck();
  }

  private loadEmployeeData(employeeId: number) {
    this.profileService
      .loadEmployee(employeeId)
      .pipe(
        switchMap(profile => {
          return forkJoin([
            of(profile),
            this.settingsService.loadSetting(SettingsKey.EmployeeWorkPercentage, profile.id),
            this.profileService.loadEmployeePreferences(),
            this.roleService.getRolesForEmployees([this.currentEmployeeId])
          ]);
        })
      )
      .subscribe(([profile, workPercentage, preferencesData, assignedRoles]) => {
        this.employeeForm = this.formBuilder.group({
          id: profile.id,
          active: [{ value: profile.active, disabled: true }],
          userName: [{ value: profile.username, disabled: true }],
          firstName: new FormControl(profile.firstName, [Validators.required, Validators.minLength(2)]),
          middleName: new FormControl(profile.middleName, [Validators.minLength(2)]),
          lastName: new FormControl(profile.lastName, [Validators.required, Validators.minLength(2)]),
          personalPresentation: new FormControl(profile.data.presentation, [Validators.minLength(5)]),
          workPhone: new FormControl(profile.data.workPhone, [Validators.minLength(7)]),
          mobilePhone: new FormControl(profile.data.mobilePhone, [Validators.minLength(7)]),
          workEmail: new FormControl(profile.email, [Validators.required, Validators.minLength(4)]),
          personalEmail: new FormControl(profile.data.personalEmail, [Validators.minLength(4)]),
          roles: new FormControl(assignedRoles[0]?.roleIds.map(x => this.roleList.find(r => r.id === x))),
          roleAssignments: this.setupFormGroupForRoleGroupAssignments(profile.employeeRoles),
          licenses: new FormControl(profile.qualifications.licenses.map(x => this.licensesList.find(l => l.id === x.id))),
          endorsements: new FormControl(profile.qualifications.unitEndorsements.map(x => this.endorsementsList.find(e => e.id === x.id))),
          selectedSkills: new FormControl(null),
          skills: this.setupSkills(profile.qualifications.skillsData),
          groups: this.setupGroups(profile.employeeGroups),
          workPercentages: this.formBuilder.array(
            workPercentage.values.map(x => {
              return this.formBuilder.group({
                id: [x.id],
                dateFrom: [moment(x.dateFrom).format(ISO_DATE_ONLY_FORMAT), [Validators.required, Validators.minLength(10)]],
                value: [Number(x.value), [Validators.required, Validators.min(1), Validators.max(100)]]
              });
            }),
            this.ValidatePercentagesAreUnique
          ),
          healthyRotation: [preferencesData.healthyRotation, Validators.required],
          hardWorkDurationMax: [preferencesData.hardWorkDurationMax, [Validators.required, Validators.min(9), Validators.max(12.5)]],
          hardWeeklyWorkMax: [preferencesData.hardWeeklyWorkMax, [Validators.required, Validators.min(0), Validators.max(54), Validators.pattern('[0-9]+')]],
          hardConsecutiveWeeklyOff: [preferencesData.hardConsecutiveWeeklyOff],
          standardWorkingHoursPerWeek: [preferencesData.standardWorkingHoursPerWeek, [Validators.required, Validators.min(0), Validators.max(100)]],
          workingDaysInRow: [preferencesData.workingDaysInRow, [Validators.required, Validators.min(0), Validators.max(100), Validators.pattern('[0-9]+')]],
          hardAfterShiftBreak: [preferencesData.hardAfterShiftBreak, [Validators.required, Validators.min(8), Validators.max(11)]],
          hardNightShiftOK: [preferencesData.hardNightShiftOK, Validators.required],
          unit: '',
          group: [-1],
          preferencesId: preferencesData.id,
          showLegacyGroups: [false],
          showLegacyPeriods: [false]
        });
        this.loadAveragingPeriods();
        this.loading = false;
        this.isBusy = false;
        this.changeDetector.markForCheck();
      });
  }

  private setupSkills(skills: SkillModel[]): FormGroup {
    const group = this.formBuilder.group({});
    skills.forEach(skill => {
      if (!group.value[skill.id.toString()]) {
        const formArray = this.formBuilder.array<FormGroup<DateAndIdForm>>([]);
        group.addControl(skill.id.toString(), formArray);
      }

      const skillControl = this.formBuilder.group<DateAndIdForm>({
        id: new FormControl(skill.employeeSkillId),
        dates: new FormControl(
          {
            dateFrom: new Date(skill.validFrom),
            dateTo: (skill.validTo && new Date(skill.validTo)) || null
          },
          [this.dateFromToValidatorForSkills(skill.id)]
        )
      });

      group.controls[skill.id].push(skillControl);
      if (skillControl.value.dates.dateTo && moment(skillControl.value.dates.dateTo).isBefore(moment(), 'day')) {
        skillControl.disable();
      }
    });

    return group;
  }

  private setupGroups(groups: EmployeeGroupModel[]): FormGroup {
    const formGroup = this.formBuilder.group({});
    groups.forEach(employeeGroup => {
      if (!formGroup.value[employeeGroup.groupId.toString()]) {
        const formArray = this.formBuilder.array<FormGroup<DateAndIdForm>>([]);
        formGroup.addControl(employeeGroup.groupId.toString(), formArray);
      }

      formGroup.controls[employeeGroup.groupId].push(
        this.formBuilder.group<DateAndIdForm>({
          id: new FormControl(employeeGroup.id),
          dates: new FormControl(
            {
              dateFrom: new Date(employeeGroup.validFrom),
              dateTo: (employeeGroup.validTo && new Date(employeeGroup.validTo)) || null
            },
            [this.dateFromToValidatorForGroups()]
          )
        })
      );
    });

    return formGroup;
  }

  private loadAveragingPeriods() {
    const groupControls = this.employeeForm.controls.groups.controls;
    this.settingsService
      .loadAveragingPeriodsForGroupsAndEmployee(
        Object.keys(groupControls)
          .filter(groupId => groupControls[groupId].controls.length > 0)
          .map(value => +value),
        this.currentEmployeeId
      )
      .subscribe(result => {
        this.averagingPeriods = result.averagingPeriods;
        this.employeeForm.removeControl('averagingPeriodOffsets');
        this.employeeForm.addControl('averagingPeriodOffsets', this.setupAveragingPeriodFormGroup(result.offsets));
        this.loadStatisticsForAveragingPeriods();
        this.changeDetector.markForCheck();
      });
  }

  private loadStatisticsForAveragingPeriods() {
    this.profileService
      .getStatisticsForEmployeeAveragingPeriods(
        this.currentEmployeeId,
        Object.keys(this.employeeForm.value.groups).map(key => +key)
      )
      .subscribe(result => {
        const stats = result[this.currentEmployeeId]?.reduce(function (acc, current) {
          acc[current.averagePeriod] = current;
          return acc;
        }, {});

        this.statisticsForAveragingPeriods = stats || {};
        this.changeDetector.markForCheck();
      });
  }

  private setupAveragingPeriodFormGroup(offsets: EmployeeAveragingPeriodOffsetResult[]) {
    const formGroup = this.formBuilder.group({});
    Object.values(this.averagingPeriods)
      .flat<SettingValue[]>()
      .forEach(period => {
        const value = offsets.find(offset => offset.averagingPeriod.id === period.id)?.offset;
        formGroup.addControl(period.id.toString(), new FormControl(value ?? null));
      });
    return formGroup;
  }

  private setupFormGroupForRoleGroupAssignments(employeeRoles: EmployeeRoleModel[]): FormGroup<{
    [roleName: string]: FormControl<GroupModel[]>;
  }> {
    const group = this.formBuilder.group({});
    this.rolesWithAssignments.forEach(role => {
      if (!group.value[role]) {
        group.addControl(role, new FormControl(employeeRoles.find(x => x.role.name === role)?.assignments ?? []));
      }
    });

    return group;
  }

  private dateFromToValidatorForSkills(skillId: number) {
    return (control: AbstractControl<DateRange>): ValidationErrors => {
      const value = control.value;
      const otherRanges = this.getUnavailableRangesForControl(skillId, control);
      if (!otherRanges?.length) {
        control.setErrors(null);
        return null;
      }

      const error = otherRanges.some(range => dateRangesOverlap(value, range));
      if (error) {
        return {
          datesOverlap: 'Date range overlaps with another one.'
        };
      } else {
        control.setErrors(null);
      }
      return null;
    };
  }

  private dateFromToValidatorForGroups() {
    return (control: AbstractControl<DateRange>): ValidationErrors => {
      const value = control.value;
      const otherRanges = this.getUnavailableRangesForGroup(control);
      if (!otherRanges?.length) {
        control.setErrors(null);
        return null;
      }

      const error = otherRanges.some(range => dateRangesOverlap(value, range));
      if (error) {
        return {
          datesOverlap: 'Date range overlaps with another one.'
        };
      } else {
        control.setErrors(null);
      }
      return null;
    };
  }

  private getUnavailableRangesForControl(skillId: number, control: AbstractControl): DateRange[] {
    const skillsControls = this.employeeForm?.controls.skills.controls[skillId]?.controls;

    return skillsControls?.filter(c => c.controls.dates !== control).map(c => c.getRawValue().dates);
  }

  private getUnavailableRangesForGroup(control: AbstractControl): DateRange[] {
    return this.allGroupsRangesFlat?.filter(c => c.controls.dates !== control).map(c => c.value.dates);
  }

  public unitSelected(): void {
    const unit = this.employeeForm.controls.unit.value;
    this.filteredGroupList = this.groupList.filter(x => x.unit.name === unit);

    if (this.filteredGroupList.length === 1) {
      this.employeeForm.controls.group.setValue(this.filteredGroupList[0].id);
    } else {
      this.employeeForm.controls.group.setValue(-1);
    }
  }

  private ValidatePercentagesAreUnique(control: AbstractControl) {
    const dates = new Set(control.value.map(x => x.dateFrom));
    if (dates.size < control.value.length) {
      return { duplicatedDateFrom: true };
    }

    return null;
  }

  private ValidatePartnerModeSelected(control: AbstractControl) {
    if (control.value.partner && control.value.sameScheduleWithPartner === null) {
      return { partnerWithoutMode: true };
    }

    return null;
  }

  public pastDateFilter = (d: Date | null): boolean => {
    const today = moment().startOf('day');
    return this.employeeForm.controls.showLegacyGroups.value || (d && moment(d).isSameOrAfter(today));
  };

  public pastDateFilterPeriods = (d: Date | null): boolean => {
    const today = moment().startOf('day');
    return this.employeeForm.controls.showLegacyPeriods.value || (d && moment(d).isSameOrAfter(today));
  };

  private getRoleAssignments(): { role: number; group: number }[] {
    const roleAssignments = [];

    this.rolesWithAssignments.forEach(role => {
      if (this.hasRole(role)) {
        const roleId = this.roleList.find(r => r.title === role).id;
        this.employeeForm.value.roleAssignments[role].forEach(group => {
          roleAssignments.push({
            role: roleId,
            group: group.id
          });
        });
      }
    });

    return roleAssignments;
  }
}
