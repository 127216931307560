import { Injectable } from '@angular/core';
import { remove } from 'lodash-es';
import { BehaviorSubject, Subject } from 'rxjs';
import { CellDescriptor } from '../dto/cell-descriptor.model';

@Injectable({
  providedIn: 'root'
})
export class GridSelectionState {
  private readonly selectionsResetSource = new Subject<void>();
  private readonly cellsHighlightedSource = new Subject<void>();
  private readonly selectionsSource = new BehaviorSubject<CellDescriptor[]>([]);

  public selections$ = this.selectionsSource.asObservable();
  public selectionsReset$ = this.selectionsResetSource.asObservable();
  public cellsHighlighted$ = this.cellsHighlightedSource.asObservable();

  public selectedEmployeeId = -1;
  public selectedTimestamp = '';

  public getSelections(): readonly CellDescriptor[] {
    return this.selectionsSource.getValue();
  }

  public isSelected(timestamp: string, employeeId: number): boolean {
    return !!this.getSelections().find(x => x.timestamp === timestamp && x.employeeId === employeeId);
  }

  public addSelection(selection: CellDescriptor): void {
    const selections = this.selectionsSource.getValue();
    if (selections.find(x => x.employeeId === selection.employeeId && x.timestamp === selection.timestamp)) {
      return;
    }

    selections.push(selection);

    this.selectionsSource.next(selections);
  }

  public setSelection(selection: CellDescriptor): void {
    this.selectionsSource.next([selection]);
    this.selectionsResetSource.next();
  }

  public removeSelection(selection: CellDescriptor): void {
    const selections = this.selectionsSource.getValue();
    remove(selections, x => x.employeeId === selection.employeeId && x.timestamp === selection.timestamp);

    this.selectionsSource.next(selections);
  }

  public resetSelections(): void {
    this.selectionsSource.next([]);
    this.selectionsResetSource.next();
  }

  public highlightColumn(timestamp: string): void {
    if (this.selectedTimestamp === timestamp) {
      this.selectedTimestamp = '';
    } else {
      this.selectedTimestamp = timestamp;
    }
    this.cellsHighlightedSource.next();
  }

  public highlightRow(employeeId: number): void {
    if (this.selectedEmployeeId === employeeId) {
      this.selectedEmployeeId = -1;
    } else {
      this.selectedEmployeeId = employeeId;
    }
    this.cellsHighlightedSource.next();
  }

  public highlightRowAndColumn(employeeId: number, timestamp: string): void {
    if (this.selectedTimestamp === timestamp && this.selectedEmployeeId === employeeId) {
      this.selectedTimestamp = '';
      this.selectedEmployeeId = -1;
    } else {
      this.selectedTimestamp = timestamp;
      this.selectedEmployeeId = employeeId;
    }
    this.cellsHighlightedSource.next();
  }

  public get hasAnythingSelected(): boolean {
    return this.getSelections().length > 0;
  }
}
