import { Injectable } from '@angular/core';
import { ErrorService } from '@services';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { RequiredShift, RequiredShiftLiveResult } from '../../../rostr/overview/dto';
import { CreateRequiredShiftPayload } from '../../models/rostr/create-required-shift.payload';
import {
  copyRequiredShifts,
  createRequiredShift,
  deleteRequiredShifts,
  getRequiredShifts,
  removeRequiredScheduleShift,
  replaceRequiredShifts,
  requiredShiftsCreatedSubscription,
  requiredShiftsDeletedSubscription,
  requiredShiftsUpdatedSubscription,
  updateRequiredShift
} from '../../queries/required-shifts.queries';
import { SearchAndReplaceShiftsPayload } from '@app/rostr/overview/api/payloads/search-and-replace-shifts.payload';

@Injectable({ providedIn: 'root' })
export class RequiredShiftsAPIService {
  constructor(
    private readonly apollo: Apollo,
    private readonly errorService: ErrorService
  ) {}

  public getRequiredShifts(groupsIds: number[], dateFrom: string, dateTo: string): Observable<RequiredShift[]> {
    return this.apollo
      .query<{ requiredShifts: RequiredShift[] }>({
        query: getRequiredShifts,
        variables: {
          groupsIds: groupsIds,
          dateFrom: dateFrom,
          dateTo: dateTo
        }
      })
      .pipe(
        map(response => {
          return response.data.requiredShifts;
        }),
        catchError(err => {
          return this.errorService.handle(err);
        })
      );
  }

  public createRequiredShift(payload: CreateRequiredShiftPayload): Observable<void> {
    return this.apollo
      .mutate({
        mutation: createRequiredShift,
        variables: payload
      })
      .pipe(
        catchError(err => {
          return this.errorService.handle(err);
        })
      ) as Observable<void>;
  }

  public updateRequiredShift(id: number, isLocked: boolean, isExcluded: boolean) {
    return this.apollo
      .mutate({
        mutation: updateRequiredShift,
        variables: {
          id,
          isLocked,
          isExcluded
        }
      })
      .pipe(catchError(err => this.errorService.handle(err)));
  }

  public deleteRequiredShifts(shiftIds: number[]): Observable<void> {
    return this.apollo
      .mutate({
        mutation: deleteRequiredShifts,
        variables: {
          ids: shiftIds
        }
      })
      .pipe(
        catchError(err => {
          return this.errorService.handle(err);
        })
      ) as Observable<void>;
  }

  public removeRequiredScheduleShift(shiftId: number): Observable<void> {
    return this.apollo
      .mutate({
        mutation: removeRequiredScheduleShift,
        variables: {
          id: shiftId
        }
      })
      .pipe(
        catchError(err => {
          return this.errorService.handle(err);
        })
      ) as Observable<void>;
  }

  public copyRequiredShifts(shiftsToCopy: { shiftsIds: number[]; targetTimestamp: string }[], groupId: number): Observable<void> {
    return this.apollo
      .mutate({
        mutation: copyRequiredShifts,
        variables: {
          shiftsToCopy: shiftsToCopy,
          groupId: groupId
        }
      })
      .pipe(
        catchError(err => {
          return this.errorService.displayErrorMessage(err);
        })
      ) as Observable<void>;
  }

  public replaceRequiredShifts(payload: SearchAndReplaceShiftsPayload): Observable<RequiredShift[]> {
    return this.apollo
      .mutate({
        mutation: replaceRequiredShifts,
        variables: payload
      })
      .pipe(
        map(
          (response: any) => response.data.replaceRequiredShifts,
          catchError((err: Error) => {
            return this.errorService.handle(err);
          })
        )
      );
  }

  public onRequiredShiftsCreated$(groupsIds: number[]): Observable<RequiredShiftLiveResult> {
    return this.apollo
      .subscribe({
        query: requiredShiftsCreatedSubscription,
        variables: {
          groupsIds
        }
      })
      .pipe(
        map(
          (response: any) => response.data.requiredShiftsCreated,
          catchError((err: Error) => {
            return this.errorService.handle(err);
          })
        )
      );
  }

  public onRequiredShiftsUpdated$(groupsIds: number[]): Observable<RequiredShiftLiveResult> {
    return this.apollo
      .subscribe({
        query: requiredShiftsUpdatedSubscription,
        variables: {
          groupsIds
        }
      })
      .pipe(
        map(
          (response: any) => response.data.requiredShiftsUpdated,
          catchError((err: Error) => {
            return this.errorService.handle(err);
          })
        )
      );
  }

  public onRequiredShiftsDeleted$(groupsIds: number[]): Observable<RequiredShiftLiveResult> {
    return this.apollo
      .subscribe({
        query: requiredShiftsDeletedSubscription,
        variables: {
          groupsIds
        }
      })
      .pipe(
        map(
          (response: any) => response.data.requiredShiftsDeleted,
          catchError((err: Error) => {
            return this.errorService.handle(err);
          })
        )
      );
  }
}
