import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-mini';

@Pipe({name: 'shMoment'})

export class MomentPipe implements PipeTransform {
  transform(value: moment.MomentInput | string | Date, format: string, fromFormat: string = null): string {
    if (moment.isMoment(value) && format) {
      return value.format(format);
    } else {
      if (value) {
        return moment(value, fromFormat).format(format);
      } else {
        return '';
      }
    }
  }
}
