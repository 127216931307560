import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { environment } from '@src/environments/environment';
import { Observable, Observer } from 'rxjs';
import { ConfigService, ENVIRONMENTS } from '@core';

import { AuthenticationService } from '@auth';

import { roleRoutesConfiguration, RoleRoutes, RoleRoute } from '../configs/role-routes-configuration';
import { ERROR } from '@helpers';

@Injectable()
export class TestersGuard {
  constructor(
    private router: Router,
    private auth: AuthenticationService,
    private configService: ConfigService
  ) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return Observable.create((observer: Observer<any>) => {
      this.auth.getLazyUser().subscribe(user => {
        /*
         * Direct a user to the appropriate module based on their auth role
         */

        if (environment.env === ENVIRONMENTS.LOCAL) {
          observer.next(true);
          return;
        }

        const urls = state.url.split('/');
        const module = urls[1];
        const page = urls[2];
        const allowedRoutes = roleRoutesConfiguration.filter(roleRoutes => this.auth.userRoles.hasRole(roleRoutes.roles));

        if (allowedRoutes.length === 0) {
          observer.next(false);
          return;
        }

        const allow = allowedRoutes.some((roleRoutes: RoleRoutes) =>
          roleRoutes.routes.some((roleRoute: RoleRoute) => {
            const moduleAllowed = roleRoute.module === module;
            const allModulePagesAllowed = !roleRoute.pages;
            const pageAllowed = allModulePagesAllowed || roleRoute.pages.includes(page);

            return moduleAllowed && pageAllowed;
          })
        );

        if (!allow) {
          const shouldNavigateToDefaultPage = location.pathname === '/';
          const url = shouldNavigateToDefaultPage ? allowedRoutes[0].defaultPage : [ERROR.NOT_FOUND];
          this.router.navigate(url);
        }

        observer.next(true);
      });
    });
  }

  public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.canActivate(route, state);
  }
}
