<div class="tooltip-content" [ngClass]="{ 'tooltip-content--blocked': isBlocked }">
  <div class="cols-container" *ngIf="!newDay.isLocked && newDay.isIncluded">
    <div class="col-item" *ngFor="let barChartElement of barChartElements">
      <div class="shift" [ngClass]="underDeliveryClasses(barChartElement.shift)">
        <span [ngClass]="underLinedMasterplan(barChartElement.shift)">{{ barChartElement.shift.value }}</span>
      </div>

      <div class="wish" *ngIf="!isInfo">
        <div class="wish-status material-icons" [ngClass]="wishStatusClass[barChartElement.wish?.status]" (click)="changeWishStatus(barChartElement.wish)">
          {{ barChartElement.wish?.icon }}
        </div>
        <div class="wish-dots">
          <i></i>
          <i></i>
        </div>
      </div>
    </div>
  </div>
</div>
