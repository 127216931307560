import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NouisliderModule } from 'ng2-nouislider';
import { CommonComponentsModule } from '@common/commonComponents.module';

// Components
import {
  AutocompleteComponent,
  DatepickerComponent,
  MatrixComponent,
  WidgetComponent,
  WidgetBaseComponent,
  TabComponent,
  HeaderComponent,
  DatepickerTooltipComponent,
  WheelMenuComponent,
  CommentTooltipComponent,
  CommentInputCreateComponent,
  CommentInputEditComponent,
  CommentListComponent,
  CommentsComponent,
  CommentsService,
  ValidatorComponent,
  BaseAppComponent,
  PlatformNavTooltipComponent,
  NavigationComponent,
  MainComponent,
  LogoutComponent,
  PanelComponent,
  FormInputComponent,
  Error404Component,
  ErrorCustomMessageComponent,
  ProfileWidgetComponent,
  ErrorPageComponent,
  ErrorNotLoggedInComponent,
  SettingsComponent,
  VacayRequestsComponent,
  CapybaraComponent
} from './components';
import { ConfigurationTooltipComponent } from './tooltips';

// Directives
import { LoadingDirective } from './directives';

// Pipes
import { MomentPipe, Highlight, CapitalizePipe, FilterByTitlePipe, SafeHtml, HumanizeDurationPipe, MinutesToTimePipe } from './pipes';

// Modules
import { TooltipModule } from './components/tooltip';

import { RouterModule } from '@angular/router';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { TextFieldModule } from '@angular/cdk/text-field';
import { ConfirmDeleteModalComponent } from '@component/modal/confirmDeleteModal.component';
import { SettingsService } from '@platform/_shared/services';

import { EmployeeService, NavigationService, WebSocketsService, EmployeeOverviewService, EntitySearchService } from './services';
import { AuthenticatedGuard, ModuleToggle, TestersGuard } from './guards';
import { HammerModule } from '@angular/platform-browser';
import { ToolTipRendererDirective } from './directives/custom-tooltip/custom-tooltip.directive';
import { CustomTooltipComponent } from './directives/custom-tooltip/custom-tooltip.component';
import { MatMenuModule } from '@angular/material/menu';
import { ColumnFilterComponent } from '@component/column-filter/column-filter.component';
import { MatBadgeModule } from '@angular/material/badge';
import { AckDialogComponent } from './components/ack-dialog/ack-dialog.component';
import { AutocompleteWrapperComponent } from '@component/autocomplete-wrapper/autocomplete-wrapper.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatTableModule } from '@angular/material/table';
import { DisableControlDirective } from './directives/disable-control.directive';
import { MatTooltipModule } from '@angular/material/tooltip';
import { VacayRequestStatusComponent } from '@component/vacay-requests/vacay-request-status/vacay-request-status.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { ConversationPanelModule } from '@component/conversation-panel/conversation-panel.module';
import { VacayRequestCommentsComponent } from '@component/vacay-requests/vacay-request-comments/vacay-request-comments.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RangeDatepickerComponent } from '@component/range-datepicker/range-datepicker.component';
import { SideBarComponent } from './components/side-bar/side-bar.component';
import { DefaultSidebarWidgetComponent } from './components/side-bar/components/default-sidebar-widget/default-sidebar-widget.component';
import { RangeDatepickerEndDateComponent } from '@component/range-datepicker/range-datepicker-end-date.component';
import { NotificationsMenuComponent } from './components/notifications/components/notifications-menu/notifications-menu.component';
import { NotificationsPopupComponent } from './components/notifications/components/notifications-popup/notifications-popup.component';
import { NotificationsSidebarWidgetComponent } from './components/notifications/components/notifications-sidebar-widget/notifications-sidebar-widget.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { NotificationsDialogComponent } from '@component/notifications/components/notifications-dialog/notifications-dialog.component';
import { SimpleInfoWidgetComponent } from './components/landing-page-widgets/simple-info-widget/simple-info-widget.component';
import { MatCardModule } from '@angular/material/card';
import { DonutChartWidgetComponent } from './components/landing-page-widgets/donut-chart-widget/donut-chart-widget.component';
import { BasicWidgetComponent } from '@component/landing-page-widgets/basic-widget/basic-widget.component';
import { NumberWithProgressBarWidgetComponent } from '@component/landing-page-widgets/number-with-progress-bar-widget/number-with-progress-bar-widget.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatListModule } from '@angular/material/list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NotificationsSettingsService } from './services/notifications/notifications-settings.service';
import { ShiftSummaryTooltipComponent } from '@component/shift-summary-tooltip/shift-summary-tooltip.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TooltipModule,
    NouisliderModule,
    RouterModule,
    MatSelectModule,
    MatCheckboxModule,
    MatRadioModule,
    TextFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    MatIconModule,
    MatButtonModule,
    CommonComponentsModule,
    MatDialogModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatDatepickerModule,
    HammerModule,
    MatMenuModule,
    MatBadgeModule,
    MatChipsModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    ConversationPanelModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
    MatCardModule,
    MatProgressBarModule,
    MatListModule,
    MatSlideToggleModule
  ],
  declarations: [
    // Components
    AutocompleteComponent,
    BaseAppComponent,
    DatepickerComponent,
    RangeDatepickerComponent,
    RangeDatepickerEndDateComponent,
    NavigationComponent,
    MainComponent,
    MatrixComponent,
    WidgetComponent,
    WidgetBaseComponent,
    ProfileWidgetComponent,
    TabComponent,
    HeaderComponent,
    DatepickerTooltipComponent,
    WheelMenuComponent,
    CommentTooltipComponent,
    CommentInputCreateComponent,
    CommentsComponent,
    CommentInputEditComponent,
    CommentListComponent,
    SettingsComponent,
    ConfigurationTooltipComponent,
    ValidatorComponent,
    PlatformNavTooltipComponent,
    LogoutComponent,
    ConfirmDeleteModalComponent,
    PanelComponent,
    FormInputComponent,
    ErrorPageComponent,
    ErrorNotLoggedInComponent,
    Error404Component,
    ErrorCustomMessageComponent,
    CustomTooltipComponent,
    ColumnFilterComponent,
    AutocompleteWrapperComponent,
    VacayRequestsComponent,
    VacayRequestStatusComponent,
    VacayRequestCommentsComponent,
    ShiftSummaryTooltipComponent,
    CapybaraComponent,

    // Directives

    LoadingDirective,
    ToolTipRendererDirective,
    DisableControlDirective,

    // Pipes
    MomentPipe,
    Highlight,
    CapitalizePipe,
    FilterByTitlePipe,
    SafeHtml,
    MinutesToTimePipe,
    HumanizeDurationPipe,
    AckDialogComponent,
    SideBarComponent,
    DefaultSidebarWidgetComponent,
    NotificationsMenuComponent,
    NotificationsPopupComponent,
    NotificationsSidebarWidgetComponent,
    NotificationsDialogComponent,

    // Widgets
    BasicWidgetComponent,
    NumberWithProgressBarWidgetComponent,
    SimpleInfoWidgetComponent,
    DonutChartWidgetComponent
  ],
  exports: [
    // Modules
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NouisliderModule,
    TooltipModule,
    MatSelectModule,
    MatCheckboxModule,
    MatRadioModule,
    TextFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    MatIconModule,
    MatButtonModule,
    MatSnackBarModule,
    MatBadgeModule,
    CommonComponentsModule,
    MatTableModule,
    MatProgressSpinnerModule,
    MatListModule,
    MatCardModule,
    MatChipsModule,

    // Components
    AutocompleteComponent,
    DatepickerComponent,
    RangeDatepickerComponent,
    RangeDatepickerEndDateComponent,
    MainComponent,
    BaseAppComponent,
    MatrixComponent,
    WidgetComponent,
    ProfileWidgetComponent,
    TabComponent,
    HeaderComponent,
    DatepickerTooltipComponent,
    WheelMenuComponent,
    CommentTooltipComponent,
    CommentInputCreateComponent,
    CommentsComponent,
    CommentInputEditComponent,
    CommentListComponent,
    SettingsComponent,
    NavigationComponent,
    ValidatorComponent,
    PlatformNavTooltipComponent,
    LogoutComponent,
    ConfirmDeleteModalComponent,
    PanelComponent,
    FormInputComponent,
    ColumnFilterComponent,
    AutocompleteWrapperComponent,
    SideBarComponent,
    NotificationsPopupComponent,
    VacayRequestsComponent,
    ShiftSummaryTooltipComponent,
    CapybaraComponent,

    // Directives
    LoadingDirective,
    ToolTipRendererDirective,
    DisableControlDirective,

    // Pipes
    MomentPipe,
    Highlight,
    CapitalizePipe,
    FilterByTitlePipe,
    SafeHtml,
    MinutesToTimePipe,
    HumanizeDurationPipe,

    // Widgets
    BasicWidgetComponent,
    NumberWithProgressBarWidgetComponent,
    SimpleInfoWidgetComponent,
    DonutChartWidgetComponent
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        EntitySearchService,
        NavigationService,
        NotificationsSettingsService,
        WebSocketsService,
        CommentsService,
        EmployeeService,
        EmployeeOverviewService,
        AuthenticatedGuard,
        TestersGuard,
        ModuleToggle,
        SettingsService
      ]
    };
  }
}
