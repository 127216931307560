import * as d3 from 'd3';

import { pathForRoundedRect, translate, xmlns } from '@shared/helpers/d3';

import { ScheduleModel } from '@shared/models';
import { SchedulesTimeline } from './timeline';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

export class Schedule {
  private readonly $el: d3.Selection<any, any, any, any>;
  private readonly el: Element;
  private readonly $line: d3.Selection<any, any, any, any>;
  private readonly $bg: d3.Selection<any, any, any, any>;
  private readonly $completed: d3.Selection<any, any, any, any>;
  private tooltipSub: Subscription;
  private resizeSub: Subscription;

  static fullOpacity = 0.9;
  static lowOpacity = 0.6;

  constructor(public model: ScheduleModel, public groupIndex: number, private readonly _timeline: SchedulesTimeline) {
    this.el = document.createElementNS(xmlns, 'g');
    this.$el = d3.select(this.el).classed('schedule', true);

    this.$bg = this.$el
      .append('path')
      .classed('bg', true)
      .attr('fill', this.color)
      .attr('opacity', this.isStarted ? Schedule.fullOpacity : Schedule.lowOpacity);

    this.$line = this.$el
      .append('rect')
      .classed('colored-line', true)
      .attr('x', 0)
      .attr('y', this.height - this._timeline.sizes.lineHeight)
      .attr('height', this._timeline.sizes.lineHeight)
      .attr('fill', this.isStarted ? this.color : 'rgba(0,0,0,0.1)');
    if (this.isStarted && !this.isFinished) {
      this.$line.style('mask', this._timeline.patternUrl(`${this._timeline.hatchId}-mask`));
    }

    this.$el
      .append('rect')
      .classed('colored-line', true)
      .attr('x', 0)
      .attr('y', 0)
      .attr('height', this.height)
      .attr('fill', this.isStarted ? this.color : 'rgba(0,0,0,0.1)');

    this.$completed = this.$el
      .append('path')
      .classed('completed', true)
      .attr('visibility', 'hidden')
      .attr('fill', this.color)
      .attr('opacity', Schedule.fullOpacity);

    this.events();
    this.subscribe();
  }

  get width() {
    const ts = this._timeline.timeScale;
    if (this.model.dateToMoment.isAfter(this._timeline.endDate)) {
      return ts(this._timeline.endDate) - ts(this.model.dateFromMoment);
    }
    if (this.model.dateFromMoment.isBefore(this._timeline.startDate)) {
      return ts(this.model.dateToMoment) - ts(this._timeline.startDate);
    }
    return ts(this.model.dateToMoment) - ts(this.model.dateFromMoment);
  }

  get yOffset() {
    return this._timeline.groupItems[this.groupIndex].yOffset;
  }

  get height() {
    return this._timeline.groupItems[this.groupIndex].height;
  }

  get color() {
    return this._timeline.getColor(this.groupIndex);
  }

  get isStarted() {
    return this.model.dateFromMoment.isBefore(this._timeline.now);
  }

  get isFinished() {
    return this.model.dateToMoment.isBefore(this._timeline.now);
  }

  public remove() {
    this.resizeSub.unsubscribe();
    this.tooltipSub.unsubscribe();
    this.$el.remove();
  }

  public node() {
    return this.el;
  }

  /**
   * Rendering functionality
   * @returns {Element}
   */
  public render() {
    const s = this._timeline.sizes;
    const ts = this._timeline.timeScale;
    const xOffset = this.model.dateFromMoment.isBefore(this._timeline.startDate) ? ts(this._timeline.startDate) : ts(this.model.dateFromMoment);

    this.$el.attr('transform', translate(xOffset, this.yOffset));

    this.$bg.attr(
      'd',
      pathForRoundedRect(0, this.height - s.scheduleHeight, this.width - s.schedulePadding, s.scheduleHeight, 6, true, true, false, false)
    );

    if (this.isStarted && !this.isFinished && this._timeline.now.year() === this._timeline.context.selectedYear) {
      this.$completed
        .attr('visibility', 'visible')
        .attr(
          'd',
          pathForRoundedRect(
            0,
            this.height - s.scheduleHeight,
            ts(this._timeline.now) - ts(this.model.dateFromMoment),
            s.scheduleHeight,
            6,
            true,
            false,
            false,
            false
          )
        );
      this.$bg.attr('opacity', Schedule.lowOpacity);
    }

    this.$line.attr('width', this.width - s.schedulePadding);

    return this.el;
  }

  private events() {
    this.tooltipSub = fromEvent(this.$el.node(), 'mouseenter')
      .pipe(debounceTime(200))
      .subscribe(() => this._timeline.context.showScheduleTooltip(this));
    this.$el.on('click touchstart', () => this._timeline.context.openSchedule(this.model));
  }

  private subscribe() {
    this.resizeSub = this._timeline.onResize.subscribe(() => this.render());
  }
}
