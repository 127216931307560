import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { HttpErrorResponse } from '@angular/common/http';
import { AppInsightsService } from './app-insights.service';

@Injectable({
  providedIn: 'root'
})
export class LoggingService {
  constructor(private appInsightsService: AppInsightsService) { }

  public log(message: string): void {
    Sentry.captureMessage(message, 'log');
    this.appInsightsService.logInformation(message);
  }

  public info(message: string): void {
    Sentry.captureMessage(message, 'info');
    this.appInsightsService.logInformation(message);
  }

  public debug(message: string): void {
    Sentry.captureMessage(message, 'debug');
  }

  public warn(message: string): void {
    Sentry.captureMessage(message, 'warning');
    this.appInsightsService.logWarning(message);
  }

  public error(error: Error | HttpErrorResponse): void {
    Sentry.captureException(error);
    this.appInsightsService.logException(error);
  }
}
