export const TIMELINE_FILTERS = {
  data: {
    Title: false,
    Summary: false,
    Duration: false,
    Group: false
  },
  hide: {
    'Non viewable': false,
    'Not authored': false
  },
  clustering: {
    'By year': true,
    'By month': false,
    'By author': false
  },
  sort: {
    'Last change': 'updatedAt',
    'Schedule date': 'dateFrom',
    'Created date': 'createdAt'
  }
};
