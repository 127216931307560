export enum WishStatus {
  Wish = 1,
  Available = 0
}


export class VacayRequestWish {

  public shiftAlias: number;
  public status: WishStatus;

  constructor(data: any = {}) {
    this.shiftAlias = data.shiftAlias;
    this.status = data.status;
  }

  get icon() {
    switch (this.status) {
      case WishStatus.Wish:
        return 'check_circle';

      case WishStatus.Available:
        return 'thumbs_up_down';
    }
  }

  get textStatus() {
    switch (this.status) {
      case WishStatus.Wish:
        return 'wish';

      case WishStatus.Available:
        return 'available';
    }
  }

}
