<ul *ngIf="items.length">
  <li *ngFor="let item of items; let isFirst = first" (click)="select(item)"
    [ngClass]="{centered: !item.icon, active: activeItem === item}">
    <a (click)="onClick(item)">
      <span class="arrow-container" *ngIf="isFirst">
        <span class="arrow"></span>
      </span>
      <span *ngIf="item.icon" class="material-icons" [ngClass]="item.icon">{{item.icon}}</span>
      <pre class="title">{{item.title}}</pre>
    </a>
  </li>
</ul>
