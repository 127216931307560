<div class="pl-navigation">
  <div class="left">
    <i class="logo"></i>
  </div>
  <div class="middle">
    <a #moduleMenu class="button-nav with-icon" (click)="openNavMenu(true)">
      <i class="material-icons" [ngClass]="activeModule.icon">{{ activeModule.icon }}</i>
      <span class="title" [title]="(activeModule && activeModule.title) || ''">
        {{ (activeModule && activeModule.title) || '' }}
      </span>
    </a>
    <a #viewMenu class="button-nav" (click)="openNavMenu(false)">
      <span class="title" [title]="(activeView && activeView.title) || ''">{{ (activeView && activeView.title) || '' }}</span>
    </a>
  </div>
  <div class="right">
    <app-notifications-menu class="button-nav no-padding"></app-notifications-menu>
    <a class="button-nav with-icon" [matMenuTriggerFor]="userMenu">
      <span class="title d-none d-lg-block" [title]="user.fullName">{{ user.fullName }}</span>
      <i class="material-icons">account_circle</i>
    </a>
  </div>
</div>
<mat-menu #userMenu="matMenu">
  <button mat-menu-item routerLink="/logout">
    <mat-icon>exit_to_app</mat-icon>
    <span>Logout</span>
  </button>
</mat-menu>
