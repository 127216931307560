import { Component, Input, forwardRef, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true
    }
  ]
})
export class CheckboxComponent implements ControlValueAccessor {
  @Input() value = false;

  @Input() fullWidth: boolean;

  @Input() labelPosition: 'before' | 'after' = 'before';

  @Input() disabled: boolean;

  @Input() indeterminate: boolean;

  @Output() checkboxChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public onChangeRegisteredFunctions: ((value: boolean) => void)[] = [];
  public onTouchedRegisteredFunctions: (() => void)[] = [];

  writeValue(value: boolean): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChangeRegisteredFunctions.push(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouchedRegisteredFunctions.push(fn);
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  valueChanged(checked: boolean) {
    this.value = checked;
    this.onChangeRegisteredFunctions.forEach(f => f(checked));
    this.checkboxChange.next(checked);
  }
}
