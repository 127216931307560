export function lower(
  array: any[],
  key: any,
  get: Function,
  isSame = true,
) {
  let lo = 0;
  let hi = array.length;
  let mid;

  while (lo < hi) {
    mid = (lo + hi) >> 1;
    if (
      (isSame && key <= get(array[mid]))
      || (!isSame && key < get(array[mid]))
    ) {
      hi = mid;
    } else {
      lo = mid + 1;
    }
  }

  return lo;
}

export function upper(
  array: any[],
  key: any,
  get: Function,
  isSame = false,
) {
  let lo = 0;
  let hi = array.length;
  let mid;

  while (lo < hi) {
    mid = (lo + hi) >> 1;
    if (
      (isSame && key >= get(array[mid]))
      || (!isSame && key > get(array[mid]))
    ) {
      lo = mid + 1;
    } else {
      hi = mid;
    }
  }
  return lo;
}

export function zeroFill(number: number, length: number) {
  const string = number.toString();
  const countZero = length - string.length;
  const arrayZero = Array(countZero).fill('0');

  return arrayZero.join() + string;
}

export function arrayFromMap<V = any>(map: Map<any, V>): V[] {
  return Array.from(map.values());
}
