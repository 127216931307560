import { BaseModel } from '@model/base.model';
import { VacayRequestBatchParams } from '@model/interfaces';
import { VacayRequestModel } from './vacay-request.model';
import moment from 'moment';

export class VacayRequestBatch extends BaseModel {
  public vacayRequests: VacayRequestModel[];
  public comment: string;
  public dateFrom: Date;
  public dateTo: Date;

  constructor(data: VacayRequestBatchParams) {
    super(data);
    this.vacayRequests = data.vacayRequests?.map(vacayRequest => new VacayRequestModel(vacayRequest));
    this.comment = data.comment;
    this.dateFrom = data.dateFrom && new Date(data.dateFrom);
    this.dateTo = data.dateTo && new Date(data.dateTo);
  }

  isSingleDay() {
    return this.dateFrom.toDateString() === this.dateTo.toDateString();
  }

  getReadableDatesDisplay() {
    const formatDate = (date: Date): string => {
      return moment(date).format('DD/MM/YYYY');
    };

    return this.isSingleDay() ? formatDate(this.dateFrom) : `${formatDate(this.dateFrom)} - ${formatDate(this.dateTo)}`;
  }
}
