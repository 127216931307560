import * as d3 from 'd3';
import { IMatrix } from '../matrix.interfaces';
import { Col } from '../cols/col';
import { Row } from '../rows/row';

export class Scroll {

  get hasNext() {
    return this._items.length > this.visibleItems && !this.last.isVisible;
  }

  get hasPrev() {
    return this._items.length > this.visibleItems && !this.first.isVisible;
  }

  get offset() {
    return this._offset;
  }

  get first() {
    return this._items[0];
  }

  get last() {
    return this._items[this._items.length - 1];
  }

  get visibleItems() {
    const width = this._matrix.svgOptions.width - this._matrix.titleSize - this._matrix.cellSize * 2 - 4;
    const height = this._matrix.svgOptions.height - this._matrix.titleSize - this._matrix.cellSize * 2 - 4;

    return Math.floor((!this._vertical ? width : height) / this._matrix.cellSize);
  }

  private $group: d3.Selection<any, any, any, any>;
  private $nextBtn: d3.Selection<any, any, any, any>;
  private $prevBtn: d3.Selection<any, any, any, any>;

  private _items: any[];

  private _offset = 0;

  public constructor(private _matrix: IMatrix,
    private _vertical = false) {

    this._items = this._vertical ? this._matrix.rows.rows : this._matrix.cols.cols;
    this.$group = this._vertical ? this._matrix.rows.container : this._matrix.cols.container;

    this._matrix.event.subscribe((event) => {
      this.init();
    });

    window.addEventListener('resize', () => {
      this.init();
    });

  }

  public render() {
    this.init();
  }

  public next() {
    if (!this.hasNext) {
      return false;
    }

    this._offset += 1;
    this.scroll(this._offset, 'next', true);
  }

  public prev() {
    if (!this.hasPrev) {
      return false;
    }

    this._offset -= 1;
    this.scroll(this._offset, 'prev', true);
  }

  private init() {
    this.scrollItems(this._offset);

    this.renderButtons();
  }

  private scroll(offset: number, type: 'prev' | 'next' = 'next', move = false) {
    this.scrollItems(offset, type, move);

    this.$nextBtn.classed('disable', !this.hasNext);
    this.$prevBtn.classed('disable', !this.hasPrev);
  }

  private renderButtons() {
    this.$group.selectAll('.matrix-scroll-btn').remove();

    this.renderNextBtn();
    this.renderPrevBtn();
  }

  private renderNextBtn() {
    this.$nextBtn = this.$group
      .append('g')
      .attr('class', 'matrix-scroll-btn')
      .classed('disable', !this.hasNext)
      .attr('transform', () => {
        const xTranslate = this._vertical ? 0 : this._matrix.titleSize - this._matrix.buttonSize;
        const yTranslate = (this.visibleItems < this._items.length + 1
          ? this.visibleItems : this._items.length) * this._matrix.cellSize + 4;

        return `translate(${xTranslate}, ${yTranslate})`;
      })
      .on('click', () => this.next());


    this.$nextBtn
      .append('rect')
      .attr('width', this._matrix.buttonSize)
      .attr('height', this._matrix.cellSize);

    this.$nextBtn
      .append('text')
      .text('Next')
      .style('text-anchor', 'middle')
      .attr('alignment-baseline', 'central')
      .attr('x', this._matrix.buttonSize / 2)
      .attr('y', this._matrix.halfCellSize);
  }

  private renderPrevBtn() {
    this.$prevBtn = this.$group
      .append('g')
      .attr('class', 'matrix-scroll-btn')
      .classed('disable', !this.hasPrev)
      .attr('transform', () => {
        const xTranslate = this._vertical ? 0 : this._matrix.titleSize - this._matrix.buttonSize;
        const yTranslate = -(this._matrix.cellSize + 4); // todo ???

        return `translate(${xTranslate}, ${yTranslate})`;
      })
      .on('click', () => this.prev());

    this.$prevBtn
      .append('rect')
      .attr('width', this._matrix.buttonSize)
      .attr('height', this._matrix.cellSize);

    this.$prevBtn
      .append('text')
      .text('Prev')
      .style('text-anchor', 'middle')
      .attr('alignment-baseline', 'central')
      .attr('x', this._matrix.buttonSize / 2)
      .attr('y', this._matrix.halfCellSize);
  }

  private scrollItems(offset: number, type: 'prev' | 'next' = 'next', move = false) {
    this.itemsSort();

    this._items.forEach((el: Col | Row, index: number) => {
      if (index >= offset && index < this.visibleItems + offset) {
        el.setVisible(true);
      } else {
        el.setVisible(false);
      }

      if (move) {
        el.moveBy(type === 'next' ? -this._matrix.cellSize : this._matrix.cellSize);
      }
    });
  }

  private itemsSort() {
    this._items.sort((a, b) => {
      if (a.index > b.index) {
        return 1;
      }
      if (a.index < b.index) {
        return -1;
      }
      return 0;
    });
  }

}
