import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { RequiredShiftsAPIService } from '@shared/services/required-shifts/required-shifts-api.service';
import { map, mergeMap, share, tap } from 'rxjs/operators';
import { ScheduleApiService } from '../../api/services/schedule-api.service';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment-mini';
import { BehaviorSubject, Observable, Subscription, combineLatest } from 'rxjs';
import { RequiredShift } from '../../dto';
import { ShiftType } from '@enums';
import { ShiftsAssignmentsAPIService } from '../../api/services/shifts-assignments-api.service';
import { getDatesBetween } from '@helpers';

@Component({
  selector: 'app-required-shifts-report',
  templateUrl: './required-shifts-report-modal.component.html',
  styleUrls: ['./required-shifts-report-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RequiredShiftsReportModalComponent implements OnInit, OnDestroy {
  public dates: string[] = [];
  public allShifts$ = new BehaviorSubject<RequiredShift[]>([]);
  private assignedShiftsIds: number[] = [];

  private createdSub: Subscription;
  private updatedSub: Subscription;
  private deletedSub: Subscription;
  private assignmentsChangedSub: Subscription;

  public dataByDate$: (date: string) => Observable<RequiredShiftsReportData> = (date: string) =>
    combineLatest([
      this.allShifts$.pipe(map(shifts => shifts.filter(s => s.timestamp === date && s.source === 'planning'))),
      this.allShifts$.pipe(map(shifts => shifts.filter(s => s.timestamp === date && s.source === 'rostr'))),
      this.allShifts$.pipe(map(shifts => shifts.filter(s => s.timestamp === date && !s.isExcluded))),
      this.allShifts$.pipe(map(shifts => shifts.filter(s => s.timestamp === date && s.isExcluded)))
    ]).pipe(
      map(([planned, surplus, actual, missing]) => {
        return {
          planned: planned,
          surplus: surplus,
          actual: actual,
          missing: missing,
          plannedCount: planned.length,
          surplusCount: surplus.length,
          actualCount: actual.length,
          missingCount: missing.length
        };
      }),
      share()
    );

  constructor(
    private readonly scheduleApi: ScheduleApiService,
    private readonly requiredShiftsAPIService: RequiredShiftsAPIService,
    private readonly shiftsAssignmentsAPIService: ShiftsAssignmentsAPIService,
    private readonly route: ActivatedRoute,
    private readonly changeDetector: ChangeDetectorRef
  ) {}

  public ngOnInit() {
    this.route.queryParams
      .pipe(
        mergeMap(schedule => this.scheduleApi.getSchedule(schedule.id)),
        tap(x => {
          this.assignedShiftsIds = x.assignedEmployees
            .flatMap(s => Object.values(s.assigned))
            .filter(s => s.parentType === ShiftType.Required)
            .map(s => s.parentId);
          this.dates = getDatesBetween(moment(x.dateFrom), moment(x.dateTo));
          this.setupSubs(
            x.groups.map(g => g.id),
            x.id
          );
        }),
        mergeMap(x =>
          this.requiredShiftsAPIService.getRequiredShifts(
            x.groups.map(g => g.id),
            x.dateFrom,
            x.dateTo
          )
        ),
        tap(x => {
          this.allShifts$.next(x.sort((a, b) => a.code.localeCompare(b.code)));
          this.changeDetector.markForCheck();
        })
      )
      .subscribe();
  }

  private setupSubs(groupsIds: number[], scheduleId: number) {
    this.createdSub = this.requiredShiftsAPIService.onRequiredShiftsCreated$(groupsIds).subscribe(result => {
      let value = this.allShifts$.value;
      value = value.concat(result.shifts);
      this.allShifts$.next(value);
      this.changeDetector.markForCheck();
    });

    this.updatedSub = this.requiredShiftsAPIService.onRequiredShiftsUpdated$(groupsIds).subscribe(result => {
      const value = this.allShifts$.value;

      for (const shift of result.shifts) {
        const existing = value.find(x => x.id === shift.id);
        existing.isExcluded = shift.isExcluded;
        existing.isLocked = shift.isLocked;
      }

      this.allShifts$.next(value);
      this.changeDetector.markForCheck();
    });

    this.deletedSub = this.requiredShiftsAPIService.onRequiredShiftsDeleted$(groupsIds).subscribe(result => {
      let value = this.allShifts$.value;
      value = value.filter(x => !result.shifts.map(s => s.id).includes(x.id));
      this.allShifts$.next(value);
      this.changeDetector.markForCheck();
    });

    this.assignmentsChangedSub = this.shiftsAssignmentsAPIService.onActivitiesChanged$(scheduleId).subscribe(result => {
      if (result.created?.length) {
        this.assignedShiftsIds = this.assignedShiftsIds.concat(result.created.map(s => s.shift.parentId));
      }

      if (result.deleted?.length) {
        this.assignedShiftsIds = this.assignedShiftsIds.filter(x => !result.deleted.map(s => s.shift.parentId).includes(x));
      }

      this.changeDetector.markForCheck();
    });
  }

  public ngOnDestroy() {
    this.createdSub?.unsubscribe();
    this.updatedSub?.unsubscribe();
    this.deletedSub?.unsubscribe();
    this.assignmentsChangedSub?.unsubscribe();
  }

  public isAssigned(shiftId: number) {
    return !!this.assignedShiftsIds.find(x => x === shiftId);
  }
}

export interface RequiredShiftsReportData {
  planned: RequiredShift[];
  surplus: RequiredShift[];
  actual: RequiredShift[];
  missing: RequiredShift[];
  plannedCount: number;
  surplusCount: number;
  actualCount: number;
  missingCount: number;
}
