import * as d3 from 'd3';
import * as moment from 'moment-mini';

import { IBaseRenderer } from './base-renderer.interface';
import { Day } from './day';
import { getYearByDateForCalendar } from './helpers';
import { translate } from '@helpers';

export class Week {
  public week: d3.Selection<any, any, any, any>; // d3 selection for current day of week
  public weekNumber: string; // The number to display to the user
  // array with links to Day objects which related with this week of day
  public weekDays: Day[] = [];
  public weekYear: number;
  // public weekDate: Date; // base week date
  // selection indicator. Will be true only if all of the days selected
  public isSelected = false;

  /**
   * @param renderer { Renderer } - the main class
   * @param index { number } - index of the week
   * @param weekDate { Date }
   */
  constructor(
    private renderer: IBaseRenderer,
    public index: number,
    public weekDate: Date
  ) {
    this.weekYear = getYearByDateForCalendar(weekDate, this.renderer.calendarYear);
  }

  /**
   * The main method to create and display current week
   */
  public render() {
    const self = this;

    this.renderer.weeksContainer.select(`week-${this.index}`).remove();
    this.weekNumber = this.calcWeekNumber();

    // console.log(this.renderer.svgOptions.scale.invert(this.index));
    this.week = this.renderer.weeksContainer
      .append('text')
      .attr('class', `weeks week-${this.index}`)
      .text(this.weekNumber)
      .attr('transform', function () {
        const textWidth = this.getComputedTextLength();
        return translate(self.renderer.svgOptions.weekScale.invert(self.index) + self.renderer.halfSize - textWidth / 2, 0);
      })
      .on('click', function () {
        const relatedDays = self.weekDays.filter(day => !day.disabled);

        relatedDays.forEach(day => {
          if (self.isSelected) {
            self.renderer.dropSelectedDate(day);
          } else {
            self.renderer.addSelectedDate(day);
          }
        });

        self.renderer.dateChanges.emit({
          type: self.isSelected ? 'group_remove' : 'group_add',
          changedDates: relatedDays.reduce((acc, day) => {
            acc.push(day.date);
            return acc;
          }, []),
          ranges: self.renderer.selectedRanges,
          activeUnit: self.renderer.activeUnit
        });
        self.selectAllDays(!self.isSelected && relatedDays.length > 0);
      });
    // this.fetchWeekDays();
  }

  /**
   * Calculate user friendly week number from 1 to 53
   * @returns { string } - number of the week
   */
  public calcWeekNumber(): string {
    // this.weekDate = new Date(first.getTime() + week * this.index);
    const w = moment(this.weekDate).isoWeek();
    return w < 10 ? `0${w}` : `${w}`;
  }

  /**
   * Memorization all related days
   * @param day { Day } - related day
   */
  public addRelatedDay(day: Day) {
    this.weekDays.push(day);
  }

  /*public fetchWeekDays() {
   for (let i = this.index * 7; i < (this.index * 7 + 7); i++) {
   if (isoWeek(this.renderer.days[i].date) === +this.weekNumber &&
   this.renderer.days[i].date.getFullYear() === this.renderer.calendarYear) {
   this.renderer.days[i].setWeek(this);
   this.weekDays.push(this.renderer.days[i]);
   }
   }
   }*/

  /**
   * Selecting/un-selecting all days of the related days
   * @param selectionStatus { boolean } true - select / false - un-select
   */
  public selectAllDays(selectionStatus) {
    for (const day of this.weekDays) {
      if (!day.disabled) {
        day.setSelection(selectionStatus, false);
      }
    }

    // this.highlightSelectedWeek();
  }

  // public checkWeekSelection() {
  //   let selectedDays = 0;
  //   for (let day of this.weekDays) {
  //     if (day.isSelected) { selectedDays++; }
  //   }
  //
  //   this.isSelected = selectedDays === 7;
  //
  //   this.highlightSelectedWeek();
  // }
  //
  // private highlightSelectedWeek() {
  //   ;
  // }

  /**
   * We do update of selected days counter and add/remove selected css-class
   */
  public updateSelection() {
    let countSelectedDays = 0;

    this.weekDays.forEach(day => day.isSelected && countSelectedDays++);
    this.isSelected = countSelectedDays === this.weekDays.length;

    this.week.classed('selected-label', this.isSelected);
  }
}
