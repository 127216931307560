<div class="search-wrapper">
  <div class="tooltip-container">
      <div class="pl-input-box bordered">
        <label for="filter" class="pl-label">Select configuration</label>
        <input
          #textInput
          id="filter"
          class="pl-input"
          autocomplete="off"
          [formControl]="searchStrControl"
          placeholder="Type to search...">
      </div>
  </div>

  <div
    class="configuration selected bordered active"
    *ngIf="!!currentConfiguration"
    [ngClass]="currentStatus">
    <div class="tooltip-container">
      <div class="left">
        <span class="shift">{{currentConfiguration.code}}</span>
      </div>
      <div class="right">
        <span
          *ngFor="let sector of currentConfiguration.sectorsAndCombinations"
          class="shift">{{sector.code}}</span>
        <span class="material-icons" (click)="unassignConfiguration()">clear</span>
      </div>
    </div>
  </div>

  <div #alternatives class="alternatives">
    <div
      class="configuration success"
      *ngFor="let configuration of predictedConfigurations.success"
      [class.active]="isSelectedConfigurarion(configuration)"
      (click)="assignConfiguration(configuration)">
      <div class="tooltip-container">
        <div class="left">
          <span class="shift">{{configuration.code}}</span>
        </div>
        <div class="right">
          <span
            *ngFor="let sector of configuration.sectorsAndCombinations"
            class="shift">{{sector.code}}</span>
        </div>
      </div>
    </div>

    <div
      class="configuration warn"
      *ngFor="let configuration of predictedConfigurations.warn"
      [class.active]="isSelectedConfigurarion(configuration)"
      (click)="assignConfiguration(configuration)">
      <div class="tooltip-container">
        <div class="left">
          <span class="shift">{{configuration.code}}</span>
        </div>
        <div class="right">
          <span
            *ngFor="let sector of configuration.sectorsAndCombinations"
            class="shift">{{sector.code}}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="more" (mousedown)="scrollAlternatives()">
    <span class="material-icons">keyboard_arrow_down</span>
  </div>
</div>
