import { LicenseModel } from './license.model';
import { SkillModel } from './skill.model';
import { UnitEndorsementModel } from './unit-endorsement.model';
import { QualificationData } from './qualification-data';

export class QualificationModel {

  public skills: QualificationData[];
  public ratingEndorsements: string[];
  public unitEndorsements: QualificationData[];
  public unitEndorsementsData: UnitEndorsementModel[];
  public licenseEndorsements: string[];
  public licensesData: LicenseModel[];
  public skillsData: SkillModel[];
  public licenses: QualificationData[];
  public certificates: string[];
  public ratings: string[];
  public ids: number[];
  public endorsements: string[];

  constructor(data: any = {}) {

    this.ratingEndorsements = data.ratingEndorsements || [];
    this.licenseEndorsements = data.licenseEndorsements || [];
    this.certificates = data.certificates || [];
    this.endorsements = data.secondarySkills || [];
    this.ratings = data.ratings || [];

    const licenseData = (data.employeeLicense && data.employeeLicense.map((el) => new LicenseModel(el))) || [];
    this.licensesData = licenseData;
    this.licenses = this.licensesData.map(license => ({ id: license.id, title: license.name }));

    this.skillsData = (data.employeeSkills && data.employeeSkills.map(s => new SkillModel(s))) || [];
    this.skills = this.skillsData.map(skill => ({ id: skill.id, title: skill.name }));

    this.unitEndorsementsData = (data.employeeUnitEndorsements && data.employeeUnitEndorsements.map(u => new UnitEndorsementModel(u))) || [];
    this.unitEndorsements = this.unitEndorsementsData.map(u => ({ id: u.id, title: u.title }));

    this.ids = (data.employeeLicense && data.employeeLicense.map((el) => el.id)) || [];
  }
}
