export enum PermissionEnum {
  EmployeePermission = 'EmployeePermission',
  SupervisorPermission = 'SupervisorPermission',
  ResourceCoordinatorPermission = 'ResourceCoordinatorPermission',
  ResourcePlannerPermission = 'ResourcePlannerPermission',
  ProjectManagerPermission = 'ProjectManagerPermission',
  AnalystPermission = 'AnalystPermission',
  UnitAdminPermission = 'UnitAdminPermission',
  SystemAdminPermission = 'SystemAdminPermission',
  Employee_Vacay_Self_Create = 'Employee_Vacay_Self_Create',
  Employee_Vacay_Self_Read = 'Employee_Vacay_Self_Read',
  Employee_Vacay_Self_Update = 'Employee_Vacay_Self_Update',
  Employee_Vacay_Self_Delete = 'Employee_Vacay_Self_Delete',
  Employee_Info_Self_Read = 'Employee_Info_Self_Read',
  Employee_Info_Self_Update = 'Employee_Info_Self_Update',
  Resource_Planner_Vacay_Division_Create = 'Resource_Planner_Vacay_Division_Create',
  Resource_Planner_Vacay_Division_Read = 'Resource_Planner_Vacay_Division_Read',
  Resource_Planner_Vacay_Division_Update = 'Resource_Planner_Vacay_Division_Update',
  Resource_Planner_Vacay_Division_Delete = 'Resource_Planner_Vacay_Division_Delete',
  Resource_Planner_Agenda_Division_Create = 'Resource_Planner_Agenda_Division_Create',
  Resource_Planner_Agenda_Division_Read = 'Resource_Planner_Agenda_Division_Read',
  Resource_Planner_Agenda_Division_Update = 'Resource_Planner_Agenda_Division_Update',
  Resource_Planner_Agenda_Division_Delete = 'Resource_Planner_Agenda_Division_Delete',
  Resource_Planner_Rostr_Division_Create = 'Resource_Planner_Rostr_Division_Create',
  Resource_Planner_Rostr_Division_Read = 'Resource_Planner_Rostr_Division_Read',
  Resource_Planner_Rostr_Division_Update = 'Resource_Planner_Rostr_Division_Update',
  Resource_Planner_Rostr_Division_Delete = 'Resource_Planner_Rostr_Division_Delete',
  Resource_Planner_Info_Division_Read = 'Resource_Planner_Info_Division_Read',
  Supervisor_Info_Unit_Read = 'Supervisor_Info_Unit_Read',
  AgendaAdminCompany = 'AgendaAdminCompany',
  AgendaAdminDivision = 'AgendaAdminDivision',
  HierarchyReadCompany = 'HierarchyReadCompany',
  UnionRepresentativePermission = 'UnionRepresentativePermission',
  RostrReadAccessPermission = 'RostrReadAccessPermission'
}
