import { ISchedulesTimelineSizes } from '@interfaces';

export const defaultScheduleTimelineSizes: ISchedulesTimelineSizes = {
  height: 252,
  groupHeight: 50,
  groupWidth: 250,
  lineHeight: 8,
  scheduleHeight: 34,
  topMargin: 86,
  footerHeight: 85,
  marginRight: 50,
  groupNameOffset: 185,
  addNewHeight: 34,
  schedulePadding: 1,
  defaultMargin: 10
};
