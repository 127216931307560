import { EmployeeModel } from '../auth/employee.model';

export interface INotificationShift {
  id?: number
  activity_id?: number
  disabledBy?: EmployeeModel
  isDisabled?: boolean
  updatedAt?: Date
}
export class NotificationModel {
  public id: number;
  public left: INotificationShift = {};
  public right: INotificationShift = {};

  constructor(data: any = {}) {
    this.id = data.id || null;
    if (data.left) {
      if (!this.id) { // TODO fix me
        this.id = data.left.id || null;
      }
      this.left.id = data.left.id;

      if (data.left.activity) {
        this.left.activity_id = data.left.activity.id;
      }

      if (data.left.disabledBy) {
        this.left.disabledBy = new EmployeeModel(data.left.disabledBy);
      }
      this.left.isDisabled = data.left.isDisabled;
      this.left.updatedAt = new Date(data.left.updatedAt);
    }

    if (data.right) {
      if (!this.id) { // TODO fix me
        this.id = data.right.id || null;
      }
      this.right.id = data.right.id;
      if (data.right.activity) {
        this.right.activity_id = data.right.activity.id;
      }

      if (data.right.disabledBy) {
        this.right.disabledBy = new EmployeeModel(data.right.disabledBy);
      }
      this.right.isDisabled = data.right.isDisabled;
      this.right.updatedAt = new Date(data.right.updatedAt);
    }
  }
}
