import { Component, Input } from '@angular/core';
import { VacayRequestModel } from '@models';

@Component({
  selector: 'app-vacay-request-status',
  templateUrl: './vacay-request-status.component.html',
  styleUrls: ['./vacay-request-status.component.scss']
})
export class VacayRequestStatusComponent {
  @Input()
  request: VacayRequestModel;
}
