import * as moment from 'moment-mini';

import { BaseModel } from '../base.model';
import { GroupModel, UnitModel } from '../companies';
import { EndorsementModel } from '../qualifications/endorsement.model';
import { SummaryDataModel } from './summary.data.model';

export class SummaryModel extends BaseModel {
  public title: string;
  public description: string;
  public dateFrom: Date;
  public dateTo: Date;

  public unit: UnitModel;
  public endorsements: EndorsementModel[];
  public groups: GroupModel[];
  public phases: { name: string; status: number; changed: moment.Moment; version: string }[];
  public data: SummaryDataModel;

  constructor(data: any = {}) {
    super(data);

    this.title = data.title || '';
    this.description = data.description || '';
    this.dateFrom = (data.dateFrom && new Date(data.dateFrom)) || undefined;
    this.dateTo = (data.dateTo && new Date(data.dateTo)) || undefined;

    this.unit = (data.unit && new UnitModel(data.unit)) || undefined;
    this.endorsements = data.unitEndorsements?.map(el => new EndorsementModel(el)) || [];
    this.groups = data.groups?.map(groupDto => new GroupModel(groupDto)) || [];
    this.phases =
      (data.phases &&
        data.phases.map(el => {
          return {
            name: el.name,
            status: el.status,
            changed: el.changed,
            version: el.string
          };
        })) ||
      [];
    this.data = new SummaryDataModel(data.data);
  }
}
