import { ErrorHandler, Injector, Injectable } from '@angular/core';
import { LoggingService } from '@services';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  // need to use Injector because we need to load the logging service prior to everything else
  constructor(private injector: Injector) { }

  handleError(err: Error | HttpErrorResponse): void {
    const loggingService = this.injector.get<LoggingService>(LoggingService);
    loggingService.error(err);

    throw err;
  }
}