import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { ScheduleValidationsAPIService } from '@app/rostr/overview/api/services/schedule-validations-api.service';
import { ScheduleValidation } from '@app/rostr/overview/dto/schedule-validation';
import { ScheduleValidationLevel, ScheduleValidationSeverity } from '@enums';
import { getSeverityColor } from '@shared/helpers/validations-helper';
import { FilteringService } from '@shared/services/filtering/filtering.service';
import { BehaviorSubject, Subject, Subscription, combineLatest, forkJoin } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { datesComparer, norwegianTextComparer } from '@helpers';
import { ColumnFilterResult, Filter } from '@interfaces';
import { ScheduleApiService } from '../../api/services/schedule-api.service';
import { AssignedEmployeeResult } from '../../dto';
import { ValidationsFacade } from '../../facades/validations.facade';
import { SettingsState } from '../../state/settings.state';

@Component({
  selector: 'app-avai-warnings',
  templateUrl: './avai-warnings-modal.component.html',
  styleUrls: ['./avai-warnings-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FilteringService]
})
export class AvaiWarningsModalComponent implements OnInit, AfterViewInit, OnDestroy {
  private updatesSub: Subscription;

  public validations: ExpandedValidation[] = [];
  public validationCodes: { value: string }[] = [];
  public allEmployeesNames: { value: string }[] = [];
  public warningsCount = 0;
  public errorsCount = 0;
  public excludedCount = 0;

  public getSeverityColor = getSeverityColor;

  @ViewChild(MatPaginator)
  public paginator: MatPaginator;

  @ViewChild(MatSort)
  public sort: MatSort;

  public filtersEnabled = true;
  private scheduleId: number;

  public matDataSource: MatTableDataSource<ExpandedValidation> = new MatTableDataSource([]);
  public displayedColumns: string[] = ['updatedAt', 'code', 'severity', 'level', 'employeeName', 'target', 'message'];
  public loading$: Subject<boolean> = new Subject();
  public filteredValidations$ = new BehaviorSubject<ExpandedValidation[]>([]);

  private searchSub$: Subscription;
  private excludedSub$: Subscription;
  private filteredSub$: Subscription;

  private employees: AssignedEmployeeResult[] = [];

  public searchForm: FormGroup<{
    term: FormControl<string>;
  }>;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly scheduleValidationsApi: ScheduleValidationsAPIService,
    private readonly validationsFacade: ValidationsFacade,
    private readonly settingsState: SettingsState,
    private readonly cd: ChangeDetectorRef,
    private readonly scheduleApiService: ScheduleApiService,
    private fb: FormBuilder,
    private filteringService: FilteringService<ExpandedValidation>
  ) {}

  public ngOnInit(): void {
    this.searchForm = this.fb.group({ term: new FormControl<string>({ value: '', disabled: true }) });
    this.loading$.next(true);
  }

  ngAfterViewInit(): void {
    this.matDataSource.paginator = this.paginator;

    this.route.queryParams.subscribe((params: { id: number }) => {
      this.scheduleId = +params.id;

      forkJoin([this.scheduleApiService.getSchedule(this.scheduleId), this.scheduleValidationsApi.getScheduleValidations(this.scheduleId)]).subscribe(
        ([schedule, validations]) => {
          this.employees = schedule.assignedEmployees;
          this.initValidations(validations);

          this.allEmployeesNames = Array.from(new Set(this.employees.map(x => x.name))).map(key => {
            return {
              value: key
            };
          });
        }
      );

      this.updatesSub = this.scheduleValidationsApi.onScheduleValidationsUpdated$(this.scheduleId).subscribe(validations => {
        this.validationsFacade.setValidations(validations);
      });
    });

    this.searchSub$ = combineLatest([
      this.searchForm.get('term').valueChanges.pipe(debounceTime(500)),
      this.filteredValidations$.asObservable(),
      this.loading$
    ]).subscribe(([term, validations]: [string, ExpandedValidation[], boolean]) => {
      this.matDataSource.data = this.search(term, validations);
      this.matDataSource.paginator.firstPage();
    });
  }

  private initValidations(validations: ScheduleValidation[]) {
    this.settingsState.initializeFromLocalStorage();
    this.validationsFacade.setValidations(validations);
    this.validationsFacade.setExcludedValidationsFromSettings();

    this.filteredSub$ = this.validationsFacade.getFilteredValidations$().subscribe(v => {
      this.populateData(v);
    });

    this.excludedSub$ = this.validationsFacade.getExcludedValidations$().subscribe(x => {
      this.excludedCount = x.length;
      this.cd.markForCheck();
    });
  }

  private populateData(validations: ScheduleValidation[]) {
    this.validations = validations.map(x => new ExpandedValidation(x, this.employees));
    this.warningsCount = validations.filter(x => x.severity === ScheduleValidationSeverity.WARNING && !x.isAccepted).length;
    this.errorsCount = validations.filter(x => x.severity === ScheduleValidationSeverity.ERROR && !x.isAccepted).length;
    this.validationCodes = Array.from(new Set(validations.map(x => x.code))).map(key => {
      return {
        value: key
      };
    });
    this.applyFilters();
    this.matDataSource.sort = this.sort;
    this.matDataSource.sortData = (data, sort) => this.sortData(data, sort);
    this.loading$.next(false);
    this.searchForm.controls.term.enable();
    this.cd.markForCheck();
  }

  public search(term: string, data: ExpandedValidation[]): ExpandedValidation[] {
    if (term?.trim().length === 0) {
      return data;
    }

    const searchArray = term
      .toLocaleLowerCase()
      .split(' ')
      .filter(t => !!t);
    return data.filter(item => searchArray.every(i => item.employeeName?.toLocaleLowerCase().indexOf(i) > -1));
  }

  public applyFilters(): void {
    if (this.filtersEnabled) {
      const filtered = this.filteringService.filterRecords(this.validations);
      this.filteredValidations$.next(filtered);
    } else {
      this.filteredValidations$.next(this.validations);
    }
  }

  public sortData(data: ExpandedValidation[], sort: Sort) {
    if (!sort.active || sort.direction === '') {
      return data;
    }

    return data.sort((validationA, validationB) => {
      const isAsc = sort.direction === 'asc';

      switch (sort.active) {
        case 'updatedAt':
          return datesComparer(new Date(validationA.updatedAt), new Date(validationB.updatedAt), isAsc);
        case 'code':
          return norwegianTextComparer(validationA.code, validationB.code, isAsc);
        case 'severity':
          return norwegianTextComparer(validationA.severity, validationB.severity, isAsc);
        case 'level':
          return norwegianTextComparer(validationA.level, validationB.level, isAsc);
        case 'employeeName':
          return norwegianTextComparer(validationA.employeeName, validationB.employeeName, isAsc);
        default:
          return 0;
      }
    });
  }

  public filterByProperty(val: ColumnFilterResult<number>, property: string): void {
    this.filteringService.addFilter({
      property: property,
      items: val.items,
      blank: val.blank,
      compareFunction: (scheduleProp: string, filterValue: string) => scheduleProp === filterValue
    } as Filter<number>);

    this.applyFilters();
  }

  public toggleFilters(): void {
    this.filtersEnabled = !this.filtersEnabled;
    this.applyFilters();
  }

  public ngOnDestroy(): void {
    this.updatesSub?.unsubscribe();
    this.searchSub$?.unsubscribe();
    this.excludedSub$?.unsubscribe();
    this.filteredSub$?.unsubscribe();
  }

  public severities = ['ERROR', 'WARNING', 'INFO'].map(key => {
    return {
      value: key
    };
  });

  public levels = ['EMPLOYEE', 'WEEK', 'DAY', 'SHIFTS'].map(key => {
    return {
      value: key
    };
  });

  public getTargetValue(validation: ExpandedValidation) {
    switch (validation.level) {
      case 'WEEK': {
        return `Week: ${validation.week.toString()}`;
      }
      case 'DAY':
        return `Day: ${validation.day.toString()}`;
      case 'SHIFTS': {
        const employee = this.employees.find(x => x.id === validation.employeeId);
        const assignedShifts = Object.values(employee.assigned);
        const shifts = assignedShifts.filter(x => validation.shifts.includes(x.avaiKey)).map(x => `[${x.dateTo.split('T')[0]} : ${x.code}]`);
        return `Shifts: ${shifts.join(' ')}`;
      }
      default:
        return '';
    }
  }
}

class ExpandedValidation {
  code: string;
  severity: ScheduleValidationSeverity;
  level: ScheduleValidationLevel;
  message: string;
  week?: number;
  day?: string;
  employeeId?: number;
  employeeName?: string;
  shifts?: string[];
  isAccepted: boolean;
  updatedAt?: string;

  constructor(validation: ScheduleValidation, employees: AssignedEmployeeResult[]) {
    this.code = validation.code;
    this.severity = validation.severity;
    this.level = validation.level;
    this.message = validation.message;
    this.week = validation.week;
    this.day = validation.day;
    this.employeeId = validation.employee;
    this.employeeName = employees.find(x => x.id === validation.employee)?.name;
    this.shifts = validation.shifts;
    this.isAccepted = validation.isAccepted;
    this.updatedAt = validation.updatedAt;
  }
}
