import * as d3 from 'd3';
import * as moment from 'moment-mini';

import { takeUntil } from 'rxjs/operators';
import { translate, isoWeek, formatWeekday, formatMonth } from '@helpers';
import { MonthFormat, DayOfWeekFormat } from '@src/app/_shared/enums/days.enum';
import { IBaseTimeline } from '..';
import { IBaseMenu } from '../menu/base-menu.interface';
import { IBaseGrid } from '../grid/base-grid.interface';
import { BaseInterval } from './base.interval';

export class DayInterval extends BaseInterval {

  static get type() {
    return DayInterval.TYPES.Day;
  }

  get title() {
    let title = `${this._dateFrom.getDate()} `;
    const mode = this._params.titleMode || 'short';

    if (mode === 'short') {
      title += formatWeekday(this._dateFrom, DayOfWeekFormat.short);
    } else if (mode === 'middle') {
      title += formatWeekday(this._dateFrom, DayOfWeekFormat.default);
    } else {
      title += formatWeekday(this._dateFrom, DayOfWeekFormat.long);
    }

    return title;
  }

  getTitleForTab(dateFrom: Date, isActive?: boolean) {
    if (isActive) {
      return `
        ${formatMonth(dateFrom, MonthFormat.long)}
        ${dateFrom.getFullYear().toString()}
      `;
    }
    return formatMonth(dateFrom, MonthFormat.long);
  }

  /********************************* Helpers **********************************************/
  get isEndOfWeek(): boolean {
    return isoWeek(this.dateFrom()) !== isoWeek(this.next());
  }

  get isStartOfWeek(): boolean {
    return isoWeek(this.dateFrom()) !== isoWeek(this.prev());
  }

  get isHoliday(): boolean {
    return !!this._timeline.holidays.find(holiday => {
      return holiday.compare(this._dateFrom);
    });
  }

  get visibleDaysInWeek(): number {
    const filters = this._grid.filters() as number[];
    return filters.length || 7;
  }

  protected $week: d3.Selection<SVGGElement, any, any, any>;
  protected $weekCircle: d3.Selection<SVGCircleElement, any, any, any>;
  protected $weekTitle: d3.Selection<SVGTextElement, any, any, any>;

  protected $holiday: d3.Selection<SVGPathElement, any, any, any>;
  constructor(
    protected _timeline: IBaseTimeline,
    protected _grid: IBaseGrid,
    protected _menu: IBaseMenu,
    protected _dateFrom: Date,
    protected _x: number,
    protected _params: any = {}
  ) {
    super(_timeline, _grid, _menu, _dateFrom, _x, _params);

    this.$week = this.$header.append<SVGGElement>('g').classed('week-indication', true);
    this.$weekCircle = this.$week.append<SVGCircleElement>('circle');
    this.$weekTitle = this.$week.append<SVGTextElement>('text');

    this.$holiday = this.$header.append<SVGPathElement>('path');
  }

  public renderHeader() {
    super.renderHeader();

    this.$header.classed('isHoliday', this.isHoliday);

    this.$headerTitle.classed('date-day', true);

    const height = this.timeHeader.height;
    const width = this.width;
    const weekRadius = 9;

    this.$week.attr('visibility', this.isStartOfWeek ? 'visible' : 'hidden');

    this.$weekCircle.attr('r', weekRadius);

    this.$weekTitle
      .text(isoWeek(this._dateFrom))
      .attr('x', 0)
      .attr('y', 1)
      .attr('dy', '0.5ex')
      .classed('text', true);

    this.$holiday
      .classed('holiday', true)
      .attr('transform', translate(width - 10.5, height - 10))
      .attr('d', 'M 0 10 L 10 10 L 10 0 Z');
  }

  public renderBackground() {
    super.renderBackground();

    this.$backgroundRect.attr('fill', this.isWeekend() ? 'rgba(0, 0, 0, .04)' : 'transparent');
  }

  /**
   * Listen to event emitters
   */
  public subscribe() {
    super.subscribe();

    this._timeline.onInitHolidays.pipe(takeUntil(this._timeline.onDestroy)).subscribe(() => {
      this.$header.classed('isHoliday', this.isHoliday);
    });
  }

  public isMonday(): boolean {
    return this._dateFrom.getDay() === 1;
  }

  public isTuesday(): boolean {
    return this._dateFrom.getDay() === 2;
  }

  public isWednesday(): boolean {
    return this._dateFrom.getDay() === 3;
  }

  public isThursday(): boolean {
    return this._dateFrom.getDay() === 4;
  }

  public isSunday(): boolean {
    return this._dateFrom.getDay() === 0;
  }

  /********************************* Dates ************************************************/
  /**
   * Setter for dates
   * @param dateFrom
   * @returns {DayInterval}
   */
  public setDates(dateFrom = this._dateFrom) {
    this._dateFrom = DayInterval.precision(dateFrom);

    this._dateTo = new Date(this._dateFrom.getTime());
    this._dateTo.setDate(this._dateTo.getDate() + 1);

    this._dateFromUnix = this._dateFrom.getTime();
    this._dateToUnix = this._dateTo.getTime();

    return this;
  }

  /**
   * Date for next interval
   * @returns {Date}
   */
  public next() {
    const date = new Date(this._dateFrom.getTime());
    const filters = this._grid.filters() as number[];

    if (!filters.length || filters.length > 6) {
      date.setDate(date.getDate() + 1);
      return date;
    }

    const index = filters.indexOf(date.getDay()) + 1;
    let day = filters[index % filters.length];

    if (index >= filters.length) {
      day += 7;
    }

    date.setDate(date.getDate() - date.getDay() + day);
    return date;
  }

  /**
   * Date for prev interval
   * @returns {Date}
   */
  public prev() {
    const date = new Date(this._dateFrom.getTime());
    const filters = this._grid.filters() as number[];

    if (!filters.length || filters.length > 6) {
      date.setDate(date.getDate() - 1);
      return date;
    }

    const index = filters.indexOf(date.getDay()) - 1;
    let day = filters[index < 0 ? filters.length + index : index];

    if (index < 0) {
      day -= 7;
    }

    date.setDate(date.getDate() - date.getDay() + day);
    return date;
  }

  /**
   * Will return count of intervals what will be overlapped by shift
   * @param from {Date}
   * @param to {Date}
   * @returns {number}
   */
  public getCountOfUsedIntervals(from, to) {
    return Math.ceil(Math.round((to.getTime() - from.getTime()) / 60000) / 1440);
  }

  /**
   * Round up to start of month
   * @returns {Date}
   */
  public base(offsetDays = 0, offsetMonths = 0) {
    const date = this._dateFrom;
    return new Date(date.getFullYear(), date.getMonth() + offsetMonths, 1 + offsetDays);
  }

  private isWeekend(): boolean {
    return [0, 6].indexOf(this._dateFrom.getDay()) > -1;
  }

  /*********************************** Static *********************************************/
  /**
   * Round hours/minutes/seconds to start of date
   * @param date
   * @returns {Date}
   */
  static precision(date: Date): Date {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  }

  static diff(from, to) {
    return moment(from).diff(moment(to), 'days');
  }
}
