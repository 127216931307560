import { BaseModel } from '../base.model';
import { EmploymentModel } from './employment.model';
import { QualificationModel } from './qualification.model';
import { WorkAssignmentModel } from './work-assignment.model';
import { ProfileDataModel } from './profile-data.model';
import { EmployeeGroupModel } from '@model/companies';
import { EmployeeRoleModel } from './employee-role.model';

export class ProfileModel extends BaseModel {
  public username: string;
  public firstName: string;
  public middleName: string;
  public lastName: string;
  public email: string;
  public data: ProfileDataModel;

  public work_assignment: WorkAssignmentModel;

  public employment: EmploymentModel;

  public active: boolean;

  public qualifications: QualificationModel;

  public employeeGroups: EmployeeGroupModel[];
  public employeeRoles: EmployeeRoleModel[];

  constructor(data: any = {}) {
    super(data);

    this.username = data.username;
    this.firstName = data.firstName || '';
    this.middleName = data.middleName || '';
    this.lastName = data.lastName || '';
    this.email = data.email || null;
    this.data = new ProfileDataModel(data.data);

    this.work_assignment = new WorkAssignmentModel(data);

    this.employment = new EmploymentModel(data);

    this.active = data.active ?? null;

    this.qualifications = new QualificationModel(data);
    this.employeeGroups = data.employeeGroups?.map(eg => new EmployeeGroupModel(eg)) || [];
    this.employeeRoles = data.employeeRoles?.map(er => new EmployeeRoleModel(er)) || [];
  }

  get fullName() {
    return `${this.firstName} ${this.middleName} ${this.lastName}`;
  }
}
