import { Apollo } from 'apollo-angular';
import { ApolloQueryResult } from '@apollo/client/core';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { createComment, deleteComment, updateComment, loadCommentsQuery, loadCommentsForScheduleQuery } from './comments.queries';
import { CommentModel } from '@models';
import { AuthenticationService } from '@auth';
import { Observable } from 'rxjs';
import { formatUsingUTCZeroOffsetForTwoDates } from '@helpers';
import { CommentTypes } from '@enums';
import { ConversationPanelComment } from '@component/conversation-panel/models';

@Injectable()
export class CommentsService {
  constructor(
    private apollo: Apollo,
    private authService: AuthenticationService
  ) {}

  public fetchCommentsForLoggedUser(dateFrom: Date, dateTo: Date): Observable<ConversationPanelComment[]> {
    const [fromDateFormatted, toDateFormatted] = formatUsingUTCZeroOffsetForTwoDates(dateFrom, dateTo);

    return this.apollo
      .query<any>({
        query: loadCommentsQuery,
        variables: {
          correspondingEmployeeId: this.authService.user.id,
          dateFrom: fromDateFormatted,
          dateTo: toDateFormatted,
          typeId: CommentTypes.VACAY
        }
      })
      .pipe(map(response => response.data.comments.map(comment => new ConversationPanelComment(comment))));
  }

  public fetchCommentsForSchedule(scheduleId: number): Observable<ConversationPanelComment[]> {
    return this.apollo
      .query<any>({
        query: loadCommentsForScheduleQuery,
        variables: {
          scheduleId
        }
      })
      .pipe(map(response => response.data.commentsForSchedule.map(comment => new ConversationPanelComment(comment))));
  }

  public createComment(data: CommentModel): Observable<CommentModel> {
    return this.apollo
      .mutate<any>({
        mutation: createComment,
        variables: {
          typeId: data.typeId,
          correspondingDay: data.correspondingDay,
          correspondingEmployeeId: data.correspondingEmployeeId ? data.correspondingEmployeeId : this.authService.user.id,
          message: data.message,
          color: data.color,
          scheduleId: data.scheduleId
        }
      })
      .pipe(
        map(response => {
          const comment = response.data.createComment;
          return new CommentModel({
            id: comment.id,
            correspondingEmployeeId: comment.correspondingEmployee.id,
            typeId: comment.type.id,
            message: comment.message,
            color: comment.color,
            correspondingDay: comment.correspondingDay,
            createdAt: comment.createdAt,
            createdBy: comment.createdBy
          });
        })
      );
  }

  public updateComment(data: CommentModel): Observable<CommentModel> {
    return this.apollo
      .mutate<any>({
        mutation: updateComment,
        variables: {
          id: data.id,
          typeId: data.typeId,
          correspondingDay: data.correspondingDay,
          correspondingEmployeeId: data.correspondingEmployeeId,
          message: data.message,
          color: data.color,
          scheduleId: data.scheduleId
        }
      })
      .pipe(
        map(response => {
          const comment = response.data.updateComment;
          return new CommentModel({
            id: comment.id,
            correspondingEmployeeId: comment.correspondingEmployee.id,
            typeId: comment.type.id,
            message: comment.message,
            color: comment.color,
            correspondingDay: comment.correspondingDay,
            createdAt: comment.createdAt,
            createdBy: comment.createdBy
          });
        })
      );
  }

  public deleteComment(data) {
    return this.apollo
      .mutate({
        mutation: deleteComment,
        variables: {
          id: data.id
        }
      })
      .pipe(map((response: ApolloQueryResult<any>) => response.data.updateComment));
  }
}
