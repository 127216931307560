import { BaseModel } from '../base.model';


export class TeamModel extends BaseModel {

  public name: string;
  public weekendRotation: number;

  public type = 'teams';
  public typeSingular = 'team';

  constructor(data: Partial<TeamModel> = {}) {
    super(data);

    this.name = data.name || '';
    this.weekendRotation = data.weekendRotation || null;
  }

}
