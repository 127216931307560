import { gql } from 'apollo-angular';

export const rostrCreateAssignedActivities = gql`
  mutation rostrCreateAssignedActivities($input: [AssignedActivityInput]) {
    rostrCreateAssignedActivities(input: $input) {
      id
    }
  }
`;

export const rostrUpsertAssignedActivity = gql`
  mutation rostrUpsertAssignedActivity(
    $id: Int!
    $employee: Int!
    $parentType: String
    $parentId: Int
    $code: String!
    $dateFrom: String!
    $dateTo: String!
    $schedule: Int!
    $shiftCodeId: Int
  ) {
    rostrUpsertAssignedActivity(
      id: $id
      schedule: $schedule
      employee: $employee
      parentType: $parentType
      parentId: $parentId
      code: $code
      dateFrom: $dateFrom
      dateTo: $dateTo
      shiftCodeId: $shiftCodeId
    ) {
      id
    }
  }
`;

export const rostrToggleLockedAssignedActivities = gql`
  mutation rostrToggleLockedAssignedActivities($input: [AssignedActivityInput]) {
    rostrToggleLockedAssignedActivities(input: $input) {
      id
    }
  }
`;

export const rostrDeleteAssignedActivities = gql`
  mutation rostrDeleteAssignedActivities($input: [Int]) {
    rostrDeleteAssignedActivities(input: $input) {
      id
    }
  }
`;

export const rostrSwapAssignedActivities = gql`
  mutation rostrSwapAssignedActivities($source: Int!, $target: Int!) {
    rostrSwapAssignedActivities(source: $source, target: $target) {
      id
    }
  }
`;

export const rostrAssignConstellation = gql`
  mutation rostrAssignConstellation($schedule: Int!, $dateFrom: String!, $employee: Int!, $constellationId: Int!) {
    rostrAssignConstellation(schedule: $schedule, dateFrom: $dateFrom, employee: $employee, constellationId: $constellationId) {
      id
    }
  }
`;

export const rostrClearAssignedActivities = gql`
  mutation deleteAllSheduleAssignedActivities(
    $scheduleId: Int!
    $dateRangeFrom: String!
    $dateRangeTo: String!
    $removeSwitch: String
    $employeeIds: [Int]
    $keepLocked: Boolean!
  ) {
    deleteAllSheduleAssignedActivities(
      scheduleId: $scheduleId
      dateRangeFrom: $dateRangeFrom
      dateRangeTo: $dateRangeTo
      removeSwitch: $removeSwitch
      employeeIds: $employeeIds
      keepLocked: $keepLocked
    ) {
      id
    }
  }
`;

export const rostrSearchAndReplaceAssignedShifts = gql`
  mutation searchAndReplaceAssignedShifts(
    $scheduleId: Int!
    $groups: [Int]!
    $dateRangeFrom: String!
    $dateRangeTo: String!
    $sourceCode: Int
    $targetCode: Int
    $shiftsLimit: Int
    $keepLockedShifts: Boolean!
    $targetShiftType: String
  ) {
    searchAndReplaceAssignedShifts(
      scheduleId: $scheduleId
      groups: $groups
      dateRangeFrom: $dateRangeFrom
      dateRangeTo: $dateRangeTo
      sourceCode: $sourceCode
      targetCode: $targetCode
      shiftsLimit: $shiftsLimit
      keepLockedShifts: $keepLockedShifts
      targetShiftType: $targetShiftType
    ) {
      id
    }
  }
`;

export const scheduleClearedSubscription = gql`
  subscription scheduleCleared($scheduleId: Int) {
    scheduleCleared(scheduleId: $scheduleId) {
      scheduleId
      changeTriggeredById
      changeTriggeredBy
    }
  }
`;
export const scheduleEmployeesOrderChangedSubscription = gql`
  subscription scheduleEmployeesOrderChanged($scheduleId: Int) {
    scheduleEmployeesOrderChanged(scheduleId: $scheduleId) {
      scheduleId
      changeTriggeredById
      changeTriggeredBy
    }
  }
`;

export const scheduleEmployeesAssignmentsChangedSubscription = gql`
  subscription scheduleEmployeesAssignmentsChanged($scheduleId: Int) {
    scheduleEmployeesAssignmentsChanged(scheduleId: $scheduleId) {
      scheduleId
      changeTriggeredById
      changeTriggeredBy
    }
  }
`;

export const assignedActivitiesChangedSubscription = gql`
  subscription assignedActivitiesChanged($scheduleId: Int) {
    assignedActivitiesChanged(scheduleId: $scheduleId) {
      created {
        shift {
          id
          avaiKey
          code
          dateFrom
          dateTo
          date
          parentId
          parentType
          employeeId
          data
          shiftAlias {
            id
            value
          }
          shiftCodeId
          type
          updatedBy {
            id
            firstName
            lastName
          }
          shiftCodeId
        }
        changeTriggeredById
        changeTriggeredBy
      }
      updated {
        shift {
          id
          avaiKey
          code
          dateFrom
          dateTo
          date
          parentId
          parentType
          employeeId
          data
          shiftCodeId
          type
          shiftAlias {
            id
            value
          }
          updatedBy {
            id
            firstName
            lastName
          }
          shiftCodeId
        }
        changeTriggeredById
        changeTriggeredBy
        previousEmployeeId
      }
      deleted {
        shift {
          id
          avaiKey
          code
          dateFrom
          dateTo
          date
          parentId
          parentType
          employeeId
          data
          shiftCodeId
          type
          shiftAlias {
            id
            value
          }
          updatedBy {
            id
            firstName
            lastName
          }
          shiftCodeId
        }
        changeTriggeredById
        changeTriggeredBy
      }
      swapped {
        shift {
          id
          avaiKey
          code
          dateFrom
          dateTo
          date
          parentId
          parentType
          employeeId
          type
          shiftCodeId
          data
          shiftAlias {
            id
            value
          }
          updatedBy {
            id
            firstName
            lastName
          }
          shiftCodeId
        }
        shiftToSwapWith {
          id
          avaiKey
          code
          dateFrom
          dateTo
          date
          parentId
          parentType
          employeeId
          type
          shiftCodeId
          data
          shiftAlias {
            id
            value
          }
          updatedBy {
            id
            firstName
            lastName
          }
          shiftCodeId
        }
        changeTriggeredById
        changeTriggeredBy
      }
    }
  }
`;
