import { Component, Inject, ComponentFactoryResolver, ViewContainerRef, Injector } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDeleteModalComponent } from '@component/modal/confirmDeleteModal.component';

@Component({
  selector: 'app-modal-dialog',
  template: ''
})
export class ModalDialogComponent {
  constructor(
    public matDialogRef: MatDialogRef<ConfirmDeleteModalComponent>,
    @Inject(MAT_DIALOG_DATA) public matDialogData: any,
    componentFactoryResolver: ComponentFactoryResolver,
    viewContainerRef: ViewContainerRef
  ) {
    const appDialogData = matDialogData.data;
    const appDialogRef = {
      close: result => matDialogRef.close(result)
    };

    const injector = Injector.create({
      providers: [
        { provide: 'appDialogData', useValue: appDialogData },
        { provide: 'appDialogRef', useValue: appDialogRef }
      ]
    });

    const componentFactory = componentFactoryResolver.resolveComponentFactory(matDialogData.component);
    viewContainerRef.createComponent(componentFactory, 0, injector);
  }
}
