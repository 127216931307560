import * as d3 from 'd3';

import { translate } from '@helpers';

import { BaseButton } from './abstract.base.button';

const iconWidth = 20;

export class Button extends BaseButton {

  isVisible = true;

  protected $icon: d3.Selection<any, any, any, any>;

  constructor(protected title: string,
    protected widthValue: number,
    protected height: number,
    protected radius: number,
    protected xValue: number,
    protected yValue: number,
    protected icon?: string,
    protected padding = 0,
    protected params?: any) {
    super(title, widthValue, height, radius, xValue, yValue, params);
  }

  public render() {
    const h = this.height;
    const w = this.widthValue;
    const r = this.radius;
    const elClass = this.isVisible ? 'btn' : 'btn disabled';

    this.$el
      .attr('transform', translate(this.xValue, this.yValue))
      .attr('class', elClass);

    this.$rect.attr('width', `${w}`)
      .attr('height', h)
      .attr('rx', r)
      .attr('ry', r)
      .attr('class', 'bg');

    if (this.icon) {

      // e.g, text-align justify
      this.renderTitleWithIcon();

    } else {

      // e.g. text-align center
      this.renderTitle();

    }

    this.events();

    return this.el;
  }

  events() {
    this.$el.on('click', () => {
      this.onClick.next({ event: d3.event });
    });
  }

  protected renderTitleWithIcon() {
    const iconTopMargin = 4.5;
    this.$el.selectAll('.material-icons').remove();
    this.$icon = this.$el.append('text').text(this.icon);
    this.$icon.attr('text-anchor', 'start')
      .attr('class', 'material-icons')
      .attr('dy', '0.5ex')
      .attr('x', this.padding)
      .attr('y', this.height / 2 + iconTopMargin);

    this.$title.attr('text-anchor', 'start').text(this.title)
      .attr('dy', '0.5ex')
      .attr('x', this.padding + iconWidth + this.padding)
      .attr('y', this.height / 2);
  }

  protected renderTitle() {
    this.$title.text(this.title)
      .attr('dy', '0.5ex')
      .attr('x', this.widthValue / 2)
      .attr('y', this.height / 2)
      .attr('text-anchor', 'middle');
  }

  public toggleVisibility() {
    this.isVisible = !this.isVisible;
    this.render();
  }

  public setVisibility(isShown: boolean) {
    this.isVisible = isShown;
    this.render();
  }
}
