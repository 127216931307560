import { gql } from 'apollo-angular';

export const createScheduleFromExcel = gql`
  mutation createScheduleFromExcel(
    $name: String!
    $dateFrom: String!
    $dateTo: String!
    $lockedVacayDays: [String]!
    $assignments: [ScheduleAssignment]!
    $draftDeadline: String
    $publishDeadline: String
    $deadline: String!
  ) {
    createScheduleFromExcel(
      name: $name
      dateFrom: $dateFrom
      dateTo: $dateTo
      lockedVacayDays: $lockedVacayDays
      assignments: $assignments
      draftDeadline: $draftDeadline
      publishDeadline: $publishDeadline
      deadline: $deadline
    ) {
      id
    }
  }
`;
