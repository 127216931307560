import * as d3 from 'd3';

import { url, xmlns } from '@helpers';

import { IBaseTimeline , IElement } from '../utils/interfaces';



export class Defs implements IElement {

  protected el: Element;
  protected $el: d3.Selection<any, any, any, any>;

  protected _url = '';

  protected $bgGridPattern: d3.Selection<any, any, any, any>; // background pattern for items
  protected _bgGridPatternID = 'bg-grid-pattern';

  protected $bgMenuPattern: d3.Selection<any, any, any, any>; // background pattern for grid
  protected _bgMenuPatternID = 'bg-items-pattern';

  // gradients
  protected $gridGradient: d3.Selection<any, any, any, any>; // background pattern for grid
  protected _gridGradientID = 'bg-grid-gradient';

  protected $headerLeftGradient: d3.Selection<any, any, any, any>;
  protected _headerLeftGradientID = 'header-left-gradient';

  protected $shiftSuccessStatusGradient: d3.Selection<any, any, any, any>;
  protected _shiftSuccessStatusGradientID = 'status-success-gradient';

  protected $shiftWarningStatusGradient: d3.Selection<any, any, any, any>;
  protected _shiftWarningStatusGradientID = 'warning-success-gradient';

  protected $shiftErrorStatusGradient: d3.Selection<any, any, any, any>;
  protected _shiftErrorStatusGradientID = 'error-success-gradient';

  protected $headerRightGradient: d3.Selection<any, any, any, any>;
  protected _headerRightGradientID = 'header-right-gradient';

  // masks
  protected $stripeIncrease: d3.Selection<any, any, any, any>;
  protected _stripeIncreaseID = 'pattern-stripeIncrease';

  protected $stripeDecrease: d3.Selection<any, any, any, any>;
  protected _stripeDecreaseID = 'pattern-stripeDecrease';

  protected $unassignedMask: d3.Selection<any, any, any, any>;
  protected _unassignedMaskID = 'pattern-unassigned';

  protected $dottedMask: d3.Selection<any, any, any, any>;
  protected _dottedMaskID = 'pattern-dotted';

  // Arrows
  protected $dragArrow: d3.Selection<any, any, any, any>;
  protected _dragArrowID = 'drag-arrow';

  protected $semisphere: d3.Selection<any, any, any, any>;
  protected _semisphereID = 'semisphere';

  // Shadows
  protected $crossButtonShadow: d3.Selection<any, any, any, any>;
  protected _crossButtonShadowID = 'cross-button-shadow';

  protected $floatingHeaderShadow: d3.Selection<any, any, any, any>;
  protected _floatingHeaderShadowID = 'floating-header-shadow';

  /**
   *
   * @param timeline
   */
  constructor(protected timeline: IBaseTimeline) {
    this.el = document.createElementNS(xmlns, 'defs');
    this.$el = d3.select(this.el);

    this._url = this.timeline.config().url;
  }

  /**
   * Render functionality
   * @param sizes
   * @returns {Element}
   */
  public render() {

    // gradients
    this.renderGridGradient()
      .renderHeaderLeftGradient()
      .renderHeaderRightGradient();

    // patterns
    this.renderMenuPattern()
      .renderGridPattern();

    // masks
    this.renderStripeIncrease()
      .renderStripeDecrease()
      .renderUnassignedMask()
      .renderDottedMask();

    // Arrows
    this.renderDragArrow()
      .renderSemisphere();

    this.renderShiftSuccessStatusGradient()
      .renderShiftWarningStatusGradient()
      .renderShiftErrorStatusGradient();

    // Shadows
    this.renderCrossButtonShadow();
    this.renderFloatingHeaderShadow();

    return this.el;
  }

  /**
   * Link to dotted mask
   * @returns {string}
   */
  public dottedMask() {
    return url(this._url, this._dottedMaskID);
  }

  /**
   * Link to unassigned mask
   * @returns {string}
   */
  public unassignedMask() {
    return url(this._url, this._unassignedMaskID);
  }

  /**
   * Link to stripe increase mask
   * @returns {string}
   */
  public stripeIncrease() {
    return url(this._url, this._stripeIncreaseID);
  }

  /**
   * Link to stripe decrease mask
   * @returns {string}
   */
  public stripeDecrease() {
    return url(this._url, this._stripeDecreaseID);
  }

  /**
   * Getter for link to left header gradient
   * @returns {string}
   */
  public headerLeftGradient() {
    return url(this._url, this._headerLeftGradientID);
  }

  /**
   * Getter of link to right header gradient
   * @returns {string}
   */
  public headerRightGradient() {
    return url(this._url, this._headerRightGradientID);
  }

  /**
   * url to grid background gradient
   * @returns {string}
   */
  public gridGradient() {
    return url(this._url, this._gridGradientID);
  }

  /**
   * Getter for link to status gradient
   * @returns {string}
   */
  public shiftSuccessStatusGradient() {
    return url(this._url, this._shiftSuccessStatusGradientID);
  }

  /**
   * Getter for link to status gradient
   * @returns {string}
   */
  public shiftWarningStatusGradient() {
    return url(this._url, this._shiftWarningStatusGradientID);
  }

  /**
   * Getter for link to status gradient
   * @returns {string}
   */
  public shiftErrorStatusGradient() {
    return url(this._url, this._shiftErrorStatusGradientID);
  }

  /**
   * Link to items pattern fill
   * @returns {string}
   */
  public menuPattern(): string {
    return url(this._url, this._bgMenuPatternID);
  }

  /**
   * Background pattern for grid
   * @returns {string}
   */
  public gridPattern() {
    return url(this._url, this._bgGridPatternID);
  }

  /**
   * Link to items pattern fill
   * @returns {string}
   */
  public dragArrow(): string {
    return `#${this._dragArrowID}`;
  }

  /**
   * Link to items pattern fill
   * @returns {string}
   */
  public semisphere(): string {
    return `#${this._semisphereID}`;
  }

  /**
   * Link to cross button shadow
   * @returns {string}
   */
  public crossButtonShadow() {
    return url(this._url, this._crossButtonShadowID);
  }

  /**
   * Link to floating header shadow
   * @returns {string}
   */
  public floatingHeaderShadow() {
    return url(this._url, this._floatingHeaderShadowID);
  }


  /************************************ IElement interface ***********************************************************/
  /**
   * DOM element
   * @returns {Element}
   */
  public node() {
    return this.el;
  }

  /**
   * d3.Selection of DOM elemet
   * @returns {d3.Selection<any, any, any, any>}
   */
  public element() {
    return this.$el;
  }

  /**
   * Mask for unassigned items row
   * @returns {Node}
   */
  protected renderUnassignedMask() {

    const $pattern = this.$el.append('pattern')
      .attr('id', this._unassignedMaskID)
      .attr('patternUnits', 'userSpaceOnUse')
      .attr('width', 4)
      .attr('height', 4);

    $pattern.append('rect')
      .attr('width', 4)
      .attr('height', 4)
      .attr('fill', 'red')
      .attr('opacity', 0.1);

    $pattern.append('path')
      .attr('d', 'M-2,2 l2,-2 M0,4 l4,-4 M3,5 l2,-2')
      .attr('stroke', '#A3A3A3')
      .attr('stroke-width', 1)
      .attr('opacity', 0.2);

    this.$unassignedMask = $pattern;
    return this;
  }

  /**
   * Render strip decrease mask
   * @returns {Node}
   */
  protected renderStripeIncrease() {
    this.$stripeIncrease = this.addMask(this._stripeIncreaseID, 4, 2, 45, 'rgba(0, 0, 0, .1)');
    return this;
  }

  /**
   * Render strip decrease mask
   * @returns {Node}
   */
  protected renderStripeDecrease() {
    this.$stripeDecrease = this.addMask(this._stripeDecreaseID, 4, 4, 45, 'rgba(0, 0, 0, .1)');
    return this;
  }

  /**
   * Add mask pattern
   * @param type
   * @param width
   * @param height
   * @param angle
   * @param color
   * @returns {Selection<any>}
   */
  protected addMask(id: string, width: number, height: number, angle: number, color: string) {
    const $pattern = this.$el.append('pattern')
      .attr('id', id)
      .attr('patternUnits', 'userSpaceOnUse')
      .attr('width', width)
      .attr('height', height)
      .attr('patternTransform', `rotate(${angle})`);

    $pattern.append('rect')
      .attr('x', '0')
      .attr('y', '0')
      .attr('width', width / 4)
      .attr('height', height)
      .style('stroke', 'none')
      .style('fill', `${color}`);


    return $pattern;
  }

  /**************************************** Gradients **********************************************/
  /**
   * Left header gradient
   * @returns {Node}
   */
  protected renderHeaderLeftGradient() {
    const color = '#f3f3f3';
    const $gradient = this.$el.append('linearGradient')
        .attr('id', this._headerLeftGradientID);

    $gradient.append('stop')
      .attr('offset', '0%')
      .attr('stop-opacity', '1')
      .attr('stop-color', color);
    $gradient.append('stop')
      .attr('offset', '50%')
      .attr('stop-opacity', '0.9')
      .attr('stop-color', color);
    $gradient.append('stop')
      .attr('offset', '100%')
      .attr('stop-opacity', '0')
      .attr('stop-color', color);

    this.$headerLeftGradient = $gradient;

    return this;
  }

  /**
   * Header right gradient
   * @returns {Node}
   */
  protected renderHeaderRightGradient() {

    const color = '#f3f3f3';
    const $gradient = this.$el.append('linearGradient')
        .attr('id', this._headerRightGradientID);

    $gradient.append('stop')
      .attr('offset', '0%')
      .attr('stop-opacity', '0')
      .attr('stop-color', color);
    $gradient.append('stop')
      .attr('offset', '50%')
      .attr('stop-opacity', '0.9')
      .attr('stop-color', color);
    $gradient.append('stop')
      .attr('offset', '100%')
      .attr('stop-opacity', '1')
      .attr('stop-color', color);

    this.$headerRightGradient = $gradient;

    return this;
  }

  /**
   * Grid background gradient
   * @returns {Node}
   */
  protected renderGridGradient() {
    this.$gridGradient = this.$el.append('linearGradient')
      .attr('x1', '50%')
      .attr('y1', '3.061617e-15%')
      .attr('x2', '50%')
      .attr('y2', '100%')
      .attr('id', this._gridGradientID);
    this.$gridGradient.append('stop')
      .attr('stop-color', '#F3F3F3')
      .attr('offset', '0%');
    this.$gridGradient.append('stop')
      .attr('stop-color', '#E5E5E5')
      .attr('offset', '100%');
    return this;
  }

  protected renderStatusGradient(colorFrom, colorTo, id) {
    const $gradient = this.$el.append('linearGradient')
      .attr('id', id).attr('x1', '50%')
      .attr('id', id).attr('y1', '100%')
      .attr('id', id).attr('x2', '50%')
      .attr('id', id).attr('y2', '0%');

    $gradient.append('stop')
      .attr('offset', '0%')
      .attr('stop-opacity', '0.3')
      .attr('stop-color', colorFrom);

    $gradient.append('stop')
      .attr('offset', '100%')
      .attr('stop-opacity', '0')
      .attr('stop-color', colorTo);

    return $gradient;
  }

  /**
   * Status gradient
   * @returns {Node}
   */
  protected renderShiftSuccessStatusGradient() {
    const color = '#08D922';

    this.$shiftSuccessStatusGradient = this.renderStatusGradient(color, '#FFFFFF', this._shiftSuccessStatusGradientID);

    return this;
  }

  /**
   * Status gradient
   * @returns {Node}
   */
  protected renderShiftWarningStatusGradient() {
    const color = '#FFD300';

    this.$shiftWarningStatusGradient = this.renderStatusGradient(color, '#FFFFFF', this._shiftWarningStatusGradientID);

    return this;
  }

  /**
   * Status gradient
   * @returns {Node}
   */
  protected renderShiftErrorStatusGradient() {
    const color = '#da5c51';

    this.$shiftErrorStatusGradient = this.renderStatusGradient(color, '#FFFFFF', this._shiftErrorStatusGradientID);

    return this;
  }

  /**************************************** BG patterns *******************************************/
  /**
   * Background pattern for items
   * @returns {Node}
   */
  protected renderMenuPattern() {
    this.$bgMenuPattern = this.addBgPattern(this._bgMenuPatternID, ['#f5f5f5', '#fafafa']);
    return this;
  }

  /**
   * Background pattern for items
   * @returns {Node}
   */
  protected renderGridPattern() {
    this.$bgGridPattern = this.addBgPattern(this._bgGridPatternID, ['#e8e8e8', '#f0f0f0']);
    return this;
  }

  /**
   * Add items or grid bg patterns
   * @param id
   * @param data
   * @returns {Selection<any>}
   */
  protected addBgPattern(id: string, data: string[]) {

    const sizes = this.timeline.sizes();
    const rh = sizes.rowHeight;

    const $pattern = this.$el.append('pattern')
      .attr('id', id).attr('width', 1)
      .attr('height', rh * 2)
      .attr('y', rh * 2)
      .attr('patternUnits', 'userSpaceOnUse');

    $pattern.selectAll('line').data(data)
      .enter().append('line')
      .attr('stroke', function (d) {
        return d;
      })
      .attr('stroke-width', 2)
      .attr('height', rh)
      .attr('x1', 0)
      .attr('x2', 0)
      .attr('y1', function (d, i) {
        return i * rh;
      })
      .attr('y2', function (d, i) {
        return i * rh + rh;
      });

    return $pattern;
  }

  /**************************************** Arrows *******************************************/
  /**
   * Background pattern for items
   * @returns {Node}
   */
  protected renderDragArrow() {
    this.$dragArrow = this.$el.append('path')
      .attr('id', this._dragArrowID)
      .attr('d', 'M0,0 L0,5 C6,5 11,0 11,-6 C11,-13 6,-17 0,-17 L0,-12 L-5,-12 L-5,-18 L-16,-6 L-5,5 L-5,0 L0,0 Z');
    return this;
  }

  /**
   * Background pattern for items
   * @returns {Node}
   */
  protected renderSemisphere() {
    this.$semisphere = this.$el.append('path')
      .attr('id', this._semisphereID)
      .attr('d', 'M0,0 L0,5 C6,5 11,0 11,-6 C11,-13 6,-17 0,-17 Z');
    return this;
  }

  /**************************************** Shadows *******************************************/
  /**
   * Cross button shadow filter
   * @returns {Node}
   */
  protected renderCrossButtonShadow() {
    this.$crossButtonShadow = this.addShadowFilter(this._crossButtonShadowID, 0.5, '#000000', 0, 2, '30px', '30px');
    return this;
  }

  /**
   * Floating header shadow filter
   * @returns {Node}
   */
  protected renderFloatingHeaderShadow() {
    this.$floatingHeaderShadow = this.addShadowFilter(this._floatingHeaderShadowID, 0.2, '#000000', 0, 3, '30px', '30px', 5);
    return this;
  }

  /**************************************** Masks **************************************************/
  /**
   * Dotted mask rendering
   * @returns {Defs}
   */
  private renderDottedMask() {

    this.$dottedMask = this.$el.append('pattern')
      .attr('id', this._dottedMaskID)
      .attr('patternUnits', 'userSpaceOnUse')
      .attr('width', 4)
      .attr('height', 4);

    this.$dottedMask.append('circle')
      .attr('r', 1)
      .attr('cx', 1)
      .attr('cy', 1);

    return this;
  }

  /**
   * There can be a lot of shadows
   * @param id
   * @param opacity
   * @param color
   * @param offsetX
   * @param offsetY
   * @param height
   * @param width
   * @returns {Selection<any>}
   */
  private addShadowFilter(id: string,
                          opacity: number,
                          color: string,
                          offsetX: number,
                          offsetY: number,
                          height?: string,
                          width?: string,
                          deviation?: number) {

    const $filter = this.$el.append('filter')
      .attr('id', id);
    if (height) {
      $filter.attr('height', height)
        .attr('width', width);
    }

    $filter.append('feGaussianBlur')
      .attr('in', 'SourceAlpha')
      .attr('stdDeviation', deviation || 1.5);

    $filter.append('feOffset')
      .attr('dx', offsetX)
      .attr('dy', offsetY)
      .attr('result', 'offsetBlur');

    $filter.append('feFlood')
      .attr('flood-color', color)
      .attr('flood-opacity', opacity);

    $filter.append('feComposite')
      .attr('in2', 'offsetBlur')
      .attr('operator', 'in');

    const $feMerge = $filter.append('feMerge');

    $feMerge.append('feMergeNode');

    $feMerge.append('feMergeNode')
      .attr('in', 'SourceGraphic');

    return $filter;
  }
}
