import { BaseModel } from '../base.model';
import { EmployeeGroupModel, GroupModel, TeamModel, UnitModel } from '../companies';
import { IEmployee } from './employee.interface';
import { Role } from '@auth';
import { Setting } from '@model/settings/settings.model';
import { SkillModel } from '@model/profile/skill.model';
import { MasterplanEmployee } from '@model/masterplans/masterplan-employee.model';
import * as moment from 'moment-mini';
import { EmployeeRoleModel, PreferenceDataModel, mapPreferencesToDataModel } from '@shared/index';

export class EmployeeModel extends BaseModel implements IEmployee {
  public username: string;
  public email: string;
  public photo: string;

  public firstName: string;
  public middleName: string;
  public lastName: string;
  public weekendRotation: string;
  public employeeNumber: string;
  public title: string;

  public group: GroupModel;
  public teams: TeamModel[];
  public workPercentage: Setting;
  public roles: Role[];
  public active: boolean;
  public order: number;
  public skills: SkillModel[];

  public employeeMasterplans: MasterplanEmployee[];
  public employeeGroups: EmployeeGroupModel[];
  public employeeRoles: EmployeeRoleModel[];

  public preferences: PreferenceDataModel;
  public lastSuccessfulLogin: moment.Moment;

  constructor(data: any = {}) {
    super(data);
    this.username = data.username || '';
    this.email = data.email || '';
    this.photo = data.photo || 'https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png';

    this.firstName = data.firstName || '';
    this.lastName = data.lastName || '';
    this.middleName = data.middleName || '';
    this.weekendRotation = data.weekendRotation || '';
    this.employeeNumber = data.employeeNumber || '';
    this.title = data.title || '';
    this.roles = data.roles || [];
    this.workPercentage = data.workPercentage || [];
    this.active = data.active || false;
    this.group = (data.group && new GroupModel(data.group)) || this.extractGroup(data.employeeGroups);
    this.teams = data.teams?.map(team => new TeamModel(team)) || undefined;
    this.order = data.order;
    this.skills = data.employeeSkills?.map(skill => new SkillModel(skill)) || [];
    this.employeeMasterplans = data.employeeMasterplans?.map(masterplanEmployee => new MasterplanEmployee(masterplanEmployee)) || [];
    this.employeeGroups = data.employeeGroups?.map(employeeGroup => new EmployeeGroupModel(employeeGroup)) || [];
    this.preferences = data.preferences ? mapPreferencesToDataModel(data.preferences) : null;
    this.employeeRoles = data.employeeRoles?.map(employeeRole => new EmployeeRoleModel(employeeRole)) || [];
  }

  get fullName(): string {
    return `${this.lastName} ${this.firstName} ${this.middleName}`;
  }

  get nameWithLastInitial(): string {
    const mn = this.middleName && this.middleName.length > 0 ? this.middleNameInitial : '';
    return `${this.lastName}, ${this.firstName} ${mn}`;
  }

  get regularFullName(): string {
    return `${this.firstName} ${this.middleName} ${this.lastName}`;
  }

  get regularFullNameForSorting(): string {
    return `${this.lastName} ${this.firstName} ${this.middleName}`;
  }

  get name(): string {
    return `${this.lastName}, ${this.firstName}`;
  }

  get initials(): string {
    return `${this.firstNameInitial}${this.middleNameInitial}${this.lastNameInitial}`;
  }

  get firstNameInitial(): string {
    return `${this.firstName.charAt(0)}.`;
  }

  get middleNameInitial(): string {
    return `${this.middleName.charAt(0)}.`;
  }

  get lastNameInitial(): string {
    return `${this.lastName.charAt(0)}.`;
  }

  get groupWithAbbreviation(): string {
    return this.group.unit ? `${this.group.name} (${this.group.unit.abbreviation})` : `${this.group.name}`;
  }

  get skillsDisplay(): string {
    return this.skills.map(skill => skill.name).join(', ');
  }

  get unit(): UnitModel {
    return this.group?.unit;
  }

  private extractGroup(employeeGroups: { validFrom: string; validTo: string; group: any }[]) {
    const assignment = employeeGroups?.find(
      group =>
        (!group.validTo && moment.utc(group.validFrom).isSameOrBefore(moment.utc())) ||
        moment.utc().isBetween(moment.utc(group.validFrom), moment.utc(group.validTo))
    );

    return assignment ? new GroupModel(assignment.group) : undefined;
  }

  public getGroupAssignedForDate(date: moment.Moment): GroupModel {
    return this.employeeGroups.find(group => moment.utc(date).isBetween(moment.utc(group.validFrom), moment.utc(group.validTo || '9999-01-01'), 'day', '[]'))
      ?.group;
  }
}
