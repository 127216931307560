import { EventEmitter } from '@angular/core';
import * as d3 from 'd3';

import { translate, xmlns, textEllipsis, ELLIPSIS_SUFFIX } from '@helpers';

import { Defs } from '../defs/defs';
import { IBaseGrid } from '../grid/base-grid.interface';
import { Menu } from '../menu/menu';
import { IBaseTimeline } from '../utils/interfaces';
import { GroupIndication } from '../utils/group-indication';
import { IItemConfig, IBaseItem } from './interfaces';
import { OpenableItem } from './openable.item';
import { RootItem } from './root.item';
import { OffsetItem } from './system/offset.item';

export abstract class BaseItem implements IBaseItem {
  public isRendered = false;

  public index: number;
  public level: number;

  protected el: Element;
  protected $el: d3.Selection<any, any, any, any>;
  protected $title: d3.Selection<any, any, any, any>;
  protected $line: d3.Selection<any, any, any, any>;
  protected $background: d3.Selection<any, any, any, any>;
  protected shadowedLine: d3.Selection<any, any, any, any>;
  protected $gridBackgroundContainer: d3.Selection<any, any, any, any>;
  protected $gridBackground: d3.Selection<any, any, any, any>;

  protected _timeline: IBaseTimeline;
  protected _grid: IBaseGrid;
  protected _menu: Menu;
  protected _parent: RootItem | OpenableItem;
  protected _defs: Defs;

  protected _y: number;

  protected _isVisible = false;

  protected _onIndexUpdate = new EventEmitter();

  protected _height;

  private _offsets = { bottom: void 0, top: void 0 };

  private _groupIndication: GroupIndication;
  private _indicationFillColor: string;

  private readonly ELLIPSIS_MARGIN = 0.15;

  constructor(protected _title = '', protected _itemConfig: IItemConfig = { height: 40 }) {
    this.el = document.createElementNS(xmlns, 'g');
    this.$el = d3.select(this.el).classed('menu-item', true);
    this.$background = this.$el.append('rect');
    this.$line = this.$el.append('line');
    this.$title = this.$el.append('text');

    this.mergeWithDefaultConfig();
    this._height = this.config.height;
  }

  get title() {
    return this._title;
  }

  get isFirst() {
    return this.index === 0;
  }

  get isLast() {
    return this.index === this._parent.items.length - 1;
  }

  get indicationFillColor() {
    if (!this._indicationFillColor) {
      this._indicationFillColor = this._timeline.getColor(this.index);
    }

    return this._indicationFillColor;
  }

  get indicationText() {
    return (this.index + 1).toString();
  }

  get config() {
    return this._itemConfig;
  }

  get height() {
    return this._height;
  }

  set offsetTop(value) {
    this._offsets.top = value;
  }

  get offsetTop() {
    return this._offsets.top;
  }

  set offsetBottom(value) {
    this._offsets.bottom = value;
  }

  get offsetBottom() {
    return this._offsets.bottom;
  }

  set parent(parent: RootItem | OpenableItem) {
    this._parent = parent;
  }

  get parent() {
    return this._parent;
  }

  get groupIndication() {
    if (!this._groupIndication) {
      this._groupIndication = this.enableGroupIndication();
    }

    return this._groupIndication;
  }

  /**
   * Simple rendering
   * @returns {Element}
   */
  public render() {
    const sizes = this._timeline.sizes();
    const mw = sizes.menuWidth;

    this.$el.attr('transform', translate(0, this.y()));

    this.renderBackground();
    this.renderGridBackground();

    this.$line
      .attr('x1', mw)
      .attr('x2', mw)
      .attr('y1', 0)
      .attr('y2', this.config.height)
      .attr('class', 'line')
      .attr('visibility', 'hidden');

    this.renderTitle();

    this.renderOffset();

    this.isRendered = true;

    return this.el;
  }

  public renderShadowedLine() {
    if (this.shadowedLine) {
      return;
    }
    const sizes = this._timeline.sizes();
    this.shadowedLine = this.$el
      .append('line')
      .attr('x1', 0)
      .attr('x2', sizes.width)
      .attr('y1', 1)
      .attr('y2', 1)
      .classed('line openable-shadow', true);
  }

  public updatePosition() {
    this.clearCache();
    this.$el.attr('transform', translate(0, this.y()));

    this.renderBackground();
    this.renderGridBackground();
  }

  /**
   * Show item
   */
  public show() {
    this._isVisible = true;

    this.$el.attr('visibility', 'visible').attr('transform', translate(0, this.y()));

    if (this.$gridBackgroundContainer) {
      this.$gridBackgroundContainer
        .attr('transform', translate(0, this.y() + this.config.offset.top))
        .attr('visibility', 'visible');
    }
  }

  /**
   * Hide item
   */
  public hide() {
    this.$el.attr('visibility', 'hidden');

    this._isVisible = false;

    if (this.$gridBackgroundContainer) {
      this.$gridBackgroundContainer.attr('visibility', 'hidden');
    }
  }

  /**
   * Subscribe to event emitters
   */
  public subscribe() { }

  /************************************************************************************/

  /**
   * Getter for grid property
   * @returns {Timeline}
   */
  public getGrid() {
    return this._grid;
  }

  /**
   * Setter for grid property
   * @param grid
   * @returns {BaseItem}
   */
  public grid(grid: IBaseGrid): this {
    this._grid = grid;
    return this;
  }

  /**
   * Getter for timeline property
   * @returns {Timeline}
   */
  public getTimeline() {
    return this._timeline;
  }

  /**
   * Setter for timeline property
   * @param timeline
   * @returns {BaseItem}
   */
  public timeline(timeline: IBaseTimeline): this {
    this._timeline = timeline;
    return this;
  }

  /**
   * Menu setter
   * @param menu
   * @returns {BaseItem}
   */
  public menu(menu: Menu): this {
    this._menu = menu;
    return this;
  }

  /**
   * Defs setter
   * @param defs
   * @returns {BaseItem}
   */
  public defs(defs: Defs): this {
    this._defs = defs;
    return this;
  }

  /************************************************************************************/
  /**
   * delete cached variables
   */
  public clearCache(): this {
    delete this._y;
    return this;
  }

  /**
   * Getter for Y property
   * can use cached variable
   * @returns {number}
   */
  public y(y?: number): number {
    if (arguments.length) {
      this._y = y;

      this.$el.attr('transform', translate(0, this.y()));
      this.$gridBackgroundContainer.attr('transform', translate(0, this.y() + this.config.offset.top));
    }

    if ('undefined' === typeof this._y) {
      const number = this.number();
      if (number === 0 || number === -1) {
        this._y = 0;
      } else {
        const prev = this._menu.visible[number - 1];
        this._y = prev.offset() as number;
      }
    }
    return this._y;
  }

  /**
   * Number in opened items array
   * @returns {number}
   */
  public number(): number {
    return this._menu.visible.indexOf(this);
  }

  /**
   * Is it odd item ?
   * @returns {boolean}
   */
  public isOdd(): boolean {
    return this.index % 2 === 1;
  }

  /**
   * Can be overwritten in some root items,
   * which has subitems. So it's height will not be equal to rowHeight
   * @returns {number}
   */
  public totalHeight(): number {
    return this.config.height + this.config.offset.top + this.config.offset.bottom;
  }

  /**
   * Will return y offset from top of items to this bottom
   * @returns {number}
   */
  public offset(): number {
    return this.y() + this.totalHeight();
  }

  /*************************************************************************************/

  /**
   * Remove functionality
   */
  public remove(): void {
    const index = this.number();
    if (index > -1) {
      this._menu.visible.splice(index, 1);
    }

    this.removeBackground();

    this.$el.selectAll('*').remove();
    this.$el.remove();
  }

  public removeBackground() {
    if (this.$gridBackgroundContainer) {
      this.$gridBackgroundContainer.remove();
    }

    if (this._parent.items) {
      this._parent.items.forEach((item: IBaseItem) => {
        item.renderBackground();
        item.renderGridBackground();
      });
    }
  }

  /***********************************************************************************/
  /**
   * Get d3 selection of this element
   * @returns {d3.Selection<any, any, any, any>}
   */
  public element(): d3.Selection<any, any, any, any> {
    return this.$el;
  }

  /**
   * Getter for element
   * @returns {Element}
   */
  public node(): Element {
    return this.el;
  }

  public renderTitle() {
    const tw = 35;
    const hh = this.height / 2;

    this.$title
      .text(this._title)
      .attr('class', 'title')
      .attr('y', hh)
      .attr('x', tw)
      .attr('dy', '0.5ex');
  }

  public applyEllipsisToTitle() {
    const sizes = this._timeline.sizes();
    const offset = sizes.menuWidth * this.ELLIPSIS_MARGIN;
    const size = sizes.menuWidth - offset * 2;

    this.$title.each(textEllipsis(size));

    // Add title meta-tag for accessability/readability on text hover (only affected by ellipsis)
    if (this.$title.text().includes(ELLIPSIS_SUFFIX)) {
      const tt = this.$title.append('title');
      tt.text(this.title);
    }
  }

  /**
   * Separate background rendering
   */
  public renderBackground() {
    const sizes = this._timeline.sizes();
    const mw = sizes.menuWidth;
    this.$background
      .attr('y', this.config.offset.top)
      .attr('height', this.height)
      .attr('width', mw)
      .attr('fill', this.isOdd() ? '#f5f5f5' : '#ffffff');
  }

  public renderGridBackground() {
    const sizes = this._timeline.sizes();
    const width = sizes.width;

    if (!this.$gridBackgroundContainer) {
      const backgroundLayer = this._grid.itemBackgroundsContainer;

      this.$gridBackgroundContainer = backgroundLayer.append('g').classed('item-background', true);
    }

    if (!this.$gridBackground) {
      this.$gridBackground = this.$gridBackgroundContainer.append('rect');
    }

    this.$gridBackgroundContainer
      .attr('visibility', this._isVisible ? 'visible' : 'hidden')
      .attr('transform', translate(0, this.y() + this.config.offset.top));

    this.$gridBackground
      .attr('width', width)
      .attr('height', this.config.height)
      .attr('fill', this.isOdd() ? '#000000' : '#ffffff')
      .attr('fill-opacity', 0.04);
  }

  /**
   * Render offsets if was passed
   */
  public renderOffset() {
    if (this.offsetTop) {
      this.offsetTop.render();
    } else if (this.config.offset.top) {
      this.offsetTop = new OffsetItem(this, OffsetItem.OFFSET_TYPES.top, this._timeline);
      this.offsetTop.render();
    }

    if (this.offsetBottom) {
      this.offsetBottom.render();
    } else if (this.config.offset.bottom) {
      this.offsetBottom = new OffsetItem(this, OffsetItem.OFFSET_TYPES.bottom, this._timeline);
      this.offsetBottom.render();
    }
  }

  /**
   * Update indexes for child elements
   */
  protected updateIndexes() {
    if (this._parent && this._parent.items) {
      this._parent.items.forEach((item, index) => {
        item.index = index;
      });

      this._onIndexUpdate.next();
    }
  }

  protected enableGroupIndication() {
    return new GroupIndication(this, this._timeline);
  }

  /**
   *
   * @returns {Node}
   */
  protected clone() {
    return this.el.cloneNode(true);
  }

  /**
   * merge item config with default config
   */
  private mergeWithDefaultConfig() {
    let offset = this._itemConfig.offset;
    offset = Object.assign(
      {
        top: null,
        bottom: null
      },
      offset
    );

    Object.assign(this._itemConfig, {
      offset
    });
  }
}
