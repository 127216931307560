export enum SettingsKey {
  EmployeeWorkPercentage = 1,
  GroupAverageWorkHours = 2,
  GroupConsecutiveWeeklyTimeOff = 3,
  GroupAveragingPeriod = 4,
  ArgusToggle = 5,
  VacayToggle = 6,
  RostrToggle = 7,
}

export const settingsKeyMapper = (key: number): SettingsKey => {
  switch (key) {
    case 1:
      return SettingsKey.EmployeeWorkPercentage;
    case 2:
      return SettingsKey.GroupAverageWorkHours;
    case 3:
      return SettingsKey.GroupConsecutiveWeeklyTimeOff;
    case 4:
      return SettingsKey.GroupAveragingPeriod;
    case 5:
      return SettingsKey.ArgusToggle;
    case 6:
      return SettingsKey.VacayToggle;
    default:
      return null;
  }
};

export enum SettingsEntityTables {
  Employees = 'employees',
  CompaniesGroups = 'companies_groups',
  CompaniesCompanies = 'companies_companies'
}

export enum SettingsTypes {
  String = 'string',
  Number = 'number',
  DateRange = 'dateRange',
}
