import { datesComparer } from '@helpers';
import { SkillModel } from '@model/profile/skill.model';
import { GroupModel } from '@models';

export interface EmployeeDataWithPreferencesResult {
  employeeWithValidQualifications: {
    id: number;
    username: string;
    firstName: string;
    middleName: string;
    lastName: string;
    employeeSkills: EmployeeSkill[];
    employeeGroups: EmployeeGroup[];
  };
  preferencesForEmployee: EmployeePreferences;
  employeeWorkPercentage: EmployeeWorkPercentage[];
}

export interface EmployeeSkill {
  id: number;
  validFrom: string;
  validTo: string;
  skill: {
    name: string;
  };
}

export interface EmployeeGroup {
  id: number;
  validFrom: string;
  validTo: string;
  group: {
    name: string;
  };
}

export interface EmployeePreferences {
  hardNightShiftOK: boolean;
  hardAfterShiftBreak: number;
  hardConsecutiveWeeklyOff: number;
  hardWeeklyWorkMax: number;
  hardWorkDurationMin: number;
  hardWorkDurationMax: number;
}

export interface EmployeeWorkPercentage {
  value: number;
  dateFrom: string;
}

export class EmployeeDataWithPreferencesModel {
  id: number;
  username: string;
  skills: SkillModel[];
  firstName: string;
  middleName: string;
  lastName: string;
  groups: GroupModel[];
  preferences: EmployeePreferences;
  workPercentage: EmployeeWorkPercentage[];

  constructor({ employeeWithValidQualifications: employee, preferencesForEmployee, employeeWorkPercentage }: EmployeeDataWithPreferencesResult) {
    this.id = employee.id;
    this.username = employee.username;
    this.firstName = employee.firstName;
    this.middleName = employee.middleName;
    this.lastName = employee.lastName;
    this.groups = employee.employeeGroups.map(employeeGroup => new GroupModel(employeeGroup));
    this.skills = employee.employeeSkills.map(employeeSkill => new SkillModel(employeeSkill));
    this.preferences = preferencesForEmployee;
    this.workPercentage = employeeWorkPercentage;
    this.workPercentage.sort((a, b) => datesComparer(new Date(a.dateFrom), new Date(b.dateFrom), false));
  }
}
