import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { ShiftDetailsService } from '@app/rostr/overview/api/services/shift-details.service';
import { AssignedShift } from '@app/vacay/interfaces';
import { ShiftService } from '@services';
import { ShiftCodeTypeEnum } from '@shared/enums/shift-code-type.enum';
import moment from 'moment';
import { map, tap } from 'rxjs/operators';

interface ShiftSummaryData {
  start: number;
  end: number;
  duration: number;
  skills?: string[];
  creator?: string;
  project?: string;
  description?: string;
  group?: string;
  workingTimeInMinutes: number;
  origin?: string;
}

@Component({
  selector: 'vc-shift-info',
  templateUrl: './shift-info.component.html',
  styleUrls: ['./shift-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShiftInfoComponent {
  @Input() set shiftInput(value: AssignedShift) {
    this.errorMessage = null;
    this.shift = value;
    this.loadShift(value);
  }

  ShiftCodeType = ShiftCodeTypeEnum;
  public shift: AssignedShift;
  data: ShiftSummaryData;
  loading = false;
  errorMessage: string;

  constructor(
    private readonly shiftService: ShiftService,
    private readonly shiftDetailsService: ShiftDetailsService,
    private readonly changeDetector: ChangeDetectorRef
  ) {}

  loadShift(shift: AssignedShift) {
    if (!shift) {
      this.errorMessage = 'No shift found.';
      this.changeDetector.markForCheck();
      return;
    }
    this.loading = true;
    switch (shift.parentType) {
      case 'required':
        this.loadRequiredShift(shift.parentId || shift.id);
        break;
      case 'agenda':
        this.loadAgenda(shift.parentId);
        break;
      default:
        this.loadRegularShift(shift.code);
    }
  }

  private dataReady() {
    this.loading = false;
    this.changeDetector.markForCheck();
  }

  private loadAgenda(parentId: number) {
    return this.shiftDetailsService
      .getAgendaRequestWithShiftDetails(parentId)
      .pipe(
        tap(agendaRequest => {
          this.loading = false;
        }),
        map(agendaRequest => {
          if (!agendaRequest) {
            this.errorMessage = 'This Agenda request has been removed.';
            return null;
          }
          const shift = agendaRequest.shiftCode;
          const createdBy = agendaRequest.createdBy;
          const data: ShiftSummaryData = {
            start: shift.start,
            end: shift.start + shift.duration,
            duration: shift.duration,
            workingTimeInMinutes: shift.workingTimeInMinutes,
            creator: createdBy ? `${createdBy.firstName} ${createdBy.lastName}` : 'Unknown',
            description: agendaRequest.description,
            project: agendaRequest.objective.project.title,
            origin: 'Agenda'
          };

          this.data = data;
        })
      )
      .subscribe(result => {
        this.changeDetector.markForCheck();
      });
  }

  private loadRequiredShift(id: number) {
    this.shiftDetailsService.getRequiredShiftDetails(id).subscribe(shift => {
      this.data = {
        start: shift.start,
        end: shift.start + shift.duration,
        duration: shift.duration,
        skills: shift.data.qualifications.skills,
        group: shift.group.name,
        workingTimeInMinutes: shift.workingTimeInMinutes,
        origin: 'Required'
      };
      this.dataReady();
    });
  }

  private loadRegularShift(code: string) {
    this.shiftService.fetchByCode(code).subscribe(shifts => {
      if (!shifts || shifts.length === 0) {
        this.data = {
          start: this.setTimesForShift(this.shift.dateFrom),
          end: this.setTimesForShift(this.shift.dateTo),
          duration: this.shift.duration,
          workingTimeInMinutes: this.shift.duration,
          description: 'The assigned shift is no longer recognized as a valid shift code, though it is still a valid for this schedule.'
        };
        this.dataReady();
        return;
      }
      const shift = shifts[0];
      this.data = {
        start: shift.start,
        end: shift.start + shift.duration,
        duration: shift.duration,
        workingTimeInMinutes: shift.workingTimeInMinutes
      };
      this.dataReady();
    });
  }

  private setTimesForShift(value) {
    const date = new Date(value);
    if (!isNaN(date.getTime())) {
      return date.getHours() * 60 + date.getMinutes();
    }

    const momentDate = moment(value);
    if (momentDate.isValid()) {
      return momentDate.hours() * 60 + momentDate.minutes();
    }

    return null;
  }
}
