import * as d3 from 'd3';
import { EventEmitter } from '@angular/core';

import { IBaseGrid } from '../grid/base-grid.interface';
import { IBaseTimeline } from '../utils/interfaces';
import { BaseItem } from '../items/base.item';
import { scale, translate, xmlns } from '@helpers';


export class Cutable {

  public onCancel = new EventEmitter<any>();
  public onApply = new EventEmitter<any>();

  protected el: Element;
  protected $el: d3.Selection<any, any, any, any>;

  protected $dialogContainer: d3.Selection<any, any, any, any>;
  protected $dialogApplyButton: d3.Selection<any, any, any, any>;
  protected $dialogCancelButton: d3.Selection<any, any, any, any>;

  protected $leftArrow: d3.Selection<any, any, any, any>;
  protected $rightArrow: d3.Selection<any, any, any, any>;

  protected timer;

  protected $body: d3.Selection<any, any, any, any>;
  protected $background: d3.Selection<any, any, any, any>;

  protected drag: d3.DragBehavior<any, any, any>;

  protected _sizes = {
    circleRadius: 12,
    height: 0,
    offset: 0,
    width: 0
  };

  protected result = {
    pos: 0,
    interval: null
  };

  protected limits = {
    left: 0,
    right: 0
  };

  protected intervals = [];

  protected positions = {
    initial: 0,
    current: 0,
    interim: 0
  };

  constructor(protected _startInterval: any,
    protected _item: BaseItem,
    protected _timeline: IBaseTimeline,
    protected _grid: IBaseGrid,
    protected _width: number,
    protected mouseCoords = void 0) {
    this.el = document.createElementNS(xmlns, 'g');
    this.$el = d3.select(this.el).classed('cutable-container', true);
    this.$body = this.$el.append('g').classed('body-container', true);

    this._sizes.width = _width;

    this.renderDialog();

    this.drag = d3.drag();
    this.$body.call(this.drag);

    this.initSizes();

    this.render();
    this.showDialog();
  }

  /**
   * Render
   */
  public render() {
    const sizes = this._timeline.sizes();
    const rh = this._item.config.height;
    const shiftMargin = sizes.shiftMargin;
    const height = rh * 0.825;  // 33px for 40px of row height
    const offset = rh * 0.0925;

    this.$el.attr('transform', translate(this._startInterval.x(), this._item.y()));
    this.$body.attr('transform', translate(this.positions.current, 0));

    this.$body.append('line')
      .attr('class', 'cutable-line')
      .attr('y1', 1)
      .attr('y2', height + offset + 8)
      .attr('x1', 0)
      .attr('x2', 0);

    this.$leftArrow = this.$body.append('path')
      .attr('d', 'M5.526 2.53V.01L0 5.538l5.526 5.527v-2.52H10V2.53H5.526z')
      .attr('transform', translate(-14, offset + height / 2 - 6))
      .classed('arrow', true);

    this.$rightArrow = this.$body.append('path')
      .attr('d', 'M5.526 2.53V.01L0 5.538l5.526 5.527v-2.52H10V2.53H5.526z')
      .attr('transform', translate(14, offset + height / 2 - 6) + scale(-1, 1))
      .classed('arrow', true);

    this.$body.append('circle')
      .attr('cx', 0)
      .attr('cy', height + offset + this._sizes.circleRadius + 2)
      .attr('r', this._sizes.circleRadius);

    this.$body.append('text')
      .attr('class', 'material-icons')
      .attr('x', -shiftMargin * 2)
      .attr('y', height + offset + this._sizes.circleRadius + 7.5 + 2) // Icon size = 15/2
      .text('content_cut');

    this._timeline.underMenuSysContainer.node().appendChild(this.el);
    this.events();
  }

  /**
   * Events for drag and any events
   */
  public events() {

    this.$dialogCancelButton.on('click', () => {
      this.destroy();
      this.onCancel.emit(true);
    });
    this.$dialogApplyButton.on('click', () => {
      this.destroy();
      this.onApply.emit(this.result.interval);
    });

    this.drag.on('start', () => {
      this.hideDialog();
      this.hideArrows();
      if (this.timer) {
        clearTimeout(this.timer);
      }
    });

    this.drag.on('drag', () => {
      const dx = d3.event.dx;
      this.hideDialog();
      this.hideArrows();

      if (dx > 0) {
        this.moveRight();
      } else if (dx < 0) {
        this.moveLeft();
      }
    });

    this.drag.on('end', () => {
      this.timer = setTimeout(() => {
        this.showDialog();
        this.showArrows();
      }, 200);
    });
  }

  /**
   * Destroy
   */
  public destroy() {
    if (this.timer) {
      clearTimeout(this.timer);
    }

    this.hideDialog();

    this.drag.on('start', null);
    this.drag.on('drag', null);
    this.drag.on('end', null);

    this.$el.selectAll('*').remove();
    this.$el.node().remove();
  }

  /**
   * Initial sizes
   */
  protected initSizes() {
    this.intervals = this._grid.intervals();

    const interval = this._grid.nextInterval(this._startInterval);
    if (interval) {
      this.limits.left = interval.x();
    }

    const lastInterval = this._grid.getIntervalByCoord(this._startInterval.x() + this._width);
    if (lastInterval) {
      this.limits.right = lastInterval.x();
    } else {
      this.limits.right = this.intervals[this.intervals.length - 1].x();
    }

    if (this.mouseCoords) {
      const clickedInterval = this._grid.getNearIntervalByCoord(this._startInterval.x() + this.mouseCoords[0]);
      let clickedX = clickedInterval.x();

      if (clickedX < this.limits.left) {
        clickedX = this.limits.left;
      }

      if (clickedX > this.limits.right) {
        clickedX = this.limits.right;
      }

      this.positions.current = clickedX - this._startInterval.x();
      this.positions.interim = clickedX;
      this.positions.initial = clickedX;

      this.result.interval = clickedInterval;
    } else {
      this.positions.current = this.limits.left - this._startInterval.x();
      this.positions.interim = this.limits.left;
      this.result.interval = interval;
      this.positions.initial = this._startInterval.x();
    }


    this.result.pos = this.positions.current;
  }

  /**
   * Render cancel/apply dialog
   */
  protected renderDialog() {
    const container = document.createElementNS(xmlns, 'g');
    this.$dialogContainer = d3.select(container).classed('dialog-container', true);

    this.$dialogCancelButton = this.$dialogContainer.append('g')
      .classed('cancel-btn', true);

    this.$dialogCancelButton.append('rect')
      .attr('width', 40)
      .attr('height', 28)
      .attr('rx', 3)
      .attr('ry', 3);

    this.$dialogCancelButton.append('text')
      .attr('class', 'material-icons')
      .text('clear')
      .attr('x', 10)
      .attr('y', 24);

    this.$dialogApplyButton = this.$dialogContainer
      .append('g')
      .classed('apply-btn', true);

    this.$dialogApplyButton.append('rect')
      .attr('width', 40)
      .attr('height', 28)
      .attr('rx', 3)
      .attr('ry', 3);

    this.$dialogApplyButton.append('text')
      .attr('class', 'material-icons')
      .text('check')
      .attr('x', 10)
      .attr('y', 24);
  }

  /**
   * Show cancel/apply dialog
   */
  protected showDialog() {
    this.$el.node().appendChild(this.$dialogContainer.node());

    const buttonWidth = this.$dialogCancelButton.node().getBoundingClientRect().width;

    this.$dialogApplyButton.attr('transform', translate(buttonWidth + 5, 0));

    const dialogWidth = this.$dialogContainer.node().getBoundingClientRect().width;

    this.$dialogContainer.attr('transform', translate(
      this._sizes.width / 2 - dialogWidth / 2,
      -30)
    );
  }

  /**
   * Hide cancel/apply dialog
   */
  protected hideDialog() {
    this.$el.selectAll('.dialog-container').remove();
  }

  /**
   * Show Arrow
   */
  protected showArrows() {
    this.$leftArrow.attr('visibility', 'visible');
    this.$rightArrow.attr('visibility', 'visible');
  }

  /**
   * Hide Arrow
   */
  protected hideArrows() {
    this.$leftArrow.attr('visibility', 'hidden');
    this.$rightArrow.attr('visibility', 'hidden');
  }

  /**
   * Left
   */
  protected moveLeft() {
    const dx = d3.event.dx;
    const x = this.positions.interim + dx;

    const nearPos = this.findLeftNearPosition(x);

    if (this.positions.current !== x && nearPos.pos >= this.limits.left && nearPos.pos <= this.limits.right) {
      this.positions.current = nearPos.pos - this._startInterval.x();
      this.$body.attr('transform', translate(this.positions.current, 0));
      this.result = nearPos;
    }

    this.positions.interim = x;
  }

  /**
   * Right
   */
  protected moveRight() {
    const dx = d3.event.dx;
    const x = this.positions.interim + dx;

    const nearPos = this.findRightNearPosition(x);

    if (this.positions.current !== x && nearPos.pos >= this.limits.left && nearPos.pos <= this.limits.right) {
      this.positions.current = nearPos.pos - this._startInterval.x();
      this.$body.attr('transform', translate(this.positions.current, 0));
      this.result = nearPos;
    }

    this.positions.interim = x;
  }

  /**
   * Looking left-hand-side near position (for ticks)
   * @param position
   * @returns {any}
   */
  protected findLeftNearPosition(position) {
    const result = {
      pos: 0,
      interval: null
    };
    this.intervals.forEach((interval) => {
      const xPos = interval.x();
      if (xPos <= position) {
        result.pos = xPos;
        result.interval = interval;
      }
    });

    return result;
  }

  /**
   * Looking right-hand-side near position (for ticks)
   * @param position
   * @returns {any}
   */
  protected findRightNearPosition(position) {
    const result = {
      pos: 0,
      interval: null
    };
    this.intervals.every((interval) => {
      const xPos = interval.x();
      result.pos = xPos;
      result.interval = interval;
      return xPos < position;
    });

    return result;
  }
}
