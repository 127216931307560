import { Apollo } from 'apollo-angular';
import { EventEmitter, Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';

import { isArray } from 'lodash-es';

import { EmployeeModel, CompanyModel, DivisionModel, SiteModel, UnitModel, GroupModel, TeamModel } from '@models';
import {
  companySearch,
  searchEmployee,
  employeesSearch,
  entityIdNameByEntityType,
  entityByEntityType
} from './entity-search.queries';

@Injectable({
  providedIn: 'root'
})
export class EntitySearchService {
  public entitySelected: EventEmitter<{ id: number; name: string }> = new EventEmitter();
  public currentEntity: { id: number; name: string };

  constructor(private apollo: Apollo) {
    this.entitySelected.subscribe(entity => {
      this.currentEntity = entity;
    });
  }

  /**
   * Search entity
   * @returns {Observable<any>}
   */
  public search(term: string) {
    return this.apollo
      .query<any>({
        query: companySearch,
        variables: { term }
      })
      .pipe(
        map(response =>
          Object.keys(response.data.companySearch)
            .filter(key => isArray(response.data.companySearch[key]))
            .map(key => {
              return response.data.companySearch[key].map(item => this.createDynamicModel(key, item, EmployeeModel));
            })
            .reduce((prev, curr) => {
              return prev.concat(curr);
            }, [])
        )
      );
  }

  /**
   * Search entity
   * @returns {Observable<any>}
   */
  public searchEmployee(term: string): any {
    return this.apollo
      .query<any>({
        query: searchEmployee,
        variables: { term }
      })
      .pipe(
        map(response => {
          const data: any = response.data.companySearch;
          if (data.employees) {
            const employeesResponse = data.employees.reduce((acc, item) => {
              acc.push(new EmployeeModel(item));
              return acc;
            }, []);

            return employeesResponse; // fixme temporary solution
          } else {
            return [];
          }
        })
      );
  }

  /**
   * Search entity
   * @returns {Observable<any>}
   */
  public searchEmployeeByEntityId(id: number, entityType: string): any {
    return this.apollo
      .query<any>({
        query: employeesSearch(entityType),
        variables: { id }
      })
      .pipe(
        map(response => {
          const data: any = response.data.employees;
          return data.map(employee => new EmployeeModel(employee));
        })
      );
  }

  public getEntityByTypeAndId(id: number, entityType: string): any {
    const query = entityType !== 'employee' ? entityIdNameByEntityType(entityType) : entityByEntityType(entityType);
    return this.apollo
      .query<any>({
        query,
        variables: { id }
      })
      .pipe(
        tap(response => this.entitySelected.emit(response.data[entityType])),
        map(response => response.data[entityType])
      );
  }

  private createDynamicModel(modelName: string, item: any, def: any) {
    const modelMappings = {
      companies: CompanyModel,
      divisions: DivisionModel,
      sites: SiteModel,
      units: UnitModel,
      groups: GroupModel,
      teams: TeamModel,
      employees: EmployeeModel
    };

    const model = modelMappings[modelName] || def;

    return new model(item);
  }
}
