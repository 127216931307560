import { gql } from 'apollo-angular';

export const loadSkillsQuery = gql`
  query loadSkills {
    skills {
      id
      name
    }
  }
`;

export const getSkillQuery = gql`
  query skill($id: Int!) {
    skill(id:$id){
      id
      name
    }
  }
`;

export const updateSkill = gql`
  mutation updateSkill(
    $id: Int!,
    $name: String!,
  ) {
    updateSkill(
      id: $id,
      name: $name,
    ) {
      id
      name
    }
  }
`;


export const createSkill = gql`
  mutation createSkill(
    $name: String!,
  ) {
    createSkill(
      name: $name,
    ) {
      id
      name
    }
  }
`;
