import { DivisionModelData } from '@interfaces';
import { BaseModel } from '../base.model';


export class DivisionModel extends BaseModel {

  public name: string;
  public companyId: number;

  public position: number;
  public type: 'divisions';
  public typeSingular: 'division';

  constructor(data: DivisionModelData = {}) {
    super(data);

    this.name = data.name || '';
    this.companyId = data.companyId || null;

    this.position = data.position || null;
  }

}
