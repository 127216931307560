import { HttpLink } from 'apollo-angular/http';
import { Apollo, ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import * as Sentry from '@sentry/browser';
import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { NgModule, ErrorHandler, Injectable } from '@angular/core';
import { BrowserModule, HammerGestureConfig, HammerModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BLACK_10, DARK_GREY_BLUE } from '@shared/const';
import { environment } from '@src/environments/environment';
import * as apolloClientFactory from '@core';
import { CoreModule, ConfigService } from '@core';
import { GlobalErrorHandler } from './_core/global-error-handler';
import { SharedModule } from './_shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import * as Hammer from 'hammerjs';
import { AuthInterceptor } from './_core/interceptors/auth/auth.interceptor';
import { NgxPiwikProModule, NgxPiwikProRouterModule } from '@piwikpro/ngx-piwik-pro';
import { VersionHeaderInterceptor } from './_core/interceptors/version-header.interceptor';

Sentry.init(environment.sentry);

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: 'localhost'
  },
  palette: {
    popup: {
      background: BLACK_10
    },
    button: {
      background: DARK_GREY_BLUE
    }
  },
  theme: 'edgeless',
  type: 'info'
};

@Injectable()
export class HorizonHammerConfig extends HammerGestureConfig {
  overrides = {
    swipe: { direction: Hammer.DIRECTION_ALL }
  };
}

let piwikModules = [];
if (environment.piwik.containerId && environment.piwik.containerUrl) {
  piwikModules = [NgxPiwikProModule.forRoot(environment.piwik.containerId, environment.piwik.containerUrl), NgxPiwikProRouterModule.forRoot()];
}

@NgModule({
  imports: [
    BrowserModule,
    HammerModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    SharedModule.forRoot(),
    HttpClientModule,
    ApolloModule,
    LoadingBarHttpClientModule,
    MatProgressBarModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    ...piwikModules
  ],
  declarations: [AppComponent],
  providers: [
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: HorizonHammerConfig
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    Apollo,
    {
      provide: APOLLO_OPTIONS,
      useFactory: (httpLink: HttpLink, configService: ConfigService) => {
        return apolloClientFactory.init(httpLink, configService);
      },
      deps: [HttpLink, ConfigService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: VersionHeaderInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
