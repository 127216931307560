import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class KeyboardState {
  private readonly isCtrlDownSource = new BehaviorSubject(false);

  public isCtrlDown$ = this.isCtrlDownSource.asObservable().pipe(distinctUntilChanged());

  get isCtrlDown() {
    return this.isCtrlDownSource.getValue();
  }

  set isCtrlDown(value: boolean) {
    this.isCtrlDownSource.next(value);
  }
}
