<mat-card>
  <mat-card-content>
    <canvas id="durationChartCanvas">{{ durationChart }}</canvas>
  </mat-card-content>
</mat-card>
<mat-card>
  <mat-card-content>
    <canvas id="timeOffBeforeChartCanvas">{{ timeOffBeforeChart }}</canvas>
  </mat-card-content>
</mat-card>
<mat-card>
  <mat-card-content>
    <canvas id="timeOffAfterChartCanvas">{{ timeOffAfterChart }}</canvas>
  </mat-card-content>
</mat-card>
