import { BaseModel } from '../base.model';
import { SectorModel, CombinationModel } from '../sectors';
import { EndorsementModel } from '../qualifications/endorsement.model';
import { GroupModel } from './group.model';
import { SiteModel } from './site.model';


export class UnitModel extends BaseModel {

  public name: string;
  public icao: string;
  public abbreviation: string;
  public siteId: number;

  public groups: GroupModel[];
  public endorsements: EndorsementModel[];
  public site: SiteModel;
  public sectorIds: number[];
  public sectors: SectorModel[];
  public plannerCombinations: CombinationModel[];

  public position: number;
  public type = 'units';
  public typeSingular = 'unit';

  constructor(data: any = {}) {
    super(data);
    this.name = data.name || '';
    this.icao = data.icao || '';
    this.abbreviation = data.abbreviation || '';
    this.siteId = data.siteId || null;
    this.site = (data.site && new SiteModel(data.site)) || undefined;
    this.groups = (data.groups && data.groups.map(el => new GroupModel(el))) || [];
    this.endorsements = data.unitEndorsements?.map(el => new EndorsementModel(el)) || [];

    this.sectors = (data.sectors && data.sectors.map(el => new SectorModel(el))) || [];
    this.sectorIds = (data.sectorIds && data.sectorIds.slice()) || [];
    this.plannerCombinations = (data.plannerCombinations && data.plannerCombinations.map(el => new CombinationModel(el))) || [];

    this.position = data.position || null;
  }

}
