<div class="dialog-window">
  <h1 data-testid="avai-warnings-window-title">AVAI warnings</h1>
  <div class="sticky-form">
    <div class="form-styles" [formGroup]="searchForm">
      <mat-form-field class="full-width" appearance="fill">
        <mat-label>Search</mat-label>
        <mat-icon matPrefix>search</mat-icon>
        <input type="text" placeholder="Enter phrase" matInput formControlName="term" />
      </mat-form-field>
    </div>
    <div class="stats">
      <span class="label">Errors: </span>
      <span class="value" [class.error]="errorsCount > 0">{{ errorsCount }}</span>
      <span class="label">Warnings: </span>
      <span class="value" [class.warning]="warningsCount > 0">{{ warningsCount }}</span>
      <span class="label">Excluded: </span>
      <span class="value">{{ excludedCount }}</span>
    </div>
    <div>
      <button mat-stroked-button (click)="toggleFilters()">
        <span *ngIf="filtersEnabled"> Disable Filters </span>
        <span *ngIf="!filtersEnabled"> Enable Filters </span>
      </button>
    </div>
  </div>
  <mat-table matSort [dataSource]="matDataSource" matSortActive="date" matSortDirection="desc" [matSortDisabled]="loading$ | async">
    <ng-container matColumnDef="updatedAt">
      <mat-header-cell *matHeaderCellDef scope="col">
        <span mat-sort-header>Updated</span>
      </mat-header-cell>
      <mat-cell *matCellDef="let row" [class.accepted]="row.isAccepted">
        {{ row.updatedAt | date: 'YYYY-MM-dd HH:mm:ss' }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="code">
      <mat-header-cell *matHeaderCellDef scope="col">
        <span mat-sort-header>Code</span>
        <app-column-filter
          [items]="validationCodes"
          blankDisplay="(blank)"
          nameField="value"
          valueField="value"
          (valueChange)="filterByProperty($event, 'code')"
          [disabled]="!filtersEnabled"
          localStorageId="validations-list-code"
        ></app-column-filter>
      </mat-header-cell>
      <mat-cell *matCellDef="let row" [class.accepted]="row.isAccepted">
        {{ row.code }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="severity">
      <mat-header-cell *matHeaderCellDef scope="col">
        <span mat-sort-header>Severity</span>
        <app-column-filter
          [items]="severities"
          blankDisplay="(blank)"
          nameField="value"
          valueField="value"
          (valueChange)="filterByProperty($event, 'severity')"
          [disabled]="!filtersEnabled"
          localStorageId="validations-list-severity"
        ></app-column-filter>
      </mat-header-cell>
      <mat-cell *matCellDef="let row" [style.color]="getSeverityColor(row.severity)" [class.accepted]="row.isAccepted">
        {{ row.severity }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="level">
      <mat-header-cell *matHeaderCellDef scope="col">
        <span mat-sort-header>Level</span>
        <app-column-filter
          [items]="levels"
          blankDisplay="(blank)"
          nameField="value"
          valueField="value"
          (valueChange)="filterByProperty($event, 'level')"
          [disabled]="!filtersEnabled"
          localStorageId="validations-list-level"
        ></app-column-filter>
      </mat-header-cell>
      <mat-cell *matCellDef="let row" [class.accepted]="row.isAccepted">
        {{ row.level | lowercase }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="employeeName">
      <mat-header-cell *matHeaderCellDef scope="col">
        <span mat-sort-header>Employee</span>
        <app-column-filter
          [items]="allEmployeesNames"
          blankDisplay="(blank)"
          nameField="value"
          valueField="value"
          (valueChange)="filterByProperty($event, 'employeeName')"
          [disabled]="!filtersEnabled"
          localStorageId="validations-list-employee"
        ></app-column-filter>
      </mat-header-cell>
      <mat-cell *matCellDef="let row" [class.accepted]="row.isAccepted">
        {{ row.employeeName }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="target">
      <mat-header-cell *matHeaderCellDef scope="col">
        <span>Target</span>
      </mat-header-cell>
      <mat-cell *matCellDef="let row" [class.accepted]="row.isAccepted">
        {{ getTargetValue(row) }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="message">
      <mat-header-cell *matHeaderCellDef scope="col">
        <span>Message</span>
      </mat-header-cell>
      <mat-cell *matCellDef="let row" [class.accepted]="row.isAccepted">
        {{ row.message }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="footer">
      <mat-footer-cell *matFooterCellDef>
        <div class="footer">
          <mat-paginator [pageSizeOptions]="[25, 50, 100]" showFirstLastButtons></mat-paginator>
        </div>
      </mat-footer-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    <mat-footer-row class="no-padding" *matFooterRowDef="['footer']; sticky: true"></mat-footer-row>
  </mat-table>
</div>
