import * as moment from 'moment-mini';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { AgendaShiftRequest } from '../models/agenda/agenda-shift-request';
import { Apollo, gql } from 'apollo-angular';
import { ErrorService } from '@services';

export enum AgendaDateStatus {
  DECLINED = 0,
  PENDING = 1,
  APPROVED = 2
}

@Injectable({
  providedIn: 'root'
})
export class AgendaService {
  constructor(
    private apollo: Apollo,
    private errorService: ErrorService
  ) {}

  private agendaListSubject: BehaviorSubject<AgendaShiftRequest[]> = new BehaviorSubject<AgendaShiftRequest[]>([]);
  public agendaList$: Observable<AgendaShiftRequest[]> = this.agendaListSubject.asObservable();

  public getAgendaForADay(date: moment.Moment): Observable<AgendaShiftRequest[]> {
    return this.agendaList$.pipe(map(agendas => agendas.filter(agenda => agenda.date.isSame(date, 'day'))));
  }

  public loadAgendaList(from: moment.Moment, to: moment.Moment, userId: number): Observable<AgendaShiftRequest[]> {
    const agendaList: AgendaShiftRequest[] = [];
    const dateFrom: Date = from.toDate();
    const dateTo: Date = to.toDate();
    return this.getAgendaRequestsWithStatuses([], [userId], dateFrom, dateTo).pipe(
      map(data => {
        data.forEach(agenda => {
          const selectedDates = Object.keys(agenda.statuses).map(date => moment(date));
          selectedDates.forEach(date => {
            const agendaMoment = moment(date);

            if (agendaMoment.isBefore(from.toDate(), 'day') || agendaMoment.isAfter(to.toDate(), 'day')) {
              // filter out agenda that are not in the range
            } else {
              agendaList.push({
                date: agendaMoment,
                project: agenda.objective?.project?.title || 'No Title',
                objective: agenda.objective?.objecttive?.title || '',
                description: agenda.description,
                status: agenda.statuses[agendaMoment.format('YYYY-MM-DD')],
                createdBy: `${agenda.createdBy.lastName}, ${agenda.createdBy.firstName}`,
                createdAt: moment.utc(agenda.createdAt, 'YYYY-MM-DDTHH:mm:ssZ'),
                shiftCode: agenda.shiftCode?.code,
                isHistorical: agendaMoment.isBefore(moment(), 'day')
              });
            }
          });
        });
        agendaList.sort((a, b) => (a.date > b.date ? 1 : -1));
        this.agendaListSubject.next(agendaList);
        return agendaList;
      })
    );
  }

  public getAgendaRequestsWithStatuses(createdByIds: number[], employeeIds: number[], dateFrom: Date, dateTo: Date): Observable<AgendaBaselineDto[]> {
    return this.apollo
      .query<{ agendaBaselineRequestsByCreatorAndDates: AgendaBaselineDto[] }>({
        query: this.agendaBaselineRequestsQuery,
        variables: {
          createdByIds: createdByIds,
          employeeIds,
          dateFrom: dateFrom?.toISOString(),
          dateTo: dateTo?.toISOString()
        }
      })
      .pipe(
        map(response => {
          return response.data.agendaBaselineRequestsByCreatorAndDates;
        }),
        catchError((err: Error | string) => {
          return this.errorService.handle(err);
        })
      );
  }

  private agendaBaselineRequestsQuery = gql`
    query agendaBaselineRequests($createdByIds: [Int], $employeeIds: [Int], $dateFrom: String, $dateTo: String) {
      agendaBaselineRequestsByCreatorAndDates(createdByIds: $createdByIds, employeeIds: $employeeIds, dateFrom: $dateFrom, dateTo: $dateTo) {
        description
        statuses
        createdBy {
          id
          firstName
          lastName
        }
        updatedBy {
          id
          firstName
          lastName
        }
        createdAt
        updatedAt
        objective {
          id
          objective {
            id
            title
          }
          project {
            id
            title
          }
        }
        shiftCode {
          code
          id
        }
      }
    }
  `;
}

export interface AgendaBaselineDto {
  description: string;
  statuses?: {
    [date: string]: AgendaDateStatus;
  };
  createdBy: { id: number; firstName: string; lastName: string };
  updatedBy: { id: number; firstName: string; lastName: string };
  createdAt: Date;
  updatedAt: Date;
  objective: { id: number; objecttive: { id: number; title: string }; project: { id: number; title: string } };
  shiftCode: { code: string; id: number };
}
