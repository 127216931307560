import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';

import { ScheduleModel } from '@model/schedules/schedule.model';
import { deleteSchedule } from '../queries/schedule-delete.queries';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScheduleDeleteService {
  private scheduleRemovedSub = new Subject();
  public scheduleRemoved$ = this.scheduleRemovedSub.asObservable();
  constructor(private readonly apollo: Apollo) {}

  public deleteSchedule(schedule: ScheduleModel) {
    return this.apollo
      .mutate({
        mutation: deleteSchedule,
        variables: {
          id: Number(schedule.id)
        }
      })
      .pipe(
        tap(() => {
          this.scheduleRemovedSub.next(schedule);
        })
      );
  }
}
