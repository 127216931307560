import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AgendaService } from '../../services/agenda.service';
import { Moment } from 'moment-mini';
import { AgendaDateStatus } from '@app/agenda/_shared/interfaces/agenda-baseline-statuses';
import { AgendaShiftRequest } from '../../models/agenda/agenda-shift-request';
import { Observable } from 'rxjs';
import { ScheduleState } from '@model/schedules/schedule-state.model';

@Component({
  selector: 'vc-agenda-info',
  templateUrl: './agenda-info.component.html',
  styleUrls: ['./agenda-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AgendaInfoComponent {
  @Input() set day(value: Moment) {
    this.agendaList$ = this.agendaService.getAgendaForADay(value);
  }

  @Input() scheduleState?: ScheduleState;

  public ScheduleStateEnum = ScheduleState;

  public agendaList$: Observable<AgendaShiftRequest[]>;
  public AgendaDateStatus = AgendaDateStatus;

  constructor(private readonly agendaService: AgendaService) {}
}
