export function mobileAndTabletcheck() {
  return !!(
    /Android/i.exec(navigator.userAgent) ||
    /webOS/i.exec(navigator.userAgent) ||
    /iPhone/i.exec(navigator.userAgent) ||
    /iPad/i.exec(navigator.userAgent) ||
    /iPod/i.exec(navigator.userAgent) ||
    /BlackBerry/i.exec(navigator.userAgent) ||
    /Windows Phone/i.exec(navigator.userAgent)
  );
}
