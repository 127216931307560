import { BaseModel } from '../base.model';
import { CombinationModel } from './sector-combination.model';
import { ConfigurationModel } from './sector-configuration.model';

export class SectorModel extends BaseModel {

  public name: string;
  public abbreviation: string;
  public description: string;

  public combinations: CombinationModel[];
  public configurations: ConfigurationModel[];

  public position: number;
  // TODO: should be const type: 'sector': = 'sector';`
  public type: 'sector' = 'sector';

  constructor(data: any = {}) {
    super(data);

    this.name = data.name || '';
    this.abbreviation = data.abbreviation || '';
    this.description = data.description || '';

    this.combinations = (data.combinations && data.combinations.map(el => new CombinationModel(el))) || [];
    this.configurations = (data.configurations && data.configurations.map(el => new ConfigurationModel(el))) || [];

    this.position = data.position || null;
  }

  get code() {
    return this.abbreviation;
  }

}
