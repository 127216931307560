import { BaseModel } from '../base.model';
import { UnitModel } from './../companies/index';

export class AssignedGroupModel extends BaseModel {

  public name: string;
  public unit: UnitModel;

  constructor(data: any = {}) {
    super(data);

    this.name = data.name || '';
    this.unit = (data.unit && new UnitModel(data.unit)) || undefined;
  }
}
