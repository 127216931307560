import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { ErrorService } from '@services';
import { Observable } from 'rxjs';
import { employeeWithPreferences } from '../queries/employee-data.queries';
import { EmployeeDataWithPreferencesModel, EmployeeDataWithPreferencesResult } from '../../dto/employee-data-with-preferences.model';

@Injectable({
  providedIn: 'root'
})
export class EmployeeDataService {
  constructor(private readonly apollo: Apollo, private readonly errorService: ErrorService) {}

  public getEmployeeDataWithPreferences(id: number, dateFrom: string, dateTo: string): Observable<EmployeeDataWithPreferencesModel> {
    return this.apollo
      .query<EmployeeDataWithPreferencesResult>({
        query: employeeWithPreferences,
        variables: {
          id,
          dateFrom,
          dateTo
        }
      })
      .pipe(
        map(response => {
          return new EmployeeDataWithPreferencesModel(response.data);
        }),
        catchError(err => {
          return this.errorService.handle(err);
        })
      );
  }
}
