import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { ScheduleStatistic, StatisticName } from '@app/rostr/overview/dto/schedule-statistic';
import { ChartColor, colors } from '@shared/helpers/charts.helper';
import { ChartsService } from '@shared/services/charts/charts.service';
import Chart from 'chart.js/auto';

@Component({
  selector: 'app-shift-charts',
  templateUrl: './shift-charts.component.html',
  styleUrls: ['./shift-charts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShiftChartsComponent implements OnInit, OnChanges, OnDestroy {
  durationChart: Chart;
  timeOffBeforeChart: Chart;
  timeOffAfterChart: Chart;

  @Input() statistics: ScheduleStatistic;

  constructor(private readonly chartsService: ChartsService) {}

  public ngOnInit(): void {
    this.durationChart = this.chartsService.createBarChart('durationChartCanvas', 'Duration', 'h');
    this.timeOffBeforeChart = this.chartsService.createBarChart('timeOffBeforeChartCanvas', 'Time off before', 'h');
    this.timeOffAfterChart = this.chartsService.createBarChart('timeOffAfterChartCanvas', 'Time off after', 'h');

    this.plotCharts(this.statistics);
  }

  public ngOnChanges(): void {
    if (this.durationChart && this.timeOffBeforeChart && this.timeOffAfterChart) {
      this.plotCharts(this.statistics);
    }
  }

  public ngOnDestroy() {
    this.durationChart?.destroy();
    this.timeOffBeforeChart?.destroy();
    this.timeOffAfterChart?.destroy();
  }

  private plotCharts(statistics: ScheduleStatistic) {
    this.plotDurationChart(statistics);
    this.plotTimeOffBeforeChart(statistics);
    this.plotTimeOffAfterChart(statistics);
  }

  private plotDurationChart(statistics: ScheduleStatistic) {
    const actualValue = statistics?.values[StatisticName.Duration] || 0;
    const min = statistics?.values[StatisticName.DurationMin] || 0;
    const max = statistics?.values[StatisticName.DurationMax] || 0;

    const chartColor: ChartColor = actualValue > max || actualValue < min ? 'warn' : 'default';
    const color = colors.get(chartColor);

    this.chartsService.plotBarChart(this.durationChart, actualValue, min, max, color);
  }

  private plotTimeOffBeforeChart(statistics: ScheduleStatistic) {
    const actualValue = statistics?.values[StatisticName.TimeOffBefore] || 0;
    const max = statistics?.values[StatisticName.RequiredTimeOffBefore] || 0;

    const chartColor: ChartColor = actualValue < max ? 'warn' : 'default';
    const color = colors.get(chartColor);

    this.chartsService.plotBarChart(this.timeOffBeforeChart, actualValue, 0, max, color);
  }

  private plotTimeOffAfterChart(statistics: ScheduleStatistic) {
    const actualValue = statistics?.values[StatisticName.TimeOffAfter] || 0;
    const max = statistics?.values[StatisticName.RequiredTimeOffAfter] || 0;

    const chartColor: ChartColor = actualValue < max ? 'warn' : 'default';
    const color = colors.get(chartColor);

    this.chartsService.plotBarChart(this.timeOffAfterChart, actualValue, 0, max, color);
  }
}
