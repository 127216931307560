import { gql } from 'apollo-angular';

export const getSchedule = gql`
  query getSchedule($id: Int!) {
    schedule(id: $id) {
      id
      name
      configurations
      isCurrent
      dateFrom
      dateTo
      assignedGroups {
        id
        name
        unit {
          id
          name
        }
      }
      draftDeadline
      publishDeadline
      deadline
      lockedVacayDays {
        day
      }
      state
    }
  }
`;

export const updateSchedule = gql`
  mutation updateSchedule($id: Int!, $description: String, $name: String, $group: Int!, $dateTo: String, $dateFrom: String) {
    updateSchedule(id: $id, description: $description, name: $name, group: $group, dateTo: $dateTo, dateFrom: $dateFrom) {
      id
      name
    }
  }
`;

export const updateScheduleFromExcel = gql`
  mutation updateScheduleFromExcel(
    $id: Int!
    $name: String!
    $dateFrom: String!
    $dateTo: String!
    $lockedVacayDays: [String]!
    $assignments: [ScheduleAssignment]
    $draftDeadline: String
    $publishDeadline: String
    $deadline: String!
  ) {
    updateScheduleFromExcel(
      id: $id
      name: $name
      dateFrom: $dateFrom
      dateTo: $dateTo
      lockedVacayDays: $lockedVacayDays
      assignments: $assignments
      draftDeadline: $draftDeadline
      deadline: $deadline
      publishDeadline: $publishDeadline
    ) {
      id
    }
  }
`;

export const updateEmployeeOrder = gql`
  mutation updateEmployeeOrder($id: Int, $employeeOrdering: [Int]) {
    updateEmployeeOrder(id: $id, employeeOrdering: $employeeOrdering) {
      id
    }
  }
`;
