export enum ScheduleState {
  OPEN = 'OPEN',
  PLANNING = 'PLANNING',
  DRAFT = 'DRAFT',
  DISTRIBUTED = 'DISTRIBUTED'
}

export const SCHEDULE_STATE_TO_LABEL_MAP = {
  [ScheduleState.OPEN]: 'Open',
  [ScheduleState.PLANNING]: 'Planning',
  [ScheduleState.DRAFT]: 'Draft',
  [ScheduleState.DISTRIBUTED]: 'Distributed'
};
