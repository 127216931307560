import { Button } from './button';
import { IButton } from './abstract.base.button';

export interface IButtonToggle extends IButton {
  setLoader(title: string, icon: string): void;
}

export class ToggleButton extends Button implements IButtonToggle {
  public toggled = false;

  constructor(
    protected _titles: { firstState: string; lastState: string },
    protected _width: number,
    protected _height: number,
    protected _radius: number,
    protected _x: number,
    protected _y: number,
    protected _icons = { firstState: void 0, lastState: void 0 },
    protected _padding = 0,
    protected _params: any = {}
  ) {
    super(_titles.firstState, _width, _height, _radius, _x, _y, _icons.firstState, _padding, _params);

    if (_params && _params.hasOwnProperty('toggled')) {
      this.toggled = _params.toggled;
    }
  }

  public events() {
    this.$el.on('click', () => {
      this.toggled = !this.toggled;
      this.onClick.next(this.toggled);
    });
  }

  public setLoader(title, icon) {
    this.title = title;
    this.icon = icon;
    this.render();
  }

  public toggle() {
    if (this.toggled) {
      this.title = this._titles.lastState;
      this.icon = this._icons.lastState;
    } else {
      this.title = this._titles.firstState;
      this.icon = this._icons.firstState;
      this.$el.attr('class', 'btn');
    }

    const buttonColor = this.toggled ? 'toggled' : '';
    const buttonDisabled = !this.isVisible ? 'disabled' : '';

    this.render();
    this.$el.attr('class', `btn ${buttonColor} ${buttonDisabled}`);
  }

  public reset() {
    this.toggled = false;
    this.toggle();
  }

  protected renderTitleWithIcon() {
    super.renderTitleWithIcon();

    this.$title.attr('text-anchor', 'start').attr('x', 30);
  }
}
