import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { AUTH_API, norwegianTextComparer } from '@helpers';
import { map, catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Role } from '../models';
import { EmployeeModel } from '@models';
import { UserRole } from '../interfaces';
import { LoggingService } from '@shared/services/logging/logging.service';
import { ConfigService } from '@core';

@Injectable({ providedIn: 'root' })
export class RoleService {
  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private logging: LoggingService
  ) {}

  public loadRoles(): Observable<Role[]> {
    const url = `${this.configService.config.rootApiDomain}${AUTH_API.ROLES}`;

    return this.http.get(url, { responseType: 'json' }).pipe(
      map<any, Role[]>(response => {
        return response.map(role => new Role(role)).sort((a, b) => norwegianTextComparer(a.name, b.name, true));
      })
      // Having catchError() here, when VACAY user logged in, causes redirect and 2nd error
      // This is one of 'kinda missing functionality' based on e2e tests research.
    );
  }

  public getRolesForEmployees(employeeIds: number[] = []): Observable<UserRole[]> {
    let params = new HttpParams();
    params = params.append('employeeIds', employeeIds.join(','));
    const url = `${this.configService.config.rootApiDomain}${AUTH_API.ROLES_FOR_USERS}`;

    return this.http.get(url, { responseType: 'json', params }).pipe(
      map<any, any>(response => {
        return response.map(r => ({ employeeId: r.employeeId, roleIds: r.roleIds }));
      }),
      catchError(getRolesForEmployeesError => {
        this.logging.error(getRolesForEmployeesError);
        return of([]);
      })
    );
  }

  public saveRolesForEmployee(data: EmployeeModel[]) {
    const updatedEmployees = this.getEmployeeInputData(data);
    const httpOptions = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(`${this.configService.config.rootApiDomain}${AUTH_API.ROLES}`, updatedEmployees, { headers: httpOptions });
  }

  private getEmployeeInputData(employees: EmployeeModel[]): UserRole[] {
    return employees.map(employee => {
      return {
        employeeId: employee.id,
        roleIds: employee.roles.map(r => r.id)
      };
    });
  }
}
