import { Pipe, PipeTransform } from '@angular/core';
import { escapeRegExp } from 'lodash-es';

@Pipe({name: 'shHighlight'})

export class Highlight implements PipeTransform {
  transform(value, secondValue) {
    return value?.replace(new RegExp(`(${escapeRegExp(secondValue)})`, 'gi'), '<span class="highlight">$1</span>');
  }
}
