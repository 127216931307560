import * as moment from 'moment-mini';

import { BaseInterval } from './base.interval';
import { Timeline } from '../timeline';
import { formatWeekday, formatMonth } from '@src/app/_shared/helpers';
import { DayOfWeekFormat, MonthFormat } from '@src/app/_shared/enums/days.enum';
import { IBaseGrid } from '../grid/base-grid.interface';
import { IBaseMenu } from '../menu/base-menu.interface';


export class HourInterval extends BaseInterval {

  constructor(protected _timeline: Timeline,
    protected _grid: IBaseGrid,
    protected _menu: IBaseMenu,
    protected _dateFrom: Date,
    protected _x: number) {
    super(_timeline, _grid, _menu, _dateFrom, _x);
  }

  static get type() {
    return HourInterval.TYPES.Hour;
  }

  get title() {
    return moment(this._dateFrom).format('HH:mm');
  }

  getTitleForTab(dateFrom: Date, isActive?: boolean) {
    return HourInterval.hoursBasedIntervalTitleForTab(dateFrom, isActive);
  }

  static hoursBasedIntervalTitleForTab(dateFrom: Date, isActive?: boolean) {
    if (isActive) {
      return `
        ${formatWeekday(dateFrom, DayOfWeekFormat.long)},
        ${formatMonth(dateFrom, MonthFormat.long)}
        ${dateFrom.getDate()} - ${dateFrom.getFullYear()}
      `;
    }
    return formatWeekday(dateFrom, DayOfWeekFormat.long);
  }

  public renderHeader() {
    super.renderHeader();

    this.$headerTitle.classed('hour', true);
  }


  /********************************* Dates ************************************************/
  /**
   * Setter for dates
   * @param dateFrom
   * @returns {DayInterval}
   */
  public setDates(dateFrom = this._dateFrom) {
    this._dateFrom = HourInterval.precision(dateFrom);

    this._dateTo = new Date(this._dateFrom.getTime());
    this._dateTo.setHours(this._dateTo.getHours() + 1);

    this._dateFromUnix = this._dateFrom.getTime();
    this._dateToUnix = this._dateTo.getTime();

    return this;
  }

  /**
   * Date for next interval
   * @returns {Date}
   */
  public next() {
    const date = new Date(this._dateFrom.getTime());
    date.setHours(date.getHours() + 1);
    return date;
  }

  /**
   * Date for prev interval
   * @returns {Date}
   */
  public prev() {
    const date = new Date(this._dateFrom.getTime());
    date.setHours(date.getHours() - 1);
    return date;
  }

  /**
   * Will return count of intervals what will be overlaped by shift
   * @param from {Date}
   * @param to {Date}
   * @returns {number}
   */
  public getCountOfUsedIntervals(from, to) {
    return Math.ceil(Math.round((to.getTime() - from.getTime()) / 60000) / 60);
  }

  /**
   * Round up to start of day
   * @returns {Date}
   */
  public base(offsetHours = 0, offsetDays = 0) {
    let date = this._dateFrom;
    date = new Date(date.getFullYear(), date.getMonth(), date.getDate() + offsetDays);
    date.setHours(date.getHours() + offsetHours);
    return date;
  }

  /*************************** static **************************************************/
  /**
   * Round to hour
   * @param date
   * @returns {Date}
   */
  static precision(date: Date): Date {

    let hours = date.getHours();
    const minutes = date.getMinutes();

    if (minutes > 30) {
      hours += 1;
    }

    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), hours);
  }

  static diff(from, to) {
    return moment(from).diff(moment(to), 'hours');
  }

}
