import { Injectable } from '@angular/core';
import { EMPTY } from 'rxjs';
import { ENVIRONMENTS } from '@core';
import { environment } from '@src/environments/environment';
import { AppInsightsService, DialogService } from '@services';
import { ApolloError } from '@apollo/client/core';

const verboseEnvironments = [ENVIRONMENTS.LOCAL, ENVIRONMENTS.DEV, ENVIRONMENTS.STAGE];

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  constructor(
    private appInsightsService: AppInsightsService,
    private dialogService: DialogService
  ) {}

  public handle(error: Error | string) {
    this.appInsightsService.logError(this.getErrorMessage(error));

    if (verboseEnvironments.includes(environment.env)) {
      this.handleErrorVerbosely(error);
    } else {
      this.displayErrorMessage(error);
    }

    return EMPTY;
  }

  public displayErrorMessage(error: Error | string) {
    this.appInsightsService.logError(this.getErrorMessage(error));
    let errorMessage: string;
    let statusCode = -1;
    if (error instanceof ApolloError) {
      if (error.networkError) {
        statusCode = (error.networkError as any).status;
      } else if (error.graphQLErrors?.length) {
        const originalError = error.graphQLErrors[0].extensions?.originalError as any;
        statusCode = originalError.status;
      }
    }

    switch (statusCode) {
      case 404:
        errorMessage = 'Could not find what you were looking for.';
        break;
      case 500:
        errorMessage = 'Ops! Something went wrong on our end.';
        break;
      case 504:
        errorMessage = 'Ops! Something caused the request to timeout.';
        break;
      default:
        errorMessage = error instanceof ApolloError ? `${error.message}` : (error as string);
        break;
    }

    this.dialogService.snackBar(errorMessage);
    return EMPTY;
  }

  private handleErrorVerbosely(error: Error | string) {
    this.dialogService.snackBar(this.getErrorMessage(error), { classes: ['break-spaces'] });
  }

  private getErrorMessage(error: Error | string) {
    return error instanceof Error ? `${error.message} \n${error.stack}` : error;
  }
}
