import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { RostrSettings } from '../dto/rostrSettings.model';

@Injectable({
  providedIn: 'root'
})
export class SettingsState {
  private localStorageKey = 'rostrSettings';

  private settings: RostrSettings = {
    displayAgendaColors: true,
    displayShiftsStatuses: true,
    displayShiftColors: true,
    displayShiftsWarnings: true,
    showStatusBar: true,
    excludedValidations: []
  };

  private readonly settingsSource = new BehaviorSubject<RostrSettings>(this.settings);

  public settingsChanged$ = this.settingsSource.asObservable();

  public get isDisplayingOfAgendaColorsEnabled(): boolean {
    return this.settings.displayAgendaColors ?? true;
  }

  public get isDisplayingShiftsStatuses(): boolean {
    return this.settings.displayShiftsStatuses ?? true;
  }

  public get isDisplayingShiftsWarnings(): boolean {
    return this.settings.displayShiftsWarnings ?? true;
  }

  public get isShowingStatusBar(): boolean {
    return this.settings.showStatusBar ?? true;
  }

  public get isDisplayingShiftColors(): boolean {
    return this.settings.displayShiftColors ?? true;
  }

  public get excludedValidations(): string[] {
    return this.settings.excludedValidations ?? [];
  }

  public initializeFromLocalStorage() {
    const settingsFromLocalStorage = localStorage.getItem(this.localStorageKey);
    if (settingsFromLocalStorage) {
      this.settings = JSON.parse(settingsFromLocalStorage);
      this.settingsSource.next(this.settings);
    }
  }

  public enableDisplayingOfAgendaColors(): void {
    this.settings.displayAgendaColors = true;
    this.publishAndSaveSettings();
  }

  public disableDisplayingOfAgendaColors(): void {
    this.settings.displayAgendaColors = false;
    this.publishAndSaveSettings();
  }

  public enableShiftColors(): void {
    this.settings.displayShiftColors = true;
    this.publishAndSaveSettings();
  }

  public disableShiftColors(): void {
    this.settings.displayShiftColors = false;
    this.publishAndSaveSettings();
  }

  public enableShiftsStatuses(): void {
    this.settings.displayShiftsStatuses = true;
    this.publishAndSaveSettings();
  }

  public disableShiftsStatuses(): void {
    this.settings.displayShiftsStatuses = false;
    this.publishAndSaveSettings();
  }

  public enableShiftsWarnings(): void {
    this.settings.displayShiftsWarnings = true;
    this.publishAndSaveSettings();
  }

  public disableShiftsWarnings(): void {
    this.settings.displayShiftsWarnings = false;
    this.publishAndSaveSettings();
  }

  public enableStatusBar(): void {
    this.settings.showStatusBar = true;
    this.publishAndSaveSettings();
  }

  public disableStatusBar(): void {
    this.settings.showStatusBar = false;
    this.publishAndSaveSettings();
  }

  public setExcludedValidations(validations: string[]): void {
    this.settings.excludedValidations = validations;
    this.publishAndSaveSettings();
  }

  private publishAndSaveSettings(): void {
    this.settingsSource.next(this.settings);
    localStorage.setItem(this.localStorageKey, JSON.stringify(this.settings));
  }
}
