import { ConversationPanelComment } from '@component/conversation-panel/models/comment.model';
import { AgendaRequest, AssignedActivity, MasterplanActivity, VacayRequest } from '.';
import { VacayAvailableRequest } from './vacay-available-request.model';
import { Group } from './group';
import { CommentType } from '@component/conversation-panel/models/comment-type';
import { RostrDayMode } from '../enums/rostr-day-mode';

function vacayRequestsMap(vacayRequests: AssignedEmployeeResult['vacay']): Map<string, VacayRequest[]> {
  const map = new Map<string, VacayRequest[]>();
  const batchIdToIndex = {} as { [batchId: number]: number };

  for (const date of Object.keys(vacayRequests).sort()) {
    const requests = vacayRequests[date];
    const requestsWithoutBatchId = requests.filter(request => !request.batchId);
    const requestsWithBatchId = requests.filter(request => request.batchId);

    Object.keys(batchIdToIndex).forEach(key => {
      if (!requestsWithBatchId.find(request => request.batchId === +key)) {
        delete batchIdToIndex[key];
      }
    });

    const sortedRequests = Array(Object.keys(batchIdToIndex).length).fill(null);

    for (const request of requestsWithBatchId) {
      const batchId = request.batchId;

      if (batchIdToIndex[batchId] === undefined) {
        batchIdToIndex[batchId] = findFirstFreeIndex(batchIdToIndex);
      }

      sortedRequests[batchIdToIndex[batchId]] = request;
    }

    map.set(date, sortedRequests.concat(requestsWithoutBatchId));
  }

  return map;
}

function findFirstFreeIndex(batchIdToIndex: { [batchId: number]: number }) {
  let firstFreeIndex = 0;
  const values = Object.values(batchIdToIndex).sort((a, b) => a - b);

  for (const value of values) {
    if (firstFreeIndex === value) {
      firstFreeIndex++;
    } else {
      return firstFreeIndex;
    }
  }

  return firstFreeIndex;
}

export class AssignedEmployee {
  id: number;
  name: string;
  order: number;
  assigned: Map<string, AssignedActivity>;
  masterplan: Map<string, MasterplanActivity>;
  vacay: Map<string, VacayRequest[]>;
  vacayAvailable: Map<string, VacayAvailableRequest>;
  agenda: Map<string, AgendaRequest[]>;
  hasVacay: boolean;
  hasVacayAvailable: boolean;
  hasVacayCompleted: boolean;
  hasAgenda: boolean;
  agendaCount: number;
  vacayCount: number;
  groups: Group[];
  skills: string[];
  comments: Map<string, ConversationPanelComment[]>;
  hasWorkPercentageIndicator: boolean;
  days: { [timestamp: string]: RostrDayMode };

  constructor(data: AssignedEmployeeResult) {
    this.id = data.id;
    this.name = data.name;
    this.order = data.order;
    this.assigned = data.assigned ? new Map(Object.entries(data.assigned)) : new Map();
    this.masterplan = data.masterplan ? new Map(Object.entries(data.masterplan)) : new Map();
    this.vacay = data.vacay ? vacayRequestsMap(data.vacay) : new Map();
    this.vacayAvailable = data.vacayAvailable ? new Map(Object.entries(data.vacayAvailable)) : new Map();
    this.agenda = data.agenda ? new Map(Object.entries(data.agenda)) : new Map();
    this.hasAgenda = data.hasAgenda;
    this.hasVacay = data.hasVacay;
    this.hasVacayAvailable = data.hasVacayAvailable;
    this.hasVacayCompleted = data.hasVacayCompleted;
    this.agendaCount = data.agendaCount;
    this.vacayCount = data.vacayCount;
    this.groups = data.groups;
    this.skills = data.skills;
    this.comments = data.comments ? new Map(Object.entries(data.comments)) : new Map();
    this.hasWorkPercentageIndicator = data.hasWorkPercentageIndicator;
    this.days = data.days;
  }

  public notesPredicate = (comment: ConversationPanelComment) => comment.type === CommentType.ROSTR || comment.type === CommentType.UNION_REPRESENTATIVE;
}

export type AssignedEmployeeResult = {
  id: number;
  name: string;
  order: number;
  assigned: { [key: string]: AssignedActivity };
  masterplan: { [key: string]: MasterplanActivity };
  vacay: { [key: string]: VacayRequest[] };
  vacayAvailable: { [key: string]: VacayAvailableRequest };
  agenda: { [key: string]: AgendaRequest[] };
  hasVacay: boolean;
  hasVacayAvailable: boolean;
  hasVacayCompleted: boolean;
  hasAgenda: boolean;
  agendaCount: number;
  vacayCount: number;
  groups: Group[];
  skills: string[];
  comments: { [key: string]: ConversationPanelComment[] };
  hasWorkPercentageIndicator: boolean;
  days: { [timestamp: string]: RostrDayMode };
};
