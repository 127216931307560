import { BaseModel } from '../base.model';


export class HolidayModel extends BaseModel {

  public name: string;
  public type: string;
  public active: boolean;
  public everyYear: boolean;
  public weekday: string;
  public date: Date;

  constructor(data: any = {}) {
    super(data);

    this.name = data.name || '';
    this.type = data.type || '';
    this.active = data.active || false;
    this.everyYear = data.everyYear || false;
    this.weekday = data.weekday || '';
    this.date = (data.date && new Date(data.date)) || undefined;
  }

  /**
   * Comparator for dates to attach holiday to calendar date or some timeline interval
   * @param date
   * @returns {boolean}
   */
  public compare(date: Date) {

    if (!this.active) {
      return false;
    }

    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();

    const d = this.date;

    if (this.everyYear) {
      return day === d.getDate() && month === d.getMonth();
    }
    return day === d.getDate() && month === d.getMonth() && d.getFullYear() === year;
  }
}
