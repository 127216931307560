import { Apollo } from 'apollo-angular';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ErrorService } from '@services';
import {
  loadSetting,
  loadSettings,
  createSettingsValue,
  updateSettingsValue,
  deleteSettingsValue,
  loadAveragingPeriodsForGroups
} from '@src/app/platform/_shared/queries/settings.queries';
import { Setting, SettingsKey, SettingsEntityTables, SettingValue, EntitySetting } from '@model/settings';
import { AveragingPeriodsForGroups, EmployeeAveragingPeriodOffsetResult } from '../interfaces';
@Injectable()
export class SettingsService {
  constructor(
    private readonly apollo: Apollo,
    private readonly errorService: ErrorService
  ) {}
  public loadSetting(settingKey: SettingsKey, entityId: number): Observable<EntitySetting> {
    return this.apollo
      .query<any>({
        query: loadSetting,
        variables: {
          settingKey,
          entityId
        }
      })
      .pipe(
        map(response => {
          return response.data.entitySetting as EntitySetting;
        }),
        catchError(err => {
          return this.errorService.handle(err);
        })
      );
  }

  public loadSettings(settingsEntityTable: SettingsEntityTables, settingKeys: SettingsKey[] | undefined = undefined): Observable<Setting[]> {
    return this.apollo
      .query<any>({
        query: loadSettings,
        variables: {
          table: settingsEntityTable,
          settingKeys
        }
      })
      .pipe(
        map(response => {
          const settings = response.data.settings;
          return settings?.map(setting => new Setting(setting));
        }),
        catchError(err => {
          return this.errorService.handle(err);
        })
      );
  }

  public createSetting(settingKey: SettingsKey, entityId: number, settings: SettingValue[]): Observable<SettingValue[]> {
    if (!settings?.length) {
      return of([]);
    }

    const createRequests = settings.map(setting => {
      return this.apollo.mutate<any>({
        mutation: createSettingsValue,
        variables: {
          settingKey,
          entityId,
          value: setting.value,
          dateFrom: setting.dateFrom,
          dateTo: setting.dateTo
        }
      });
    });

    const allRequests$ = forkJoin(createRequests);
    return allRequests$.pipe(
      map(createdSettings => {
        return createdSettings.map(setting => {
          return new SettingValue(setting.data.createSettingsValue);
        });
      }),
      catchError(err => {
        return this.errorService.handle(err);
      })
    );
  }

  public updateSetting(settingsKey: SettingsKey, settingsToUpdate: SettingValue[]): Observable<SettingValue[]> {
    if (!settingsToUpdate?.length) {
      return of([]);
    }

    const updateRequests = settingsToUpdate.map(setting => {
      return this.apollo.mutate<any>({
        mutation: updateSettingsValue,
        variables: {
          id: setting.id,
          value: setting.value,
          dateFrom: setting.dateFrom,
          dateTo: setting.dateTo
        }
      });
    });

    const allRequests = forkJoin(updateRequests);
    return allRequests.pipe(
      map(updatedSettings => {
        return updatedSettings.map(setting => new SettingValue(setting.data.updateSettingsValue));
      }),
      catchError(err => {
        return this.errorService.handle(err);
      })
    );
  }

  public deleteSetting(settingsToDelete: number[]): Observable<SettingValue[]> {
    if (!settingsToDelete?.length) {
      return of([]);
    }

    const deleteRequests = settingsToDelete.map(id => {
      return this.apollo.mutate<any>({
        mutation: deleteSettingsValue,
        variables: {
          id
        }
      });
    });

    const allRequests = forkJoin(deleteRequests);
    return allRequests.pipe(
      map(deletedSettings => {
        return deletedSettings.map(setting => new SettingValue(setting.data.deleteSettingsValue));
      }),
      catchError(err => {
        return this.errorService.handle(err);
      })
    );
  }

  public loadAveragingPeriodsForGroupsAndEmployee(
    groupIds: number[],
    employeeId: number
  ): Observable<{
    averagingPeriods: AveragingPeriodsForGroups;
    offsets: EmployeeAveragingPeriodOffsetResult[];
  }> {
    return this.apollo
      .query<{
        averagingPeriodsForGroups: {
          id: number;
          entityId: number;
          dateFrom: string;
          dateTo: string;
        }[];
        employeeAveragingPeriods: {
          offset: number;
          averagingPeriod: {
            id: number;
          };
        }[];
      }>({
        query: loadAveragingPeriodsForGroups,
        variables: {
          groupIds,
          employee: employeeId
        }
      })
      .pipe(
        map(response => {
          return {
            averagingPeriods: response.data.averagingPeriodsForGroups
              .map(value => new SettingValue(value))
              .reduce((results, element) => {
                results[element.entityId] = results[element.entityId] || [];
                results[element.entityId].push(element);
                return results;
              }, {}),
            offsets: response.data.employeeAveragingPeriods.map(item => ({ offset: item.offset, averagingPeriod: item.averagingPeriod }))
          };
        }),
        catchError(err => {
          return this.errorService.handle(err);
        })
      );
  }
}
