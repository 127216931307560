import { SiteModelData } from '@shared/interfaces';
import { BaseModel } from '../base.model';
import { DivisionModel } from './division.model';

export class SiteModel extends BaseModel {

  public name: string;
  public divisionId: number;
  public division: DivisionModel;
  public position: number;
  public type: 'sites';
  public typeSingular: 'site';

  constructor(data: SiteModelData = {}) {
    super(data);

    this.name = data.name || '';
    this.divisionId = data.divisionId || null;
    this.division = (data.division && new DivisionModel(data.division)) || undefined;

    this.position = data.position || null;
  }

}
