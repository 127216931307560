import * as d3 from 'd3';
import { EventEmitter } from '@angular/core';

import { translate } from '@helpers';

import { Defs } from '../defs/defs';
import { IBaseGrid } from '../grid/base-grid.interface';
import { Menu } from '../menu/menu';
import { IBaseTimeline } from '../utils/interfaces';
import { BaseItem } from './base.item';
import { IItemConfig, IOpenableItem } from './interfaces';
import { OpenableItem } from './openable.item';
import { ResponsiveItem } from './responsive.item';
import { SimpleItem } from './simple.item';
import { SubItem } from './sub.item';

export class RootItem extends BaseItem implements IOpenableItem {

  get items() {
    return this._items;
  }

  set parent(parent: RootItem | OpenableItem) {
    this._parent = parent;

    this._subitems.forEach(sub => (sub.parent = this));
    this._items.forEach(item => (item.parent = this));
  }

  get parent() {
    return this._parent;
  }
  // events
  public onOpen: EventEmitter<any> = new EventEmitter();
  public onClose: EventEmitter<any> = new EventEmitter();
  public onAddItem: EventEmitter<any> = new EventEmitter();
  public onChangeHeight: EventEmitter<any> = new EventEmitter();
  protected $toggle: d3.Selection<any, any, any, any>;
  protected $pointer: d3.Selection<any, any, any, any>;
  protected $panel: d3.Selection<any, any, any, any>;

  constructor(
    protected _title = '',
    protected _items: BaseItem[] = [],
    protected _subitems: SubItem[] = [],
    protected _opened = true,
    protected _itemConfig: IItemConfig = { height: 40 }
  ) {
    super(_title, _itemConfig);
    this.$el.classed('category', true);
    this.$panel = this.$el.append('g').classed('panel', true);
  }

  /**
   * Rendering functionality
   * @returns {Element}
   */
  public render() {
    const sizes = this._timeline.sizes();
    const circleDiameter = 14;
    const toggleMargin = 10;
    const rh = sizes.rowHeight;
    const mw = sizes.menuWidth - (toggleMargin + circleDiameter / 2);
    const lw = sizes.menuWidth;
    const width = sizes.width;
    const hh = rh / 2;
    const lh = rh * (this._subitems.length + 1);

    this.$el.attr('transform', translate(0, this.y()));

    this.$panel.attr('transform', translate(lw, +this.config.offset.top));

    this.$line
      .attr('x1', 0)
      .attr('x2', width)
      .attr('y1', lh - 1 + this.config.offset.top)
      .attr('y2', lh - 1 + this.config.offset.top)
      .attr('class', 'line root');

    this.$toggle = this.$el
      .append('g')
      .classed('openable-toggle', true)
      .attr('transform', translate(mw, hh));

    this.$toggle
      .append('circle')
      .attr('r', circleDiameter / 2)
      .classed('toggle-circle', true);

    this.$pointer = this.$toggle
      .append('polygon')
      .attr('points', '1.131371 0 0 0 4 4 8 0 6.868629 0 4 2.868629')
      .classed('toggle-mark', true);

    if (this._opened) {
      this.$el.classed('opened', true);
    }

    this.renderTitle();
    this.renderBackground();

    this.renderOffset();

    this.events();

    this.isRendered = true;

    return this.el;
  }

  /**
   * DOM event listeners
   */
  public events() {
    this.$toggle.on('mouseup', () => {
      this.toogle();
    });
  }

  /**
   * Subscribe to event emitters
   */
  public subscribe() {
    this._items.forEach((item: any) => {
      this.addEventsToItem(item);
    });
    this._subitems.forEach((item: SubItem) => {
      item.subscribe();
    });
  }

  // FIXME DRY
  public addEventsToItem(item) {
    if (item instanceof RootItem || item instanceof OpenableItem) {
      item.onOpen.subscribe(() => {
        this.onOpen.next(this);
      });
      item.onClose.subscribe(() => {
        this.onClose.next(this);
      });
    }
    if (item.onChangeHeight) {
      item.onChangeHeight.subscribe(v => {
        this.onChangeHeight.next(v);
      });
    }
    if (item.onAddItem) {
      item.onAddItem.subscribe(v => {
        this.onAddItem.next(v);
      });
    }
    item.subscribe();
  }

  public updateItemsPosition(items: BaseItem[]) {
    this._items = items;
    this._menu.updateTree();
    this.items.forEach(item => item.updatePosition());
  }

  public updatePosition() {
    super.updatePosition();

    this.items.forEach(item => item.updatePosition());
  }

  /**
   * Show child items
   */
  public open() {
    if (!this._items.length) {
      return;
    }
    this._opened = true;
    this.onOpen.emit();
    this.$el.classed('opened', true);
  }

  /**
   * Hide child items
   */
  public close() {
    if (!this._items.length) {
      return this;
    }
    this._opened = false;
    this.onClose.next(this);
    this.$el.classed('opened', false);
  }

  /**
   * Close or open depending in current state
   */
  public toogle() {
    return this._opened ? this.close() : this.open();
  }

  /**
   * Is item opened ?
   * @returns {boolean}
   */
  public isOpen() {
    return this._opened;
  }

  /************************************ Items and subitems ********************************************/

  public subitems(): SubItem[] {
    return this._subitems;
  }

  public addSubItem(item, index?: number) {
    item.parent = this;
    item.timeline(this._timeline);
    item.menu(this._menu);
    item.defs(this._defs);
    item.grid(this._grid);

    // add item to items array
    if (index) {
      item.index = index;
      this._items.splice(index, 0, item);
      this.shiftIndexesForItems(index, 1);
    } else {
      item.index = this.items.length;
      this._items.push(item);
    }

    item.level = this.level + 1;

    this.addEventsToItem(item);
    const visible = this._menu.visible;
    if (this._opened) {
      visible.push(item);
    } else {
      item.hide();
    }
    this._menu.node.appendChild(item.render());

    if (!(item instanceof ResponsiveItem) && item.renderShifts) {
      item.renderShifts();
    }

    this.onAddItem.next(item);
  }

  public removeSubItem(item: BaseItem) {
    item.remove();
    this.removeItem(item);

    this.onChangeHeight.next();
  }

  /**
   * Remove item
   * @param item
   * @returns {RootItem}
   */
  private removeItem(item: BaseItem) {
    const index = this._items.indexOf(item);
    if (index > -1) {
      this._items.splice(index, 1);
    }
    this.updateChildIndexes();
    return this;
  }

  private shiftIndexesForItems(index: number, direction: number) {
    this._items.filter(i => i.index > index).forEach(i => i.index = i.index + direction);
  }

  /*************************************************************************************************/

  /**
   * Setter for grid property
   * @param grid
   * @returns {RootItem}
   */
  public grid(grid: IBaseGrid): this {
    super.grid(grid);
    this._subitems.forEach(sub => {
      sub.grid(grid);
    });
    this._items.forEach((item: BaseItem) => {
      item.grid(grid);
    });
    return this;
  }

  /**
   * Setter for timeline property
   * @param timeline
   * @returns {RootItem}
   */
  public timeline(timeline: IBaseTimeline): this {
    super.timeline(timeline);
    this._subitems.forEach(sub => {
      sub.timeline(timeline);
    });
    this._items.forEach((item: BaseItem) => {
      item.timeline(timeline);
    });
    return this;
  }

  /**
   * Menu setter
   * @param menu
   * @returns {RootItem}
   */
  public menu(menu: Menu): this {
    super.menu(menu);
    this._subitems.forEach(sub => {
      sub.menu(menu);
    });
    this._items.forEach((item: SimpleItem) => {
      item.menu(menu);
    });
    return this;
  }

  /**
   * Defs setter
   * @param defs
   * @returns {RootItem}
   */
  public defs(defs: Defs): this {
    this._defs = defs;
    this._subitems.forEach(sub => {
      sub.defs(defs);
    });
    this._items.forEach((item: SimpleItem) => {
      item.defs(defs);
    });
    return this;
  }

  /****************************************************************************************************/
  /**
   * Remove functionality
   */
  public remove(): void {
    this._subitems.forEach(sub => {
      sub.remove();
    });
    this._items.forEach(item => {
      item.remove();
    });
    super.remove();
  }

  public renderTitle() {
    const tw = 35;
    const hh = this.height / 2;

    this.$title
      .text(this._title)
      .attr('class', 'title root-title')
      .attr('y', hh + this.config.offset.top)
      .attr('x', tw)
      .attr('dy', '0.5ex');
  }

  /**
   * Background rendering
   */
  public renderBackground() {
    const sizes = this._timeline.sizes();
    const rh = sizes.rowHeight;
    const width = sizes.width;

    this.$background
      .attr('y', this.config.offset.top)
      .attr('width', width)
      .attr('height', rh - 1)
      .attr('class', 'bg');
  }

  protected updateChildIndexes() {
    this.items.forEach((item: any, index) => {
      item.index = index;
    });

    this._onIndexUpdate.next();
  }
}
