import { BaseModel } from '../base.model';
import * as moment from 'moment-mini';
import { ISO_DATE_ONLY_FORMAT } from '@helpers';
import { QualificationData } from './qualification-data';

export interface LicenseModelData {
  id?: number;
  license?: {
    id?: number;
    name?: string;
  };
  name?: string;
  obtained?: string;
  validTo?: string;
  validFrom?: string;
}
export class LicenseModel extends BaseModel implements QualificationData {
  public name: string;
  public obtained: string;
  public validTo: string;
  public validFrom: string;
  public medical: number;

  constructor(data: LicenseModelData = {}) {
    super(data);
    this.id = data.license?.id || data.id || undefined;
    this.name = data.license?.name || data.name || undefined;
    this.obtained = (data.obtained && moment(data.obtained).format(ISO_DATE_ONLY_FORMAT)) || undefined;
    this.validTo = (data.validTo && moment(data.validTo).format(ISO_DATE_ONLY_FORMAT)) || undefined;
    this.validFrom = (data.validFrom && moment(data.validFrom).format(ISO_DATE_ONLY_FORMAT)) || undefined;
  }

  get title() {
    return this.name;
  }
}
