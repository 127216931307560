<div class="notification" *ngFor="let notification of notifications" [routerLink]="notification.link" [class.has-link]="notification.link">
  <div class="notification-wrapper">
    <div class="left-panel">
      <mat-icon [style.color]="convertSeverityIntoColor(notification.severity)">{{ convertSourceIntoIcon(notification.source) }}</mat-icon>
    </div>
    <div class="center-panel">
      <div class="header">
        {{ notification.title }}
      </div>
      <div class="content">
        {{ notification.content }}
      </div>
    </div>
    <div class="right-panel" *ngIf="notification.link">
      <mat-icon>launch</mat-icon>
    </div>
  </div>
  <div class="footer">
    {{ notification.createdAt | date : 'YYYY-MM-dd HH:mm:ss' }}
  </div>
</div>
