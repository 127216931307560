import { ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';
import { AuthenticationService } from '@auth';
import { CreateCommentPayload, UpdateCommentPayload, DeleteCommentPayload } from '@component/conversation-panel/models';
import { CommentsAPIService } from '@component/conversation-panel/services/comments-api.service';
import { VacayRequestModel } from '@models';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-vacay-request-comments',
  templateUrl: './vacay-request-comments.component.html',
  styleUrls: ['./vacay-request-comments.component.scss']
})
export class VacayRequestCommentsComponent implements OnDestroy {
  @Input()
  request: VacayRequestModel;

  @Input()
  scheduleId: number;

  private destroy$ = new Subject();
  private markAsReadSub: Subscription;

  constructor(
    private readonly commentsApiService: CommentsAPIService,
    private readonly auth: AuthenticationService,
    private readonly cd: ChangeDetectorRef
  ) {}

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public startMarkingAsRead() {
    this.markAsReadSub = this.commentsApiService
      .onCommentsCreated$()
      .pipe(takeUntil(this.destroy$))
      .subscribe(comments => {
        const commentsToMark = comments.filter(com => this.request.comments.findIndex(element => element.id === com.id) >= 0);
        this.markCommentsAsRead(commentsToMark.map(comment => comment.id));
      });
  }

  public stopMarkingAsRead() {
    this.markAsReadSub?.unsubscribe();
  }

  public getUnreadCommentsCount(): number {
    return this.request.comments.filter(comment => !comment.isRead && comment.authorId !== this.auth.user.id).length;
  }

  public createComment(event: CreateCommentPayload): void {
    this.commentsApiService.createComment$(event).pipe(takeUntil(this.destroy$)).subscribe();
  }

  public updateComment(event: UpdateCommentPayload): void {
    this.commentsApiService.updateComment$(event).pipe(takeUntil(this.destroy$)).subscribe();
  }

  public deleteComment(event: DeleteCommentPayload): void {
    this.commentsApiService.deleteComment$(event).pipe(takeUntil(this.destroy$)).subscribe();
  }

  public markCommentsAsRead(comments: number[]): void {
    const commentsToMark = this.request.comments.filter(comment => comments.includes(comment.id));

    commentsToMark.forEach(comment => (comment.isRead = true));
    this.cd.markForCheck();
    this.commentsApiService
      .markCommentsAsRead$(commentsToMark.map(comment => comment.id))
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }
}
