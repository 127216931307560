import { datesExtremes, ISO_DATE_ONLY_FORMAT } from '@helpers';
export interface DatepickerSettingsModelData {
  dates?: Date[];
  initialFormat?: string;
  showDates?: boolean;
  showHolidays?: boolean;
  year?: number;
  clickableHandler?;
}
export class DatepickerSettingsModel {
  public dates: Date[];
  public initialFormat: string;
  public showDates: boolean;
  public showHolidays: boolean;
  public year: number;
  public clickableHandler;

  constructor(data: DatepickerSettingsModelData = {}) {
    this.dates = (data.dates && data.dates.map(date => new Date(date))) || [];
    this.initialFormat = data.initialFormat || ISO_DATE_ONLY_FORMAT;
    this.showDates = data.showDates === undefined ? true : data.showDates;
    this.showHolidays = data.showHolidays === undefined ? false : data.showHolidays;
    this.year = data.year || new Date().getFullYear();
    this.clickableHandler = data.clickableHandler || void 0;
  }

  public updateYear() {
    if (this.dates.length) {
      const extremes = datesExtremes(this.dates);
      this.year = extremes.max.getFullYear();
    } else {
      this.year = new Date().getFullYear();
    }
  }
}
