import { Observable, Subscriber } from 'rxjs';
import { map, take, tap, catchError, mergeMap } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';
import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { ConfigService } from '@core';
import { AUTH_API } from '@helpers';
import { EmployeeModel, PermissionModel } from '@models';
import { IEmployee } from '@model/auth/employee.interface';

import { loadCurrentEmployee } from '../load-current-employee.query';
import { AppInsightsService, ErrorService } from '@shared/services';
import { utc } from 'moment-mini';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private userModel = new EmployeeModel();
  private userPermissionModel = new PermissionModel();
  private userDataResolver = new EventEmitter();

  constructor(
    private http: HttpClient,
    private apollo: Apollo,
    private configService: ConfigService,
    private appInsightsService: AppInsightsService,
    private errorService: ErrorService
  ) {}

  get user(): EmployeeModel {
    return this.userModel;
  }

  get userRoles(): PermissionModel {
    return this.userPermissionModel;
  }

  get isAuthenticated() {
    return !!this.userModel.id;
  }

  getLazyUser() {
    return new Observable<EmployeeModel>(observer => {
      if (this.userModel.id) {
        observer.next(this.userModel);
        observer.complete();
      } else {
        this.userDataResolver.pipe(take(1)).subscribe(() => {
          observer.next(this.userModel);
          observer.complete();
        });
      }
    });
  }

  fetchUserData(lastSuccessfulLogin: string): Observable<EmployeeModel> {
    // Not clear why userPermissions variable introduced previously, but it might be used in future.
    return new Observable(observer => {
      this.apollo
        .query<{ employee: IEmployee }>({ query: loadCurrentEmployee })
        .pipe(
          tap(response => {
            this.userModel = new EmployeeModel(response.data.employee);
            this.userModel.lastSuccessfulLogin = utc(lastSuccessfulLogin);
          }),
          map(() => this.userModel),
          catchError(err => {
            return this.errorService.handle(err);
          })
        )
        .subscribe(data => {
          observer.next(data);
          this.userDataResolver.emit(this.userModel);
          observer.complete();
        });
    });
  }

  /**
   * User Data
   * @desc this is the first call on every page so we can know the current
   * user and the relevant information to that user. We grab the user roles
   * as well to know how to handle any failures with that user. If the user
   * isn't authenticated then we redirect the user to be logged in
   */
  userData(): Observable<EmployeeModel> {
    const url = `${this.configService.config.rootApiDomain}${AUTH_API.ME}`;

    return this.http.get(url, { responseType: 'json' }).pipe(
      tap(
        response => {
          this.userPermissionModel = new PermissionModel(response);
        },
        (error: HttpErrorResponse) => {
          // When application works, /me endpoint is OK, then OK, but as soon it's failed, then goes 401.
          // This is not convenient especially for e2e tests.
          if (error.status === 401) {
            this.appInsightsService.clearUserId();
            window.location.href = `${this.configService.config.rootApiDomain}${AUTH_API.LOGIN}`;
          }
        }
      ),
      mergeMap((response: any) => this.fetchUserData(response.lastSuccessfulLogin)),
      tap(user => this.appInsightsService.setUserId(`${user.id}`))
    );
  }

  /**
   * Clear all cookies
   */
  logout(): Observable<unknown> {
    return new Observable((observer: Subscriber<unknown>) => {
      this.userModel = new EmployeeModel(); // update User in whole app with empty data
      this.appInsightsService.clearUserId();

      observer.next();
      observer.complete();
    });
  }
}
