import { BehaviorSubject } from 'rxjs';
import { ConversationPanelComment } from './comment.model';
import { map } from 'rxjs/operators';

export class CommentList {
  private list: ConversationPanelComment[];
  private listSubject = new BehaviorSubject<ConversationPanelComment[]>([]);
  public list$ = this.listSubject.asObservable();
  public hasComments$ = this.list$.pipe(map(comments => comments.length > 0));
  public hasUnreadComments$ = this.list$.pipe(map(comments => comments.some(c => !c.isRead && c.authorId !== this.currentUserId)));

  constructor(
    comments: ConversationPanelComment[],
    private currentUserId: number
  ) {
    this.list = comments;
  }

  public get unreadComments(): ConversationPanelComment[] {
    return this.list.filter(c => !c.isRead && c.authorId !== this.currentUserId);
  }

  public notifyChanges(comments: ConversationPanelComment[]): void {
    this.listSubject.next(comments);
  }

  public updateComment(comment: ConversationPanelComment): void {
    const item = this.list.find(c => c.id === comment.id);
    item.message = comment.message;
    if (comment.authorId !== this.currentUserId) {
      item.isRead = false;
    }
    this.notifyChanges(this.list);
  }

  public deleteComment(comment: ConversationPanelComment): void {
    this.list = this.list.filter(c => c.id !== comment.id);
    this.notifyChanges(this.list);
  }

  public addComment(comment: ConversationPanelComment): void {
    this.list = this.list.concat(comment);
    this.notifyChanges(this.list);
  }

  public setAllRead() {
    this.list.forEach(c => (c.isRead = true));
    this.notifyChanges(this.list);
  }
}
