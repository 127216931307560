import { isNumber as _isNumber } from 'lodash-es';

import { DatepickerSettingsModel } from '@component/datepicker/datepicker-settings.model';
import { dateRange, DAY_IN_MILLISECONDS, diffBetweenDates } from '@helpers';

import { BaseModel } from '../base.model';
import { EmployeeModel } from '../auth';
import { ChangelogModel } from './changelog.model';

type ReportTabParams = {
  calendarSettings: DatepickerSettingsModel;
};

type ReportTabData = {
  changelog: ChangelogModel[];
  tabAuthors: EmployeeModel[];
};

interface IVersion {
  major: number;
  minor: number;
  patch: number;
}

export class ReportTabModel extends BaseModel {
  set alias(alias: string) {
    if (!alias) {
      return;
    }

    this._alias = this.makeAlias(alias);
  }

  get alias() {
    return this._alias;
  }

  public title: string;
  public order: number;

  public version: IVersion;
  public authors: EmployeeModel[];

  public chartCurrentPage: number;
  public params: ReportTabParams;
  public data: ReportTabData = { changelog: void 0, tabAuthors: void 0 };

  public marginLeft: string;
  public marginRight: string;
  public paginationRowNumber: number;

  private _alias: string;

  constructor(data: any = {}) {
    super(data);

    this.title = data.title || '';
    this.alias = data.alias || this.title;
    this.order = _isNumber(data.order) ? data.order : null;

    this.version = (data.version && { ...data.version }) || undefined;

    if (data?.authors) {
      this.authors = data.authors.map(author => new EmployeeModel(author));
    } else {
      this.authors = [];
    }

    if (data?.data?.changelog) {
      this.data.changelog = data.data.changelog.map(changelog => new ChangelogModel(changelog));
    } else {
      this.data.changelog = [];
    }

    if (data?.tabAuthors) {
      this.data.tabAuthors = data.tabAuthors.map(author => new EmployeeModel(author));
    } else {
      this.data.tabAuthors = [];
    }

    if (data?.params?.calendarSettings) {
      this.params = {
        calendarSettings: new DatepickerSettingsModel(data.params.calendarSettings)
      };
    } else {
      this.params = {
        calendarSettings: new DatepickerSettingsModel()
      };
    }

    this.chartCurrentPage = 10; // default 'plugin time' at 5:00
    this.marginLeft = '-45px';
    this.marginRight = '0';
    this.paginationRowNumber = 1;
  }

  /***** Generate Alias *****/

  /**
   * Created alias from text
   * @param {string} text
   * @return {string}
   */
  public makeAlias(text: string): string {
    return text
      .trim()
      .toLowerCase()
      .replace(/ +(?= )/g, '')
      .replace(/ /g, '-')
      .replace(/[^\w-]+/g, '');
  }

  public setDates(start: Date, end: Date) {
    if (!start || !end) {
      return;
    }

    this.params.calendarSettings.dates = dateRange(start, end);
  }
}
