import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ConfigService } from '@core';
import { environment } from '@src/environments/environment';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { EmployeeModel } from '@models';
import { AppInsightsService } from '@services';
import { NgcCookieConsentService } from 'ngx-cookieconsent';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public lastGitCommit;
  public googleAnalytics;
  public user: EmployeeModel;

  constructor(
    public loader: LoadingBarService,
    private meta: Meta,
    private configService: ConfigService,
    private appInsightsService: AppInsightsService,
    private cookieConsentService: NgcCookieConsentService
  ) {
    if (!environment.production) {
      this.meta.addTag({ name: 'git-commit', content: configService.config.lastGitCommit });
    }

    this.googleAnalytics = configService.config.googleAnalytics;
    if (this.googleAnalytics) {
      this.addGoogleAnalytics();
    }

    this.appInsightsService.start();
  }

  ngOnInit() {
    this.setupCookieContent(this.configService.config.rootApiDomain);
  }

  private addGoogleAnalytics() {
    const script = document.createElement('script');
    script.innerHTML = `
      window.ga=window.ga||function(){(ga.q=ga.q||[]).push(arguments)};ga.l=+new Date;
      ga('create', '${this.googleAnalytics}', 'auto');
      ga('send', 'pageview');
    `;
    const sourceScript = document.createElement('script');
    sourceScript.src = 'https://www.google-analytics.com/analytics.js';
    document.head.appendChild(script);
    document.head.appendChild(sourceScript);
  }

  private setupCookieContent(domain: string) {
    const config = this.cookieConsentService.getConfig();
    const domainWithoutProtocol = domain.replace('http://', '').replace('https://', '');

    config.cookie.domain = domainWithoutProtocol;
    config.content = config.content || {};
    config.content.href = 'https://www.datatilsynet.no/personvern-pa-ulike-omrader/internett-og-apper/cookies/';

    this.cookieConsentService.destroy();
    this.cookieConsentService.init(config);
  }
}
