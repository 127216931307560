import { BaseModel } from '../base.model';
import * as moment from 'moment-mini';
import { ISO_DATE_ONLY_FORMAT } from '@helpers';
import { QualificationData } from './qualification-data';

export interface SkillModelData {
  skill?: {
    id?: number;
    name?: string;
  };
  id?: number;
  name?: string;
  obtained?: string;
  validTo?: string;
  validFrom?: string;
  employeeId?: number;
}

export class SkillModel extends BaseModel implements QualificationData {
  public id: number;
  public employeeSkillId: number;
  public name: string;
  public obtained: string;
  public validTo: string;
  public validFrom: string;
  public employeeId: number;

  constructor(data: SkillModelData = {}) {
    super(data);

    this.id = data.skill?.id || data.id || undefined;
    this.employeeSkillId = data.id;
    this.name = data.skill?.name || data.name || undefined;
    this.obtained = (data.obtained && moment(data.obtained).format(ISO_DATE_ONLY_FORMAT)) || undefined;
    this.validTo = (data.validTo && moment(data.validTo).format(ISO_DATE_ONLY_FORMAT)) || undefined;
    this.validFrom = (data.validFrom && moment(data.validFrom).format(ISO_DATE_ONLY_FORMAT)) || undefined;
    this.employeeId = data.employeeId;
  }

  get title() {
    return this.name;
  }
}
