import { norwegianTextComparer, sortByNumbers } from '@helpers';
import { AssignedEmployee } from '../dto';

export enum EmployeeSortingMode {
  Alphabetical,
  Index
}

export type EmployeeSortingFunction = (employee1: AssignedEmployee, employee2: AssignedEmployee) => number;

function compareEmployeesAlphabetically(employee1: AssignedEmployee, employee2: AssignedEmployee, asc = true): number {
  return norwegianTextComparer(employee1.name, employee2.name, asc);
}

function compareEmployeesByOrder(employee1: AssignedEmployee, employee2: AssignedEmployee, asc = true): number {
  return sortByNumbers(employee1.order, employee2.order, asc);
}

export function getSortingFunction(mode: EmployeeSortingMode, ascending: boolean): EmployeeSortingFunction {
  if (mode === EmployeeSortingMode.Alphabetical) {
    return (a, b) => compareEmployeesAlphabetically(a, b, ascending);
  } else {
    return (a, b) => compareEmployeesByOrder(a, b, ascending);
  }
}
