import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'pl-tab',
  templateUrl: './tab.component.html',
  encapsulation: ViewEncapsulation.None
})

export class TabComponent {
  @Input() color: string;
}
