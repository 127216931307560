import { BaseModel } from '@model/base.model';

export class VacayScheduleStatusModel extends BaseModel {
  public employeeId: number;
  public id: number;
  public isCompleted: boolean;
  public scheduleId: number;

  constructor(data: any = {}) {
    super(data);
    this.employeeId = data.employeeId;
    this.isCompleted = data.isCompleted || false;
    this.scheduleId = data.scheduleId;
  }
}
