import { ChangeDetectionStrategy, Component, Input, signal } from '@angular/core';
import { Moment } from 'moment-mini';
import { Observable } from 'rxjs';
import { VacayScheduleService } from '../../services';
import { VacayRequestModel } from '@models';
import { DialogService, VacayRequestsService } from '@services';
import { ScheduleState } from '@model/schedules/schedule-state.model';

@Component({
  selector: 'vc-vacay-info',
  templateUrl: './vacay-info.component.html',
  styleUrls: ['./vacay-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VacayInfoComponent {
  @Input() set day(value: Moment) {
    this.vacayList$ = this.vacayScheduleService.getWishesForDay$(value);
  }

  @Input() state: ScheduleState;

  public vacayList$: Observable<VacayRequestModel[]>;

  public deleting = signal(false);

  ScheduleState = ScheduleState;

  constructor(
    private readonly vacayScheduleService: VacayScheduleService,
    private readonly dialogService: DialogService,
    private readonly vacayRequestsService: VacayRequestsService
  ) {}

  async deleteVacayRequest(request: VacayRequestModel) {
    this.deleting.set(true);
    const batch = request.vacayRequestBatch;
    if (batch) {
      const confirm = await this.dialogService.confirm(
        'Cancel request',
        `This will cancel all requests in this batch for dates ${batch.getReadableDatesDisplay()}. Are you sure?`
      );
      if (confirm) {
        this.vacayRequestsService.deleteVacayRequestBatch(request.vacayRequestBatch.id).subscribe(() => {
          this.deleting.set(false);
        });
      } else {
        this.deleting.set(false);
      }
    } else {
      this.deleting.set(false);
      throw new Error('No batch found for request');
    }
  }
}
