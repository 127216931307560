import * as moment from 'moment-mini';

export class EmploymentModel {
  public workType: string;
  public percentage: number;
  public hourlyWage: string;
  public hireDate: moment.Moment;
  public beginDate: moment.Moment;
  public quitDate: moment.Moment;
  public endDate: moment.Moment;

  constructor(data: any = {}) {

    // temporary values
    this.workType = data.workType || void 0;
    this.percentage = data.percentage || void 0;
    this.hourlyWage = data.hourlyWage || void 0;
    this.hireDate = data.hireDate || void 0;
    this.beginDate = data.beginDate || void 0;
    this.quitDate = data.quitDate || void 0;
    this.endDate = data.endDate || void 0;
  }
}
