import { gql } from 'apollo-angular';

const fragments = {
  settingsValue: gql`
    fragment SettingsValue on SettingsValue {
      id
      settingKey
      entityId
      value
      dateFrom
      dateTo
    }
  `,
  setting: gql`
    fragment Setting on Setting {
      key
      name
      type
      defaultValue
    }
  `
};

export const loadSetting = gql`
  query loadSetting($settingKey: Int!, $entityId: Int) {
    entitySetting (settingKey: $settingKey, entityId: $entityId) {
      setting {
        ...Setting
      }
      entityId
      values {
        ...SettingsValue
      }
    }
  }
  ${fragments.setting},
  ${fragments.settingsValue}
`;

export const loadAveragingPeriodsForGroups = gql`
  query AveragingPeriodsForGroups($groupIds: [Int]!, $employee: Int!) {
    averagingPeriodsForGroups(groupIds: $groupIds) {
      id
      settingKey
      entityId
      value
      dateFrom
      dateTo
    }
    employeeAveragingPeriods(employee: $employee) {
      offset
      averagingPeriod {
        id
      }
    }
  }
`;

export const loadSettings = gql`
  query loadSettings($table: String, $settingKeys: [Int!]) {
    settings (table: $table, settingKeys: $settingKeys) {
      key
      name
      type
      defaultValue
      entitySettings {
        entityId
        setting {
          ...Setting
        }
        currentValue {
          ...SettingsValue
        }
      }
    }
  }
  ${fragments.setting},
  ${fragments.settingsValue}
`;

export const createSettingsValue = gql`
  mutation createSettingsValue($settingKey: Int!, $entityId: Int, $value: String, $dateFrom: String, $dateTo: String) {
    createSettingsValue(settingKey: $settingKey, entityId: $entityId, value: $value, dateFrom: $dateFrom, dateTo: $dateTo) {
      ...SettingsValue
    }
  }
  ${fragments.settingsValue}
`;

export const updateSettingsValue = gql`
  mutation updateSettingsValue($id: Int!, $value: String, $dateFrom: String, $dateTo: String) {
    updateSettingsValue(id: $id, value: $value, dateFrom: $dateFrom, dateTo: $dateTo) {
      ...SettingsValue
    }
  }
  ${fragments.settingsValue}
`;

export const deleteSettingsValue = gql`
  mutation deleteSettingsValue($id: Int!) {
    deleteSettingsValue(id: $id) {
      ...SettingsValue
    }
  }
  ${fragments.settingsValue}
`;
