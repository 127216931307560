import { Injectable } from '@angular/core';
import { IRawCategory, minutesToHoursStatistic, STATS_CATEGORY, STATS_MODE, wishesGranted } from '@app/rostr/_shared';
import { Category, CustomPeriod, StatCategory } from '@app/rostr/_shared/models/stats';
import { Observable } from 'rxjs';
import { DisplayedStatisticState } from './displayed-statistic-state.class';

@Injectable({ providedIn: 'root' })
export class RostrStatisticsState {
  private readonly allStats: { [category in STATS_CATEGORY]?: Category } = {};

  private readonly _weeklyStatistics: DisplayedStatisticState;
  private readonly _mainStatistics: DisplayedStatisticState;

  get weeklyStatistics(): DisplayedStatisticState {
    return this._weeklyStatistics;
  }

  get mainStatistics(): DisplayedStatisticState {
    return this._mainStatistics;
  }

  constructor() {
    this.allStats[STATS_CATEGORY.AssignedDuration] = new StatCategory(minutesToHoursStatistic);
    this.allStats[STATS_CATEGORY.Deviation] = new StatCategory(minutesToHoursStatistic);
    this.allStats[STATS_CATEGORY.VacayRequests] = new StatCategory(wishesGranted);
    this.allStats[STATS_CATEGORY.VacayRequestsIgnoreAgenda] = new StatCategory(wishesGranted);
    this.allStats[STATS_CATEGORY.CustomPeriodWeekly] = new CustomPeriod();
    this.allStats[STATS_CATEGORY.CustomPeriodMain] = new CustomPeriod();

    this._weeklyStatistics = new DisplayedStatisticState(STATS_MODE.Weekly);
    this._mainStatistics = new DisplayedStatisticState(STATS_MODE.Schedule);
  }

  public setCategoryData(category: STATS_CATEGORY, data: IRawCategory, employeesIds?: number[]): void {
    this.allStats[category].setData(data, employeesIds);
  }

  public getWeeklyStat$(employeeId: number, key: string): Observable<string> {
    return this.weeklyStatistics.getStat$(employeeId, key);
  }

  public getMainStat$(employeeId: number): Observable<string> {
    return this.mainStatistics.getStat$(employeeId);
  }

  public loadMainCategory(employeeIds?: number[]): void {
    this.mainStatistics.loadData(this.allStats[this.mainStatistics.category], employeeIds);
  }

  public loadWeeklyCategory(employeeIds?: number[]): void {
    this.weeklyStatistics.loadData(this.allStats[this.weeklyStatistics.category], employeeIds);
  }

  public getStoredCategory(category: STATS_CATEGORY): Category {
    return this.allStats[category];
  }

  public setEmployeesChanged(ids: number[]): void {
    Object.keys(this.allStats).forEach((key: STATS_CATEGORY) => {
      this.allStats[key].setEmployeesChanged(ids);
    });
  }

  public cleanUp(): void {
    this.allStats[STATS_CATEGORY.AssignedDuration] = new StatCategory(minutesToHoursStatistic);
    this.allStats[STATS_CATEGORY.Deviation] = new StatCategory(minutesToHoursStatistic);
    this.allStats[STATS_CATEGORY.VacayRequests] = new StatCategory(wishesGranted);
    this.allStats[STATS_CATEGORY.VacayRequestsIgnoreAgenda] = new StatCategory(wishesGranted);
    this.allStats[STATS_CATEGORY.CustomPeriodWeekly] = new CustomPeriod();
    this.allStats[STATS_CATEGORY.CustomPeriodMain] = new CustomPeriod();

    this._weeklyStatistics.cleanUp();
    this._mainStatistics.cleanUp();
  }
}
