import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ConstellationTemplate } from '@model/masterplans/constellation-template.model';
import { ConstellationService } from '../../services/constellations.service/constellations.service';
import { Subscription } from 'rxjs';
import { MessageBusService } from '@shared/services/message-bus/message-bus.service';
import { ConstellationCreatedMessage, ConstellationDeletedMessage } from '../../classes';

@Component({
  selector: 'app-constellation-manager-sidebar-widget',
  templateUrl: './constellation-manager-sidebar-widget.component.html',
  styleUrls: ['./constellation-manager-sidebar-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConstellationManagerSidebarWidgetComponent implements OnInit, OnDestroy {
  constellations: ConstellationTemplate[] = [];

  createdSub: Subscription;
  constructor(
    private readonly constellationService: ConstellationService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly messageBus: MessageBusService
  ) {}

  ngOnDestroy(): void {
    this.createdSub.unsubscribe();
  }

  ngOnInit() {
    this.createdSub = this.messageBus.on(ConstellationCreatedMessage).subscribe(() => {
      this.fetchMyConstellations();
    });
    this.fetchMyConstellations();
  }

  private fetchMyConstellations() {
    this.constellationService.fetchMyConstellations().subscribe(result => {
      this.constellations = result;
      this.changeDetector.markForCheck();
    });
  }

  deleteConstellation(constellation: ConstellationTemplate) {
    this.constellationService.deleteConstellationTemplate(constellation.id).subscribe(() => {
      this.messageBus.publish(new ConstellationDeletedMessage(constellation.id));
      this.constellations = this.constellations.filter(item => item !== constellation);
      this.changeDetector.markForCheck();
    });
  }
}
