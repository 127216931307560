import * as d3 from 'd3';

import { xmlns } from '@helpers';

import { IMatrix } from '../matrix.interfaces';
import { Col } from '../cols/col';
import { Row } from '../rows/row';


export class Droppable {
  private group: Element;
  private $group: d3.Selection<any, any, any, any>;

  private x: number;
  private y: number;

  private _offsetX = 0;
  private _offsetY = 0;
  private _rotate = 0;

  private _element: Col | Row;

  private _guessPosition: Col | Row;

  constructor(private matrix: IMatrix) {
    this.group = document.createElementNS(xmlns, 'g');
    this.$group = d3.select(this.group).classed('droppable', true);
  }

  get node() {
   return this.group;
  }

  // class element
  set element(value) {
    this._element = value;
  }

  // highlighted supposed position
  get guessPosition() {
    return this._guessPosition;
  }

  public render() {
    this.$group.selectAll('*').remove();

    this.$group
      .attr('transform', `translate(${this._offsetX}, ${this._offsetY}) rotate(${this._rotate})`)
      .append('g')
      .attr('transform', `translate(${this.x}, ${this.y})`)
      .append('rect')
      .attr('width', this.matrix.titleSize)
      .attr('height', this.matrix.cellSize)
      .attr('class', 'success')
      .attr('opacity', 0.6)
      .attr('rx', 3)
      .attr('ry', 3);

    return this.group;
  }

  public setCoords(newX: number, newY: number) {
    this.x = newX;
    this.y = newY;
  }

  public setOffsets(offsetX: number, offsetY: number, rotate: number) {
    this._offsetX = offsetX;
    this._offsetY = offsetY;
    this._rotate = rotate;
  }

  public move(newX: number, newY: number) {
    this.x = newX;
    this.y = newY;

    this._guessPosition = this._element instanceof Col ? this.matrix.cols.dragCol(newY) : this.matrix.rows.dragRow(newY);
    this.$group.select('g').attr('transform', `translate(0, ${this._guessPosition.y})`);
  }

}
