<svg
  class="svg-left"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  x="0px"
  y="0px"
  viewBox="0 0 55 39"
  enable-background="new 0 0 55 39"
  xml:space="preserve"
  preserveAspectRatio="none"
>
  <path d="M55.6,0c-6.1,0-14.2,3.7-18.3,8.2L16.6,31.7C13,35.9,5.5,39,0,39h55.6V0z" [style.fill]="color" />
</svg>
<span class="tab-title" [style.background]="color"><ng-content></ng-content></span>
<svg
  class="svg-right"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  x="0px"
  y="0px"
  viewBox="0 0 55 39"
  enable-background="new 0 0 55 39"
  xml:space="preserve"
  preserveAspectRatio="none"
>
  <path d="M0,0c6.1,0,14.2,3.7,18.3,8.2L39,31.7c3.6,4.1,11.1,7.5,16.6,7.5H0L0,0z" [style.fill]="color" />
</svg>
