import { BaseModel } from '@models';

import { DialogService } from '@services';

export class BaseTableComponent<TableT extends BaseModel> {
  public items: TableT[] = [];
  public editMode: boolean;

  public titleField = 'title';

  protected _deletedItems: TableT[] = [];
  protected _updatedItems: TableT[] = [];

  private _snapshot: TableT[];

  constructor(public dialogService: DialogService) { }

  public editTable() {
    this.editMode = !this.editMode;
  }

  public deleteRow(row) {
    if (!this.editMode) {
      return;
    }

    this.dialogService.confirm('Delete', 'This row').then(
      deleteConfirmed => {
        if (deleteConfirmed) {
          this.items.splice(row.$$index, 1);
          if (row.id) {
            this._deletedItems.push(row);
          }
        }
      },
      () => null
    );
  }

  public updateValueByTarget(event, row) {
    const index = this.items.findIndex(el => el.id === row.id);
    this.items[index][event.target.name] = event.target.value;
    this.updateValue(row);
  }

  public updateValue(row) {
    if (row.id) {
      const updatedItemsIndex = this._updatedItems.findIndex(el => el.id === row.id);
      const rowIndex = this.items.findIndex(el => el.id === row.id);

      if (updatedItemsIndex !== -1) {
        this._updatedItems[updatedItemsIndex] = this.items[rowIndex];
      } else {
        this._updatedItems.push(this.items[rowIndex]);
      }
    }
  }

  public cancel() {
    this.clean();
    this.items = this.getCopy(this._snapshot);
  }

  public renderBoolLabel(value: boolean) {
    return value ? 'Yes' : 'No';
  }

  public save() {
    this.clean();
    this.setSnapshot();
  }

  protected addRow(row) {
    this.items.push(row);
  }

  protected setSnapshot() {
    this._snapshot = this.getCopy(this.items);
  }

  private clean() {
    this._deletedItems.length = 0;
    this._updatedItems.length = 0;
  }

  private getCopy(items) {
    const cloned = [];
    items.forEach(item => {
      cloned.push(item.clone());
    });

    return cloned;
  }
}
