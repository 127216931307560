export const AFTER_SHIFT_BREAK_MIN = 8;
export const AFTER_SHIFT_BREAK_MAX = 11;
export const AFTER_SHIFT_BREAK_MAX_SOFT = 24;
export const AFTER_SHIFT_BREAK_DEFAULT = 11;

export const CONSECUTIVE_WEEKLY_OFF_MIN = 28;
export const CONSECUTIVE_WEEKLY_OFF_MAX = 36;
export const CONSECUTIVE_WEEKLY_OFF_DEFAULT = 35;

export const WORK_DURATION_MAX = 12.5;
export const WORK_DURATION_MIN = 9;

export const WEEKLY_WORK_MAX = 54;
export const WEEKLY_WORK_MIN = 0;

export const STANDARD_WORKING_HOURS_PER_WEEK_MAX = 100;
export const STANDARD_WORKING_HOURS_PER_WEEK_MIN = 0;

export const WORKING_DAYS_IN_ROW_MAX = 100;
export const WORKING_DAYS_IN_ROW_MIN = 0;

export const preferencesShorterLabels = {
  hardAfterShiftBreak: 'Min. break',
  hardWorkDurationMax: 'Daily max',
  hardWeeklyWorkMax: 'Weekly max',
  standardWorkingHoursPerWeek: 'Avg. per week',
  workingDaysInRow: 'Days in row'
};

export const preferencesLabels = {
  hardAfterShiftBreak: 'Minimum break length',
  hardWorkDurationMax: 'Maximum shift length',
  hardWeeklyWorkMax: 'Standard working hours per week',
  standardWorkingHoursPerWeek: 'Standard working hours per week',
  workingDaysInRow: 'Working days in a row'
};
