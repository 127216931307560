import { Cols } from './cols/cols';
import { Rows } from './rows/rows';
import { Grid } from './grid/grid';
import { Scroll } from './scrolling/scroll';
import { Draggable } from './draggable/draggable';
import { IMatrix, IMatrixComponent } from './matrix.interfaces';

import * as d3 from 'd3';

export class Matrix implements IMatrix {

  get svg() {
    return this.$svg;
  }

  get data() {
    return this.element.data;
  }

  get titleSize() {
    return this.sizes.title;
  }

  get maxTitleSize() {
    return this.sizes.maxTitle;
  }

  get cellSize() {
    return this.sizes.cell;
  }

  get halfCellSize() {
    return this.sizes.cell / 2;
  }

  get buttonSize() {
    return this.sizes.button;
  }

  get offset() {
    return this.sizes.cell + this.sizes.title;
  }

  get colAddClick() {
    return this.element.colAddClick;
  }

  get rowAddClick() {
    return this.element.rowAddClick;
  }

  get colTitleClick() {
    return this.element.colTitleClick;
  }

  get rowTitleClick() {
    return this.element.rowTitleClick;
  }

  get cellClick() {
    return this.element.cellClick;
  }

  get relationPosition() {
    return this.element.relationPosition;
  }

  get colShowAddBtn() {
    return this.element.colShowAddBtn;
  }

  get rowShowAddBtn() {
    return this.element.rowShowAddBtn;
  }

  get selectType() {
    return this.element.selectType;
  }

  get rowsDataLength() {
    let count = 0;
    this.element.data.rows.forEach((el) => count += el.data.length);
    return count;
  }

  get colsDataLength() {
    let count = 0;
    this.element.data.cols.forEach((el) => count += el.data.length);
    return count;
  }

  get event() {
    return this.element.event;
  }

  get visibleColsCount() {
    return this.scrollCol.visibleItems;
  }

  get visibleRowsCount() {
   return this.scrollRow.visibleItems;
  }

  get availableColsCount() {
    return this.visibleColsCount <  this.colsDataLength + 1
        ? this.visibleColsCount : this.colsDataLength;
  }

  get availableRowsCount() {
    return this.visibleRowsCount <  this.rowsDataLength + 1
      ? this.visibleRowsCount : this.rowsDataLength;
  }

  public $svg: d3.Selection<any, any, any, any>;

  public readonly svgOptions = { width: 0, height: 0 };
  public readonly scrollCol: Scroll;
  public readonly scrollRow: Scroll;

  public cols: Cols;
  public rows: Rows;
  public grid: Grid;
  public draggable: Draggable;
  private main: Element; // main 'g' node


  private sizes = {
    cell: 30,
    title: 120,
    maxTitle: 120 / 8 - 2,
    button: 120 / 2 - 2
  };

  public constructor(private readonly element: IMatrixComponent) {
    this.calcSize();

    this.$svg = d3.select(this.element.el.nativeElement)
      .select('.matrix > svg')
      .attr('width', this.svgOptions.width)
      .attr('height', this.svgOptions.height);

    this.grid = new Grid(this);
    this.cols = new Cols(this);
    this.rows = new Rows(this);
    this.scrollCol = new Scroll(this);
    this.scrollRow = new Scroll(this, true);
    this.draggable = new Draggable(this);

    this.main = this.$svg.node() as Element;
    this.main.appendChild(this.draggable.node);


    this.render();

    // window.addEventListener('resize', () => {
    //   this.calcSize();
    //   this.$svg
    //     .attr('width', this.svgOptions.width)
    //     .attr('height', this.svgOptions.height);
    // });

  }

  public renderCol(colData: any) {
    this.cols.renderCol(colData);
  }

  public renderRow(rowData: any) {
    this.rows.renderRow(rowData);
  }

  public deleteCol(colData: any) {
   this.cols.deleteCol(colData);
  }

  public deleteRow(rowData: any) {
    this.rows.deleteRow(rowData);
  }

  private calcSize() {
    this.svgOptions.width = this.element.width || document.documentElement.clientWidth;
    this.svgOptions.height = this.element.height || document.documentElement.clientHeight;
  }

  private render() {
    this.grid.render(); // render sublayout as background for cells
    this.cols.render();
    this.rows.render();
    this.grid.renderCells();
    this.scrollCol.render();
    this.scrollRow.render();

    this.drawHeaders();
  }

  private drawHeaders() {
    this.$svg.selectAll('.matrix-header').remove();

    this.drawColHeader();
    this.drawRowHeader();
  }

  private drawColHeader() {
    this.$svg
      .append('g')
      .attr('class', 'matrix-header')
      .attr('transform', `translate(${this.sizes.title + this.sizes.cell}, 20)`)
      .append('text')
      .text(this.element.colHeader);
  }

  private drawRowHeader() {
    this.$svg
      .append('g')
      .attr('class', 'matrix-header')
      .attr('transform', () => {
        const translateX = 20;
        const translateY = this.sizes.title + this.sizes.cell + this.element.rowHeader.length * 7;
        return `translate(${translateX}, ${translateY}) rotate(-90)`;
      })
      .append('text')
      .text(this.element.rowHeader);
  }
}
