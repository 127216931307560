import { SettingsKey, settingsKeyMapper } from './settings-key';

export class SettingValue {
  id: number;
  key: SettingsKey;
  value: string;
  entityId: number;
  dateFrom: string;
  dateTo?: string | null;

  constructor(data: any) {
    this.id = data?.id;
    this.key = settingsKeyMapper(data?.key || data?.settingKey);
    this.value = data?.value;
    this.entityId = data?.entityId;
    this.dateFrom = data?.dateFrom;
    this.dateTo = data?.dateTo;
  }
}

export interface EntitySetting {
  entityId: number;
  setting?: Setting;
  currentValue?: SettingValue;
  nextValue?: SettingValue;
  values?: SettingValue[];
}

export class Setting {
  key: SettingsKey;
  name: string;
  defaultValue: string;
  type: string;
  entitySettings?: EntitySetting[];

  constructor(data: any) {
    this.key = settingsKeyMapper(data.key);
    this.name = data?.name;
    this.defaultValue = data?.defaultValue;
    this.type = data?.type;
    this.entitySettings = data?.entitySettings || [];
  }

  public getPresentSettingValue(): string {
    // `currentValue?.value` is expected string but in debug mode value can be also number.
    // first part of condition is for values like "true" or "false" or "-23" or "123" or undefined.
    // second part of condition is for numeric values like 0, 1, 10, etc. HZN-3246 use case.
    const valueIsTruthy = this.entitySettings[0]?.currentValue?.value || Number(this.entitySettings[0]?.currentValue?.value) >= 0;

    return valueIsTruthy ? this.entitySettings[0].currentValue.value : this.defaultValue;
  }
}
