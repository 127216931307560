import * as d3 from 'd3';
import { weekFormat } from './helpers';
import { IBaseRenderer } from './base-renderer.interface';
import { Day } from './day';
import { weekDay } from '@helpers';

export class Month {
  public month: d3.Selection<any, any, any, any>; // d3 selection for current day of week
  public date; // The month start date
  public days: Day[] = []; // Related days
  public countSelectedDays = 0; // Counter of the selected days
  // selection indicator. Will be true only if all of the days selected
  public isSelected = false;
  /*
   We use days limits for count how many days related with current month.
   For example:
   If daysLimit = 5 and countSelectedDays = 5 then we set flag isSelected = true
   and highlights current month in elements
   */
  public daysLimit = 0;

  /**
   * @param renderer { Renderer } - the main class
   * @param label { string } - display text
   * @param index { number } - month index
   */
  constructor(
    private renderer: IBaseRenderer,
    private label: string,
    private index: number
  ) {}

  public render() {
    const self = this;

    this.renderLabel();

    this.days.length = 0;
    this.daysLimit = 0;
    this.countSelectedDays = 0;
    this.month.classed('selected-label', false);

    self.date = new Date(this.renderer.calendarYear, this.index, 1);
    this.renderer.monthContainer
      .append('path')
      .attr('class', 'month')
      .attr('d', function () {
        return self.monthPath(self.date, self);
      });
    // this.fetchDays();
  }

  /**
   * Render month label
   */
  public renderLabel() {
    const self = this;

    if (this.month) {
      this.month.remove();
    }

    this.month = this.renderer.monthLabels
      .append('text')
      .text(this.label)
      .attr('class', 'month-label')
      .attr('alignment-baseline', 'central')
      .attr('text-anchor', 'middle')
      .attr('y', 0)
      .attr('x', function () {
        const textWidth = this.getComputedTextLength();
        return self.renderer.svgOptions.monthScale.invert(self.index) + self.renderer.cellSize * 3 - textWidth / 2;
      })
      .on('click', function () {
        const relatedDays = self.days.filter(day => !day.disabled);

        relatedDays.forEach(day => {
          if (self.isSelected) {
            self.renderer.dropSelectedDate(day);
          } else {
            self.renderer.addSelectedDate(day);
          }
        });

        self.renderer.dateChanges.emit({
          type: self.isSelected ? 'group_remove' : 'group_add',
          changedDates: relatedDays.reduce((acc, day) => {
            acc.push(day.date);
            return acc;
          }, []),
          ranges: self.renderer.selectedRanges,
          activeUnit: self.renderer.activeUnit
        });
        self.selectAllMonth(!self.isSelected && relatedDays.length > 0);
      });
  }

  /**
   * Selecting/un-selecting all days of the related days
   * @param selectionStatus { boolean } true - select / false - un-select
   */
  public selectAllMonth(selectionStatus: boolean) {
    for (let i = 0; i < this.days.length; i++) {
      const day = this.days[i];

      if (!day.disabled) {
        this.days[i].setSelection(selectionStatus, false);
      }
    }
  }

  /**
   * Memorization all related days
   * @param day { Day } - related day
   */
  public addRelatedDay(day: Day) {
    if (day.disabled) {
      return;
    }

    if (day.isSelected) {
      this.countSelectedDays++;
    }
    this.daysLimit++;
    this.days.push(day);
    this.checkSelection();
  }

  /**
   * We are do update selected days counter and add/remove selected css-class
   * @param isSelected { boolean } - true - selected / false - un-selected
   */
  public updateSelection(isSelected: boolean) {
    this.countSelectedDays = isSelected ? this.countSelectedDays + 1 : this.countSelectedDays - 1;

    this.isSelected = this.countSelectedDays === this.daysLimit;

    this.month.classed('selected-label', this.isSelected);
  }

  /**
   * Checking current selection status and add/remove selected css-class
   */
  public checkSelection() {
    this.isSelected = this.countSelectedDays === this.daysLimit;
    this.month.classed('selected-label', this.isSelected);
  }

  /**
   * Method to drawing month curves
   * @param t0 { Date } - month start
   * @param self { Month } - self-class
   * @returns {string} - curve path
   */
  private monthPath(t0, self) {
    const t1 = new Date(t0.getFullYear(), t0.getMonth() + 1, 0);
    const d0 = +weekDay(t0);
    const w0 = this.renderer.svgOptions.weekScale.invert(+weekFormat(t0));
    const w0Next = this.renderer.svgOptions.weekScale.invert(+weekFormat(t0) + 1);
    const d1 = +weekDay(t1);
    const w1 = this.renderer.svgOptions.weekScale.invert(+weekFormat(t1));
    const w1Next = this.renderer.svgOptions.weekScale.invert(+weekFormat(t1) + 1);

    return `M${w0Next}, ${d0 * self.renderer.cellSize}
        H${w0}
        V${7 * self.renderer.cellSize}
        H${w1}
        V${(d1 + 1) * self.renderer.cellSize}
        H${w1Next}
        V0
        H${w0Next}
        Z`;
  }

  /*private fetchDays() {
   let yearStart = moment()
   .year(this.renderer.calendarYear)
   .startOf('year')
   .isoWeekday(1);

   let currMonthStart = moment()
   .year(this.renderer.calendarYear)
   .month(this.index + 1)
   .startOf('month');

   let offset = currMonthStart.diff(yearStart, 'days');

   let daysInMonth = moment()
   .month(this.index)
   .daysInMonth();

   for (let i = 0; i < daysInMonth; i++) {
   this.days.push(this.renderer.days[ offset - daysInMonth + i]);
   }
   }*/
}
