import * as d3 from 'd3';
import { IItemConfig } from './interfaces';
import { BaseItem } from './base.item';
export class WarningItem extends BaseItem {
    protected $warning: d3.Selection<any, any, any, any>;
    constructor(protected _title = '', protected _warning = '', protected _itemConfig: IItemConfig = { height: 40, offset: { bottom: 10 } }) {
        super(_title, _itemConfig);
        this.$warning = this.$el.append('text');
    }

    public render() {
        this.renderWarningText();
        return super.render();
    }

    public renderWarningText() {
        const sizes = this._timeline.sizes();
        const tw = (sizes.gridWidth + sizes.menuWidth) / 2;
        const hh = this.height / 2;

        this.$warning
            .text(this._warning)
            .attr('class', 'title')
            .attr('y', hh)
            .attr('x', tw)
            .attr('dy', '0.5ex');
    }

    public renderBackground() {
        const sizes = this._timeline.sizes();
        const mw = sizes.gridWidth + sizes.menuWidth;
        this.$background
            .attr('y', this.config.offset.top)
            .attr('height', this.height)
            .attr('width', mw)
            .attr('fill', '#009aff')
            .attr('fill-opacity","0.75');
    }
}
