import * as moment from 'moment-mini';
import { min as _min, max as _max } from 'lodash-es';
import { DayOfWeekFormat, MonthFormat } from '../enums/days.enum';
import { DateRange } from '@shared/interfaces/date-range.interface';
import { MatDateFormats } from '@angular/material/core';

export const HMM_FORMAT = 'H:mm';
export const HMS_FORMAT = 'HH:mm:ss';
export const FULL_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const FULL_DATE_FORMAT_DAY_FIRST = 'DD-MM-YYYY HH:mm:ss';
export const FULL_DATE_FORMAT_W_SLASH = 'YYYY/MM/DD HH:mm:ss';
export const FULL_DATE_FORMAT_W_SLASH_MIDNIGHT = 'YYYY/MM/DD 00:00:00';
export const SHORT_DATE_FORMAT = 'DD.MM.YYYY';
export const DATE_PICKER_DATE_FORMAT = 'dd mmm, yyyy';
export const MINUTES_PER_HOUR = 60;
export const SECONDS_PER_MINUTE = 60;
export const HOURS_PER_DAY = 24;
export const MINUTES_PER_DAY = MINUTES_PER_HOUR * HOURS_PER_DAY;
export const DAY_IN_MILLISECONDS = 1000 * SECONDS_PER_MINUTE * MINUTES_PER_HOUR * HOURS_PER_DAY;
export const FORMAT_WEEKDAY_DAY_MONTH_YEAR_SHORT = 'dddd D MMM YYYY';
export const FORMAT_WEEKDAY_DAY_MONTH_YEAR_SHORT_W_COMMA = 'dddd, DD.MM.YYYY';
export const DATE_FORMAT_HUMAN_READABLE = 'DD. MMM YYYY, H:mm';

export const UTC_OFFSET_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ';
export const UTC_ZERO_OFFSET_FORMAT = 'YYYY-MM-DDTHH:mm:ss+00:00';

export const ISO_DATE_ONLY_FORMAT = 'YYYY-MM-DD';
export const ISO_DATE_ONLY_STRING_LENGTH = 10;
export const ISO_DATE_ONLY_REGEX = /^\d{4}-\d{2}-\d{2}$/gm;

export const DATEPICKER_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'LL'
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

/**
 *  @description Will be used in any place that the current year is needed
 *
 *  @returns The current year as a number ex... 2019
 *
 */
export const CURRENT_YEAR = new Date().getFullYear();

export function weekDay(date: Date) {
  return (date.getDay() + 6) % 7;
}

export enum TimeMeasure {
  Hours = 'hours',
  Days = 'days',
  Minutes = 'minutes',
  Seconds = 'seconds'
}

/**
 * Number of weeks in month
 * @param date
 * @returns {number}
 */
export function weeksInMonth(date: Date): number {
  const firstOfMonth = new Date(date.getFullYear(), date.getMonth() - 1, 1);
  const lastOfMonth = new Date(date.getFullYear(), date.getMonth(), 0);
  const used = firstOfMonth.getDay() + lastOfMonth.getDate();

  return Math.ceil(used / 7);
}

export function formatWeekday(date: Date | string, format: DayOfWeekFormat): string {
  const momentDate = moment(date);

  if (format === DayOfWeekFormat.short) {
    return momentDate.format('dd').substring(0, 1);
  } else {
    return momentDate.format(format);
  }
}

export function formatMonth(date: Date | string, format: MonthFormat): string {
  return moment(date).format(format);
}

export function endOfWeek(date: Date) {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate() + 7 - date.getDay());
}

export function endOfMonth(date: Date) {
  return new Date(date.getFullYear(), date.getMonth() + 1, 0);
}

export function isoWeek(date: Date) {
  // Create a copy of this date object
  const target = new Date(date.valueOf());

  // ISO week date weeks start on monday
  // so correct the day number
  const dayNr = (date.getDay() + 6) % 7;

  // ISO 8601 states that week 1 is the week
  // with the first thursday of that year.
  // Set the target date to the thursday in the target week
  target.setDate(target.getDate() - dayNr + 3);

  // Store the millisecond value of the target date
  const firstThursday = target.getTime();

  // Set the target to the first thursday of the year
  // First set the target to january first
  target.setMonth(0, 1);
  // Not a thursday? Correct the date to the next thursday
  if (target.getDay() !== 4) {
    target.setMonth(0, 1 + ((4 - target.getDay() + 7) % 7));
  }

  // The weeknumber is the number of weeks between the
  // first thursday of the year and the thursday in the target week
  // 604800000 = 7 * 24 * 3600 * 1000
  return 1 + Math.ceil((firstThursday - target.getTime()) / 604800000);
}

export function dateFromMomentUTC(date: moment.Moment) {
  return (date && new Date(date.format(FULL_DATE_FORMAT_W_SLASH))) || void 0;
}

export function daysDifference(dateFrom: moment.Moment | Date, dateTo: moment.Moment | Date): number {
  return moment(dateTo).diff(moment(dateFrom), 'days');
}

export function diffBetweenDates(date1: moment.Moment | Date, date2: moment.Moment | Date, measure: 'days' | 'hours' | 'minutes' | 'seconds' = 'minutes') {
  const timeDiff = date2.valueOf() - date1.valueOf();

  switch (measure) {
    case 'days': {
      // The output can be wrong between last day in summertime and first day in wintertime. The result will be 2 in that case.
      return Math.ceil(timeDiff / (1000 * 3600 * 24));
    }
    case 'hours': {
      return Math.ceil(timeDiff / (1000 * 3600));
    }
    case 'minutes': {
      return Math.ceil(timeDiff / (1000 * 60));
    }
    case 'seconds': {
      return Math.ceil(timeDiff / 1000);
    }
  }
}

/*
 * return array of the dates
 */
export function dateRange(start: Date, end: Date) {
  const dateArray = [];
  const currentDate = new Date(start.getTime());

  while (currentDate.getTime() <= end.getTime()) {
    dateArray.push(new Date(currentDate.getTime()));
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dateArray;
}

/**
 * return object consists of min and max value
 */
export function datesExtremes(range: Date[]): { min: Date; max: Date } {
  const min = new Date(_min(range).toString());
  const max = new Date(_max(range).toString());
  return { min, max };
}

export function enumerateMomentDates(dateFrom: moment.Moment, dateTo: moment.Moment): moment.Moment[] {
  const dateArray = [];
  let currentDate = moment(dateFrom);
  while (currentDate <= dateTo) {
    dateArray.push(currentDate);
    currentDate = moment(currentDate).add(1, 'days');
  }
  return dateArray;
}

/**
 * Given a date object returns an ISOString formatted date as string at the start of the day time (midnight)
 * @param  date [A date]
 * @return string [Start of date string]
 */
export function getStartOfDayISOString(date: Date): string {
  return moment(date).utcOffset('+0000', true).toISOString();
}

/*
 * format a date to YYYY/MM/DD Midnight
 */
export function formatMidnight(date: moment.Moment): string {
  return date.format(FULL_DATE_FORMAT_W_SLASH_MIDNIGHT);
}

/**
 * Parse string to UTC using format with zero offset
 */
export function parseUsingUTCZeroOffsetFormat(date: string): moment.Moment {
  return moment.utc(date, UTC_OFFSET_FORMAT);
}

/**
 * Format UTC using format with zero offset
 */
export function formatUsingUTCZeroOffset(date: moment.Moment): string {
  return date.format(UTC_ZERO_OFFSET_FORMAT);
}

export function formatUsingUTCZeroOffsetForTwoDates(fromDate: Date, toDate: Date) {
  return [formatUsingUTCZeroOffset(moment(fromDate)), formatUsingUTCZeroOffset(moment(toDate))];
}

export function formatUsingUTCZeroOffsetStartOfEndOf(fromDate: Date, toDate: Date) {
  // Example: From Aug 01 00:00:00 but To Aug 10 23:59:59 - so that to include whole days of statiscics (or any date related data).
  return [formatUsingUTCZeroOffset(moment(fromDate).startOf('day')), formatUsingUTCZeroOffset(moment(toDate).endOf('day'))];
}

export function formatUsingUTCZeroOffsetStartOfDay(date: Date) {
  return formatUsingUTCZeroOffset(moment(date).startOf('day'));
}

/**
 * Converts a localized date to UTC date without the time zone offset
 * It will not change the time so it skips the offset information
 */
export function toUtcWithLocalOffset(date: Date): moment.Moment {
  const utcOffset = moment().utcOffset();
  return moment.utc(date).add(utcOffset, 'minutes');
}

export function getISODateOnlyString(isoDateString: string): string {
  if (!isISOString(isoDateString)) {
    return null;
  }

  if (isISODateOnlyString(isoDateString)) {
    return isoDateString;
  } else {
    return isoDateString.substr(0, ISO_DATE_ONLY_STRING_LENGTH);
  }
}

export function getISODateOnlyStringFromLocalMidnight(localMidnightDate: Date): string {
  const utcMidnight = moment(localMidnightDate).utcOffset(0, true);
  return getISODateOnlyString(utcMidnight.toISOString());
}

export function isISODateOnlyString(isoDateString: string): boolean {
  return ISO_DATE_ONLY_REGEX.test(isoDateString);
}

export function isISOString(isoDateString: string): boolean {
  return moment(isoDateString, moment.ISO_8601, true).isValid();
}

export function formatDateToShortFormat(date: Date | string) {
  return moment(date).format(SHORT_DATE_FORMAT);
}

export function compareIsoWeekYearObject(a: { year: number; isoWeek: number }, b: { year: number; isoWeek: number }): number {
  if (a.year === b.year) {
    if (a.isoWeek > b.isoWeek) {
      return 1;
    } else if (a.isoWeek < b.isoWeek) {
      return -1;
    } else {
      return 0;
    }
  } else if (a.year < b.year) {
    return -1;
  } else {
    return 1;
  }
}

export function getMomentDiffFromNow(dateFrom: string): moment.Moment {
  const dateFromMoment = moment(dateFrom);
  const diffInSeconds = moment().diff(dateFromMoment, 'seconds');
  const runningForMoment = moment();
  runningForMoment.set({ hour: 0, minute: 0, second: 0 });
  runningForMoment.add(diffInSeconds, 'seconds');

  return runningForMoment;
}

export function getMomentDiff(dateFrom: string, dateTo: string): moment.Moment {
  const dateFromMoment = moment(dateFrom);
  const dateToMoment = moment(dateTo);
  const diffInSeconds = dateToMoment.diff(dateFromMoment, 'seconds');
  const runningForMoment = moment();
  runningForMoment.set({ hour: 0, minute: 0, second: 0 });
  runningForMoment.add(diffInSeconds, 'seconds');

  return runningForMoment;
}

export function toUtcWithLocalOffsetStartOfDay(date: Date): Date {
  if (!date) {
    return null;
  }
  return toUtcWithLocalOffset(date).startOf('day').toDate();
}

export function toUtcWithLocalOffsetEndOfDay(date: Date): Date {
  if (!date) {
    return null;
  }
  return toUtcWithLocalOffset(date).endOf('day').toDate();
}

export function dateBelongsToRange(date: Date, dateFrom: Date, dateTo?: Date): boolean {
  const dateStart = toUtcWithLocalOffset(date)?.startOf('day');
  const dateFromStart = toUtcWithLocalOffset(dateFrom)?.startOf('day');
  const dateToStart = (dateTo && toUtcWithLocalOffset(dateTo)?.startOf('day')) || null;
  return dateStart.isSameOrAfter(dateFromStart) && (!dateToStart || dateStart.isSameOrBefore(dateToStart));
}

export function dateRangesOverlap(range1: DateRange, range2: DateRange): boolean {
  const range1Start = toUtcWithLocalOffset(range1.dateFrom)?.startOf('day');
  const range1End = (range1.dateTo && toUtcWithLocalOffset(range1.dateTo))?.startOf('day');
  const range2Start = toUtcWithLocalOffset(range2.dateFrom)?.startOf('day');
  const range2End = (range2.dateTo && toUtcWithLocalOffset(range2.dateTo))?.startOf('day');

  return (
    (range1Start.isSameOrAfter(range2Start) && range1Start.isSameOrBefore(range2End)) ||
    (range2Start.isSameOrAfter(range1Start) && range2Start.isSameOrBefore(range1End)) ||
    (!range1End && range1Start.isSameOrBefore(range2End)) ||
    (!range2End && range2Start.isSameOrBefore(range1End)) ||
    (!range2End && !range1End)
  );
}

export function getWeekDays(date: moment.Moment): string[] {
  const weekDays = [];
  for (let i = 0; i < 7; i++) {
    weekDays.push(date.clone().add(i, 'days').format('YYYY-MM-DD'));
  }

  return weekDays;
}

export function getDatesBetween(startDate: moment.Moment, endDate: moment.Moment): string[] {
  const dates = [];
  let currentDate = startDate.clone();

  while (currentDate.isSameOrBefore(endDate)) {
    dates.push(currentDate.format('YYYY-MM-DD'));
    currentDate = currentDate.clone().add(1, 'days');
  }

  return dates;
}
