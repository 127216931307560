import { gql } from 'apollo-angular';

const fragments = {
  profile: gql`
    fragment ProfileInfo on Employee {
      id
      active
      username
      email
      firstName
      middleName
      lastName
      data
      group {
        id
        name
        unit {
          id
          name
        }
      }
      employeeGroups {
        id
        group {
          id
          name
          unit {
            id
            name
          }
        }
        validFrom
        validTo
      }
      employeeSkills {
        id
        obtained
        validFrom
        validTo
        skill {
          id
          name
        }
      }
      employeeLicense {
        id
        obtained
        validFrom
        validTo
        license {
          id
          name
        }
      }
      employeeUnitEndorsements {
        id
        obtained
        validFrom
        validTo
        unitEndorsement {
          id
          title
        }
      }
    }
  `
};

export const loadEmployeeQuery = gql`
  query employeeWithValidQualifications($id: Int!) {
    employeeWithValidQualifications(id: $id) {
      employeeRoles {
        role {
          id
          name
        }
        employeeRoleGroups {
          group {
            id
            name
          }
        }
      }
      ...ProfileInfo
    }
  }
  ${fragments.profile}
`;

export const loadQualifications = gql`
  query loadQualifications {
    licenses {
      title: name
      id
    }
    ratings {
      title: abbreviation
      id
    }
    skills {
      title: name
      id
    }
    unitEndorsements {
      title
      id
    }
  }
`;

export const updateEmployeeQuery = gql`
  mutation updateEmployee($id: Int!, $email: String, $firstName: String!, $middleName: String!, $lastName: String!, $data: JSON) {
    updateEmployee(id: $id, email: $email, firstName: $firstName, middleName: $middleName, lastName: $lastName, data: $data) {
      ...ProfileInfo
    }
  }
  ${fragments.profile}
`;

export const updateEmployeeWithAllReferencesQuery = gql`
  mutation updateEmployeeWithAllReferences(
    $id: Int!
    $email: String
    $firstName: String!
    $middleName: String!
    $lastName: String!
    $data: JSON
    $qualifications: EmployeeQualificationsInput!
    $skills: [EmployeeSkillInput]!
    $groups: [EmployeeGroupInput]!
    $workPercentages: [EmployeeWorkPercentageInput]!
    $preferences: EmployeePreferencesInput!
    $averagingPeriodOffsets: [EmployeeAveragingPeriodInput]
  ) {
    updateEmployeeWithAllReferences(
      id: $id
      email: $email
      firstName: $firstName
      middleName: $middleName
      lastName: $lastName
      data: $data
      qualifications: $qualifications
      skills: $skills
      groups: $groups
      workPercentages: $workPercentages
      preferences: $preferences
      averagingPeriodOffsets: $averagingPeriodOffsets
    ) {
      ...ProfileInfo
    }
  }
  ${fragments.profile}
`;

export const updateEmployeeQualificationsMutation = gql`
  mutation updateEmployeeQualifications($qualifications: EmployeeQualificationsInput!) {
    updateEmployeeQualifications(qualifications: $qualifications) {
      ...ProfileInfo
    }
  }
  ${fragments.profile}
`;

export const bulkEmployeeSkillOperation = gql`
  mutation bulkEmployeeSkillsOperation($created: [EmployeeSkillInput], $deleted: [EmployeeSkillInput], $updated: [EmployeeSkillInput]) {
    bulkEmployeeSkillOperation(created: $created, deleted: $deleted, updated: $updated) {
      updated {
        id
        obtained
        validFrom
        validTo
        skill {
          id
          name
        }
      }
      created {
        id
        obtained
        validFrom
        validTo
        skill {
          id
          name
        }
      }
      deleted {
        id
      }
    }
  }
`;

export const createEmployeeQualificationLicense = gql`
  mutation createEmployeeLicense($employeeId: Int!, $obtained: String!, $validFrom: String!, $licenseId: Int!, $validTo: String!) {
    createEmployeeLicense(employee: $employeeId, license: $licenseId, obtained: $obtained, validFrom: $validFrom, validTo: $validTo) {
      id
      license {
        id
        title: name
      }
      validFrom
      validTo
      obtained
      employee {
        id
      }
    }
  }
`;

export const deleteEmployeeQualificationLicense = gql`
  mutation deleteEmployeeQualificationLicense($id: Int!) {
    deleteEmployeeLicense(id: $id) {
      id
      license {
        id
        name
      }
      employee {
        id
      }
    }
  }
`;

export const updateEmployeeQualificationLicense = gql`
  mutation updateEmployeeLicense($id: Int!, $obtained: String, $validFrom: String, $validTo: String) {
    updateEmployeeLicense(id: $id, obtained: $obtained, validFrom: $validFrom, validTo: $validTo) {
      id
      license {
        id
        title: name
      }
      validFrom
      validTo
      obtained
      employee {
        id
      }
    }
  }
`;

export const sectorEmployeeHoursStatisticsQuery = gql`
  query sectorEmployeeHoursStatistics($employee: Int!) {
    sectorEmployeeHoursStatistics(employee: $employee) {
      allUnitEndorsementsHours
      employeeHours {
        unitEndorsement {
          id
          title
        }
        hours
        currencySetting {
          minHours
          numDays
        }
        isCurrent
        hoursPerDay
        notCurrentDate
        projectedHours
        daysLeft
      }
    }
  }
`;

export const loadEmployeePreferencesQuery = gql`
  query employeePreferences($id: Int!) {
    preferencesForEmployee(employeeId: $id) {
      workDurationMin
      workDurationMax
      id
      partner {
        firstName
        lastName
        id
      }
      startWorktimeMin
      startWorktimeMax
      healthyRotation
      sameScheduleWithPartner
      hardNightShiftOK
      hardWeekdays
      hardWorkDurationMin
      hardWorkDurationMax
      hardAfterShiftBreak
      hardConsecutiveWeeklyOff
      hardWeeklyWorkMax
      standardWorkingHoursPerWeek
      workingDaysInRow
    }
  }
`;

export const updateEmployeePreferences = gql`
  mutation updateEmployeePreferences(
    $id: Int!
    $workDurationMin: Int!
    $workDurationMax: Int!
    $startWorktimeMin: Int!
    $startWorktimeMax: Int!
    $healthyRotation: Boolean!
    $sameScheduleWithPartner: Boolean
    $partnerId: Int
    $standardWorkingHoursPerWeek: Float!
    $workingDaysInRow: Int!
  ) {
    updateEmployeePreferences(
      id: $id
      workDurationMin: $workDurationMin
      workDurationMax: $workDurationMax
      startWorktimeMin: $startWorktimeMin
      startWorktimeMax: $startWorktimeMax
      healthyRotation: $healthyRotation
      standardWorkingHoursPerWeek: $standardWorkingHoursPerWeek
      workingDaysInRow: $workingDaysInRow
      sameScheduleWithPartner: $sameScheduleWithPartner
      partnerId: $partnerId
    ) {
      workDurationMin
      workDurationMax
      id
      partner {
        id
      }
      startWorktimeMin
      startWorktimeMax
      healthyRotation
      sameScheduleWithPartner
      standardWorkingHoursPerWeek
      workingDaysInRow
    }
  }
`;

export const statisticsForEmployeeAveragingPeriodsQuery = gql`
  query RootQuery($employee: Int!, $groups: [Int]!) {
    employeeStatisticsForAveragingPeriods(employee: $employee, groups: $groups)
  }
`;

export const saveEmployeeRoleGroups = gql`
  mutation SaveEmployeeRoleGroups($employee: Int!, $input: [EmployeeRoleGroupInput]) {
    saveEmployeeRoleGroups(employee: $employee, input: $input) {
      employeeRole {
        id
      }
    }
  }
`;

export const updatePreferencesForEmployees = gql`
  mutation UpdateEmployeePreferencesForEmployees($preferences: EmployeePreferencesUpdateInput!, $employees: [Int]!) {
    updateEmployeePreferencesForEmployees(preferences: $preferences, employees: $employees)
  }
`;
