<div class="time-picker-input">
  <mat-select
    data-testid="time-picker-hour"
    [id]="id"
    name="hours"
    [disableOptionCentering]="true"
    [(value)]="hourValue"
    (selectionChange)="onHoursChange($event)"
    [disabled]="disabled"
  >
    <mat-option data-testid="time-picker-hour-option" *ngFor="let hour of hours" [value]="hour">
      {{ hour }}
    </mat-option>
  </mat-select>
  <mat-select
    data-testid="time-picker-minutes"
    name="minutes"
    [disableOptionCentering]="true"
    [(value)]="minuteValue"
    (selectionChange)="onMinutesChange($event)"
    [disabled]="disabled"
  >
    <mat-option data-testid="time-picker-minutes-option" *ngFor="let minute of minutes" [value]="minute">
      {{ minute }}
    </mat-option>
  </mat-select>
</div>
