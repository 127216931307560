import { fromEvent as observableFromEvent, Subject } from 'rxjs';
import * as d3 from 'd3';

import { debounceTime } from 'rxjs/operators';
import { HolidayModel } from '@models';

import { Defs } from './defs/defs';
import { GridBackground } from './defs/grid.background';
import { Draggable } from './draggable/draggable';
import { Header } from './headers/header';
import { Menu } from './menu/menu';
import { IRenderable, ISizes, ISubscribable, ITimelineConfig, IBaseTimeline } from './utils/interfaces';
import { Grid } from './grid/grid';

export class Timeline implements IRenderable, ISubscribable, IBaseTimeline {

  public onWindowResize = observableFromEvent(window, 'resize');
  public onResize = new Subject<ISizes>();
  public loaded = new Subject<void | boolean>();
  public onInitHolidays = new Subject<HolidayModel[]>();
  public onDestroy = new Subject<void>();

  public colors = ['#f6a623', '#7cb9ff', '#50e3c2', '#da65ce', '#B27EFF'];
  protected $container: d3.Selection<HTMLDivElement, any, any, any>;
  protected $el: d3.Selection<SVGElement, any, any, any>;

  protected loaderWrapper: d3.Selection<any, any, any, any>;

  protected _defs: Defs;
  protected _gridBackground: GridBackground;
  protected _draggable: Draggable;

  protected _sizes: ISizes = {
    height: 0,
    width: 0,
    rowHeight: 40,
    intervalWidth: 0,
    menuWidth: 217,
    menuHeight: 0,
    gridWidth: 0,
    headerHeight: 0,
    shiftMargin: 3.5
  };

  private _holidays: HolidayModel[] = [];

  constructor(
    protected _element: HTMLDivElement, // where we will place our main svg tag
    protected _grid: Grid, // grid class
    protected _menu: Menu, // items class
    protected _header: Header,
    protected _context: any, // ng2 component
    protected _config: ITimelineConfig
  ) {
    this.$container = d3.select(this._element);
    this.$el = this.$container
      .append<SVGElement>('svg')
      .attr('height', this._sizes.height)
      .attr('width', this._sizes.width);

    this.resize();

    this._defs = new Defs(this);

    this._menu
      .timeline(this)
      .grid(this._grid)
      .defs(this._defs)
      .setup();

    this._gridBackground = new GridBackground(this);

    this._grid
      .timeline(this)
      .menu(this._menu)
      .background(this._gridBackground)
      .setup();

    this.header.timeline = this;
    this.header.init();

    this.node.appendChild(this._defs.node());
    this.node.appendChild(this._gridBackground.background.node());
    this.node.appendChild(this._header.node);
    this.node.appendChild(this._grid.node());

    this.node.appendChild(this._menu.underSysContainer.node());
    this.node.appendChild(this._menu.node);
    this.node.appendChild(this._menu.overSysContainer.node());

    this._draggable = new Draggable(this, this._grid, this._menu);
    this.overMenuSysContainer.node().appendChild(this._draggable.node());

    this.subscribe();
  }

  get element() {
    return this.$el;
  }

  get node() {
    return this.$el.node();
  }

  get underMenuSysContainer() {
    return this._menu.underSysContainer;
  }

  get overMenuSysContainer() {
    return this._menu.overSysContainer;
  }

  get underShiftsSysContainer() {
    return this._grid.underShiftsSysContainer;
  }

  get overShiftsSysContainer() {
    return this._grid.overShiftsSysContainer;
  }

  get header() {
    return this._header;
  }

  get holidays() {
    return this._holidays;
  }

  /**
   * Rendering functionality
   * @returns {Element}
   */
  public render() {
    this._defs.render();
    this._gridBackground.render();
    this._grid.render();
    this._header.render();
    this._menu.render();

    return this.node;
  }

  public initLoader() {
    this.$container.classed('loading', true);

    this.loaderWrapper = this.$container.append('div').classed('loader-wrapper', true);

    this.loaderWrapper
      .append('div')
      .classed('loader', true)
      .text('Loading...');
  }

  public addHolidays(holidays: HolidayModel[]) {
    this._holidays = holidays;
    this.onInitHolidays.next(this._holidays);
    this.onInitHolidays.complete();
  }

  public setStartDate(date: Date) {
    this._grid.setDateFrom(date);
  }

  /*********************************** Event listeners ******************************************************/
  /**
   * Subscribe to event emitters
   */
  public subscribe() {
    this._menu.subscribe();
    this._grid.subscribe();
    this._gridBackground.subscribe();
    this._header.subscribe();

    // Initial resize
    this.resize();

    // Resize on change with debounce for decreasing number of calls
    this.onWindowResize.pipe(debounceTime(200)).subscribe(() => {
      this.resize();
    });

    this._menu.onChange.subscribe(() => {
      this.resize();
    });

    this.onResize.subscribe((sizes: ISizes) => {
      this.$el.attr('height', sizes.height).attr('width', sizes.width);
    });

    this.loaded.subscribe(isItems => {
      this.resize();

      this.$container.classed('loading', false);

      if (isItems) {
        this.loaderWrapper.style('top', `${this._sizes.headerHeight + 1}px`).style('left', `${this._sizes.menuWidth - 1}px`);
      } else {
        this.loaderWrapper.classed('hide', true);
      }
    });
  }

  public getColor(index: number) {
    return this.colors[index % this.colors.length];
  }

  /************************************* Wrappers  *******************************************************************/
  /**
   * Setter for grid day filters
   * @param filters
   */
  public filters(filters: number[]) {
    this._grid.filters(filters);
  }

  /************************************ Getters ***********************************************************************/
  /**
   * Timeline config
   * @return {ITimelineConfig}
   */
  public config() {
    return this._config;
  }

  /**
   * Current sizes
   * @returns {ISizes}
   */
  public sizes() {
    return this._sizes;
  }

  /**
   * Return timeline context object
   * @returns {any}
   */
  public context() {
    return this._context;
  }

  /**
   * Getter for defs
   * @returns {Defs}
   */
  public defs() {
    return this._defs;
  }

  /**
   * Getter for menu
   * @returns {Grid}
   */
  public grid() {
    return this._grid;
  }

  /**
   * Getter for grid
   * @returns {Menu}
   */
  public menu() {
    return this._menu;
  }

  /**
   * Getter for draggable container
   * @returns {any}
   */
  public draggable() {
    return this._draggable;
  }

  public destroy() {
    this.onDestroy.next();
    this.onDestroy.complete();

    this._grid.destroy();

    this.$el.selectAll('*').remove();
    this.$el.remove();
  }

  /**
   * Setup current sizes
   */
  protected resize() {
    const sizes = this._sizes;
    const config = this._config;
    const gridConfig = this._grid.config();

    if (config.menuWidth) {
      sizes.menuWidth = config.menuWidth;
    }

    if (config.shiftMargin) {
      sizes.shiftMargin = config.shiftMargin;
    }

    sizes.menuHeight = this._menu.height;
    sizes.headerHeight = this.header.height;
    sizes.height = sizes.headerHeight + sizes.menuHeight;

    sizes.width = this._element.clientWidth || this._element.getBoundingClientRect().width;
    sizes.gridWidth = sizes.width - sizes.menuWidth;
    sizes.intervalWidth = gridConfig.intervalWidth;

    this._sizes = sizes;

    this.onResize.next(sizes);

    return this;
  }
}
