import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';

import { DialogService } from '@services';
import { environment } from '@environment';

@Injectable()
export class VersionHeaderInterceptor implements HttpInterceptor {
  constructor(private readonly dialogService: DialogService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 400) {
          const clientVersion = environment.clientVersion;
          const serverVersion = error.headers.get('server-version');
          const proxyVersion = error.headers.get('proxy-version');

          if (clientVersion !== serverVersion || clientVersion !== proxyVersion) {
            this.dialogService
              .ack('Versions mismatch', 'Operation could not be completed. Please try again, if problem still occurs try again later. Click OK to reload page.')
              .pipe(tap(() => window.location.reload()))
              .subscribe();

            // Returning undefined here causes an error:
            // "TypeError: You provided ‘undefined’ where a stream was expected. You can provide an Observable, Promise, Array, or Iterable."
            return throwError(error);
          }
        }
        return throwError(error);
      })
    );
  }
}
