import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConversationPanelComponent } from './conversation-panel.component';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  declarations: [ConversationPanelComponent],
  imports: [CommonModule, MatIconModule, MatFormFieldModule, FormsModule, MatInputModule],
  exports: [ConversationPanelComponent]
})
export class ConversationPanelModule {}
