import { Component, ViewContainerRef } from '@angular/core';


@Component({
  selector: 'pl-tooltip-container',
  template: ''
})
export class TooltipContainerComponent {

  constructor(private _viewContainerRef: ViewContainerRef) {}

  get viewContainerRef() {
    return this._viewContainerRef;
  }

}
