import { ApplicationRef, Injectable, ViewContainerRef } from '@angular/core';
import { TooltipContainerComponent } from './tooltip-container.component';

@Injectable()
export class TooltipContainerService {
  private _viewContainerRef: ViewContainerRef;

  constructor(private appRef: ApplicationRef) {}

  public get viewContainerRef(): ViewContainerRef {
    if (!this._viewContainerRef) {
      this.initViewContainerRef();
    }

    return this._viewContainerRef;
  }

  private initViewContainerRef() {
    let tooltipContainerComponent = this.appRef.components.find(component => component.componentType === TooltipContainerComponent);

    if (!tooltipContainerComponent) {
      try {
        tooltipContainerComponent = this.appRef.bootstrap(TooltipContainerComponent);
      } catch (error) {
        console.error(error);
      }
    }

    this._viewContainerRef = tooltipContainerComponent?.instance.viewContainerRef;
  }
}
