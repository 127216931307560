import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { EmployeeModel, GroupModel, LicenseModel, LicenseModelData } from '@models';
import {
  upsertGroupQualifications as upsertGroupQualifications,
  createBulkShiftGroupQualifications,
  deleteShiftGroupQualifications,
  loadGroup,
  loadGroupEmployees,
  loadGroupWithShifts,
  loadGroups,
  loadMyGroups,
  saveRoleAssignmentsForGroup,
  loadShiftsForGroup,
  loadGroupQualifications,
  updateGroup
} from './group.queries';
import { Observable } from 'rxjs';
import { norwegianTextComparer } from '@helpers';
import { ShiftGroupQualificationCreateData } from '@interfaces';
import { GroupQualificationCreateData } from '@shared/interfaces/group-qualification.interface';
import { ShiftGroupQualificationModel } from '@model/companies/shift-group-qualification.model';
import { SkillModel, SkillModelData } from '@model/profile/skill.model';
import { UnitEndorsementModel, UnitEndorsementModelData } from '@model/profile/unit-endorsement.model';

@Injectable({
  providedIn: 'root'
})
export class GroupService {
  public data;

  constructor(private apollo: Apollo) {}

  public loadGroups(): Observable<GroupModel[]> {
    return this.apollo
      .query<any>({ query: loadGroups })
      .pipe(map(response => response.data.groups.map(element => new GroupModel(element)).sort((a, b) => norwegianTextComparer(a.name, b.name, true))));
  }

  public loadGroup(id: number): Observable<GroupModel> {
    return this.apollo
      .query<any>({
        query: loadGroup,
        variables: { id }
      })
      .pipe(map(response => new GroupModel(response.data.group)));
  }

  public loadGroupWithShifts(id: number): Observable<GroupModel> {
    return this.apollo
      .query<any>({
        query: loadGroupWithShifts,
        variables: { groupId: id }
      })
      .pipe(map(response => new GroupModel(response.data.group)));
  }

  public loadShiftsForGroup(id: number): Observable<ShiftGroupQualificationModel[]> {
    return this.apollo
      .query<any>({
        query: loadShiftsForGroup,
        variables: { groupId: id }
      })
      .pipe(
        map(response => {
          const result = [];
          const qualificationsArray: [] = response.data.group.shiftGroupQualifications;
          for (const qualification of qualificationsArray) {
            const model = new ShiftGroupQualificationModel(qualification);
            result.push(model);
          }
          return result;
        })
      );
  }

  public loadMyGroups(): Observable<GroupModel[]> {
    return this.apollo
      .query<any>({ query: loadMyGroups })
      .pipe(
        map(response =>
          response.data.myGroupsAsResourcePlanner.map(element => new GroupModel(element)).sort((a, b) => norwegianTextComparer(a.name, b.name, true))
        )
      );
  }

  public loadGroupEmployees(groupId: number): Observable<EmployeeModel[]> {
    return this.apollo
      .query<any>({
        query: loadGroupEmployees,
        variables: { groupId }
      })
      .pipe(map(response => response.data.groupEmployees.map(employee => new EmployeeModel(employee))));
  }

  public loadGroupQualifications(groupId: number): Observable<{ skills: SkillModel[]; licenses: LicenseModel[]; unitEndorsements: UnitEndorsementModel[] }> {
    return this.apollo
      .query<{ group: { skills: SkillModelData[]; licenses: LicenseModelData[]; unitEndorsements: UnitEndorsementModelData[] } }>({
        query: loadGroupQualifications,
        variables: { groupId }
      })
      .pipe(
        map(response => {
          const skills = response.data.group.skills.map(skill => new SkillModel(skill));
          const licenses = response.data.group.licenses.map(license => new LicenseModel(license));
          const unitEndorsements = response.data.group.unitEndorsements.map(endorsement => new UnitEndorsementModel(endorsement));
          return { skills, licenses, unitEndorsements };
        })
      );
  }

  public saveRoleAssignmentsForGroup(groupId: number, employeeRoleIds: number[]) {
    return this.apollo
      .query<any>({
        query: saveRoleAssignmentsForGroup,
        variables: { groupId, employeeRoleIds }
      })
      .pipe(map(response => response.data.saveRoleAssignmentsForGroup));
  }

  public createShiftGroupQualifications(data: ShiftGroupQualificationCreateData[]): Observable<number[]> {
    return this.apollo
      .mutate<{ createBulkShiftGroupQualification: { id: number }[] }>({
        mutation: createBulkShiftGroupQualifications,
        variables: { data }
      })
      .pipe(map(response => response.data.createBulkShiftGroupQualification.map(item => item.id)));
  }

  public upsertGroupQualifications(data: GroupQualificationCreateData): Observable<number> {
    return this.apollo
      .mutate<{ upsertGroupQualifications: { id: number } }>({
        mutation: upsertGroupQualifications,
        variables: { data }
      })
      .pipe(map(response => response.data.upsertGroupQualifications.id));
  }

  public updateGroup(id: number, argusAutomaticOverwrite: boolean): Observable<number> {
    return this.apollo
      .mutate<{ updateGroup: { id: number; argusAutomaticOverwrite: boolean } }>({
        mutation: updateGroup,
        variables: { id, argusAutomaticOverwrite }
      })
      .pipe(map(response => response.data.updateGroup.id));
  }

  public deleteShiftGroupQualifications(ids: number[]): Observable<number> {
    return this.apollo
      .mutate<{ deleteShiftGroupQualifications: number }>({
        mutation: deleteShiftGroupQualifications,
        variables: { ids }
      })
      .pipe(map(response => response.data.deleteShiftGroupQualifications));
  }
}
