import { ComponentFactory, ComponentFactoryResolver, ComponentRef, Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { LoadingComponent } from '@common/components';

@Directive({
  selector: '[shLoading]'
})
export class LoadingDirective {
  loadingFactory: ComponentFactory<LoadingComponent>;
  loadingComponent: ComponentRef<LoadingComponent>;

  @Input()
  set shLoading(isLoading: boolean) {
    this.clearTemplate();

    if (isLoading) {
      this.showLoading();
    }
    else {
      this.showComponent();
    }
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {
    this.loadingFactory = this.componentFactoryResolver.resolveComponentFactory(LoadingComponent);
  }

  private showLoading() {
    this.loadingComponent = this.viewContainerRef.createComponent(this.loadingFactory);
  }

  private showComponent() {
    this.viewContainerRef.createEmbeddedView(this.templateRef);
  }

  private clearTemplate() {
    this.viewContainerRef.clear();
  }
}
