import { Injectable } from '@angular/core';
import { LoggingService } from '@services';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { createConstellationTemplateMutation, deleteConstellationTemplateMutation, fetchMyConstellationsQuery } from '../../queries/constellations.query';
import { ConstellationTemplate } from '@model/masterplans/constellation-template.model';

@Injectable({ providedIn: 'root' })
export class ConstellationService {
  constructor(
    private apollo: Apollo,
    private logging: LoggingService
  ) {}

  public createConstellationTemplate(data: { name: string; shiftCodes: string[] }): Observable<ConstellationTemplate> {
    return this.apollo
      .mutate<any>({
        mutation: createConstellationTemplateMutation,
        variables: {
          name: data.name,
          shiftCodes: data.shiftCodes
        }
      })
      .pipe(map(result => new ConstellationTemplate(result.data.createConstellationTemplate)));
  }

  public deleteConstellationTemplate(id: number): Observable<number> {
    return this.apollo
      .mutate<any>({
        mutation: deleteConstellationTemplateMutation,
        variables: {
          id
        }
      })
      .pipe(map(result => result.data.deleteConstellationTemplate));
  }

  public fetchMyConstellations(): Observable<ConstellationTemplate[]> {
    return this.apollo
      .query<any>({
        query: fetchMyConstellationsQuery
      })
      .pipe(map(result => result.data.myConstellationTemplates.map(constellation => new ConstellationTemplate(constellation))));
  }
}
