import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ErrorPageComponent } from '@component/error-page/error-page.component';

@Component({
  selector: 'app-error-custom-message',
  templateUrl: './error-custom-message.component.html',
})
export class ErrorCustomMessageComponent extends ErrorPageComponent {
  public message: string;

  constructor(route: ActivatedRoute) {
    super();
    route.params.subscribe(params => {
      // Old code seems to be by mistake expects, that params.message should be already encoded by btoa().
      // And then decoding params.message by atob() function here would work (would make some sense).
      // So far neither in Frontend nor in Backend no code which would encode message and pass here to decode.
      // this.message = atob(params.message);
      this.message = params.message;
    });
  }
}

