import { gql } from 'apollo-angular';

export const createComment = gql`
  mutation createComment($typeId: Int!, $correspondingDay: String!, $correspondingEmployeeId: Int!, $message: String!, $color: String, $scheduleId: ID) {
    createComment(
      typeId: $typeId
      correspondingDay: $correspondingDay
      correspondingEmployeeId: $correspondingEmployeeId
      message: $message
      color: $color
      scheduleId: $scheduleId
    ) {
      id
      message
      color
      correspondingDay
      correspondingEmployee {
        id
      }
      type {
        id
      }
      createdAt
      createdBy {
        id
        firstName
        middleName
        lastName
      }
    }
  }
`;

export const updateComment = gql`
  mutation updateComment(
    $id: ID!
    $typeId: Int!
    $correspondingDay: String!
    $correspondingEmployeeId: Int!
    $message: String!
    $color: String
    $scheduleId: ID
  ) {
    updateComment(
      id: $id
      typeId: $typeId
      correspondingDay: $correspondingDay
      correspondingEmployeeId: $correspondingEmployeeId
      message: $message
      color: $color
      scheduleId: $scheduleId
    ) {
      id
      message
      color
      correspondingDay
      correspondingEmployee {
        id
      }
      type {
        id
      }
      createdAt
      createdBy {
        id
        firstName
        middleName
        lastName
      }
    }
  }
`;

export const deleteComment = gql`
  mutation deleteComment($id: ID!) {
    deleteComment(id: $id) {
      id
    }
  }
`;

export const loadCommentsQuery = gql`
  query loadComments($correspondingEmployeeId: Int!, $typeId: Int!, $dateFrom: String, $dateTo: String) {
    comments(correspondingEmployeeId: $correspondingEmployeeId, typeId: $typeId, dateFrom: $dateFrom, dateTo: $dateTo) {
      id
      message
      color
      createdAt
      correspondingDay
      correspondingEmployee {
        id
      }
      type {
        id
      }
      createdAt
      createdBy {
        id
        firstName
        middleName
        lastName
      }
      isRead
    }
  }
`;

export const loadCommentsForScheduleQuery = gql`
  query loadCommentsForSchedule($scheduleId: ID!) {
    commentsForSchedule(scheduleId: $scheduleId) {
      id
      message
      createdAt
      correspondingDay
      correspondingEmployee {
        id
      }
      type {
        id
      }
      createdAt
      createdBy {
        id
        firstName
        middleName
        lastName
      }
      isRead
    }
  }
`;
