import { BaseModel } from '../base.model';
import { EmployeeModel } from '../auth';
import { MasterplanModel } from '../schedules/masterplan.model';
import { UnitModel } from './index';
import { ManualShiftModel } from '@model/rostr';
import { SkillModel } from '@model/profile/skill.model';
import { LicenseModel } from '@shared/index';
import { UnitEndorsementModel } from '@model/profile/unit-endorsement.model';

export class GroupModel extends BaseModel {
  public name: string;
  public description: string;
  public validTo: string;
  public validFrom: string;
  public employees: EmployeeModel[];
  public masterplans: MasterplanModel[];
  public unit: UnitModel;
  public type = 'groups';
  public typeSingular = 'group';
  public shifts: ManualShiftModel[];
  public skills: SkillModel[];
  public licenses: LicenseModel[];
  public unitEndorsements: UnitEndorsementModel[];
  public argusAutomaticOverwrite: boolean;

  constructor(data: any = {}) {
    super(data);

    this.name = data.name || data.group?.name || '';
    this.description = data.description || '';
    this.validTo = data.validTo;
    this.validFrom = data.validFrom;
    this.unit = (data.unit && new UnitModel(data.unit)) || undefined;
    this.employees = (data.employees && data.employees.map(el => new EmployeeModel(el))) || [];
    this.masterplans = (data.masterplans && data.masterplans.map(el => new MasterplanModel(el))) || [];
    this.shifts = data.shiftCodes?.map(shift => new ManualShiftModel(shift)) || [];
    this.skills = data.skills?.map(skill => new SkillModel(skill)) || [];
    this.licenses = data.licenses?.map(license => new LicenseModel(license)) || [];
    this.unitEndorsements = data.unitEndorsements?.map(endorsement => new UnitEndorsementModel(endorsement)) || [];
    this.argusAutomaticOverwrite = data.argusAutomaticOverwrite || false;
  }

  get regularFullName() {
    return `${this.name} (${this.unit.abbreviation})`;
  }
}
