import { Apollo } from 'apollo-angular';
import { ApolloQueryResult } from '@apollo/client/core';
import { Injectable } from '@angular/core';
import { map, tap, catchError } from 'rxjs/operators';

import { ShiftAliasModel } from '@shared/models';

import { SCHEDULE_ADDITIONAL_DATA_TYPE } from '@constants';

import { bulkShiftAliasOperation, loadShiftAliases } from './shift-alias.queries';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../../../_auth/services/authentication.service';
import { ErrorService } from '../error/error.service';

@Injectable({
  providedIn: 'root'
})
export class ShiftAliasService {
  public data = [];

  constructor(
    private apollo: Apollo,
    private authService: AuthenticationService,
    private errorService: ErrorService
  ) {}

  public loadShiftAliases(): Observable<ShiftAliasModel[]> {
    return this.apollo
      .query<any>({
        query: loadShiftAliases,
        variables: {
          targetName: SCHEDULE_ADDITIONAL_DATA_TYPE.company,
          targetId: this.authService.user.group.unit.site.division.companyId,
          type: 'sector'
        }
      })
      .pipe(
        tap(response => {
          this.data = response.data.shiftAliases.map(element => new ShiftAliasModel(element));
        }),
        map(() => this.data),
        catchError(err => {
          return this.errorService.handle(err);
        })
      );
  }

  public fullUpdate(data: { created: ShiftAliasModel[]; updated: ShiftAliasModel[]; deleted: ShiftAliasModel[] }) {
    return this.apollo
      .mutate({
        mutation: bulkShiftAliasOperation,
        variables: {
          created: data.created.map(el => {
            return {
              start: el.start || 0,
              duration: el.duration || 0,
              alias: el.alias,
              value: el.value,
              active: el.active,
              targetId: this.authService.user.group.unit.site.division.companyId,
              targetName: SCHEDULE_ADDITIONAL_DATA_TYPE.company,
              type: el.type
            };
          }),
          updated: data.updated.map(el => {
            return {
              id: el.id,
              start: el.start || 0,
              duration: el.duration || 0,
              alias: el.alias,
              value: el.value,
              active: el.active,
              type: el.type
            };
          }),
          deleted: data.deleted.map(el => el.id)
        }
      })
      .pipe(
        tap((response: ApolloQueryResult<any>) => {
          const res = response.data.bulkShiftAliasOperation;

          this.data = [...this.data.filter(v => v.id !== null)];
          this.data = [...this.data, ...res.created.map(el => new ShiftAliasModel(el))];
        }),
        map((response: ApolloQueryResult<any>) => response.data.bulkShiftAliasOperation)
      );
  }
}
