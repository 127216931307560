import { gql } from 'apollo-angular';

export const notificationsQuery = gql`
  query notifications($limit: Int!) {
    notifications(limit: $limit) {
      id
      createdAt
      isRead
      severity
      title
      content
      source
      popupType
      link
    }
  }
`;

export const markNotificationAsRead = gql`
  mutation markNotificationsAsRead {
    markNotificationsAsRead {
      id
    }
  }
`;

export const publishMaintenanceNotification = gql`
  mutation publishMaintenanceNotification($title: String!, $content: String!) {
    publishMaintenanceNotification(title: $title, content: $content) {
      id
    }
  }
`;

export const notificationCreatedSubscription = gql`
  subscription notificationCreated {
    notificationCreated {
      id
      createdAt
      isRead
      severity
      title
      content
      source
      popupType
      link
    }
  }
`;
