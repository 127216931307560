import { Component, Input,  Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})

export class ButtonComponent {
  @Input() value: string;
  @Input() class: string;
  @Input() ariaLabel: string;
  @Input() disabled: boolean;
  @Input() circleShape = false;
  @Input() type: string;
  @Output() onclickEvent: EventEmitter<any> = new EventEmitter();
}
