import { RADIX_HEX } from '@constants';

export class RandomColor {

  /************************************************** HSV settings ****************************************************/
  private saturation = 0.7;
  private value = 0.90;

  /************************************************** Properties ******************************************************/
  private goldenRatioValue = 0.618033988749895;
  private hue = Math.random(); // no-sonar

  /**
   *
   * @param hex
   * @param saturation
   * @param value
   * @returns {any}
   */
  public goldenRatio() {
    const hue = (this.hue + this.goldenRatioValue) % 1;
    this.hue = hue;
    return this.hex(hue);
  }

  /**
   * Color generation using halton sequence
   * @param index
   * @param base
   * @returns {string}
   */
  public halton(index: number, base: number) {
    const hue = RandomColor.halton(index, base);
    return this.hex(hue);
  }

  /**
   *
   * @param hue
   * @returns {string}
   */
  private hex(hue: number) {
    const s = this.saturation;
    const v = this.value;
    return ['#'].concat(this.hsvToRgb(hue, s, v).map((val) => this.padHex(val.toString(16)))).join('');
  }

  /*********************************** helpers ************************************************************************/
  /**
   * Conver hsv to rgb
   * @param h
   * @param s
   * @param v
   * @returns {[number,number,number]}
   */
  private hsvToRgb(h, s, v) {
    const hI = Math.floor(h * 6);
    const f = h * 6 - hI;
    const p = v * (1 - s);
    const q = v * (1 - f * s);
    const t = v * (1 - (1 - f) * s);
    let r = 255;
    let g = 255;
    let b = 255;
    switch (hI) {
      case 0:
        r = v, g = t, b = p;
        break;
      case 1:
        r = q, g = v, b = p;
        break;
      case 2:
        r = p, g = v, b = t;
        break;
      case 3:
        r = p, g = q, b = v;
        break;
      case 4:
        r = t, g = p, b = v;
        break;
      case 5:
        r = v, g = p, b = q;
        break;
    }
    return [Math.floor(r * 256), Math.floor(g * 256), Math.floor(b * 256)];
  }

  /**
   *
   * @param str
   * @returns {string}
   */
  private padHex(str: string) {
    if (str.length >= 2) {
      return str.slice(0, 2);
    }
    return new Array(2 - str.length + 1).join('0') + str;
  }

  /**
   * White or black text for color ?
   * @param hex
   * @returns {boolean}
   */
  static white(hex: string) {
    const rgb = [parseInt(hex.slice(1, 3), RADIX_HEX), parseInt(hex.slice(3, 5), RADIX_HEX), parseInt(hex.slice(5, 7), RADIX_HEX)];
    return Math.round(((rgb[0] * 299) + (rgb[1] * 587) + (rgb[2] * 114)) / 1000) <= 125;
  }

  /******************************************* Quasi random engines *************************************

   /**
   * Halton sequence generator
   * @param index
   * @param base
   * @returns {number}
   */
  static halton(index, base) {
    let result = 0;
    let frac = 1 / base;
    let i = index + 1;

    while (i > 0) {
      result += frac * (i % base);
      i = Math.floor(i / base);
      frac = frac / base;
    }

    return result;
  }

  static shadeBlendConvert(p, c0, c1?) {
    const n = p < 0 ? p * -1 : p;
    const  u = Math.round;
    const  w = parseInt;
    if (c0.length > 7) {
      const f = c0.split(',');
      const  t = (c1 ? c1 : p < 0 ? 'rgb(0,0,0)' : 'rgb(255,255,255)').split(',');
      const  R = w(f[0].slice(4));
      const  G = w(f[1]);
      const  B = w(f[2]);
      return `rgb(${(u((w(t[0].slice(4)) - R) * n) + R)},${(u((w(t[1]) - G) * n) + G)},${(u((w(t[2]) - B) * n) + B)})`;
    } else {
      const f = w(c0.slice(1), 16);
      const t = w((c1 ? c1 : p < 0 ? '#000000' : '#FFFFFF').slice(1), 16);
      const R1 = f >> 16;
      const G1 = f >> 8 & 0x00FF;
      const B1 = f & 0x0000FF;
// eslint-disable-next-line max-len
      return `#${(0x1000000 + (u(((t >> 16) - R1) * n) + R1) * 0x10000 + (u(((t >> 8 & 0x00FF) - G1) * n) + G1) * 0x100 + (u(((t & 0x0000FF) - B1) * n) + B1)).toString(16).slice(1)}`;
    }
  }
}
