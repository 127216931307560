import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ElementRef, OnChanges } from '@angular/core';
import { AnimationTriggerMetadata, animate, style, state, transition, trigger } from '@angular/animations';

import { TooltipComponent } from '@component/tooltip';
import { WidgetService } from './widget.service';

const triggerData1: AnimationTriggerMetadata = trigger('minimize', [
  // Note. `overflow` is non-animateable. Using `visibility` would be also OK, but it's kinda does have bad performace.
  // So the best option here is to animate `opacity`.
  state('1', style({ height: 0, opacity: 0 })),
  state('0', style({ height: '*', opacity: 1 })),

  transition('* => *', animate('300ms ease-in-out'))
]);

@Component({
  selector: 'pl-widget',
  templateUrl: './widget.component.html',
  styleUrls: ['./widget.component.scss'],
  providers: [WidgetService],
  animations: [triggerData1]
})
export class WidgetComponent implements OnInit, OnChanges {
  @Input() heading: string;
  @Input() noEditOption: boolean;
  @Input() editMode: boolean;
  @Input() additionalActions = [];
  @Input() disableSaveBtn = false;
  @Output() editModeChanged = new EventEmitter();
  @Output() stateChanged = new EventEmitter();
  @Output() widgetSave = new EventEmitter();
  @Output() widgetCancel = new EventEmitter();

  @ViewChild('actionMenu') public actionMenu: ElementRef;
  @ViewChild('actionMenuTooltip', { static: true }) public actionMenuTooltip: TooltipComponent;

  public actionsForMenu;

  public state = 'menu';
  public widgetActionMenu: any;

  constructor(private _widgetService: WidgetService) {}

  public ngOnInit() {
    // configs for tooltip
    this.widgetActionMenu = {
      placement: 'left',
      width: 180
    };

    this.actionsForMenu = [
      { icon: 'mode_edit', name: 'Edit', action: () => this.edit() },
      { icon: 'vertical_align_center', name: 'Minimize', action: () => this.changeState('minimize') },
      ...this.additionalActions
    ];

    if (this.noEditOption) {
      this.actionsForMenu.shift();
    }
  }

  public toggle(action) {
    if (action.type === 'toggle') {
      action.activeAction = action.activeAction === 0 ? 1 : 0;
    }
  }

  public ngOnChanges(changes) {
    if (changes.editMode && changes.editMode.currentValue !== changes.editMode.previousValue) {
      if (changes.editMode.currentValue) {
        this.edit();
      }
      if (!(changes.editMode.previousValue instanceof Object) && changes.editMode.previousValue && !changes.editMode.currentValue) {
        this.changeState('menu');
      }
    }
  }

  public selectAction(fn: () => void) {
    this.actionMenuTooltip.close();
    fn();
  }

  public openMenu() {
    this.widgetActionMenu.targetElement = this.actionMenu.nativeElement.getBoundingClientRect();
    this.actionMenuTooltip.open();
  }

  public edit(): any {
    this.editModeChanged.emit(true);
    this._widgetService.editAction = WidgetService.actions.EDIT;
    this.changeState('edit');
  }

  public save(): any {
    this.editModeChanged.emit(false);
    this._widgetService.editAction = WidgetService.actions.SAVE;
    this.widgetSave.emit(true);
    this.changeState('menu');
  }

  public cancel(): any {
    this.editModeChanged.emit(false);
    this._widgetService.editAction = WidgetService.actions.CANCEL;
    this.widgetCancel.emit(true);
    this.changeState('menu');
  }

  public changeState(latestState: string): any {
    this.state = latestState;
    this.stateChanged.emit(this.state);
    if (this.actionMenuTooltip) {
      this.actionMenuTooltip.close();
    }
  }

  public checkState(latestState: string) {
    return this.state === latestState;
  }
}
