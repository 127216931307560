import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validators } from '@angular/forms';
import { MatCalendarCellClassFunction, MatDateRangePicker } from '@angular/material/datepicker';
import { getWeekNumberClasses } from '@common/components/datepickers/datepicker-utils';
import { dateBelongsToRange } from '@helpers';
import { DateRange } from '@shared/interfaces/date-range.interface';
import { Subscription } from 'rxjs';
import { auditTime } from 'rxjs/operators';

interface FormDatesInterface {
  dateFrom: FormControl<Date>;
  dateTo: FormControl<Date>;
}

@Component({
  selector: 'app-range-datepicker',
  templateUrl: './range-datepicker.component.html',
  styleUrls: ['./range-datepicker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => RangeDatepickerComponent)
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => RangeDatepickerComponent),
      multi: true
    }
  ],
  encapsulation: ViewEncapsulation.None
})
export class RangeDatepickerComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @Input() label = 'Date range';

  @Input() alreadySelectedRanges: DateRange[] = [];

  @Input() showClearButton = false;

  @Input() dateFilter: (d: Date | null) => boolean = null;

  @Input() customMessage = '';

  @Input() startAt: Date | null = null;

  @Output() valueUpdated = new EventEmitter<DateRange>();

  @ViewChild('picker')
  public picker: MatDateRangePicker<Date>;

  public required = false;

  private _value: DateRange = {
    dateFrom: null,
    dateTo: null
  };

  set value(value: DateRange) {
    this._value = value;
    this.datesFormGroup.patchValue(value);
    this.onChange(value);
    this.onValidatorChange();
  }

  get value() {
    return this._value;
  }

  public errors: ValidationErrors = {};

  public datesFormGroup: FormGroup<FormDatesInterface>;

  public disabled = false;
  private onChange = (value: DateRange) => {};
  public onTouched = () => {};
  private onValidatorChange = () => {};

  private onChangeSub: Subscription;

  constructor(private changeDetector: ChangeDetectorRef) {}

  validate(control: AbstractControl<any, any>): ValidationErrors {
    this.required = control.hasValidator(Validators.required);
    this.errors = control.errors;
    this.datesFormGroup.setErrors(control.errors);
    this.datesFormGroup.controls.dateFrom.setErrors(control.errors);
    this.datesFormGroup.controls.dateTo.setErrors(control.errors);
    this.changeDetector.markForCheck();

    return control.errors;
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.onValidatorChange = fn;
  }

  ngOnInit() {
    this.datesFormGroup = new FormGroup({
      dateFrom: new FormControl(this.value.dateFrom),
      dateTo: new FormControl(this.value.dateTo)
    });

    this.onChangeSub = this.datesFormGroup.valueChanges.pipe(auditTime(0)).subscribe(dates => {
      this._value = dates;
      this.onChange(dates);
      this.valueUpdated.emit(dates);
    });
  }

  ngOnDestroy(): void {
    this.onChangeSub?.unsubscribe();
  }

  writeValue(value: DateRange): void {
    this.value = value;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    if (isDisabled) {
      this.datesFormGroup.disable();
    } else {
      this.datesFormGroup.enable();
    }
  }

  dateClass: MatCalendarCellClassFunction<Date> = (cellDate, view) => {
    const classes = getWeekNumberClasses(cellDate);

    if (view === 'month') {
      const isUnavailable = this.alreadySelectedRanges.some(range => dateBelongsToRange(cellDate, range.dateFrom, range.dateTo));
      if (isUnavailable) {
        classes.push('unavailable');
        classes.push('mat-calendar-body-in-comparison-range');
      }
    }
    return classes;
  };

  clear() {
    this.datesFormGroup.reset();
    this.picker.close();
  }
}
