import { Apollo } from 'apollo-angular';
import { ApolloQueryResult } from '@apollo/client/core';
import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { SiteModel } from '@shared/models/companies/site.model';
import {
  createSite,
  createSiteDivisionRelation,
  deleteSite,
  deleteSiteDivisionRelation,
  loadSite,
  loadSites,
  updateSite
} from './site.queries';
import { IBaseCrudService } from '@interfaces';

@Injectable({
  providedIn: 'root'
})
export class SiteService implements IBaseCrudService<SiteModel> {
  private data: SiteModel[] = [];

  constructor(private apollo: Apollo) { }

  public load() {
    return this.apollo.query<any>({ query: loadSites }).pipe(
      map(response => {
        return response.data.sites.map(element => new SiteModel(element));
      }),
      tap(mapped => {
        this.data = mapped;
      })
    );
  }

  public loadSite(id: number) {
    return this.apollo
      .query<any>({
        query: loadSite,
        variables: { id }
      })
      .pipe(map(response => new SiteModel(response.data.site)));
  }

  public create(data: SiteModel) {
    return this.apollo
      .mutate({
        mutation: createSite,
        variables: {
          name: data.name
        }
      }).pipe(
        map((response: ApolloQueryResult<any>) => new SiteModel(response.data.createSite)),
        tap((model: SiteModel) => {
          this.data = [...this.data, model];
        })
      );
  }

  public update(data: SiteModel) {
    return this.apollo
      .mutate({
        mutation: updateSite,
        variables: {
          id: data.id,
          name: data.name
        }
      }).pipe(
        map((response: ApolloQueryResult<any>) => new SiteModel(response.data.updateSite)),
        tap((model: SiteModel) => {
          const site = this.data.find(el => el.id === model.id);
          Object.assign(site, model);
        })
      );
  }

  public delete(data: SiteModel) {
    return this.apollo
      .mutate({
        mutation: deleteSite,
        variables: {
          id: data.id
        }
      }).pipe(
        tap((response: ApolloQueryResult<any>) => {
          const res = response.data.deleteSite;
          this.data = [...this.data.filter(el => el.id !== res.id)];
        }),
        map(response => response.data.deleteSite)
      );
  }

  public addDivisionRelation(site: number, division: number) {
    return this.apollo
      .mutate({
        mutation: createSiteDivisionRelation,
        variables: {
          siteId: site,
          divisionId: division
        }
      })
      .subscribe(() => {
        const elem = this.data.find(el => el.id === site);
        elem.divisionId = division;
      });
  }

  public removeDivisionRelation(site: number) {
    return this.apollo
      .mutate({
        mutation: deleteSiteDivisionRelation,
        variables: {
          siteId: site
        }
      })
      .subscribe(() => {
        const elem = this.data.find(el => el.id === site);
        elem.divisionId = null;
      });
  }

  public updateDivisionRelation(id: number, divisionId: number, isSelected: boolean) {
    if (isSelected) {
      this.addDivisionRelation(id, divisionId);
    } else {
      this.removeDivisionRelation(id);
    }
  }
}
