import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { myPreferences, updateMyPreferencesMutation } from '../queries';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PreferenceModel } from '@models';

@Injectable({
  providedIn: 'root'
})
export class MyPreferencesService {
  constructor(private readonly apollo: Apollo) {}

  fetchMyPreferences(): Observable<PreferenceModel> {
    return this.apollo
      .query<{ myPreferences: PreferenceModel }>({
        query: myPreferences
      })
      .pipe(map(result => result.data.myPreferences));
  }

  updateMyPreferences(data: { startWorktimeMin: number; startWorktimeMax: number; afterShiftBreak: number }): Observable<PreferenceModel> {
    return this.apollo
      .mutate<{ updateMyPreferences: PreferenceModel }>({
        mutation: updateMyPreferencesMutation,
        variables: data
      })
      .pipe(map(result => result.data.updateMyPreferences));
  }
}
