<div *ngIf="!loading; else loadingContent" class="wrapper">
  <div class="widget-title">
    <div>
      <div>Employee Editor</div>
    </div>
  </div>

  <div class="busy-overlay" *ngIf="isBusy"></div>

  <ng-container [formGroup]="employeeForm">
    <div class="active-container">
      <mat-checkbox formControlName="active">Active</mat-checkbox>
    </div>
    <mat-accordion multi>
      <mat-expansion-panel [expanded]="false">
        <mat-expansion-panel-header>
          <mat-panel-title> <mat-icon>face</mat-icon> Personal information </mat-panel-title>
        </mat-expansion-panel-header>

        <mat-form-field appearance="fill" floatLabel="always">
          <mat-label>Username</mat-label>
          <input matInput formControlName="userName" />
          <mat-icon matSuffix>person_add</mat-icon>
        </mat-form-field>

        <mat-form-field appearance="fill" floatLabel="always">
          <mat-label>First name</mat-label>
          <input matInput formControlName="firstName" />
          <mat-icon matSuffix>person</mat-icon>
        </mat-form-field>

        <mat-form-field appearance="fill" floatLabel="always">
          <mat-label>Middle name</mat-label>
          <input matInput formControlName="middleName" />
          <mat-icon matSuffix>person_outline</mat-icon>
        </mat-form-field>

        <mat-form-field appearance="fill" floatLabel="always">
          <mat-label>Last name</mat-label>
          <input matInput formControlName="lastName" />
          <mat-icon matSuffix>people</mat-icon>
        </mat-form-field>

        <mat-form-field appearance="fill" floatLabel="always">
          <mat-label>Personal presentation</mat-label>
          <textarea matInput formControlName="personalPresentation"></textarea>
        </mat-form-field>
      </mat-expansion-panel>

      <mat-expansion-panel [expanded]="false">
        <mat-expansion-panel-header>
          <mat-panel-title> <mat-icon>contact_mail</mat-icon> Contact info </mat-panel-title>
        </mat-expansion-panel-header>

        <mat-form-field appearance="fill" floatLabel="always">
          <mat-label>Work phone</mat-label>
          <input matInput formControlName="workPhone" />
          <mat-icon matSuffix>phone</mat-icon>
        </mat-form-field>

        <mat-form-field appearance="fill" floatLabel="always">
          <mat-label>Mobile phone</mat-label>
          <input matInput formControlName="mobilePhone" />
          <mat-icon matSuffix>stay_current_portrait</mat-icon>
        </mat-form-field>

        <mat-form-field appearance="fill" floatLabel="always">
          <mat-label>Work email</mat-label>
          <input matInput formControlName="workEmail" />
          <mat-icon matSuffix>email</mat-icon>
        </mat-form-field>

        <mat-form-field appearance="fill" floatLabel="always">
          <mat-label>Personal email</mat-label>
          <input matInput formControlName="personalEmail" />
          <mat-icon matSuffix>mail_outline</mat-icon>
        </mat-form-field>
      </mat-expansion-panel>

      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title> <mat-icon>filter_frames</mat-icon> Skills </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="d-flex align-center skills-container">
          <mat-form-field appearance="fill">
            <mat-label>Skills</mat-label>
            <mat-select formControlName="selectedSkills">
              <mat-option *ngFor="let skill of skillList" [value]="skill.id">{{ skill.title }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-icon fontIcon="add" [class.disabled]="!employeeForm.value.selectedSkills" (click)="addSkill()"></mat-icon>
        </div>

        <form [formGroup]="skillsForm">
          <fieldset [class.invalid]="skillsForm.controls[item.key].invalid" *ngFor="let item of skillsForm.controls | keyvalue: keyOrder">
            <legend>{{ getSkillName(item.key) }}</legend>
            <ng-container [formArrayName]="item.key">
              <div class="range-container" [class.new]="!range.value.id" *ngFor="let range of skillsForm.controls[item.key].controls; let i = index">
                <ng-container *ngIf="pastDateFilter(range.value.dates.dateFrom)">
                  <app-range-datepicker
                    (valueUpdated)="skillsChanged(item.key)"
                    [formControl]="range.controls.dates"
                    [alreadySelectedRanges]="getUnavailableRangesForRange(item.key, i)"
                    [dateFilter]="pastDateFilter"
                  ></app-range-datepicker>
                  <mat-icon fontIcon="delete" (click)="removeSkillRange(item.key, i)" *ngIf="!range.disabled"></mat-icon>
                </ng-container>
                <app-range-datepicker-end-date
                  *ngIf="!pastDateFilter(range.value.dates.dateFrom)"
                  (valueUpdated)="skillsChanged(item.key)"
                  [formControl]="range.controls.dates"
                  [alreadySelectedRanges]="getUnavailableRangesForRange(item.key, i)"
                  [dateFilter]="pastDateFilter"
                  customMessage="You can only select end date, because this skill assignment has already started."
                ></app-range-datepicker-end-date>
              </div>
            </ng-container>
            <mat-icon fontIcon="add" (click)="addNewSkillRange(item.key)"></mat-icon>
          </fieldset>
        </form>
      </mat-expansion-panel>

      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title> <mat-icon>list_alt</mat-icon> Qualifications </mat-panel-title>
        </mat-expansion-panel-header>

        <mat-form-field appearance="fill">
          <mat-label>Licenses</mat-label>
          <mat-select formControlName="licenses" multiple>
            <mat-option *ngFor="let license of licensesList" [value]="license">{{ license.title }}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Endorsements</mat-label>
          <mat-select formControlName="endorsements" multiple>
            <mat-option *ngFor="let endorsement of endorsementsList" [value]="endorsement">{{ endorsement.title }}</mat-option>
          </mat-select>
        </mat-form-field>
      </mat-expansion-panel>

      <mat-expansion-panel [expanded]="false">
        <mat-expansion-panel-header>
          <mat-panel-title> <mat-icon>schedule</mat-icon> Work percentage </mat-panel-title>
        </mat-expansion-panel-header>

        <ng-container formArrayName="workPercentages">
          <ng-container *ngFor="let workPercentage of workPercentages.controls; let i = index">
            <div [formGroup]="workPercentage" class="percentages">
              <div class="date-from">
                <label for="dateFrom">Date From</label>
                <app-date-picker formControlName="dateFrom"></app-date-picker>
              </div>
              <div class="value">
                <mat-form-field appearance="fill" floatLabel="always">
                  <mat-label>Percentage</mat-label>
                  <input matInput type="number" formControlName="value" />
                </mat-form-field>
              </div>
              <div class="actions">
                <mat-icon fontIcon="delete" (click)="removeWorkPercentage(i)"></mat-icon>
              </div>
            </div>
          </ng-container>
        </ng-container>

        <mat-icon fontIcon="add" (click)="addWorkPercentage()" [class.disabled]="!isWorkPercentagesFormValid"></mat-icon>
      </mat-expansion-panel>

      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title> <mat-icon>assignment</mat-icon> Assignments </mat-panel-title>
        </mat-expansion-panel-header>

        <mat-form-field appearance="fill">
          <mat-label>Roles</mat-label>
          <mat-select formControlName="roles" multiple>
            <mat-option *ngFor="let role of roleList" [value]="role">{{ role.title }}</mat-option>
          </mat-select>
        </mat-form-field>

        <div *ngIf="!isBusy && (auth.userRoles.isSystemAdmin || auth.userRoles.isResourcePlanner)">
          <div *ngIf="canHaveAssignments()">Role group assignments:</div>
          <small *ngIf="!canHaveAssignments()">You can assign groups to roles {{ rolesWithAssignments.join(', ') }}.</small>
          <ng-container *ngFor="let role of rolesWithAssignments">
            <div class="mt-3" *ngIf="hasRole(role)">
              <app-autocomplete-wrapper
                [label]="role + ' assignments'"
                [items]="groupList"
                [formControl]="employeeForm.controls.roleAssignments.controls[role]"
                [displayWith]="groupDisplay"
                [multiple]="true"
              ></app-autocomplete-wrapper>
            </div>
          </ng-container>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title> <mat-icon>group</mat-icon> Group </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-form-field appearance="fill">
          <mat-label>Unit</mat-label>
          <mat-select formControlName="unit" (selectionChange)="unitSelected()">
            <mat-option *ngFor="let unit of unitList" [value]="unit">{{ unit }}</mat-option>
          </mat-select>
        </mat-form-field>

        <div class="groups-add-container">
          <mat-form-field appearance="fill">
            <mat-label>Group</mat-label>
            <mat-select formControlName="group">
              <mat-option *ngFor="let group of filteredGroupList" [value]="group.id">{{ group.name }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-icon fontIcon="add" [class.disabled]="!employeeForm.controls.group.valid" (click)="addGroup()"></mat-icon>
        </div>

        <mat-checkbox formControlName="showLegacyGroups">Show legacy</mat-checkbox>

        <form [formGroup]="groupsForm">
          <fieldset [class.invalid]="groupsForm.controls[item.key].invalid" *ngFor="let item of groupsForm.controls | keyvalue: keyOrder">
            <legend>{{ getGroupName(item.key) }}</legend>
            <ng-container [formArrayName]="item.key">
              <div [class.new]="!range.value.id" *ngFor="let range of groupsForm.controls[item.key].controls; let i = index">
                <div class="range-container" *ngIf="!range.value.dates.dateTo || pastDateFilter(range.value.dates.dateTo)">
                  <app-range-datepicker
                    (valueUpdated)="groupsChanged()"
                    [formControl]="range.controls.dates"
                    [alreadySelectedRanges]="getUnavailableRangesForGroup(range.controls.dates)"
                    [dateFilter]="pastDateFilter"
                  ></app-range-datepicker>
                  <mat-icon fontIcon="delete" (click)="removeGroupRange(item.key, i)"></mat-icon>
                </div>
              </div>
            </ng-container>
            <mat-icon fontIcon="add" (click)="addNewGroupRange(item.key)" [class.disabled]="!groupsForm.valid"></mat-icon>
          </fieldset>
        </form>
      </mat-expansion-panel>

      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title> <mat-icon>av_timer</mat-icon> Averaging periods </mat-panel-title>
        </mat-expansion-panel-header>

        <div><mat-checkbox formControlName="showLegacyPeriods">Show legacy</mat-checkbox></div>

        <div *ngFor="let item of averagingPeriods | keyvalue">
          <div class="mt-3">{{ getGroupName(item.key) }}</div>
          <div *ngFor="let period of item.value">
            <ng-container *ngIf="employeeForm?.controls.averagingPeriodOffsets?.controls[period.id]; let control">
              <mat-form-field appearance="fill" *ngIf="pastDateFilterPeriods(period.dateTo)">
                <mat-label>
                  <span> {{ period.dateFrom | date: 'dd/MM/yyyy' }} </span> - <span>{{ period.dateTo | date: 'dd/MM/yyyy' }}</span>
                </mat-label>
                <input matInput type="number" [formControl]="control" placeholder="Input offset for this period." />
                <span
                  matSuffix
                  *ngIf="statisticsForAveragingPeriods"
                  customTooltip="This is current assigned duration in this averaging period without provided offset."
                  [showDelay]="500"
                >
                  ({{ statisticsForAveragingPeriods[period.id]?.duration | humanizeDuration }})
                </span>
              </mat-form-field>
            </ng-container>
          </div>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title> <mat-icon>sentiment_very_satisfied</mat-icon> Preferences </mat-panel-title>
        </mat-expansion-panel-header>

        <div>
          <mat-checkbox formControlName="healthyRotation">Healthy rotation</mat-checkbox>
        </div>
        <div>
          <mat-checkbox formControlName="hardNightShiftOK">Work nights</mat-checkbox>
        </div>

        <mat-form-field appearance="fill" floatLabel="always">
          <mat-label> {{ preferences.preferencesLabels.hardWorkDurationMax }}</mat-label>
          <input matInput type="number" formControlName="hardWorkDurationMax" step="0.5" />
          <mat-hint>Must be between 9 and 12.5</mat-hint>
          <mat-error>Must be between 9 and 12.5</mat-error>
        </mat-form-field>

        <mat-form-field class="mt-3" appearance="fill" floatLabel="always">
          <mat-label> {{ preferences.preferencesLabels.hardWeeklyWorkMax }}</mat-label>
          <input matInput type="number" formControlName="hardWeeklyWorkMax" />
          <mat-hint>Must be between 0 and 54</mat-hint>
          <mat-error>Must be between 0 and 54</mat-error>
        </mat-form-field>

        <mat-form-field class="mt-3" appearance="fill" floatLabel="always">
          <mat-label> {{ preferences.preferencesLabels.standardWorkingHoursPerWeek }}</mat-label>
          <input matInput type="number" formControlName="standardWorkingHoursPerWeek" step="0.5" />
          <mat-hint>Must be between 0 and 100</mat-hint>
          <mat-error>Must be between 0 and 100</mat-error>
        </mat-form-field>

        <mat-form-field class="mt-3" appearance="fill" floatLabel="always">
          <mat-label> {{ preferences.preferencesLabels.workingDaysInRow }}</mat-label>
          <input matInput type="number" formControlName="workingDaysInRow" />
          <mat-hint>Must be between 0 and 100</mat-hint>
          <mat-error>Must be between 0 and 100</mat-error>
        </mat-form-field>

        <mat-form-field class="mt-3" appearance="fill" floatLabel="always">
          <mat-label> {{ preferences.preferencesLabels.hardAfterShiftBreak }}</mat-label>
          <input matInput type="number" formControlName="hardAfterShiftBreak" />
          <mat-hint>Must be between 8 and 11</mat-hint>
          <mat-error>Must be between 8 and 11</mat-error>
        </mat-form-field>

        <mat-form-field class="mt-3" appearance="fill" floatLabel="always">
          <mat-label>Minimum weekly break length</mat-label>
          <input type="number" required [min]="minWeeklyOff" [max]="maxWeeklyOff" matInput formControlName="hardConsecutiveWeeklyOff" />
          <mat-hint> Must be between {{ minWeeklyOff }} and {{ maxWeeklyOff }}</mat-hint>
          <mat-error> Must be between {{ minWeeklyOff }} and {{ maxWeeklyOff }} </mat-error>
        </mat-form-field>
      </mat-expansion-panel>
    </mat-accordion>
  </ng-container>
  <div class="footer">
    <button mat-raised-button color="primary" (click)="save()" [disabled]="!employeeForm.valid">Save</button>
    <button mat-raised-button (click)="cancel()">Cancel</button>
  </div>
</div>

<ng-template #loadingContent>
  <mat-spinner color="accent"></mat-spinner>
</ng-template>
