/*
Dialog to make sure of deletion etc
It takes header, body, icon if applicable as well as other text on buttons if passed in as a object with okBtn and cancelBtn as keys
*/

import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'confirm-delete-dialog',
  templateUrl: 'confirm-delete-dialog.component.html',
  styleUrls: ['./confirm-delete-dialog.component.scss']
})
export class ConfirmDeleteModalComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmDeleteModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    dialogRef.disableClose = data.config?.disableClose;
  }

  onNoClick(event): void {
    event.stopPropagation();
    this.dialogRef.close();
  }

  onOkClick(event): void {
    event.stopPropagation();
  }
}
