import { Injectable } from '@angular/core';
import { minutesToHours } from '@app/rostr/_shared';
import { chartBackgroundTransparencyLevel } from '@shared/helpers/charts.helper';
import Chart, { ChartConfiguration } from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(ChartDataLabels);

@Injectable({
  providedIn: 'root'
})
export class ChartsService {
  createBarChart(chartId: string, title: string, unit: string) {
    return new Chart(chartId, {
      type: 'bar',
      data: {
        labels: [0],
        datasets: [
          {
            label: `${title} [${unit}]`,
            indexAxis: 'y',
            data: [0],
            borderWidth: 1
          }
        ]
      },
      options: {
        indexAxis: 'y',
        scales: {
          x: {
            min: 0,
            max: 0
          }
        },
        animation: {
          duration: 0
        },
        plugins: {
          legend: {
            position: 'bottom'
          },
          datalabels: null
        }
      }
    });
  }

  plotBarChart(chart: Chart, actualValueInMinutes: number, minInMinutes: number, maxInMinutes: number, color: string) {
    chart.data.labels[0] = minutesToHours(actualValueInMinutes);
    chart.data.datasets[0].data[0] = actualValueInMinutes / 60;

    chart.data.datasets[0].backgroundColor = [`${color}${chartBackgroundTransparencyLevel}`];
    chart.data.datasets[0].borderColor = [color];

    chart.options.scales.x.min = minInMinutes / 60;
    chart.options.scales.x.max = maxInMinutes / 60;

    chart.update('none');
  }

  createDoughnutChart(chartId: string, title: string, labels: string[], colors: string[]) {
    return new Chart(chartId, {
      type: 'doughnut',
      data: {
        labels: labels,
        datasets: [
          {
            label: title,
            data: [],
            backgroundColor: colors,
            hoverOffset: 4
          }
        ]
      },
      options: {
        plugins: {
          legend: {
            position: 'bottom'
          },
          title: {
            display: true,
            text: title
          },
          animation: {
            duration: 0
          },
          datalabels: {
            anchor: 'center',
            backgroundColor: null,
            formatter: Math.round,
            color: '#444444',
            font: {
              size: 16
            },
            display(context) {
              return (context.dataset.data[context.dataIndex] as number) > 0;
            }
          }
        }
      }
    } as ChartConfiguration);
  }

  plotDoughnutChart(chart: Chart, data: number[]) {
    chart.data.datasets[0].data = data;
    chart.update('none');
  }
}
