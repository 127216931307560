import { AGENDA_ADMIN_DIVISION, RESOURCE_PLANNER, ROSTR_READ_ACCESS, UNION_REPRESENTATIVE } from '@constants';
import { gql } from 'apollo-angular';

export const loadEmployeeSkillsQuery = gql`
  query loadEmployeeSkills($id: Int!) {
    employeeSkills(id: $id) {
      id
      validFrom
      validTo
      skill {
        name
      }
    }
  }
`;

export const loadAllQuery = gql`
  query loadAllEmployees {
    fullEmployeeList {
      id
      username
      firstName
      middleName
      lastName
      active
      employeeGroups {
        validFrom
        validTo
        group {
          id
          name
          unit {
            id
            name
          }
        }
      }
      employeeSkills {
        id
        skill {
          id
          name
        }
      }
      group {
        id
        name
        unit {
          id
          abbreviation
          name
        }
      }
    }
  }
`;

export const loadWithNamesOnlyQuery = gql`
  query loadEmployeesWithNamesOnly {
    employees {
      id
      firstName
      middleName
      lastName
    }
  }
`;

export const loadWithNamesAndCurrentGroupQuery = gql`
  query loadEmployeesWithNamesAndCurrentGroup {
    employees {
      id
      firstName
      middleName
      lastName
      group {
        id
        name
      }
    }
  }
`;

export const loadByIdsQuery = gql`
  query loadEmployees($ids: [Int], $_deleted: Boolean) {
    employees(ids: $ids, _deleted: $_deleted) {
      id
      firstName
      middleName
      lastName
      deletedAt
      active
      group {
        id
        name
        unit {
          id
          abbreviation
          name
        }
      }
    }
  }
`;
export const loadEntitySettings = gql`
  query loadEntitySettings($keys: [Int!]) {
    settings(settingKeys: $keys) {
      key
      name
      defaultValue
      entitySettings {
        currentValue {
          dateFrom
          value
        }
        nextValue {
          dateFrom
          value
        }
        entityId
      }
    }
  }
`;

const employeeFragment = gql`
  fragment EmployeeFields on Employee {
    id
    firstName
    lastName
    employeeRoles {
      id
      employeeRoleGroups {
        groupId
      }
    }
  }
`;

export const employeesWithGroupAssignableRoles = gql`
  query EmployeesWithRole {
    resourcePlanner: employeesWithRole(role: "${RESOURCE_PLANNER}") {
      ...EmployeeFields
    }
    agendaAdmin: employeesWithRole(role: "${AGENDA_ADMIN_DIVISION}") {
      ...EmployeeFields
    }
    unionRepresentative: employeesWithRole(role: "${UNION_REPRESENTATIVE}") {
      ...EmployeeFields
    }
    rostrReadAccess: employeesWithRole(role: "${ROSTR_READ_ACCESS}") {
      ...EmployeeFields
    }
  }
  ${employeeFragment}
`;
