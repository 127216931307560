import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AssignedActivity } from '../dto';
import { ConcurrentUserActionData } from '../dto/concurrent-user-action-data';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EmployeeColumnInvalidationService {
  private readonly employeeColumnsInvalidationSource = new Subject<EmployeeColumnInvalidatedEvent>();
  private readonly shiftAssignmentSource = new Subject<AssignmentEvent>();
  private readonly concurrentUserSpottedSource = new Subject<ConcurrentUserSpottedEvent>();
  private readonly columnInvalidationSource = new Subject<string>();

  employeeColumnInvalidated$ = this.employeeColumnsInvalidationSource.asObservable();
  employeeColumnInvalidatedByEmployeeAndTimestamp$ = (timestamp: string, employeeId: number) =>
    this.employeeColumnInvalidated$.pipe(filter(x => x.timestamp === timestamp && x.employeeId === employeeId));
  concurrentUserSpotted$ = this.concurrentUserSpottedSource.asObservable();
  columnInvalidated$ = this.columnInvalidationSource.asObservable();
  shiftAssignment$ = this.shiftAssignmentSource.asObservable();

  onShiftAssignmentByEmployeeAndTimestamp$ = (employeeId: number, timestamp: string) =>
    this.shiftAssignmentSource.pipe(filter(x => x.employeeId === employeeId && x.timestamp === timestamp));

  invalidateEmployeeColumn(evt: EmployeeColumnInvalidatedEvent): void {
    this.employeeColumnsInvalidationSource.next(evt);
  }

  publishAssignment(evt: AssignmentEvent): void {
    this.shiftAssignmentSource.next(evt);
  }

  indicatedConcurrentUserSpotted(evt: ConcurrentUserSpottedEvent): void {
    this.concurrentUserSpottedSource.next(evt);
  }

  invalidateColumn(timestamp: string): void {
    this.columnInvalidationSource.next(timestamp);
  }
}

export class EmployeeColumnInvalidatedEvent {
  constructor(
    public readonly timestamp: string,
    public readonly employeeId: number
  ) {}
}

export class AssignmentEvent {
  constructor(
    public readonly assignmentType: AssignmentType,
    public readonly activity: AssignedActivity,
    public readonly timestamp: string,
    public readonly employeeId: number
  ) {}
}

export class ConcurrentUserSpottedEvent {
  constructor(
    public readonly timestamp: string,
    public readonly employeeId: number,
    public readonly data: ConcurrentUserActionData
  ) {}
}

export enum AssignmentType {
  Assign,
  Unassign
}
