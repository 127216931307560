<div>
  <div class="loading-indicator" *ngIf="isHistoryLoading$ | async">
    <mat-spinner></mat-spinner>
  </div>

  <div class="widget-title">
    <div>
      <div>Schedule history</div>
    </div>
  </div>

  <mat-card (click)="applyHistoryBatch(0)" *ngIf="(history$ | async).length > 0">
    <mat-card-content>
      <div>
        <mat-icon>settings_backup_restore</mat-icon>
        <span>Undo all</span>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card *ngFor="let batchGroup of history$ | async" [class.undone]="batchGroup.entries[0].isUndone" (click)="applyHistoryBatch(batchGroup.batch)">
    <mat-card-content>
      <div *ngFor="let entry of batchGroup.entries">
        <mat-icon>{{ getIcon(entry) }}</mat-icon>
        <span>{{ getMessage(entry) }}</span>
      </div>
      <div class="footer">{{ batchGroup.entries[0].createdAt | date: 'YYYY-MM-dd HH:mm:ss' }}</div>
    </mat-card-content>
  </mat-card>

  <div class="no-items" *ngIf="(history$ | async)?.length === 0">
    <mat-card>
      <mat-card-content>
        <mat-icon>list_alt</mat-icon>
        <span>No entries available</span>
      </mat-card-content>
    </mat-card>
  </div>
</div>
