import { Observable } from 'rxjs';
import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { ErrorService } from '@services';
import { catchError, map } from 'rxjs/operators';
import { ApolloQueryResult } from '@apollo/client/core';

import {
  commentsCreatedSubscription,
  commentDeletedSubscription,
  commentUpdatedSubscription,
  createComment,
  createComments,
  deleteComment,
  markCommentAsRead,
  updateComment,
  unflagUnionRepresentativeCommentMutation
} from '../queries/comments.queries';
import { CommentResult, ConversationPanelComment, CreateCommentPayload, DeleteCommentPayload, UpdateCommentPayload } from '../models';

@Injectable({ providedIn: 'root' })
export class CommentsAPIService {
  constructor(
    private readonly apollo: Apollo,
    private readonly errorService: ErrorService
  ) {}

  public createComment$(payload: CreateCommentPayload): Observable<ConversationPanelComment> {
    return this.apollo
      .mutate({
        mutation: createComment,
        variables: payload
      })
      .pipe(
        map((response: ApolloQueryResult<{ createComment: CommentResult }>) => {
          return new ConversationPanelComment(response.data.createComment);
        }),
        catchError((err: Error) => {
          return this.errorService.handle(err);
        })
      );
  }

  public createComments$(payload: CreateCommentPayload[]): Observable<void> {
    return this.apollo
      .mutate({
        mutation: createComments,
        variables: { input: payload }
      })
      .pipe(
        catchError((err: Error) => {
          return this.errorService.handle(err);
        })
      ) as Observable<void>;
  }

  public updateComment$(payload: UpdateCommentPayload): Observable<void> {
    return this.apollo
      .mutate({
        mutation: updateComment,
        variables: payload
      })
      .pipe(
        catchError((err: Error) => {
          return this.errorService.handle(err);
        })
      ) as Observable<void>;
  }

  public deleteComment$(payload: DeleteCommentPayload): Observable<void> {
    return this.apollo
      .mutate({
        mutation: deleteComment,
        variables: payload
      })
      .pipe(
        catchError((err: Error) => {
          return this.errorService.handle(err);
        })
      ) as Observable<void>;
  }

  public markCommentsAsRead$(ids: number[]): Observable<void> {
    return this.apollo
      .mutate({
        mutation: markCommentAsRead,
        variables: { ids }
      })
      .pipe(
        catchError((err: Error) => {
          return this.errorService.handle(err);
        })
      ) as Observable<void>;
  }

  public unflagUnionRepresentativeComment$(id: number, scheduleId: number): Observable<void> {
    return this.apollo
      .mutate({
        mutation: unflagUnionRepresentativeCommentMutation,
        variables: { id, scheduleId }
      })
      .pipe(
        catchError((err: Error) => {
          return this.errorService.handle(err);
        })
      ) as Observable<void>;
  }

  public onCommentsCreated$(): Observable<ConversationPanelComment[]> {
    return this.apollo
      .subscribe({
        query: commentsCreatedSubscription
      })
      .pipe(map((response: { data: { commentsCreated: CommentResult[] } }) => response.data.commentsCreated.map(x => new ConversationPanelComment(x))))
      .pipe(
        catchError((err: Error) => {
          return this.errorService.handle(err);
        })
      );
  }

  public onCommentUpdated$(): Observable<ConversationPanelComment> {
    return this.apollo
      .subscribe({
        query: commentUpdatedSubscription
      })
      .pipe(map((response: { data: { commentUpdated: CommentResult } }) => new ConversationPanelComment(response.data.commentUpdated)))
      .pipe(
        catchError((err: Error) => {
          return this.errorService.handle(err);
        })
      );
  }

  public onCommentDeleted$(): Observable<ConversationPanelComment> {
    return this.apollo
      .subscribe({
        query: commentDeletedSubscription
      })
      .pipe(map((response: { data: { commentDeleted: CommentResult } }) => new ConversationPanelComment(response.data.commentDeleted)))
      .pipe(
        catchError((err: Error) => {
          return this.errorService.handle(err);
        })
      );
  }
}
