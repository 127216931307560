import { BaseModel } from '@model/base.model';
import { GroupModel } from './group.model';
import { ManualShiftModel } from '@model/rostr';
import { SkillModel, SkillModelData } from '@model/profile/skill.model';
import { LicenseModel, LicenseModelData, norwegianTextComparer } from '@shared/index';
import { UnitEndorsementModel, UnitEndorsementModelData } from '@model/profile/unit-endorsement.model';

export interface ShiftGroupQualificationModelData {
  group: unknown;
  shift: unknown;
  skills: SkillModelData[];
  licenses: LicenseModelData[];
  unitEndorsements: UnitEndorsementModelData[];
  comment: string;
}

export class ShiftGroupQualificationModel extends BaseModel {
  public group: GroupModel;
  public shift: ManualShiftModel;
  public skills: SkillModel[];
  public licenses: LicenseModel[];
  public unitEndorsements: UnitEndorsementModel[];
  public comment: string;

  constructor(data: any = {}) {
    super(data);

    this.group = data.group ?? new GroupModel(data.group);
    this.shift = data.shift ?? new ManualShiftModel(data.shiftCode);
    this.comment = data.comment || '';
    this.skills = data.skills?.map(el => new SkillModel(el)).sort((a: SkillModel, b: SkillModel) => norwegianTextComparer(a.name, b.name, true)) || [];
    this.licenses = data.licenses?.map(el => new LicenseModel(el)) || [];
    this.unitEndorsements = data.unitEndorsements?.map(el => new UnitEndorsementModel(el)) || [];
  }

  get shiftType() {
    return this.shift?.type;
  }
}
