import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { map, switchMap, catchError, mergeMap } from 'rxjs/operators';
import { EmployeeModel, Setting } from '@models';
import { bulkQuery } from './employee-overview.queries';
import { merge, Observable, of } from 'rxjs';

import { EmployeeService } from './employee.service';
import { RoleService } from '../../../_auth/services/role.service';
import { LoggingService } from '../logging/logging.service';
import { UserRole } from '../../../_auth/interfaces';
import { Role } from '../../../_auth/models';

@Injectable()
export class EmployeeOverviewService {
  constructor(private apollo: Apollo, private employeeService: EmployeeService, private roleService: RoleService, private logging: LoggingService) {}

  public loadEmployeeOverviewData(roles: Role[] = []): Observable<EmployeeModel[]> {
    return this.employeeService.loadEmployeeData().pipe(
      switchMap(employees =>
        this.loadEmployeeRoles(employees.map(el => el.id)).pipe(
          map(userRoles => this.assignRolesToEmployees(userRoles, employees, roles)),
          mergeMap(employeesWithRoles =>
            this.loadEmployeesSettings(employeesWithRoles.map(e => e.id)).pipe(
              map(workPercentages => this.assignWorkPercentageToEmployees(workPercentages, employeesWithRoles))
            )
          ),
          catchError(loadEmployeeOverviewDataError => {
            this.logging.error(loadEmployeeOverviewDataError);
            return of([]);
          })
        )
      )
    );
  }

  public loadEmployeeSkills(employeeId: number) {
    return this.employeeService.loadEmployeeSkills(employeeId);
  }

  private assignRolesToEmployees(userRoles: UserRole[], employees: EmployeeModel[], roles: Role[]) {
    const userRolesDictionary = userRoles.reduce((prev, userRole) => ({ ...prev, [userRole.employeeId]: userRole.roleIds }), {});

    employees.forEach(employee => {
      employee.roles = roles.filter(role => (userRolesDictionary[employee.id] || []).includes(role.id));
    });

    return employees;
  }

  private assignWorkPercentageToEmployees(workPercentages: Setting[], employees: EmployeeModel[]) {
    const workPercentageDictionary = workPercentages.reduce((dictionaryUpdate, workPercentage) => {
      dictionaryUpdate.set(workPercentage.entitySettings[0].entityId, workPercentage);

      return dictionaryUpdate;
    }, new Map());

    employees.forEach(employee => {
      employee.workPercentage = workPercentageDictionary.get(employee.id);
    });

    return employees;
  }

  public loadEmployeesSettings(employeesIds: number[]): Observable<Setting[]> {
    return this.employeeService.loadWorkPercentageForEmployees(employeesIds);
  }

  public loadEmployeeRoles(employeeIds: number[]): Observable<UserRole[]> {
    return this.roleService.getRolesForEmployees(employeeIds);
  }

  public fullUpdate(employees: { deleted: EmployeeModel[]; updated: EmployeeModel[] }) {
    const updatedEmployees = employees.updated.map(employee => {
      return {
        _deleted: true,
        id: employee.id,
        groupId: employee.group !== null ? employee.group?.id : null,
        deletedAt: null
      };
    });

    const authUpdate = this.updateRoles(employees.updated);

    const beUpdate = this.apollo.mutate({
      mutation: bulkQuery,
      variables: {
        updated: updatedEmployees,
        deleted: employees.deleted.map(employee => employee.id)
      }
    });

    return merge(authUpdate, beUpdate);
  }

  private updateRoles(employeesToUpdate: EmployeeModel[]) {
    return this.roleService.saveRolesForEmployee(employeesToUpdate);
  }
}
