import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { FetchResult } from '@apollo/client/link/core/types';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import * as moment from 'moment-mini';

import { AuthenticationService } from '@auth';
import { MasterplanAssignedActivityModel, ScheduleModel, ShiftAliasModel, VacayRequestModel } from '@models';
import { FULL_DATE_FORMAT } from '@helpers';
import { ErrorService } from '@services';
import {
  createRequest,
  deleteRequest,
  loadAdditionalData,
  loadDaysIncluded,
  loadMasterplans,
  loadRequests,
  loadScheduleAssignedActivities,
  loadSchedules,
  subscriptionCreateRequest,
  subscriptionDeleteRequest,
  subscriptionUpdateRequest,
  updateRequest
} from '../queries';
import { IVacayRequestCreatedPayload, IVacayRequestUpdatedPayload, IVacayRequestDeletedPayload } from '../interfaces/vacay-subscription.interface';
import {
  SchedulesQueryResponse,
  MasterplanAssignedActivitiesQueryResponse,
  VacayRequestsQueryResponse,
  VacayCreateRequestPayload,
  VacayUpdateRequestPayload,
  VacayRequestDaysIncludedQueryResponse,
  ShiftAndHealthAliases
} from '../interfaces/other-interfaces';
import { ScheduleAssignedActivitiesQueryResponse, ScheduleAssignedActivity } from '../interfaces/schedule-assigned-activity';

interface ILoadSchedulesVariables {
  dateFrom?: Date;
}

@Injectable({
  providedIn: 'root'
})
export class ScheduleService {
  constructor(
    private apollo: Apollo,
    private authService: AuthenticationService,
    private errorService: ErrorService
  ) {}

  /**
   *
   * @param filterUpcomingSchedules - flag to tell GraphQL query to fetch ONLY upcoming schedules.
   *
   * @returns {Observable<ScheduleModel[]>}
   */
  public loadSchedules(filterUpcomingSchedules = false): Observable<ScheduleModel[]> {
    const variables: ILoadSchedulesVariables = {};

    if (filterUpcomingSchedules) {
      variables.dateFrom = new Date();
    }

    return this.apollo
      .query<SchedulesQueryResponse>({
        query: loadSchedules,
        variables
      })
      .pipe(
        map((result: FetchResult<SchedulesQueryResponse>) => {
          if (result.data.vacaySchedules === null) {
            this.errorService.handle('No schedules found');
            return [];
          }
          return result.data.vacaySchedules.map(schedule => new ScheduleModel(schedule));
        }),
        catchError(err => {
          return this.errorService.handle(err);
        })
      );
  }

  /**
   * Load masterplan activities
   *
   * @return {Observable<MasterplanAssignedActivityModel[]>}
   */
  public loadMasterplans(scheduleIds?: number[]): Observable<MasterplanAssignedActivityModel[]> {
    return this.apollo
      .query<MasterplanAssignedActivitiesQueryResponse>({
        query: loadMasterplans,
        variables: {
          employee: this.authService.user.id,
          schedules: scheduleIds
        }
      })
      .pipe(
        map((result: FetchResult<MasterplanAssignedActivitiesQueryResponse>) => {
          return result.data.masterplanAssignedActivities.map(shift => new MasterplanAssignedActivityModel(shift));
        }),
        catchError(err => {
          return this.errorService.handle(err);
        })
      );
  }

  /**
   * Load vacay requests
   *
   * @return {Observable<VacayRequestModel[]>}
   */
  public loadRequests(): Observable<VacayRequestModel[]> {
    return this.apollo
      .query<VacayRequestsQueryResponse>({
        query: loadRequests,
        variables: {
          employee: this.authService.user.id
        }
      })
      .pipe(
        map((result: FetchResult<VacayRequestsQueryResponse>) => {
          return result.data.vacayRequests.map(request => new VacayRequestModel(request));
        }),
        catchError(err => {
          return this.errorService.handle(err);
        })
      );
  }

  public loadDaysIncluded(scheduleId: number, employeeId: number): Observable<string[]> {
    return this.apollo
      .query<VacayRequestDaysIncludedQueryResponse>({
        query: loadDaysIncluded,
        variables: {
          scheduleId: scheduleId,
          employeeId: employeeId
        }
      })
      .pipe(
        map((response: FetchResult<VacayRequestDaysIncludedQueryResponse>) => {
          return response.data.vacayScheduleDaysIncluded;
        }),
        catchError(err => {
          return this.errorService.handle(err);
        })
      );
  }

  /**
   * Load shift aliases and health statuses
   *
   * @return {Observable<ShiftAndHealthAliases>}
   */
  public loadShiftAliases(): Observable<ShiftAliasModel[]> {
    return this.apollo
      .query<ShiftAndHealthAliases>({
        query: loadAdditionalData,
        variables: {
          active: true
        }
      })
      .pipe(
        map((result: FetchResult<ShiftAndHealthAliases>) => {
          return result.data.shiftAliases.map(alias => new ShiftAliasModel(alias));
        })
      );
  }

  /**
   * Create vacay request
   *
   * @param {Date} data.date
   * @param {number} data.shiftAlias
   * @param {VacayRequestWish[]} [data.wishes]
   */
  public createVacayRequest(data: VacayCreateRequestPayload): Observable<any> {
    const dateWithoutTimezone = moment(data.date).format(FULL_DATE_FORMAT);

    return this.apollo.mutate({
      mutation: createRequest,
      variables: {
        employee: this.authService.user.id,
        date: dateWithoutTimezone,
        shiftAlias: data.shiftAlias,
        data: {
          wishes: data.wishes
        }
      }
    });
  }

  /**
   * Update vacay request
   *
   * @param {number} data.id
   * @param {Date} [data.date]
   * @param {number} [data.shiftAlias]
   * @param {wishes} [data.wishes]
   */
  public updateRequest(data: VacayUpdateRequestPayload): Observable<any> {
    const dateWithoutTimezone = data.date && moment(data.date).format(FULL_DATE_FORMAT);

    const wishes = data.wishes && { wishes: data.wishes };

    return this.apollo.mutate({
      mutation: updateRequest,
      variables: {
        id: data.id,
        shiftAlias: data.shiftAlias,
        data: wishes,
        date: dateWithoutTimezone
      }
    });
  }

  /**
   * Delete vacay request
   *
   * @param {number} id
   *
   * @return {Observable<number>}
   */
  public deleteRequest(id: number): Observable<any> {
    return this.apollo.mutate({
      mutation: deleteRequest,
      variables: { id }
    });
  }

  /**
   * Subscription to create vacay request
   *
   * @return {Observable<VacayRequestModel>}
   */
  public subscriptionCreateRequest(): Observable<VacayRequestModel> {
    return this.apollo
      .subscribe({
        query: subscriptionCreateRequest,
        variables: {
          employee: this.authService.user.id
        }
      })
      .pipe(
        map((payload: IVacayRequestCreatedPayload) => {
          return new VacayRequestModel(payload.data.vacayRequestCreated);
        })
      );
  }

  /**
   * Subscription to update vacay request
   *
   * @return {Observable<VacayRequestModel>}
   */
  public subscriptionUpdateRequest(): Observable<VacayRequestModel> {
    return this.apollo
      .subscribe({
        query: subscriptionUpdateRequest,
        variables: {
          employee: this.authService.user.id
        }
      })
      .pipe(
        map((payload: IVacayRequestUpdatedPayload) => {
          return new VacayRequestModel(payload.data.vacayRequestUpdated);
        })
      );
  }

  /**
   * Subscription to delete vacay request
   *
   * @return {Observable<number>}
   */
  public subscriptionDeleteRequest(): Observable<number> {
    return this.apollo
      .subscribe({
        query: subscriptionDeleteRequest,
        variables: {
          employee: this.authService.user.id
        }
      })
      .pipe(
        map((payload: IVacayRequestDeletedPayload) => {
          return payload.data.vacayRequestDeleted.id;
        })
      );
  }

  public loadSchedulesAssignedActivities(scheduleId: number, employeeId: number): Observable<ScheduleAssignedActivity[]> {
    return this.apollo
      .query<ScheduleAssignedActivitiesQueryResponse>({
        query: loadScheduleAssignedActivities,
        variables: {
          employee: employeeId,
          schedule: scheduleId
        }
      })
      .pipe(
        map((result: FetchResult<ScheduleAssignedActivitiesQueryResponse>) => {
          return result.data.scheduleAssignedActivities;
        }),
        catchError(err => {
          return this.errorService.handle(err);
        })
      );
  }
}
