import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { FetchResult } from '@apollo/client/core';
import { Apollo } from 'apollo-angular';

import { SkillModel } from '@model/profile/skill.model';
import { LoggingService, ErrorService } from '@shared/services';

import { createSkill, getSkillQuery, loadSkillsQuery, updateSkill } from './skill.queries';

@Injectable({ providedIn: 'root' })
export class SkillService {
  constructor(
    private readonly apollo: Apollo,
    private readonly logging: LoggingService,
    private readonly errorService: ErrorService
  ) {}

  public loadHorizonSkills(): Observable<SkillModel[]> {
    return this.apollo
      .query<any>({
        query: loadSkillsQuery
      })
      .pipe(
        map(skillsData => skillsData.data.skills.map(skill => new SkillModel(skill))),
        catchError(loadSkillsError => {
          this.logging.error(loadSkillsError);
          return of([]);
        })
      );
  }

  public get(id): Observable<SkillModel> {
    return this.apollo
      .query<any>({
        query: getSkillQuery,
        variables: {
          id
        }
      })
      .pipe(
        map(skillData => new SkillModel(skillData.data.skill)),
        catchError(getSkillError => {
          return this.errorService.handle(getSkillError);
        })
      );
  }

  public update(data: SkillModel): Observable<SkillModel> {
    return this.apollo
      .mutate({
        mutation: updateSkill,
        variables: {
          id: data.id,
          name: data.name
        }
      })
      .pipe(
        map((result: FetchResult) => {
          return new SkillModel(result.data.skill);
        }),
        catchError(err => {
          return this.errorService.handle(err);
        })
      );
  }

  public create(data: SkillModel): Observable<SkillModel> {
    return this.apollo
      .mutate({
        mutation: createSkill,
        variables: {
          name: data.name
        }
      })
      .pipe(
        map((result: FetchResult) => {
          return new SkillModel(result.data.skill);
        }),
        catchError(err => {
          return this.errorService.handle(err);
        })
      );
  }
}
