import * as d3 from 'd3';

import { translate, lower } from '@helpers';

import { Draggable } from '../draggable/draggable';
import { IShift } from '../shifts/base.shift';
import { BaseItem } from './base.item';
import { IItemConfig } from './interfaces';


export class ShiftsBasedItem extends BaseItem {

  protected _drag: d3.DragBehavior<any, any, any>;
  protected _draggable: Draggable;

  constructor(protected _title = '',
              protected _shifts: IShift[] = [],
              protected _itemConfig: IItemConfig = { height: 40 }) {
    super(_title, _itemConfig);
    this.shifts(_shifts);
  }

  /*********************************** Shifts ********************************/
  /**
   * Getter and setter for shifts property
   * @param shifts
   * @returns {any}
   */
  public shifts(shifts?: IShift[]): this | IShift[] {
    if (!arguments.length) {
      return this._shifts;
    }
    this._shifts = shifts;
    shifts.forEach((shift: IShift) => {
      shift.item(this as any);
    });
    return this;
  }

  public leftHandSideShift(shift) {
    const index = this._shifts.indexOf(shift);
    if (index === -1) {
      return false;
    }

    if (index === 0) {
      return false;
    } else {
      return this._shifts[index - 1];
    }
  }

  public rightHandSideShift(shift) {
    const index = this._shifts.indexOf(shift);
    if (index === -1) {
      return false;
    }

    if (index === this._shifts.length - 1) {
      return false;
    } else {
      return this._shifts[index + 1];
    }
  }

  /**
   * Add shift to item shifts
   * @param shift
   * @returns {OpenableItem}
   */
  public addShift(shift: IShift) {
    if (this._shifts.indexOf(shift) === -1) {
      this.insertInOrderShift(shift);
      shift.item(this as any);
    }
    return this;
  }

  /**
   * Get all currently visible shifts
   * @returns {IShift[]}
   */
  public visible() {
    const visible = this._grid.visible();
    return this._shifts.filter((shift: IShift) => {
      return visible.indexOf(shift) > -1;
    });
  }

  ////////////////////////// Drag for masterplan....... //////////

  public dragEnable() {
    this._drag = d3.drag();
    this.$el.call(this._drag);
    this.dragPhasesBehavior();
  }

  public dragDisable() {
    this.$el.on('mousedown.drag', null);
    this._drag.on('start', null);
    this._drag.on('drag', null);
    this._drag.on('end', null);
    this._drag = undefined;
  }

  public dragPhasesBehavior() {
    this._drag.on('start', () => {
      this.dragStart();
    });
    this._drag.on('drag', () => {
      this.drag();
    });
    this._drag.on('end', () => {
      this.dragEnd();
    });
  }

  // FIXME this method was added only for masterplan settings....
  public move(dragItem, direction) {
    const currentY = this._y;

    // Levels
    const currentLevel = this.level;
    const dragLevel = dragItem.level;

    // Parents
    const currentParent = this.parent as any;
    const dragParent = dragItem.parent;

    // Subsets
    const subs = this._timeline.context().masterplan.subsets;


    // If drag between same levels
    if (currentLevel === dragLevel) {
      // Just get all items
      const items = currentParent.items;

      // Get indexes
      const currentIndex = items.indexOf(this);
      const dragIndex = items.indexOf(dragItem);

      // And swap items
      items[currentIndex] = dragItem;
      items[dragIndex] = this;

      const subset = dragItem.subset;

      const currentActivities = subset.activities[this.index];

      subset.activities[this.index] = subset.activities[dragItem.index];
      subset.activities[dragItem.index] = currentActivities;
    } else {
      // get all items from second level
      const items = this._menu.getItemsByLevel(2);
      const dragItemIndex = items.indexOf(dragItem);
      // Select Prev/Next Item
      const prevNextItem = items[direction === 'up' ? dragItemIndex - 1 : dragItemIndex + 1];

      // Remove from current parent
      const dragItems = dragParent.items;
      dragItems.splice(dragItems.indexOf(dragItem), 1);

      // Add to new parent
      if (direction === 'up') {
        prevNextItem.parent.items.push(dragItem);
        subs[prevNextItem.subset.groupIndex].activities.push(dragItem.subset.activities[dragItem.index]);
      } else {
        prevNextItem.parent.items.unshift(dragItem);
        subs[prevNextItem.subset.groupIndex].activities.unshift(dragItem.subset.activities[dragItem.index]);
      }

      subs[dragItem.subset.groupIndex].activities.splice(dragItem.index, 1);

      dragItem.subset = prevNextItem.subset;
      dragItem.parent = prevNextItem.parent;

      // Update subsets
    }

    // Move
    this.y(dragItem.y());
    dragItem.y(currentY);

    // Move shifts
    const shifts = this.shifts() as any;
    const dragShifts = dragItem.shifts();

    shifts.forEach((shift) => {
      shift.updatePositionByItem();
    });

    dragShifts.forEach((shift) => {
      shift.updatePositionByItem();
    });

    // Other recalcs
    this.recalcItems();
  }

  protected insertInOrderShift(shift) {
    const nearShift = lower(this._shifts, shift.dateFrom().getTime(), (v) => v.dateFrom().getTime());

    this._shifts.splice(nearShift, 0, shift);
  }


  // FIXME should be moved (repeats in each item)
  protected sortAssignedShifts() {
    this._shifts.sort((a: IShift, b: IShift) => {
      return a.dateFrom().getTime() - b.dateFrom().getTime();
    });
  }

  protected dragStart() {
    const draggable = this._timeline.draggable();
    draggable.content(this.dragRender());
    draggable.offset.apply(draggable, this.dragOffset());
    draggable.area.apply(draggable, this.dragArea());
    draggable.coords.apply(draggable, this.dragCoords());
    draggable.show().render();
    draggable.droppable.hide();

    this._draggable = draggable;

    this.$el.attr('visibility', 'hidden');
  }

  protected drag() {
    const dy = d3.event.dy;
    const dropItem = this._menu.getNearItemByCoord(this._draggable.y(), this.totalHeight()) as any;

    this._draggable.moveY(dy);

    if (dy && dropItem !== this) {
      dropItem.move(this, dy > 0 ? 'down' : 'up');
    }
  }

  protected dragEnd() {
    const dragItem = this._menu.getNearItemByCoord(this._draggable.y(), this.totalHeight()) as any;

    this._y = dragItem.y();
    this.$el.attr('transform', translate(0, this._y));

    this._draggable.hide();
    this.$el.attr('visibility', 'visible');
  }

  protected dragRender() {
    const $clone = d3.select<any, any>(this.clone());
    $clone.classed('drag', true);
    $clone.attr('transform', null);
    $clone.selectAll('.group-indication').remove();
    $clone.select('rect').attr('fill', '#ff0');
    return $clone.node();
  }

  protected dragOffset() {
    const sizes = this._timeline.sizes();
    return [-(sizes.menuWidth % this._grid.config().intervalWidth), 0];
  }

  protected dragArea() {
    // let items = this._parent.items;
    const items = this._menu.getItemsByLevel(1).concat(this._menu.getItemsByLevel(2));
    const firstItem = items[0];
    const lastItem = items[items.length - 1];

    return [0, firstItem.y(), 0, lastItem.y()];
  }

  protected dragCoords() {
    return [0, this.y()];
  }

  protected recalcItems() {
    this._menu.updateTree();

    let items = this._menu.getItemsByLevel(2);

    items.forEach((item) => {
      item.assignedActivities.forEach((activity) => {
        activity.removeMirrorActivity();
      });

      item.updateTitle();
      item.renderBackground();
      item.renderGridBackground();
    });

    items.forEach((item) => {
      if (item.assignedActivities) {
        item.assignedActivities.forEach((activity) => {
          activity.createMirrorActivity();
        });
      }
    });

    items = this._menu.getItemsByLevel(1);
    items.forEach((item: any) => {
      item.renderBackground();
      item.renderGridBackground();
    });
  }

}
