import { Apollo } from 'apollo-angular';
import { ApolloQueryResult } from '@apollo/client/core';
import { Injectable } from '@angular/core';
import { map, tap, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import {
  PreferenceModel,
  PreferenceDataModel,
  mapPreferencesToDataModel,
  mapDataModelToPreferences,
  hoursToMinutes
} from '@shared/models/profile/preferences.model';
import { ProfileModel, QualificationLicense } from '@shared/models/profile';
import {
  loadEmployeeQuery,
  updateEmployeeQuery,
  loadQualifications,
  createEmployeeQualificationLicense,
  updateEmployeeQualificationLicense,
  deleteEmployeeQualificationLicense,
  sectorEmployeeHoursStatisticsQuery,
  loadEmployeePreferencesQuery,
  updateEmployeePreferences,
  updateEmployeeQualificationsMutation,
  bulkEmployeeSkillOperation,
  updateEmployeeWithAllReferencesQuery,
  statisticsForEmployeeAveragingPeriodsQuery,
  saveEmployeeRoleGroups,
  updatePreferencesForEmployees
} from '@src/app/platform/_shared/queries/profile.queries';
import { Observable } from 'rxjs';
import * as moment from 'moment-mini';
import { ErrorService } from '@services';
import { EmployeeWithAllReferences } from '../models/employee-with-all-references';
import { toUtcWithLocalOffsetStartOfDay } from '@helpers';
import { EmployeeAveragingPeriodStatistics, EmployeeSkillInputData } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  public data: ProfileModel[] = [];
  public currentEmployee: ProfileModel;
  public currentEmployeePreferences: PreferenceDataModel;
  constructor(
    private apollo: Apollo,
    private route: Router,
    private errorService: ErrorService
  ) {}

  public loadEmployee(id: number) {
    return this.apollo
      .query<any>({
        query: loadEmployeeQuery,
        variables: {
          id
        }
      })
      .pipe(
        tap(response => {
          this.currentEmployee = new ProfileModel(response.data.employeeWithValidQualifications);
        }),
        map(() => this.currentEmployee),
        catchError(err => {
          return this.errorService.handle(err);
        })
      );
  }

  public loadQualificationOptions() {
    return this.apollo.query<any>({ query: loadQualifications }).pipe(
      map(response => response.data),
      catchError(err => {
        return this.errorService.handle(err);
      })
    );
  }

  public loadSectorHours(employee: number) {
    return this.apollo
      .query<any>({
        query: sectorEmployeeHoursStatisticsQuery,
        variables: {
          employee
        }
      })
      .pipe(
        map(response => response.data),
        catchError(err => {
          return this.errorService.handle(err);
        })
      );
  }

  public updateEmployee(data: ProfileModel) {
    this.updateCurrentEmployee(data);
  }

  public updateCurrentEmployee(data: ProfileModel) {
    return (
      this.apollo
        .mutate({
          mutation: updateEmployeeQuery,
          variables: {
            id: data.id,
            email: data.email,
            firstName: data.firstName,
            middleName: data.middleName,
            lastName: data.lastName,
            data: data.data,
            employeeSkills: data.qualifications.skillsData
          }
        })
        .subscribe((response: ApolloQueryResult<any>) => {
          const res = new ProfileModel(response.data.updateEmployee);
          Object.assign(this.currentEmployee, res);
        }),
      catchError(err => {
        return this.errorService.handle(err);
      })
    );
  }

  public saveEmployeeRoleGroups(employee: number, input: { role: number; group: number }[]) {
    return this.apollo.mutate({
      mutation: saveEmployeeRoleGroups,
      variables: {
        employee,
        input
      }
    });
  }

  public updateEmployeeWithAllReferences(data: EmployeeWithAllReferences) {
    const qualificationDefaults = {
      validFrom: moment.utc(),
      validTo: moment.utc().add(1, 'years'),
      obtained: moment.utc()
    };

    const skills = [];
    Object.keys(data.skills || {}).forEach(skillId => {
      data.skills[skillId].forEach(range => {
        skills.push({
          id: range.id,
          skill: Number(skillId),
          validFrom: toUtcWithLocalOffsetStartOfDay(range.dates.dateFrom),
          validTo: toUtcWithLocalOffsetStartOfDay(range.dates.dateTo)
        });
      });
    });

    const groups = [];
    Object.keys(data.groups).forEach(groupId => {
      data.groups[groupId].forEach(range => {
        groups.push({
          id: range.id,
          group: Number(groupId),
          validFrom: toUtcWithLocalOffsetStartOfDay(range.dates.dateFrom),
          validTo: toUtcWithLocalOffsetStartOfDay(range.dates.dateTo)
        });
      });
    });

    return this.apollo.mutate({
      mutation: updateEmployeeWithAllReferencesQuery,
      variables: {
        id: data.id,
        email: data.workEmail,
        firstName: data.firstName,
        middleName: data.middleName,
        lastName: data.lastName,
        data: {
          mobilePhone: data.mobilePhone,
          workPhone: data.workPhone,
          presentation: data.personalPresentation,
          personalEmail: data.personalEmail
        },
        skills: skills,
        groups: groups,
        qualifications: {
          employeeId: data.id,
          employeeLicenses: data.licenses.map(l => ({
            id: l.id,
            ...qualificationDefaults
          })),
          employeeUnitEndorsements: data.endorsements.map(u => ({
            id: u.id,
            ...qualificationDefaults
          }))
        },
        workPercentages: data.workPercentages.map(x => {
          return { id: x.id, dateFrom: x.dateFrom, value: String(x.value) };
        }),
        preferences: {
          id: data.preferencesId,
          healthyRotation: data.healthyRotation,
          hardWorkDurationMax: hoursToMinutes(data.hardWorkDurationMax),
          hardWeeklyWorkMax: hoursToMinutes(data.hardWeeklyWorkMax),
          hardAfterShiftBreak: hoursToMinutes(data.hardAfterShiftBreak),
          hardConsecutiveWeeklyOff: hoursToMinutes(data.hardConsecutiveWeeklyOff),
          maxHourPerWeek: data.maxHourPerWeek,
          standardWorkingHoursPerWeek: data.standardWorkingHoursPerWeek,
          workingDaysInRow: data.workingDaysInRow,
          hardNightShiftOK: data.hardNightShiftOK
        },
        averagingPeriodOffsets: Object.entries(data.averagingPeriodOffsets).map(([averagingPeriodId, offset]) => ({
          averagingPeriodId: +averagingPeriodId,
          offset,
          employeeId: data.id
        }))
      }
    });
  }

  public updateEmployeeQualifications(data: ProfileModel) {
    const qualificationDefaults = {
      validFrom: moment.utc(),
      validTo: moment.utc().add(1, 'years'),
      obtained: moment.utc()
    };

    return this.apollo
      .mutate({
        mutation: updateEmployeeQualificationsMutation,
        variables: {
          qualifications: {
            employeeId: data.id,
            employeeSkills: data.qualifications.skills.map(s => ({
              id: s.id,
              ...qualificationDefaults
            })),
            employeeLicenses: data.qualifications.licenses.map(l => ({
              id: l.id,
              ...qualificationDefaults
            })),
            employeeUnitEndorsements: data.qualifications.unitEndorsements.map(u => ({
              id: u.id,
              ...qualificationDefaults
            }))
          }
        }
      })
      .pipe(
        tap((response: ApolloQueryResult<any>) => {
          const res = new ProfileModel(response.data.updateEmployeeQualifications);
          Object.assign(this.currentEmployee, res);
        }),
        catchError(err => {
          return this.errorService.handle(err);
        })
      );
  }

  public updateEmployeeSkills(
    employeeId: number,
    input: { created?: EmployeeSkillInputData[]; deleted?: EmployeeSkillInputData[]; updated?: EmployeeSkillInputData[] }
  ) {
    return this.apollo
      .mutate({
        mutation: bulkEmployeeSkillOperation,
        variables: {
          created: input.created,
          updated: input.updated,
          deleted: input.deleted
        }
      })
      .pipe(
        catchError(err => {
          return this.errorService.handle(err);
        })
      );
  }

  public createQualificationLicense(data: QualificationLicense) {
    return this.apollo.mutate({
      mutation: createEmployeeQualificationLicense,
      variables: {
        employeeId: data.employeeId,
        licenseId: data.id,
        obtained: data.obtained,
        validFrom: data.validFrom,
        validTo: data.validTo
      }
    });
  }

  public removeQualificationLicense(id: number) {
    return this.apollo.mutate({
      mutation: deleteEmployeeQualificationLicense,
      variables: {
        id
      }
    });
  }

  public updateQualificationLicense(data: QualificationLicense) {
    return this.apollo.mutate({
      mutation: updateEmployeeQualificationLicense,
      variables: {
        id: data.qualificationId,
        obtained: data.obtained,
        validFrom: data.validFrom,
        validTo: data.validTo
      }
    });
  }

  public loadEmployeePreferences(): Observable<PreferenceDataModel> {
    return this.apollo
      .query<PreferenceModel>({
        query: loadEmployeePreferencesQuery,
        variables: {
          id: this.currentEmployee.id
        }
      })
      .pipe(
        tap((response: ApolloQueryResult<any>) => {
          if (response.data.preferencesForEmployee) {
            this.currentEmployeePreferences = mapPreferencesToDataModel(response.data.preferencesForEmployee || { id: this.currentEmployee.id });
          } else {
            this.currentEmployeePreferences = new PreferenceDataModel();
          }
        }),
        map(() => this.currentEmployeePreferences),
        catchError(err => {
          return this.errorService.handle(err);
        })
      );
  }

  public updateEmployeePreference(data: PreferenceDataModel) {
    const preferences = mapDataModelToPreferences(data);
    return this.apollo.mutate({
      mutation: updateEmployeePreferences,
      variables: {
        id: preferences.id,
        healthyRotation: preferences.healthyRotation,
        hardWorkDurationMax: preferences.hardWorkDurationMax,
        hardWeeklyWorkMax: preferences.hardWeeklyWorkMax,
        standardWorkingHourPerWeek: preferences.standardWorkingHoursPerWeek,
        workingDaysInRow: preferences.workingDaysInRow,
        hardAfterShiftBreak: preferences.hardAfterShiftBreak
      }
    });
  }

  public getStatisticsForEmployeeAveragingPeriods(employeeId: number, groupIds: number[]): Observable<EmployeeAveragingPeriodStatistics> {
    return this.apollo
      .query<{ employeeStatisticsForAveragingPeriods: EmployeeAveragingPeriodStatistics }>({
        query: statisticsForEmployeeAveragingPeriodsQuery,
        variables: {
          employee: employeeId,
          groups: groupIds
        }
      })
      .pipe(
        map(response => response.data.employeeStatisticsForAveragingPeriods),
        catchError(err => {
          return this.errorService.handle(err);
        })
      );
  }

  public updatePreferencesForEmployees(employees: number[], preferences: Partial<PreferenceDataModel>) {
    return this.apollo
      .mutate<{ updateEmployeePreferencesForEmployees: number[] }>({
        mutation: updatePreferencesForEmployees,
        variables: {
          employees,
          preferences
        }
      })
      .pipe(map(response => response.data.updateEmployeePreferencesForEmployees[0]));
  }
}
