<mat-menu #commentsMenu="matMenu" class="no-max-width">
  <ng-template matMenuContent>
    <div (click)="$event.stopPropagation()" class="menu-content">
      <sh-conversation-panel
        [comments]="request.comments"
        (commentCreated)="createComment($event)"
        (commentDeleted)="deleteComment($event)"
        (commentUpdated)="updateComment($event)"
        (commentsRead)="markCommentsAsRead($event.comments)"
        [timestamp]="request.date | date: 'YYYY-MM-dd'"
        [employeeId]="request.employee.id"
        [scheduleId]="scheduleId"
      ></sh-conversation-panel>
    </div>
  </ng-template>
</mat-menu>

<button mat-button [matMenuTriggerFor]="commentsMenu" (menuOpened)="startMarkingAsRead()" (menuClosed)="stopMarkingAsRead()">
  <span *ngIf="getUnreadCommentsCount() > 0; else noMessages" class="unread"> {{ getUnreadCommentsCount() }} unread </span>
</button>

<ng-template #noMessages>
  <span class="font-normal">{{ request.comments.length }} comments</span>
</ng-template>
