<div class="dialog-window">
  <h1>Required shifts report</h1>
  <div>
    <div class="day" *ngFor="let date of dates">
      <div class="column-date">{{ date }}</div>
      <div class="column-values">
        <ng-container>
          <div class="label">Planned [{{ (dataByDate$(date) | async).plannedCount }}]:</div>
          <div class="row-shifts">
            <div
              *ngFor="let shift of (dataByDate$(date) | async).planned"
              class="shift"
              [class.unassigned]="!isAssigned(shift.id)"
              [class.locked]="shift.isLocked"
            >
              {{ shift.code }}
            </div>
          </div>
        </ng-container>
        <ng-container>
          <div class="label">Actual [{{ (dataByDate$(date) | async).actualCount }}]:</div>
          <div class="row-shifts">
            <div
              *ngFor="let shift of (dataByDate$(date) | async).actual"
              class="shift actual"
              [class.unassigned]="!isAssigned(shift.id)"
              [class.locked]="shift.isLocked"
            >
              {{ shift.code }}
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="(dataByDate$(date) | async).surplusCount">
          <div class="label">Surplus [{{ (dataByDate$(date) | async).surplusCount }}]:</div>
          <div class="row-shifts">
            <div
              *ngFor="let shift of (dataByDate$(date) | async).surplus"
              class="shift surplus"
              [class.unassigned]="!isAssigned(shift.id)"
              [class.locked]="shift.isLocked"
            >
              {{ shift.code }}
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="(dataByDate$(date) | async).missingCount">
          <div class="label">Missing [{{ (dataByDate$(date) | async).missingCount }}]:</div>
          <div class="row-shifts">
            <div *ngFor="let shift of (dataByDate$(date) | async).missing" class="shift missing">{{ shift.code }}</div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
