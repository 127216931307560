import { Routes } from '@angular/router';
import { DefaultSidebarWidgetComponent } from './components/default-sidebar-widget/default-sidebar-widget.component';
import { EmployeeSidebarWidgetComponent } from '@app/rostr/overview/components/employee-list/components/employee-sidebar-widget/employee-sidebar-widget.component';
import { EmployeeEditorSidebarWidgetComponent } from '@platform/entity/employee/overview/components/employee-editor-sidebar-widget/employee-editor-sidebar-widget.component';
import { AssignedActivitySidebarWidgetComponent } from '@app/rostr/overview/components/grid/components/assigned-activity/components/assigned-activity-sidebar-widget/assigned-activity-sidebar-widget.component';
import { StatsCellSidebarWidgetComponent } from '@app/rostr/overview/components/stats-cell/components/stats-cell-sidebar-widget/stats-cell-sidebar-widget.component';
import { RequiredShiftSidebarWidgetComponent } from '@app/rostr/overview/components/grid/components/required-shifts-column/components/required-shift-sidebar-widget/required-shift-sidebar-widget.component';
import { NotificationsSidebarWidgetComponent } from '@component/notifications/components/notifications-sidebar-widget/notifications-sidebar-widget.component';
import { MyPreferencesWidgetComponent } from '@app/vacay/_shared/components/my-preferences/my-preferences-widget.component';
import { ConstellationManagerSidebarWidgetComponent } from '@app/planning/_shared/components/constellation-manager-sidebar-widget/constellation-manager-sideabr-widget.component';
import { DayWidgetComponent } from '@app/vacay/_shared/components/day-widget/day-widget.component';
import { HistorySidebarWidgetComponent } from '@app/rostr/overview/components/history-sidebar-widget/history-sidebar-widget.component';

export const sidebarRoutes: Routes = [
  {
    path: '',
    outlet: 'sidebar',
    component: DefaultSidebarWidgetComponent
  },
  {
    path: 'employee-widget/:id/:dateFrom/:dateTo',
    outlet: 'sidebar',
    component: EmployeeSidebarWidgetComponent
  },
  {
    path: 'employee-editor-widget/:id',
    outlet: 'sidebar',
    component: EmployeeEditorSidebarWidgetComponent
  },
  {
    path: 'constellations-widget',
    outlet: 'sidebar',
    component: ConstellationManagerSidebarWidgetComponent
  },
  {
    path: 'assigned-activity-widget/:id',
    outlet: 'sidebar',
    component: AssignedActivitySidebarWidgetComponent
  },
  {
    path: 'schedule-history-widget/:id',
    outlet: 'sidebar',
    component: HistorySidebarWidgetComponent
  },
  {
    path: 'stats-cell-widget/:week/:employee',
    outlet: 'sidebar',
    component: StatsCellSidebarWidgetComponent
  },
  {
    path: 'required-shift-widget/:day',
    outlet: 'sidebar',
    component: RequiredShiftSidebarWidgetComponent
  },
  {
    path: 'notifications',
    outlet: 'sidebar',
    component: NotificationsSidebarWidgetComponent
  },
  {
    path: 'my-preferences',
    outlet: 'sidebar',
    component: MyPreferencesWidgetComponent
  },
  {
    path: 'day-widget/:schedule/:day',
    outlet: 'sidebar',
    component: DayWidgetComponent
  }
];
