import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ScheduleDto } from '../../dto';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class ScheduleApiService {
  private readonly url = '/api/rostr/get-by-id';

  constructor(private readonly http: HttpClient) {}

  public getSchedule(id: number): Observable<ScheduleDto> {
    return this.http.get<ScheduleDto>(`${this.url}/${id}?q=${Date.now().toString()}`);
  }
}
