import { ShiftCodeTypeEnum } from '@shared/enums/shift-code-type.enum';

export const DAY_OFF = ['|O|', '/O', '|O', 'O|', 'ON', '|U|', '/U', '|U', 'U|', 'UN', 'X', 'XN', 'Ø', '-', 'XSN'];
export const VACATION = ['PER', 'FER', 'JUL', '34'];
export const STAND_BY = ['1AV', '3AV', '4AV', 'AV1', 'AV2', 'AV3', 'AV4'];
export const NIGHT_SHIFT = ['N'];

export const SHIFTS_TIME_RESOLUTION = 30;

export function isDayOff(code: string) {
  return DAY_OFF.indexOf(code) > -1;
}

export function isNightShift(code: string) {
  return NIGHT_SHIFT.indexOf(code) > -1;
}

export function isStandBy(code: string) {
  return STAND_BY.indexOf(code) > -1;
}

export function isVacation(code: string) {
  return VACATION.indexOf(code) > -1;
}

export function isDayOffOrVacation(code: string) {
  return isDayOff(code) || isVacation(code);
}

export function generateShiftCode(shiftType: ShiftCodeTypeEnum, startInMinutes: number, endInMinutes: number) {
  const minutesInHour = 60;
  const minutesInterval = 5;
  const asciiCharA = 65;

  const prefix = getShiftPrefix(shiftType);

  const hStart = Math.floor(startInMinutes / minutesInHour);
  let hEnd = Math.floor(endInMinutes / minutesInHour);

  if (hEnd === 0) {
    hEnd = 24;
  }

  const mStart = startInMinutes % minutesInHour;
  const startFiveMinutesIntervals = Math.floor(mStart / minutesInterval);
  const startMinutesCode = String.fromCharCode(asciiCharA + startFiveMinutesIntervals);

  const mEnd = endInMinutes % minutesInHour;
  const endFiveMinutesIntervals = Math.floor(mEnd / minutesInterval);
  const endMinutesCode = String.fromCharCode(asciiCharA + endFiveMinutesIntervals);

  return `${prefix}${addPaddingZero(hStart)}${startMinutesCode}${addPaddingZero(hEnd)}${endMinutesCode}`;
}

function getShiftPrefix(shiftType: ShiftCodeTypeEnum) {
  switch (shiftType) {
    case ShiftCodeTypeEnum.Administrative: {
      return 'D';
    }
    case ShiftCodeTypeEnum.Leave: {
      return 'L';
    }
    case ShiftCodeTypeEnum.Standby: {
      return 'A';
    }
    case ShiftCodeTypeEnum.TimeOff: {
      return 'T';
    }
    case ShiftCodeTypeEnum.Vacation: {
      return 'V';
    }
    case ShiftCodeTypeEnum.Complex: {
      return 'C';
    }
    default: {
      return '';
    }
  }
}

function addPaddingZero(num: number): string {
  return num < 10 ? `0${num}` : num.toString();
}
