import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()

export class WidgetService {

  public static actions = { EDIT: 'edit', SAVE: 'save', CANCEL: 'cancel' };
  private readonly _editActionSource = new Subject<any>();
  private readonly _editAction = this._editActionSource.asObservable();

  get editAction() {
    return this._editAction;
  }

  set editAction(action: any) {
    this._editActionSource.next(action);
  }

}
