import { Constructor } from '@angular/cdk/table';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MessageBusService {
  private readonly messages = new Subject<Message>();

  public publish(message: Message) {
    this.messages.next(message);
  }

  on<T extends Message>(messageType: Constructor<T>): Observable<T> {
    return this.messages.pipe(filter(x => x instanceof messageType)) as Observable<T>;
  }
}

export interface Message {}
