export const COLOR_PICKER_DEFAULT = '#1c76ff';
export const DEFAULT_OBJECTIVE_COLOR = '#000000';

export const COLOR_PICKER_VALUES = [
    DEFAULT_OBJECTIVE_COLOR,
    COLOR_PICKER_DEFAULT,
    '#2199A4',
    '#6785BE',
    '#8432ff',
    '#dd76d2',
    '#FF8D8B',
    '#C5A687',
    '#bbbbbb',
];
