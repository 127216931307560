import { Component } from '@angular/core';

@Component({
  selector: 'pl-widget-base',
  templateUrl: './widget-base.component.html'
})
export class WidgetBaseComponent {
  heading: string;
  public editMode = false;
  public state = '';

  public onEdit(edit) {
    this.editMode = edit;
  }
}
