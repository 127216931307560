import { IMatrix } from '../matrix.interfaces';
import { IRows } from './rows.interface';
import { Row } from './row';
import { MatrixGroupDataModel } from '../matrix-group-data.model';
import { IConfig } from '../utils/interfaces';

import * as d3 from 'd3';

export class Rows implements IRows {

  get container() {
    return this.$container;
  }
  public rows: Row[] = [];
  private $container: d3.Selection<any, any, any, any>;

  constructor(private matrix: IMatrix) {
    this.$container = this.initRowsGroup();
  }

  public render() {
    this.renderRows();
  }

  public renderRow(data: any) {
    const type = data.type;
    const group = this.getGroupForRow(type);

    const row = this.getCurrentRow(type, data[group.key]);
    if (row) {
      // editing;
      row.title = data[group.title];
      row.rerender();

    } else {
      // new row;
      const config = {
        index: this.rows.length,
        key: data[group.key],
        title: data[group.title],
        color: group.color,
        type: group.type
      };
      this.createRow(config);
      this.matrix.grid.redrawLayout();
      this.matrix.grid.redrawLayout();

      // redraw cols lines
      this.matrix.cols.cols.forEach(col => col.drawLine());

      this.renderControls();
    }
  }

  public deleteRow(data: any) {
    const group = this.getGroupForRow(data.type);
    const col = this.getCurrentRow(data.type, data[group.key]);
    col.destroyWithTranslation();
    this.matrix.grid.redrawLayout();

    this.renderControls();
  }

  /*
   * Get current row (supposed position)
   */
  public dragRow(y: number) {
    y += this.matrix.cellSize / 2;

    // return row that in y interval from y to y + cell size
    return this.rows.find((item) => item.y <= y && item.offsetY >= y);
  }

  private initRowsGroup() {
    return this.matrix.svg
      .append('g')
      .attr('class', 'matrix-rows')
      .attr('transform', () => {
        const translate = this.matrix.cellSize + this.matrix.titleSize;
        return `translate(${this.matrix.cellSize}, ${translate})`;
      });
  }

  private renderControls() {
    if (this.matrix.rowShowAddBtn) {
      this.renderRowAddBtn();
    }
  }

  private renderRowAddBtn() {
    this.$container.select('.matrix-add-btn').remove();

    const button = this.$container
      .append('g')
      .attr('class', 'matrix-add-btn')
      .on('click', () => this.matrix.rowAddClick.emit(this));

    const rect = button.append('rect')
      .attr('width', this.matrix.buttonSize)
      .attr('height', this.matrix.cellSize)
      .attr('x', this.matrix.titleSize - this.matrix.buttonSize)
      .attr('y', () => this.matrix.availableRowsCount * this.matrix.cellSize + 4);

    button.append('text')
      .text('Add')
      .style('text-anchor', 'middle')
      .attr('alignment-baseline', 'central')
      .attr('x', +rect.attr('x') + this.matrix.buttonSize / 2)
      .attr('y', +rect.attr('y') + this.matrix.halfCellSize);
  }

  private renderRows() {
    this.$container.selectAll('*').remove();
    this.rows.length = 0;
    this.matrix.data.rows.forEach((group: MatrixGroupDataModel) => {
      const length = this.rows.length;

      group.data.forEach((data: any, ind: number) => {
        const config: IConfig = {
          index: ind + length,
          key: data[group.key],
          title: data[group.title],
          color: group.color,
          type: group.type
        };

        this.createRow(config);
      });
    });

    this.renderControls();
  }

  private createRow(config: IConfig) {
    const row = new Row(this.matrix, this, config, {click: this.matrix.rowTitleClick});
    row.render();
    this.rows.push(row);
  }

  private getGroupForRow(type: string): MatrixGroupDataModel {
    return this.matrix.data.rows.find((el: any) => el.type === type);
  }

  private getCurrentRow(type: string, key: any): Row {
    return this.rows
      .filter((el: Row) => el.type === type)
      .find((el: Row) => el.key === key);
  }

}
