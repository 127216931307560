<div class="notifications-popup" *ngIf="notifications.length">
  <div class="notifications-container">
    <div class="notification" *ngFor="let notification of notifications" [routerLink]="notification.link" [class.has-link]="notification.link">
      <div class="left-panel">
        <mat-icon [style.color]="convertSeverityIntoColor(notification.severity)">{{ convertSourceIntoIcon(notification.source) }}</mat-icon>
      </div>
      <div class="right-panel">
        <div class="header">
          {{ notification.title }}
        </div>
        <div class="content">
          {{ notification.content }}
        </div>
      </div>
      <div class="close-panel">
        <mat-icon (click)="closeNotification(notification)">close</mat-icon>
      </div>
    </div>
  </div>
</div>
