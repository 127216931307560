import { BaseModel } from '../base.model';
import { EmployeeModel } from '../auth/employee.model';

export class ChangelogModel extends BaseModel {
  public content: string;
  public author: EmployeeModel;
  public date: Date;

  constructor(data: any = {}) {
    super(data);

    this.content = data.content || void 0;
    this.author = data.author && new EmployeeModel(this.getAuthorData(data.author)) || void 0;
    this.date = data.date || void 0;
  }

  private getAuthorData(author) {
    return Number.isInteger(author) ?  { id: author } :  author;
  }
}
