import * as moment from 'moment-mini';

import { BaseModel } from '../base.model';
import { ShiftAliasModel } from '../activities/shift-alias';
import { ActivityDateModel } from '../activities';
import { diffBetweenDates, parseUsingUTCZeroOffsetFormat } from '../../helpers/date';
import { IRostrRequestBaseModelParams } from '../interfaces';

export class RostrRequestBaseModel extends BaseModel {
  public isFromPreviousSchedule = false;
  public code: string;

  public positionDateFrom: moment.Moment;
  public positionDateTo: moment.Moment;

  public iDate: ActivityDateModel;
  public alias: ShiftAliasModel;

  protected dateFromMoment: moment.Moment;
  protected dateToMoment: moment.Moment;

  constructor(
    data: Partial<IRostrRequestBaseModelParams> = {
      code: '',
      dateFrom: undefined,
      dateTo: undefined,
      iDate: undefined,
      shiftAlias: undefined
    }
  ) {
    super(data);

    this.code = data.code;
    this.iDate = data.iDate ? new ActivityDateModel(data.iDate) : data.iDate;
    this.dateFrom = data.dateFrom || (this.iDate && this.iDate.from) || void 0;
    this.dateTo = data.dateTo || (this.iDate && this.iDate.to) || void 0;
    this.alias = data.shiftAlias ? new ShiftAliasModel(data.shiftAlias) : undefined;
    if (this.iDate && this.iDate.date && this.dateTo && this.dateFrom) {
      this.positionDateFrom = this.iDate.date.clone();
      const dayDiff = Math.abs(diffBetweenDates(this.dateTo, this.dateFrom, 'days'));
      this.positionDateTo = this.positionDateFrom.clone().add(dayDiff === 0 ? 1 : dayDiff, 'day');
    } else {
      this.positionDateFrom = (this.dateFrom && this.dateFrom.clone()) || undefined;
      this.positionDateTo = (this.dateTo && this.dateTo.clone()) || undefined;
    }
  }

  set dateFrom(date) {
    this.dateFromMoment = void 0;

    if (!date) {
      return;
    }

    if (moment.isMoment(date)) {
      this.dateFromMoment = date;
      return;
    }

    this.dateFromMoment = parseUsingUTCZeroOffsetFormat(date);
  }

  get dateFrom() {
    return this.dateFromMoment;
  }

  set dateTo(date) {
    this.dateToMoment = void 0;

    if (!date) {
      return;
    }

    if (moment.isMoment(date)) {
      this.dateToMoment = date;
      return;
    }

    this.dateToMoment = parseUsingUTCZeroOffsetFormat(date);
  }

  get dateTo() {
    return this.dateToMoment;
  }
}
