import * as d3 from 'd3';
import * as moment from 'moment-mini';

import { translate } from '@shared/helpers/d3';

import { SchedulesTimeline } from './timeline';

export class Footer {
  public $monthsAxis: d3.Selection<any, any, any, any>; // Axis g tag
  public $quartersAxis: d3.Selection<any, any, any, any>; // Axis g tag
  public $pagination: d3.Selection<any, any, any, any>; // Axis g tag
  public $paginationBtn: d3.Selection<any, any, any, any>; // Pagination g tag

  constructor(private readonly $el: d3.Selection<any, any, any, any>, private readonly _timeline: SchedulesTimeline) {
    this.$el.attr('visibility', 'hidden');
    this.$monthsAxis = this.$el.append('g').attr('class', 'axis months');
    this.$quartersAxis = this.$el.append('g').attr('class', 'axis quarters');
    this.$pagination = this.$el.append('g').attr('class', 'pagination');

    this.$paginationBtn = this.$pagination
      .selectAll('g.button')
      .data(['chevron_left', 'chevron_right'])
      .enter()
      .append('g')
      .classed('button', true)
      .on('click', (d, i) => this._timeline.context.yearChanged.emit(i ? 1 : -1));

    this.$paginationBtn.append('circle').attr('r', 20).attr('cx', 0);

    this.$paginationBtn
      .append('text')
      .text((d) => d)
      .classed('material-icons', true)
      .attr('transform', (d, i) => translate(i ? -18 : -20, 19));

    this.subscribe();
  }

  public render() {
    this.$el.attr('transform', translate(this._timeline.sizes.groupWidth, this._timeline.sizes.groupsHeight));

    this.$monthsAxis.attr('transform', translate(0, 18));
    this.$quartersAxis.attr('transform', translate(0, 48));
    this.renderAxis();

    this.$pagination.attr('transform', translate(0, 48));
    this.renderPagination();
  }

  private subscribe() {
    this._timeline.onResize.subscribe(() => {
      this.renderAxis();
      this.renderPagination();
    });
  }

  /**
   * Rendering axis
   * @returns {void}
   */
  private renderAxis() {
    const sy = this._timeline.context.selectedYear;
    const monthsAxis = d3.axisBottom(this._timeline.timeScale).tickFormat(d3.timeFormat('%b')).tickSizeOuter(0);

    this.$monthsAxis.call(monthsAxis);
    const monthsTicks = this.$monthsAxis.selectAll('.tick');
    monthsTicks
      .select('text')
      .attr('dy', 0)
      .attr('transform', (d, i) => translate(this.axisLabelAdjust(i) / 2, 0));
    monthsTicks
      .select('line')
      .attr('y1', (d, i) => (i === 0 || i === 12 ? -10 : 0))
      .attr('y2', (d, i) => (i === 0 || i === 12 ? -3 : 12));

    this.$monthsAxis
      .append('g')
      .classed('tick', true)
      .append('text')
      .attr('dy', '-0.5ex')
      .attr('transform', translate(-this.axisLabelAdjust(11) / 2, 13))
      .text('Dec');

    const quartersAxis = d3
      .axisBottom(this._timeline.timeScale)
      .ticks(4)
      .tickFormat((x: Date) => {
        const month = x.getMonth();
        if (month <= 2) {
          return 'Q1';
        } else if (month <= 5) {
          return 'Q2';
        } else if (month <= 8) {
          return 'Q3';
        } else {
          return 'Q4';
        }
      })
      .tickSizeInner(12)
      .tickSizeOuter(0);

    this.$quartersAxis.call(quartersAxis);

    const quartersTicks = this.$quartersAxis.selectAll('.tick');
    quartersTicks
      .select('text')
      .attr('dy', '-0.6ex')
      .attr('transform', (d, i) => translate(this.axisLabelAdjust(i, true) / 2, 0));

    const quartersEnds = quartersTicks.filter((d, i) => i === 0 || i === 4);
    quartersEnds.select('line').attr('y1', 9).attr('y2', 16);

    quartersEnds
      .append('text')
      .classed('year', true)
      .attr('dy', 37)
      .text((d, i) => (i === 0 ? sy : sy + 1));
  }

  private axisLabelAdjust(index, isQuarter?) {
    const sy = this._timeline.context.selectedYear;
    const month = isQuarter ? (index + 1) * 3 : index;
    const lastDay = moment(new Date(sy, month)).daysInMonth();
    return this._timeline.timeScale(new Date(sy, month, lastDay)) - this._timeline.timeScale(new Date(sy, isQuarter ? index * 3 + 1 : index, 1));
  }

  private renderPagination() {
    const end = this._timeline.timeScale(new Date(this._timeline.context.selectedYear, 11, 31, 23, 59));
    this.$paginationBtn.attr('transform', (d, i) => translate(i === 0 ? 0 : end, -12));
  }

  public show() {
    this.$el.attr('visibility', 'visible');
  }
}
