import { STATS_MODE, CUSTOM_PERIOD_MODE } from '../../enums';
import { customPeriodFormattingFunctions } from '../../helpers';
import { ICategoryClass, IFormattingFunction, IRawCategory, IRawCustomPeriod, IStatisticMode } from '../../interfaces';
import { MainStatistic, WeeklyStatistic, CustomPeriodStatistic } from '.';

/* Category containers */

export abstract class Category implements ICategoryClass {
  abstract getStatsForMode(mode: STATS_MODE | CUSTOM_PERIOD_MODE): IStatisticMode;
  abstract setData(data: IRawCategory | IRawCustomPeriod, employeeIds?: number[]): void;

  private employeesChanged: Set<number> = new Set([]);
  public loaded = false;

  public setEmployeesChanged(ids: number[]): void {
    this.employeesChanged = new Set([...this.employeesChanged, ...ids]);
  }

  public clearEmployeeChanged(): void {
    this.employeesChanged = new Set([]);
  }

  public getChangedEmployees(): number[] {
    return Array.from(this.employeesChanged);
  }

  public hasChangedEmployees(): boolean {
    return this.employeesChanged.size > 0;
  }
}

export class StatCategory extends Category {
  private readonly statistics: { [mode in STATS_MODE]: IStatisticMode };

  constructor(formattingFunction: IFormattingFunction) {
    super();
    this.statistics = {
      [STATS_MODE.AveragePeriod]: new MainStatistic(formattingFunction),
      [STATS_MODE.Schedule]: new MainStatistic(formattingFunction),
      [STATS_MODE.Weekly]: new WeeklyStatistic(formattingFunction)
    };
  }

  public setData(data: IRawCategory, employeeIds?: number[]): void {
    this.statistics[STATS_MODE.AveragePeriod].setData(data[STATS_MODE.AveragePeriod], employeeIds);
    this.statistics[STATS_MODE.Schedule].setData(data[STATS_MODE.Schedule], employeeIds);
    this.statistics[STATS_MODE.Weekly].setData(data[STATS_MODE.Weekly], employeeIds);
  }

  public getStatsForMode(mode: STATS_MODE): IStatisticMode {
    return this.statistics[mode];
  }
}

export class CustomPeriod extends Category {
  private statistics: CustomPeriodStatistic;

  constructor() {
    super();
    this.statistics = new CustomPeriodStatistic();
  }

  public setData(data: IRawCustomPeriod, employeeIds?: number[]): void {
    this.statistics.setData(data, employeeIds);
  }

  public getStatsForMode(mode: CUSTOM_PERIOD_MODE): CustomPeriodStatistic {
    this.statistics.formattingFunction = customPeriodFormattingFunctions[mode];
    return this.statistics;
  }
}
