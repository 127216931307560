import { Injectable } from '@angular/core';
import { Filter } from '@interfaces';

@Injectable()
export class FilteringService<T = unknown> {
  public filters: Filter[] = [];

  public filterRecords(records: T[]): T[] {
    return records.filter(element => {
      return this.filters.every(filter => this.matchElementAndFilter(element, filter));
    });
  }

  public addFilter(filter: Filter): void {
    const index = this.filters.findIndex(filterItem => filter.property === filterItem.property);
    if (this.isFilterDisabled(filter)) {
      if (index < 0) {
        return;
      }
      this.filters.splice(index, 1);
    } else {
      if (index < 0) {
        this.filters.push(filter);
      } else {
        this.filters[index] = filter;
      }
    }
  }

  public getFilterValueByProperty(property: string): Filter {
    return this.filters.find(filter => filter.property === property);
  }

  public reset(): void {
    this.filters = [];
  }

  private matchElementAndFilter(record: T, { property, items, blank, compareFunction }: Filter): boolean {
    const isDefined = record[property] !== undefined && record[property] !== null;
    if (!blank && !isDefined) {
      return false;
    }
    return (blank && (!isDefined || record[property].length === 0)) || items.some(item => compareFunction(record[property], item));
  }

  private isFilterDisabled(filter: Filter) {
    return filter.items.length === 0 && !filter.blank;
  }
}
