<div class="conversation-container">
  <div>
    <div class="wrapper" #commentsScroll>
      <div *ngIf="!comments?.length" class="no-items">There are no comments yet</div>
      <div *ngFor="let comment of comments" class="comment" [class.self]="currentUserId === comment.authorId" [class.unread]="isNotReadAndNotOwn(comment)">
        <div *ngIf="commentBeingEditedId !== comment.id">
          <div class="comment-header">{{ comment.author }} {{ toHumanReadableDate(comment.createdAt) }}</div>
          <div>{{ comment.message }}</div>
          <div *ngIf="currentUserId === comment.authorId && confirmDeleteId !== comment.id" class="actions-box">
            <mat-icon (click)="startEditing(comment)">edit</mat-icon>
            <mat-icon class="delete" (click)="promptDelete(comment.id)">delete</mat-icon>
          </div>
          <div *ngIf="confirmDeleteId === comment.id" class="confirm-delete-box">
            <div>Confirm</div>
            <mat-icon class="cancel" (click)="cancelDelete()">close</mat-icon>
            <mat-icon class="accept" (click)="deleteComment(comment.id)">done</mat-icon>
          </div>
        </div>
        <div class="edit" *ngIf="commentBeingEditedId === comment.id">
          <mat-form-field appearance="fill">
            <input matInput [(ngModel)]="updatedText" (keyup.enter)="acceptChanges(comment)" (keyup.escape)="cancelChanges()" />
          </mat-form-field>
          <div *ngIf="currentUserId === comment.authorId" class="edit-actions-box">
            <mat-icon class="cancel" (click)="cancelChanges()">close</mat-icon>
            <mat-icon class="accept" (click)="acceptChanges(comment)">done</mat-icon>
          </div>
        </div>
      </div>
    </div>
    <div class="write-box">
      <mat-form-field appearance="fill">
        <mat-label>Write a comment</mat-label>
        <input maxlength="500" matInput [(ngModel)]="commentText" (keyup.enter)="createComment()" autocomplete="off" />
        <mat-hint align="end">{{ commentText?.length }} / 500</mat-hint>
        <mat-icon class="send" matSuffix (click)="createComment()">send</mat-icon>
      </mat-form-field>
    </div>
  </div>
</div>
