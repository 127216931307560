import { Observable } from 'rxjs';
import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { ErrorService } from '@services';
import { catchError, map } from 'rxjs/operators';
import { scheduleHistoryEntries, scheduleHistoryUpdatedSubscription, applyHistoryBatch } from '../queries/schedule-history.queries';
import { ScheduleHistoryEntry } from '../../dto';

@Injectable({ providedIn: 'root' })
export class ScheduleHistoryAPIService {
  constructor(
    private readonly apollo: Apollo,
    private readonly errorService: ErrorService
  ) {}

  public getScheduleHistory(scheduleId: number): Observable<ScheduleHistoryEntry[]> {
    return this.apollo
      .query<{ scheduleHistoryEntries: ScheduleHistoryEntry[] }>({
        query: scheduleHistoryEntries,
        variables: {
          scheduleId: scheduleId
        }
      })
      .pipe(
        map(response => {
          return response.data.scheduleHistoryEntries;
        }),
        catchError(err => {
          return this.errorService.handle(err);
        })
      );
  }

  public applyHistoryBatch(batch: number, scheduleId: number): Observable<void> {
    return this.apollo
      .mutate({
        mutation: applyHistoryBatch,
        variables: {
          batch: batch,
          scheduleId: scheduleId
        }
      })
      .pipe(
        catchError(err => {
          return this.errorService.handle(err);
        })
      ) as Observable<void>;
  }

  public onScheduleHistoryUpdated$(scheduleId: number): Observable<ScheduleHistoryEntry[]> {
    return this.apollo
      .subscribe({
        query: scheduleHistoryUpdatedSubscription,
        variables: {
          scheduleId
        }
      })
      .pipe(
        map(
          (response: { data: { scheduleHistoryUpdated: { entries: ScheduleHistoryEntry[] } } }) =>
            response.data.scheduleHistoryUpdated.entries.map(x => {
              return { ...x };
            }),
          catchError((err: Error) => {
            return this.errorService.handle(err);
          })
        )
      );
  }
}
