import * as d3 from 'd3';

import { takeUntil } from 'rxjs/operators';
import { pathForRoundedRect, translate, xmlns } from '@helpers';

import { IBaseTimeline } from './interfaces';
import { IBaseItem, IOpenableItem, isOpenableItem } from '../items/interfaces';

interface IGroupIndicationConfig {
  fillColor?: string;
  textColor?: string;
  opacity?: number;
  x?: number;
  y?: number;
  text?: string;
  icon?: string;
}

export class GroupIndication {
  public el: Element;
  public $el: d3.Selection<any, any, any, any>;

  public $path: d3.Selection<any, any, any, any>;
  public $text: d3.Selection<any, any, any, any>;

  protected config: IGroupIndicationConfig = {
    fillColor: this.item.indicationFillColor
  };

  protected rendered = false;
  protected isSubIndication = false;

  constructor(protected item: IBaseItem, protected timeline: IBaseTimeline) {
    this.el = document.createElementNS(xmlns, 'g');
    this.$el = d3.select(this.el).classed('indication', true);
    this.$path = this.$el.append('path');
    this.$text = this.$el.append('text');

    this.subscribe();
  }

  public render(config: IGroupIndicationConfig = this.config) {
    const isFirst = this.item.isFirst;
    let isLast = this.item.isLast;

    this.isSubIndication = false;

    const item: unknown = this.item;
    if (isLast && isOpenableItem(item) && (item as IOpenableItem).isOpen()) {
      isLast = false;
    }

    this.drawIndication(isFirst, isLast, config);
  }

  public renderForSubItem(config: IGroupIndicationConfig = this.config) {
    const parent = this.item.parent;
    const isFirst = false;
    const isLast = parent.isLast && this.item.isLast;

    this.isSubIndication = true;

    this.drawIndication(isFirst, isLast, config);
  }

  public update(config: IGroupIndicationConfig = this.config) {
    if (!this.rendered) {
      return;
    }

    if (this.isSubIndication) {
      this.renderForSubItem(config);
      return;
    }

    this.render(config);
  }

  protected drawIndication(isFirst: boolean, isLast: boolean, config: IGroupIndicationConfig) {
    if (!this.rendered) {
      this.item.node().appendChild(this.el);
      this.rendered = true;
    }

    this.updateConfiguration(config);

    const { x: xPos, fillColor, textColor, opacity, text, icon } = this.config;

    let { y: yPos } = this.config;

    const itemHeight = this.item.height;

    const offset = isFirst && isLast ? yPos * 2 : yPos;
    const height = itemHeight - offset;

    if ((!isFirst && !isLast) || (!isFirst && isLast)) {
      yPos = 0;
    }

    const numberPos = (itemHeight - yPos * 2) / 2;

    this.$el.attr('transform', translate(xPos, yPos));

    this.$path
      .style('fill', fillColor)
      .style('opacity', opacity)
      .attr('d', this.indicationPath(!isFirst && !isLast ? itemHeight : height, isFirst, isLast));

    this.$text
      .text(text)
      .style('fill', textColor)
      .attr('dy', '0.5ex')
      .attr('x', 10)
      .attr('y', numberPos)
      .classed('material-icons', !!icon);

    if (icon) {
      this.$text
        .text(icon)
        .attr('x', 3)
        .attr('y', numberPos + 7);
    }
  }

  protected indicationPath(height: number, top: boolean, bottom: boolean) {
    return pathForRoundedRect(0, 0, 20, height, 10, top, top, bottom, bottom);
  }

  protected updateConfiguration(config: IGroupIndicationConfig) {
    if (!config) {
      return;
    }

    Object.assign(this.config, config);

    if (!this.config.x) {
      this.config.x = this.isSubIndication ? 12 : 7;
    }

    if (!this.config.y) {
      this.config.y = 5;
    }
  }

  protected subscribe() {
    const timeline = this.timeline;
    const menu = timeline.menu();

    menu.onChange.pipe(takeUntil(timeline.onDestroy)).subscribe(() => this.update());
  }
}
