type WSLockModelData = {
  id?: number; // locked ReportPlugin.id
  type?: string;
  createdBy?: number;
  timeout?: number;
  timeToLive?: number;
}

export class WSLockModel {

  public id: number; // locked ReportPlugin.id
  public type: string; // of locked element
  public createdBy: number;
  public timeout: number; // Measured in minutes
  public timeToLive: number; // Measured in seconds

  constructor(data: WSLockModelData = {}) {
    this.id = data?.id || null; // locked ReportPlugin.id
    this.type = data?.type || '';
    this.createdBy = data?.createdBy || null;
    this.timeout = data?.timeout || 60;
    this.timeToLive = data?.timeToLive || null;
  }
}
