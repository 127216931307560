import { Component, Inject } from '@angular/core';

import * as moment from 'moment-mini';

import { TooltipContext } from '@component/tooltip/tooltip.config';
import { FORMAT_WEEKDAY_DAY_MONTH_YEAR_SHORT } from '@helpers';
import { AgendaUnavailability, AgendaEmployeeUnavailability } from '@app/agenda/_shared';

@Component({
  selector: 'sh-datepicker-tooltip',
  templateUrl: 'datepicker.tooltip.component.html',
  styleUrls: ['datepicker.tooltip.component.scss']
})
export class DatepickerTooltipComponent {

  public date: string;
  employeesUnavailabilitySorted: AgendaEmployeeUnavailability[];

  constructor(@Inject(TooltipContext) public agendaUnavailability: AgendaUnavailability) {
    this.date = this.formatDate(this.agendaUnavailability.date);
    this.employeesUnavailabilitySorted = this.getSortedEmployeesUnavailability();
  }

  private getSortedEmployeesUnavailability(): AgendaEmployeeUnavailability[] {
    if (!this.agendaUnavailability) {
      return null;
    }

    const employeesUnavailabilityCopy = [...this.agendaUnavailability.employeesUnavailability];
    return employeesUnavailabilityCopy.sort(this.compareUnavailabilities);
  }

  private compareUnavailabilities(a: AgendaEmployeeUnavailability, b: AgendaEmployeeUnavailability): number {
    const sortLastName = a.employee.lastName.localeCompare(b.employee.lastName);
    const sortFirstName = a.employee.firstName.localeCompare(b.employee.firstName);
    const sortReason = a.reason.localeCompare(b.reason);

    return sortLastName || sortFirstName || sortReason;
  }

  private formatDate(date: string) {
    return moment(date).format(FORMAT_WEEKDAY_DAY_MONTH_YEAR_SHORT);
  }
}
