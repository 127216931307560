import { SkillModel } from '@model/profile/skill.model';
import { BaseModel } from '../base.model';
import { AssignedGroupModel } from './assigned-group.model';

export class AssignedEmployeeModel extends BaseModel {

  public name: string;
  public group: AssignedGroupModel;
  public firstName: string;
  public middleName: string;
  public lastName: string;
  public order: number;
  public skills: SkillModel[];

  constructor(data: any = {}) {
    super(data);

    this.name = data.name || '';
    this.group = (data.assignedGroup && new AssignedGroupModel(data.assignedGroup)) || undefined;
    this.firstName = data.firstName;
    this.middleName = data.middleName;
    this.lastName = data.lastName;
    this.order = data.order;
    this.skills = data.skills && data.skills.map(skill => new SkillModel(skill));
  }
}
