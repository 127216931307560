import { gql } from 'apollo-angular';

export const fragments = {
  alias: gql`
    fragment ShiftAliasInfo on ShiftAlias {
      id
      timeFrom
      timeTo
      start
      window
      duration
      alias
      value
      active
      type
    }
  `
};

export const loadShiftAliases = gql`
  query loadShiftAliases($targetName: String!, $targetId: Int!, $type: String) {
    shiftAliases(targetName: $targetName, targetId: $targetId, type: $type) {
      ...ShiftAliasInfo
    }
  }
  ${fragments.alias}
`;

export const bulkShiftAliasOperation = gql`
  mutation bulkOperation($created: [ShiftAliasInput], $updated: [ShiftAliasInput], $deleted: [Int]) {
    bulkShiftAliasOperation(created: $created, updated: $updated, deleted: $deleted) {
      created {
        ...ShiftAliasInfo
      }

      updated {
        ...ShiftAliasInfo
      }

      deleted {
        id
      }
    }
  }
  ${fragments.alias}
`;
