import { gql } from 'apollo-angular';

export const loadUnit = gql`
  query loadUnit($id: Int!) {
    unit(id: $id) {
      id
      name
      icao
      abbreviation
      siteId
      sectorIds
    }
  }
`;

export const loadUnits = gql`
  query loadUnits {
    units {
      id
      name
      icao
      abbreviation
      siteId
      sectorIds
      groups {
        id
        name
      }
    }
  }
`;

export const loadAgendaUnits = gql`
  query loadAgendaUnits {
    agendaUnits {
      id
      name
      icao
      abbreviation
      siteId
      sectorIds
      groups {
        id
        name
      }
    }
  }
`;

export const createUnit = gql`
  mutation createUnit(
    $name: String!,
    $icao: String,
    $abbreviation: String
  ) {
    createUnit(
      name: $name,
      icao: $icao,
      abbreviation: $abbreviation
    ) {
      id
      name
      icao
      abbreviation
    }
  }
`;

export const updateUnit = gql`
  mutation updateUnit(
    $id: Int!,
    $name: String!,
    $icao: String,
    $abbreviation: String
  ) {
    updateUnit(
      id: $id,
      name: $name,
      icao: $icao,
      abbreviation: $abbreviation
    ) {
      id
      name
      icao
      abbreviation
      sectorIds
    }
  }
`;

export const deleteUnit = gql`
  mutation deleteUnit($id: Int!) {
    deleteUnit(id: $id) {
      id
    }
  }
`;

export const createUnitSiteRelation = gql`
  mutation createUnitSiteRelation(
    $unitId: Int!,
    $siteId: Int!
  ) {
    createUnitSiteRelation(
      unitId: $unitId,
      siteId: $siteId
    )
  }
`;

export const deleteUnitSiteRelation = gql`
  mutation deleteUnitSiteRelation($unitId: Int!) {
    deleteUnitSiteRelation(unitId: $unitId)
  }
`;

export const createUnitSectorRelation = gql`
  mutation createUnitSectorRelation(
    $unitId: Int!,
    $sectorId: Int!
  ) {
    createUnitSectorRelation(
      unitId: $unitId,
      sectorId: $sectorId
    )
  }
`;

export const deleteUnitSectorRelation = gql`
  mutation deleteUnitSectorRelation(
    $sectorId: Int!,
    $unitId: Int!
  ) {
    deleteUnitSectorRelation(
      unitId: $unitId,
      sectorId: $sectorId
    )
  }
`;
