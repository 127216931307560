import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { convertSeverityIntoColor, convertSourceIntoIcon } from '@component/notifications/helpers/notification-helper';
import { NotificationModel } from '@component/notifications/models';

@Component({
  selector: 'app-notifications-dialog',
  templateUrl: './notifications-dialog.component.html',
  styleUrls: ['./notifications-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationsDialogComponent {
  public convertSourceIntoIcon = convertSourceIntoIcon;
  public convertSeverityIntoColor = convertSeverityIntoColor;

  constructor(@Inject(MAT_DIALOG_DATA) public data: NotificationModel) {}
}
