<div class="tabs" *ngIf="tabs.length">
  <pl-tab class="tab prev-btn" (click)="select(null, true)"></pl-tab>
  <pl-tab
    class="tab"
    *ngFor="let tab of tabs"
    [style.z-index]="blocksCount - tab.offset"
    [class.active]="tab.current"
    [class.disabled]="!tab.current && editMode"
    (click)="select(tab)"
    [color]="getColor(tab.offset)"
  >
    {{ tab.title }}
  </pl-tab>
  <pl-tab class="tab next-btn" (click)="select()"></pl-tab>
</div>
