import { gql } from 'apollo-angular';

export const startJob = gql`
  mutation rostrStartAvaiJob($schedule: Int!, $algorithm: String!) {
    rostrStartAvaiJob(schedule: $schedule, algorithm: $algorithm) {
      id
      module
      type
      targetType
      targetId
      status
      isActive
      data
      createdAt
      updatedAt
    }
  }
`;

export const stopJob = gql`
  mutation rostrStopAvaiJob($schedule: Int!) {
    rostrStopAvaiJob(schedule: $schedule) {
      id
      module
      type
      targetType
      targetId
      status
      isActive
      data
    }
  }
`;

export const jobCreated = gql`
  subscription jobCreated($targetId: Int, $targetType: String) {
    jobCreated(targetId: $targetId, targetType: $targetType) {
      id
      module
      type
      targetType
      targetId
      status
      isActive
      data
      createdAt
      createdBy {
        firstName
        lastName
      }
      updatedAt
    }
  }
`;

export const jobChanged = gql`
  subscription jobUpdated($targetId: Int, $targetType: String) {
    jobUpdated(targetId: $targetId, targetType: $targetType) {
      id
      module
      type
      targetType
      targetId
      status
      isActive
      data
      createdAt
      createdBy {
        firstName
        lastName
      }
      updatedAt
    }
  }
`;
