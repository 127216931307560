import { CommentModel } from '@models';
export enum CommentAction {
    CREATE = 1,
    UPDATE = 2,
    REMOVE = 3,
    CANCEL = 4
}

export interface ICommentsCallbackProtocol {
  action: CommentAction,
  target: CommentModel
}
