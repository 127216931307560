import { Injectable } from '@angular/core';
import { WARNING_CHAR } from '@constants';
import { DialogService } from '../dialog/dialog.service';
import { IValidationResult } from './validation-result';

@Injectable()
export class ValidationService<T> {

  private readonly rules: ((entity: T) => Promise<IValidationResult>)[] = [];

  constructor(private readonly dialogService: DialogService) { }

  public addRule(validator: (entity: T) => Promise<IValidationResult>) {
    this.rules.push(validator);
  }

  public async validate(data: T): Promise<boolean> {
    const validationResults = await this.applyValidationRules(data);
    const invalidValidationResults = validationResults.filter(result => !result.isValid);

    if (invalidValidationResults.length) {
      return this.communicateToTheUser(invalidValidationResults);
    }

    return true;
  }

  private async applyValidationRules(data: T): Promise<IValidationResult[]> {
    return Promise.all(
      this.rules.map(async ruleValidation => {
        return ruleValidation(data);
      })
    );
  }

  private hasError(results: IValidationResult[]): boolean {
    return results
      .filter(result => result.isError)
      ?.length > 0;
  }

  private formatMessage(result: IValidationResult): string {
    const cssClass = result.isError ? 'invalid' : '';
    return `<span class="${cssClass}">${WARNING_CHAR} ${result.message}</span>`;
  }

  private validationMessageComparator(a: IValidationResult, b: IValidationResult) {
    if (a.isError === b.isError) {
      return 0;
    }

    return a.isError > b.isError ? -1 : 1;
  }

  private async communicateToTheUser(results: IValidationResult[]) {
    const concatenatedMessage = results
      .sort(this.validationMessageComparator)
      .map(result => this.formatMessage(result))
      .join(`\n\n`);

    if (this.hasError(results)) {
      await this.dialogService.alert('There is an error.', concatenatedMessage, null, null, 'break-spaces');
      return false;
    }

    return this.dialogService.confirm('Are you sure to continue?', concatenatedMessage, null, null, 'break-spaces');
  }
}
