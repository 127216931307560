import { gql } from 'apollo-angular';

export const loadGroup = gql`
  query loadGroup($id: Int!) {
    group(id: $id) {
      id
      name
      description
      argusAutomaticOverwrite
      unit {
        name
        abbreviation
      }
      licenses {
        id
        name
      }
      skills {
        id
        name
      }
      unitEndorsements {
        id
        title
      }
    }
  }
`;

export const loadGroupWithShifts = gql`
  query Group($groupId: Int!) {
    group(id: $groupId) {
      id
      name
      shiftCodes {
        id
        code
        metacode
        comment
        type {
          id
          name
          countAsWork
        }
        color
        start
        duration
        workingTimePercentage
        workingTimeInMinutes
        isQuicklyAccessible
        licenses {
          id
          name
        }
        skills {
          id
          name
        }
        unitEndorsements {
          id
          title
        }
        isComplex
        details {
          id
          start
          duration
          typeId
          type {
            id
            name
            countAsWork
          }
          workingTimePercentage
          workingTimeInMinutes
        }
      }

      name
    }
  }
`;

export const loadGroupQualifications = gql`
  query groupQualifications($groupId: Int!) {
    group(id: $groupId) {
      licenses {
        id
        name
      }
      skills {
        id
        name
      }
      unitEndorsements {
        id
        title
      }
    }
  }
`;

export const loadShiftsForGroup = gql`
  query Group($groupId: Int!) {
    group(id: $groupId) {
      id
      shiftGroupQualifications {
        id
        shiftCode {
          id
          code
          type {
            id
          }
          start
          duration
          workingTimeInMinutes
          isQuicklyAccessible
          shiftAlias {
            id
            value
          }
        }
      }
      name
    }
  }
`;

export const loadGroups = gql`
  query loadGroups {
    groups {
      id
      name
      description
      unit {
        id
        name
        abbreviation
      }
    }
  }
`;

export const loadAgendaGroups = gql`
  query loadAgendaGroups {
    agendaGroups {
      id
      name
      description
      unit {
        id
        name
        abbreviation
      }
    }
  }
`;

export const loadMyGroups = gql`
  query MyGroups {
    myGroupsAsResourcePlanner {
      id
      name
    }
  }
`;

export const loadGroupEmployees = gql`
  query GroupEmployees($groupId: Int!) {
    groupEmployees(groupId: $groupId) {
      id
      firstName
      lastName
      preferences {
        hardWorkDurationMax
        hardWeeklyWorkMax
        standardWorkingHoursPerWeek
        workingDaysInRow
        hardAfterShiftBreak
      }
    }
  }
`;

export const saveRoleAssignmentsForGroup = gql`
  mutation SaveRoleAssignmentsForGroup($groupId: Int!, $employeeRoleIds: [Int]!) {
    saveRoleAssignmentsForGroup(groupId: $groupId, employeeRoleIds: $employeeRoleIds) {
      groupId
    }
  }
`;

export const deleteShiftGroupQualifications = gql`
  mutation DeleteShiftGroupQualification($ids: [Int]!) {
    deleteShiftGroupQualification(ids: $ids)
  }
`;

export const createBulkShiftGroupQualifications = gql`
  mutation CreateBulkShiftGroupQualification($data: [ShiftGroupQualificationInput]!) {
    createBulkShiftGroupQualification(data: $data) {
      id
    }
  }
`;

export const upsertGroupQualifications = gql`
  mutation UpsertGroupQualifications($data: GroupQualificationInput!) {
    upsertGroupQualifications(data: $data) {
      id
    }
  }
`;

export const updateGroup = gql`
  mutation updateGroup($id: Int!, $argusAutomaticOverwrite: Boolean!) {
    updateGroup(id: $id, argusAutomaticOverwrite: $argusAutomaticOverwrite) {
      id
    }
  }
`;
