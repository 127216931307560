import { EventEmitter } from '@angular/core';
import * as d3 from 'd3';
import { takeUntil } from 'rxjs/operators';

import { translate } from '@helpers';

import { Defs } from '../defs/defs';
import { Menu } from '../menu/menu';
import { IShift } from '../shifts/base.shift';
import { Timeline } from '../timeline';
import { BaseItem } from './base.item';
import { IItemConfig, IOpenableItem } from './interfaces';
import { RootItem } from './root.item';
import { ShiftsBasedItem } from './shifts-based.item';
import { SimpleItem } from './simple.item';
import { IBaseGrid } from '../grid/base-grid.interface';

export class OpenableItem extends ShiftsBasedItem implements IOpenableItem {
  public onOpen: EventEmitter<any> = new EventEmitter();
  public onClose: EventEmitter<any> = new EventEmitter();
  public onAddItem: EventEmitter<any> = new EventEmitter();
  public onChangeHeight: EventEmitter<any> = new EventEmitter();
  protected $toggle: d3.Selection<any, any, any, any>;
  protected $pointer: d3.Selection<any, any, any, any>;
  protected $items: d3.Selection<any, any, any, any>;

  constructor(
    protected _title = '',
    protected _items: BaseItem[] = [],
    protected _shifts: IShift[] = [],
    protected _opened = true,
    protected _itemConfig: IItemConfig = { height: 40 }
  ) {
    super(_title, _shifts, _itemConfig);
    this.shifts(_shifts);
  }

  get items() {
    return this._items;
  }

  set parent(parent: RootItem | OpenableItem) {
    this._parent = parent;

    this._items.forEach(item => (item.parent = this));
  }

  get parent() {
    return this._parent;
  }

  /**
   * Rendering functionality
   * @returns {Element}
   */
  public render() {
    super.render();

    this._items.forEach(item => {
      this.addSubItem(item);
    });

    this.setToggle();

    this.events();

    return this.el;
  }

  /**
   * DOM event listeners
   */
  public events() {}

  /**
   * Subscribe to event emitters
   */
  public subscribe() {
    // this._menu.onChange.subscribe(() => {
    //   // if (this.$gridBackgroundContainer) {
    //   //   this.$gridBackgroundContainer.attr('transform', translate(10, this.y()))
    //   // }
    //   // this.$items.attr('y', this.offset());
    //   // this.$items.attr('visibility', (this._menu.visible().indexOf(this) === -1 ? 'hidden' : 'visible'));
    // });

    this._grid.onManualRender.pipe(takeUntil(this._timeline.onDestroy)).subscribe(() => {
      this.sortAssignedShifts();
    });

    this._items.forEach((item: any) => {
      this.addEventsToItem(item);
    });

    this._shifts.forEach((shift: IShift) => {
      shift.subscribe();
    });
  }

  // FIXME DRY
  public addEventsToItem(item) {
    if (item instanceof RootItem || item instanceof OpenableItem) {
      item.onOpen.pipe(takeUntil(this._timeline.onDestroy)).subscribe(() => {
        this.onOpen.next(this);
      });
      item.onClose.pipe(takeUntil(this._timeline.onDestroy)).subscribe(() => {
        this.onClose.next(this);
      });
    }
    if (item.onChangeHeight) {
      item.onChangeHeight.pipe(takeUntil(this._timeline.onDestroy)).subscribe(v => {
        this.onChangeHeight.next(v);
      });
    }
    item.subscribe();
  }

  public updateItemsPosition(items: BaseItem[]) {
    this._items = items;
    this._menu.updateTree();
    this.items.forEach(item => item.updatePosition());
  }

  public updatePosition() {
    super.updatePosition();

    this.items.forEach(item => item.updatePosition());
  }

  public totalHeight() {
    if (this.offsetBottom && this._opened) {
      const offset = this.offsetBottom.renderProps.offset;
      return super.totalHeight() - offset;
    } else {
      return super.totalHeight();
    }
  }

  /**************************************************************************/
  /**
   * Show child items
   */
  public open() {
    if (!this._items.length) {
      return;
    }

    if (this.offsetBottom) {
      this.offsetBottom.hide();
    }

    this._opened = true;
    this.onOpen.emit();
    this.opened();
  }

  public childrenItems() {
    return this._items;
  }

  /**
   * Hide child items
   */
  public close() {
    if (!this._items.length) {
      return this;
    }

    if (this.offsetBottom) {
      this.offsetBottom.show();
    }

    this._opened = false;
    this.onClose.next(this);
    this.closed();
  }

  /**
   * Close or open depending in current state
   */
  public toogle() {
    return this._opened ? this.close() : this.open();
  }

  /**
   * Is item opened ?
   * @returns {boolean}
   */
  public isOpen() {
    return this._opened;
  }

  /**
   * Will return y offset from top of items to this bottom
   * @returns {number}
   */
  public offset(): number {
    return this._opened ? this.y() + this.height : this.y() + this.totalHeight();
  }

  /*********************************** Shifts ********************************/
  /**
   * Getter and setter for shifts property
   * @param shifts
   * @returns {any}
   */
  public shifts(shifts?: IShift[]): this | IShift[] {
    if (!arguments.length) {
      return this._shifts;
    }
    this._shifts = shifts;
    shifts.forEach((shift: IShift) => {
      shift.item(this);
    });
    return this;
  }

  /**
   * Add shift to item shifts
   * @param shift
   * @returns {OpenableItem}
   */
  public asyncAddShift(shift: IShift) {
    shift.item(this);
    shift.timeline(this._timeline);
    shift.defs(this._defs);
    shift.grid(this._grid);
    shift.menu(this._menu);
    shift.subscribe();

    this.addShift(shift);

    this._grid.assignToAvailableInterval(shift);

    return this;
  }

  public renderShifts() {
    this._shifts.forEach(shift => {
      shift.render();
    });
  }

  public addSubItem(item) {
    item.parent = this;
    item.timeline(this._timeline);
    item.menu(this._menu);
    item.defs(this._defs);
    item.grid(this._grid);
    item.index = this.items.length;
    item.level = this.level + 1;

    if (item instanceof RootItem || item instanceof OpenableItem) {
      item.onOpen.pipe(takeUntil(this._timeline.onDestroy)).subscribe(target => {
        this.onOpen.next(this);
      });
      item.onClose.pipe(takeUntil(this._timeline.onDestroy)).subscribe(target => {
        this.onClose.next(this);
      });
    }
    if (item.onChangeHeight) {
      item.onChangeHeight.pipe(takeUntil(this._timeline.onDestroy)).subscribe(v => {
        this.onChangeHeight.next(v);
      });
    }
    item.subscribe();

    if (this._items.indexOf(item) === -1) {
      this._items.push(item);
    }

    const visible = this._menu.visible;

    if (this._opened) {
      if (visible.indexOf(item) === -1) {
        visible.push(item);
      }
    } else {
      item.hide();
    }
    this._menu.node.appendChild(item.render());
    this.setToggle();
    this.onAddItem.next(item);
  }

  public insertSubItemBefore(item) {
    item.parent = this._parent;
    item.timeline(this._timeline);
    item.menu(this._menu);
    item.defs(this._defs);
    item.grid(this._grid);

    if (item.onChangeHeight) {
      item.onChangeHeight.takeUntil(this._timeline.onDestroy).subscribe(v => {
        this.onChangeHeight.next(v);
      });
    }

    if (item.onAddItem) {
      item.onAddItem.takeUntil(this._timeline.onDestroy).subscribe(v => {
        this.onAddItem.next(v);
      });
    }

    if (item.onClose) {
      item.onClose.takeUntil(this._timeline.onDestroy).subscribe(v => {
        this.onClose.next(v);
      });
    }

    if (item.onOpen) {
      item.onOpen.takeUntil(this._timeline.onDestroy).subscribe(v => {
        this.onOpen.next(v);
      });
    }

    item.subscribe();

    const pItems = this._parent.items;
    const parentInsertIndex = pItems.indexOf(this);
    pItems.splice(parentInsertIndex, 0, item);

    item.index = pItems.indexOf(item);
    item.level = this.level;

    const visible = this._menu.visible;
    if (this._opened) {
      const insertVisibleIndex = this._items.indexOf(this);
      visible.splice(this.number(), insertVisibleIndex, item);
      visible.push(item);
    } else {
      item.hide();
    }

    item.render();

    this._menu.node.insertBefore(item.element().node(), this.$el.node());

    this.onAddItem.next(item);
    // this._menu.element.insert(item.element(), this.el);
  }

  public insertSubItemAfter(item) {
    item.parent = this._parent;
    item.timeline(this._timeline);
    item.menu(this._menu);
    item.defs(this._defs);
    item.grid(this._grid);

    if (item.onChangeHeight) {
      item.onChangeHeight.takeUntil(this._timeline.onDestroy).subscribe(v => {
        this.onChangeHeight.next(v);
      });
    }

    if (item.onAddItem) {
      item.onAddItem.takeUntil(this._timeline.onDestroy).subscribe(v => {
        this.onAddItem.next(v);
      });
    }

    if (item.onClose) {
      item.onClose.takeUntil(this._timeline.onDestroy).subscribe(v => {
        this.onClose.next(v);
      });
    }

    if (item.onOpen) {
      item.onOpen.takeUntil(this._timeline.onDestroy).subscribe(v => {
        this.onOpen.next(v);
      });
    }

    item.subscribe();

    const pItems = this._parent.items;
    const parentInsertIndex = pItems.indexOf(this);

    pItems.splice(parentInsertIndex + 1, 0, item);

    item.index = pItems.indexOf(item);
    item.level = this.level;

    const visible = this._menu.visible;

    if (this._opened) {
      const insertVisibleIndex = this._items.indexOf(this);
      visible.splice(insertVisibleIndex + 1, 0, item);
      visible.push(item);
    } else {
      item.hide();
    }

    item.render();

    const afterElement = pItems[parentInsertIndex + 2];
    if (afterElement) {
      this._menu.node.insertBefore(item.element().node(), afterElement.element().node());
    } else {
      this._menu.node.appendChild(item.element().node());
    }

    this.onAddItem.next(item);
  }

  public removeSubItem(item) {
    const itemIndex = this._items.indexOf(item);
    this._items.splice(itemIndex, 1);

    item.remove();

    this.onChangeHeight.emit();
  }

  /**
   * Remove shift
   * @param shift
   * @returns {OpenableItem}
   */
  public removeShift(shift: IShift) {
    const index = this._shifts.indexOf(shift);
    if (index > -1) {
      this._shifts.splice(index, 1);
    }
    return this;
  }

  /**
   * Y position for shift
   * @param shift
   */
  public shiftPosition(shift: IShift): number {
    return this.y();
  }

  /*******************************************************************/
  /**
   * Remove functionality
   */
  public remove() {
    this._shifts.forEach(shift => {
      shift.remove();
    });
    this._items.forEach(item => {
      item.remove();
    });

    super.remove();

    this.onChangeHeight.emit(this);
  }

  /*************************************************************************************************/
  /**
   * Setter for grid property
   * @param grid
   * @returns {Baseitem}
   */
  public grid(grid: IBaseGrid): this {
    if (grid) {
      super.grid(grid);

      this._shifts.forEach(shift => {
        shift.grid(grid);
      });
      this._items.forEach((item: SimpleItem) => {
        item.grid(grid);
      });
    }
    return this;
  }

  /**
   * Setter for timeline property
   * @param timeline
   * @returns {Baseitem}
   */
  public timeline(timeline: Timeline): this {
    if (timeline) {
      super.timeline(timeline);

      this._shifts.forEach(shift => {
        shift.timeline(timeline);
      });

      this._items.forEach((item: SimpleItem) => {
        item.timeline(timeline);
      });
    }
    return this;
  }

  /**
   * Menu setter
   * @param menu
   * @returns {Baseitem}
   */
  public menu(menu: Menu): this {
    super.menu(menu);
    this._shifts.forEach(shift => {
      shift.menu(menu);
    });
    this._items.forEach((item: SimpleItem) => {
      item.menu(menu);
    });
    return this;
  }

  /**
   * Defs setter
   * @param defs
   * @returns {BaseItem}
   */
  public defs(defs: Defs): this {
    this._defs = defs;
    this._shifts.forEach(shift => {
      shift.defs(defs);
    });
    this._items.forEach((item: SimpleItem) => {
      item.defs(defs);
    });
    return this;
  }

  protected setToggle() {
    const sizes = this._timeline.sizes();
    const circleDiameter = 14;
    const toggleMargin = 9.5;
    const rh = this.height;
    const mw = sizes.menuWidth - (toggleMargin + circleDiameter / 2);
    const hh = rh / 2;

    if (this._items.length) {
      if (this.$toggle) {
        this.$toggle.selectAll('*').remove();
      }

      this.$toggle = this.$el
        .append('g')
        .classed('openable-toggle', true)
        .attr('transform', translate(mw, hh));

      this.$toggle
        .append('circle')
        .attr('r', circleDiameter / 2)
        .classed('toggle-circle', true);

      this.$pointer = this.$toggle
        .append('polygon')
        .attr('points', '1.131371 0 0 0 4 4 8 0 6.868629 0 4 2.868629')
        .classed('toggle-mark', true);

      if (this._opened) {
        this.opened();
      } else {
        this.closed();
      }

      this.$toggle.on('click', () => {
        d3.event.stopPropagation();
        this.toogle();
      });
    }
  }

  /**
   * Some additional rendering for opened item
   */
  protected opened() {
    this.$el.classed('opened', true);
  }

  /**
   * Some additional rendering when item is closed
   */
  protected closed() {
    this.$el.classed('opened', false);
    // this.$items.attr('visibility', 'hidden');
  }

  protected updateChildIndexes() {
    this.items.forEach((item: any, index) => {
      item.index = index;
    });

    this._onIndexUpdate.next();
  }
}
