import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { ErrorService } from '@services';
import { statsForSchedule } from '@app/rostr/_shared/queries/rostr-overview.queries';
import { IFetchStatisticsParameters, IFetchStatisticsResponse, IRawCategories, IRostrStatisticsQueryVariables } from '@app/rostr/_shared';
import { ApolloQueryResult } from '@apollo/client/core';

@Injectable({ providedIn: 'root' })
export class RostrStatisticsAPIService {
  constructor(private readonly apollo: Apollo, private readonly errorService: ErrorService) { }

  public fetchStatisticForSchedule(scheduleId: number, params: IFetchStatisticsParameters): Observable<IRawCategories> {
    const { categories, dateFrom, dateTo, affectedEmployeesIds } = params;

    const queryVariables: IRostrStatisticsQueryVariables = {
      schedule: scheduleId,
      categories,
      averageAssignedDateFrom: dateFrom,
      averageAssignedDateTo: dateTo,
      employeesIds: affectedEmployeesIds
    };

    return this.apollo
      .query({
        query: statsForSchedule,
        variables: queryVariables
      })
      .pipe(
        map((result: ApolloQueryResult<IFetchStatisticsResponse>) => result.data.rostrStatsForSchedule),
        catchError((err) => {
          return this.errorService.handle(err);
        })
      );
  }
}
