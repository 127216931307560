import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IAvaiJob } from '../dto/avai/avai.job';

@Injectable({ providedIn: 'root' })
export class AvaiState {
  private readonly _jobSubject$ = new BehaviorSubject<IAvaiJob>(null);
  private readonly _isJobRunning$ = new BehaviorSubject<boolean>(false);
  private readonly _isNewSolutionAvailable$ = new BehaviorSubject<boolean>(false);

  public readonly job = this._jobSubject$.asObservable();
  public readonly isJobRunning: Observable<boolean> = this._isJobRunning$.asObservable();
  public readonly isNewSolutionAvailable: Observable<boolean> = this._isNewSolutionAvailable$.asObservable();

  public setJobData(data: IAvaiJob): void {
    this._isJobRunning$.next(data?.isActive);
    this._jobSubject$.next(data);
  }

  public setNewSolutionFlag(): void {
    this._isNewSolutionAvailable$.next(true);
  }

  public cleanUp(): void {
    this._jobSubject$.next(null);
    this._isJobRunning$.next(false);
    this._isNewSolutionAvailable$.next(false);
  }
}
