import { Injectable } from '@angular/core';
import { StatisticsState } from '../state/statistics.state';
import { ScheduleStatistic } from '../dto/schedule-statistic';
import { tap } from 'rxjs/operators';
import { ScheduleStatisticsAPIService } from '../api/services/schedule-statistics-api.service';

@Injectable({ providedIn: 'root' })
export class StatisticsFacade {
  constructor(
    private readonly statisticsState: StatisticsState,
    private readonly scheduleStatisticsApi: ScheduleStatisticsAPIService
  ) {}

  public setStatistics(statistics: ScheduleStatistic[]): void {
    this.statisticsState.setStatistics(statistics);
  }

  public getStatistics$ = () => this.statisticsState.statistics$;
  public getStatisticsByWeekForEmployee$ = (week: number, employeeId: number) => this.statisticsState.getStatisticsByWeekAndEmployee(week, employeeId);
  public getStatisticsByEmployee$ = (employeeId: number) => this.statisticsState.getStatisticsByEmployee(employeeId);
  public getStatisticsByShift$ = (shiftId: string) => this.statisticsState.getStatisticsByShift(shiftId);

  onScheduleStatisticsUpdated$ = (scheduleId: number) =>
    this.scheduleStatisticsApi.onScheduleStatisticsUpdated$(scheduleId).pipe(tap(liveResult => this.setStatistics(liveResult)));

  public cleanUp() {
    this.statisticsState.cleanUp();
  }
}
