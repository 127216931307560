import { IRole, Role } from '@auth';
import { BaseModel } from '../base.model';
import { GroupModel } from '@model/companies';
import { IBaseModel } from '@model/interfaces';
interface EmployeeRoleData extends IBaseModel {
  employeeId: number;
  roleId: number;
  role: IRole;
  employeeRoleGroups: { group: GroupModel; groupId: number }[];
}

export class EmployeeRoleModel extends BaseModel {
  employeeId: number;
  roleId: number;
  role: Role;
  assignments: GroupModel[];
  assignmentsIds: number[];

  constructor(data: Partial<EmployeeRoleData> = {}) {
    super(data);
    this.employeeId = data.employeeId;
    this.roleId = data.roleId;
    this.role = new Role(data.role);
    this.assignments = data.employeeRoleGroups?.map(employeeRoleGroup => new GroupModel(employeeRoleGroup.group));
    this.assignmentsIds = data.employeeRoleGroups?.map(employeeRoleGroup => employeeRoleGroup.groupId) || [];
  }
}
