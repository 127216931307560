/* eslint-disable */
import * as d3 from 'd3';

export const xmlns: 'http://www.w3.org/2000/svg' = 'http://www.w3.org/2000/svg';

export const ELLIPSIS_SUFFIX = '…';

export function url(urlString: string, id: string) {
  const path = urlString || window.location.pathname;

  return `url("${path}#${id}")`;
}

/**
 * Generate translate string
 *
 * @param {number} x
 * @param {number} y
 *
 * @return {string}
 */
export function translate(x: number, y: number) {
  return `translate(${x}, ${y})`;
}

/**
 * Scale by x and y
 *
 * @param {number} x
 * @param {number} y
 *
 * @returns {string}
 */
export function scale(x, y) {
  return `scale(${x}, ${y})`;
}

/**
 * Getting path for rounded rectangle
 *
 * @param {number} x
 * @param {number} y
 * @param {number} w - Widht
 * @param {number} h - Height
 * @param {number} r - Ragius
 * @param {boolean} tl - Show top left radius
 * @param {boolean} tr - Show top right radius
 * @param {boolean} bl - Show bottom left radius
 * @param {boolean} br - Show bottom right radius
 *
 * @returns {string}
 */
export function pathForRoundedRect(
  x: number, y: number, w: number, h: number, r: number,
  tl: boolean, tr: boolean, bl: boolean, br: boolean
) {
  let retval: string;

  retval = `M ${x + r} ${y}`;
  retval += `h ${w - 2 * r}`;

  if (tr) {
    retval += `a ${r} ${r} 0 0 1 ${r} ${r}`;
  } else {
    retval += `h ${r} v ${r}`;
  }

  retval += `v ${h - 2 * r}`;

  if (br) {
    retval += `a ${r} ${r} 0 0 1 -${r} ${r}`;
  } else {
    retval += `v ${r} h -${r}`;
  }

  retval += `h ${2 * r - w}`;

  if (bl) {
    retval += `a ${r} ${r} 0 0 1 -${r} -${r}`;
  } else {
    retval += `h -${r} v -${r}`;
  }

  retval += `v ${2 * r - h}`;

  if (tl) {
    retval += `a ${r} ${r} 0 0 1 ${r} -${r}`;
  } else {
    retval += `v -${r} h ${r}`;
  }

  retval += `Z`;

  return retval;
}

export function shadeBlendConvert(p, c0, c1?) {
  const n = p < 0 ? p * -1 : p;
  const u = Math.round;
  const w = parseInt;
  if (c0.length > 7) {
    const f = c0.split(',');
    const t = (c1 ? c1 : p < 0 ? 'rgb(0,0,0)' : 'rgb(255,255,255)').split(',');
    const R = w(f[0].slice(4));
    const G = w(f[1]);
    const B = w(f[2]);
    return `rgb(${(u((w(t[0].slice(4)) - R) * n) + R)},${(u((w(t[1]) - G) * n) + G)},${(u((w(t[2]) - B) * n) + B)})`;
  } else {
    const f = w(c0.slice(1), 16);
    const t = w((c1 ? c1 : p < 0 ? '#000000' : '#FFFFFF').slice(1), 16);
    const R1 = f >> 16;
    const G1 = f >> 8 & 0x00FF;
    const B1 = f & 0x0000FF;
// eslint-disable-next-line max-len
    return `#${(0x1000000 + (u(((t >> 16) - R1) * n) + R1) * 0x10000 + (u(((t >> 8 & 0x00FF) - G1) * n) + G1) * 0x100 + (u(((t & 0x0000FF) - B1) * n) + B1)).toString(16).slice(1)}`;
  }
}

export function textEllipsis(width: number) {

  return function() {
    const element = d3.select<SVGTextElement, any>(this);
    let textLength = element.node().getComputedTextLength();
    let text = element.text();
    
    while (textLength > width && text.length > 0) {
      text = text.slice(0, -1);
      element.text(`${text}${ELLIPSIS_SUFFIX}`);
      textLength = element.node().getComputedTextLength();
    }

    return element;
  }
}

export function insertBefore(
  body: d3.Selection<any, any, any, any>,
  newChild: SVGElement,
  refChild: d3.Selection<any, any, any, any>,
) {
  body.node().insertBefore(newChild, refChild.node());
}

export function createAndInsertBefore(
  body: d3.Selection<any, any, any, any>,
  newChild: string,
  refChild: d3.Selection<any, any, any, any>,
): d3.Selection<any, any, any, any> {
  const el = d3.select(document.createElementNS(xmlns, newChild));
  body.node().insertBefore(el.node(), refChild.node());

  return el;
}

export function getTranslate(element): [number, number] {
  const g = document.createElementNS('http://www.w3.org/2000/svg', 'g');

  const transform = element.attr('transform');

  // If element haven't got the transform attribute
  if (!transform) { return [null, null]; }

  // Set the transform attribute to the provided string value.
  g.setAttributeNS(null, 'transform', transform);

  // consolidate the SVGTransformList containing all transformations
  // to a single SVGTransform of type SVG_TRANSFORM_MATRIX and get
  // its SVGMatrix.
  const matrix = g.transform.baseVal.consolidate().matrix;

  // As per definition values e and f are the ones for the translation.
  return [matrix.e, matrix.f];
}

export function appendGElementWithClassAttribute(
  element: d3.Selection<Element, any, any, any>,
  className: string
): d3.Selection<SVGGElement, any, any, any> {
  return element.append<SVGGElement>('g').attr('class', className);
}