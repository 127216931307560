import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ScheduleHistoryEntry } from '../dto';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HistoryState {
  private readonly historySource$ = new BehaviorSubject<ScheduleHistoryEntry[]>([]);
  private readonly isHistoryLoadingSubject$ = new BehaviorSubject<boolean>(false);

  public readonly history$ = this.historySource$.asObservable();
  public readonly isHistoryLoading$ = this.isHistoryLoadingSubject$.asObservable();

  public get isHistoryLoading() {
    return this.isHistoryLoadingSubject$.value;
  }

  public get history() {
    return this.historySource$.value;
  }

  public hasAnythingToUndo$ = this.history$.pipe(map(x => x.some(y => !y.isUndone)));
  public hasAnythingToRedo$ = this.history$.pipe(map(x => x.some(y => y.isUndone)));

  public get hasAnythingToRedo() {
    return this.entriesToRedo.length > 0;
  }

  public get hasAnythingToUndo() {
    return this.entriesToUndo.length > 0;
  }

  public get entriesToRedo() {
    return this.historySource$.value.filter(x => x.isUndone);
  }

  public get entriesToUndo() {
    return this.historySource$.value.filter(x => !x.isUndone);
  }

  public setHistory(history: ScheduleHistoryEntry[]) {
    this.historySource$.next(history);
  }

  public setIsHistoryLoading(areLoading: boolean): void {
    this.isHistoryLoadingSubject$.next(areLoading);
  }

  public cleanUp(): void {
    this.historySource$.next([]);
  }
}
