import { PermissionEnum } from '@enums';
import { BaseModel } from '../base.model';
import {
  EMPLOYEE_ROLE,
  SUPERVISOR,
  RESOURCE_PLANNER,
  RESOURCE_COORDINATOR,
  ANALYST,
  UNIT_ADMIN,
  SYSTEM_ADMIN,
  UNION_REPRESENTATIVE,
  ROSTR_READ_ACCESS
} from '@constants';

export class PermissionModel extends BaseModel {
  public permissions: string[];
  public roles: string[];

  constructor(data: any = {}) {
    super(data);

    this.permissions = data.permissions || '';
    this.roles = data.roles || '';
  }

  get isEmployee() {
    return this.roles.includes(EMPLOYEE_ROLE);
  }

  get isSupervisor() {
    return this.roles.includes(SUPERVISOR);
  }

  get isResourceCoordinator() {
    return this.roles.includes(RESOURCE_COORDINATOR);
  }

  get isResourcePlanner() {
    return this.roles.includes(RESOURCE_PLANNER);
  }

  get isAnalyst() {
    return this.roles.includes(ANALYST);
  }

  get isUnitAdmin() {
    return this.roles.includes(UNIT_ADMIN);
  }

  get isSystemAdmin() {
    return this.roles.includes(SYSTEM_ADMIN);
  }

  get isUnionRepresentative() {
    return this.roles.includes(UNION_REPRESENTATIVE);
  }

  get isRostrReadAccess() {
    return this.roles.includes(ROSTR_READ_ACCESS);
  }

  public hasRole(roles: string[]): boolean {
    return this.roles.some(r => roles.includes(r));
  }

  public hasPermission(permission: PermissionEnum) {
    return this.permissions.some(p => p === permission.toString());
  }
}
