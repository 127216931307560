import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { convertSeverityIntoColor, convertSourceIntoIcon } from '@component/notifications/helpers/notification-helper';
import { NotificationModel } from '@component/notifications/models';
import { NotificationsAPIService } from '@component/notifications/services/notifications-api.service';
import { SideBarWidgetName } from '@shared/enums/sidebar-widget.enum';
import { NotificationsSettingsService } from '@shared/services/notifications/notifications-settings.service';
import { SideBarService } from '@shared/services/side-bar/side-bar.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-notifications-menu',
  templateUrl: './notifications-menu.component.html',
  styleUrls: ['./notifications-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationsMenuComponent implements OnInit, OnDestroy {
  private newNotificationsSub: Subscription;

  public notifications: NotificationModel[] = [];
  public unreadCount = 0;
  public showPopups = true;

  public convertSourceIntoIcon = convertSourceIntoIcon;
  public convertSeverityIntoColor = convertSeverityIntoColor;

  constructor(
    private readonly notificationsAPIService: NotificationsAPIService,
    private readonly sideBarService: SideBarService,
    private readonly notificationsSettingsService: NotificationsSettingsService,
    private readonly changeDetector: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.notificationsAPIService
      .fetchNotifications(50)
      .pipe(take(1))
      .subscribe(notifications => {
        this.notifications = notifications;
        this.unreadCount = notifications.filter(x => !x.isRead).length;
        this.changeDetector.markForCheck();
      });

    this.newNotificationsSub = this.notificationsAPIService.onNotificationCreated$().subscribe(notification => {
      this.notifications.unshift(notification);

      this.unreadCount = this.notifications.filter(x => !x.isRead).length;
      this.changeDetector.markForCheck();
    });

    this.showPopups = this.notificationsSettingsService.isDisplayingOfPopupsEnabled;
  }

  public ngOnDestroy(): void {
    this.newNotificationsSub.unsubscribe();
  }

  public markAllAsRead() {
    if (this.unreadCount > 0) {
      this.notificationsAPIService
        .markNotificationsAsRead$()
        .pipe(take(1))
        .subscribe(() => {
          this.notifications.forEach(notification => (notification.isRead = true));
          this.unreadCount = 0;
          this.changeDetector.markForCheck();
        });
    }
  }

  public hasAnyErrorNotification() {
    return this.notifications.some(x => !x.isRead && x.severity === 'ERROR');
  }

  public openNotificationsSideBar(): void {
    this.sideBarService.activateSideBar(SideBarWidgetName.Notifications);
  }

  public showPopupsChanged(e: MatSlideToggleChange) {
    if (e.checked) {
      this.notificationsSettingsService.enablePopupsDisplaying();
    } else {
      this.notificationsSettingsService.disablePopupsDisplaying();
    }
    this.showPopups = e.checked;
    this.changeDetector.markForCheck();
  }
}
