import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NotificationsSettingsService {
  private localStorageKey = 'notificationsSettings';
  private isInitialized = false;

  private settings: NotificationsSettings = {
    displayPopups: true
  };

  public get isDisplayingOfPopupsEnabled(): boolean {
    if (!this.isInitialized) {
      this.initializeFromLocalStorage();
    }

    return this.settings.displayPopups ?? true;
  }

  public initializeFromLocalStorage() {
    const settingsFromLocalStorage = localStorage.getItem(this.localStorageKey);
    if (settingsFromLocalStorage) {
      this.settings = JSON.parse(settingsFromLocalStorage);
    }

    this.isInitialized = true;
  }

  public enablePopupsDisplaying(): void {
    this.settings.displayPopups = true;
    this.saveSettings();
  }

  public disablePopupsDisplaying(): void {
    this.settings.displayPopups = false;
    this.saveSettings();
  }

  private saveSettings(): void {
    localStorage.setItem(this.localStorageKey, JSON.stringify(this.settings));
  }
}

export interface NotificationsSettings {
  displayPopups: boolean;
}
