import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';

import { debounceTime } from 'rxjs/operators';

import { TooltipContext } from '@component/tooltip/tooltip.config';
import { smartSearch } from '@helpers';
import { ConfigurationModel } from '@models';

export interface IConfigurationsTooltipContext {
  currentConfiguration: ConfigurationModel;
  configurations: ConfigurationModel[];
  countSectors: number;
  assignCallback: (configuration: ConfigurationModel) => void;
  unassignCallback: () => void;
}

@Component({
  selector: 'br-configurations-tooltip',
  templateUrl: 'configuration-tooltip.component.html',
  styleUrls: ['configuration-tooltip.component.scss']
})
export class ConfigurationTooltipComponent implements OnInit {
  get currentStatus() {
    if (this.allConfigurations.success.indexOf(this.currentConfiguration) > -1) {
      return 'success';
    }

    return 'warn';
  }

  @ViewChild('alternatives') alternatives: ElementRef;

  public currentConfiguration: ConfigurationModel;

  public predictedConfigurations = { success: [], warn: [] };

  public searchStrControl = new FormControl<string>('');
  protected allConfigurations = { success: [], warn: [] };

  constructor(@Inject(TooltipContext) private tooltipContext: IConfigurationsTooltipContext) {}

  public ngOnInit() {
    this.currentConfiguration = this.tooltipContext.currentConfiguration;

    this.tooltipContext.configurations.forEach(configuration => {
      if (this.tooltipContext.countSectors === configuration.sectorsAndCombinations.length) {
        this.predictedConfigurations.success.push(configuration);
        this.allConfigurations.success.push(configuration);
        return;
      }

      this.predictedConfigurations.warn.push(configuration);
      this.allConfigurations.warn.push(configuration);
    });

    this.searchStrControl.valueChanges.pipe(debounceTime(300)).subscribe(value => this.filter(value));
  }

  public assignConfiguration(configuration: ConfigurationModel) {
    if (this.isSelectedConfigurarion(configuration)) {
      return;
    }

    this.tooltipContext.assignCallback(configuration);
  }


  public unassignConfiguration() {
    this.tooltipContext.unassignCallback();
  }

  public scrollAlternatives() {
    this.alternatives.nativeElement.scrollTop += 30;
  }

  public isSelectedConfigurarion(configuration: ConfigurationModel) {
    return this.currentConfiguration === configuration;
  }

  protected filter(value: string) {
    if (!value.length) {
      this.predictedConfigurations.success = this.allConfigurations.success;
      this.predictedConfigurations.warn = this.allConfigurations.warn;
      return;
    }

    const success = smartSearch(this.allConfigurations.success, value, 'code');
    this.predictedConfigurations.success = success.slice(0, 10);
    const warn = smartSearch(this.allConfigurations.warn, value, 'code');
    this.predictedConfigurations.warn = warn.slice(0, 10);
  }
}
