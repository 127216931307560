import { ChangeDetectionStrategy, Component, forwardRef, Injectable, ViewEncapsulation } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DateRange, MAT_DATE_RANGE_SELECTION_STRATEGY, MatDateRangeSelectionStrategy } from '@angular/material/datepicker';
import { RangeDatepickerComponent } from './range-datepicker.component';
import { DateAdapter } from '@angular/material/core';

@Injectable()
export class EndDateSelectionStrategy<D> implements MatDateRangeSelectionStrategy<D> {
  constructor(private _dateAdapter: DateAdapter<D>) {}

  selectionFinished(date: D | null, currentRange: DateRange<D>): DateRange<D> {
    return this._endDateRange(date, currentRange);
  }

  createPreview(activeDate: D | null, currentRange: DateRange<D>): DateRange<D> {
    return this._endDateRange(activeDate, currentRange);
  }

  private _endDateRange(date: D | null, currentRange: DateRange<D>): DateRange<D> {
    if (date) {
      return new DateRange<D>(currentRange.start, date);
    }

    return new DateRange<D>(currentRange.start, null);
  }
}

@Component({
  selector: 'app-range-datepicker-end-date',
  templateUrl: './range-datepicker.component.html',
  styleUrls: ['./range-datepicker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => RangeDatepickerEndDateComponent)
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => RangeDatepickerEndDateComponent),
      multi: true
    },
    {
      provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
      useClass: EndDateSelectionStrategy
    }
  ],
  encapsulation: ViewEncapsulation.None
})
export class RangeDatepickerEndDateComponent extends RangeDatepickerComponent {
  public startAt = new Date();
}
