import { Observable, Observer } from 'rxjs';
import { take } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie';
import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';

import { AuthenticationService } from '@auth';

@Injectable()
export class AuthenticatedGuard {
  constructor(
    private router: Router,
    private auth: AuthenticationService,
    private cookies: CookieService
  ) {
    // TODO maybe put it in some other place ?
    this.router.events.subscribe(event => {
      // on Wildcard, /vacay is used as initial route, and later on it stored in cookie `prevpage`
      // on localhost no such behavior.
      if (event instanceof NavigationStart && event.url !== '/login' && event.url !== '/logout') {
        this.cookies.put('prevpage', event.url);
      }
    });
  }

  canActivate() {
    const auth = this.auth;
    const router = this.router;

    if (auth.isAuthenticated) {
      return true;
    }

    return new Observable((observer: Observer<any>) => {
      auth.userData().subscribe(
        () => observer.next(true),
        () => {
          // error here happens in most cases 401, 502 or 504. Useful place for debugging.
          router.navigate(['error-not-logged-in']);
          observer.next(false);
        }
      );
    }).pipe(take(1));
  }
}
