import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { SettingsEntityTables, SettingsKey } from '@model/settings/settings-key';
import { routeConfig } from '../configs/route.config';
import { SettingsService } from '@platform/_shared/services/settings.service';
import { Observable, Observer } from 'rxjs';

@Injectable()
export class ModuleToggle {
  constructor(
    private readonly router: Router,
    private readonly settingsService: SettingsService
  ) {}

  canActivate(route: ActivatedRouteSnapshot) {
    return new Observable((observer: Observer<boolean>) => {
      const settingsEntity = SettingsEntityTables.CompaniesCompanies;
      const settingsKeys = [SettingsKey.ArgusToggle, SettingsKey.VacayToggle];

      this.settingsService.loadSettings(settingsEntity, settingsKeys).subscribe(response => {
        response.forEach(systemSetting => {
          const allModules = routeConfig.getModules();
          const moduleForFollowingSetting = allModules.find(module => systemSetting.key === module.moduleToggle.settingIdentifier);
          if (moduleForFollowingSetting) {
            const val = JSON.parse(systemSetting.getPresentSettingValue());
            routeConfig.setModuleToggle(moduleForFollowingSetting.path, val);
          }
        });
        const foundModule = routeConfig.getModule(route.routeConfig.path);
        if (foundModule && !foundModule?.moduleToggle.active) {
          this.router.navigate(['error-404']);
        }
        observer.next(true);
      });
    });
  }
}
