import { IWeeklyStatFormatted } from '../..';

/* Full statistic for single employee */
export class EmployeeStatistic {
  stat: IWeeklyStatFormatted | string;

  constructor(stat = null) {
    this.stat = stat;
  }

  public getStat(key?: string): string {
    return '';
  }
}

export class WeeklyEmployeeStatistic extends EmployeeStatistic {
  stat: IWeeklyStatFormatted;

  public getStat(key: string): string {
    return this.stat?.[key] ?? '';
  }
}

export class MainEmployeeStatistic extends EmployeeStatistic {
  stat: string;

  public getStat(): string {
    return this.stat ?? '';
  }
}
