import { gql } from 'apollo-angular';

const shiftInfoFragment = gql`
  fragment ShiftInfo on ManualShift {
    workingTimeInMinutes
    code
    metacode
    comment
    start
    id
    shiftAlias {
      id
      value
    }
    type {
      id
      name
    }
    workingTimePercentage

    duration
    isComplex
    color
    isGlobal
    isQuicklyAccessible
    details {
      id
      typeId
      start
      duration
      workingTimePercentage
      workingTimeInMinutes
    }
    skills {
      id
      name
    }
    unitEndorsements {
      id
      title
    }
    licenses {
      id
      name
    }
    groupId
  }
`;

export const loadManualShift = gql`
  query manualShift($id: Int!) {
    manualShift(id: $id) {
      ...ShiftInfo
    }
  }
  ${shiftInfoFragment}
`;

export const loadManualShifts = gql`
  query manualShifts($groupId: Int) {
    manualShifts(groupId: $groupId) {
      ...ShiftInfo
    }
  }
  ${shiftInfoFragment}
`;

export const loadGlobalShifts = gql`
  query globalShifts {
    globalShifts {
      ...ShiftInfo
    }
  }
  ${shiftInfoFragment}
`;

export const loadManualShiftsWithNameAndTypeOnly = gql`
  query manualShifts {
    manualShifts {
      id
      typeId
      code
      groupId
      isGlobal
    }
  }
`;

export const loadManualShiftsForGroupWithNameAndTypeOnly = gql`
  query manualShifts($groupId: Int) {
    manualShifts(groupId: $groupId) {
      id
      typeId
      code
      isGlobal
    }
  }
`;

export const loadManualShiftsByCode = gql`
  query manualShifts($code: String) {
    manualShifts(code: $code) {
      start
      duration
      skills {
        name
      }
      type {
        id
        name
      }
      code
      workingTimeInMinutes
      workingTimePercentage
      isGlobal
    }
  }
`;

export const createManualShift = gql`
  mutation createManualShift(
    $code: String!
    $metacode: String!
    $comment: String
    $start: Int!
    $duration: Int!
    $typeId: Int!
    $workingTimePercentage: Float!
    $details: [ShiftCodeDetailsInput]
    $isComplex: Boolean!
    $color: String
    $isGlobal: Boolean
    $groupId: Int
    $skills: [Int]
    $licenses: [Int]
    $unitEndorsements: [Int]
    $isQuicklyAccessible: Boolean!
  ) {
    createManualShift(
      code: $code
      metacode: $metacode
      comment: $comment
      start: $start
      duration: $duration
      typeId: $typeId
      workingTimePercentage: $workingTimePercentage
      details: $details
      isComplex: $isComplex
      color: $color
      isGlobal: $isGlobal
      isQuicklyAccessible: $isQuicklyAccessible
      groupId: $groupId
      skills: $skills
      licenses: $licenses
      unitEndorsements: $unitEndorsements
    ) {
      ...ShiftInfo
    }
  }
  ${shiftInfoFragment}
`;
export const updateManualShift = gql`
  mutation updateManualShift(
    $id: Int!
    $code: String!
    $metacode: String!
    $comment: String
    $start: Int!
    $duration: Int!
    $typeId: Int!
    $workingTimePercentage: Float!
    $details: [ShiftCodeDetailsInput]
    $isComplex: Boolean!
    $color: String
    $isGlobal: Boolean
    $isQuicklyAccessible: Boolean!
    $skills: [Int]
    $licenses: [Int]
    $unitEndorsements: [Int]
  ) {
    updateManualShift(
      id: $id
      code: $code
      metacode: $metacode
      comment: $comment
      start: $start
      duration: $duration
      typeId: $typeId
      workingTimePercentage: $workingTimePercentage
      details: $details
      isComplex: $isComplex
      color: $color
      isGlobal: $isGlobal
      isQuicklyAccessible: $isQuicklyAccessible
      skills: $skills
      licenses: $licenses
      unitEndorsements: $unitEndorsements
    ) {
      ...ShiftInfo
    }
  }
  ${shiftInfoFragment}
`;

export const deleteManualShift = gql`
  mutation deleteManualShift($id: Int!) {
    deleteManualShift(id: $id) {
      ...ShiftInfo
    }
  }
  ${shiftInfoFragment}
`;

export const shiftCodeTypesQuery = gql`
  query shiftCodeTypes {
    shiftCodeTypes {
      id
      name
      countAsWork
    }
  }
`;

export const shiftCodeColorsQuery = gql`
  query ShiftCodeColors {
    shiftCodeColors {
      color
      id
    }
  }
`;

export const ShiftCodeDetailsQuery = gql`
  query shiftCodeDetails {
    shiftCodeDetails {
      id
      start
      duration
      workingTimeInMinutes
      workingTimePercentage
      type
    }
  }
`;

export const shiftsConstellationsQuery = gql`
  query shiftsConstellations($groupId: Int!) {
    shiftsConstellations(groupId: $groupId) {
      id
      name
      comment
      shifts {
        id
        code
        color
      }
    }
  }
`;

export const createShiftsConstellation = gql`
  mutation createShiftsConstellation($name: String!, $comment: String!, $groupId: Int!, $shifts: [ShiftsConstellationShiftInput]!) {
    createShiftsConstellation(name: $name, comment: $comment, groupId: $groupId, shifts: $shifts) {
      id
      name
      comment
      shifts {
        id
        code
      }
    }
  }
`;

export const deleteShiftsConstellations = gql`
  mutation deleteShiftsConstellations($ids: [Int]!) {
    deleteShiftsConstellations(ids: $ids)
  }
`;
