import { gql } from 'apollo-angular';

export const startAvaiJob = gql`
  mutation rostrStartAvaiJob($schedule: Int!) {
    rostrStartAvaiJob(schedule: $schedule) {
      id
      module
      type
      targetType
      targetId
      status
      isActive
      data
    }
  }
`;

export const stopAvaiJob = gql`
  mutation rostrStopAvaiJob($schedule: Int!) {
    rostrStopAvaiJob(schedule: $schedule) {
      id
      module
      type
      targetType
      targetId
      status
      isActive
      data
    }
  }
`;

export const scheduleDataUpdated = gql`
  subscription rostrScheduleUpdated($id: Int) {
    rostrScheduleUpdated(id: $id) {
      id
      diff {
        changed {
          id
          code
          dateFrom
          dateTo
          iDate {
            date
          }
          scheduleId
          employeeId
          parentId
          parentType
          data
          shiftAlias {
            id
            value
          }
        }
        added {
          id
          code
          dateFrom
          dateTo
          iDate {
            date
          }
          scheduleId
          employeeId
          parentId
          parentType
          data
          shiftAlias {
            id
            value
          }
        }
        removed {
          id
          code
          scheduleId
          employeeId
          parentId
          parentType
        }
      }
    }
  }
`;

export const rostrActiveAvaiJob = gql`
  query rostrActiveAvaiJob($schedule: Int!) {
    rostrActiveAvaiJob(schedule: $schedule) {
      id
      module
      type
      targetType
      targetId
      status
      isActive
      data
      createdAt
      createdBy {
        firstName
        lastName
      }
    }
  }
`;
