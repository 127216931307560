import { Apollo } from 'apollo-angular';
import { ApolloQueryResult } from '@apollo/client/core';
import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';

import { DivisionModel } from '@models';

import {
  createDivision,
  createDivisionCompanyRelation,
  deleteDivision,
  deleteDivisionCompanyRelation,
  loadDivision,
  loadDivisions,
  updateDivision
} from './division.queries';
import { IBaseCrudService } from '@interfaces';

@Injectable({
  providedIn: 'root'
})
export class DivisionService implements IBaseCrudService<DivisionModel>{
  private data: DivisionModel[];

  constructor(private apollo: Apollo) { }

  public load() {
    return this.apollo.query<any>({ query: loadDivisions }).pipe(
      map(response => {
        return response.data.divisions.map(element => new DivisionModel(element));
      }),
      tap(mapped => {
        this.data = mapped;
      })
    );
  }

  public loadDivision(id: number) {
    return this.apollo
      .query<any>({
        query: loadDivision,
        variables: { id }
      })
      .pipe(map(response => new DivisionModel(response.data.division)));
  }

  public create(data: DivisionModel) {
    return this.apollo
      .mutate({
        mutation: createDivision,
        variables: {
          name: data.name
        }
      }).pipe(
        map((response: ApolloQueryResult<any>) => new DivisionModel(response.data.createDivision)),
        tap((model: DivisionModel) => {
          this.data = [...this.data, model];
        })
      );
  }

  public update(data: DivisionModel) {
    return this.apollo
      .mutate({
        mutation: updateDivision,
        variables: {
          id: data.id,
          name: data.name
        }
      }).pipe(
        map((response: ApolloQueryResult<any>) => new DivisionModel(response.data.updateDivision)),
        tap((model: DivisionModel) => {
          const division = this.data.find(el => el.id === model.id);
          Object.assign(division, model);
        })
      );
  }

  public delete(data: DivisionModel) {
    return this.apollo
      .mutate({
        mutation: deleteDivision,
        variables: {
          id: data.id
        }
      }).pipe(
        tap((response: ApolloQueryResult<any>) => {
          const res = response.data.deleteDivision;
          this.data = [...this.data.filter(el => el.id !== res.id)];
        }),
        map((response: ApolloQueryResult<any>) => response.data.deleteDivision)
      );
  }

  public addCompanyRelation(division: number, company: number) {
    return this.apollo
      .mutate({
        mutation: createDivisionCompanyRelation,
        variables: {
          divisionId: division,
          companyId: company
        }
      })
      .subscribe(() => {
        const elem = this.data.find(el => el.id === division);
        elem.companyId = company;
      });
  }

  public removeCompanyRelation(division: number) {
    return this.apollo
      .mutate({
        mutation: deleteDivisionCompanyRelation,
        variables: {
          divisionId: division
        }
      })
      .subscribe(() => {
        const elem = this.data.find(el => el.id === division);
        elem.companyId = null;
      });
  }

  public updateCompanyRelation(id: number, companyId: number, isSelected: boolean) {
    if (isSelected) {
      this.addCompanyRelation(id, companyId);
    } else {
      this.removeCompanyRelation(id);
    }
  }
}
