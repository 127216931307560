import * as d3 from 'd3';

import { MasterplanAssignedActivityModel } from '@shared/models';

import { ScheduleTimeline } from '../timeline';
import { Activity } from './activity';


export class MasterplanActivity
  extends Activity<MasterplanAssignedActivityModel> {

  protected $code: d3.Selection<SVGTextElement, any, any, any>;

  constructor(model: MasterplanAssignedActivityModel,
              timeline: ScheduleTimeline) {
    super(model, timeline);

    this.$el.attr('class', 'masterplan');

    this.$code = this.$el.append<SVGTextElement>('text').attr('class', 'masterplan-code');
  }

  get isDayOff() {
    return this.model.shiftAlias.isDayOff;
  }

  /**
   * Render masterplan activity
   */
  public render() {
    const size = this.sizes.cell;
    const halfSize = size / 2;

    this.$code
      .text(this.model.shiftAlias.value)
      .attr('x', halfSize)
      .attr('y', halfSize)
      .attr('dy', '0.5ex');
  }

}
