import { RADIX_DEC } from '@constants';
import { NouiFormatter } from 'ng2-nouislider';

export class TimeFormatter implements NouiFormatter {

  constructor(private _format: string, private _label: string) {}

  to(value) {

    let time;

    switch (this._format) {
      case 'hours': {
        time = makeTime(value, 2);
      } break;
      case 'minutes': {
        time = minutes(value);
      } break;
      case 'seconds': {

      } break;
      case 'default': {
        time = parseInt(value, RADIX_DEC);
      }
    }
    return time + this._label;
  }

  from(value) {
    return Number(value);
  }
}

export function sliderTooltips(configs = { count: 1, format: 'default', label: '' }) {
  const tooltips = [];

  while (tooltips.length < configs.count) {
    tooltips.push(new TimeFormatter(configs.format, configs.label));
  }

  return tooltips;
}

export function makeTimes(values, places) {
  return `${makeTime(values[0], places)} - ${makeTime(values[1], places)}`;
}

export function makeTime(value, places) {
  const hours = Math.floor(value / 2).toLocaleString(undefined, { minimumIntegerDigits: places });
  const mins = ((value % 2) * 30).toLocaleString(undefined, { minimumIntegerDigits: 2 });
  return `${hours}:${mins}`;
}

export function minutes(seconds) {
  const m = (seconds / 60).toFixed(0);
  return `${m} min`;
}
