import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@auth';
import { ConfigService } from '@core';

@Component({
  selector: 'pl-logout',
  template: `logout..`
})
export class LogoutComponent {

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private configService: ConfigService) {
    this.authService.logout().subscribe(() => {
      window.location.href = `${configService.config.rootApiDomain}/logout`;
    });
  }

}
