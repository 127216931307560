import { BaseModel } from '../base.model';
import * as moment from 'moment-mini';
import { ISO_DATE_ONLY_FORMAT } from '@helpers';
import { QualificationData } from './qualification-data';

export interface UnitEndorsementModelData {
  unitEndorsement?: {
    id?: number;
    title?: string;
  };
  id?: number;
  title?: string;
  obtained?: string;
  validTo?: string;
  validFrom?: string;
}

export class UnitEndorsementModel extends BaseModel implements QualificationData {
  public title: string;
  public obtained: string;
  public validTo: string;
  public validFrom: string;

  constructor(data: UnitEndorsementModelData = {}) {
    super(data);

    this.id = data.unitEndorsement?.id ?? data.id;
    this.title = data.unitEndorsement?.title ?? data.title;
    this.obtained = data.obtained && moment(data.obtained).format(ISO_DATE_ONLY_FORMAT);
    this.validTo = data.validTo && moment(data.validTo).format(ISO_DATE_ONLY_FORMAT);
    this.validFrom = data.validFrom && moment(data.validFrom).format(ISO_DATE_ONLY_FORMAT);
  }
}
