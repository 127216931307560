import { Activity } from '../../../_shared/models/rostr/activity.model';
import { ShiftType } from '../../../_shared/enums/shift-type';

export function isActivityRequiredOne(activity: Activity): boolean {
  return activity?.parentType === ShiftType.Required;
}

export function isActivityAgendaOne(activity: Activity): boolean {
  return activity?.parentType === ShiftType.Agenda;
}

export function isActivityMasterplanOne(activity: Activity): boolean {
  return activity?.parentType === ShiftType.Master;
}

export function isActivityVacayOne(activity: Activity): boolean {
  return activity?.parentType === ShiftType.Vacay;
}

export function isActivityManualOne(activity: Activity): boolean {
  return activity?.parentType === ShiftType.Manual;
}
