import { BaseModel } from '../base.model';
import * as moment from 'moment-mini';
import { ISO_DATE_ONLY_FORMAT } from '@helpers';
import { GroupModel } from './group.model';

export class EmployeeGroupModel extends BaseModel {
  public groupId: number;
  public group: GroupModel;
  public id: number;
  public name: string;
  public obtained: string;
  public validTo: string;
  public validFrom: string;
  public employeeId: number;

  constructor(data: any = {}) {
    super(data);

    this.groupId = data.group?.id || null;
    this.group = new GroupModel(data.group);
    this.id = data.id;
    this.name = data.group?.name || null;
    this.validTo = (data.validTo && moment(data.validTo).format(ISO_DATE_ONLY_FORMAT)) || undefined;
    this.validFrom = (data.validFrom && moment(data.validFrom).format(ISO_DATE_ONLY_FORMAT)) || undefined;
    this.employeeId = data.employeeId;
  }
}
