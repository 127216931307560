import { gql } from 'apollo-angular';

const fragments = {
  schedule: gql`
    fragment ScheduleInfo on Schedule {
      id
      name
      dateFrom
      dateTo
      createdAt
      createdBy {
        id
        firstName
        middleName
        lastName
      }
      updatedAt
      updatedBy {
        id
        firstName
        middleName
        lastName
      }
      groups {
        id
        name
        unit {
          id
          name
        }
      }
      latestJobId
      latestJobStatus
      latestJobCreatedAt
      latestJobUpdatedAt
      state
    }
  `
};

export const loadGroups = gql`
  query loadGroups {
    groups {
      id
      name
      unit {
        name
      }
    }
  }
`;

export const loadUnits = gql`
  query loadUnits {
    units {
      id
      name
    }
  }
`;

export const loadSchedulesByInterval = gql`
  query schedulesByInterval($groupIds: [Int]!, $dateFrom: String!, $dateTo: String!) {
    schedulesByInterval(groupIds: $groupIds, dateFrom: $dateFrom, dateTo: $dateTo) {
      id
      name
      dateFrom
      dateTo
      createdAt
      createdBy {
        id
        firstName
        middleName
        lastName
      }
      updatedAt
      updatedBy {
        id
        firstName
        middleName
        lastName
      }
      groups {
        id
        name
        unit {
          id
          name
        }
      }
    }
  }
`;

export const loadSchedules = gql`
  query loadSchedules($dateFrom: String) {
    schedules(dateFrom: $dateFrom) {
      ...ScheduleInfo
    }
  }
  ${fragments.schedule}
`;

export const bulkScheduleOperation = gql`
  mutation bulkScheduleOperation($created: [ScheduleInput], $updated: [ScheduleInput], $deleted: [Int]) {
    bulkScheduleOperation(created: $created, updated: $updated, deleted: $deleted) {
      created {
        ...ScheduleInfo
      }

      updated {
        ...ScheduleInfo
      }

      deleted {
        id
      }
    }
  }
  ${fragments.schedule}
`;

export const firstScheduleInGroups = gql`
  query firstScheduleInGroups($groupIds: [Int]!) {
    firstScheduleInGroups(groupIds: $groupIds) {
      dateFrom
      groupIds
    }
  }
`;
export const groupsByEntity = entityType => gql`
  query groupsByEntity ($id: Int!) {
    groups(${entityType} : $id) {
      id
      name
      description
      unit {
        id
        name
      }
    }
  }
`;
