export const EMPLOYEE_ROLE = 'Employee';
export const SUPERVISOR = 'Supervisor';
export const RESOURCE_COORDINATOR = 'ResourceCoordinator';
export const RESOURCE_PLANNER = 'ResourcePlanner';
export const PROJECT_MANAGER = 'ProjectManager';
export const ANALYST = 'Analyst';
export const UNIT_ADMIN = 'UnitAdmin';
export const SYSTEM_ADMIN = 'SystemAdmin';
export const AGENDA_ADMIN = 'AgendaAdmin';
export const AGENDA_ADMIN_COMPANY = 'AgendaAdminCompany';
export const AGENDA_ADMIN_DIVISION = 'AgendaAdminDivision';
export const UNION_REPRESENTATIVE = 'UnionRepresentative';
export const ROSTR_READ_ACCESS = 'RostrReadAccess';
