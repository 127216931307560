import { diffBetweenDates } from '../../helpers/date';
import { EmployeeModel } from '../auth';
import { CommentModel } from '../comment';
import { RostrRequestBaseModel } from '../rostr/rostr-request-base.model';
import { NotificationModel } from './notification.model';
import { PartialActivityModel } from './partial-activity.model';
import { ScheduleModel } from './schedule.model';

import { PartialActivityType } from '@shared/types';
import { ShiftCodeTypeEnum } from '@shared/enums/shift-code-type.enum';

export class ScheduleAssignedActivityModel extends RostrRequestBaseModel {
  public parentType: PartialActivityType;
  public parentId: number;

  public isLocked = false;

  public schedule: ScheduleModel;
  public employee: EmployeeModel;

  public alert: NotificationModel;
  public warning: NotificationModel;

  public type: ShiftCodeTypeEnum;

  protected _comments: CommentModel[];
  protected _activities: PartialActivityModel[] = [];

  constructor(data: any = {}) {
    super(data);

    this.parentType = data.parentType || void 0;
    this.parentId = data.parentId || null;

    this.schedule = (data.schedule && new ScheduleModel(data.schedule)) || undefined;
    this.employee = (data.employee && new EmployeeModel(data.employee)) || undefined;
    this.isLocked = data.data ? Boolean(data.data.isLocked) : false;

    this.comments = data.comments;
    this.activities = data.data && data.data.activities;

    this.alert = data.alert && new NotificationModel(data.alert);
    this.warning = data.warning && new NotificationModel(data.warning);
    this.type = data.type;
  }

  set comments(comments) {
    this._comments = [];

    if (!comments || !comments.length) {
      return;
    }

    this._comments = comments.map(comment => {
      return new CommentModel(comment);
    });
  }

  get comments() {
    return this._comments;
  }

  set activities(activities) {
    const snappedDate = this.iDate && this.iDate.snapped;

    if (!snappedDate) {
      return;
    }

    if (!activities || !activities.length) {
      let type: PartialActivityType = 'sector';

      if (!this.parentType || !this.parentType.length) {
        console.warn('ParentType not found. There are may be errors');
        return;
      }

      switch (this.parentType) {
        case 'agenda':
          type = 'agenda';
          break;

        case 'manualShift':
          type = 'manual';
          break;
      }

      const activity = {
        start: 0,
        duration: diffBetweenDates(snappedDate.from, snappedDate.to),
        description: this.code,
        type
      };

      this._activities = [new PartialActivityModel(activity, snappedDate.from)];
      return;
    }

    this._activities = activities.map(activity => {
      return new PartialActivityModel(activity, snappedDate.from);
    });
  }

  get activities() {
    return this._activities;
  }

  get duration() {
    return this.dateTo.diff(this.dateFrom, 'minutes');
  }

  get group() {
    return (this.employee && this.employee.group) || void 0;
  }
}
