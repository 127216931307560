<div>
  <div class="header">
    <h2>{{ title }}</h2>
    <h3>{{ schedule?.name }}</h3>
  </div>
  <mat-form-field appearance="fill" class="searchbar">
    <mat-label>Filter table by employee</mat-label>
    <mat-icon matPrefix>search</mat-icon>
    <input type="text" placeholder="Enter employee's name" matInput [formControl]="searchControl" [disableControl]="loading" />
  </mat-form-field>
  <table mat-table matSort [dataSource]="matDataSource" multiTemplateDataRows (matSortChange)="sortData($event)">
    <ng-container matColumnDef="fullName">
      <th mat-header-cell *matHeaderCellDef scope="col">
        <span mat-sort-header>Name</span>
      </th>
      <td mat-cell *matCellDef="let row; let i = index">
        <div>
          {{ row.fullName }}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="batch">
      <th mat-header-cell *matHeaderCellDef scope="col">
        <span mat-sort-header>Batch</span>
      </th>
      <td mat-cell *matCellDef="let row; let i = index">
        <div *ngIf="row.batch">{{ row.date | date: 'dd/MM/yyyy' }} - {{ row.dateTo | date: 'dd/MM/yyyy' }}</div>
      </td>
    </ng-container>

    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef scope="col">
        <span mat-sort-header>Date</span>
      </th>
      <td mat-cell *matCellDef="let row">
        <div>
          {{ row.date | date: 'dd/MM/yyyy' }}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef scope="col">
        <span mat-sort-header>Status</span>
        <app-column-filter *ngIf="!loading" [items]="statuses" nameField="name" valueField="value" (valueChange)="filterStatus($event)"></app-column-filter>
      </th>
      <td mat-cell *matCellDef="let row">
        @if (row.requests.length === 1) {
        <div class="d-flex align-center">
          <app-vacay-request-status [request]="row.requests[0]"></app-vacay-request-status>
          <ng-template [ngTemplateOutlet]="requestActions" [ngTemplateOutletContext]="{ $implicit: row.requests[0] }"></ng-template>
        </div>
        } @else {
        <div class="d-flex align-center">
          <div>
            <div class="d-flex align-center">
              <div class="circle unassigned">{{ row.unassigned }}</div>
              <div class="circle approved">{{ row.approved }}</div>
              <div class="circle pending">{{ row.pending }}</div>
              <div class="circle declined">{{ row.declined }}</div>
            </div>
          </div>
          <div>
            <button
              mat-icon-button
              [disabled]="actionLoading()"
              (click)="approveBatch($event, row)"
              matTooltip="Approve all shifts in this batch"
              class="approved-text-hover"
            >
              <mat-icon>playlist_add_check</mat-icon>
            </button>
            <button
              mat-icon-button
              [disabled]="actionLoading()"
              (click)="makeBatchPending($event, row)"
              matTooltip="Send all shifts in this batch to pending"
              class="pending-text-hover"
            >
              <mat-icon>schedule</mat-icon>
            </button>
            <button
              mat-icon-button
              [disabled]="actionLoading()"
              (click)="clearBatch($event, row)"
              matTooltip="Remove all assigned shifts in this batch"
              class="declined-text-hover"
            >
              <mat-icon> clear</mat-icon>
            </button>
          </div>
        </div>
        }
      </td>
    </ng-container>

    <ng-container matColumnDef="statusDetails">
      <td mat-cell *matCellDef="let row">
        <div [@detailExpand]="row === expandedElement ? 'expanded' : 'collapsed'" class="element-detail">
          @for (request of row.requests; track request.id) {
          <div class="d-flex align-center single-request-line">
            <app-vacay-request-status [request]="request"></app-vacay-request-status>
            <ng-template [ngTemplateOutlet]="requestActions" [ngTemplateOutletContext]="{ $implicit: request }"></ng-template>
          </div>
          }
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="alias">
      <th mat-header-cell *matHeaderCellDef scope="col">
        <span mat-sort-header>Request</span>
        <app-column-filter *ngIf="!loading" [items]="aliases" nameField="name" valueField="value" (valueChange)="filterAlias($event)"></app-column-filter>
      </th>
      <td mat-cell *matCellDef="let row">
        <div>
          <div>{{ row.requestedShift }}</div>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="assigned">
      <th mat-header-cell *matHeaderCellDef scope="col">
        <span mat-sort-header>Assigned</span>
      </th>
      <td mat-cell *matCellDef="let row">
        @if (row.requests.length > 1) { ... } @else {
        <div>{{ row.requests[0].assignedActivity?.code || ' - ' }}</div>
        }
      </td>
    </ng-container>
    <ng-container matColumnDef="assignedDetails">
      <td mat-cell *matCellDef="let row">
        <div [@detailExpand]="row === expandedElement ? 'expanded' : 'collapsed'" class="element-detail">
          @for (request of row.requests; track request.id) {
          <div class="single-request-line">{{ request.assignedActivity?.code || ' - ' }}</div>
          }
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef scope="col">
        <span mat-sort-header>Actions</span>
      </th>
      <td mat-cell *matCellDef="let row"></td>
    </ng-container>

    <ng-container matColumnDef="comment">
      <th mat-header-cell *matHeaderCellDef scope="col">
        <span>Comments</span>
      </th>
      <td mat-cell *matCellDef="let row">
        @if (row.requests.length === 1) {
        <app-vacay-request-comments
          [request]="row.requests[0]"
          *ngIf="!commentsLoading; else loadingComments"
          [scheduleId]="scheduleId"
        ></app-vacay-request-comments>
        } @else {
        <div class="d-flex align-center">
          <div class="red-dot" *ngIf="hasAnyUnreadComments(row)"></div>
          <div>{{ row.comment }}</div>
        </div>
        }
      </td>
    </ng-container>

    <ng-container matColumnDef="commentDetails">
      <td mat-cell *matCellDef="let row">
        <div [@detailExpand]="row === expandedElement ? 'expanded' : 'collapsed'" class="element-detail">
          @for (request of row.requests; track request.id) {
          <div class="d-flex align-center single-request-line">
            <app-vacay-request-comments [request]="request"></app-vacay-request-comments>
          </div>
          }
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="empty">
      <td mat-cell *matCellDef="let row"></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: columns" class="element-row" (click)="expand($event, row)" [class.expanded-row]="expandedElement === row"></tr>

    <tr mat-row *matRowDef="let row; columns: ['empty', 'empty', 'empty', 'assignedDetails', 'statusDetails', 'commentDetails']" class="detail-row"></tr>
  </table>
  <div class="footer">
    <div>
      <mat-chip-set>
        <mat-chip class="unassigned"> Unassigned </mat-chip>
        <mat-chip class="approved"> Assigned </mat-chip>
        <mat-chip class="pending"> Pending </mat-chip>
        <mat-chip class="declined"> Declined </mat-chip>
      </mat-chip-set>
    </div>
    <div>
      <mat-paginator [pageSizeOptions]="[10, 20, 30, 50]" showFirstLastButtons pageSize="20"></mat-paginator>
    </div>
  </div>

  <div *ngIf="loading" class="spinner">
    <mat-progress-spinner mode="indeterminate"> </mat-progress-spinner>
  </div>
  <ng-template #loadingComments>
    <div class="row-spinner">
      <mat-progress-spinner mode="indeterminate" diameter="24"> </mat-progress-spinner>
    </div>
  </ng-template>
</div>

<ng-template #requestActions let-request>
  <div class="d-flex align-center">
    @if (request.shiftCode || request.shiftAlias?.value === 'X') {
    <button
      *ngIf="request.shiftCode || request.shiftAlias?.value === 'X'"
      [disabled]="actionLoading()"
      color="success"
      mat-icon-button
      (click)="approveRequest(request)"
      matTooltip="Approve (assign + lock)"
      class="approved-text-hover"
    >
      <mat-icon>check_circle_outline</mat-icon>
    </button>
    <button
      *ngIf="request.shiftCode || request.shiftAlias?.value === 'X'"
      [disabled]="actionLoading()"
      color="success"
      mat-icon-button
      (click)="makeRequestPending(request)"
      matTooltip="Make pending (assign only)"
      class="pending-text-hover"
    >
      <mat-icon>schedule</mat-icon>
    </button>
    } @if (request.assignedActivity) {
    <button
      *ngIf="request.assignedActivity.isLocked"
      [disabled]="actionLoading()"
      mat-icon-button
      matTooltip="Unlock currently assigned shift"
      (click)="lockRequest(request)"
    >
      <mat-icon>lock</mat-icon>
    </button>
    <button
      *ngIf="!request.assignedActivity.isLocked"
      [disabled]="actionLoading()"
      mat-icon-button
      matTooltip="Lock currently assigned shift"
      (click)="lockRequest(request)"
    >
      <mat-icon>lock_open</mat-icon>
    </button>
    <button mat-icon-button [disabled]="actionLoading()" matTooltip="Remove assigned shift" (click)="clearRequest(request)" class="declined-text-hover">
      <mat-icon>clear</mat-icon>
    </button>
    }
  </div>
</ng-template>
