import * as d3 from 'd3';
import { IShift } from '../shifts/base.shift';
import { EventEmitter } from '@angular/core';
import { IBaseGrid } from '../grid/base-grid.interface';
import { IBaseTimeline } from '../utils/interfaces';

export interface IShiftableItem {
  shifts: (shifts?: IShift[]) => this | IShift[];
  removeShift: (shift: IShift) => this;
}

export interface IBaseItem {
  readonly title: string;
  index: number;
  level: number;
  readonly isFirst: boolean;
  readonly isLast: boolean;
  readonly indicationFillColor: string;
  parent: IOpenableItem;
  readonly config: IItemConfig;
  readonly height: number;
  y(y?: number): number;
  node(): Element;
  element(): d3.Selection<any, any, any, any>;
  getGrid(): IBaseGrid;
  getTimeline(): IBaseTimeline;
  renderBackground(): void;
  renderGridBackground(): void;
  renderOffset(): void;
  renderTitle(): void;
  remove(): void;
  updatePosition(): void;
  clearCache(): void;
}

export interface IOpenableItem extends IBaseItem {
  onOpen: EventEmitter<any>;
  onClose: EventEmitter<any>;
  readonly items: IBaseItem[];
  isOpen: () => boolean;
  open: () => void;
  close: () => void;
  toogle: () => void;
  updateItemsPosition: (items: IBaseItem[]) => void;
}

// custom type guard, as a workarounf "instanceof" not working with interfaces in TS
// @see https://github.com/Microsoft/TypeScript/issues/19120
// @see https://www.typescriptlang.org/docs/handbook/advanced-types.html#user-defined-type-guards
export function isOpenableItem(item: any): item is IOpenableItem {
  return !!(item as IOpenableItem).isOpen;
}

export interface IItemConfig {
  height?: number,
  offset?: { top?: number, bottom?: number }
}
