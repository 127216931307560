import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SideBarService } from '@shared/services/side-bar/side-bar.service';
import { Subscription } from 'rxjs';
import { delay, tap } from 'rxjs/operators';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SideBarComponent implements OnInit, OnDestroy {
  private sideBarClosedSubscription: Subscription;
  private sideBarActivatedSubscription: Subscription;
  private notificationTriggeredSubscription: Subscription;

  public isVisible = false;
  public isLocked = false;
  public displayBanner = false;
  public bannerContent = '';

  constructor(private readonly sideBarService: SideBarService, private readonly changeDetector: ChangeDetectorRef, private readonly router: Router) {}

  public ngOnInit(): void {
    this.sideBarService.initializeFromLocalStorage();
    this.isLocked = this.sideBarService.isLocked;
    this.isVisible = this.sideBarService.isLocked;

    this.sideBarClosedSubscription = this.sideBarService.sideBarClosed$.subscribe(() => {
      if (this.isVisible) {
        this.close();
      }
    });

    this.sideBarActivatedSubscription = this.sideBarService.sideBarActivated$.subscribe(() => {
      if (!this.isVisible) {
        this.open();
      }
    });

    this.notificationTriggeredSubscription = this.sideBarService.notificationTriggered$
      .pipe(
        tap(content => {
          this.bannerContent = content;
          this.displayBanner = true;
        }),
        delay(3000),
        tap(() => {
          this.displayBanner = false;
          this.changeDetector.markForCheck();
        })
      )
      .subscribe(() => {});
  }

  public ngOnDestroy(): void {
    this.sideBarClosedSubscription.unsubscribe();
    this.sideBarActivatedSubscription.unsubscribe();
    this.notificationTriggeredSubscription.unsubscribe();
  }

  public toggleLocked(): void {
    this.isLocked = !this.isLocked;
    this.sideBarService.setIsLocked(this.isLocked);
  }

  public open(): void {
    this.isVisible = true;
    this.changeDetector.markForCheck();
  }

  public close(): void {
    this.isVisible = false;
    if (this.isLocked) {
      this.toggleLocked();
    }
    this.router.navigate([{ outlets: { sidebar: null } }]);
  }
}
