import * as d3 from 'd3';
import { isoWeek } from '@helpers';

export const weekFormat = d3.timeFormat('%W');

export function getYearByDateForCalendar(date, targetYear) {
  const yearStart = new Date(date.getFullYear(), 0, 1);
  const yearEnd = new Date(date.getFullYear(), 11, 31);
  const fullYear = date.getFullYear();

  if (yearStart.getUTCDay() !== 0 && date.getMonth() === 0 && date.getDate() <= 6 - yearStart.getUTCDay() + 1) {
    return fullYear - 1;
  } else if (
    date.getMonth() === 11 &&
    fullYear === targetYear &&
    yearEnd.getDate() === 31 &&
    date.getDate() === 31 &&
    yearEnd.getDay() === 1
  ) {
    return fullYear + 1;
  } else {
    return fullYear;
  }
}

export function getCountOfWeeksForCalendar(year) {
  const firstDayOfYear = new Date(year, 0, 1);
  const lastDayOfYear = new Date(year, 11, 31);
  const dayms = 1000 * 60 * 60 * 24;
  const countOfDaysInYear = (lastDayOfYear.getTime() - firstDayOfYear.getTime()) / dayms;

  let countOfWeeksInYear = Math.ceil((countOfDaysInYear + firstDayOfYear.getDay()) / 7);

  const weekStart = isoWeek(firstDayOfYear);
  const weekEnd = isoWeek(lastDayOfYear);

  if (weekStart !== 1 && (weekEnd === 1 || (weekEnd === 52 && firstDayOfYear.getDay() === 0 && lastDayOfYear.getDay() === 0))) {
    countOfWeeksInYear += 1;
  }

  return countOfWeeksInYear;
}
