import { EmployeeModel } from '../auth/employee.model';

export class SummaryDataModel {

  public access: AccessModel[];

  constructor(data: any = {}) {
    this.access = data && data.access && data.access.map(el => new AccessModel(el)) || [];
  }

}


export class AccessModel {

  public employee: EmployeeModel;
  public access_level: string;

  constructor(data: any = {}) {
    this.employee = data.employee && new EmployeeModel(data.employee) || void 0;
    this.access_level = data.access_level || void 0;
  }
}
