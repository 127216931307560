import { gql } from 'apollo-angular';

export const loadDivision = gql`
  query loadDivision($id: Int!) {
    division(id: $id) {
      id
      name
      companyId
    }
  }
`;

export const loadDivisions = gql`
  query loadDivisions {
    divisions {
      id
      name
      companyId
    }
  }
`;

export const createDivision = gql`
  mutation createDivision($name: String!) {
    createDivision(name: $name) {
      id
      name
    }
  }
`;

export const updateDivision = gql`
  mutation updateDivision(
    $id: Int!,
    $name: String!
  ) {
    updateDivision (
      id: $id,
      name: $name
    ) {
      id
      name
      companyId
    }
  }
`;

export const deleteDivision = gql`
  mutation deleteDivision($id: Int!) {
    deleteDivision(id: $id) {
      id
    }
  }
`;

export const createDivisionCompanyRelation = gql`
  mutation createDivisionCompanyRelation(
    $divisionId: Int!,
    $companyId: Int!
  ) {
    createDivisionCompanyRelation(
      divisionId: $divisionId,
      companyId: $companyId
    )
  }
`;

export const deleteDivisionCompanyRelation = gql`
  mutation deleteDivisionCompanyRelation($divisionId: Int!) {
    deleteDivisionCompanyRelation(divisionId: $divisionId)
  }
`;
