import * as d3 from 'd3';

import { xmlns } from '@helpers';

import { Header } from './header';


export abstract class BasePartialHeader {

  protected $el: d3.Selection<SVGGElement, any, any, any>;

  private _header: Header;

  constructor() {
    this.initElements();
  }

  abstract get height(): number;

  get y() {
    return this.prev && (this.prev.height + this.prev.y) || 0;
  }

  get index() {
    return this.header.orderedPartials.indexOf(this);
  }

  get prev() {
    return this.header.orderedPartials[this.index - 1];
  }

  get next() {
    return this.header.orderedPartials[this.index + 1];
  }

  set header(header: Header) {
    this._header = header;
  }

  get header() {
    return this._header;
  }

  get timeline() {
    return this.header.timeline;
  }

  get grid() {
    return this.timeline.grid();
  }

  get menu() {
    return this.timeline.menu();
  }

  get element() {
    return this.$el;
  }

  get node() {
    return this.$el.node();
  }

  public abstract init();

  public abstract render();

  public abstract subscribe();

  protected initElements() {
    const el = document.createElementNS(xmlns, 'g');
    this.$el = d3.select(el);
  }

}
