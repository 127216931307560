export enum WeekDayName {
    SUNDAY = 'SUN',
    MONDAY = 'MON',
    TUESDAY = 'TUE',
    WEDNESDAY = 'WED',
    THURSDAY = 'THU',
    FRIDAY = 'FRI',
    SATURDAY = 'SAT',
    HOLIDAY = 'HOL',
    SPECIAL = 'SPEC',
}

export enum WeekDayValue {
    SUNDAY = 0,
    MONDAY,
    TUESDAY,
    WEDNESDAY,
    THURSDAY,
    FRIDAY,
    SATURDAY,
    HOLIDAY,
    SPECIAL,
}


export enum DayOfWeekFormat {
    short = '',
    default = 'ddd',
    long = 'dddd'
}

export enum MonthFormat {
    short = 'MMM',
    long = 'MMMM'
}
