import { BaseModel } from '../base.model';
import { CombinationModel, SectorModel } from './index';


export class ConfigurationModel extends BaseModel {

  public name: string;
  public abbreviation: string;
  public description: string;

  public sectors: SectorModel[];
  public combinations: CombinationModel[];
  public sectorIds: number[] = [];
  public combinationIds: number[] = [];

  public position: number;
  public type = 'configurations';

  constructor(data: any = {}) {
    super(data);

    this.name = data.name || '';
    this.abbreviation = data.abbreviation || '';
    this.description = data.description || '';

    this.sectors = (data.sectors && data.sectors.map(el => new SectorModel(el))) || [];
    this.combinations = (data.combinations && data.combinations.map(el => new CombinationModel(el))) || [];
    this.sectorIds = (data.sectorIds && data.sectorIds.slice()) || [];
    this.combinationIds = (data.combinationIds && data.combinationIds.slice()) || [];

    this.position = data.position || null;
  }

  get code() {
    return this.abbreviation;
  }

  get sectorsAndCombinations() {
    return [...this.sectors, ...this.combinations];
  }

}
