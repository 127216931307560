import * as d3 from 'd3';
import { Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { pathForRoundedRect, translate, xmlns } from '@helpers';

import { BaseInterval } from '../intervals/base.interval';
import { IBaseGrid } from '../grid/base-grid.interface';
import { SubItem } from '../items/sub.item';
import { SimpleItem } from '../items/simple.item';
import { IRenderable, IElement, ISubscribable, IBaseTimeline } from '../utils/interfaces';
import { Defs } from '../defs/defs';
import { ResponsiveItem } from '../items/responsive.item';
import { Cutable } from '../cutable/cutable';
import { OpenableItem } from '../items/openable.item';
import { IBaseMenu } from '../menu/base-menu.interface';
import { Grid } from '../grid/grid';


export interface IShift extends IRenderable, IElement, ISubscribable {

  // need to read this property to properly place night shifts
  title: string;
  interval: (interval?: BaseInterval) => BaseInterval | this;
  endInterval: () => BaseInterval;
  item: (item?: ResponsiveItem | SubItem | OpenableItem | SimpleItem) => this | SubItem | OpenableItem | SimpleItem | ResponsiveItem;

  timeline: (timeline?: IBaseTimeline) => IBaseTimeline | this;
  grid: (grid?: IBaseGrid) => this | IBaseGrid;
  menu: (menu?: IBaseMenu) => IBaseMenu | this;
  defs: (defs?: Defs) => Defs | this;

  dateFrom: () => Date;
  dateTo: () => Date;

  attachShiftToInterval: () => void;
  detach: () => Node;
  show: () => this;
  hide: () => this;

  compare: (interval: BaseInterval) => boolean;
  overlap: (shift: IShift) => boolean;
  overlapByCoords: (x1: number, x2: number) => boolean;
  remove: () => void;
}

// rename to base-timeline-shift.ts and BaseTimelineShift
export class BaseShift implements IShift {

  set title(title) {
    this._title = title;
    this.$title.text(this._title);
  }

  get title() {
    return this._title;
  }

  get dateFromUnix() {
    return this._dateFromUnix;
  }

  get dateToUnix() {
    return this._dateToUnix;
  }

  static readonly STATUSES = {
    warn: 'warning',
    success: 'success',
    error: 'danger',
    none: 'default'
  };

  protected el: SVGGElement;
  protected $el: d3.Selection<SVGGElement, any, any, any>;
  protected $bodyContainer: d3.Selection<any, any, any, any>;
  protected $decorator: d3.Selection<any, any, any, any>;
  protected $shadow: d3.Selection<any, any, any, any>;
  protected $background: d3.Selection<any, any, any, any>;
  protected $title: d3.Selection<any, any, any, any>;
  protected $lock: d3.Selection<any, any, any, any>;

  protected _item: SubItem | OpenableItem | SimpleItem | ResponsiveItem;
  protected _interval: BaseInterval;
  protected _intervals: BaseInterval[] = [];

  protected _timeline: IBaseTimeline;
  protected _grid: Grid;
  protected _menu: IBaseMenu;
  protected _defs: Defs;

  protected _delta: number;

  protected rendered = false;

  protected menuOnChange$: Subscription;
  protected gridOnZoom$: Subscription;

  protected props: any = {
    renderProps: {
      sizes: null
    },
  };

  private _dateFromUnix: number;
  private _dateToUnix: number;

  constructor(protected _title = '',
    protected _dateFrom: Date,
    protected _dateTo: Date
  ) {
    this.el = document.createElementNS(xmlns, 'g');
    this.$el = d3.select<SVGGElement, any>(this.el).classed('task', true);
    this.initElements();
    this._delta = this._dateTo.getTime() - this._dateFrom.getTime();

    this.updateUnixTimes();
  }

  public initElements() {
    this.initBodyContainer();
    this.initShadow();
    this.initBackground();
    this.initTitle();
  }

  public render() {

    // Looking available intervals for render
    this.assignToRelatedIntervals();

    // Init props
    this.initRenderProps();

    // Set element position
    this.$el.attr('transform', translate(0, this._item.shiftPosition(this)));

    // Render background and title
    this.renderBackgroundShadow();
    this.renderBackground();
    this.renderTitle();

    // Events
    this.events();

    this.rendered = true;

    return this.el;
  }

  public attachShiftToInterval() {
    if (!this.rendered) {
      this.render();
    }

    if (!this._interval) {
      if (!this._grid.assignToAvailableInterval(this)) {
        return;
      }
    }

    this.$el.attr('transform', translate(0, this._item.shiftPosition(this)));

    this._interval.addShift(this);
  }

  public updatePositionByItem() {
    this.$el.attr('transform', translate(0, this._item.shiftPosition(this)));
  }

  /**
   * DOM event listeners
   */
  public events() {
    this.$el.on('mouseenter', () => {
      setTimeout(() => {
        if (this.$shadow) {
          this.$shadow.classed('hover', true);
        }
      }, 0);
    });
    this.$el.on('mouseleave', () => {
      setTimeout(() => {
        if (this.$shadow) {
          this.$shadow.classed('hover', false);
        }
      }, 0);
    });
  }

  public subscribe() {
    const menu = this._menu;

    if (!this.menuOnChange$) {
      this.menuOnChange$ = menu.onChange.pipe(takeUntil(this._timeline.onDestroy)).subscribe(() => {
        const item = this._item;
        if (menu.visible.indexOf(item) > -1) {
          this.show();
        } else {
          this.hide();
        }
      });
    }

    if (!this.gridOnZoom$) {
      this.gridOnZoom$ = this._grid.onZoom.pipe(takeUntil(this._timeline.onDestroy)).subscribe(() => {
        this.rendered = false;
        this.detach();
      });
    }

    return this;
  }

  /******************************* Helpers ****************************************************/
  /**
   * Width of task in pixes
   * @returns {number}
   */
  public width() {
    const scale = this._grid.scale();
    return scale.invert(this._dateTo.getTime() - this._dateFrom.getTime());
  }

  public height() {
    return this._item.config.height * 0.825;
  }

  /**
   * If true task will be attached to interval which has passed date to this function
   * @param interval
   * @returns {boolean}
   */
  public compare(interval: BaseInterval): boolean {
    return this._dateFrom.getTime() === interval.dateFrom().getTime();
  }

  /**
   * Overlapping with some shift
   * @param shift
   */
  public overlap(shift: IShift) {
    const from = this._dateFrom.getTime();
    const to = this._dateTo.getTime();
    const start = shift.dateFrom().getTime();
    const end = shift.dateTo().getTime();
    return ((from < end) && (to > start));
  }

  public overlapByCoords(x1: number, x2: number) {
    const interval = this.interval() as BaseInterval;
    if (!interval) {
      return false;
    }
    const leftLimit = interval.x();
    const rightLimit = leftLimit + this.width();

    return x2 > leftLimit && x1 <= leftLimit
      || x1 < rightLimit && rightLimit <= x2
      || x1 >= leftLimit && x2 <= rightLimit;
  }

  public assignToRelatedIntervals() {
    // TODO fix it
    if (!this._interval) {
      return;
    }

    const interval = this._interval;

    const intervalsCount = interval.getCountOfUsedIntervals(this._dateFrom, this._dateTo);

    if (intervalsCount > 1) {
      const intervals = this._grid.intervals();

      let nextInterval = interval;

      let shouldBeWatched = false;
      const lengthFromCurrentToLastInterval = Math.abs(intervals.indexOf(interval) - intervals.length);
      const lim = (intervalsCount < lengthFromCurrentToLastInterval) ? intervalsCount - 1 : lengthFromCurrentToLastInterval;

      for (let i = 1; i <= lim; i++) {
        nextInterval = this._grid.nextInterval(nextInterval);

        if (nextInterval) {
          nextInterval.addOverlappedShift(this);
          this.addRelatedInterval(nextInterval);
        } else {
          shouldBeWatched = true;
        }
      }

      if (shouldBeWatched) {
        const nextSubscription = this._grid.onNextInterval.pipe(takeUntil(this._timeline.onDestroy)).subscribe((item) => {
          this.assignToRelatedIntervals();
          nextSubscription.unsubscribe();
        });

        const prevSubscription = this._grid.onPrevInterval.pipe(takeUntil(this._timeline.onDestroy)).subscribe((item) => {
          this.assignToRelatedIntervals();
          prevSubscription.unsubscribe();
        });
      }
    }
  }

  /******************************* Dates ******************************************************/

  /**
   * Set date from and change dateTo depending on delta
   * @param date
   * @returns {BaseShift}
   */
  public setDates(date: Date) {
    this._dateFrom = new Date(date.getTime());
    this._dateTo = new Date(date.getTime() + this._delta);
    return this;
  }

  /**
   * Date from
   * @returns {Date}
   */
  public dateFrom(): Date {
    return this._dateFrom;
  }

  /**
   * Date to
   * @returns {Date}
   */
  public dateTo(): Date {
    return this._dateTo;
  }

  /**
   * Re-write unix timestamps from current dates
   */
  public updateUnixTimes() {
    this._dateFromUnix = this._dateFrom.getTime();
    this._dateToUnix = this._dateTo.getTime();
  }

  /******************************* Main *******************************************************/
  /**
   * Assign item to task
   * @param item
   * @returns {any}
   */
  public item(item?: SubItem | OpenableItem | SimpleItem | ResponsiveItem): this | SubItem | OpenableItem | SimpleItem | ResponsiveItem {
    if (!arguments.length) {
      return this._item;
    }
    this._item = item;
    return this;
  }

  /**
   *
   * @param interval
   * @returns {any}
   */
  public interval(interval?: BaseInterval): BaseInterval | this {
    if (!arguments.length) {
      return this._interval;
    }
    this._interval = interval;
    return this;
  }

  /**
   * Getter for related intervals
   * @returns {BaseInterval[]}
   */
  public relatedIntervals() {
    return this._intervals;
  }

  /**
   * Setter for new related interval
   * @param interval
   */
  public addRelatedInterval(interval: BaseInterval) {
    if (this._intervals.indexOf(interval) === -1) {
      this._intervals.push(interval);
    }
  }

  /**
   * returned last (right) interval
   */
  public endInterval() {
    const interval = this.interval() as BaseInterval;
    if (interval) {
      return this._grid.getNearIntervalByCoord(interval.x() + this.width());
    } else {
      return void 0;
    }
  }

  /**
   * Getter and setter for timeline
   * @param timeline
   * @returns {any}
   */
  public timeline(timeline?: IBaseTimeline): IBaseTimeline | this {
    if (!arguments.length) {
      return this._timeline;
    }
    this._timeline = timeline;
    return this;
  }

  /**
   * Getter and setter for grid property
   * @param grid
   * @returns {any}
   */
  public grid(grid?: Grid): this | Grid { // fix for getting grid
    if (!arguments.length) {
      return this._grid;
    }
    this._grid = grid;
    this._grid.addShift(this);
    return this;
  }

  /**
   * Getter and setter for menu property
   * @param menu
   * @returns {any}
   */
  public menu(menu?: IBaseMenu): IBaseMenu | this {
    if (!arguments.length) {
      return this._menu;
    }
    this._menu = menu;
    return this;
  }

  /**
   * Getter and setter for defs property
   * @param defs
   */
  public defs(defs?: Defs): Defs | this {
    if (!arguments.length) {
      return this._defs;
    }
    this._defs = defs;
    return this;
  }

  /******************************** DOM ********************************************************/
  /**
   * Show task
   * @returns {BaseShift}
   */
  public show() {
    this.$el.attr('visibility', 'visible')
      .attr('transform', translate(0, this._item.shiftPosition(this)));
    return this;
  }

  /**
   * Hide task
   * @returns {BaseShift}
   */
  public hide() {
    this.$el.attr('visibility', 'hidden');
    return this;
  }

  public restore(): void {
    this._grid.addShift(this);
    const item = this._item;
    item.addShift(this);
    if (this._interval) {
      this._interval.addShift(this);
    }
    this.render();
  }

  /**
   * Remove functionality
   */
  public remove(): void {
    this._grid.removeShift(this);
    const item = this._item as SimpleItem;
    item.removeShift(this);
    if (this._interval) {
      this._interval.removeShift(this);
    }
    this.$el.selectAll('*').remove();
    this.$el.remove();
  }

  /************************************ IElement interface ***********************************/
  /**
   * DOM element
   * @returns {Element}
   */
  public node() {
    return this.el;
  }

  /**
   * d3.Selection of DOM element
   * @returns {d3.Selection<any, any, any, any>}
   */
  public element() {
    return this.$el;
  }

  /**
   * Clone of element
   * @returns {Node}
   */
  public clone() {
    return this.el.cloneNode(true);
  }

  /**
   * Detach node from DOM
   */
  public detach() {
    return this.el.parentNode && this.el.parentNode.removeChild(this.el);
  }

  public createIndicator() {
    this.initRenderProps();

    const props = this.props.renderProps;
    const xPos = props.shiftMargin;
    const yPos = props.height + props.offset - 4;

    if (this.$decorator) {
      this.$decorator.remove();
    }

    this.$decorator = this.$bodyContainer.append('path')
      .classed('decorator', true)
      .attr('transform', translate(xPos, yPos))
      .attr('d', pathForRoundedRect(
        0,
        0,
        props.width,
        4,
        2.5,
        false,
        false,
        true,
        true,
      ));
  }

  protected enableCutableMode(mouseCoords) {
    return new Cutable(this._interval, this._item, this._timeline, this._grid, this.width(), mouseCoords);
  }

  /**
   * Return difference by interval indexes between two shifts
   * @param shift1
   * @param shift2
   * @returns {any}
   */
  protected differenceBetweenShiftsInIntervals(shift1, shift2) {
    const intervals = this._grid.intervals();

    const shift1Interval = (shift1 && shift1.endInterval()) || null;
    const shift2Interval = (shift2 && shift2.interval()) || null;
    if (!shift1Interval || !shift2Interval) { return false; }

    const shift1IntervalIndex = intervals.indexOf(shift1Interval);
    const shift2IntervalIndex = intervals.indexOf(shift2Interval);

    return Math.abs(shift1IntervalIndex - shift2IntervalIndex);
  }

  protected initRenderProps() {
    const sizes = this._timeline.sizes();

    this.props.renderProps.sizes = sizes;
    this.props.renderProps.rowHeight = this._item.config.height;
    this.props.renderProps.shiftMargin = sizes.shiftMargin;
    this.props.renderProps.width = Math.round(this.width() - this.props.renderProps.shiftMargin * 2);
    this.props.renderProps.height = this.height();
    this.props.renderProps.offset = this.props.renderProps.rowHeight * 0.0925;

    return this.props.renderProps;
  }

  /************************************ Render interfaces ***********************************/

  /** Initializations **/
  protected initBodyContainer() {
    this.$bodyContainer = this.$el.append('g').classed('body-container', true);
  }

  protected initBackground() {
    this.$background = this.$bodyContainer.append('rect');
  }

  protected initShadow() {
    this.$shadow = this.$bodyContainer.append('rect');
  }

  protected initTitle() {
    this.$title = this.$bodyContainer.append('text').attr('pointer-events', 'none');
  }

  /** Render **/

  protected renderBackgroundShadow() {
    const props = this.props.renderProps;

    this.$shadow.classed('shadow', true)
      .attr('height', props.height)
      .attr('width', props.width - 1)
      .attr('y', props.offset)
      .attr('x', props.shiftMargin)
      .attr('rx', 3)
      .attr('ry', 3);
  }

  protected renderBackground() {
    const props = this.props.renderProps;

    this.$background.classed('background', true)
      .attr('height', props.height)
      .attr('y', props.offset)
      .attr('x', props.shiftMargin)
      .attr('width', props.width)
      .attr('fill', '#fff')
      .attr('rx', 3)
      .attr('ry', 3);
  }

  protected renderTitle() {
    const props = this.props.renderProps;

    this.$title.classed('content', true)
      .attr('y', props.rowHeight / 2)
      .attr('x', props.width / 2 + props.shiftMargin)
      .attr('dy', '0.5ex')
      .text(this._title);
  }

  /************************************ Locks ********************************************/

  /**
   * Render lock
   * @returns {BasedShift}
   */
  protected renderLock() {
    const sizes = this._timeline.sizes();
    const rh = this._item.config.height;
    const shiftMargin = sizes.shiftMargin;
    const width = Math.round(this.width() - shiftMargin * 2); // fixme any ideas ?
    const height = rh * 0.825;

    const lockFontSize = 13;
    const lockHeight = 16;

    this.$el.classed('locked', true);

    this.$lock = this.$bodyContainer
      .append('g')
      .classed('lock', true)
      .attr('transform', translate(-2, (rh / 2) - (lockHeight / 2) - 1));


    this.$lock.append('path')
      .classed('lock-bg', true)
      .attr('d', 'M4.666 0 ' +
        'h13.568 ' +
        'c.873 0 1.645.566 1.907 1.398' +
        'L22.7 9.5' +
        'H.2' +
        'l2.56-8.102' +
        'C3.02.566 3.792 0 4.665 0z')
      .attr('rx', 3)
      .attr('ry', 3)
      .attr('transform', translate(width / 2 - 22.5 / 2 + 5, height / 2));

    this.$lock.append('text')
      .classed('material-icons', true)
      .attr('y', height - lockFontSize / 2 + 1.5) // Height - fontsize/2 + e
      .attr('x', width / 2 + lockFontSize / 2 - 1)
      .text('vpn_key');

    return this;
  }

  /**
   * Remove lock render
   */
  protected removeLock() {
    this.$lock.remove();
    this.$el.classed('locked', false);
  }
}
