<div class="vacay-info" *ngFor="let request of vacayList$ | async">
  Shift: <strong>{{ request.shiftCode.code }}</strong>
  <small *ngIf="request.vacayRequestBatch">
    <strong>{{ request.vacayRequestBatch?.dateFrom | shMoment: 'DD/MM/YYYY' }}</strong>
    <strong *ngIf="!request.vacayRequestBatch.isSingleDay()"> - {{ request.vacayRequestBatch?.dateTo | shMoment: 'DD/MM/YYYY' }}</strong>
  </small>
  <small>{{ request.vacayRequestBatch?.comment }}</small>
  <small>Created {{ request.createdAt | shMoment: 'DD/MM/YYYY' }} by {{ request.createdBy.name }}</small>

  <button mat-icon-button color="warn" *ngIf="state === ScheduleState.OPEN" (click)="deleteVacayRequest(request)" class="delete-button" [disabled]="deleting()">
    <mat-icon>delete</mat-icon>
  </button>
</div>

<div *ngIf="!(vacayList$ | async)?.length">
  <p>No shifts requested.</p>
</div>
