import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ScheduleStatisticLevel } from '@enums';
import { ScheduleStatistic } from '../dto/schedule-statistic';

@Injectable({
  providedIn: 'root'
})
export class StatisticsState {
  private readonly statisticsSource$ = new BehaviorSubject<ScheduleStatistic[]>([]);

  public statistics$ = this.statisticsSource$.asObservable();

  public setStatistics(statistics: ScheduleStatistic[]) {
    this.statisticsSource$.next(statistics);
  }

  getStatisticsByEmployee(employeeId: number) {
    return this.statistics$.pipe(map(x => x.find(statistic => statistic.level === ScheduleStatisticLevel.EMPLOYEE && statistic.employee === employeeId)));
  }

  getStatisticsByWeekAndEmployee(week: number, employeeId: number) {
    return this.statistics$.pipe(
      map(x => x.find(statistic => statistic.level === ScheduleStatisticLevel.WEEK && statistic.week === week && statistic.employee === employeeId))
    );
  }

  getStatisticsByShift(parentId: string) {
    return this.statistics$.pipe(map(x => x.find(statistic => statistic.level === ScheduleStatisticLevel.SHIFT && statistic.shift === parentId)));
  }

  public cleanUp(): void {
    this.statisticsSource$.next([]);
  }
}
