import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'sh-validator-component',
  templateUrl: './validator.component.html',
  styleUrls: ['./validator.component.scss']
})
export class ValidatorComponent {
  @Input() control: AbstractControl;

  get errorMessage() {
    if (this.control) {
      for (const propertyName in this.control.errors) {
        if ((this.control.errors.hasOwnProperty(propertyName) && this.control.touched) || this.control.dirty) {
          return this.getValidatorErrorMessage(propertyName, this.control.errors[propertyName]);
        }
      }
    }

    return null;
  }

  private getValidatorErrorMessage(validatorName: string, validatorValue?: any) {
    const config = {
      required: 'Required',
      invalidEmailAddress: 'Invalid email address',
      invalidPassword: 'Invalid password. Password must be at least 6 characters long, and contain a number.',
      minlength: `Minimum length ${validatorValue.requiredLength}`,
      max: `Maximum value ${validatorValue.max}`,
      min: `Minimum value ${validatorValue.min}`,
      requiredAutocomplete: 'Required',
      requiredDatepicker: 'Required',
      duplicatedDates: `Duplicated dates not allowed: ${validatorValue.duplicatedDates?.join(', ')}`,
      invalidDatePeriod: 'Period start should be before period end',
      overlappingPeriods: `Periods should not overlap: ${validatorValue.overlappingPeriods?.join(', ')}`
    };

    return config[validatorName];
  }
}
