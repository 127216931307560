<div *ngIf="!loading; else loadingContent">
  <div class="widget-title">
    <div>
      <div>My preferences</div>
    </div>
  </div>

  <div [formGroup]="preferencesFormGroup">
    <mat-accordion multi>
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title> <mat-icon>access_time</mat-icon> Worktime preferences </mat-panel-title>
        </mat-expansion-panel-header>

        <mat-form-field appearance="fill" class="full-width">
          <mat-label>Preferred time off between shifts</mat-label>
          <input type="number" required [min]="minBreak" [max]="maxBreak" matInput formControlName="afterShiftBreak" />
          <mat-hint> Must be between {{ minBreak }} and {{ maxBreak }}</mat-hint>
          <mat-error> Must be between {{ minBreak }} and {{ maxBreak }} </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" class="mt-3 full-width">
          <mat-label>Earliest shift starting time</mat-label>
          <app-time-picker formControlName="startWorktimeMin"></app-time-picker>
        </mat-form-field>

        <mat-form-field appearance="fill" class="full-width">
          <mat-label>Latest shift starting time</mat-label>
          <app-time-picker formControlName="startWorktimeMax"></app-time-picker>
        </mat-form-field>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <div class="footer">
    <button mat-raised-button color="primary" (click)="save()" [disabled]="!preferencesFormGroup.valid || !preferencesFormGroup.dirty">Save</button>
    <button mat-raised-button (click)="cancel()">Cancel</button>
  </div>
</div>

<ng-template #loadingContent>
  <mat-spinner [diameter]="80" color="accent"></mat-spinner>
</ng-template>
