import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DialogWindowService {
  public openAvaiWarnings(id: number): void {
    window.open(
      `/avai-warning?id=${id}`,
      'avaiWarnings',
      `toolbar=no,
      location=no,
      status=no,
      menubar=no,
      scrollbars=yes,
      resizable=yes,
      width=${600},
      height=${450}`
    );
  }

  public openVacayRequests(scheduleId: number): void {
    window.open(
      `/vacay-requests?schedule=${scheduleId}`,
      'vacayRequests',
      `toolbar=no,
      location=no,
      status=no,
      menubar=no,
      scrollbars=yes,
      resizable=yes,
      width=${1000},
      height=${600}`
    );
  }

  public openRequiredShiftsReport(id: number): void {
    window.open(
      `/required-shifts-report?id=${id}`,
      'Required shifts report',
      `toolbar=no,
      location=no,
      status=no,
      menubar=no,
      scrollbars=yes,
      resizable=yes,
      width=${600},
      height=${450}`
    );
  }
}
