import { gql } from 'apollo-angular';

export const scheduleValidations = gql`
  query scheduleValidations($scheduleId: Int!) {
    scheduleValidations(scheduleId: $scheduleId) {
      id
      checksum
      code
      day
      employee
      isCrossShifts
      level
      message
      severity
      shifts
      week
      updatedAt
      isAccepted
      acceptedBy {
        id
        firstName
        lastName
      }
    }
  }
`;

export const toggleValidation = gql`
  mutation toggleValidation($checksum: String!, $scheduleId: Int!) {
    toggleValidation(checksum: $checksum, scheduleId: $scheduleId) {
      id
    }
  }
`;

export const revalidateMutation = gql`
  mutation revalidateSchedule($scheduleId: Int!) {
    revalidateSchedule(scheduleId: $scheduleId) {
      id
    }
  }
`;

export const scheduleValidationsUpdatedSubscription = gql`
  subscription scheduleValidationsUpdated($scheduleId: Int) {
    scheduleValidationsUpdated(scheduleId: $scheduleId) {
      validations {
        id
        checksum
        code
        day
        employee
        isCrossShifts
        level
        message
        severity
        shifts
        week
        updatedAt
        isAccepted
        acceptedBy {
          id
          firstName
          lastName
        }
      }
    }
  }
`;
