import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { OverviewMonth } from '../dto/overview-month';
import { OverviewColumn } from '../dto/overview-column';
import { AssignedActivity, AssignedEmployee, RequiredShift, ScheduleDto } from '../dto';
import { EmployeeSortingMode } from '../helpers/sorting-functions';
import { EmployeeFilter } from '../dto/employee-filter';
import { ConcurrentUserActionData } from '../dto/concurrent-user-action-data';
import { isActivityAgendaOne } from '../helpers/activities-helper';
import { AssignmentEvent } from '../services/employee-column-invalidation.service';
import { PREDICT_KEY } from '@app/rostr/_shared/consts/map-keys.const';
import { Group } from '../dto/group';
import { ScheduleState } from '@model/schedules/schedule-state.model';
import { ShiftStatus } from '@enums';

@Injectable({ providedIn: 'root' })
export class RostrState {
  constructor() {}

  private readonly _currentActiveColumnIdSubject$ = new BehaviorSubject<number>(0);
  private readonly _isGridLoadingSubject$ = new BehaviorSubject<boolean>(true);
  private readonly _isEmployeeListLoading$ = new BehaviorSubject<boolean>(true);
  private readonly _scheduleSubject$ = new BehaviorSubject<ScheduleDto>(null);
  private readonly _scheduleAssignedEmployeesSubject$ = new BehaviorSubject<AssignedEmployee[]>(null);
  private readonly _monthsSubject$ = new BehaviorSubject<OverviewMonth[]>([]);
  private readonly _columnsSubject$ = new BehaviorSubject<OverviewColumn[]>([]);
  private readonly _requiredShiftsSubject$ = new BehaviorSubject<Map<string, RequiredShift[]>>(null);
  private readonly _maxNumberOfUnassignedShifts$ = new BehaviorSubject<number>(0);
  private readonly _isUnassignedShiftsExpandedSubject$ = new BehaviorSubject<boolean>(false);
  private readonly _isUnassignedShiftsLockedSubject$ = new BehaviorSubject<boolean>(true);
  private readonly _groupsSubject$ = new BehaviorSubject<Group[]>([]);
  private readonly _skillsSubject$ = new BehaviorSubject<string[]>([]);
  private readonly _employeeFilterSubject$ = new BehaviorSubject<EmployeeFilter>(null);
  private readonly _employeeSortingModeSubject$ = new BehaviorSubject<EmployeeSortingMode>(EmployeeSortingMode.Index);
  private readonly _employeeSortingAscendingSubject$ = new BehaviorSubject<boolean>(true);
  private readonly _employeeDetailsVisibilityChangedSource = new Subject<void>();
  private readonly _shiftsFilterSource$ = new BehaviorSubject<string>('');
  private readonly _scheduleStateSubject$ = new BehaviorSubject<ScheduleState>(null);

  public readonly requiredShiftsColumnsLengths = new Map<string, number>();
  public detailsVisibilityEntries: Map<number, boolean> = new Map();
  public isBeingUsedByConcurrentUserEntries = new Map<number, Map<string, ConcurrentUserActionData>>();

  public readonly employeeSortingAscending$ = this._employeeSortingAscendingSubject$.asObservable();
  public readonly employeeSortingMode$ = this._employeeSortingModeSubject$.asObservable();
  public readonly unassignedShiftsLocked$ = this._isUnassignedShiftsLockedSubject$.asObservable();
  public readonly isGridLoading$: Observable<boolean> = this._isGridLoadingSubject$.asObservable();
  public readonly isCurrentActiveColumnId$: Observable<number> = this._currentActiveColumnIdSubject$.asObservable();
  public readonly isEmployeeListLoading$: Observable<boolean> = this._isEmployeeListLoading$.asObservable();
  public readonly scheduleModel$: Observable<ScheduleDto> = this._scheduleSubject$.asObservable();
  public readonly scheduleAssignedEmployees$: Observable<AssignedEmployee[]> = this._scheduleAssignedEmployeesSubject$.asObservable();
  public readonly months$: Observable<OverviewMonth[]> = this._monthsSubject$.asObservable();
  public readonly columns$: Observable<OverviewColumn[]> = this._columnsSubject$.asObservable();
  public readonly requiredShifts$: Observable<Map<string, RequiredShift[]>> = this._requiredShiftsSubject$.asObservable();
  public readonly unassignedShiftsLength$: Observable<number> = this._maxNumberOfUnassignedShifts$.asObservable();
  public readonly isUnassignedShiftsExpanded$: Observable<boolean> = this._isUnassignedShiftsExpandedSubject$.asObservable();
  public readonly groups$: Observable<Group[]> = this._groupsSubject$.asObservable();
  public readonly skills$: Observable<string[]> = this._skillsSubject$.asObservable();
  public readonly employeeFilter$: Observable<EmployeeFilter> = this._employeeFilterSubject$.asObservable();
  public readonly employeeDetailsVisibilityChanged$ = this._employeeDetailsVisibilityChangedSource.asObservable();
  public readonly shiftsFilter$: Observable<string> = this._shiftsFilterSource$.asObservable();
  public readonly scheduleState$: Observable<ScheduleState> = this._scheduleStateSubject$.asObservable();

  public setEmployeeSortingMode(mode: EmployeeSortingMode): void {
    this._employeeSortingModeSubject$.next(mode);
  }

  public setEmployeeSortingAscending(ascending: boolean): void {
    this._employeeSortingAscendingSubject$.next(ascending);
  }

  public setCurrentActiveColumnIdLoading(isLoading: number): void {
    this._currentActiveColumnIdSubject$.next(isLoading);
  }

  public setIsGridLoading(isLoading: boolean): void {
    this._isGridLoadingSubject$.next(isLoading);
  }

  public setIsEmployeeListLoading(isLoading: boolean): void {
    this._isEmployeeListLoading$.next(isLoading);
  }

  public setEmployeeFilter(employeeFilter: EmployeeFilter): void {
    this._employeeFilterSubject$.next(employeeFilter);
  }

  public setMaxNumberOfUnassignedShifts(max: number): void {
    this._maxNumberOfUnassignedShifts$.next(max);
  }

  public toggleUnassignedLock(): void {
    this._isUnassignedShiftsLockedSubject$.next(!this._isUnassignedShiftsLockedSubject$.getValue());
  }

  public setSkills(skills: string[]): void {
    this._skillsSubject$.next(skills);
  }

  public setGroups(groups: Group[]): void {
    this._groupsSubject$.next(groups);
  }

  public setRequiredShifts(requiredShifts: Map<string, RequiredShift[]>): void {
    this._requiredShiftsSubject$.next(requiredShifts);
  }

  public setRequiredShiftsForGivenTimestamp(timestamp: string, requiredShifts: RequiredShift[]): void {
    this._requiredShiftsSubject$.value.set(timestamp, requiredShifts);
    this._requiredShiftsSubject$.next(this._requiredShiftsSubject$.value);
  }

  public setSchedule(schedule: ScheduleDto): void {
    this._scheduleSubject$.next(schedule);
    this._scheduleStateSubject$.next(schedule.state);
  }

  public setAssignedEmployees(assignedEmployees: AssignedEmployee[]): void {
    this._scheduleAssignedEmployeesSubject$.next(assignedEmployees);
  }

  public setMonths(months: OverviewMonth[]): void {
    this._monthsSubject$.next(months);
  }

  public setColumns(columns: OverviewColumn[]): void {
    this._columnsSubject$.next(columns);
  }

  public expandUnassignedShifts(expand: boolean): void {
    this._isUnassignedShiftsExpandedSubject$.next(expand);
  }

  public notifyRowExpandedOrCollapsed(): void {
    this._employeeDetailsVisibilityChangedSource.next();
  }

  public setShiftsFilter(code: string): void {
    this._shiftsFilterSource$.next(code);
  }

  public getAllAssignedAgendaActivities(): AssignedActivity[] {
    const employees = this._scheduleAssignedEmployeesSubject$.getValue();
    return employees.flatMap(employee => Array.from(employee.assigned.values())).filter(activity => isActivityAgendaOne(activity));
  }

  public getAllVacayRequestedActivities(statuses: Map<number, Map<string, Map<number, ShiftStatus>>>): AssignedActivity[] {
    const employees = this._scheduleAssignedEmployeesSubject$.getValue();
    const resultActivities = [];
    employees.forEach(employee => {
      if (employee.hasVacay) {
        Array.from(employee.vacay.keys())
          .filter(key => key !== PREDICT_KEY)
          .forEach(timestamp => {
            const assignedActivityOnVacay = employee.assigned.get(timestamp);
            if (assignedActivityOnVacay && Array.from(statuses.get(employee.id).get(timestamp).values()).some(status => status === ShiftStatus.Success)) {
              resultActivities.push(assignedActivityOnVacay);
            }
          });
      }
    });
    return resultActivities;
  }

  public getAssignedEmployee(employeeId: number): AssignedEmployee {
    const employees = this._scheduleAssignedEmployeesSubject$.getValue();
    return employees.find(emp => emp.id === employeeId);
  }

  public upsertActivity(evt: AssignmentEvent): void {
    const employee = this.getAssignedEmployee(evt.employeeId);

    if (employee) {
      const existing = employee.assigned.get(evt.timestamp);
      if (existing) {
        Object.assign(existing, evt.activity);
      } else {
        employee.assigned.set(evt.timestamp, evt.activity);
      }
    } else {
      console.log('An attempt to update non existing employee occurred');
    }
  }

  public unassignActivity(evt: AssignmentEvent): void {
    const employee = this.getAssignedEmployee(evt.employeeId);

    if (employee) {
      employee.assigned.delete(evt.timestamp);
    } else {
      console.log('An attempt to unassign activity from non existing employee occurred');
    }
  }

  public updateScheduleState(newState: ScheduleState): void {
    this._scheduleStateSubject$.next(newState);
  }

  public cleanUp(): void {
    this._currentActiveColumnIdSubject$.next(0);
    this._isGridLoadingSubject$.next(true);
    this._isEmployeeListLoading$.next(true);
    this._scheduleSubject$.next(null);
    this._scheduleAssignedEmployeesSubject$.next(null);
    this._monthsSubject$.next([]);
    this._columnsSubject$.next([]);
    this._requiredShiftsSubject$.value.clear();
    this._maxNumberOfUnassignedShifts$.next(0);
    this._isUnassignedShiftsExpandedSubject$.next(false);
    this._isUnassignedShiftsLockedSubject$.next(true);
    this._employeeSortingAscendingSubject$.next(true);
    this._employeeSortingModeSubject$.next(EmployeeSortingMode.Index);
    this._groupsSubject$.next([]);
    this._skillsSubject$.next([]);
    this._employeeFilterSubject$.next(null);
    this._shiftsFilterSource$.next('');
    this.requiredShiftsColumnsLengths.clear();
    this.detailsVisibilityEntries.clear();
    this.isBeingUsedByConcurrentUserEntries = new Map<number, Map<string, ConcurrentUserActionData>>();
    this._scheduleStateSubject$.next(null);
  }
}
