import { Injectable } from '@angular/core';
import {
  CUSTOM_PERIOD_MODE,
  getKeyForIsoYearWeek,
  IFetchStatisticsParameters,
  IRawCategories,
  minutesToHours,
  minutesToHoursStatistic,
  STATS_CATEGORY,
  STATS_MODE
} from '@app/rostr/_shared';
import { getStartOfDayISOString } from '@helpers';
import { ScheduleDto } from '../dto';
import { combineLatest, Observable } from 'rxjs';
import { RostrStatisticsAPIService } from '../api/services/rostr-statistics-api.service';
import { RostrStatisticsState } from '../state/statistics/rostr-statistics.state';
import { filter, map, take } from 'rxjs/operators';
import { StatisticsState } from '../state/statistics.state';

@Injectable({ providedIn: 'root' })
export class RostrStatisticsFacade {
  public scheduleId: number;

  get weeklyStatisticActive$(): Observable<STATS_CATEGORY> {
    return this.rostrStatisticsState.weeklyStatistics.category$;
  }

  get weeklyStatisticMode$(): Observable<STATS_MODE | CUSTOM_PERIOD_MODE> {
    return this.rostrStatisticsState.weeklyStatistics.mode$;
  }

  get mainStatisticActive$(): Observable<STATS_CATEGORY> {
    return this.rostrStatisticsState.mainStatistics.category$;
  }

  get mainStatisticMode$(): Observable<STATS_MODE | CUSTOM_PERIOD_MODE> {
    return this.rostrStatisticsState.mainStatistics.mode$;
  }

  get weeklyStatisticLoading$(): Observable<boolean> {
    return this.rostrStatisticsState.weeklyStatistics.loading$;
  }

  get mainStatisticLoading$(): Observable<boolean> {
    return this.rostrStatisticsState.mainStatistics.loading$;
  }

  get mainStartDate$(): Observable<Date> {
    return this.rostrStatisticsState.mainStatistics.startDate$;
  }

  get weeklyStartDate$(): Observable<Date> {
    return this.rostrStatisticsState.weeklyStatistics.startDate$;
  }

  get mainEndDate$(): Observable<Date> {
    return this.rostrStatisticsState.mainStatistics.endDate$;
  }

  get weeklyEndDate$(): Observable<Date> {
    return this.rostrStatisticsState.weeklyStatistics.endDate$;
  }

  constructor(
    private readonly rostrStatisticsApi: RostrStatisticsAPIService,

    private readonly avaiStatisticsState: StatisticsState,
    private readonly rostrStatisticsState: RostrStatisticsState
  ) {}

  public cleanUp(): void {
    this.rostrStatisticsState.cleanUp();
  }

  public loadWeeklyStatistics(updateOnly = false): void {
    const weeklyStatActive = this.rostrStatisticsState.weeklyStatistics.category;
    const weeklyModeActive = this.rostrStatisticsState.weeklyStatistics.mode;
    const storedCategory = this.rostrStatisticsState.getStoredCategory(weeklyStatActive);
    const statToFetch = weeklyStatActive === STATS_CATEGORY.CustomPeriodWeekly ? STATS_CATEGORY.CustomPeriod : weeklyStatActive;
    const shouldUpdate = storedCategory.hasChangedEmployees() && storedCategory.loaded;

    if (statToFetch === STATS_CATEGORY.AssignedDuration && (weeklyModeActive === STATS_MODE.Weekly || weeklyModeActive === STATS_MODE.Schedule)) {
      return;
    }

    if (storedCategory.loaded && !shouldUpdate) {
      this.rostrStatisticsState.loadWeeklyCategory();
      return;
    }

    this.rostrStatisticsState.weeklyStatistics.loading = true;

    const params: IFetchStatisticsParameters = {
      categories: [statToFetch]
    };

    if (shouldUpdate) {
      params.affectedEmployeesIds = storedCategory.getChangedEmployees();
    }

    if (statToFetch === STATS_CATEGORY.CustomPeriod) {
      params.dateFrom = getStartOfDayISOString(this.rostrStatisticsState.weeklyStatistics.startDate);
      params.dateTo = getStartOfDayISOString(this.rostrStatisticsState.weeklyStatistics.endDate);
    }

    this.rostrStatisticsApi
      .fetchStatisticForSchedule(this.scheduleId, params)
      .pipe(take(1))
      .subscribe((response: IRawCategories) => {
        this.rostrStatisticsState.setCategoryData(weeklyStatActive, response[statToFetch], params.affectedEmployeesIds);
        storedCategory.loaded = true;
        storedCategory.clearEmployeeChanged();
        this.rostrStatisticsState.weeklyStatistics.loading = false;
        if (updateOnly) {
          this.rostrStatisticsState.loadWeeklyCategory(params.affectedEmployeesIds);
        } else {
          this.rostrStatisticsState.loadWeeklyCategory();
        }
      });
  }

  public loadMainStatistics(updateOnly = false): void {
    const mainStatActive = this.rostrStatisticsState.mainStatistics.category;
    const mainModeActive = this.rostrStatisticsState.mainStatistics.mode;
    const storedCategory = this.rostrStatisticsState.getStoredCategory(mainStatActive);

    const statToFetch = mainStatActive === STATS_CATEGORY.CustomPeriodMain ? STATS_CATEGORY.CustomPeriod : mainStatActive;
    const shouldUpdate = storedCategory.hasChangedEmployees() && storedCategory.loaded;

    if (statToFetch === STATS_CATEGORY.AssignedDuration && mainModeActive === STATS_MODE.Schedule) {
      return;
    }

    if (storedCategory.loaded && !shouldUpdate) {
      this.rostrStatisticsState.loadMainCategory();
      return;
    }

    this.rostrStatisticsState.mainStatistics.loading = true;

    const params: IFetchStatisticsParameters = {
      categories: [statToFetch]
    };

    if (shouldUpdate) {
      params.affectedEmployeesIds = storedCategory.getChangedEmployees();
    }

    if (statToFetch === STATS_CATEGORY.CustomPeriod) {
      params.dateFrom = getStartOfDayISOString(this.rostrStatisticsState.mainStatistics.startDate);
      params.dateTo = getStartOfDayISOString(this.rostrStatisticsState.mainStatistics.endDate);
    }

    this.rostrStatisticsApi
      .fetchStatisticForSchedule(this.scheduleId, params)
      .pipe(take(1))
      .subscribe((response: IRawCategories) => {
        this.rostrStatisticsState.setCategoryData(mainStatActive, response[statToFetch], params.affectedEmployeesIds);
        storedCategory.loaded = true;
        storedCategory.clearEmployeeChanged();
        this.rostrStatisticsState.mainStatistics.loading = false;
        if (updateOnly) {
          this.rostrStatisticsState.loadMainCategory(params.affectedEmployeesIds);
        } else {
          this.rostrStatisticsState.loadMainCategory();
        }
      });
  }

  public getWeeklyStat$ = (employeeId: number, weekNumber: number, year: number): Observable<string> =>
    combineLatest([
      this.rostrStatisticsState.getWeeklyStat$(employeeId, getKeyForIsoYearWeek(year, weekNumber)),
      this.avaiStatisticsState.getStatisticsByWeekAndEmployee(weekNumber, employeeId),
      this.avaiStatisticsState.getStatisticsByEmployee(employeeId),
      this.rostrStatisticsState.weeklyStatistics.mode$,
      this.rostrStatisticsState.weeklyStatistics.category$
    ]).pipe(
      map(([calculated, avaiWeek, avaiEmployee, mode, category]) => {
        if (avaiWeek && mode === STATS_MODE.Weekly && category === STATS_CATEGORY.AssignedDuration) {
          return minutesToHours(avaiWeek.values['time_worked']);
        } else if (avaiEmployee && mode === STATS_MODE.Schedule && category === STATS_CATEGORY.AssignedDuration) {
          return minutesToHours(avaiEmployee.values['total_work_time']);
        }
        return calculated;
      })
    );

  public getMainStat$ = (employeeId: number): Observable<string> =>
    combineLatest([
      this.rostrStatisticsState.getMainStat$(employeeId),
      this.avaiStatisticsState.getStatisticsByEmployee(employeeId),
      this.rostrStatisticsState.mainStatistics.mode$,
      this.rostrStatisticsState.mainStatistics.category$
    ]).pipe(
      filter(([calculated, avaiEmployee]) => !!calculated || !!avaiEmployee),
      map(([calculated, avaiEmployee, mode, category]) => {
        if (mode === STATS_MODE.Schedule && category === STATS_CATEGORY.AssignedDuration) {
          return minutesToHours(avaiEmployee.values['total_work_time']);
        }
        return calculated;
      })
    );

  public getEmployeeStats$(employeeId: number, category: STATS_CATEGORY): Observable<string> {
    return this.rostrStatisticsApi
      .fetchStatisticForSchedule(this.scheduleId, {
        categories: [category],
        affectedEmployeesIds: [employeeId]
      })
      .pipe(
        map((data: IRawCategories) => {
          const scheduleStats = data[category].bySchedule[employeeId][0];
          return minutesToHoursStatistic(scheduleStats);
        })
      );
  }

  public setMainStatisticMode(mode: STATS_MODE): void {
    this.rostrStatisticsState.mainStatistics.mode = mode;
    this.rostrStatisticsState.loadMainCategory();
    this.loadMainStatistics();
  }

  public setWeeklyStatisticMode(mode: STATS_MODE): void {
    this.rostrStatisticsState.weeklyStatistics.mode = mode;
    this.rostrStatisticsState.loadWeeklyCategory();
    this.loadWeeklyStatistics();
  }

  public setWeeklyCategory(category: STATS_CATEGORY): void {
    const prevCategory = this.rostrStatisticsState.weeklyStatistics.category;
    if (prevCategory === STATS_CATEGORY.CustomPeriodWeekly) {
      this.rostrStatisticsState.weeklyStatistics.mode = STATS_MODE.Weekly;
    }
    this.rostrStatisticsState.weeklyStatistics.category = category;
    this.loadWeeklyStatistics();
  }

  public setMainCategory(category: STATS_CATEGORY): void {
    const prevCategory = this.rostrStatisticsState.mainStatistics.category;
    if (prevCategory === STATS_CATEGORY.CustomPeriodMain) {
      this.rostrStatisticsState.mainStatistics.mode = STATS_MODE.Schedule;
    }
    this.rostrStatisticsState.mainStatistics.category = category;
    this.loadMainStatistics();
  }

  private reloadMainCustomPeriodIfSelected() {
    const mainCustomPeriod = this.rostrStatisticsState.getStoredCategory(STATS_CATEGORY.CustomPeriodMain);
    mainCustomPeriod.loaded = false;
    if (this.rostrStatisticsState.mainStatistics.category === STATS_CATEGORY.CustomPeriodMain) {
      this.loadMainStatistics();
    }
  }

  private reloadWeeklyCustomPeriodIfSelected() {
    const weeklyCustomPeriod = this.rostrStatisticsState.getStoredCategory(STATS_CATEGORY.CustomPeriodWeekly);
    weeklyCustomPeriod.loaded = false;
    if (this.rostrStatisticsState.weeklyStatistics.category === STATS_CATEGORY.CustomPeriodWeekly) {
      this.loadWeeklyStatistics();
    }
  }

  public setMainStartDate(value: Date): void {
    this.rostrStatisticsState.mainStatistics.startDate = value;
    this.reloadMainCustomPeriodIfSelected();
  }

  public setWeeklyStartDate(value: Date): void {
    this.rostrStatisticsState.weeklyStatistics.startDate = value;
    this.reloadWeeklyCustomPeriodIfSelected();
  }

  public setMainEndDate(value: Date): void {
    this.rostrStatisticsState.mainStatistics.endDate = value;
    this.reloadMainCustomPeriodIfSelected();
  }

  public setWeeklyEndDate(value: Date): void {
    this.rostrStatisticsState.weeklyStatistics.endDate = value;
    this.reloadWeeklyCustomPeriodIfSelected();
  }

  public setDefaultDates(startDate: string, endDate: string): void {
    this.rostrStatisticsState.weeklyStatistics.startDate = new Date(startDate);
    this.rostrStatisticsState.weeklyStatistics.endDate = new Date(endDate);
    this.rostrStatisticsState.mainStatistics.startDate = new Date(startDate);
    this.rostrStatisticsState.mainStatistics.endDate = new Date(endDate);
  }

  public setMainCustomPeriodMode(value: CUSTOM_PERIOD_MODE): void {
    const prevCategory = this.rostrStatisticsState.mainStatistics.category;
    this.rostrStatisticsState.mainStatistics.category = STATS_CATEGORY.CustomPeriodMain;
    this.rostrStatisticsState.mainStatistics.mode = value;
    if (prevCategory !== STATS_CATEGORY.CustomPeriodMain) {
      this.loadMainStatistics();
    } else {
      this.rostrStatisticsState.loadMainCategory();
    }
  }

  public setWeeklyCustomPeriodMode(value: CUSTOM_PERIOD_MODE): void {
    const prevCategory = this.rostrStatisticsState.weeklyStatistics.category;
    this.rostrStatisticsState.weeklyStatistics.category = STATS_CATEGORY.CustomPeriodWeekly;
    this.rostrStatisticsState.weeklyStatistics.mode = value;
    if (prevCategory !== STATS_CATEGORY.CustomPeriodWeekly) {
      this.loadWeeklyStatistics();
    } else {
      this.rostrStatisticsState.loadWeeklyCategory();
    }
  }

  public updateStatisticForEmployees(employeesIds: number[]): void {
    this.rostrStatisticsState.setEmployeesChanged(employeesIds);
    this.loadWeeklyStatistics(true);
    this.loadMainStatistics(true);
  }
}
