import { MODULES } from '@core';

/**
 * @description Used for router redirects.
 */
export const ROSTR = {
  OVERVIEW: `/${MODULES.ROSTR}/overview`,
  SCHEDULES: `/${MODULES.ROSTR}/schedules`
};

export const VACAY = {
  SCHEDULE: `/${MODULES.VACAY}/schedule`
};

export const EMPLOYEE = {
  OVERVIEW: `/${MODULES.PLATFORM}/employee/overview`,
  PROFILE: id => `/${MODULES.PLATFORM}/employee/${id || 1}/profile`,
  MEDICAL: `/${MODULES.PLATFORM}/employee/medical`,
  GROUP: `/${MODULES.PLATFORM}/group`
};

export const AGENDA = {
  OVERVIEW: `/${MODULES.AGENDA}/overview`,
  TIMELINE: `/${MODULES.AGENDA}/timeline`,
  PROJECTS: `/${MODULES.AGENDA}/projects`,
  PROJECTS_NEW: `/${MODULES.AGENDA}/projects/new`,
  OBJECTIVES: `/${MODULES.AGENDA}/objectives`,
  UNSPECIFIED: `/${MODULES.AGENDA}/unspecified`
};

export const ARGUS = {
  OVERVIEW: `/${MODULES.ARGUS}`,
  REPORT: `/${MODULES.ARGUS}/report/1/1/intro`
};

export const SETTINGS = {
  HOLIDAYS: `/${MODULES.SETTINGS}/holidays`,
  SECTORS: `/${MODULES.SETTINGS}/sectors`,
  ORGANIZATION: `/${MODULES.SETTINGS}/organization/companies/divisions`,
  GROUPS: `/${MODULES.SETTINGS}/groups`,
  SHIFTS: `/${MODULES.SETTINGS}/shifts`,
  SHIFT_ALIASES: `/${MODULES.SETTINGS}/shift-aliases`,
  MAINTENANCE: `/${MODULES.SETTINGS}/maintenance`,
  SKILLS: `/${MODULES.SETTINGS}/skills`,
  LICENSES: `/${MODULES.SETTINGS}/licenses`,
  ENDORSEMENTS: `/${MODULES.SETTINGS}/endorsements`
};

export const AUTH_API = {
  ME: `/api/me`,
  LOGIN: `/login`,
  ROLES: `/api/roles`,
  ROLES_FOR_USERS: `/api/roles/overview/user`
};

export const ERROR = {
  NOT_FOUND: `/404`
};

export const PLANNING = {
  MASTERPLAN_OVERVIEW: `/${MODULES.PLANNING}/masterplan`,
  MASTERPLAN_EDITOR: `/${MODULES.PLANNING}/masterplan/editor`,
  MASTERPLAN_PREVIEW: `/${MODULES.PLANNING}/masterplan/preview`,
  MASTERPLAN_LIST: `/${MODULES.PLANNING}/masterplan/list`,
  REQUIREMENTS_EDITOR: `/${MODULES.PLANNING}/requirements/editor`
};
