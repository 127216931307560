import { Component, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-custom-tooltip',
  templateUrl: './custom-tooltip.component.html',
  styleUrls: ['./custom-tooltip.component.scss']
})
export class CustomTooltipComponent {
  /* Use this to display simple text tooltip*/
  @Input() text: string;
  @Input() contentTemplate: TemplateRef<any>;

  /* Header will not appear if not provided */
  @Input() tooltipTitle: string = null;
  @Input() inputData: unknown = null;

  @Input() maxWidth: string = null;
}
