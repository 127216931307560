import { filter } from 'rxjs/operators';
import { Component, ElementRef, HostListener, OnInit, ViewChild, Input, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';

import { CommentModel } from '@models';
import { nonWhitespaceValue } from '@shared/validators';

import { CommentAction } from '../../interfaces';
import { CommentInputComponent } from '../comment-input.component';

@Component({
  selector: 'sh-comment-input-edit',
  templateUrl: '../comment-input.component.html',
  styleUrls: ['../comment-input.component.scss']
})
export class CommentInputEditComponent extends CommentInputComponent implements OnInit, OnDestroy {
  @Input() comment: CommentModel;
  @ViewChild('colorPicker') colorPicker: ElementRef;
  @ViewChild('contentInput', { static: true }) contentInput: ElementRef;

  constructor(fb: UntypedFormBuilder) {
    super(fb);
  }

  public ngOnInit() {
    this.commentForm = this.fb.group(
      {
        color: '',
        message: [this.comment.message, [Validators.required]]
      },
      {
        validator: nonWhitespaceValue('message')
      }
    );

    this.colorHandler();
  }

  public ngOnDestroy() {
    if (this.colorSubscription) {
      this.colorSubscription.unsubscribe();
    }
  }

  public colorHandler() {
    this.commentForm.patchValue(this.comment);

    this.colorSubscription = this.commentForm
      .get('color')
      .valueChanges.pipe(filter(val => this.comment.color !== val))
      .subscribe(val => {
        this.comment.color = val;
        this.colorChange.emit({ backgroundColor: val, borderColor: val });
        this.updateComment();
      });
  }

  @HostListener('document:click', ['$event.target'])
  public close(target: EventTarget) {
    if (this.colorsVisible && !this.colorPicker.nativeElement.contains(target)) {
      this.colorsVisible = false;
    }
  }

  public deleteComment() {
    this.callback({
      action: CommentAction.REMOVE,
      target: this.comment
    });

    this.commentRemoved.emit();
  }

  public updateComment() {
    this.comment.message = this.commentForm.get('message').value;
    this.callback({
      action: CommentAction.UPDATE,
      target: this.comment
    });

    this.editingFinished.emit();
  }

  public closeEditing() {
    this.callback({
      action: CommentAction.CANCEL,
      target: this.comment
    });
    this.commentForm.patchValue(this.comment);
    this.editingFinished.emit();
  }
}
