import * as d3 from 'd3';
import * as moment from 'moment-mini';
import { takeUntil } from 'rxjs/operators';

import { translate, xmlns, isoWeek } from '@helpers';

import { IBaseGrid } from '../grid/base-grid.interface';
import { IShift } from '../shifts/base.shift';
import { TimeHeader } from '../headers/time-header/time.header';
import { IBaseTimeline } from '../utils/interfaces';
import { IBaseMenu } from '../menu/base-menu.interface';

enum IntervalTypes {
  Base,
  Day,
  Hour,
  HalfHour,
  Week
}

export abstract class BaseInterval {

  static TYPES = IntervalTypes;

  protected $header: d3.Selection<SVGGElement, any, any, any>;
  protected $headerLine: d3.Selection<SVGLineElement, any, any, any>;
  protected $headerTitle: d3.Selection<SVGTextElement, any, any, any>;

  protected $background: d3.Selection<SVGGElement, any, any, any>;
  protected $backgroundRect: d3.Selection<SVGRectElement, any, any, any>;
  protected $backgroundLine: d3.Selection<SVGLineElement, any, any, any>;

  protected $shifts: d3.Selection<SVGGElement, any, any, any>;

  protected _shifts: IShift[] = [];
  protected _overlappedShifts: IShift[] = [];

  protected _dateTo: Date;
  protected _dateFromUnix: number;
  protected _dateToUnix: number;

  protected _weekNumber: number;
  protected _yearNumber: number;

  private _timeHeader: TimeHeader;

  constructor(
    protected _timeline: IBaseTimeline,
    protected _grid: IBaseGrid,
    protected _menu: IBaseMenu,
    protected _dateFrom: Date,
    protected _x: number,
    protected _params: any = {},
  ) {
    const shifts = document.createElementNS(xmlns, 'g');
    this.$shifts = d3.select(shifts);

    const header = document.createElementNS(xmlns, 'g');
    this.$header = d3.select(header).classed('interval-header', true);

    this.$headerLine = this.$header.append<SVGLineElement>('line');
    this.$headerTitle = this.$header.append<SVGTextElement>('text');

    const background = document.createElementNS(xmlns, 'g');
    this.$background = d3.select(background).classed('interval-background', true);

    this.$backgroundRect = this.$background.append<SVGRectElement>('rect');
    this.$backgroundLine = this.$background.append<SVGLineElement>('line');

    this.setDates();
    this.updateWeekYear();

    this.subscribe();
  }

  get weekNumber() {
    return this._weekNumber;
  }

  get yearNumber() {
    return this._yearNumber;
  }

  static get type() { return IntervalTypes.Base; }

  get leftSide() {
    const gridOffset = this._grid.offset();
    return this.x() + gridOffset;
  }

  get rightSide() {
    return this.leftSide + this.width;
  }

  get width() {
    const sizes = this.timeline.sizes();
    return sizes.intervalWidth;
  }

  get dateFromUnix() {
    return this._dateFromUnix;
  }

  get dateToUnix() {
    return this._dateToUnix;
  }

  abstract get title(): string;

  abstract getTitleForTab(dateFrom: Date, isActive?: boolean): string;

  get index(): number {
    return this._grid.intervals().indexOf(this);
  }

  get isFirst() {
    return this.index === 0;
  }

  get isLast() {
    return this.index === this._grid.intervals().length - 1;
  }

  get header() {
    return this.$header;
  }

  get headerNode() {
    return this.$header.node();
  }

  get background() {
    return this.$background;
  }

  get backgroundNode() {
    return this.$background.node();
  }

  get shiftsNode() {
    return this.$shifts.node();
  }

  get timeline() {
    return this._timeline;
  }

  set timeHeader(header: TimeHeader) {
    this._timeHeader = header;
  }

  get timeHeader() {
    return this._timeHeader;
  }

  /**
   * Rendering functionality
   */

  public renderHeader() {
    const height = this.timeHeader.height;
    const yPos = Math.round(height * 0.25);
    const width = this.width;

    this.$header
      .classed('no-events', true)
      .attr('transform', translate(this._x, 0));

    this.$headerTitle
      .text(this.title)
      .attr('x', width / 2)
      .attr('y', yPos + (height - yPos) / 2)
      .attr('dy', '0.5ex')
      .classed('text', true);

    this.$headerLine
      .attr('x1', width)
      .attr('x2', width)
      .attr('y1', yPos)
      .attr('y2', height)
      .classed('line line-interval', true);
  }

  public renderBackground() {
    this.$shifts.attr('transform', translate(this._x, 0));

    const width = this.width;
    const height = this._menu.height;

    this.$background
      .classed('no-events', true)
      .attr('transform', translate(this._x, 0));

    this.$backgroundRect
      .attr('height', height)
      .attr('width', width)
      .attr('fill', 'transparent');

    this.$backgroundLine
      .attr('x1', width)
      .attr('x2', width)
      .attr('y1', 0)
      .attr('y2', height)
      .attr('class', 'line line-interval');
  }

  public remove() {
    this.$header.remove();
    this.$background.remove();
    this.$shifts.remove();
  }

  /**
   * Subscribe to event emitters
   */
  public subscribe() {
    this._timeline.onResize
      .pipe(takeUntil(this._timeline.onDestroy))
      .subscribe(() => this.renderBackground());
  }

  /******************************** $el functions *****************************************/
  /**
   * When interval moves to next or prev date
   * @param date
   * @param x
   */
  public update(date: Date, x: number) {
    this.setDates(date);
    this.updateWeekYear();

    this._x = x;

    if (this.timeHeader) {
      this.renderHeader();
    }

    this.renderBackground();
  }

  /**
   * Update week year
   */
  public updateWeekYear() {
    this._weekNumber = isoWeek(this._dateFrom);
    // When date is last 52 week of 2021 year, but actual date is 01 Jan 2022,
    // then old way using Date built-in object approach via this._dateFrom.getFullYear() gives a wrong year.
    this._yearNumber = moment(this._dateFrom).isoWeekYear();
  }

  /**
   * Move to some point regarding delta x
   */
  public move(dx: number): this {
    this._x = this._x + dx;

    if (this.timeHeader) {
      this.$header.attr('transform', translate(this._x, 0));
    }

    this.$background.attr('transform', translate(this._x, 0));
    this.$shifts.attr('transform', translate(this._x, 0));

    return this;
  }

  /**
   * Move to some point regarding x
   */
  public snap(x: number): this {
    this._x = x;

    if (this.timeHeader) {
      this.$header.attr('transform', translate(this._x, 0));
    }

    this.$background.attr('transform', translate(x, 0));
    this.$shifts.attr('transform', translate(x, 0));

    return this;
  }

  /**
   * Getter of x property
   * @param x
   * @returns {any}
   */
  public x(): number {
    return this._x;
  }

  /******************************* Dates ******************************************************/
  /**
   * Can receive date from and will set dateTo
   * @param {Date} dateFrom
   */
  abstract setDates(dateFrom?: Date): this;

  /**
   * Current date
   * @returns {Date}
   */
  public dateFrom(): Date {
    return this._dateFrom;
  }

  /**
   * Date to
   * @returns {Date}
   */
  public dateTo(): Date {
    return this._dateTo;
  }

  /**
   * Date for next interval
   * @returns {Date}
   */
  abstract next(): Date;

  /**
   * Date for prev interval
   * @returns {Date}
   */
  abstract prev(): Date;

  abstract getCountOfUsedIntervals(from: Date, to: Date): number;

  /**
   * Base date of interval
   * @returns {Date}
   */
  abstract base(offset?: number, count?: number): Date;

  /****************************** Shifts **********************************************************/
  /**
   * Getter and setter for shifts property
   * @param shifts
   * @returns {BaseInterval}
   */
  public shifts(shifts?: IShift[]): IShift[] | this {
    if (!arguments.length) {
      return this._shifts;
    }
    this._shifts.forEach((s: any) => s.detach());
    this._shifts = shifts;
    this._shifts.forEach((shift: IShift) => {
      shift.interval(this);
      this.shiftsNode.appendChild(shift.node());
    });

    if (shifts.length > 0) {
      this._grid.onIntervalRendered.next(this);
    }
    return this;
  }

  /**
   * Getter for related shifts property
   * @returns {BaseInterval}
   */
  public overlappedShifts(): IShift[] {
    return this._overlappedShifts;
  }

  /**
   * Setter for related shift
   * @param shift
   * @returns {BaseInterval}
   */
  public addOverlappedShift(shift) {
    if (this._overlappedShifts.indexOf(shift) === -1) {
      this._overlappedShifts.push(shift);
    }
  }

  /**
   * Add one shift
   * @param shift
   * @returns {BaseInterval}
   */
  public addShift(shift: IShift): this {
    if (this._shifts.indexOf(shift) === -1) {
      this._shifts.push(shift);
    }
    shift.interval(this);
    this.shiftsNode.appendChild(shift.node());
    return this;
  }

  /**
   * Remove shift
   * @param shift
   * @returns {BaseInterval}
   */
  public removeShift(shift: IShift): this {
    const shiftIndex = this._shifts.indexOf(shift);
    if (shiftIndex > -1) {
      this._shifts.splice(shiftIndex, 1);
      (shift as any).detach();
    }
    return this;
  }

  /****************************** Static functions **********************************************/

  /**
   * When zooming from date with hours we need to round date to 00:00:00 as example
   * the same can be happened with other intervals
   * @param date
   * @returns {Date}
   */
  static precision(date: Date): Date {
    console.error('Method precision of class BaseInterval must be overwritten');
    return new Date(date.getTime());
  }

  static diff(from, to) {
    return moment(from).diff(moment(to));
  }

}
