import { Component, ViewChild, EventEmitter, Output, Input, AfterViewInit, Inject } from '@angular/core';
import { CommentModel } from '@shared/models';
import { CommentsService } from '@component/comments-tooltip/services/comments.service';
import { CommentAction, ICommentsCallbackProtocol } from '@component/comments-tooltip/interfaces';
import { CommentListComponent } from '@component/comments-tooltip/comment-list/comment-list.component';
import { CommentInputCreateComponent } from '@component/comments-tooltip/comment-input/comment-input-create/comment-input-create.component';
import { DialogService } from '@shared/services/dialog/dialog.service';
import { Messages } from '@app/vacay/_shared';
import { ServerStatus, CommentTypes } from '@enums';
import { LoggingService } from '@shared/services/logging/logging.service';
import { getISODateOnlyStringFromLocalMidnight } from '@helpers';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'sh-comments',
  templateUrl: 'comments.component.html',
  styleUrls: ['comments.component.scss']
})
export class CommentsComponent implements AfterViewInit {
  @ViewChild('commentInputCreate') public commentInputCreateComponent: CommentInputCreateComponent;
  @ViewChild('commentList') public commentList: CommentListComponent;

  @Input() comments: CommentModel[];
  @Input() correspondingDay: Date;
  @Input() commentType: CommentTypes;
  @Input() correspondingEmployeeId: number;
  @Output() closeEvent = new EventEmitter();
  @Output() commentsChanged = new EventEmitter<boolean>();

  public showColorSelection = false;
  public showCommentInputCreate = false;
  public isInEditMode = false;

  constructor(
    protected readonly commentsService: CommentsService,
    private readonly dialogService: DialogService,
    private readonly logging: LoggingService,
    @Inject(DOCUMENT) private readonly document: Document
  ) {}

  ngAfterViewInit() {
    const commentsDiv = this.document.getElementById('comments-components');
    commentsDiv.scrollTop = commentsDiv.scrollHeight;
  }

  public selectCommentToEdit() {
    this.showCommentInputCreate = false;
    this.isInEditMode = true;
  }

  public addComment() {
    this.switchCommentInputVisibility();
  }

  public commentActionCallback = (response: ICommentsCallbackProtocol) => {
    const comment = response.target;
    this.isInEditMode = false;

    switch (response.action) {
      case CommentAction.CREATE:
        this.createComment(comment);
        this.switchCommentInputVisibility();
        break;

      case CommentAction.UPDATE:
        this.updateComment(comment);
        break;

      case CommentAction.REMOVE:
        this.openDeleteModal(comment);
        break;
    }
  };

  public close() {
    this.closeEvent.emit();
  }

  public switchCommentInputVisibility() {
    this.showCommentInputCreate = !this.showCommentInputCreate;
  }

  protected createComment(comment: CommentModel) {
    comment.correspondingDay = getISODateOnlyStringFromLocalMidnight(this.correspondingDay);
    comment.serverStatus = ServerStatus.SAVING;
    comment.typeId = this.commentType;
    comment.correspondingEmployeeId = this.correspondingEmployeeId;

    this.commentsService.createComment(comment).subscribe(
      (response) => {
        this.comments.push(response);
        this.commentsChanged.emit(true);
      },
      () => {
        this.dialogService.alert('', Messages.commentNotSaved, 'error-modal');
      }
    );
  }

  protected updateComment(comment: CommentModel) {
    comment.serverStatus = ServerStatus.UPDATING;

    this.commentsService.updateComment(comment).subscribe(
      () => (comment.serverStatus = ServerStatus.OK),
      () => {
        this.dialogService.alert('', Messages.commentNotSaved, 'error-modal');
        comment.serverStatus = ServerStatus.UPDATE_ERROR;
      }
    );
  }

  protected removeComment(comment: CommentModel): void {
    comment.serverStatus = ServerStatus.DELETING;
    this.commentsService.deleteComment(comment).subscribe(
      () => {
        const commentIndex = this.comments.findIndex((c) => c.id === comment.id);
        this.comments.splice(commentIndex, 1);
        this.commentsChanged.emit(this.comments.length > 0);
      },
      () => {
        comment.serverStatus = ServerStatus.DELETE_ERROR;
        this.dialogService.alert('', Messages.commentNotSaved, 'error-modal');
      }
    );
  }

  private openDeleteModal(comment: CommentModel): void {
    this.dialogService
      .confirm(
        `Confirm deletion of comment from: ${comment.createdBy.firstName} ${comment.createdBy.lastName}`,
        `${comment.message.substring(0, 50)}...`
      )
      .then((result) => {
        if (result) {
          this.removeComment(comment);
        }
      })
      .catch((error) => this.logging.error(error));
  }
}
