import { Apollo } from 'apollo-angular';
import { ApolloQueryResult } from '@apollo/client/core';
import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import * as moment from 'moment-mini';

import { HolidayModel } from '@models';
import { loadHolidaysQuery, holidaysByYear, bulkHolidayOperation } from './holidays.queries';
import { Observable } from 'rxjs';
import { DateRangeMoment } from '@interfaces';

@Injectable({
  providedIn: 'root'
})
export class HolidaysService {
  public data: HolidayModel[] = [];
  public holidaysCache = {};

  constructor(private apollo: Apollo) {}

  public fetchData(): Observable<HolidayModel[]> {
    return this.apollo.query<any>({ query: loadHolidaysQuery }).pipe(map(response => response.data.holidays.map(holiday => new HolidayModel(holiday))));
  }

  public loadHolidaysByYear(year: number) {
    return this.apollo
      .query<any>({
        query: holidaysByYear,
        variables: {
          year: year.toString()
        }
      })
      .pipe(
        tap(response => {
          this.data = response.data.holidaysByYear.map(element => new HolidayModel(element));
          if (!this.holidaysCache[year]) {
            this.holidaysCache[year] = this.data.filter(holiday => holiday.active === true);
          }
        }),
        map(() => this.data)
      );
  }

  public fullUpdate(data: { created: HolidayModel[]; updated: HolidayModel[]; deleted: HolidayModel[] }) {
    return this.apollo
      .mutate({
        mutation: bulkHolidayOperation,
        variables: {
          created: data.created.map(el => {
            return {
              name: el.name,
              type: el.type,
              active: el.active,
              everyYear: el.everyYear,
              date: el.date || moment()
            };
          }),
          updated: data.updated.map(el => {
            return {
              id: el.id,
              name: el.name,
              type: el.type,
              active: el.active,
              everyYear: el.everyYear,
              date: el.date || moment()
            };
          }),
          deleted: data.deleted.map(el => el.id)
        }
      })
      .pipe(
        map((response: ApolloQueryResult<any>) => response.data.bulkHolidayOperation),
        tap(bulkHolidayOperationResult => {
          const alreadyStoredItems = this.data.filter(v => v.id !== null);
          const createdItems = bulkHolidayOperationResult.created.map(el => new HolidayModel(el));

          this.data = [...alreadyStoredItems, ...createdItems];
        })
      );
  }

  public static holidaysToDates(holidays: HolidayModel[], inYears: number[]): moment.Moment[] {
    const singleHolidays = holidays
      .filter(holiday => holiday.active === true && holiday.everyYear === false && inYears.findIndex(n => n === holiday.date.getUTCFullYear()) !== -1)
      .map(h => moment.utc(h.date));
    const recurringHolidays = holidays.filter(holiday => holiday.active === true && holiday.everyYear === true).map(h => moment.utc(h.date));
    const recurringHolidaysExpanded = recurringHolidays.flatMap(date => inYears.map(year => moment(date).year(year)));

    return [...singleHolidays, ...recurringHolidaysExpanded];
  }

  public static holidaysToDatesByDateRange(holidays: HolidayModel[], dateRangeMoment: DateRangeMoment): moment.Moment[] {
    const singleHolidays = holidays
      .filter(holiday => holiday.active === true && holiday.everyYear === false)
      .map(h => moment.utc(h.date))
      .filter(h => h.isBetween(dateRangeMoment.dateFrom, dateRangeMoment.dateTo, 'day'));
    const recurringHolidays = holidays.filter(holiday => holiday.active === true && holiday.everyYear === true).map(h => moment.utc(h.date));

    const firstYear = dateRangeMoment.dateFrom.year();
    const lastYear = dateRangeMoment.dateTo.year();
    const years: number[] = [];
    for (let year = firstYear; year <= lastYear; year++) {
      years.push(year);
    }
    const recurringHolidaysExpanded = recurringHolidays.flatMap(date => years.map(year => moment(date).year(year)));

    return [...singleHolidays, ...recurringHolidaysExpanded];
  }
}
