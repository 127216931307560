import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-form-input',
  templateUrl: './form-input.component.html'
})
export class FormInputComponent {
  // Value for <app-form-input> is really `unknown` or `any` so UntypedFormControl is reasonable to use it here.
  // Although values are in most cases sting | number | Date but we still can't use code like FormControl<string | number | Date>
  @Input() public control: UntypedFormControl;

  @Input() public disabled: boolean;

  @Input() public isBordered = true;

  public get isInvalid(): boolean {
    return this.control.touched && this.control.invalid;
  }
}
