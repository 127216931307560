import { range } from 'lodash-es';
import * as moment from 'moment-mini';
import { Component, ElementRef, HostBinding, Input, forwardRef } from '@angular/core';
import { AbstractControlDirective, ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { RADIX_DEC } from '@constants';
import { MINUTES_PER_DAY, MINUTES_PER_HOUR } from '@helpers';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimePickerComponent),
      multi: true
    },
    { provide: MatFormFieldControl, useExisting: TimePickerComponent }
  ]
})
export class TimePickerComponent implements ControlValueAccessor, MatFormFieldControl<number> {
  static nextId = 0;

  stateChanges: Subject<void> = new Subject<void>();
  @HostBinding() id = `time-picker-${TimePickerComponent.nextId++}`;
  @Input() placeholder: string;
  ngControl: NgControl | AbstractControlDirective;
  focused: boolean;
  empty: boolean;
  shouldLabelFloat = true;
  errorState: boolean;
  controlType?: string;
  autofilled?: boolean;
  @Input('aria-describedby') userAriaDescribedBy: string;
  @Input() class: string;
  @Input() ariaLabel: string;
  @Input() required: boolean;
  @Input() value = 0;
  @Input() disabled: boolean;
  public onChangeRegisteredFunctions: ((value: number) => void)[] = [];
  public onTouchedRegisteredFunctions: (() => void)[] = [];
  public isDisabled: boolean;

  public hours = range(0, 24);
  public minutes = range(0, 60, 5);

  public hourValue = 0;
  public minuteValue = 0;

  constructor(private _elementRef: ElementRef) {}

  public onTouched() {
    this.onTouchedRegisteredFunctions.forEach(f => f());
  }

  writeValue(value: number): void {
    const sanitizedValue = value % MINUTES_PER_DAY;

    if (sanitizedValue > 0) {
      this.updateValue(sanitizedValue, false);
      this.hourValue = Math.floor(sanitizedValue / MINUTES_PER_HOUR);
      this.minuteValue = sanitizedValue % MINUTES_PER_HOUR;
    }
    this.stateChanges.next();
  }

  registerOnChange(fn: (value: number) => void): void {
    this.onChangeRegisteredFunctions.push(fn);
  }

  registerOnTouched(fn: () => void): void {
    this.onTouchedRegisteredFunctions.push(fn);
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  onHoursChange(event: MatSelectChange) {
    this.hourValue = Number.parseInt(event.value, RADIX_DEC);
    this.updateValue(this.hourValue * 60 + this.minuteValue);
  }

  onMinutesChange(event: MatSelectChange) {
    this.minuteValue = Number.parseInt(event.value, RADIX_DEC);
    this.updateValue(this.hourValue * 60 + this.minuteValue);
  }

  private updateValue(minutes: number, notifyRegisteredListeners = true) {
    this.value = minutes;
    const duration = moment.duration(minutes, 'minutes');
    this.minuteValue = duration.minutes();
    this.hourValue = duration.hours();

    this.onChangeRegisteredFunctions.forEach(f => f(this.value));

    if (notifyRegisteredListeners) {
      this.onChangeRegisteredFunctions.forEach(f => f(this.value));
    }
  }

  setDescribedByIds(ids: string[]): void {
    const controlElement = this._elementRef.nativeElement.querySelector('.time-picker-input')!;
    controlElement.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick(event: MouseEvent): void {}
}
