import { ShiftType } from '../enums/shift-type';

export const shiftTypeMapByEnum = new Map<ShiftType, string>([
  [ShiftType.Required, 'required'],
  [ShiftType.Master, 'master'],
  [ShiftType.Vacay, 'vacay'],
  [ShiftType.Agenda, 'agenda'],
  [ShiftType.Manual, 'manualShift'],
  [ShiftType.VacayAvailable, 'vacayAvailable']
]);

export const shiftTypeMapByName = new Map<string, ShiftType>([
  ['required', ShiftType.Required],
  ['master', ShiftType.Master],
  ['vacay', ShiftType.Vacay],
  ['agenda', ShiftType.Agenda],
  ['manualShift', ShiftType.Manual],
  ['vacayAvailable', ShiftType.VacayAvailable]
]);

export function parseShiftType(input: ShiftType): string {
  return shiftTypeMapByEnum.has(input) ? shiftTypeMapByEnum.get(input) : 'unknown';
}

export function parseStringShiftType(input: string): ShiftType {
  return shiftTypeMapByName.has(input) ? shiftTypeMapByName.get(input) : ShiftType.Unknown;
}
