import * as d3 from 'd3';
import { EventEmitter } from '@angular/core';
import { takeUntil, debounceTime } from 'rxjs/operators';

import { difference as _difference } from 'lodash-es';

import { IShift } from '../shifts/base.shift';
import { Timeline } from '../timeline';
import { Menu } from '../menu/menu';
import { Defs } from '../defs/defs';
import { Grid } from '../grid/grid';
import { IItemConfig } from './interfaces';
import { ShiftsBasedItem } from './shifts-based.item';
import { IBaseGrid } from '../grid/base-grid.interface';

export class ResponsiveItem extends ShiftsBasedItem {

  // events
  public onChangeHeight: EventEmitter<any> = new EventEmitter();

  protected _visible: IShift[] = [];
  protected _joins: IShift[][] = [];
  // protected _height: number;

  protected $backgroundMask: d3.Selection<any, any, any, any>;
  protected $gridBackgroundMask: d3.Selection<any, any, any, any>;

  constructor(protected _title = '',
    protected _shifts: IShift[] = [],
    protected _itemConfig: IItemConfig = { height: 40 }) {
    super(_title, _shifts, _itemConfig);
    this.shifts(_shifts);
    this.$backgroundMask = this.$el.insert('rect', 'line');
  }

  public render() {
    super.render();

    return this.el;
  }

  public addSubItem(item) {
    item.parent = this;
    item.timeline(this._timeline);
    item.menu(this._menu);
    item.defs(this._defs);
    item.grid(this._grid);
    item.level = this.level + 1;

    item.subscribe();
    this._shifts.push(item);
    const visible = this._menu.visible;
    visible.push(item);
    this._menu.node.appendChild(item.render());
    item.renderShifts();
  }


  /**
   * Calculate item height
   * @returns {number}
   */
  public totalHeight() {
    return this._height;
  }

  /**
   * Subscribe to event emitters
   */
  public subscribe() {
    this.onChangeHeight.pipe(takeUntil(this._timeline.onDestroy)).subscribe((height) => {
      this.$background.attr('height', height);
      this.$backgroundMask.attr('height', height);
      this.$gridBackground.attr('height', height);
      this.$gridBackgroundMask.attr('height', height);
    });
    this._shifts.forEach((shift: IShift) => {
      shift.subscribe();
    });
    // this._grid.onDragEnd.subscribe(() => {
    //   this.reRender();
    // });
    // this._grid.onPrevInterval.subscribe(() => {
    //   this.reRender();
    // });
    // this._grid.onNextInterval.subscribe(() => {
    //   this.reRender();
    // });
    this._grid.onIntervalRendered.pipe(debounceTime(300)).pipe(takeUntil(this._timeline.onDestroy)).subscribe(() => {
      this.reRender();
    });
    // this._grid.onRenderIntervals.subscribe(() => {
    //   this.reRender();
    // });
    this._grid.onManualRender.pipe(takeUntil(this._timeline.onDestroy)).subscribe(() => {
      this.sortAssignedShifts();
      this.reRender();
    });
  }

  /**
   *
   * @param shift
   * @returns {any}
   */
  public join(shift: IShift): IShift[] | boolean {
    const joins = this._joins;
    for (let i = 0; i < joins.length; i++) {
      const j = joins[i];
      if (j.indexOf(shift) > -1) {
        return j;
      }
    }
    return false;
  }

  public itemHeight() {
    return this.config.height * this._joins.length;
  }


  /**
   * Getter and setter for shifts property
   * @param shifts
   * @returns {any}
   */
  public shifts(shifts?: IShift[]): this | IShift[] {
    if (!arguments.length) {
      return this._shifts;
    }
    this._shifts = shifts;
    shifts.forEach((shift: IShift) => {
      shift.item(this);
    });
    return this;
  }

  public asyncAddShift(shift: IShift) {
    shift.item(this);
    shift.timeline(this._timeline);
    shift.defs(this._defs);
    shift.grid(this._grid);
    shift.menu(this._menu);
    shift.subscribe();

    this.addShift(shift);
    return this;
  }

  /**
   * Remove shift
   * @param shift
   * @returns {OpenableItem}
   */
  public removeShift(shift: IShift) {
    const index = this._shifts.indexOf(shift);
    if (index > -1) {
      this._shifts.splice(index, 1);
    }
    // this.lookup();
    return this;
  }

  /**
   * Y position for shift
   * @param shift
   */
  public shiftPosition(shift: IShift): number {
    const rh = this.config.height;
    const join = this.join(shift) as IShift[];
    const y = this.y();
    return (join) ? (this._joins.indexOf(join) * rh) + y : y;
  }

  /**
   * Get all currently visible shifts
   * @returns {IShift[]}
   */
  public visible() {
    return this._visible;
  }

  /****************************************** Some main functionality ***************************************/
  /**
   * Setter for grid property
   * @param grid
   * @returns {Baseitem}
   */
  public grid(grid: IBaseGrid): this {
    super.grid(grid);
    this._shifts.forEach((shift) => {
      shift.grid(grid);
    });
    this.lookup();
    return this;
  }

  /**
   * Setter for timeline property
   * @param timeline
   * @returns {Baseitem}
   */
  public timeline(timeline: Timeline): this {
    super.timeline(timeline);
    this._shifts.forEach((shift) => {
      shift.timeline(timeline);
    });
    return this;
  }

  /**
   * Menu setter
   * @param menu
   * @returns {Baseitem}
   */
  public menu(menu: Menu): this {
    super.menu(menu);
    this._shifts.forEach((shift) => {
      shift.menu(menu);
    });
    return this;
  }

  /**
   * Defs setter
   * @param defs
   * @returns {BaseItem}
   */
  public defs(defs: Defs): this {
    this._defs = defs;
    this._shifts.forEach((shift) => {
      shift.defs(defs);
    });
    return this;
  }

  /*******************************************************************/
  /**
   * Remove functionality
   */
  public remove() {
    let i = this._shifts.length - 1;
    while (i >= 0) {
      this._shifts[i].remove();
      i--;
    }
    super.remove();
  }

  public reRender() {
    // FIXME ATTENTION. Can work not stable.
    const prevShifts = this._joins.slice();
    this.lookup();
    const lines = [];

    for (let i = 0; i < this._joins.length; i++) {
      lines.push(_difference(this._joins[i], prevShifts[i]));
    }

    lines.forEach((line) => {
      line.forEach((shift) => {
        shift.attachShiftToInterval();
      });
    });
    // FIXME bottleneck
    /*this._shifts.forEach((shift) => {
      shift.render();
    });*/
  }

  public renderBackground() {
    super.renderBackground();

    this.$backgroundMask
      .attr('width', this._timeline.sizes().menuWidth)
      .attr('height', this.totalHeight())
      .attr('fill', this._defs.unassignedMask());
  }

  public renderGridBackground() {
    super.renderGridBackground();

    const sizes = this._timeline.sizes();
    const width = sizes.width;

    if (!this.$gridBackgroundMask) {
      this.$gridBackgroundMask = this.$gridBackgroundContainer
        .append('rect')
        .attr('fill', this._defs.unassignedMask());
    }

    this.$gridBackgroundMask
      .attr('width', width)
      .attr('height', this.totalHeight());

  }

  /*********************************** Shifts ********************************/
  /**
   * Set current visible tasks and overlap joins
   * @returns {ResponsiveItem}
   */
  protected lookup() {

    this._joins = [];

    const all = this._grid.visible();
    const shifts = this._shifts;
    const visible = shifts.filter((shift: IShift) => {
      return all.indexOf(shift) > -1;
    });

    this._joins = this.groupShifts(visible);

    this._visible = visible;
    //
    const height = this.config.height * this._joins.length;

    if (this._height !== height) {
      this._height = height;
      this.onChangeHeight.emit(height);
    }
    return this;
  }

  // FIXME should be moved (repeats in each item)
  protected sortAssignedShifts() {
    this._shifts.sort((a: IShift, b: IShift) => {
      return a.dateFrom().getTime() - b.dateFrom().getTime();
    });
  }

  private groupShifts(shifts) {
    /**
     * We are must iterate all shifts and try to find dates intersection
     */
    return shifts.reduce((acc, shift) => {
      let noConflicts = true; // by default we are think that we have not intersection
      let iter = 0;
      /**
       * Iterate shifts until we meet conflict
       */
      while (noConflicts && iter < acc.length) {
        const hasConflict = !!acc[iter].find((insertedShift) => {
          return insertedShift.overlap(shift);
        });

        /**
         * If we haven't conflicts then just insert shift at the last line
         * else
         * create new line
         */
        if (!hasConflict) {
          noConflicts = false;
          acc[iter].push(shift);
        } else if (iter === acc.length - 1) {
          noConflicts = false;
          acc.push([shift]);
        }
        iter++;
      }
      return acc;
    }, [[]]);
  }

}
