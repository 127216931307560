import { gql } from 'apollo-angular';

export const loadQuery = gql`
  query loadEmployeesWithLicenses {
    employeeLicenses {
      employee {
        id
        email
        firstName
        middleName
        lastName
        deletedAt
        group {
          unit {
            site {
              division {
                company {
                  medicalSetting {
                    interval
                  }
                }
              }
            }
          }
        }
      }
      obtained
      validFrom
      validTo
      license {
        name
      }
    }
  }
`;

export const bulkQuery = gql`
  mutation bulkEmployeeOperation( $deleted: [Int], $updated: [EmployeeInput] ) {
    bulkEmployeeOperation( deleted: $deleted, updated: $updated ) {
      updated {
        id
        email
        firstName
        middleName
        lastName
        deletedAt
      }

      deleted {
        id
      }
    }
  }
`;

export const sendEmailCommand = gql`
  mutation sendEmailCommand($employeeId: Int!) {
    sendEmailCommand(employee: $employeeId)
  }
`;
