import { Observable } from 'rxjs';
import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { ErrorService } from '@services';
import { catchError, map } from 'rxjs/operators';
import { revalidateMutation, scheduleValidations, scheduleValidationsUpdatedSubscription, toggleValidation } from '../queries/schedule-validations.queries';
import { ScheduleValidation } from '../../dto/schedule-validation';

@Injectable({ providedIn: 'root' })
export class ScheduleValidationsAPIService {
  constructor(
    private readonly apollo: Apollo,
    private readonly errorService: ErrorService
  ) {}

  public getScheduleValidations(scheduleId: number): Observable<ScheduleValidation[]> {
    return this.apollo
      .query<{ scheduleValidations: ScheduleValidation[] }>({
        query: scheduleValidations,
        variables: {
          scheduleId: scheduleId
        }
      })
      .pipe(
        map(response => {
          return response.data.scheduleValidations;
        }),
        catchError(err => {
          return this.errorService.handle(err);
        })
      );
  }

  public toggleValidation(checksum: string, scheduleId): Observable<void> {
    return this.apollo
      .mutate({
        mutation: toggleValidation,
        variables: {
          checksum: checksum,
          scheduleId: scheduleId
        }
      })
      .pipe(
        catchError(err => {
          return this.errorService.handle(err);
        })
      ) as Observable<void>;
  }

  public revalidate(scheduleId: number): Observable<void> {
    return this.apollo
      .mutate({
        mutation: revalidateMutation,
        variables: {
          scheduleId: scheduleId
        }
      })
      .pipe(
        catchError(err => {
          return this.errorService.handle(err);
        })
      ) as Observable<void>;
  }

  public onScheduleValidationsUpdated$(scheduleId: number): Observable<ScheduleValidation[]> {
    return this.apollo
      .subscribe({
        query: scheduleValidationsUpdatedSubscription,
        variables: {
          scheduleId
        }
      })
      .pipe(
        map(
          (response: { data: { scheduleValidationsUpdated: { validations: ScheduleValidation[] } } }) =>
            response.data.scheduleValidationsUpdated.validations.map(x => {
              return { ...x };
            }),
          catchError((err: Error) => {
            return this.errorService.handle(err);
          })
        )
      );
  }
}
