import { filter } from 'rxjs/operators';
import { Component, ElementRef, HostListener, OnInit, ViewChild, Input, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { CommentModel } from '@models';
import { noWhitespaceValidator } from '@shared/validators';

import { CommentAction, ICommentsCallbackProtocol } from '../../interfaces';
import { CommentInputComponent } from '../comment-input.component';

@Component({
  selector: 'sh-comment-input-create',
  templateUrl: '../comment-input.component.html',
  styleUrls: ['../comment-input.component.scss']
})
export class CommentInputCreateComponent extends CommentInputComponent implements OnInit, OnDestroy {
  @Input() callback: (response: ICommentsCallbackProtocol) => any;
  public comment: CommentModel = new CommentModel();

  @ViewChild('colorPicker') colorPicker: ElementRef;
  @ViewChild('contentInput', { static: true }) contentInput: ElementRef;

  constructor(fb: FormBuilder) {
    super(fb);
  }

  public ngOnInit() {
    this.commentForm = this.fb.group({
      color: [''],
      message: [this.comment.message, [Validators.required, noWhitespaceValidator]]
    });

    this.colorHandler();
    setTimeout(() => this.contentInput.nativeElement.focus(), 100);
  }

  public ngOnDestroy() {
    if (this.colorSubscription) {
      this.colorSubscription.unsubscribe();
    }
  }

  public colorHandler() {
    this.commentForm.patchValue(this.comment);

    this.colorSubscription = this.commentForm
      .get('color')
      .valueChanges.pipe(filter(val => this.comment.color !== val))
      .subscribe(val => {
        this.comment.color = val;
        this.colorChange.emit({ backgroundColor: val, borderColor: val });
        this.updateComment();
      });
  }

  @HostListener('document:click', ['$event.target'])
  public close(target: EventTarget) {
    if (this.colorsVisible && !this.colorPicker.nativeElement.contains(target)) {
      this.colorsVisible = false;
    }
  }
  public updateComment() {
    this.comment.message = this.commentForm.get('message').value;
    this.callback({
      action: CommentAction.CREATE,
      target: this.comment
    });
  }

  public deleteComment() {
    this.callback({
      action: CommentAction.REMOVE,
      target: this.comment
    });

    this.commentRemoved.emit();
  }

  public closeEditing() {
    this.commentForm.patchValue(this.comment);
    this.editingFinished.emit();
  }
}
