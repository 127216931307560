import { gql } from 'apollo-angular';

export const loadSite = gql`
  query loadSite($id: Int!) {
    site(id: $id) {
      id
      name
      divisionId
    }
  }
`;

export const loadSites = gql`
  query loadSites {
    sites {
      id
      name
      divisionId
    }
  }
`;

export const createSite = gql`
  mutation createSite($name: String!) {
    createSite(name: $name) {
      id
      name
    }
  }
`;

export const updateSite = gql`
  mutation updateSite(
    $id: Int!,
    $name: String!
  ) {
    updateSite(
      id: $id,
      name: $name
    ) {
      id
      name
      divisionId
    }
  }
`;

export const deleteSite = gql`
  mutation deleteSite($id: Int!) {
    deleteSite(id: $id) {
      id
    }
  }
`;

export const createSiteDivisionRelation = gql`
  mutation createSiteDivisionRelation(
    $siteId: Int!,
    $divisionId: Int!
  ) {
    createSiteDivisionRelation(
      siteId: $siteId,
      divisionId: $divisionId
    )
  }
`;

export const deleteSiteDivisionRelation = gql`
  mutation deleteSiteDivisionRelation($siteId: Int!) {
    deleteSiteDivisionRelation(siteId: $siteId)
  }
`;
