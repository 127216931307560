import * as moment from 'moment-mini';

export class ProfileDataModel {

  public birthday: moment.Moment;
  public profilePicture: string;
  public presentation: string;

  public personalEmail: string;
  public mobilePhone: string;
  public workPhone: string;

  constructor(data: any = {}) {
    const profilePicture = 'https://dailyscandinavian.com/wp-content/uploads/2015/05/270315_Trolls-Tongue-Norway.jpg';

    this.birthday = data && data.birthday || void 0;
    this.profilePicture = profilePicture || void 0;
    this.presentation = data && data.presentation || void 0;
    this.personalEmail = data && data.personalEmail || void 0;
    this.mobilePhone = data && data.mobilePhone || void 0;
    this.workPhone = data && data.workPhone || void 0;
  }

}
