import { gql } from 'apollo-angular';

export const loadCompany = gql`
  query loadCompany($id: Int!) {
    company(id: $id) {
      id
      name
      nameShort
      medicalSetting {
        id
        interval
        emails
      }
    }
  }
`;

export const loadCompanies = gql`
  query loadCompanies {
    companies {
      id
      name
      nameShort
    }
  }
`;
export const createMedicalSetting = gql`
  mutation createMedicalSetting(
    $interval: Int!
    $emails: String!
    $company: Int!
  ) {
    createMedicalSetting(
      interval: $interval
      emails: $emails
      company: $company
    ) {
      id
      interval
      emails
      company {
        id
        name
      }
    }
  }
`;

export const updateMedicalSetting = gql`
  mutation updateMedicalSetting(
    $id: Int!
    $interval: Int!
    $emails: String!
    $company: Int!
  ) {
    updateMedicalSetting(
      id: $id
      interval: $interval
      emails: $emails
      company: $company
    ) {
      id
      interval
      emails
      company {
        id
        name
      }
    }
  }
`;

export const createCompany = gql`
  mutation createCompany(
    $name: String!,
    $nameShort: String
  ) {
    createCompany(
      name: $name,
      nameShort: $nameShort
    ) {
      id
      name
      nameShort
    }
  }
`;

export const updateCompany = gql`
  mutation updateCompany(
    $id: Int!,
    $name: String!,
    $nameShort: String
  ) {
    updateCompany(
      id: $id,
      name: $name,
      nameShort: $nameShort
    ) {
      id
      name
      nameShort
    }
  }
`;

export const deleteCompany = gql`
  mutation deleteCompany($id: Int!) {
    deleteCompany(id: $id) {
      id
    }
  }
`;
