import { HttpHeaders } from '@angular/common/http';
import { ApolloClientOptions, split, from, DocumentNode } from '@apollo/client/core';
import { onError } from '@apollo/client/link/error';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { HttpLink } from 'apollo-angular/http';
import extractFiles from 'extract-files/extractFiles.mjs';
import isExtractableFile from 'extract-files/isExtractableFile.mjs';

import { inMemoryCache } from '@core';
import { ConfigService } from '../config.service';
import { Definition } from './definition.interface';
import { OPERATION_KIND_DEFINITION, SUBSCRIPTION_OPERATION_NAME, PERMISSIONS_ERROR } from './apollo-consts.const';

// https://www.apollographql.com/docs/apollo-server/security/cors/#preventing-cross-site-request-forgery-csrf
// If we use a GraphQL client that performs GET requests without sending
// `Content-Type`, `X-Apollo-Operation-Name` or `Apollo-Require-Preflight` headers, but it does send a `Some-Special-Header` header,
// we can pass csrfPrevention: { requestHeaders: ['Some-Special-Header'] } to new ApolloServer().
// This option replaces checking for the two headers `X-Apollo-Operation-Name` and `Apollo-Require-Preflight` in the CSRF prevention logic.
// The check for `Content-Type` remains the same.

// https://www.apollographql.com/docs/apollo-server/security/cors/#graphql-upload
const customApolloHeaders = new HttpHeaders({
  'x-apollo-operation-name': 'true'
});

export const init = (httpLink: HttpLink, configService: ConfigService): ApolloClientOptions<any> => {
  const httpLinkInstance = httpLink.create({
    uri: configService.config.graphqlUrl,
    extractFiles: body => extractFiles(body, isExtractableFile),
    headers: customApolloHeaders
  });

  const graphQlWsClient = createClient({
    url: configService.config.graphqlWebsocketUrl,
    shouldRetry: () => true,
    retryAttempts: configService.config.subscriptionAttempts
    // `keepAlive` is 0 by default (Ping/Pong then is disabled) and when we use `configService.config.subscriptionTimeout` value then Ping/Pong is enabled.

    // https://www.apollographql.com/docs/apollo-server/data/subscriptions/#example-authentication-with-apollo-client
    // https://the-guild.dev/graphql/ws/recipes#client-usage-with-apollo-client-web
    // We can use `connectionParams` here, to pass `authorization` into `message.payload` but for now looks like we don't need that.
    // But we may need it if we use `LOCAL_ONLY_AUTH_PROXY_BYPASS=true` in Backend which we didn't have properly implemented in past anyway.
    // Note. `connectionParams` will be accessible ONLY in GraphQL WebSocket Server `.onConenct()` hook (within Apollo Server v4 setup).
  });

  const wsLinkInstance = new GraphQLWsLink(graphQlWsClient);

  const isSubscriptionOperation = (query: DocumentNode) => {
    const { kind, operation }: Definition = getMainDefinition(query);
    return kind === OPERATION_KIND_DEFINITION && operation === SUBSCRIPTION_OPERATION_NAME;
  };

  const link = split(
    // split based on operation type
    ({ query }) => isSubscriptionOperation(query),
    wsLinkInstance,
    httpLinkInstance
  );

  const error = onError(({ graphQLErrors }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message }) => {
        if (message === PERMISSIONS_ERROR) {
          console.error(message);
        }
      });
    }
  });

  return {
    connectToDevTools: false,
    link: from([error, link]),
    cache: inMemoryCache,
    defaultOptions: {
      query: {
        fetchPolicy: 'no-cache'
      }
    }
  };
};
