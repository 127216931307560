import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { ScheduleStatistic, StatisticName } from '@app/rostr/overview/dto/schedule-statistic';
import { ChartColor, colors } from '@shared/helpers/charts.helper';
import { ChartsService } from '@shared/services/charts/charts.service';
import Chart from 'chart.js/auto';

@Component({
  selector: 'app-week-charts',
  templateUrl: './week-charts.component.html',
  styleUrls: ['./week-charts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WeekChartsComponent implements OnInit, OnChanges, OnDestroy {
  workedChart: Chart;

  @Input() statistics: ScheduleStatistic;

  constructor(private readonly chartsService: ChartsService) {}

  public ngOnInit(): void {
    this.workedChart = this.chartsService.createBarChart('workedChartCanvas', 'Worked hours', 'h');
    this.plotWorkedHoursChart(this.statistics);
  }

  public ngOnChanges(): void {
    if (this.workedChart) {
      this.plotWorkedHoursChart(this.statistics);
    }
  }

  public ngOnDestroy() {
    this.workedChart?.destroy();
  }

  private plotWorkedHoursChart(statistics: ScheduleStatistic) {
    const actualValue = statistics?.values[StatisticName.TimeWorked] || 0;
    const max = statistics?.values[StatisticName.MaxTimeWorked] || 0;

    const chartColor: ChartColor = actualValue > max ? 'warn' : 'default';
    const color = colors.get(chartColor);

    this.chartsService.plotBarChart(this.workedChart, actualValue, 0, max, color);
  }
}
