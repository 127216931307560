import { CommentType } from './comment-type';

export class CreateCommentPayload {
  public typeId: number;

  constructor(
    public correspondingDay: string,
    public correspondingEmployeeId: number,
    public message: string,
    public scheduleId: number,
    type: CommentType
  ) {
    this.typeId = type;
  }
}
