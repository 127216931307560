import * as moment from 'moment-mini';
import { Multiplier } from './multiplier';

export function datesComparer(firstValue: Date, secondValue: Date, isAsc: boolean): number {
  const timeComparisonResult = Math.sign(firstValue.getTime() - secondValue.getTime());
  return timeComparisonResult * directionMultiplier(isAsc);
}

export function sortByDate(a: string, b: string, isAsc = true): Multiplier {
  const ascendingComparison = moment(a).isBefore(moment(b)) ? -1 : 1;
  const descendingComparison = moment(b).isBefore(moment(a)) ? -1 : 1;
  return isAsc ? ascendingComparison : descendingComparison;
}

export function norwegianTextComparer(firstValue: string, secondValue: string, isAsc: boolean): number {
  if (!firstValue || !secondValue) {
    return 0;
  }

  const textComparisonResult = firstValue.localeCompare(secondValue.valueOf(), 'nb');
  return textComparisonResult * directionMultiplier(isAsc);
}

function directionMultiplier(isAsc: boolean): Multiplier {
  return isAsc ? 1 : -1;
}

export function sortByNumbers(a: number, b: number, isAsc: boolean): number {
  return isAsc ? a - b : b - a;
}
