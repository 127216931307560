<div>
  <div class="sidebar-title">My constellations</div>
  <div *ngFor="let constellation of constellations" class="row-wrapper">
    <div class="constellation">
      <div class="constellation-name" [title]="constellation.name">
        {{ constellation.name }}
      </div>
      <div class="constellation-container">
        <div *ngFor="let shiftCode of constellation.shiftCodes" [class]="'shift-tile shift ' + shiftCode">{{ shiftCode }}</div>
      </div>
    </div>

    <div class="buttons-wrapper">
      <button mat-icon-button color="warn" (click)="deleteConstellation(constellation)"><mat-icon>delete_forever</mat-icon></button>
    </div>
  </div>
  <div class="empty-label" *ngIf="constellations.length === 0">There is no any constellation yet</div>
</div>
