import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError, share, tap } from 'rxjs/operators';
import { ManualShiftModel, ShiftCodeType } from '@models';
import {
  createManualShift,
  createShiftsConstellation,
  deleteManualShift,
  loadGlobalShifts,
  deleteShiftsConstellations,
  loadManualShift,
  loadManualShifts,
  loadManualShiftsByCode,
  loadManualShiftsWithNameAndTypeOnly,
  shiftCodeColorsQuery,
  shiftCodeTypesQuery,
  shiftsConstellationsQuery,
  updateManualShift,
  loadManualShiftsForGroupWithNameAndTypeOnly
} from './shift.queries';
import { ErrorService } from '../error/error.service';
import { IStartDurationShift } from '@app/settings/shifts/_shared/start-duration-shift.interface';
import { ComplexShift } from '@app/settings/shifts/_shared/complex-shift.model';
import { ShiftsConstellationModel } from '@model/companies/shifts-constellation.model';
import { ShiftsConstellationCreateData } from '@shared/interfaces/shifts-constellation.interface';

@Injectable({
  providedIn: 'root'
})
export class ShiftService {
  constructor(
    private readonly apollo: Apollo,
    private readonly errorService: ErrorService
  ) {}

  public getAllCodeTypes(): Observable<ShiftCodeType[]> {
    return this.apollo
      .query<any>({
        query: shiftCodeTypesQuery
      })
      .pipe(
        map(res => res.data.shiftCodeTypes),
        share(),
        catchError(err => {
          return this.errorService.handle(err);
        })
      );
  }

  public get(id: number): Observable<ManualShiftModel> {
    return this.apollo
      .query<any>({
        query: loadManualShift,
        variables: {
          id
        }
      })
      .pipe(
        map(result => {
          return new ManualShiftModel(result.data.manualShift);
        }),
        catchError(err => {
          return this.errorService.handle(err);
        })
      );
  }

  public fetch(): Observable<ManualShiftModel[]> {
    return this.apollo.query<any>({ query: loadManualShifts }).pipe(
      map(result => {
        return result.data.manualShifts.map(shift => new ManualShiftModel(shift));
      }),
      catchError(err => {
        return this.errorService.handle(err);
      })
    );
  }

  public fetchForGroup(groupId: number): Observable<ManualShiftModel[]> {
    return this.apollo.query<any>({ query: loadManualShifts, variables: { groupId } }).pipe(
      map(result => {
        return result.data.manualShifts.map(shift => new ManualShiftModel(shift));
      }),
      catchError(err => {
        return this.errorService.handle(err);
      })
    );
  }

  public fetchForGroupWithNameAndTypeOnly(groupId: number): Observable<ManualShiftModel[]> {
    return this.apollo.query<any>({ query: loadManualShiftsForGroupWithNameAndTypeOnly, variables: { groupId } }).pipe(
      map(result => {
        return result.data.manualShifts.map(shift => new ManualShiftModel(shift));
      }),
      catchError(err => {
        return this.errorService.handle(err);
      })
    );
  }

  public fetchWithNameAndTypeOnly(): Observable<ManualShiftModel[]> {
    return this.apollo.query<any>({ query: loadManualShiftsWithNameAndTypeOnly }).pipe(
      map(result => {
        return result.data.manualShifts.map(shift => new ManualShiftModel(shift));
      }),
      catchError(err => {
        return this.errorService.handle(err);
      })
    );
  }

  public fetchGlobalShifts(): Observable<ManualShiftModel[]> {
    return this.apollo.query<any>({ query: loadGlobalShifts }).pipe(
      map(result => {
        return result.data.globalShifts.map(shift => new ManualShiftModel(shift));
      }),
      catchError(err => {
        return this.errorService.handle(err);
      })
    );
  }

  public fetchByCode(code: string): Observable<ManualShiftModel[]> {
    return this.apollo.query<any>({ query: loadManualShiftsByCode, variables: { code } }).pipe(
      map(result => {
        return result.data.manualShifts.map(shift => new ManualShiftModel(shift));
      }),
      catchError(err => {
        return this.errorService.handle(err);
      })
    );
  }

  public update(data: ManualShiftModel | IStartDurationShift): Observable<ManualShiftModel> {
    return this.apollo
      .mutate<any>({
        mutation: updateManualShift,
        variables: {
          id: data.id,
          code: data.code,
          metacode: data.metacode,
          comment: data.comment,
          start: data.start,
          duration: data.duration,
          workingTimePercentage: data.workingTimePercentage,
          typeId: data.typeId,
          color: data.color,
          isQuicklyAccessible: data.isQuicklyAccessible,
          details: data.details,
          isComplex: data.isComplex,
          skills: data?.skills?.map(x => x.id) || [],
          licenses: data?.licenses?.map(x => x.id) || [],
          unitEndorsements: data?.unitEndorsements?.map(x => x.id) || []
        }
      })
      .pipe(
        map(result => {
          return new ManualShiftModel(result.data.manualShift);
        }),
        catchError(err => {
          return this.errorService.handle(err);
        })
      );
  }

  public updateComplex(data: ComplexShift): Observable<ManualShiftModel> {
    return this.apollo
      .mutate<any>({
        mutation: updateManualShift,
        variables: {
          id: data.id,
          code: data.code,
          metacode: data.metacode,
          comment: data.comment,
          start: data.start,
          duration: data.duration,
          workingTimePercentage: data.workingTimePercentage,
          typeId: data.typeId,
          details: data.details,
          color: data.color,
          isGlobal: true,
          isQuicklyAccessible: data.isQuicklyAccessible,
          isComplex: true
        }
      })
      .pipe(
        map(result => {
          return new ManualShiftModel(result.data.manualShift);
        }),
        catchError(err => {
          return this.errorService.handle(err);
        })
      );
  }

  public create(data: ManualShiftModel | IStartDurationShift): Observable<ManualShiftModel> {
    return this.apollo
      .mutate<any>({
        mutation: createManualShift,
        variables: {
          id: data.id,
          code: data.code,
          metacode: data.metacode,
          comment: data.comment,
          start: data.start,
          duration: data.duration,
          workingTimePercentage: data.workingTimePercentage,
          typeId: data.typeId,
          isComplex: data.isComplex,
          color: data.color,
          isQuicklyAccessible: data.isQuicklyAccessible,
          groupId: data?.groupId,
          skills: data?.skills?.map(x => x.id) || [],
          licenses: data?.licenses?.map(x => x.id) || [],
          unitEndorsements: data?.unitEndorsements?.map(x => x.id) || [],
          details: data.details
        }
      })
      .pipe(
        map(result => {
          return new ManualShiftModel(result.data.manualShift);
        }),
        catchError(err => {
          return this.errorService.handle(err);
        })
      );
  }

  public createComplex(data: ComplexShift): Observable<ManualShiftModel> {
    return this.apollo
      .mutate<any>({
        mutation: createManualShift,
        variables: {
          id: data.id,
          code: data.code,
          metacode: data.metacode,
          comment: data.comment,
          start: data.start,
          duration: data.duration,
          workingTimePercentage: data.workingTimePercentage,
          typeId: data.typeId,
          isComplex: data.isComplex,
          details: data.details,
          color: data.color,
          isGlobal: true,
          isQuicklyAccessible: data.isQuicklyAccessible
        }
      })
      .pipe(
        map(result => {
          return new ManualShiftModel(result.data.manualShift);
        }),
        catchError(err => {
          return this.errorService.handle(err);
        })
      );
  }

  public delete(id: number): Observable<ManualShiftModel> {
    return this.apollo
      .mutate<any>({
        mutation: deleteManualShift,
        variables: {
          id
        }
      })
      .pipe(
        map(result => {
          return new ManualShiftModel(result.data.manualShift);
        }),
        catchError(err => {
          return this.errorService.handle(err);
        })
      );
  }

  public getShiftCodeColors(): Observable<Map<number, string>> {
    return this.apollo
      .query<{ shiftCodeColors: { id: number; color: string }[] }>({
        query: shiftCodeColorsQuery
      })
      .pipe(
        map(res => {
          const shiftCodeColors = new Map<number, string>();
          res.data.shiftCodeColors.forEach(item => {
            shiftCodeColors.set(item.id, item.color);
          });
          return shiftCodeColors;
        }),
        share(),
        catchError(err => {
          return this.errorService.handle(err);
        })
      );
  }

  public getShiftsConstellations(groupId: number): Observable<ShiftsConstellationModel[]> {
    return this.apollo
      .query<any>({
        query: shiftsConstellationsQuery,
        variables: {
          groupId: groupId
        }
      })
      .pipe(
        map(res => res.data.shiftsConstellations.map(x => new ShiftsConstellationModel(x))),
        map((constellations: ShiftsConstellationModel[]) => constellations.sort((a, b) => a.id - b.id)),
        share(),
        catchError(err => {
          return this.errorService.handle(err);
        })
      );
  }

  public createShiftsConstellation(data: ShiftsConstellationCreateData): Observable<ShiftsConstellationModel> {
    return this.apollo
      .query<any>({
        query: createShiftsConstellation,
        variables: data
      })
      .pipe(
        map(res => new ShiftsConstellationModel(res.data.createShiftsConstellation)),
        catchError(err => {
          return this.errorService.handle(err);
        })
      );
  }

  public deleteShiftsConstellations(ids: number[]) {
    return this.apollo
      .query<any>({
        query: deleteShiftsConstellations,
        variables: { ids: ids }
      })
      .pipe(
        catchError(err => {
          return this.errorService.handle(err);
        })
      );
  }
}
