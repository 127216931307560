import { BaseModel } from '../base.model';

export const REPORT_PLUGIN_TYPE = {
  LineChart: 1,
  Demand: 2,
  HeatMap: 3,
  TextEditor: 4,
  Shiftr: 5,
  Cluster: 6
};

export class ReportPluginTypeModel extends BaseModel {
  public type: number;
  public name: string;

  constructor(data: any = {}) {
    super(data);

    this.type = data.type || null;
    this.name = data.name || '';
  }
}
