import { Injectable } from '@angular/core';
import { IAvaiIssueData } from '@app/rostr/_shared/interfaces/avai-issue-report.interface';
import { AvaiJobStatus } from '@models';
import { Observable, Subscription } from 'rxjs';
import { filter, pairwise, startWith, take } from 'rxjs/operators';
import { AvaiService } from '../api/services/avai.service';
import { ScheduleDto } from '../dto';
import { IAvaiJob } from '../dto/avai/avai.job';
import { AvaiState } from '../state/avai.state';

@Injectable({
  providedIn: 'root'
})
export class AvaiFacade {
  private scheduleId: number;
  private subscription$: Subscription;

  get isJobRunning$(): Observable<boolean> {
    return this.state.isJobRunning;
  }

  get isNewSolutionAvailable$(): Observable<boolean> {
    return this.state.isNewSolutionAvailable;
  }

  constructor(
    private readonly api: AvaiService,
    private readonly state: AvaiState
  ) {}

  get job$(): Observable<IAvaiJob> {
    return this.state.job;
  }

  public cleanUp(): void {
    this.state.cleanUp();
    this.stopListening();
  }

  public start(algorithm: string): void {
    this.api
      .startJob(this.scheduleId, algorithm)
      .pipe(take(1))
      .subscribe(data => {
        this.state.setJobData(data);
        this.startListening(data.targetId);
      });
  }

  public stop(): void {
    this.api.stopAvaiJob(this.scheduleId).pipe(take(1)).subscribe();
  }

  public stopWatching(): void {
    this.stopListening();
  }

  public watch(schedule: ScheduleDto): void {
    this.scheduleId = schedule.id;

    this.state.setJobData(schedule.job);
    this.startListening(schedule.id);
  }

  public reportAvaiIssue(description: string): Observable<IAvaiIssueData> {
    return this.api.reportAvaiIssue(this.scheduleId, { description });
  }

  private stopListening(): void {
    this.subscription$?.unsubscribe();
  }

  private startListening(scheduleId: number): void {
    this.stopListening();
    this.subscription$ = this.api
      .watchForUpdatedJobs(scheduleId)
      .pipe(
        startWith(<IAvaiJob>null),
        pairwise(),
        filter((jobs: [IAvaiJob, IAvaiJob]) => this.areDifferent(jobs))
      )
      .subscribe(data => {
        const [, latest] = data;
        this.state.setJobData(latest);

        if (this.hasNewSolutionOrFinished(data)) {
          this.state.setNewSolutionFlag();
        }
      });
  }

  public watchJobsCreated(): Observable<IAvaiJob> {
    return this.api.watchForNewJobs(null);
  }

  public watchAllJobsChanges(): Observable<IAvaiJob> {
    return this.api.watchForUpdatedJobs(null);
  }

  private areDifferent(pair: [IAvaiJob, IAvaiJob]) {
    const [jobA, jobB] = pair;
    return !jobA || jobA.data.latestVersion !== jobB.data.latestVersion || jobA.status !== jobB.status;
  }

  private hasNewSolutionOrFinished(pair: [IAvaiJob, IAvaiJob]) {
    const [jobA, jobB] = pair;
    return jobA && jobB && (jobB.status === AvaiJobStatus.FINISHED || jobA.data.latestVersion !== jobB.data.latestVersion);
  }
}
