<form class="container-fluid pl-form" [class.editable]="editMode">
  <div class="settings-grid">
    <div class="row" [formGroup]="settingsFormGroup"
      *ngFor="let settingsFormGroup of settingsFormGroups; let i = index;">
      <div class="col-lg-12">

        <div class="row">
          <div class="col-sm">
            <app-form-input [control]="settingsFormGroup.controls.dateFrom">
              <label for="dateFrom" class="pl-label">Date from</label>
              <app-date-picker formControlName="dateFrom"></app-date-picker>
            </app-form-input>
            <sh-validator-component [control]="settingsFormGroup.controls.dateFrom"></sh-validator-component>
          </div>

          <div class="col-sm" *ngIf="settingsFormGroup.controls.dateTo">
            <app-form-input [control]="settingsFormGroup.controls.dateTo">
              <label for="dateTo" class="pl-label">Date to</label>
              <app-date-picker formControlName="dateTo"></app-date-picker>
            </app-form-input>
            <sh-validator-component [control]="settingsFormGroup.controls.dateTo"></sh-validator-component>
          </div>

          <div class="col-sm" *ngIf="settingsFormGroup.controls.value">
            <app-form-input [control]="settingsFormGroup.controls.value">
              <label for="value" class="pl-label">Setting value</label>
              <div *ngIf="!editMode" class="placeholder-for-values">
                {{ settingsFormGroup.controls.value.value }}
              </div>
              <input id="value" type="number" class="pl-input" formControlName="value" />
            </app-form-input>
            <sh-validator-component [control]="settingsFormGroup.controls.value"></sh-validator-component>
          </div>

          <div class="col-sm-1 icons-container">
            <label></label>
            <a
              class="button-nav with-icon"
              [ngClass]="{ hidden: !editMode }"
              *ngIf="settingsFormGroup?.value?.id"
              (click)="delete(settingsFormGroup?.value?.id)">
              <span class="material-icons">delete</span>
            </a>
            <a
              class="button-nav with-icon"
              [ngClass]="{ hidden: !editMode }"
              *ngIf="!settingsFormGroup?.value?.id"
              (click)="clear(i)">
              <span class="material-icons">clear</span>
            </a>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12">
            <sh-validator-component [control]="settingsFormGroup"></sh-validator-component>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row settings-validator" *ngIf="editMode">
    <div class="col-lg-12">
      <sh-validator-component [control]="settingsForm"></sh-validator-component>
      <app-button type="button" class="btn btn-default btn-light btn-no-margin" value="Add"
        [ngClass]="{ hidden: addButtonDisabled }"
        (click)="addSetting()"></app-button>
    </div>
  </div>
</form>