import * as d3 from 'd3';
import * as moment from 'moment-mini';

import { GroupModel } from '@shared/models';
import { translate, xmlns } from '@shared/helpers/d3';

import { SchedulesTimeline } from './timeline';

export class ScheduleTimelineGroupItem {
  public yOffset = 0;
  public height: number;
  public firstScheduleDateFrom: moment.Moment;

  private readonly $el: d3.Selection<any, any, any, any>;
  private readonly el: Element;
  private readonly $title: d3.Selection<any, any, any, any>;
  private readonly $bgLine: d3.Selection<any, any, any, any>;
  private readonly $line: d3.Selection<any, any, any, any>;
  private readonly $todayArrow: d3.Selection<any, any, any, any>;

  private readonly $addNew: d3.Selection<any, any, any, any>;
  private readonly $addNewRect: d3.Selection<any, any, any, any>;
  private readonly $addNewText: d3.Selection<any, any, any, any>;
  private readonly $addNewIcon: d3.Selection<any, any, any, any>;

  private get isFirstGroupOfNewUnit(): boolean {
    const isFirst = this.groupIndex === 0;
    const previousUnitIsDifferent = this._timeline.groups[this.groupIndex]?.unit.id !== this._timeline.groups[this.groupIndex - 1]?.unit.id;

    return isFirst || previousUnitIsDifferent;
  }

  constructor(public readonly model: GroupModel, public readonly groupIndex: number, private readonly _timeline: SchedulesTimeline) {
    const s = _timeline.sizes;

    this.el = document.createElementNS(xmlns, 'g');
    this.$el = d3.select(this.el).classed('group-item', true);

    this.height = this._timeline.sizes.groupHeight * (this.isFirstGroupOfNewUnit ? 2.25 : 1);
    this.$bgLine = this._timeline.$groupLines
      .append('rect')
      .attr('x', 0)
      .attr('width', window.innerWidth)
      .attr('height', this._timeline.sizes.lineHeight)
      .classed('bg-line', true);

    this.$title = this.$el
      .append('text')
      .classed('name', true)
      .attr('x', s.groupWidth - s.groupNameOffset + s.defaultMargin)
      .attr('y', this.height - s.lineHeight - s.defaultMargin)
      .text(this.model.name);

    if (this.isFirstGroupOfNewUnit) {
      this.$el
        .append('text')
        .classed('name unit-name', true)
        .attr('x', s.groupWidth - s.groupNameOffset)
        .attr('y', this.height - s.groupHeight - s.defaultMargin)
        .text(this.model.unit.name);
    }

    this.$line = this.$el
      .append('rect')
      .classed('colored-line', true)
      .attr('y', this.height - s.lineHeight)
      .attr('height', s.lineHeight)
      .attr('fill', this._timeline.getColor(this.groupIndex));

    this.$todayArrow = this.$el
      .append('polygon')
      .classed('today-arrow', true)
      .attr('visibility', 'hidden')
      .attr('points', '0, -15, 0, 15, 15, 0')
      .attr('fill', this._timeline.getColor(this.groupIndex));

    this.$addNew = this.$el.append('g').classed('add-new', true);
    this.$addNewRect = this.$addNew
      .append('rect')
      .attr('height', s.addNewHeight)
      .attr('x', s.groupWidth)
      .attr('y', this.height - s.lineHeight - s.addNewHeight)
      .on('click touchstart', () => this._timeline.context.createNewSchedule(this.model));
    this.$addNewText = this.$addNew
      .append('text')
      .attr('y', this.height - s.lineHeight - s.addNewHeight / 3.5)
      .classed('title', true);
    this.$addNewIcon = this.$addNew
      .append('text')
      .text('add_box')
      .attr('y', this.height - s.lineHeight - s.addNewHeight / 3.5)
      .attr('dx', 70)
      .attr('dy', 4)
      .classed('material-icons', true);

    this.subscribe();
  }

  /**
   * Rendering functionality
   * @returns {Element}
   */
  public render() {
    const prev = this.groupIndex && this._timeline.groupItems[this.groupIndex - 1];
    this.yOffset = prev ? prev.yOffset + prev.height : 0;

    this.$el.attr('transform', translate(0, this.yOffset));
    this.$bgLine.attr('y', this.yOffset + this.height - this._timeline.sizes.lineHeight);
    return this.el;
  }

  public renderTodayArrow() {
    const s = this._timeline.sizes;
    const sy = this._timeline.context.selectedYear;
    const ts = this._timeline.timeScale;
    const first = this.firstScheduleDateFrom;
    const now = this._timeline.now;
    const groupSchedules = this._timeline.schedules.filter((item) => item.model.groups.some((group) => group.id === this.model.id));

    this.renderAddNew(!!groupSchedules.length);

    // line rendering
    if (first && first.year() === sy && now.year() === sy && now.isAfter(first)) {
      // if first schedule and now in selected year we should render arrow line from first to now
      this.$line
        .attr('visibility', 'visible')
        .attr('x', s.groupWidth + ts(first))
        .attr('width', ts(now) - ts(first) + 1);
      this.$todayArrow.attr('visibility', 'visible').attr('transform', translate(s.groupWidth + ts(now), this.height - s.lineHeight / 2));
    } else if (first && first.year() === sy && now.isAfter(first)) {
      // if first schedule in selected year but now is after that we should render arrow line from first to
      // the end of year
      this.$line
        .attr('visibility', 'visible')
        .attr('x', s.groupWidth + ts(first))
        .attr('width', window.innerWidth - s.groupWidth - ts(first));
    } else if (first && first.year() < sy && sy < now.year()) {
      // if first schedule before selected year and selected year before now line should be from 0 to the end of screen
      this.$line.attr('visibility', 'visible').attr('x', 0).attr('width', window.innerWidth);
    } else if (first && first.year() < sy && now.year() === sy) {
      // if first schedule before selected year and selected year is now line should be from 0 to the now
      this.$line
        .attr('visibility', 'visible')
        .attr('x', 0)
        .attr('width', s.groupWidth + ts(now) + 1);
      this.$todayArrow.attr('visibility', 'visible').attr('transform', translate(s.groupWidth + ts(now), this.height - s.lineHeight / 2));
    } else {
      this.$line.attr('visibility', 'hidden');
    }

    if (sy !== now.year() || !first || now.isBefore(first)) {
      this.$todayArrow.attr('visibility', 'hidden');
    }
  }

  public renderAddNew(hide?: boolean) {
    const sy = this._timeline.context.selectedYear;
    const s = this._timeline.sizes;
    this.$addNew.classed('hide', hide);

    if (hide) {
      return;
    }

    const available = sy >= this._timeline.now.year();
    const ts = this._timeline.timeScale;
    const width = ts(new Date(sy, 11, 31, 23, 59, 59));

    this.$addNew.classed('available', available);
    this.$addNewText.attr('x', s.groupWidth + width / 2).text(available ? 'New schedule' : 'No available schedules');
    if (available) {
      this.$addNewRect.attr('width', width);
      this.$addNewIcon.attr('x', s.groupWidth + width / 2);
    }
  }

  private subscribe() {
    const s = this._timeline.sizes;

    this._timeline.onResize.subscribe(() => {
      this.$bgLine.attr('width', window.innerWidth);
      this.$line.attr('width', s.groupWidth + this._timeline.timeScale(this._timeline.now) + 1);
      this.renderTodayArrow();
    });
  }
}
