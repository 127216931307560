import { ShiftAliasModel } from '../activities';
import { EmployeeModel } from '../auth';
import { GroupModel } from '../companies';
import { VacayRequestWish, WishStatus } from './vacay-request-wish';
import { RostrRequestBaseModel } from '../rostr/rostr-request-base.model';
import { IVacayRequestParams } from '../interfaces';
import { ScheduleAssignedActivityModel } from '@model/schedules';
import { VacayRequestStatus } from '@shared/enums/vacay-request-status.enum';
import { ConversationPanelComment } from '@component/conversation-panel/models';
import { VacayRequestBatch } from './vacay-request-batch.model';
import { ManualShiftModel } from '@model/rostr';
import { ShiftCodeTypeEnum } from '@shared/enums/shift-code-type.enum';

export class VacayRequestModel extends RostrRequestBaseModel {
  get type() {
    return 'vacay';
  }

  set wishes(wishes) {
    this.wishesCollection = [];

    if (!wishes || !wishes.length) {
      return;
    }

    this.wishesCollection = wishes.map(wish => new VacayRequestWish(wish));
  }

  get wishes() {
    return this.wishesCollection;
  }

  get wishStatus() {
    if (!this.wishes) {
      return;
    }

    if (!this.shiftAlias || !this.shiftAlias.id) {
      console.warn('Shift alias id not found');
      return;
    }

    return this.wishes.find(wish => wish.shiftAlias === this.shiftAlias.id);
  }

  get available() {
    return this.groupedWishes.available;
  }

  get wished() {
    return this.groupedWishes.wished;
  }

  get isDayOff() {
    if (!this.shiftAlias || !this.shiftAlias.type) {
      console.warn('Shift alias type not found');
      return;
    }

    return this.shiftAlias.type === 'dayOff';
  }

  get isVacation() {
    if (!this.shiftAlias || !this.shiftAlias.type) {
      console.warn('Shift alias type not found');
      return;
    }

    return this.shiftAlias.type === 'vacation';
  }

  private _requestStatus;

  get requestStatus(): VacayRequestStatus {
    return this._requestStatus;
  }

  private _assignedActivity: ScheduleAssignedActivityModel;

  get assignedActivity(): ScheduleAssignedActivityModel {
    return this._assignedActivity;
  }

  set assignedActivity(assignedActivity: ScheduleAssignedActivityModel) {
    this._assignedActivity = assignedActivity;
    this.calculateRequestStatus();
  }

  public startTime: number;
  public start: number;
  public duration: number;

  public group: GroupModel;
  public employee: EmployeeModel;
  public shiftAlias: ShiftAliasModel;

  public groupedWishes = {
    wished: void 0,
    available: void 0,
    unavailable: void 0
  };

  public comments: ConversationPanelComment[];
  protected wishesCollection: VacayRequestWish[];
  public date: Date;
  public shiftCodeId: number;
  public shiftCode: ManualShiftModel;
  public vacayRequestBatch: VacayRequestBatch;
  public vacayRequestBatchId: number;
  public isDeclined: boolean;

  constructor(
    data: Partial<IVacayRequestParams> = {
      id: undefined,
      createdAt: undefined,
      createdBy: undefined,
      updatedAt: undefined,
      updatedBy: undefined,
      deletedAt: undefined,
      __typename: '',
      code: '',
      dateFrom: undefined,
      dateTo: undefined,
      iDate: undefined,
      shiftAlias: undefined,
      comments: [],
      data: undefined,
      duration: undefined,
      employee: undefined,
      group: undefined,
      startTime: undefined,
      start: undefined,
      date: undefined,
      shiftCode: undefined,
      shiftCodeId: undefined,
      vacayRequestBatch: undefined,
      vacayRequestBatchId: undefined,
      assignedActivity: undefined,
      isDeclined: undefined
    }
  ) {
    super(data);

    this.start = data.start;
    this.duration = data.duration;

    this.group = data.group ? new GroupModel(data.group) : data.group;
    this.employee = data.employee ? new EmployeeModel(data.employee) : undefined;
    this.shiftAlias = data.shiftAlias ? new ShiftAliasModel(data.shiftAlias) : undefined;
    this.comments = data?.comments?.map(comment => new ConversationPanelComment(comment)) || [];
    this.wishes = data && !!data.data && data.data.wishes;
    this.groupedWishes.available = (this.wishes && this.wishes.find(wish => wish.status === WishStatus.Available)) || void 0;
    this.groupedWishes.wished = (this.wishes && this.wishes.find(wish => wish.status === WishStatus.Wish)) || void 0;
    this.date = new Date(data.date);
    this.shiftCodeId = data.shiftCodeId;
    this.shiftCode = data.shiftCode ? new ManualShiftModel(data.shiftCode) : undefined;
    this.vacayRequestBatch = data.vacayRequestBatch && new VacayRequestBatch(data.vacayRequestBatch);
    this.vacayRequestBatchId = data.vacayRequestBatchId ?? data.vacayRequestBatch?.id;
    this.createdBy = data.createdBy ? new EmployeeModel(data.createdBy) : undefined;
    this.isDeclined = data.isDeclined;
    this.assignedActivity = data.assignedActivity ? new ScheduleAssignedActivityModel(data.assignedActivity) : null;
  }

  private calculateRequestStatus() {
    if (!this._assignedActivity) {
      this._requestStatus = VacayRequestStatus.UNASSIGNED;
      return;
    }

    if (this.shiftCode) {
      this._requestStatus = this.getRequestStatusWithShiftCode();
    } else {
      this._requestStatus = this.getRequestStatusWithShiftAlias();
    }
  }

  private getRequestStatusWithShiftCode(): VacayRequestStatus {
    const shiftCodeType = this.shiftCode.type.id as ShiftCodeTypeEnum;
    const assignedActivityType = this.assignedActivity.type;

    if (shiftCodeType !== ShiftCodeTypeEnum.Operative) {
      if (shiftCodeType === assignedActivityType) {
        return this.assignedActivity.isLocked ? VacayRequestStatus.APPROVED : VacayRequestStatus.PENDING;
      } else {
        return VacayRequestStatus.DECLINED;
      }
    } else {
      return this.shiftCode.code === this.assignedActivity.code ? VacayRequestStatus.APPROVED : VacayRequestStatus.DECLINED;
    }
  }

  private getRequestStatusWithShiftAlias(): VacayRequestStatus {
    if (this.shiftAlias.value === this.assignedActivity.alias.value) {
      if (this.assignedActivity.isLocked) {
        return VacayRequestStatus.APPROVED;
      } else {
        return VacayRequestStatus.PENDING;
      }
    } else {
      return VacayRequestStatus.DECLINED;
    }
  }
}
