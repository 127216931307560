import * as moment from 'moment-mini';

import { BaseModel } from '../base.model';
import { GroupModel } from '../companies';
import { ReportModel } from '../reports';
import { ScheduleModel } from './schedule.model';
import { MasterplanActivityModel } from './masterplan-activity.model';


export class MasterplanModel extends BaseModel {

  public title: string;
  public date: moment.Moment;

  public group: GroupModel;
  public report: ReportModel;
  public schedule: ScheduleModel;
  public groups: any;
  public data: MasterplanDataModel;

  // will be in the future
  public shifts: number;
  public hours: number;
  public status: string;

  constructor(data: any = {}) {
    super(data);

    this.title = data.title || '';
    this.date = (data.date && moment.utc(data.date, 'YYYY-MM-DDTHH:mm:ssZ')) || undefined;

    this.group = (data.group && new GroupModel(data.group)) || undefined;
    this.report = (data.report && new ReportModel(data.report)) || undefined;
    this.schedule = (data.schedule && new ScheduleModel(data.schedule)) || undefined;
    this.groups = data.groups || [];
    this.data = (data.data && new MasterplanDataModel(data.data)) || undefined;
  }

  get dateFrom() {
    return this.data.reportDateFrom;
  }

  get requiredActivities() {
    return this.data.required;
  }

  get assignedActivities() {
    return this.data.assigned;
  }

  get subsets() {
    return this.data.assigned;
  }

}


export class MasterplanDataModel {

  public required: MasterplanActivityModel[];
  public assigned: MasterplanSubset[];
  public reportDateFrom: moment.Moment;
  public employees: number[];
  public employeeAssignments: any;

  constructor(data: any = {}) {
    this.required = (
      data.required
      && data.required.map(el => new MasterplanActivityModel(el))
    ) || [];

    this.assigned = (data.assigned && data.assigned.map((subset) => new MasterplanSubset(subset))) || [];

    this.reportDateFrom = (
      data.reportDateFrom
      && moment.utc(data.reportDateFrom, 'YYYY-MM-DDTHH:mm:ssZ')
    ) || void 0;

    this.employeeAssignments = data.employeeAssignments || [];
  }
}


export class MasterplanSubset {
  public color: string;
  public activities: MasterplanActivityModel[][] = [];
  public groupIndex;

  constructor(subset: any = {}) {
    this.groupIndex = subset.groupIndex;

    if (subset.activities) {
      this.activities = subset.activities.map((row) => {
        return row.map(el => (el && new MasterplanActivityModel(el, this.groupIndex)) || void 0);
      });
    }
  }
}
