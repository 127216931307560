import * as d3 from 'd3';
import { debounce as _debounce, sortBy as _sortBy } from 'lodash-es';
import * as moment from 'moment-mini';
import { EventEmitter } from '@angular/core';

import { translate, xmlns, isoWeek } from '@helpers';

import { getYearByDateForCalendar, getCountOfWeeksForCalendar } from './helpers';
import { Day } from './day';
import { Month } from './month';
import { Week } from './week';
import { WeekDay } from './weekDay';
import { IBaseRenderer } from './base-renderer.interface';

export class Renderer implements IBaseRenderer {
  get gridContainer() {
    return this.$gridContainer;
  }

  get daysContainer() {
    return this.$daysContainer;
  }

  get weeksContainer() {
    return this.$weeksContainer;
  }

  get monthContainer() {
    return this.$monthContainer;
  }

  get monthLabels() {
    return this.$monthLabels;
  }

  get cellSize() {
    return this._cellSize;
  }

  get halfSize() {
    return this._halfSize;
  }

  get baseDate() {
    return moment().year(this.calendarYear).startOf('year').isoWeekday(1).toDate();
  }
  public window: d3.Selection<any, any, any, any> = d3.select('window');
  public svg: d3.Selection<any, any, any, any>;

  public sizes = {
    controlsWidth: 55,
    marginTop: 2,
    marginLeft: 15,
    marginRight: 15,
    monthsLabelHeight: 20,
    weeksContainerHeight: 30
  };

  public start;

  public calendarYear: number = moment().year();

  public activeUnit = void 0;

  public days: Day[] = []; // array with days objects
  public months: Month[] = []; // array with months objects
  public weeks: Week[] = []; // array with weeks objects
  public daysOfWeek: WeekDay[] = []; // array with days of weeks objects

  public holidays = {};

  public date; // start date

  // public selectedDates = {};

  public renderDaysGridEvt = new EventEmitter();
  public daysReadyEvt = new EventEmitter();
  public daySelectEvent = new EventEmitter();
  public yearChanged = new EventEmitter();
  public dataProvider = new EventEmitter();
  public onDestroy = new EventEmitter();

  public isShowDayText;
  public showHolidays;

  public selected = [];
  public selectedRanges = [];
  public selectedDates = {};
  public limitsData = { limits: void 0, handler: void 0 };

  public relatedHolidayDays = [];

  public $gridContainer;
  public $monthContainer;
  public $monthLabels;
  public $daysContainer;
  public $weeksContainer;

  public svgOptions = {
    height: 0,
    width: 0,
    controlsScale: d3.scaleLinear(),
    weekScale: d3.scaleLinear(),
    monthScale: d3.scaleLinear()
  };

  public editMode = true;

  private monthsLabels: string[] = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

  private _weekDaysLabels: string[] = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];

  private _svg: d3.Selection<any, any, any, any>;
  private _cellSize: number;
  private _halfSize: number;
  /*
   Containers for drawing controls
   */
  private _gridContainer;
  private _monthContainer;
  private _daysContainer;
  private _weeksContainer;
  private _controlsContainer;
  private _monthLabels;

  private _tooltipDebounceFn;
  private _brushReached = false;

  constructor(
    public el: HTMLElement,
    public options,
    public resultDates,
    public dateChanges,
    public tooltipEvent,
    public dayDisableFn,
    public invalidSelectionFn
  ) {
    this.start = moment().startOf('year').isoWeekday(1).toDate();
    this.calendarYear = this.options.year || this.calendarYear;

    this.isShowDayText = this.options.showDates;
    this.showHolidays = this.options.showHolidays;

    this.dayDisableFn = dayDisableFn;
    this.invalidSelectionFn = invalidSelectionFn;

    this.selectedRanges = [];
    this.selectedDates = {};

    this.window = d3.select(window);
    this.calcCellSize();

    this._svg = d3.select(el).selectAll('svg').attr('width', this.svgOptions.width).attr('height', this.svgOptions.height);

    this.svg = this._svg.append('g').attr('class', 'main').attr('transform', translate(this.sizes.marginLeft, this.sizes.marginTop));

    if (this.options.unitsList && this.options.activeUnitId) {
      this.activeUnit = this.options.unitsList.find(unit => unit.id === this.options.activeUnitId);
    }

    /*
     Container initialization
     */
    this.initGrid();
    this.initMonth();
    this.initMonthLabels();
    this.initDaysContainer();
    this.initWeeksContainer();

    // Subscribe to select/un-select day
    this.daySelectEvent.subscribe(d => {
      if (d.status) {
        this.addSelectedDate(d.e);
        if (d.callEmit) {
          this.dateChanges.emit({
            type: 'add',
            changedDate: d.e.date,
            ranges: this.selectedRanges,
            activeUnit: this.activeUnit
          });
        }
      } else {
        this.dropSelectedDate(d.e);
        if (d.callEmit) {
          this.dateChanges.emit({
            type: 'remove',
            changedDate: d.e.date,
            ranges: this.selectedRanges,
            activeUnit: this.activeUnit
          });
        }
      }

      this.emitResultDates();
    });

    this.options.dates.forEach(date => {
      const momentDate = moment(date);
      const index = momentDate.dayOfYear() - 1;
      const day = new Day(this, index, momentDate.toDate(), {
        daySelectEvent: this.daySelectEvent,
        isSelected: true
      });
      // this.selectedDates[this.calendarYear]
      this.addSelectedDate(day);
      // this.emitResultDates();
    });

    this.emitResultDates();
    this.initDayTooltip();
  }

  public destroy() {
    this.svg.selectAll('*').remove();

    this.days.forEach(d => d.onDestroy());

    this.onDestroy.emit();
    this.onDestroy.complete();
  }

  public initGrid() {
    this._gridContainer = document.createElementNS(xmlns, 'g');
    this.$gridContainer = d3
      .select(this._gridContainer)
      .attr('id', 'elements-container')
      .attr('transform', translate(this.sizes.controlsWidth, this.sizes.marginTop + this.sizes.monthsLabelHeight));
  }

  public attachGrid() {
    this.svg.node().prepend(this.$gridContainer.node());

    /**
     * Add events for draggable select
     */
    const self = this;

    this.$gridContainer.on('mousedown', function () {
      const point = d3.mouse(this);

      self._tooltipDebounceFn.cancel();
      self.$gridContainer.on('mouseenter', null);
      self.$gridContainer.on('mouseleave', null);

      self.tooltipEvent.emit({
        toggle: false
      });

      self.$gridContainer.on('mousemove', function () {
        const c1 = point;
        const c2 = d3.mouse(self.gridContainer.node());
        let x1;
        let x2;
        let y1;
        let y2;

        x1 = c1[0] <= c2[0] ? c1[0] : c2[0];
        x2 = c1[0] <= c2[0] ? c2[0] : c1[0];
        y1 = c1[1] <= c2[1] ? c1[1] : c2[1];
        y2 = c1[1] <= c2[1] ? c2[1] : c1[1];

        self.brush_select(x1, y1, x2, y2);
      });
    });

    this.$gridContainer.on('click', () => {
      // Protection
      if (this._brushReached) {
        return;
      }

      const selectedDay = this.getSelectedDay();

      if (!selectedDay.disabled) {
        selectedDay.setSelection(!selectedDay.isSelected);
        this.invalidSelectionFn && this.invalidSelectionFn(null);
      } else {
        this.invalidSelectionFn && this.invalidSelectionFn(selectedDay);
      }
    });

    /**
     * Apply selecting after draggable select end
     */
    this.$gridContainer.on('mouseup', () => {
      this.$gridContainer.on('mousemove', null);
      this.brush_apply();

      // Must have for click protection
      setTimeout(() => {
        this._brushReached = false;
        this.tooltipEvents();
      });
    });
  }

  public initDayTooltip() {
    let sDay;

    this._tooltipDebounceFn = _debounce(selectedDay => {
      if (sDay === selectedDay) {
        return;
      }
      sDay = selectedDay;

      this.tooltipEvent.emit({
        toggle: true,
        configs: {
          targetElement: selectedDay.cellGroup.node().getBoundingClientRect(),
          context: {
            date: selectedDay.date,
            holiday: selectedDay.holidayData
          }
        }
      });
    }, 500);

    this.tooltipEvents();
  }

  public tooltipEvents() {
    const mouseMoveFn = () => {
      this._tooltipDebounceFn.cancel();
      this._tooltipDebounceFn(this.getSelectedDay());
    };

    this.$gridContainer.on('mousemove', mouseMoveFn);

    this.$gridContainer.on('mouseleave', () => {
      this.$gridContainer.on('mousemove', null);
      this._tooltipDebounceFn.cancel();
      this.tooltipEvent.emit({
        toggle: false
      });
    });

    this.$gridContainer.on('mouseenter', () => {
      this.$gridContainer.on('mousemove', mouseMoveFn);
    });
  }

  public destroyGrid() {
    this.$gridContainer.on('mouseup', null);
    this.$gridContainer.on('mousemove', null);
    this.$gridContainer.on('click', null);
    this.$gridContainer.remove();
  }

  public initMonth() {
    this._monthContainer = document.createElementNS(xmlns, 'g');
    this.$monthContainer = d3
      .select(this._monthContainer)
      .attr('id', 'month-container')
      .attr('transform', translate(this.sizes.controlsWidth, this.sizes.marginTop + this.sizes.monthsLabelHeight));
  }

  public initMonthLabels() {
    this._monthLabels = document.createElementNS(xmlns, 'g');
    this.$monthLabels = d3
      .select<SVGElement, any>(this._monthLabels)
      .attr('id', 'month-labels')
      .attr('transform', translate(this.sizes.controlsWidth, this.sizes.monthsLabelHeight / 2));
  }

  public initDaysContainer() {
    this._daysContainer = document.createElementNS(xmlns, 'g');
    this.$daysContainer = d3
      .select(this._daysContainer)
      .attr('id', 'container-days')
      .attr('transform', translate(0, this.sizes.marginTop + this.sizes.monthsLabelHeight));
  }

  public initWeeksContainer() {
    this._weeksContainer = document.createElementNS(xmlns, 'g');
    this.$weeksContainer = d3
      .select(this._weeksContainer)
      .attr('id', 'container-weeks')
      .attr('transform', translate(this.sizes.controlsWidth, this.svgOptions.height - this.sizes.weeksContainerHeight / 2));
  }

  public updateDates(dates) {
    this.removeAllRanges(false);

    dates.forEach(date => {
      const momentDate = moment(date);
      const index = momentDate.dayOfYear() - 1;

      if (momentDate.year() === this.calendarYear) {
        this.days[index].setSelection(true, false);
      } else {
        const day = new Day(this, index, momentDate.toDate(), {
          daySelectEvent: this.daySelectEvent,
          isSelected: true
        });

        this.addSelectedDate(day);
      }
    });

    this.emitResultDates();
  }

  public updateUnits(units) {
    this.activeUnit = void 0;
    this.options.unitsList = units;
    this.drawUnitsSelector();
  }

  public setActiveUnit(unitId) {
    const unit = this.options.unitsList.find(_unit => _unit.id === unitId);
    if (!unit) {
      return;
    }

    this.activeUnit = unit;
    this.drawUnitsSelector();
    this.emitResultDates();
  }

  public renderDaysGrid() {
    this.days.length = 0;
    this.date = moment().year(this.calendarYear).startOf('year');

    const countOfDays = moment().year(this.calendarYear).endOf('year').dayOfYear();

    for (let i = 0; i < countOfDays; i++) {
      // Restore days from cache or init new day
      let day;
      if (this.selectedDates[this.calendarYear] && this.selectedDates[this.calendarYear][i]) {
        day = this.selectedDates[this.calendarYear][i];
        this.addSelectedDate(day);
      } else {
        day = new Day(this, i, this.date.toDate(), {
          daySelectEvent: this.daySelectEvent,
          disabled: this.dayDisableFn ? this.dayDisableFn(this.date) : false
        });
      }

      // let selectedDate = this.options.dates.find((date) => {
      //   return this.date.isSame(moment(date));
      // });
      // if (selectedDate) {
      //   day.setSelection(true);
      // }

      day.isShowDayText = this.isShowDayText;

      // Add links to related objects
      if (this.date.year() === this.calendarYear) {
        const dateMonth = this.months[this.date.month()];

        const dateWeek = this.weeks.find(week => {
          // TODO fix it. It's not correct way to check current week
          const date = this.date.toDate();
          const weekNumber = isoWeek(date);
          const weekYear = getYearByDateForCalendar(date, this.calendarYear);
          return +week.weekNumber === weekNumber && weekYear === week.weekYear && week.weekDays.length < 7;
        });

        const dayOfWeek = this.daysOfWeek[moment(this.date).isoWeekday() - 1];

        dayOfWeek.addRelatedDay(day);
        day.dayOfWeek = dayOfWeek;

        dateMonth.addRelatedDay(day);
        day.month = dateMonth;

        dateWeek.addRelatedDay(day);
        day.week = dateWeek;
      }

      day.render();
      this.days.push(day);

      this.date.add(1, 'd');
    }
    this.daysReadyEvt.emit();
    this.renderDaysGridEvt.emit();

    this.attachGrid();
    // this.setInitializeData(this.options.initialData);
  }

  public rerenderDaysGrid() {
    this.destroyGrid();
    this.$monthContainer.remove();

    this.initMonth();
    this.initGrid();

    for (let i = 0; i < this.months.length; i++) {
      this.months[i].render();
    }
    this.drawWeeks();
    this.drawWeeksDay();
    this.renderDaysGrid();

    this.svg.node().appendChild(this.$gridContainer.node());
    this.svg.node().appendChild(this.$monthContainer.node());
  }

  public renderMonths() {
    this.monthsLabels.map((v, index) => {
      const month = new Month(this, v, index);
      month.render();
      this.months.push(month);
    });
    this.svg.node().appendChild(this.$monthContainer.node());
    this.svg.node().appendChild(this.$monthLabels.node());
  }

  public drawControls() {
    this.svg.selectAll('.controls').remove();
    this._controlsContainer = this.svg
      .append('g')
      .attr('class', 'controls')
      .attr('translate', translate(0, this.sizes.marginTop + this.sizes.monthsLabelHeight));

    this.drawCurrentYearLabel();

    this.drawUnitsSelector();
  }

  public drawCurrentYearLabel() {
    const self = this;
    this._controlsContainer.selectAll('.decrease-year').remove();
    this._controlsContainer.selectAll('.increase-year').remove();

    let textWidth = 0;
    this._controlsContainer.selectAll('.year-label').remove();

    const yearMargin = self.svgOptions.controlsScale.invert(1);

    this._controlsContainer
      .append('text')
      .attr('class', 'year-label')
      .text(this.calendarYear)
      .attr('style', 'font-size: 14px; fill: #000')
      .attr('transform', function () {
        textWidth = this.getComputedTextLength();
        return `${translate(yearMargin, self.svgOptions.height / 2)}rotate(-90)`;
      })
      .style('text-anchor', 'middle');

    this._controlsContainer
      .append('text')
      .attr('transform', `${translate(yearMargin, this.svgOptions.height / 2 + textWidth * 1.7)}rotate(-90)`)
      .style('text-anchor', 'middle')
      .attr('style', 'font-size: 14px; fill: #000')
      .attr('class', 'decrease-year')
      .style('cursor', 'pointer')
      .text(function () {
        return '<  ';
      })
      .on('click', () => {
        this.calendarYear--;
        this.calcSizes();
        this.drawCurrentYearLabel();
        this.rerenderDaysGrid();
        this.yearChanged.emit(this.calendarYear);
      });

    this._controlsContainer
      .append('text')
      .attr('transform', `${translate(yearMargin, this.svgOptions.height / 2 - textWidth * 1.7)}rotate(-90)`)
      .style('text-anchor', 'middle')
      .attr('style', 'font-size: 14px; fill: #000')
      .attr('class', 'increase-year')
      .style('cursor', 'pointer')
      .text(function () {
        return '  >';
      })
      .on('click', () => {
        this.calendarYear++;
        this.calcSizes();
        this.drawCurrentYearLabel();
        this.rerenderDaysGrid();
        this.yearChanged.emit(this.calendarYear);
      });
  }

  public drawUnitsSelector() {
    if (!this.options.unitsList || !this.options.unitsList.length) {
      return false;
    }

    const self = this;
    this._controlsContainer.selectAll('.units-selector').remove();
    const unitsContainer = this._controlsContainer.append('g').attr('class', 'units-selector');

    let textWidth = 0;

    const yearMargin = self.svgOptions.controlsScale.invert(0);

    unitsContainer
      .append('text')
      .attr('class', 'unit-label')
      .text(this.activeUnit ? this.activeUnit.name : this.options.unitsList[0].name)
      .attr('style', 'font-size: 14px; fill: #000')
      .attr('transform', function () {
        textWidth = this.getComputedTextLength();
        return `${translate(yearMargin, self.svgOptions.height / 2)}rotate(-90)`;
      })
      .style('text-anchor', 'middle');

    unitsContainer
      .append('text')
      .attr('transform', `${translate(yearMargin, this.svgOptions.height / 2 + textWidth * 1.4)}rotate(-90)`)
      .style('text-anchor', 'middle')
      .attr('style', 'font-size: 14px; fill: #000')
      .attr('class', 'down-unit')
      .style('cursor', 'pointer')
      .text(function () {
        return '<  ';
      })
      .on('click', () => {
        const unitsCount = this.options.unitsList.length;
        const indexActiveUnit = this.options.unitsList.indexOf(this.activeUnit);
        if (indexActiveUnit === 0) {
          this.activeUnit = this.options.unitsList[unitsCount - 1];
        } else if (indexActiveUnit === -1) {
          this.activeUnit = this.options.unitsList[0];
        } else {
          this.activeUnit = this.options.unitsList[indexActiveUnit - 1];
        }
        this.drawUnitsSelector();
        this.emitResultDates();
      });

    unitsContainer
      .append('text')
      .attr('transform', `${translate(yearMargin, this.svgOptions.height / 2 - textWidth * 1.4)}rotate(-90)`)
      .style('text-anchor', 'middle')
      .attr('style', 'font-size: 14px; fill: #000')
      .attr('class', 'up-unit')
      .style('cursor', 'pointer')
      .text(function () {
        return '  >';
      })
      .on('click', () => {
        const unitsCount = this.options.unitsList.length;
        const indexActiveUnit = this.options.unitsList.indexOf(this.activeUnit);
        if (indexActiveUnit >= unitsCount) {
          this.activeUnit = this.options.unitsList[0];
        } else if (indexActiveUnit === -1) {
          this.activeUnit = this.options.unitsList[1] || this.options.unitsList[0];
        } else {
          this.activeUnit = this.options.unitsList[indexActiveUnit + 1];
        }
        this.drawUnitsSelector();
        this.emitResultDates();
      });
  }

  public drawWeeksDay() {
    this.$daysContainer.selectAll('*').remove();
    this.daysOfWeek.length = 0;
    this._weekDaysLabels.forEach((label, index) => {
      const wd = new WeekDay(this, index, label);
      wd.render();
      this.daysOfWeek.push(wd);
    });
    this.svg.node().appendChild(this.$daysContainer.node());
  }

  public drawWeeks() {
    this.$weeksContainer.selectAll('*').remove();
    this.weeks.length = 0;

    this.date = moment().year(this.calendarYear).startOf('year').startOf('isoWeek');

    this.svg.node().appendChild(this.$weeksContainer.node());

    for (let i = 0; i < getCountOfWeeksForCalendar(this.calendarYear); i++) {
      const week = new Week(this, i, this.date.toDate());
      this.date.add(1, 'week');
      week.render();
      this.weeks.push(week);
    }
  }

  public showDays() {
    // this.dayHideEvent.emit();
    this.dataProvider.emit({
      type: 'daysHide',
      payload: void 0
    });
  }

  public setHolidays(holidays, year) {
    this.holidays[year] = holidays;
    if (this.showHolidays && this.calendarYear === year) {
      if (this.holidays[this.calendarYear]) {
        // this.showHolidaysEvent.emit(this.holidays[this.calendarYear]);
        this.dataProvider.emit({
          type: 'showHolidays',
          payload: this.holidays[this.calendarYear]
        });
      }
    }
  }

  public toggleHolidays() {
    this.showHolidays = !this.showHolidays;
    if (this.showHolidays) {
      if (this.holidays[this.calendarYear]) {
        this.dataProvider.emit({
          type: 'showHolidays',
          payload: this.holidays[this.calendarYear]
        });
        // this.showHolidaysEvent.emit(this.holidays[this.calendarYear]);
      }
    } else {
      this.relatedHolidayDays.forEach(day => {
        day.highlightAsHoliday(false);
      });
      this.relatedHolidayDays.length = 0;
    }
  }

  public addSelectedDate(newDate: Day) {
    if (this.selected.indexOf(newDate.date) === -1) {
      const year = moment(newDate.date).year();
      if (!this.selectedDates[year]) {
        this.selectedDates[year] = {};
      }

      this.selectedDates[year][newDate.index] = newDate;
      this.selected.push(newDate.date);

      // Recalculation ranges
      this.recalc(newDate);
    }
  }

  /**
   * Un-selecting day
   * @param newDate { Day }
   */
  public dropSelectedDate(newDate: Day) {
    if (this.selected.indexOf(newDate.date) !== -1) {
      delete this.selectedDates[moment(newDate.date).year()][newDate.index];
      this.selected.splice(this.selected.indexOf(newDate.date), 1);
      this.splitRanges(newDate.date);
    }
  }

  public removeRange(range) {
    const rangeIndex = this.selectedRanges.indexOf(range);
    if (rangeIndex > -1) {
      const deletedDates = this.selectedRanges[rangeIndex].days.slice();

      deletedDates.forEach(day => {
        // params: status and emitEvent
        day.setSelection(false, false);
        this.dropSelectedDate(day);
      });

      this.dateChanges.emit({
        type: 'group_remove',
        changedDates: deletedDates.reduce((acc, day) => {
          acc.push(day.date);
          return acc;
        }, []),
        ranges: this.selectedRanges,
        activeUnit: this.activeUnit
      });
    }
  }

  public removeAllRanges(emitEvent?) {
    let deletedDates = [];
    for (let i = this.selectedRanges.length - 1; i >= 0; i--) {
      const rangeDates = this.selectedRanges[i].days.slice();
      deletedDates = deletedDates.concat(rangeDates);

      rangeDates.forEach(day => {
        // params: status and emitEvent
        day.setSelection(false, false);
        this.dropSelectedDate(day);
      });
    }

    // TODO need to fix eventEmitters for dates when we update or remove them
    if (typeof emitEvent === 'undefined') {
      this.dateChanges.emit({
        type: 'group_remove',
        changedDates: deletedDates.reduce((acc, day) => {
          acc.push(day.date);
          return acc;
        }, []),
        ranges: [],
        activeUnit: this.activeUnit
      });
    }
  }

  public setLimits(value) {
    this.limitsData = value;

    this.dataProvider.emit({
      type: 'setLimit',
      data: value
    });
  }

  public setEditMode(value) {
    this.editMode = value;
    this.emitResultDates();
  }
  /**
   * return dates array in result event
   */
  public fetchDates() {
    this.emitResultDates();
  }

  public emitResultDates() {
    this.resultDates.emit({
      dates: this.selected,
      activeUnit: this.activeUnit,
      ranges: this.selectedRanges,
      editMode: this.editMode
    });
  }

  private calcCellSize() {
    window.addEventListener('resize', () => {
      if (this.calcSizes()) {
        this._svg.attr('width', this.svgOptions.width).attr('height', this.svgOptions.height);
        this.$weeksContainer.selectAll('*').remove();
        this.initWeeksContainer();
        this.drawControls();
        this.rerenderDaysGrid();
      }
      //
      // this.svg.selectAll('svg').attr('width', 5);
    });

    this.calcSizes();
  }

  private calcSizes() {
    this.svgOptions.width = this.el.getBoundingClientRect().width - this.sizes.marginLeft - this.sizes.marginRight;
    if (this.svgOptions.width <= 0) {
      if (!this.el.firstElementChild.attributes.getNamedItem('width').nodeValue) {
        return false;
      }

      this.svgOptions.width = Number(this.el.firstElementChild.attributes.getNamedItem('width').nodeValue);
    }

    if (this.svgOptions.width > 1210) {
      this.svgOptions.width = 1210;
    }

    const fullWidth = this.svgOptions.width - this.sizes.controlsWidth - this.sizes.marginLeft - this.sizes.marginRight;

    this._cellSize = fullWidth / getCountOfWeeksForCalendar(this.calendarYear);
    this._halfSize = this._cellSize / 2;
    this.svgOptions.height = 7 * this.cellSize + this.sizes.marginTop + this.sizes.weeksContainerHeight + this.sizes.monthsLabelHeight;

    this.svgOptions.weekScale.domain([0, fullWidth]).range([0, getCountOfWeeksForCalendar(this.calendarYear)]);
    this.svgOptions.monthScale.domain([0, fullWidth]).range([0, 12]);
    this.svgOptions.controlsScale.domain([0, this.sizes.controlsWidth]).range([0, 2]);
    return true;
  }

  /**
   * Spliting into two ranges if we remove day from range
   * @param date
   */
  private splitRanges(date) {
    this.selectedRanges.forEach((elem, index) => {
      if (elem) {
        // If removed day inside
        if (moment(date).diff(elem.rangeStart, 'days') > 0 && moment(date).diff(elem.rangeEnd, 'days') < 0) {
          // split into two ranges
          this.selectedRanges.splice(index + 1, 0, {
            rangeStart: moment(date).add(1, 'd'),
            rangeEnd: elem.rangeEnd,
            days: []
          });

          // find removed day in range
          const removedIndex = this.selectedRanges[index].days.findIndex(day => {
            return moment(day.date).diff(moment(date).add(1, 'd'), 'minutes') === 0;
          });

          // If found then move right part of the range into new range
          if (removedIndex !== -1) {
            for (let i = removedIndex; i < this.selectedRanges[index].days.length; i++) {
              this.selectedRanges[index + 1].days.push(this.selectedRanges[index].days[i]);
            }

            // and crop current range
            this.selectedRanges[index].days.splice(removedIndex, this.selectedRanges[index].days.length);
            this.selectedRanges[index].days.splice(removedIndex - 1, this.selectedRanges[index].days.length);
          }

          elem.rangeEnd = moment(date).add(-1, 'd');
          return false;
        } else {
          if (moment(date).diff(elem.rangeEnd, 'days') === 0 && moment(date).diff(elem.rangeStart, 'days') === 0) {
            this.selectedRanges.splice(index, 1);
            return false;
          } else {
            if (moment(date).diff(elem.rangeEnd, 'days') === 0) {
              this.selectedRanges[index].rangeEnd = moment(date).add(-1, 'd');
              this.selectedRanges[index].days.splice(this.selectedRanges[index].days.length - 1, 1);
              return false;
            } else if (moment(date).diff(elem.rangeStart, 'days') === 0) {
              this.selectedRanges[index].rangeStart = moment(date).add(1, 'd');
              this.selectedRanges[index].days.splice(0, 1);
              return false;
            }
          }
        }
      }
    });
  }

  /**
   * Recalculation all ranges if selected new day
   * @param newDate
   */
  private recalc(newDate) {
    let recalced = false;
    const date = moment(newDate.date);
    this.selectedRanges.forEach((elem, index) => {
      if (elem) {
        // Union ranges if they touch
        if (elem.rangeStart.diff(date, 'days') === 0 && elem.rangeEnd.diff(date, 'days') === 0) {
          recalced = true;
        } else if (elem.rangeStart.diff(date, 'days') === 1 || elem.rangeStart.diff(date, 'days') === 0) {
          elem.rangeStart = date;
          elem.days.unshift(newDate);
          // recalc left part
          this.recalcRanges(index, 'prev');
          // recalc right part
          this.recalcRanges(index, 'next');
          recalced = true;
        } else if (date.diff(elem.rangeEnd, 'days') === 1 || date.diff(elem.rangeEnd, 'days') === 0) {
          elem.rangeEnd = date;
          elem.days.push(newDate);
          // recalc left part
          this.recalcRanges(index, 'prev');
          // recalc right part
          this.recalcRanges(index, 'next');
          recalced = true;
        } else if (elem.rangeStart.diff(date, 'days') < 0 && elem.rangeEnd.diff(date, 'days') > 0) {
          recalced = true;
        }
      } else {
        recalced = true;
      }
    });

    // If we cannot do union ranges (we haven't boundary ranges) then we add new range
    if (!recalced) {
      this.selectedRanges.push({
        rangeStart: moment(newDate.date),
        rangeEnd: moment(newDate.date),
        days: [newDate]
      });

      this.selectedRanges = _sortBy(this.selectedRanges, el => {
        return el.rangeStart.unix();
      });
    }
  }

  private getSelectedDay(): Day {
    const [x, y] = d3.mouse(this._gridContainer);
    const xPosition = Math.ceil(x / this._cellSize) - 1;
    const yPosition = Math.floor(y / this._cellSize);
    const offset = 7 - this.weeks[0].weekDays.length;

    return this.days[xPosition * 7 - offset + yPosition];
  }

  // Checking neighboring ranges
  private recalcRanges(currentIndex, checkDirection) {
    let concated = false;
    if (checkDirection === 'next' && this.selectedRanges[currentIndex + 1]) {
      if (this.selectedRanges[currentIndex].rangeEnd.diff(this.selectedRanges[currentIndex + 1].rangeStart, 'days') === -1) {
        this.selectedRanges[currentIndex + 1].days.forEach(date => {
          this.selectedRanges[currentIndex].days.push(date);
        });

        this.selectedRanges[currentIndex].rangeEnd = this.selectedRanges[currentIndex + 1].rangeEnd;

        this.selectedRanges.splice(currentIndex + 1, 1);
      }
      concated = true;
    } else if (checkDirection === 'prev' && this.selectedRanges[currentIndex - 1]) {
      if (this.selectedRanges[currentIndex].rangeStart.diff(this.selectedRanges[currentIndex - 1].rangeEnd, 'days') === 0) {
        this.selectedRanges[currentIndex].days.forEach(date => {
          this.selectedRanges[currentIndex - 1].days.push(date);
        });

        this.selectedRanges[currentIndex - 1].rangeEnd = this.selectedRanges[currentIndex].rangeEnd;

        this.selectedRanges.splice(currentIndex, 1);
      }
      concated = true;
    }
    return concated;
  }

  private brush_select(x1, y1, x2, y2) {
    const self = this;
    this.$gridContainer.selectAll('.day-cell').classed('brushed', function () {
      const c = d3
        .select(this)
        .attr('transform')
        .replace('translate(', '')
        .replace(')', '')
        .split(',')
        .map(function (v) {
          return parseFloat(v);
        });
      const x = c[0];
      const y = c[1];
      const j = x + self.cellSize;
      const k = y + self.cellSize;

      return (x >= x1 || j >= x1) && (x2 >= x || x2 >= j) && (y >= y1 || k >= y1) && (y2 >= y || y2 >= k);
    });

    const days = this.$gridContainer.selectAll('.day-cell.brushed');

    if (days.size() > 1) {
      this._brushReached = true;
    }
  }

  private brush_apply() {
    const self = this;
    const days = this.$gridContainer.selectAll('.day-cell.brushed');

    const daysObjects = [];

    if (days.size() > 1) {
      days.each(function (day) {
        const dayIndex = d3.select(this).attr('day-index');
        if (!self.days[dayIndex].disabled) {
          self.days[dayIndex].setSelection(true, false);
          daysObjects.push(self.days[dayIndex]);
          self.addSelectedDate(self.days[dayIndex]);
          self.invalidSelectionFn && self.invalidSelectionFn(null);
        } else {
          self.invalidSelectionFn && self.invalidSelectionFn(self.days[dayIndex]);
        }
      });

      this.dateChanges.emit({
        type: 'group_add',
        changedDates: daysObjects.reduce((acc, day) => {
          acc.push(day.date);
          return acc;
        }, []),
        ranges: this.selectedRanges,
        activeUnit: this.activeUnit
      });
    }

    days.classed('brushed', false);
  }
}
