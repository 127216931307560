import { BaseModel } from '../base.model';
import { COLOR_PICKER_DEFAULT } from '@constants';
import { ServerStatus, CommentTypes } from '@enums';
import { getISODateOnlyString } from '@helpers';

export class CommentModel extends BaseModel {
  public message: string;
  public color: string;
  public serverStatus: ServerStatus = ServerStatus.OK;
  public correspondingEmployeeId: number;
  public typeId: number;
  public correspondingDay: string;
  public scheduleId: number;

  constructor(data: any = {}) {
    super(data);
    this.message = data.message || '';
    this.color = data.color || COLOR_PICKER_DEFAULT;
    this.correspondingEmployeeId = data.correspondingEmployeeId || null;
    this.typeId = data.typeId || null;
    this.correspondingDay = getISODateOnlyString(data.correspondingDay);
  }

  static defaultColor() {
    return COLOR_PICKER_DEFAULT;
  }
}
