import { IMatrix } from '../matrix.interfaces';
import { Col } from './col';
import { MatrixGroupDataModel } from '../matrix-group-data.model';
import { IConfig } from '../utils/interfaces';

import * as d3 from 'd3';
import { ICols } from './cols.interface';

export class Cols implements ICols {

  get container() {
    return this.$container;
  }
  public cols: Col[] = [];
  private $container: d3.Selection<any, any, any, any>;

  constructor(private matrix: IMatrix) {
    this.$container = this.initColsGroup();
  }

  public render() {
    this.renderCols();
    this.renderControls();
  }

  public renderCol(data: any) {
    const type = data.type;
    const group = this.getGroupForCol(type);
    const col = this.getCurrentCol(type, data[group.key]);

    if (col) {
      // editing;
      col.title = data[group.title];
      col.rerender();

    } else {
      // new col;
      const config = {
        index: this.cols.length,
        key: data[group.key],
        title: data[group.title],
        color: group.color,
        type
      };
      this.createCol(config);
      this.matrix.grid.redrawLayout();

      // redraw lines for rows
      this.matrix.rows.rows.forEach(row => row.drawLine());

      this.renderControls();
    }
  }

  public deleteCol(data: any) {
    const group = this.getGroupForCol(data.type);
    const col = this.getCurrentCol(data.type, data[group.key]);
    col.destroyWithTranslation();
    this.matrix.grid.redrawLayout();

    this.renderControls();
  }

  /*
   * Get current col (supposed position)
   */
  public dragCol(y: number) {
    y += this.matrix.cellSize / 2;
    return this.cols.find((item) => item.y <= y && item.offsetY >= y);
  }

  private initColsGroup() {
    return this.matrix.svg
      .append('g')
      .attr('class', 'matrix-cols')
      .attr('transform', () => {
        const translate = this.matrix.cellSize + this.matrix.titleSize;
        return `translate(${translate}, ${translate}) rotate(-90)`;
      });
  }

  private renderControls() {
    if (this.matrix.colShowAddBtn) {
      this.renderColAddBtn();
    }
  }

  private renderColAddBtn() {
    this.$container.select('.matrix-add-btn').remove();

    const button = this.$container
      .append('g')
      .attr('class', 'matrix-add-btn')
      .on('click', () => this.matrix.colAddClick.emit(this));

    const rect = button.append('rect')
      .attr('width', this.matrix.buttonSize)
      .attr('height', this.matrix.cellSize)
      .attr('x', 0)
      .attr('y', () => this.matrix.availableColsCount * this.matrix.cellSize + 4);

    button.append('text')
      .text('Add')
      .style('text-anchor', 'middle')
      .attr('alignment-baseline', 'central')
      .attr('x', +rect.attr('x') + this.matrix.buttonSize / 2)
      .attr('y', +rect.attr('y') + this.matrix.halfCellSize);
  }

  private renderCols() {
    this.$container.selectAll('*').remove();
    this.cols.length = 0;

    this.matrix.data.cols.forEach((group: any) => {
      const length = this.cols.length;

      group.data.forEach((data: any, ind: number) => {
        const config: IConfig = {
          index: ind + length,
          key: data[group.key],
          title: data[group.title],
          color: group.color,
          type: group.type
        };

        this.createCol(config);
      });
    });

    this.renderControls();
  }

  private createCol(config: IConfig) {
    const col = new Col(this.matrix, this, config, {click: this.matrix.colTitleClick});
    col.render();
    this.cols.push(col);
  }

  private getGroupForCol(type: string): MatrixGroupDataModel {
    return this.matrix.data.cols.find((el: any) => el.type === type);
  }

  private getCurrentCol(type: string, key: any): Col {
    return this.cols
      .filter((el: Col) => el.type === type)
      .find((el: Col) => el.key === key);
  }

}
