import { DateRange } from '@shared/index';

export interface EmployeeWithAllReferences {
  id: number;
  firstName: string;
  middleName: string;
  lastName: string;
  personalPresentation: string;
  workPhone: string;
  mobilePhone: string;
  workEmail: string;
  personalEmail: string;
  licenses: IdAndTitlePair[];
  endorsements: IdAndTitlePair[];
  skills: {
    [skillId: string]: {
      id?: number;
      dates: DateRange;
    }[];
  };
  groups: {
    [groupId: string]: {
      id?: number;
      dates: DateRange;
    }[];
  };
  workPercentages: WorkPercentage[];
  healthyRotation: boolean;
  hardWorkDurationMax: number;
  hardWeeklyWorkMax: number;
  hardAfterShiftBreak: number;
  hardConsecutiveWeeklyOff: number;
  hardNightShiftOK: boolean;
  maxHourPerWeek: number;
  standardWorkingHoursPerWeek: number;
  workingDaysInRow: number;
  unit: string;
  group: number;
  preferencesId: number;
  averagingPeriodOffsets: any;
}

export interface WorkPercentage {
  id: number;
  dateFrom: string;
  value: number;
}

export class IdAndTitlePair {
  constructor(
    public id: number,
    public title: string
  ) {}
}
