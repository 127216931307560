import * as d3 from 'd3';

import { xmlns } from '@shared/helpers/d3';
import { BaseModel } from '@shared/models';

import { Day } from '../calendar';
import { ScheduleTimeline } from '../timeline';


export abstract class Activity<ModelType extends BaseModel> {

  protected el: SVGElement;
  protected $el: d3.Selection<SVGElement, any, any, any>;

  protected _day: Day;

  constructor(protected _model: ModelType,
              protected _timeline: ScheduleTimeline) {
    this.el = document.createElementNS(xmlns, 'g');
    this.$el = d3.select(this.el).attr('class', 'request');
  }

  get id() {
    return this.model.id;
  }

  get model() {
    return this._model;
  }

  set day(day: Day) {
    this._day = day;
  }

  get day() {
    return this._day;
  }

  get timeline() {
    return this._timeline;
  }

  get defs() {
    return this.timeline.defs;
  }

  get sizes() {
    return this.timeline.sizes;
  }

  get storage() {
    return this.timeline.storage;
  }

  get element() {
    return this.$el;
  }

  get node() {
    return this.el;
  }

}
