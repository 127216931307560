<div class="widget" [class.editable]="checkState('edit')">
  <div class="header" [class.minimized]="checkState('minimize')">
    <p>
      <span class="header-text" *ngIf="heading">{{ heading }}</span>
    </p>

    <div class="multi-menu" *ngIf="checkState('edit')">
      <a class="menu-btn">
        <span class="circle idle" [class.disabled]="disableSaveBtn">
          <i class="material-icons" *ngIf="checkState('edit')" (click)="save()"> done </i>
        </span>
        <span class="circle idle">
          <i class="material-icons" *ngIf="checkState('edit')" (click)="cancel()"> clear </i>
        </span>
      </a>
    </div>

    <div class="single-menu" *ngIf="checkState('menu') || checkState('minimize')">
      <a #actionMenu class="menu-btn">
        <span class="circle idle">
          <span class="burger-btn" *ngIf="checkState('menu')" (click)="openMenu()">
            <i></i>
            <i></i>
            <i></i>
          </span>
          <i class="material-icons" *ngIf="checkState('minimize')" (click)="changeState('menu')"> vertical_align_bottom </i>
        </span>
      </a>
    </div>
  </div>
  <pl-tooltip #actionMenuTooltip [config]="widgetActionMenu">
    <div class="tooltip-content">
      <ul>
        <li *ngFor="let action of actionsForMenu" (click)="selectAction(action.action || action.actions[action.activeAction].action); toggle(action)">
          <ng-template [ngIf]="!action.type">
            <span class="material-icons">{{ action.icon }}</span>
            <span class="item-name">{{ action.name }}</span>
          </ng-template>
          <ng-template [ngIf]="action.type === 'toggle'">
            <span class="material-icons">{{ action.actions[action.activeAction].icon }}</span>
            <span class="item-name">{{ action.actions[action.activeAction].name }}</span>
          </ng-template>
        </li>
      </ul>
    </div>
  </pl-tooltip>
  <div class="widget-content" [@minimize]="checkState('minimize')" [ngClass]="{ minimized: checkState('minimize') }">
    <ng-content></ng-content>
  </div>
</div>
