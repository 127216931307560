import {
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';

import { timer as timerObservable, Subscription } from 'rxjs';

import { InternalTooltipReference } from './tooltip.reference';
import {
  ITooltipConfig,
  TooltipConfig,
  TooltipPlacement,
} from './tooltip.config';


@Component({
  selector: 'pl-tooltip-dynamic',
  template: `
    <div
      #tooltip
      class="arrow-container"
      [class.full]="arrowFull"
      [style]="arrowContainerStyles">
      <div
        [class.arrow]="!arrowFull"
        [class.arrowFull]="arrowFull"
        [style]="arrowStyles"
        [style.borderColor]="borderColor"
        [style.backgroundColor]="backgroundColor">
      </div>
    </div>
  `,
})
export class TooltipDynamicComponent implements OnInit, OnDestroy {

  @ViewChild('tooltip', { read: ViewContainerRef, static: true })
  public tooltipTarget: ViewContainerRef;

  @HostBinding('class') classes: string;
  @HostBinding('class.open') visible = false;
  @HostBinding('class.fixed') fixed = false;

  @HostBinding('style.top.px') top = 0;
  @HostBinding('style.left.px') left = 0;
  @HostBinding('style.width.px') width: number;

  @HostBinding('style.zIndex') zIndex: number;

  @HostBinding('style.min-width.px')
  get minWidth() {
    return this._config.width || this._config.targetElement.width;
  }

  @HostBinding('style.borderColor')
  get borderColor() {
    return this._config.styles && this._config.styles.borderColor;
  }

  @HostBinding('style.backgroundColor')
  get backgroundColor() {
    return this._config.styles && this._config.styles.backgroundColor;
  }

  public placement: TooltipPlacement;
  public arrowFull = false;
  public arrowStyles: {
    width?: string;
    height?: string;
  };
  public arrowContainerStyles: {
    width?: string; maxWidth?: string;
    height?: string; maxHeight?: string;
    margin?: string;
  };

  private _timer: Subscription;

  private _offset = 0;
  private _anchor = { top: 0, left: 0 };
  private _boundaries = { top: 0, right: 0, bottom: 0, left: 0 };

  private _tooltipClientRect: DOMRect;

  constructor(
    private _element: ElementRef,
    @Inject(TooltipConfig) private _config: ITooltipConfig,
    private _internalTooltipRef: InternalTooltipReference,
  ) { }

  public ngOnInit() {
    this._offset = this._config.offset || 0;

    void new Promise<void>((resolve) => {
      setTimeout(() => {
        this._tooltipClientRect = this._element.nativeElement.getBoundingClientRect();
        resolve();
      });
    }).then(() => {
      this.countPlacement();
      this.countShape(this.placement);
      this.countArrowStyles(this.placement);

      this.classes = `${this.placement} ${this._config.classes || ''}`;
      this.fixed = this._config.fixed;
      this.width = this._config.width;
      this.top = this._anchor.top;
      this.left = this._anchor.left;
      this.zIndex = this._config.zIndex;
      this.arrowFull = this._config.arrowFull;

      setTimeout(() => {
        this.visible = true;
        this._internalTooltipRef.open();
      });
    });
  }

  public ngOnDestroy() {
    this.destroyAutoclose();
  }

  @HostListener('document:mousemove', ['$event'])
  public documentMouseMove(event: MouseEvent) {
    if (!this._config.autoclose) { return; }

    if (
      this.visible
      && this.checkMouseOutside(event)
      && this.checkMouseOutsideTargetElement(event)
      && !this._element.nativeElement.contains(event.target)
    ) {
      this.initAutoclose();
    } else {
      this.destroyAutoclose();
    }
  }

  @HostListener('document:click', ['$event'])
  public close(event: MouseEvent) {
    if (
      this.visible
      && this.checkMouseOutside(event)
      && this.checkMouseOutsideTargetElement(event)
      && !this._element.nativeElement.contains(event.target)
    ) {
      this.visible = false;
      this._internalTooltipRef.close();
    }
  }

  private checkMouseOutside(event: MouseEvent) {
    const { top, right, bottom, left } = this._element.nativeElement.getBoundingClientRect();

    return event.clientX < left
      || event.clientX > right
      || event.clientY < top
      || event.clientY > bottom;
  }

  private checkMouseOutsideTargetElement(event: MouseEvent) {
    const targetEl = this._config.targetElement;

    const leftSide = targetEl.left;
    const rightSide = leftSide + targetEl.width;
    const top = targetEl.top;
    const bottom = top + targetEl.height;

    return event.clientX < leftSide
      || event.clientX > rightSide
      || event.clientY < top
      || event.clientY > bottom;
  }

  private initAutoclose() {
    if (!this._config.autoclose) { return; }

    this._timer = timerObservable(this._config.autoclose)
      .subscribe(() => {
        this.visible = false;
        this._internalTooltipRef.close();
      });
  }

  private destroyAutoclose() {
    if (this._timer) {
      this._timer.unsubscribe();
    }
  }

  private countPlacement() {
    const currentPlacement = this._config.placement;
    const autoPlacement = this._config.autoPlacement;
    let placement = currentPlacement;

    if (!autoPlacement) {
      this.placement = placement;
      this._internalTooltipRef.placement = this.placement;

      return;
    }

    const { top, right, bottom, left } = this.checkPlacement(currentPlacement);

    switch (currentPlacement) {
      case 'top': {
        if (top && !left) {
          placement = 'top-left';
        } else if (top && !right) {
          placement = 'top-right';
        } else if (!top && !left) {
          placement = 'bottom-left';
        } else if (!top && !right) {
          placement = 'bottom-right';
        } else if (!top) {
          placement = 'bottom';
        }
        break;
      }
      case 'top-left': {
        if (top && !right) {
          placement = 'top-right';
        } else if (!top && !right) {
          placement = 'bottom-right';
        } else if (!top) {
          placement = 'bottom-left';
        }
        break;
      }
      case 'top-right': {
        if (top && !left) {
          placement = 'top-left';
        } else if (!top && !left) {
          placement = 'bottom-left';
        } else if (!top) {
          placement = 'bottom-right';
        }
        break;
      }
      case 'bottom': {
        if (bottom && !left) {
          placement = 'bottom-left';
        } else if (bottom && !right) {
          placement = 'bottom-right';
        } else if (!bottom && !left) {
          placement = 'top-left';
        } else if (!bottom && !right) {
          placement = 'top-right';
        } else if (!bottom) {
          placement = 'top';
        }
        break;
      }
      case 'bottom-left': {
        if (bottom && !right) {
          placement = 'bottom-right';
        } else if (!bottom && !right) {
          placement = 'top-right';
        } else if (!bottom) {
          placement = 'top-left';
        }
        break;
      }
      case 'bottom-right': {
        if (bottom && !left) {
          placement = 'bottom-left';
        } else if (!bottom && !left) {
          placement = 'top-left';
        } else if (!bottom) {
          placement = 'top-right';
        }
        break;
      }
      case 'right': {
        if (right && !top) {
          placement = 'right-top';
        } else if (right && !bottom) {
          placement = 'right-bottom';
        } else if (!right && !top) {
          placement = 'left-top';
        } else if (!right && !bottom) {
          placement = 'left-bottom';
        } else if (!right) {
          placement = 'left';
        }
        break;
      }
      case 'right-top': {
        if (right && !bottom) {
          placement = 'right-bottom';
        } else if (!right && !bottom) {
          placement = 'left-bottom';
        } else if (!right) {
          placement = 'left-top';
        }
        break;
      }
      case 'right-bottom': {
        if (right && !top) {
          placement = 'right-top';
        } else if (!right && !top) {
          placement = 'left-top';
        } else if (!right) {
          placement = 'left-bottom';
        }
        break;
      }
      case 'left': {
        if (left && !top) {
          placement = 'left-top';
        } else if (left && !bottom) {
          placement = 'left-bottom';
        } else if (!left && !top) {
          placement = 'right-top';
        } else if (!left && !bottom) {
          placement = 'right-bottom';
        } else if (!left) {
          placement = 'right';
        }
        break;
      }
      case 'left-top': {
        if (left && !bottom) {
          placement = 'left-bottom';
        } else if (!left && !bottom) {
          placement = 'right-bottom';
        } else if (!left) {
          placement = 'right-top';
        }
        break;
      }
      case 'left-bottom': {
        if (left && !top) {
          placement = 'left-top';
        } else if (!left && !top) {
          placement = 'right-top';
        } else if (!left) {
          placement = 'right-bottom';
        }
        break;
      }
    }

    this.placement = placement;

    this._internalTooltipRef.placement = this.placement;
  }

  private checkPlacement(placement: TooltipPlacement) {
    this.countShape(placement);

    return {
      top: this._boundaries.top > 0,
      right: this._boundaries.right < window.innerWidth,
      bottom: this._boundaries.bottom < window.innerHeight,
      left: this._boundaries.left > 0,
    };
  }

  private countShape(placement: TooltipPlacement) {
    const clientRect = this._tooltipClientRect;
    const { top, left, width, height } = this._config.targetElement;

    this._anchor = { top, left };

    switch (placement) {
      case 'top': {
        this._anchor.left += (width / 2);

        this._boundaries.top = this._anchor.top - clientRect.height;
        this._boundaries.right = this._anchor.left + clientRect.width / 2;
        this._boundaries.bottom = this._anchor.top;
        this._boundaries.left = this._anchor.left - clientRect.width / 2;
        break;
      }
      case 'top-left': {
        this._anchor.left -= this._offset;

        this._boundaries.top = this._anchor.top - clientRect.height;
        this._boundaries.right = this._anchor.left + clientRect.width;
        this._boundaries.bottom = this._anchor.top;
        this._boundaries.left = this._anchor.left;
        break;
      }
      case 'top-right': {
        this._anchor.left += width + this._offset;

        this._boundaries.top = this._anchor.top - clientRect.height;
        this._boundaries.right = this._anchor.left;
        this._boundaries.bottom = this._anchor.top;
        this._boundaries.left = this._anchor.left - clientRect.width;
        break;
      }
      case 'right': {
        this._anchor.top += (height / 2);
        this._anchor.left += width;

        this._boundaries.top = this._anchor.top - clientRect.height / 2;
        this._boundaries.right = this._anchor.left + clientRect.width;
        this._boundaries.bottom = this._anchor.top + clientRect.height / 2;
        this._boundaries.left = this._anchor.left;
        break;
      }
      case 'right-top': {
        this._anchor.left += width;
        this._anchor.top -= this._offset;

        this._boundaries.top = this._anchor.top;
        this._boundaries.right = this._anchor.left + clientRect.width;
        this._boundaries.bottom = this._anchor.top + clientRect.height;
        this._boundaries.left = this._anchor.left;
        break;
      }
      case 'right-bottom': {
        this._anchor.top += height + this._offset;
        this._anchor.left += width;

        this._boundaries.top = this._anchor.top - clientRect.height;
        this._boundaries.right = this._anchor.left + clientRect.width;
        this._boundaries.bottom = this._anchor.top;
        this._boundaries.left = this._anchor.left;
        break;
      }
      case 'bottom': {
        this._anchor.top += height;
        this._anchor.left += (width / 2);

        this._boundaries.top = this._anchor.top;
        this._boundaries.right = this._anchor.left + clientRect.width / 2;
        this._boundaries.bottom = this._anchor.top + clientRect.height;
        this._boundaries.left = this._anchor.left - clientRect.width / 2;
        break;
      }
      case 'bottom-left': {
        this._anchor.top += height;
        this._anchor.left -= this._offset;

        this._boundaries.top = this._anchor.top;
        this._boundaries.right = this._anchor.left + clientRect.width;
        this._boundaries.bottom = this._anchor.top + clientRect.height;
        this._boundaries.left = this._anchor.left;
        break;
      }
      case 'bottom-right': {
        this._anchor.top += height;
        this._anchor.left += width + this._offset;

        this._boundaries.top = this._anchor.top;
        this._boundaries.right = this._anchor.left;
        this._boundaries.bottom = this._anchor.top + clientRect.height;
        this._boundaries.left = this._anchor.left - clientRect.width;
        break;
      }
      case 'left': {
        this._anchor.top += (height / 2);

        this._boundaries.top = this._anchor.top - clientRect.height / 2;
        this._boundaries.right = this._anchor.left;
        this._boundaries.bottom = this._anchor.top + clientRect.height / 2;
        this._boundaries.left = this._anchor.left - clientRect.width;
        break;
      }
      case 'left-top': {
        this._anchor.top -= this._offset;

        this._boundaries.top = this._anchor.top;
        this._boundaries.right = this._anchor.left;
        this._boundaries.bottom = this._anchor.top + clientRect.height;
        this._boundaries.left = this._anchor.left - clientRect.width;
        break;
      }
      case 'left-bottom': {
        this._anchor.top += height + this._offset;

        this._boundaries.top = this._anchor.top - clientRect.height;
        this._boundaries.right = this._anchor.left;
        this._boundaries.bottom = this._anchor.top;
        this._boundaries.left = this._anchor.left - clientRect.width;
        break;
      }
    }

    if (!this.fixed) {
      this._anchor.top += window.scrollY;
      this._anchor.left += window.scrollX;
    }
  }

  private countArrowStyles(placement: TooltipPlacement) {
    const clientRect = this._tooltipClientRect;

    switch (placement) {
      case 'top':
      case 'bottom': {
        if (this._config.arrowFull) {
          this.arrowStyles = {
            width: `${this._config.targetElement.width}px`,
          };
        }

        break;
      }
      case 'top-left':
      case 'top-right':
      case 'bottom-left':
      case 'bottom-right': {
        this.arrowContainerStyles = {
          width: `${this._config.targetElement.width}px`,
          maxWidth: `${clientRect.width || this._config.targetElement.width}px`,
          margin: `0 ${this._offset}px`,
        };

        if (this._config.arrowFull) {
          this.arrowStyles = {
            width: `${this._config.targetElement.width}px`,
          };
        }

        break;
      }
      case 'left':
      case 'right': {
        if (this._config.arrowFull) {
          this.arrowStyles = {
            height: `${this._config.targetElement.height}px`,
          };
        }

        break;
      }
      case 'left-top':
      case 'left-bottom':
      case 'right-top':
      case 'right-bottom': {
        this.arrowContainerStyles = {
          height: `${this._config.targetElement.height}px`,
          maxHeight: `${clientRect.height || this._config.targetElement.height}px`,
          margin: `${this._offset}px 0`,
        };

        if (this._config.arrowFull) {
          this.arrowStyles = {
            height: `${this._config.targetElement.height}px`,
          };
        }

        break;
      }
    }
  }

}
