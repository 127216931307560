import { Injectable } from '@angular/core';
import { ShiftStatus } from '@enums';
import { Activity } from '@model/rostr/activity.model';

@Injectable({
  providedIn: 'root'
})
export class ShiftsState {
  private readonly _assignedActivitiesStatuses = new Map<number, Map<string, ShiftStatus>>();
  private readonly _assignedActivitiesAvailability = new Map<number, Map<string, boolean>>();
  private readonly _masterplanStatuses = new Map<number, Map<string, ShiftStatus>>();
  private readonly _vacayStatuses = new Map<number, Map<string, Map<number, ShiftStatus>>>();
  private readonly _vacayAvailableStatuses = new Map<number, Map<string, ShiftStatus>>();
  private readonly _agendaStatuses = new Map<number, Map<string, Map<number, ShiftStatus>>>();
  private _shiftCodeColors = new Map<number, string>();

  public allTimestamps: string[] = [];

  get assignedActivitiesStatuses(): Map<number, Map<string, ShiftStatus>> {
    return this._assignedActivitiesStatuses;
  }

  get assignedActivitiesAvailability(): Map<number, Map<string, boolean>> {
    return this._assignedActivitiesAvailability;
  }

  get masterplanStatuses(): Map<number, Map<string, ShiftStatus>> {
    return this._masterplanStatuses;
  }

  get vacayStatuses(): Map<number, Map<string, Map<number, ShiftStatus>>> {
    return this._vacayStatuses;
  }

  get vacayAvailableStatuses(): Map<number, Map<string, ShiftStatus>> {
    return this._vacayAvailableStatuses;
  }

  get agendaStatuses(): Map<number, Map<string, Map<number, ShiftStatus>>> {
    return this._agendaStatuses;
  }

  get shiftCodeColors(): Map<number, string> {
    return this._shiftCodeColors;
  }

  set shiftCodeColors(colors: Map<number, string>) {
    this._shiftCodeColors = colors;
  }

  public initializeStatusesForGivenEmployee(employeeId: number): void {
    this.assignedActivitiesStatuses.set(employeeId, new Map<string, ShiftStatus>());
    this.assignedActivitiesAvailability.set(employeeId, new Map<string, boolean>());
    this.masterplanStatuses.set(employeeId, new Map<string, ShiftStatus>());
    this.vacayStatuses.set(employeeId, new Map<string, Map<number, ShiftStatus>>());
    this.vacayAvailableStatuses.set(employeeId, new Map<string, ShiftStatus>());
    this.agendaStatuses.set(employeeId, new Map<string, Map<number, ShiftStatus>>());
  }

  public cleanUp(): void {
    this.assignedActivitiesStatuses.clear();
    this.assignedActivitiesAvailability.clear();
    this.masterplanStatuses.clear();
    this.vacayStatuses.clear();
    this.vacayAvailableStatuses.clear();
    this.agendaStatuses.clear();
    this.shiftCodeColors.clear();
  }

  public getColorForActivity(activity: Activity): string {
    return this.shiftCodeColors.get(activity.shiftCodeId ?? activity.id);
  }
}
