import { Injectable } from '@angular/core';
import { HistoryState } from '../state/history.state';
import { ScheduleHistoryAPIService } from '../api/services/schedule-history-api.service';
import { first, tap } from 'rxjs/operators';
import { AuthenticationService } from '@auth';
import { DialogService } from '@services';
import { Observable } from 'rxjs';
import { ScheduleHistoryEntry } from '../dto';

@Injectable({ providedIn: 'root' })
export class HistoryFacade {
  private scheduleId: number;

  constructor(
    private readonly historyState: HistoryState,
    private readonly scheduleHistoryApi: ScheduleHistoryAPIService,
    private readonly authService: AuthenticationService,
    private readonly dialogService: DialogService
  ) {}

  public readonly historyEntries$: Observable<ScheduleHistoryEntry[]> = this.historyState.history$;
  public readonly isHistoryLoading$: Observable<boolean> = this.historyState.isHistoryLoading$;
  public readonly hasAnythingToUndo$: Observable<boolean> = this.historyState.hasAnythingToUndo$;
  public readonly hasAnythingToRedo$: Observable<boolean> = this.historyState.hasAnythingToRedo$;

  public loadHistory(scheduleId: number): void {
    this.scheduleId = scheduleId;
    this.scheduleHistoryApi
      .getScheduleHistory(scheduleId)
      .pipe(first())
      .subscribe(history => this.historyState.setHistory(history));
  }

  onScheduleHistoryUpdated$ = (scheduleId: number) =>
    this.scheduleHistoryApi.onScheduleHistoryUpdated$(scheduleId).pipe(
      tap(liveResult => {
        this.historyState.setIsHistoryLoading(false);
        this.historyState.setHistory(liveResult);
      })
    );

  public applyHistoryBatch(batch: number) {
    this.historyState.setIsHistoryLoading(true);
    return this.scheduleHistoryApi.applyHistoryBatch(batch, this.scheduleId).pipe(first()).subscribe();
  }

  public async undo() {
    if (this.historyState.isHistoryLoading || !this.historyState.hasAnythingToUndo) {
      return;
    }

    const doneEntries = this.historyState.entriesToUndo;

    if (doneEntries[doneEntries.length - 1].createdBy.id !== this.authService.user.id) {
      const confirm = await this.dialogService.confirm('Undo operation', 'This operation was not performed by you. Do you still want to proceed?');

      if (!confirm) {
        return;
      }
    }

    const doneBatches = Array.from(new Set(doneEntries.map(x => x.batch)?.sort((a, b) => b - a)));

    if (doneBatches.length > 1) {
      this.applyHistoryBatch(doneBatches[1]);
    } else {
      this.applyHistoryBatch(0);
    }
  }

  public async redo() {
    if (this.historyState.isHistoryLoading || !this.historyState.hasAnythingToRedo) {
      return;
    }

    const undoneEntries = this.historyState.entriesToRedo;

    if (undoneEntries[0].createdBy.id !== this.authService.user.id) {
      const confirm = await this.dialogService.confirm('Redo operation', 'This operation was not performed by you. Do you still want to proceed?');

      if (!confirm) {
        return;
      }
    }

    const lastBatchEntry = undoneEntries[0];
    this.applyHistoryBatch(lastBatchEntry.batch);
  }

  public cleanUp() {
    this.historyState.cleanUp();
  }
}
