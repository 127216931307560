import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { ErrorService } from '@services';
import { catchError, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ScheduleEventsService {
  constructor(
    private readonly apollo: Apollo,
    private readonly errorService: ErrorService
  ) {}

  public getScheduleEvents(id: number): Observable<ScheduleEvent> {
    return this.apollo
      .subscribe({
        query: scheduleEventsSubscription,
        variables: {
          scheduleId: id
        }
      })
      .pipe(map((response: { data: { scheduleEvents: ScheduleEvent } }) => response.data.scheduleEvents))
      .pipe(
        catchError((err: Error) => {
          return this.errorService.handle(err);
        })
      );
  }
}

export interface ScheduleEvent {
  type: string;
  scheduleId: number;
  payload: any;
}

const scheduleEventsSubscription = gql`
  subscription scheduleEvents($scheduleId: Int) {
    scheduleEvents(scheduleId: $scheduleId) {
      type
      scheduleId
      payload
    }
  }
`;
