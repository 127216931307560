<div class="comments-container">
  <div class="comments-components" id="comments-components">
    <div class="no-comments" *ngIf="!comments.length && !showCommentInputCreate">
      <div>No comments</div>
      <span>Please click "Comment" to add a comment</span>
    </div>
    <sh-comment-list #commentList [comments]="comments" (selectCommentToEdit)="selectCommentToEdit()" [callback]="commentActionCallback">
    </sh-comment-list>

    <ng-template [ngIf]="showCommentInputCreate">
      <div class="comment-text-view">
        <sh-comment-input-create
          #commentInputCreate
          [showColorSelection]="showColorSelection"
          [callback]="commentActionCallback"
          (editingFinished)="switchCommentInputVisibility()"
        >
        </sh-comment-input-create>
      </div>
    </ng-template>
  </div>

  <div class="tooltip-footer">
    <button (click)="addComment()" class="add-button" [disabled]="showCommentInputCreate || isInEditMode">
      <div class="icon">
        <i class="material-icons">create</i>
      </div>
      <span class="tooltip-comment">Comment</span>
    </button>
    <button type="button" (click)="close()" class="close-button">Close</button>
  </div>
</div>
