import { gql } from 'apollo-angular';

const fragments = {
  holiday: gql`
    fragment HolidayInfo on Holiday {
      id
      name
      type
      active
      everyYear
      weekday
      date
    }
  `,
};

export const holidaysByYear = gql`
  query holidaysByYear($year: String!) {
    holidaysByYear(year: $year) {
      ...HolidayInfo
    }
  }
  ${fragments.holiday}
`;

export const bulkHolidayOperation = gql`
  mutation bulkHolidayOperation(
    $created: [HolidayInput],
    $updated: [HolidayInput],
    $deleted: [Int]
  ) {
    bulkHolidayOperation(
      created: $created,
      updated: $updated,
      deleted: $deleted
    ) {
      created {
        ...HolidayInfo
      }
      updated {
        ...HolidayInfo
      }
      deleted {
        id
      }
    }
  }
  ${fragments.holiday}
`;

export const loadHolidaysQuery = gql`
  query loadHolidays {
    holidays {
      id
      name
      type
      active
      everyYear
      weekday
      date
    }
  }
`;
