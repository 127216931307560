import { EventEmitter } from '@angular/core';

import { ITooltipConfig, ITooltipStyles, TooltipPlacement } from './tooltip.config';


export class InternalTooltipReference {

  public placement: TooltipPlacement;
  public onOpen = new EventEmitter<void>();
  public onClose = new EventEmitter<void>();
  public closeCallback: () => void;

  private _tooltipRef: TooltipReference;

  constructor(private _config: ITooltipConfig) {
    this._tooltipRef = new TooltipReference(this);
  }

  get config() {
    return this._config;
  }

  get tooltipRef() {
    return this._tooltipRef;
  }

  public open() {
    this.onOpen.emit();
  }

  public close(payload = void 0) {
    this.closeCallback();
    this.onClose.emit(payload);

    this.onOpen.complete();
    this.onClose.complete();
  }

  public updateStyles(styles: ITooltipStyles) {
    this._config.styles = styles;
  }

}


export class TooltipReference {

  constructor(private _internaleRef: InternalTooltipReference) {}

  get onOpen() {
    return this._internaleRef.onOpen;
  }

  get onClose() {
    return this._internaleRef.onClose;
  }

  get placement() {
    return this._internaleRef.placement;
  }

  public close(payload = void 0) {
    this._internaleRef.close(payload);
  }

  public updateStyles(styles: ITooltipStyles) {
    this._internaleRef.updateStyles(styles);
  }

}
