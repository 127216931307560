import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-ack-dialog',
  templateUrl: './ack-dialog.component.html',
  styleUrls: ['./ack-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AckDialogComponent implements OnInit {
  // dialog's title
  public title = '';
  // dialog's message
  public message = '';

  constructor(
    public dialogRef: MatDialogRef<AckDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { title: string; message: string }
  ) {
    this.dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.title = this.data.title;
    this.message = this.data.message;
  }

  close(event: any) {
    event.stopPropagation();
    this.dialogRef.close(true);
  }
}
