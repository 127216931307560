export const ENVIRONMENTS = {
  LOCAL: 'local',
  DEV: 'dev',
  STAGE: 'stage'
};

export enum MODULES {
  VACAY = 'vacay',
  AGENDA = 'agenda',
  ARGUS = 'argus',
  PLATFORM = 'entity',
  ROSTR = 'rostr',
  SETTINGS = 'settings',
  PLANNING = 'planning'
}
