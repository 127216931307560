import { gql } from 'apollo-angular';

export const loadMasterplan = gql`
  query loadMasterplan($id: Int!) {
    masterplan(id: $id) {
      id
      title
      data
    }
  }
`;

export const saveMasterplan = gql`
  mutation saveMasterplan($id: Int!, $data: JSON) {
    updateMasterplan(id: $id, data: $data) {
      id
    }
  }
`;
