import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { TooltipContext, TooltipReference } from '../../tooltip';
import { CommentModel } from '@models';
import * as moment from 'moment-mini';
import { CommentTypes } from '@enums';

@Component({
  selector: 'sh-comment-tooltip',
  templateUrl: './comment-tooltip.component.html',
  styleUrls: ['./comment-tooltip.component.scss']
})
export class CommentTooltipComponent implements OnInit {
  public comments: CommentModel[] = [];
  public correspondingDay: moment.Moment;
  public correspondingEmployeeId: number;
  public commentType: CommentTypes;
  public updateCommentIndicatorCallback: (response: boolean) => any;

  constructor(
    private tooltipReference: TooltipReference,
    @Inject(TooltipContext) private tooltipContext: any,
  ) {}

  public ngOnInit() {
    this.comments = this.tooltipContext.comments;
    this.correspondingDay = this.tooltipContext.correspondingDay;
    this.correspondingEmployeeId = this.tooltipContext.correspondingEmployeeId;
    this.commentType = this.tooltipContext.commentType;
    this.updateCommentIndicatorCallback = this.tooltipContext.callback;
  }

  public closeCommentTooltip() {
    this.tooltipReference.close();
  }

  public updateCommentIndicator(event) {
    this.updateCommentIndicatorCallback(event);
  }
}
