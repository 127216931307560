import { BaseModel } from '../base.model';
import { UnitModel } from '../companies/unit.model';
import { SectorModel } from '../sectors/sector.model';

export class EndorsementModel extends BaseModel {
  public title: string;

  public unit: UnitModel;
  public sectors: SectorModel[];

  constructor(data: any = {}) {
    super(data);

    this.title = data.title || '';

    this.unit = (data.unit && new UnitModel(data.unit)) || undefined;
    this.sectors = (data.sectors && data.sectors.map(el => new SectorModel(el))) || [];
  }
}
