import * as moment from 'moment-mini';
import { AssignedGroupModel, AssignedEmployeeModel } from '@shared/models';
import { dateFromMomentUTC, parseUsingUTCZeroOffsetFormat } from '@shared/helpers/date';
import { BaseModel } from '../base.model';
import { GroupModel, UnitModel } from '../companies';
import { AvaiJobStatus } from '@model/avai';
import { VacayScheduleStatusModel } from '@model/vacay/vacay-schedule-status.model';
import { ScheduleState } from './schedule-state.model';
import { ScheduleResponseData } from '@app/vacay/_shared/interfaces/schedule-response-data';
import { VacayStatusResponseData } from '@app/vacay/_shared/interfaces/vacay-status-response-data';

export class ScheduleModel extends BaseModel {
  public name: string;
  public description: string;

  public isCurrent: boolean;
  public isUpcoming: boolean;
  public isScheduleLockedInVacay: boolean;
  public isScheduleLockedInAgenda: boolean;
  public dateFrom: Date;
  public dateTo: Date;
  public dateFromMoment: moment.Moment;
  public dateToMoment: moment.Moment;
  public draftDeadline: moment.Moment;
  public publishDeadline: moment.Moment;
  public deadline: moment.Moment;
  public groups: GroupModel[];
  public assignedGroups: AssignedGroupModel[];
  public vacayStatuses: VacayScheduleStatusModel[];

  public assignedEmployees: AssignedEmployeeModel[];
  public lockedVacayDays: moment.Moment[];
  public latestJobId: string;
  public latestJobStatus: AvaiJobStatus;
  public latestJobCreatedAt: string;
  public latestJobUpdatedAt: string;
  public state: ScheduleState;

  private _dateFromUnix: number;
  private _dateToUnix: number;

  constructor(
    data: ScheduleResponseData = {
      name: 'Schedule Name',
      description: '',
      dateFrom: undefined,
      dateTo: undefined,
      isCurrent: undefined,
      isUpcoming: undefined,
      isScheduleLockedInVacay: undefined,
      isScheduleLockedInAgenda: undefined,
      lockedVacayDays: undefined,
      groups: undefined,
      assignedGroups: undefined,
      assignedEmployees: undefined,
      masterplan: undefined,
      draftDeadline: undefined,
      publishDeadline: undefined,
      deadline: undefined,
      latestJobId: undefined,
      latestJobStatus: undefined,
      latestJobCreatedAt: undefined,
      latestJobUpdatedAt: undefined,
      vacayStatuses: undefined,
      state: undefined
    }
  ) {
    super(data);

    this.name = data.name;
    this.description = data.description;

    this.isCurrent = Boolean(data.isCurrent);
    this.isUpcoming = Boolean(data.isUpcoming);
    this.isScheduleLockedInVacay = Boolean(data.isScheduleLockedInVacay);
    this.isScheduleLockedInAgenda = Boolean(data.isScheduleLockedInAgenda);

    this.dateFromMoment = data.dateFrom && (moment.isMoment(data.dateFrom) ? data.dateFrom : parseUsingUTCZeroOffsetFormat(data.dateFrom));
    this.dateToMoment = data.dateTo && (moment.isMoment(data.dateTo) ? data.dateTo : parseUsingUTCZeroOffsetFormat(data.dateTo));
    this.dateFrom = dateFromMomentUTC(this.dateFromMoment);
    this.dateTo = dateFromMomentUTC(this.dateToMoment);
    this._dateFromUnix = this.dateFrom && this.dateFrom.getTime();
    this._dateToUnix = this.dateTo && this.dateTo.getTime();

    this.groups = data.groups && data.groups.map(group => new GroupModel(group));
    this.assignedGroups = data.assignedGroups && data.assignedGroups.map(assignedGroup => new AssignedGroupModel(assignedGroup));
    this.assignedEmployees = data.assignedEmployees && data.assignedEmployees.map(employee => new AssignedEmployeeModel(employee));

    this.draftDeadline = data.draftDeadline && parseUsingUTCZeroOffsetFormat(data.draftDeadline);
    this.publishDeadline = data.publishDeadline && parseUsingUTCZeroOffsetFormat(data.publishDeadline);
    this.deadline = data.deadline && parseUsingUTCZeroOffsetFormat(data.deadline);
    this.lockedVacayDays = data.lockedVacayDays && data.lockedVacayDays.map(lockedVacayDay => parseUsingUTCZeroOffsetFormat(lockedVacayDay.day));
    this.latestJobId = data.latestJobId;
    this.latestJobStatus = data.latestJobStatus;
    this.latestJobCreatedAt = data.latestJobCreatedAt;
    this.latestJobUpdatedAt = data.latestJobUpdatedAt;
    this.vacayStatuses =
      data.vacayStatuses?.map((value: VacayStatusResponseData): VacayScheduleStatusModel => {
        const result = new VacayScheduleStatusModel();
        result.employeeId = value.employee.id;
        result.id = value.id;
        result.scheduleId = data.id;
        result.isCompleted = value.isCompleted;
        return result;
      }) || [];
    this.state = data.state;
  }

  get dateFromUnix(): number {
    return this._dateFromUnix;
  }

  get dateToUnix(): number {
    return this._dateToUnix;
  }

  get groupNames(): string {
    return this.groups
      .map(group => group.name)
      .sort()
      .join(', ');
  }

  get unitNames(): string {
    return this.groups
      .map(group => group.unit.name)
      .sort()
      .join(', ');
  }

  get units(): UnitModel[] {
    return this.groups.map(group => group.unit);
  }

  get groupUnitNames(): string {
    return this.groups.map(group => `${group.unit.name}/${group.name}`).join(', ');
  }
}
