import { catchError, map, tap } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';
import { ApolloQueryResult } from '@apollo/client/core';
import { Injectable } from '@angular/core';

import { WSLockModel } from '@models';

import {
  createLockMutation,
  deleteLockMutation,
  getAllLocksByTypeQuery,
  getAllLocksQuery,
  getFullInfoAboutLockCreatorQuery,
  subscriptionOnLockCreation,
  subscriptionOnLockDeleting,
  subscriptionOnLockUpdating,
  updateLockMutation
} from './websockets.queries';
import { LoggingService } from '../logging/logging.service';
import { ErrorService } from '../error/error.service';


@Injectable()
export class WebSocketsService {
  public allLocks: WSLockModel[];

  constructor(
    private readonly apollo: Apollo,
    private readonly logging: LoggingService,
    private readonly errorService: ErrorService
  ) { }

  // Note. So far this function not used anymore, and getAllLocksByType() used instead.
  public getAllLocks() {
    return this.apollo
      .query<any>({
        query: getAllLocksQuery
      })
      .pipe(
        map(
          response => response.data.locks.map(lock => new WSLockModel(lock)),
          catchError(this.createCatchErrorHandler())
        ),
        tap((mappedLocks) => {
          this.logging.info(`getAllLocks - '${mappedLocks?.length || 0} locks'`);
          this.allLocks = mappedLocks;
        })
      );
  }

  public getAllLocksByType(type: string) {
    return this.apollo
      .query<any>({
        query: getAllLocksByTypeQuery,
        variables: {
          type
        }
      })
      .pipe(
        tap((payload) => {
          if (payload?.data?.locks?.length > 0) {
            this.logging.info(`WebSocketsService - getAllLocksByType() - count: ${payload?.data?.locks?.length} locks of type: ${type}, networkStatus: ${payload?.networkStatus}`);
          } else {
            this.logging.info(`WebSocketsService - getAllLocksByType() - no locked plugins of type: ${type}`);
          }
        }),
        map(
          payload => payload.data.locks.map(lock => new WSLockModel(lock)),
          catchError(this.createCatchErrorHandler())
        )
      );
  }

  public createLock(lockInfo: WSLockModel) {
    return this.apollo
      .mutate({
        mutation: createLockMutation,
        variables: {
          id: lockInfo.id, // ReportPlugin.id
          type: lockInfo.type,
          timeout: lockInfo.timeout,
          createdBy: lockInfo.createdBy
        }
      })
      .pipe(
        tap((payload: ApolloQueryResult<any>) => {
          this.logging.info(`Lock of type ${payload?.data?.createLock?.type} CREATED, plugin/lock ID - ${payload?.data?.createLock?.id}`);
        }),
        map(
          (payload: ApolloQueryResult<any>) => new WSLockModel(payload.data?.createLock),
          catchError(this.createCatchErrorHandler())
        )
      );
  }

  public updateLock(lockInfo: WSLockModel) {
    return this.apollo
      .mutate({
        mutation: updateLockMutation,
        variables: {
          id: lockInfo.id, // ReportPlugin.id
          type: lockInfo.type,
          timeout: lockInfo.timeout
        }
      })
      .pipe(
        tap((payload: ApolloQueryResult<any>) => {
          this.logging.info(`Lock of type ${payload?.data?.updateLock?.type} UPDATED, plugin/lock ID - ${payload?.data?.updateLock?.id}`);
        }),
        map(
          (payload: ApolloQueryResult<any>) => new WSLockModel(payload.data?.updateLock),
          catchError(this.createCatchErrorHandler())
        )
      );
  }

  public deleteLock(lockInfo: WSLockModel) {
    return this.apollo
      .mutate({
        mutation: deleteLockMutation,
        variables: {
          id: lockInfo.id, // ReportPlugin.id
          type: lockInfo.type
        }
      })
      .pipe(
        tap((payload: ApolloQueryResult<any>) => {
          this.logging.info(`Lock of type ${payload?.data?.deleteLock?.type} DELETED, plugin/lock ID - ${payload?.data?.deleteLock?.id}`);
        }),
        map(
          (payload: ApolloQueryResult<any>) => new WSLockModel(payload.data?.deleteLock),
          catchError(this.createCatchErrorHandler())
        )
      );
  }

  public subscribeOnLockCreation(type: string, reportId: number) {
    return this.apollo
      .subscribe({
        query: subscriptionOnLockCreation,
        variables: {
          type,
          id: reportId // need to have ReportPlugin.id
        }
      })
      .pipe(
        map((payload: ApolloQueryResult<any>) => {
          this.logging.info(`SubscribeOnLockCreation. Lock was CREATED. Type - ${payload?.data?.lockCreated?.type}, ID - ${payload?.data?.lockCreated?.id}`);
          return new WSLockModel(payload.data.lockCreated);
        }),
        catchError(this.createCatchErrorHandler())
      );
  }

  // WAS NOT USED AT ALL
  public subscribeOnLockUpdating(type: string, pluginAkaLockId: number) {
    return this.apollo
      .subscribe({
        query: subscriptionOnLockUpdating,
        variables: {
          type,
          id: pluginAkaLockId // need to have ReportPlugin.id
        }
      })
      .pipe(
        map((payload: ApolloQueryResult<any>) => {
          this.logging.info(`SubscribeOnLockUpdating. Lock was UPDATED. Element type - ${payload?.data?.lockUpdated?.type}, ID - ${payload?.data?.lockUpdated?.id}`);
          return new WSLockModel(payload.data.lockUpdated);
        }),
        catchError(this.createCatchErrorHandler())
      );
  }

  public subscribeOnLockDeleting(type: string, reportId: number) {
    return this.apollo
      .subscribe({
        query: subscriptionOnLockDeleting,
        variables: {
          type,
          id: reportId // need to have ReportPlugin.id
        }
      })
      .pipe(
        map((payload: ApolloQueryResult<any>) => {
          this.logging.info(`SubscribeOnLockDeleting. Lock was DELETED. Element type - ${payload?.data?.lockDeleted?.type}, ID -  ${payload?.data?.lockDeleted?.id}`);
          return new WSLockModel(payload.data.lockDeleted);
        }),
        catchError(this.createCatchErrorHandler())
      );
  }

  public getFullInfoAboutLockCreator(userId: number) {
    return this.apollo
      .query<any>({
        query: getFullInfoAboutLockCreatorQuery,
        variables: {
          id: userId
        }
      })
      .pipe(
        map(response => {
          const result = response.data.employee;
          return `${result.firstName || ''} ${result.middleName || ''} ${result.lastName || ''}`;
        }),
        catchError(this.createCatchErrorHandler())
      );
  }

  private createCatchErrorHandler() {
    return (error) => {
      // for better traceability log entries from STAGE and PROD logs on Sentry.
      error.name = 'WebSocketServiceError';

      this.logging.error(error);
      return this.errorService.handle(error);
    };
  }
}
