<div class="comment-text-view canEdit" *ngFor="let comment of comments">
  <div class="wrapper" (click)="edit(comment)" [style.color]="textColor" *ngIf="commentToEdit !== comment; else commentInputEdit">
    <div class="overlay" *ngIf="canEditComment(comment)">
      Edit comment
      <span class="material-icons">edit</span>
    </div>
    <p class="server-status">
      <ng-container [ngSwitch]="comment.serverStatus">
        <span *ngSwitchCase="serverStatuses.UPDATING">updating...</span>
        <span *ngSwitchCase="serverStatuses.UPDATE_ERROR">Update error</span>
        <span *ngSwitchCase="serverStatuses.DELETE_ERROR"></span>
        <span *ngSwitchCase="serverStatuses.DELETE_ERROR">Delete error</span>
      </ng-container>
    </p>
    <div class="comment-text">
      <div class="author" *ngIf="comment.createdBy">{{ comment.createdBy.firstName }} {{ comment.createdBy.lastName }}</div>
      <div class="comment">{{ comment.message }}</div>
      <div class="comment-date">
        {{ comment.updatedAt || comment.createdAt | date: 'd MMMM y' }} at {{ comment.updatedAt || comment.createdAt | date: 'H:mm' }}
      </div>
    </div>
  </div>
  <ng-template #commentInputEdit>
    <sh-comment-input-edit
      #commentInput
      [comment]="comment"
      [textColor]="textColor"
      [showColorSelection]="showColorSelection"
      [callback]="callback"
      (editingFinished)="resetCommentToEdit()"
      (commentRemoved)="resetCommentToEdit()"
    >
    </sh-comment-input-edit>
  </ng-template>
</div>
