import * as d3 from 'd3';

import { Subject, fromEvent, merge } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';

import { translate, weekDay } from '@helpers';

import { IBaseRenderer } from './base-renderer.interface';
import { Week } from './week';
import { Month } from './month';
import { WeekDay } from './weekDay';
import { UnavailableDaySeverity } from './datepicker.type';
import { SEVERITY_MAX } from './constants';

export class Day {
  public cellGroup: d3.Selection<any, any, any, any>; // d3 selection for cell group
  public dayCell: d3.Selection<any, any, any, any>; // d3 selection for day cell
  public dayText: d3.Selection<any, any, any, any>; // d3 selection for day text
  public indicator: d3.Selection<any, any, any, any>; // d3 selection for indicator

  public relWeek: Week; // link to related week object
  public relMonth: Month; // link to related month object
  public relDayOfWeek: WeekDay; // link to related day of the week object
  // selection indicator. Will be true only if all of the days selected
  public isSelected = false;
  public isShowDayText = false;
  public isClickable = true; // TODO
  public holidayData;
  public specialDay$ = new Subject<Day>();
  public disabled = false;

  protected el: Element;
  protected $el: d3.Selection<any, any, any, any>;

  private onDestroy$ = new Subject();

  /**
   * @param renderer { Renderer } - the main class
   * @param index { number } - day index
   * @param date { date }
   * @param options { object } - { daySelectEvent: function()<EventEmiter> }
   */
  constructor(private renderer: IBaseRenderer, public index: number, public date: Date, private options?: any) {
    this.isSelected = !!this.options.isSelected;
    this.disabled = options.disabled;
  }

  onDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.specialDay$.complete();
  }

  set month(month: Month) {
    this.relMonth = month;
  }

  set week(week: Week) {
    this.relWeek = week;
  }

  set dayOfWeek(wd: WeekDay) {
    this.relDayOfWeek = wd;
  }

  public render() {
    this.cellGroup = this.drawCellGroup();
    this.dayCell = this.drawDayCell();
    this.dayText = this.drawDayText();
    this.checkLimits();
    this.subscribe();
  }

  public subscribe() {
    this.renderer.dataProvider.subscribe(message => {
      switch (message.type) {
        case 'setLimit':
          {
            this.checkLimits();
          }
          break;

        case 'daysHide':
          {
            if (this.dayText) {
              this.dayText.classed('hidden', !this.renderer.isShowDayText);
            }
          }
          break;

        case 'showHolidays': {
          this.showHolidays(message.payload);
        }
      }
    });
  }

  public highlightAsHoliday(flag) {
    if (this.dayText) {
      this.dayCell.classed('holiday', flag);
    }
  }

  /**
   * Set selection for this day
   * @param status { boolean }
   * @param emitEvent { boolean }
   */
  public setSelection(status: boolean, emitEvent = true) {
    if (!this.renderer.editMode) {
      return;
    }
    if (this.isSelected !== status) {
      this.isSelected = status;

      if (this.dayCell) {
        this.cellGroup.classed('selected', this.isSelected);
        // update selection/highligting for related objects
        this.relWeek.updateSelection();
        this.relMonth.updateSelection(this.isSelected);
        this.relDayOfWeek.updateSelection(this.isSelected);
      }

      this.options.daySelectEvent.emit({ status, e: this, callEmit: emitEvent });
    }
  }

  public setSeverityOfUnavailability(severity?: number) {
    const severities = Object.values(UnavailableDaySeverity);
    const allSeveritiesClasses = severities.join(' ');
    const severityStyleClass = `severity-${severity > SEVERITY_MAX ? 'x' : severity}`;

    if (!this.renderer.editMode) {
      return;
    }

    if (this.dayCell) {
      if (severity) {
        this.cellGroup.classed(severityStyleClass, true);
        this.registerCellEventEmitters();
      } else {
        this.cellGroup.classed(allSeveritiesClasses, false);
        this.unregisterCellEventEmitters();
      }
    }
  }

  private registerCellEventEmitters() {
    const element = this.cellGroup.node();

    merge(fromEvent(element, 'mouseenter'), fromEvent(element, 'mouseleave'))
      .pipe(
        map((event: Event) => event.type as 'mouseenter' | 'mouseleave'),
        takeUntil(this.onDestroy$)
      )
      .subscribe(type => {
        const next = type === 'mouseenter' ? this : null;
        this.specialDay$.next(next);
      });
  }

  private unregisterCellEventEmitters() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.onDestroy$ = new Subject();
    this.specialDay$.next(null);
  }

  private checkLimits() {
    if (!this.renderer.limitsData.limits || !this.renderer.limitsData.limits[this.renderer.calendarYear]) {
      return;
    }
    const elem = this.renderer.limitsData.limits[this.renderer.calendarYear][this.index];
    if (elem) {
      const elemClass = this.renderer.limitsData.handler(elem.limit - elem.used);

      if (!this.indicator && elemClass) {
        this.indicator = this.cellGroup
          .insert('path', 'text')
          .attr('d', 'M 0 0 L 0 7 L 7 0Z')
          .attr('transform', translate(0.4, 0.4))
          .classed('indicator', true);
      }
      if (this.indicator) {
        this.indicator.classed('unavailable', false);
        this.indicator.classed('almost-full', false);
        this.indicator.classed(elemClass, true);
      }
    }
  }

  private showHolidays(holidays) {
    const date = this.date;
    const isSame = holidays.find(day => {
      return day.compare(date);
    });

    if (isSame) {
      this.holidayData = isSame;
      this.renderer.relatedHolidayDays.push(this);
    }
    this.highlightAsHoliday(isSame);
  }
  /**
   * Draw day cell group
   * @returns { * }
   */
  private drawCellGroup(): d3.Selection<any, any, any, any> {
    if (this.renderer.options.clickableHandler && !this.disabled) {
      this.isClickable = this.renderer.options.clickableHandler(this.date);
    }
    return this.renderer.gridContainer
      .append('g')
      .attr('class', 'day-cell')
      .attr('day-index', this.index)
      .classed('clickable', this.isClickable)
      .classed('selected', this.isSelected)
      .attr('transform', () => {
        return ['translate(', this.renderer.svgOptions.weekScale.invert(this.relWeek.index), ',', weekDay(this.date) * this.renderer.cellSize, ')'].join('');
      });
  }

  /**
   * Draw day cell
   * @returns {Selection<Datum>}
   */
  private drawDayCell(): d3.Selection<any, any, any, any> {
    return this.cellGroup.append('rect').attr('class', 'day').attr('width', this.renderer.cellSize).attr('height', this.renderer.cellSize);
  }

  /**
   * Draw day text
   * @returns {Selection<Datum>}
   */
  private drawDayText(): d3.Selection<any, any, any, any> {
    this.cellGroup.selectAll('text').remove();

    return this.cellGroup
      .append('text')
      .attr('alignment-baseline', 'central')
      .attr('text-anchor', 'middle')
      .attr('x', this.renderer.halfSize)
      .attr('y', this.renderer.halfSize)
      .text(() => {
        return d3.timeFormat('%d')(this.date);
      })
      .classed('hidden', !this.isShowDayText);
  }
}
