import { BaseModel } from '../base.model';
import { ShiftAliasModelData } from '@interfaces';

export class ShiftAliasModel extends BaseModel {
  public timeFrom: string;
  public timeTo: string;
  public alias: string;
  public value: string;
  public active: boolean;

  public start: number;
  public window: number;
  public duration: number;
  public type: string;

  constructor(data: ShiftAliasModelData = {}) {
    super(data);

    this.timeFrom = data.timeFrom || '';
    this.timeTo = data.timeTo || '';
    this.alias = data.alias || '';
    this.value = data.value || '';
    this.active = data.active || false;

    this.start = data.start || null;
    this.window = data.window || null;
    this.duration = data.duration || null;
    this.type = data.type || 'sector';
  }

  get isDayOff() {
    return this.type === 'dayOff';
  }

  get isVacation() {
    return this.type === 'vacation';
  }
}
