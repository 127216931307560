import * as moment from 'moment-mini';
import { parseUsingUTCZeroOffsetFormat } from '@shared/helpers/date';


export class MasterplanActivityModel {

  public id: number;
  public code: string;
  public start: number;
  public duration: number;
  public date: moment.Moment;
  public date_from: moment.Moment;
  public date_to: moment.Moment;
  public parentId: number;
  public subsetIndex: number;
  public employee_id: number;
  public data: string;

  constructor(data: any = {}, subsetIndex: number = void 0) {
    this.subsetIndex = subsetIndex;

    this.id = data.id || null;
    this.code = data.code || '';
    this.data = data.data || '';
    this.date = data.iDate && moment(data.iDate.date) || null;
    this.date_from = (data.date_from && parseUsingUTCZeroOffsetFormat(data.date_from)) || void 0;
    this.date_to = (data.date_to && parseUsingUTCZeroOffsetFormat(data.date_to)) || void 0;
    this.employee_id = data.employee_id || null;
    this.parentId = data.parentId || null;
    this.duration = data.duration || 0;
    this.start = data.start || 0;
  }

}
