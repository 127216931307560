export class ShiftsConstellationModel {
  public id: number;
  public name: string;
  public comment: string;
  public shifts: { id: number; color: string; code: string }[];

  constructor(data: any = {}) {
    this.id = data.id;
    this.shifts = data.shifts;
    this.comment = data.comment;
    this.name = data.name;
  }
}
