<mat-form-field class="select-control" appearance="fill">
  <mat-label>{{ label }}</mat-label>
  <mat-chip-grid #chipList *ngIf="multiple" [required]="isRequired()" [errorStateMatcher]="localStateMatcher" [formControl]="control">
    <mat-chip-row *ngFor="let item of value" (removed)="remove(item)" [value]="item">
      <span class="select-control">{{ displayWith(item) }}</span>
      <button matChipRemove>
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip-row>
    <input
      #multiInput
      type="text"
      [formControl]="localFormControl"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      matInput
      [errorStateMatcher]="localStateMatcher"
      autocomplete="off"
      [name]="label"
      [placeholder]="placeholder"
      (blur)="onTouched()"
      (focus)="clearSearch()"
      [readonly]="readonly"
    />
  </mat-chip-grid>

  <input
    *ngIf="!multiple"
    type="text"
    [matAutocomplete]="auto"
    matInput
    [formControl]="localFormControl"
    autocomplete="off"
    (blur)="reset()"
    (focus)="clearSearch()"
    [errorStateMatcher]="localStateMatcher"
    [required]="isRequired()"
    [name]="label"
    [placeholder]="placeholder"
    [readonly]="readonly"
  />
  <mat-autocomplete #auto [displayWith]="displayWith" panelWidth="auto" autoActiveFirstOption>
    <mat-option *ngFor="let item of filteredItems$ | async" [value]="item" [disabled]="itemDisabled(item)"> {{ displayWith(item) }} </mat-option>
  </mat-autocomplete>
</mat-form-field>
