import { Component } from '@angular/core';
import { ErrorPageComponent } from '@component/error-page/error-page.component';

@Component({
  selector: 'app-404',
  templateUrl: './error-404.component.html',
})
export class Error404Component extends ErrorPageComponent {
}

