<mat-progress-bar mode="determinate" color="accent" [value]="loader.progress$ | async" class="top-fixed" *ngIf="loader.progress$ | async"> </mat-progress-bar>

<pl-base-app>
  <router-outlet></router-outlet>
</pl-base-app>

<app-side-bar></app-side-bar>

<app-notifications-popup></app-notifications-popup>

<pl-tooltip-container></pl-tooltip-container>
