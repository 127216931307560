export class ShiftrPluginConfigCellModel {
  public configurationId: number;
  public from: number;
  public to: number;
  public title: string;
  public value: number;

  constructor(data: any = {}) {
    this.configurationId = data.configurationId || null;
    this.from = data.from;
    this.to = data.to;
    this.title = typeof data.title === 'number' ? data.title.toString() : data.title;
    this.value = data.value || 0;
  }

  get size() {
    return this.to - this.from + 1;
  }
}
