import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SideBarWidgetName } from '@shared/enums/sidebar-widget.enum';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SideBarService {
  private localStorageKey = 'sidebarSettings';

  private settings: SideBarSettings = {
    isLocked: false
  };

  private readonly closeIfNotLockedSource = new Subject<void>();
  private readonly sideBarActivatedSource = new Subject<void>();
  private readonly notificationTriggeredSource = new Subject<string>();
  private readonly settingsSource = new Subject<SideBarSettings>();

  public sideBarClosed$ = this.closeIfNotLockedSource.asObservable();
  public sideBarActivated$ = this.sideBarActivatedSource.asObservable();
  public notificationTriggered$ = this.notificationTriggeredSource.asObservable();
  public settingsChanged$ = this.settingsSource.asObservable();

  constructor(private readonly router: Router) {}

  closeIfNotLocked(): void {
    if (!this.isLocked) {
      this.closeIfNotLockedSource.next();
    }
  }

  activateSideBar(widgetName: SideBarWidgetName, parameters: (string | number)[] = []): void {
    this.sideBarActivatedSource.next();
    this.router.navigate([{ outlets: { sidebar: [widgetName, ...parameters] } }]);
  }

  displayNotification(content: string): void {
    this.notificationTriggeredSource.next(content);
  }

  public get isLocked(): boolean {
    return this.settings.isLocked ?? false;
  }

  public setIsLocked(locked: boolean): void {
    this.settings.isLocked = locked;
    this.publishAndSaveSettings();
  }

  public initializeFromLocalStorage() {
    const settingsFromLocalStorage = localStorage.getItem(this.localStorageKey);
    if (settingsFromLocalStorage) {
      this.settings = JSON.parse(settingsFromLocalStorage);
    }
  }

  private publishAndSaveSettings(): void {
    this.settingsSource.next(this.settings);
    localStorage.setItem(this.localStorageKey, JSON.stringify(this.settings));
  }
}

interface SideBarSettings {
  isLocked: boolean;
}
