import { BaseModel } from '../base.model';
import { MedicalSetting } from '../medical';


export class CompanyModel extends BaseModel {

  public name: string;
  public nameShort: string;

  public position: number;
  public type = 'companies';
  public typeSingular = 'company';

  public medicalSetting: MedicalSetting;

  constructor(data: any = {}) {
    super(data);

    this.name = data.name || '';
    this.nameShort = data.nameShort || '';

    this.position = data.position || null;
    this.medicalSetting = (data.medicalSetting && data.medicalSetting.length > 0 && data.medicalSetting[0]) || undefined;
  }

}
