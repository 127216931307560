import { gql } from 'apollo-angular';

export const subscriptionOnLockCreation = gql`
  subscription lockCreated($type: String, $id: Int) {
    lockCreated(type: $type, id: $id) {
      type,
      id,
      createdBy
    }
  }
`;

// Strange, `$id: Int` code WAS HERE since 4 years ago, but not used in 2023 so far.
export const subscriptionOnLockUpdating = gql`
  subscription lockUpdated($type: String, $id: Int) {
    lockUpdated(type: $type, id: $id) {
      type,
      id,
      createdBy
    }
  }
`;

export const subscriptionOnLockDeleting = gql`
  subscription lockDeleted($type: String, $id: Int) {
    lockDeleted(type: $type, id: $id) {
      type,
      id,
      createdBy
    }
  }
`;

export const createLockMutation = gql`
  mutation createLock($type: String!, $id: Int!, $timeout: Float, $createdBy: Int!) {
    createLock(type: $type, id: $id, timeout: $timeout, createdBy: $createdBy) {
      type
      id
      createdBy
    }
  }
`;

export const updateLockMutation = gql`
  mutation updateLock($type: String!, $id: Int!, $timeout: Float) {
    updateLock(type: $type, id: $id, timeout: $timeout) {
      type
      id
      createdBy
    }
  }
`;

export const deleteLockMutation = gql`
  mutation deleteLock($type: String!, $id: Int!) {
    deleteLock(type: $type, id: $id) {
      type
      id
    }
  }
`;

export const getLockQuery = gql`
  query lock($type: String!, $id: Int!) {
    lock(type: $type, id: $id) {
      type
      id,
      createdBy
    }
  }
`;

export const getAllLocksQuery = gql`
  query locks {
    locks {
      type
      id
      createdBy
    }
  }
`;

export const getAllLocksByTypeQuery = gql`
  query locks($type: String) {
    locks(type: $type) {
      type
      id
      createdBy
      timeToLive
    }
  }
`;

export const getFullInfoAboutLockCreatorQuery = gql`
  query loadProfiles($id: Int!) {
    employee(id: $id) {
      id
      firstName
      middleName
      lastName
    }
  }
`;
