import { IRole } from '../interfaces';

export class Role implements IRole {
    public id: number;
    public name: string;
    public description: string;
    constructor(data: IRole = { id: undefined, name: undefined, description: undefined } ) {
        this.id = data.id;
        this.name = data.name;
        this.description = data.description;
    }
}