import { OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ScheduleValidation } from '@app/rostr/overview/dto/schedule-validation';
import { RostrFacade } from '@app/rostr/overview/facades/rostr.facade';
import { StatisticsFacade } from '@app/rostr/overview/facades/statistics.facade';
import { ValidationsFacade } from '@app/rostr/overview/facades/validations.facade';
import { getSeverityColor, getSeverityIcon } from '@shared/helpers/validations-helper';
import { Observable, Subscription } from 'rxjs';
import { first, share } from 'rxjs/operators';
import { ScheduleStatistic } from '@app/rostr/overview/dto/schedule-statistic';

@Component({
  selector: 'app-assigned-activity-sidebar-widget',
  templateUrl: './assigned-activity-sidebar-widget.component.html',
  styleUrls: ['./assigned-activity-sidebar-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssignedActivitySidebarWidgetComponent implements OnInit, OnDestroy {
  routeSub: Subscription;
  validations$: Observable<ScheduleValidation[]>;
  statistics$: Observable<ScheduleStatistic>;

  public getSeverityColor = getSeverityColor;
  public getSeverityIcon = getSeverityIcon;

  public shiftId: string = null;

  // In every widget, constructors should not have complex dependencies as long
  // these would be loaded hierarchically from all modules that have widgets.
  // Copy this comment to every new widget
  constructor(
    private readonly rostrFacade: RostrFacade,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly validationsFacade: ValidationsFacade,
    private readonly statisticsFacade: StatisticsFacade,
    private readonly route: ActivatedRoute
  ) {}

  public ngOnInit(): void {
    this.routeSub = this.route.params.subscribe(paramMap => {
      this.shiftId = paramMap.id === 'undefined' ? null : paramMap.id;

      if (this.shiftId) {
        this.validations$ = this.validationsFacade.getFilteredValidationsByShift$(this.shiftId).pipe(share());
        this.statistics$ = this.statisticsFacade.getStatisticsByShift$(this.shiftId).pipe(share());
      } else {
        this.validations$ = null;
        this.statistics$ = null;
      }

      this.changeDetector.markForCheck();
    });
  }

  public ngOnDestroy(): void {
    this.routeSub?.unsubscribe();
  }

  public toggleValidation(validation: ScheduleValidation): void {
    this.rostrFacade.schedule$.pipe(first()).subscribe(schedule => {
      this.validationsFacade.toggleValidation(validation.checksum, schedule.id).pipe(first()).subscribe();
    });
  }
}
