import { Component, Input, Output, EventEmitter, OnInit, ElementRef } from '@angular/core';
import * as moment from 'moment-mini';

import { RandomColor } from '@classes';

@Component({
  selector: 'sh-year-tab-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() currentYear: number;
  @Input() editMode = false;

  @Output() changeYearClick: EventEmitter<any> = new EventEmitter();

  public tabs = [];
  public blocksCount = 0;
  public containerWidth: number;

  private minBlockWidth = 200;

  constructor(public el: ElementRef) {
    this.containerWidth = document.body.clientWidth - this.minBlockWidth;
    this.blocksCount = Math.floor(this.containerWidth / this.minBlockWidth);
    if (this.blocksCount % 2 === 0) {
      this.blocksCount--;
    }
  }

  public ngOnInit() {
    this.makeData();
  }

  public select(tab = null, isPrev = false) {
    if (tab && tab.current) {
      return;
    }
    let offset = 0;
    if (tab) {
      offset = tab.prev ? -tab.offset : tab.offset;
    } else {
      offset = isPrev ? -1 : 1;
    }
    this.currentYear = this.getYear(offset);
    this.changeYearClick.emit({ year: this.currentYear });

    this.makeData();
  }
  public getColor(offset) {
    if (!offset) {
      return '#f0f0f0';
    }
    return RandomColor.shadeBlendConvert(-offset / 25, '#dadce0');
  }

  private makeData() {
    const data = [];

    const middle = Math.ceil(this.blocksCount / 2) - 1;

    for (let i = 0; i < this.blocksCount; i++) {
      if (i < middle) {
        const offset = middle - i;
        data.push({
          title: this.getYear(-offset),
          prev: true,
          offset
        });
      } else if (i === middle) {
        data.push({
          title: this.currentYear,
          current: true
        });
      } else {
        const offset = i - middle;
        data.push({
          title: this.getYear(offset),
          next: true,
          offset
        });
      }
    }
    this.tabs = data;
  }

  private getYear(offset) {
    return moment(`${this.currentYear + offset}`, 'YYYY').year();
  }
}
