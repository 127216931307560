import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, signal } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { LiveUpdateResult } from '@app/rostr/overview/dto/live-update-result.model';
import { CommentsService } from '@component/comments-tooltip';
import { ConversationPanelComment, CommentType } from '@component/conversation-panel/models';
import { CommentsAPIService } from '@component/conversation-panel/services/comments-api.service';
import { datesComparer, norwegianTextComparer, removeDuplicatesById, sortByNumbers } from '@helpers';
import { ColumnFilterResult, Filter } from '@interfaces';
import { ScheduleAssignedActivityModel, ScheduleModel, VacayRequestModel } from '@models';
import { VacayRequestStatus } from '@shared/enums/vacay-request-status.enum';
import { FilteringService } from '@shared/services/filtering/filtering.service';
import { BehaviorSubject, combineLatest, Subject, Subscription } from 'rxjs';
import { startWith, debounceTime, takeUntil, filter } from 'rxjs/operators';
import { VacayRequestsService } from '../../services/vacay-requests/vacay-requests.service';
import { VacayRequestActionsService } from './services/vacay-request-actions.service';
import { animate, state, style, transition, trigger } from '@angular/animations';

interface FilterItem {
  name: string;
  value: unknown;
}
interface RowItem {
  fullName: string;
  batch: number;
  comment: string;
  date: Date;
  dateTo: Date;
  requestedShift: string;
  approved?: number;
  declined?: number;
  unassigned?: number;
  pending?: number;
  requests: VacayRequestModel[];
}
@Component({
  selector: 'app-vacay-requests',
  templateUrl: './vacay-requests.component.html',
  styleUrls: ['./vacay-requests.component.scss'],
  providers: [FilteringService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('detailExpand', [
      state('collapsed,void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ]
})
export class VacayRequestsComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatPaginator)
  public paginator: MatPaginator;
  @ViewChild(MatSort)
  public sort: MatSort;

  scheduleId: number;

  schedule: ScheduleModel;
  requests: VacayRequestModel[] = [];
  rowItems: RowItem[];
  loading = false;
  commentsLoading = false;
  actionLoading = signal(false);
  title = 'Vacay requests';
  columns = ['fullName', 'batch', 'alias', 'assigned', 'status', 'comment'];
  matDataSource: MatTableDataSource<RowItem> = new MatTableDataSource([]);
  searchControl: FormControl<string> = new FormControl('');

  filterSub: Subscription;
  public expandedElement: RowItem | null;

  private destroy$ = new Subject();

  filteredRequests$ = new BehaviorSubject<RowItem[]>([]);
  statuses: FilterItem[] = [
    { name: 'Unassigned', value: VacayRequestStatus.UNASSIGNED },
    { name: 'Pending', value: VacayRequestStatus.PENDING },
    { name: 'Assigned', value: VacayRequestStatus.APPROVED },
    { name: 'Declined', value: VacayRequestStatus.DECLINED }
  ];
  aliases: FilterItem[] = [];

  constructor(
    private readonly vacayRequestsService: VacayRequestsService,
    private readonly route: ActivatedRoute,
    private readonly filteringService: FilteringService<RowItem>,
    private readonly commentService: CommentsService,
    private readonly commentsApiService: CommentsAPIService,
    private readonly vacayRequestActionsService: VacayRequestActionsService,
    private readonly cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe((params: { schedule: string }) => {
      this.scheduleId = parseInt(params.schedule);
      this.fetchRequests();
      this.subscribeForChanges();
    });

    this.filterSub = combineLatest([this.searchControl.valueChanges.pipe(startWith(''), debounceTime(500)), this.filteredRequests$.asObservable()])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([term, rowItems]: [string, RowItem[]]) => {
        this.matDataSource.data = this.search(term, rowItems);
        this.matDataSource.paginator.firstPage();
        this.sortData(this.sort);
      });

    this.subscribeToComments();
  }

  // group VacayRequests by vacayRequestBatchId
  setupRowItems() {
    const rowItems: RowItem[] = [];
    const batchedRequests = this.requests.filter(request => request.vacayRequestBatchId);
    const unbatchedRequests = this.requests.filter(request => !request.vacayRequestBatchId);

    const batchedRequestBatches = Array.from(new Set(batchedRequests.map(request => request.vacayRequestBatchId))).map(batchId => {
      const vacayRequest = batchedRequests.find(request => request.vacayRequestBatchId === batchId);
      return {
        batchId,
        requests: batchedRequests.filter(request => request.vacayRequestBatchId === batchId),
        comment: vacayRequest.vacayRequestBatch.comment,
        date: vacayRequest.vacayRequestBatch.dateFrom,
        dateTo: vacayRequest.vacayRequestBatch.dateTo
      };
    });

    batchedRequestBatches.forEach(batch => {
      rowItems.push({
        fullName: batch.requests[0].employee.fullName,
        batch: batch.batchId,
        comment: batch.comment,
        requests: batch.requests,
        date: batch.date,
        dateTo: batch.dateTo,
        requestedShift: batch.requests[0].shiftCode.code
      });
    });

    unbatchedRequests.forEach(request => {
      rowItems.push({
        fullName: request.employee.fullName,
        batch: null,
        comment: '',
        requests: [request],
        date: request.date,
        dateTo: null,
        requestedShift: request.shiftAlias.value
      });
    });

    rowItems.forEach(rowItem => this.calculateStatusesCount(rowItem));
    this.rowItems = rowItems;
  }

  hasAnyUnreadComments(row: RowItem): boolean {
    return row.requests.some(request => request.comments.some(comment => !comment.isRead));
  }

  ngAfterViewInit(): void {
    this.matDataSource.paginator = this.paginator;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public expand(event: MouseEvent, rowItem: RowItem) {
    event.stopPropagation();
    if (rowItem.requests.length > 1) {
      this.expandedElement = this.expandedElement === rowItem ? null : rowItem;
    }
  }

  public search(term: string, data: RowItem[]): RowItem[] {
    if (term?.trim().length === 0) {
      return data;
    }

    const searchArray = term
      .toLocaleLowerCase()
      .split(' ')
      .filter(t => !!t);
    return data.filter(item => searchArray.every(i => item.fullName.toLocaleLowerCase().indexOf(i) > -1));
  }

  fetchRequests(): void {
    this.loading = true;
    this.vacayRequestsService.getVacayRequestsForSchedule(this.scheduleId).subscribe(({ requests, schedule }) => {
      this.requests = requests;
      this.schedule = schedule;
      this.extractAliases();
      this.setupRowItems();
      this.applyFilters();
      this.loading = false;
      this.fetchComments();
    });
  }

  fetchComments(): void {
    this.commentsLoading = true;
    this.commentService.fetchCommentsForSchedule(this.scheduleId).subscribe(comments => {
      this.requests.forEach(request => {
        request.comments = comments.filter(comment => {
          return (
            comment.type === CommentType.VACAY && comment.employeeId === request.employee.id && new Date(comment.timestamp).getTime() === request.date.getTime()
          );
        });
      });
      this.commentsLoading = false;
      this.cd.markForCheck();
    });
  }

  approveRequest(request: VacayRequestModel) {
    this.actionLoading.set(true);
    this.vacayRequestActionsService.approveRequest(request, this.scheduleId).subscribe();
  }

  makeRequestPending(request: VacayRequestModel) {
    this.actionLoading.set(true);
    this.vacayRequestActionsService.approveRequest(request, this.scheduleId, false).subscribe();
  }

  approveBatch(event: MouseEvent, row: RowItem) {
    event.stopPropagation();
    this.actionLoading.set(true);
    const requestsToApprove = row.requests;
    this.vacayRequestActionsService.approveMultipleVacayRequests(requestsToApprove, this.scheduleId).subscribe();
  }

  makeBatchPending(event: MouseEvent, row: RowItem) {
    event.stopPropagation();
    this.actionLoading.set(true);
    const requestsToApprove = row.requests;
    this.vacayRequestActionsService.approveMultipleVacayRequests(requestsToApprove, this.scheduleId, false).subscribe();
  }

  clearBatch(event: MouseEvent, row: RowItem) {
    event.stopPropagation();
    this.actionLoading.set(true);
    this.vacayRequestActionsService.clearRequests(row.requests).subscribe();
  }

  lockRequest(request: VacayRequestModel) {
    this.actionLoading.set(true);
    this.vacayRequestActionsService.toggleLockRequest(request).subscribe();
  }

  clearRequest(request: VacayRequestModel) {
    this.actionLoading.set(true);
    this.vacayRequestActionsService.clearRequests([request]).subscribe();
  }

  private subscribeForChanges(): void {
    this.vacayRequestsService
      .onActivitiesChanged$(this.scheduleId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ created, deleted, updated, swapped }) => {
        if (deleted) {
          this.handleDeleted(deleted);
        }
        if (created) {
          this.handleCreated(created);
        }
        if (updated) {
          this.handleUpdated(updated);
        }
        if (swapped) {
          this.handleSwapped(swapped);
        }
        this.actionLoading.set(false);
        this.cd.markForCheck();
      });
  }

  private handleDeleted(deleted: LiveUpdateResult[]) {
    deleted.forEach(item => {
      const affectedRequests = this.requests.filter(request => request.assignedActivity?.id === item.shift.id);
      if (affectedRequests.length) {
        affectedRequests.forEach(request => {
          request.assignedActivity = null;
          this.updateStatusesCountForRowWithRequest(request);
        });
      }
    });
  }

  private handleCreated(created: LiveUpdateResult[]) {
    created.forEach(item => {
      const affectedRequests = this.requests.filter(
        request => request.employee.id === item.shift.employeeId && request.date.getTime() === new Date(item.shift.date).getTime()
      );

      if (affectedRequests.length) {
        affectedRequests.forEach(request => {
          request.assignedActivity = new ScheduleAssignedActivityModel(item.shift);
          this.updateStatusesCountForRowWithRequest(request);
        });
      }
    });
  }

  private handleUpdated(updated: LiveUpdateResult[]) {
    updated.forEach(item => {
      const affectedRequests = this.requests.filter(request => request.assignedActivity?.id === item.shift.id);
      const newRequests = this.requests.filter(
        request => request.employee.id === item.shift.employeeId && request.date.getTime() === new Date(item.shift.date).getTime()
      );

      if (affectedRequests.length) {
        affectedRequests.forEach(request => {
          request.assignedActivity = null;
          this.updateStatusesCountForRowWithRequest(request);
        });
      }
      if (newRequests.length) {
        newRequests.forEach(request => {
          request.assignedActivity = new ScheduleAssignedActivityModel(item.shift);
          this.updateStatusesCountForRowWithRequest(request);
        });
      }
    });
  }

  private handleSwapped(swapped: LiveUpdateResult[]) {
    swapped.forEach(item => {
      const affectedRequests1 = this.requests.filter(request => request.assignedActivity?.id === item.shift.id);
      const affectedRequests2 = this.requests.filter(request => request.assignedActivity?.id === item.shiftToSwapWith.id);
      if (affectedRequests1.length) {
        affectedRequests1.forEach(request => {
          request.assignedActivity = new ScheduleAssignedActivityModel(item.shiftToSwapWith);
          this.updateStatusesCountForRowWithRequest(request);
        });
      }
      if (affectedRequests2.length) {
        affectedRequests2.forEach(request => {
          request.assignedActivity = new ScheduleAssignedActivityModel(item.shift);
          this.updateStatusesCountForRowWithRequest(request);
        });
      }
    });
  }

  private extractAliases(): void {
    this.aliases = removeDuplicatesById(this.requests.map(request => request.shiftAlias).filter(x => x)).map(alias => ({
      name: alias.value,
      value: alias.value
    }));
  }

  public applyFilters(): void {
    const filtered = this.filteringService.filterRecords(this.rowItems);
    this.filteredRequests$.next(filtered);
  }

  public filterStatus(val: ColumnFilterResult<VacayRequestStatus>): void {
    this.filteringService.addFilter({
      property: 'requests',
      items: val.items,
      compareFunction: (requestProp: VacayRequestModel[], filterValue: VacayRequestStatus) => requestProp.some(request => request.requestStatus === filterValue)
    } as Filter<VacayRequestStatus>);

    this.applyFilters();
  }

  public filterAlias(val: ColumnFilterResult<string>): void {
    this.filteringService.addFilter({
      property: 'requestedShift',
      items: val.items,
      compareFunction: (requestProp: string, filterValue: string) => requestProp === filterValue
    } as Filter<string>);

    this.applyFilters();
  }

  sortData(sort: Sort): void {
    const data = this.matDataSource.data;
    this.matDataSource.data = data.sort((a, b) => {
      if (!sort.direction) {
        return datesComparer(a.date, b.date, true) || norwegianTextComparer(a.fullName, b.fullName, true);
      }
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'fullName':
          return norwegianTextComparer(a.fullName, b.fullName, isAsc);
        case 'status':
          return datesComparer(a.date, b.date, isAsc);
        case 'alias':
          return norwegianTextComparer(
            a.requests[0].shiftAlias?.value || a.requests[0].shiftCode.code,
            b.requests[0].shiftAlias?.value || b.requests[0].shiftCode.code,
            isAsc
          );
        case 'assigned':
          return norwegianTextComparer(a.requests[0].assignedActivity?.code || '-', b.requests[0].assignedActivity?.code || '-', isAsc);
        case 'batch':
          return sortByNumbers(a.batch || -1, b.batch || -1, isAsc);
        default:
          return 0;
      }
    });
  }

  subscribeToComments() {
    this.commentsApiService
      .onCommentsCreated$()
      .pipe(
        filter(comments => comments.some(comment => comment.type === CommentType.VACAY)),
        takeUntil(this.destroy$)
      )
      .subscribe(comments => {
        comments
          .filter(comment => comment.type === CommentType.VACAY)
          .forEach(comment => {
            const affectedRequests = this.findAffectedRequests(comment);

            affectedRequests.forEach(affectedRequest => {
              affectedRequest.comments = [...affectedRequest.comments, comment];
              this.cd.markForCheck();
            });
          });
      });

    this.commentsApiService
      .onCommentDeleted$()
      .pipe(takeUntil(this.destroy$))
      .subscribe(comment => {
        const affectedRequests = this.findAffectedRequests(comment);

        affectedRequests.forEach(affectedRequest => {
          affectedRequest.comments = affectedRequest.comments.filter(c => c.id !== comment.id);
          this.cd.markForCheck();
        });
      });

    this.commentsApiService
      .onCommentUpdated$()
      .pipe(takeUntil(this.destroy$))
      .subscribe(comment => {
        const affectedRequests = this.findAffectedRequests(comment);

        affectedRequests.forEach(affectedRequest => {
          const existing = affectedRequest.comments.find(c => c.id === comment.id);

          if (existing) {
            existing.message = comment.message;
            affectedRequest.comments = [...affectedRequest.comments];
          }

          this.cd.markForCheck();
        });
      });
  }

  private findAffectedRequests(comment: ConversationPanelComment): VacayRequestModel[] {
    return this.requests.filter(request => request.employee.id === comment.employeeId && request.date.getTime() === new Date(comment.timestamp).getTime());
  }

  private updateStatusesCountForRowWithRequest(request: VacayRequestModel) {
    const row = this.rowItems.find(rowItem => rowItem.requests.includes(request));
    if (row) {
      this.calculateStatusesCount(row);
    }
  }

  private calculateStatusesCount(row: RowItem) {
    row.approved = 0;
    row.declined = 0;
    row.unassigned = 0;
    row.pending = 0;

    row.requests.forEach(request => {
      switch (request.requestStatus) {
        case VacayRequestStatus.APPROVED:
          row.approved++;
          break;
        case VacayRequestStatus.DECLINED:
          row.declined++;
          break;
        case VacayRequestStatus.UNASSIGNED:
          row.unassigned++;
          break;
        case VacayRequestStatus.PENDING:
          row.pending++;
          break;
      }
    });
  }
}
