import { Apollo } from 'apollo-angular';
import { ApolloQueryResult } from '@apollo/client/core';
import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { UnitModel } from '@models';
import {
  createUnit,
  createUnitSectorRelation,
  createUnitSiteRelation,
  deleteUnit,
  deleteUnitSectorRelation,
  deleteUnitSiteRelation,
  loadUnit,
  loadUnits,
  updateUnit
} from './unit.queries';
import { IBaseCrudService } from '@interfaces';
import { Observable } from 'rxjs';
import { norwegianTextComparer } from '@helpers';

@Injectable({
  providedIn: 'root'
})
export class UnitService implements IBaseCrudService<UnitModel> {
  private data: UnitModel[] = [];

  constructor(private apollo: Apollo) {}

  public load(): Observable<UnitModel[]> {
    return this.apollo.query<any>({ query: loadUnits }).pipe(
      map(response => {
        return response.data.units.map(element => new UnitModel(element)).sort((a, b) => norwegianTextComparer(a.name, b.name, true));
      }),
      tap(mapped => {
        this.data = mapped;
      })
    );
  }

  public loadUnit(id: number): Observable<UnitModel> {
    return this.apollo
      .query<any>({
        query: loadUnit,
        variables: { id }
      })
      .pipe(map(response => new UnitModel(response.data.unit)));
  }

  public create(data: UnitModel) {
    return this.apollo
      .mutate({
        mutation: createUnit,
        variables: {
          name: data.name,
          icao: data.icao,
          abbreviation: data.abbreviation
        }
      })
      .pipe(
        map((response: ApolloQueryResult<any>) => new UnitModel(response.data.createUnit)),
        tap((model: UnitModel) => {
          this.data = [...this.data, model];
        })
      );
  }

  public update(data: UnitModel) {
    return this.apollo
      .mutate({
        mutation: updateUnit,
        variables: {
          id: data.id,
          name: data.name,
          icao: data.icao,
          abbreviation: data.abbreviation
        }
      })
      .pipe(
        map((response: ApolloQueryResult<any>) => new UnitModel(response.data.updateUnit)),
        tap((model: UnitModel) => {
          const unit = this.data.find(el => el.id === model.id);
          Object.assign(unit, model);
        })
      );
  }

  public delete(data: UnitModel) {
    return this.apollo
      .mutate({
        mutation: deleteUnit,
        variables: {
          id: data.id
        }
      })
      .pipe(
        tap((response: ApolloQueryResult<any>) => {
          const res = response.data.deleteUnit;
          this.data = [...this.data.filter(el => el.id !== res.id)];
        }),
        map((response: ApolloQueryResult<any>) => response.data.deleteUnit)
      );
  }

  public addSiteRelation(unit: number, site: number) {
    return this.apollo
      .mutate({
        mutation: createUnitSiteRelation,
        variables: {
          unitId: unit,
          siteId: site
        }
      })
      .subscribe(() => {
        const elem = this.data.find(el => el.id === unit);
        elem.siteId = site;
      });
  }

  public removeSiteRelation(unit: number) {
    return this.apollo
      .mutate({
        mutation: deleteUnitSiteRelation,
        variables: {
          unitId: unit
        }
      })
      .subscribe(() => {
        const elem = this.data.find(el => el.id === unit);
        elem.siteId = null;
      });
  }

  public addSectorToUnit(unit: number, sector: number) {
    return this.apollo
      .mutate({
        mutation: createUnitSectorRelation,
        variables: {
          unitId: unit,
          sectorId: sector
        }
      })
      .subscribe(() => {
        const element = this.data.find(el => el.id === unit);
        element.sectorIds.push(sector);
      });
  }

  public removeSectorFromUnit(unit: number, sector: number) {
    return this.apollo
      .mutate({
        mutation: deleteUnitSectorRelation,
        variables: {
          unitId: unit,
          sectorId: sector
        }
      })
      .subscribe(() => {
        const element = this.data.find(el => el.id === unit);
        const index = element.sectorIds.indexOf(sector);
        element.sectorIds.splice(index, 1);
      });
  }

  public updateSiteRelation(id: number, siteId: number, isSelected: boolean) {
    if (isSelected) {
      this.addSiteRelation(id, siteId);
    } else {
      this.removeSiteRelation(id);
    }
  }

  public updateSectorRelation(id: number, sectorId: number, isSelected: boolean) {
    if (isSelected) {
      this.addSectorToUnit(id, sectorId);
    } else {
      this.removeSectorFromUnit(id, sectorId);
    }
  }
}
