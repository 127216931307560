<div class="wrapper">
  <label class="pl-label" *ngIf="options.label">{{options.label}}</label>
  <input type="text" class="pl-input"
         [placeholder]="options.placeholder"
         [(ngModel)]="searchedText"
         (focus)="focused = true"
         (blur)="lazyBlur()">

  <ul class="autocomplete-content dropdown-content" *ngIf="options.showResults && focused">
    <li class="character-counter"
        *ngIf="options.loading && options.showResults"
    >Searching...</li>
    <li class="character-counter"
        *ngIf="searched && !options.loading && data.length === 0 && options.showResults"
    >Records not found</li>
    <li *ngFor="let res of data" (click)="selectEl(res)">
      <img [src]="res.picture" class="right circle" *ngIf="res.picture">
      <span class="truncate-line" [innerHtml]="options.resultLineHandler(res) | shHighlight:searchedText"></span>
    </li>
  </ul>
</div>

