import { fromEvent as observableFromEvent } from 'rxjs';
import { takeUntil, map, debounceTime, tap } from 'rxjs/operators';
import * as d3 from 'd3';

import { translate } from '@helpers';

import { BaseInterval } from '../../intervals/base.interval';
import { BasePartialHeader } from '../base-partial.header';


interface ITimeHeaderConfig {
  floating: boolean;
}


export class TimeHeader extends BasePartialHeader {

  get height() {
    return 33;
  }

  get config() {
    return this._config;
  }

  protected set floating(value: boolean) {
    this._floating = value;
    this.$el.classed('floating', this.floating);
  }

  protected get floating() {
    return this._floating;
  }

  public intervals: BaseInterval[] = [];

  protected $background: d3.Selection<SVGRectElement, any, any, any>;
  protected $intervals: d3.Selection<SVGGElement, any, any, any>;

  private _config: ITimeHeaderConfig = { floating: true };
  private _floating = false;

  constructor(config?: Partial<ITimeHeaderConfig>) {
    super();

    Object.assign(this._config, config);
  }

  public init() {
    this.intervals = this.grid.allIntervals;
    this.intervals.forEach(interval => interval.timeHeader = this);
  }

  public render() {
    const { width } = this.timeline.sizes();
    const height = this.height;
    const xPos = this.grid.offset();
    const yPos = this.y;

    this.$el.attr('transform', translate(xPos, yPos));

    this.$background
      .attr('x', -xPos)
      .attr('width', width)
      .attr('height', height)
      .attr('filter', this.timeline.defs().floatingHeaderShadow());

    this.intervals.forEach((interval) => {
      interval.renderHeader();
      this.$intervals.node().appendChild(interval.headerNode);
    });
  }

  public subscribe() {
    this.grid.onRenderIntervals
      .pipe(takeUntil(this.timeline.onDestroy))
      .subscribe(() => {
        this.$intervals.selectAll('.interval-header').remove();

        this.init();
        this.render();
      });

    if (this.config.floating) {
      observableFromEvent(window, 'scroll')
        .pipe(
          takeUntil(this.timeline.onDestroy),
          map(() => this.header.node.getBoundingClientRect().top + this.y),
          tap((topBoundary) => {
            this.$el.classed('hide', topBoundary < 0);
            this.updatePosition(topBoundary);
            this.floating = topBoundary < 0;
          }),
          debounceTime(100)
        )
        .subscribe((topBoundary) => {
          if (topBoundary < 0) {
            this.$el.classed('hide', false);
          }
        });
    }
  }

  protected initElements() {
    super.initElements();

    this.$el.classed('time-header', true);

    this.$background = this.$el
      .append<SVGRectElement>('rect')
      .classed('time-header-background', true);

    this.$intervals = this.$el
      .append<SVGGElement>('g')
      .classed('time-header-intervals', true);
  }

  protected updatePosition(offset = 0) {
    const xPos = this.grid.offset();
    let yPos = this.y;

    if (offset < 0) {
      yPos += -offset;
    }

    this.$el.attr('transform', translate(xPos, yPos));
  }

}
