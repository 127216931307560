import { ShiftType } from '../../enums/shift-type';

export class Activity {
  id: number;
  code: string;
  alias: string;
  parentId: number;
  parentType: ShiftType;
  dateFrom: string;
  dateTo: string;
  groupId?: number;
  isGlobal?: boolean;
  shiftCodeId: number;
}
