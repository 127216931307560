import { IMatrix } from '../matrix.interfaces';
import { Col } from '../cols/col';
import { Row } from '../rows/row';
import { Cell } from './cell';

import * as d3 from 'd3';

export class Grid {

  get container() {
    return this.$gridContainer;
  }
  public cells: Cell[] = [];

  private $gridContainer: d3.Selection<any, any, any, any>;
  private $gridRect: d3.Selection<any, any, any, any>;

  constructor(private readonly matrix: IMatrix) {
    this.$gridContainer = this.initGridContainer();
  }

  public render() {
    this.drawSubLayer();
  }

  /*
   * Sublayout for background grid color and clicking area
   */
  public drawSubLayer() {
    this.$gridContainer.select('*').remove();

    this.$gridRect = this.$gridContainer
      .append('rect')
      .style('fill', 'white');

    this.redrawLayout();
  }

  /*
   * Redraw sublayout with current width and height
   */
  public redrawLayout() {
    this.$gridRect
      .attr('width', () => this.matrix.availableColsCount * this.matrix.cellSize )
      .attr('height', () => this.matrix.availableRowsCount * this.matrix.cellSize );
  }

  public renderCells() {
    this.matrix.cols.cols.forEach((colData) => {
      const colIndex: number = colData.index;
      this.matrix.rows.rows.forEach((rowData: any) => {
        const rowIndex: number = rowData.index;
        this.renderCell(colData, rowData, colIndex, rowIndex);
      });
    });
  }

  public renderCell(colData: any, rowData: any, colIndex: number, rowIndex: number) {
    const colKey: any = colData.key;
    const rowKey: any = rowData.key;

    const groupCol = this.matrix.data.cols.find((el: any) => el.type  === colData.type);
    const groupRow = this.matrix.data.rows.find((el: any) => el.type === rowData.type);

    const col = groupCol.data.find((el: any) => el[groupCol.key] === colKey); // current col
    const row = groupRow.data.find((el: any) => el[groupRow.key] === rowKey); // current row

    // get relation field
    const relation = this.matrix.relationPosition === 'row' ? groupRow.relation : groupCol.relation;

    // get relation value
    const rel = this.matrix.relationPosition === 'row' ? col[relation] : row[relation];

    const key = this.matrix.relationPosition === 'row' ? rowKey : colKey;
    const isSelected = rel instanceof Array ? rel.indexOf(key) !== -1 : rel === key ;

    if (isSelected) {
      const config = {
        colKey,
        rowKey,
        colIndex,
        rowIndex
      };
      const cell = new Cell(this.matrix, config, {click: this.matrix.cellClick});
      cell.setSelectionClass(isSelected);
    }
  }

  /*
   * Disable Grid when drag action
   */
  public disableGrid() {
    this.$gridContainer.append('rect')
      .classed('disable', true)
      .attr('width', () => this.matrix.availableColsCount * this.matrix.cellSize )
      .attr('height', () => this.matrix.availableRowsCount * this.matrix.cellSize );
  }

  /*
   * Enable Grid after end drag action
   */
  public enableGrid() {
    this.$gridContainer.select('.disable').remove();
  }

  private clickCell() {

    // get dependent col and row
    const [col, row] = this.getPosition();

    // if col and row exist -> check cell with these dependencies
    if (col && row) {
      const cell: Cell = this.cells
        .find((el: Cell) => el.col.index === col.index && el.row.index === row.index);

      cell ? cell.destroyWithRelations() : this.createCell(col, row);
    }
  }

  private createCell(col: Col, row: Row) {
    const config = {
      colKey: col.key,
      rowKey: row.key,
      colIndex: col.index,
      rowIndex: row.index
    };
    const cell = new Cell(this.matrix, config, {click: this.matrix.cellClick});
    cell.setSelection(true);
    cell.setVisible();
    cell.moveBy(cell.col.offset, cell.row.offset); // scroll
  }

  /*
   * Get col and row depend position of click
   */
  private getPosition(): [Col, Row] {
    const [x, y] = d3.mouse(d3.event.currentTarget);
    const colIndex = Math.floor(x / this.matrix.cellSize) + this.matrix.scrollCol.offset;
    const rowIndex = Math.floor(y / this.matrix.cellSize) + this.matrix.scrollRow.offset;

    const col = this.matrix.cols.cols.find((el: Col) => el.index === colIndex);
    const row = this.matrix.rows.rows.find((el: Row) => el.index === rowIndex);
    return [col, row];
  }

  private initGridContainer() {
    return this.matrix.svg
      .append('g')
      .attr('class', 'matrix-cells')
      .style('cursor', 'pointer')
      .attr('transform', `translate(${this.matrix.offset}, ${this.matrix.offset})`)
      .on('click', () => {
        this.clickCell();
      });
  }

}
