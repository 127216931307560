import { gql } from 'apollo-angular';

export const scheduleStatisticsUpdatedSubscription = gql`
  subscription scheduleStatisticsUpdated($scheduleId: Int) {
    scheduleStatisticsUpdated(scheduleId: $scheduleId) {
      statistics {
        day
        employee
        level
        shift
        week
        values
      }
    }
  }
`;
