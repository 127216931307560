<div class="side-bar" *ngIf="isVisible">
  <div class="header">
    <mat-icon *ngIf="isLocked" fontIcon="lock" (click)="toggleLocked()"></mat-icon>
    <mat-icon *ngIf="!isLocked" fontIcon="lock_open" (click)="toggleLocked()"></mat-icon>
    <mat-icon fontIcon="clear" class="align-right" (click)="close()"></mat-icon>
  </div>
  <div class="content">
    <router-outlet name="sidebar"></router-outlet>
  </div>
  <div class="banner" *ngIf="displayBanner">
    <span>{{ bannerContent }}</span>
    <mat-icon fontIcon="done"></mat-icon>
  </div>
</div>
