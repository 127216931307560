<div matBadge="{{ unreadCount }}" [matBadgeColor]="hasAnyErrorNotification() ? 'warn' : 'primary'" [matBadgeHidden]="unreadCount === 0" matBadgeSize="small">
  <mat-icon class="navbar-icon" [class.has-unread]="unreadCount > 0" [matMenuTriggerFor]="notificationsMenu">{{
    showPopups ? 'notifications' : 'notifications_off'
  }}</mat-icon>
</div>

<mat-menu class="notifications-menu" #notificationsMenu="matMenu" (closed)="markAllAsRead()">
  <div class="container" *ngIf="notifications.length">
    <div class="header">
      <mat-slide-toggle (click)="$event.stopPropagation()" color="primary" [checked]="showPopups" (change)="showPopupsChanged($event)"
        >Show popups</mat-slide-toggle
      >
    </div>
    <div class="scrollable-area">
      <div
        class="notification"
        *ngFor="let notification of notifications"
        [class.read]="notification.isRead"
        [routerLink]="notification.link"
        [class.has-link]="notification.link"
      >
        <div class="notification-wrapper">
          <div class="left-panel">
            <mat-icon [style.color]="convertSeverityIntoColor(notification.severity)">{{ convertSourceIntoIcon(notification.source) }}</mat-icon>
          </div>
          <div class="center-panel">
            <div class="header">
              {{ notification.title }}
            </div>
            <div class="content">
              {{ notification.content }}
            </div>
          </div>
          <div class="right-panel" *ngIf="notification.link">
            <mat-icon>launch</mat-icon>
          </div>
        </div>
        <div class="footer">
          {{ notification.createdAt | date: 'YYYY-MM-dd HH:mm:ss' }}
        </div>
      </div>
    </div>
    <span class="show-all" (click)="openNotificationsSideBar()">Show all notifications</span>
  </div>
  <span class="no-items" *ngIf="!notifications.length">There is no any notification to be shown.</span>
</mat-menu>
