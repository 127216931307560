import { Component, Inject, OnInit } from '@angular/core';

import { TooltipContext, TooltipReference } from '@shared/components/tooltip';
import { Router } from '@angular/router';
import { MODULES } from '@core';

@Component({
  selector: 'pl-nav-tooltip',
  templateUrl: 'nav-tooltip.component.html',
  styleUrls: ['nav-tooltip.component.scss']
})
export class PlatformNavTooltipComponent implements OnInit {
  public position: number;
  public items = [];
  public callback;
  public activeItem;

  constructor(
    private router: Router,
    private tooltipReference: TooltipReference,
    @Inject(TooltipContext) private tooltipContext: any
  ) { }

  public ngOnInit() {
    this.position = this.tooltipContext.position;
    this.items = this.tooltipContext.activeModules || [];
    this.activeItem = this.tooltipContext.activeItem;
    this.callback = this.tooltipContext.callback;
  }

  public select(item) {
    this.callback(item);
    this.tooltipReference.close();
  }

  public onClick(route: any) {
    const url = this.getUrl(route);
    this.router.navigateByUrl(url);
  }

  private getUrl(route: any): string {
    if (route.url) {
      return route.url;
    }
    return route.path !== MODULES.PLATFORM ? `/${route.path}` : '/';
  }
}
