export enum STATS_CATEGORY {
  AssignedDuration = 'assignedDuration',
  Deviation = 'deviationFromAveragingTarget',
  VacayRequests = 'vacayRequests',
  VacayRequestsIgnoreAgenda = 'vacayRequestsIgnoreAgenda',
  CustomPeriod = 'customPeriod',
  // Below are artificial categories, which are not existing on backend site
  CustomPeriodWeekly = 'customPeriodWeekly',
  CustomPeriodMain = 'customPeriodMain',
  Target = 'averagingTarget'
}

export enum CUSTOM_PERIOD_MODE {
  DaysOff = 'daysOff',
  DaysWorked = 'daysWorked',
  Deviation = 'deviationFromAveragingTarget',
  Holidays = 'holidaysCount',
  NightShifts = 'nightShiftsCount',
  PerDay = 'perDay',
  PerWeek = 'perWeek',
  TotalMinutes = 'totalMinutes',
  weekendShifts = 'weekendShiftsCount',
  weekendsWorked = 'weekendsWorkedCount'
}

export enum STATS_MODE {
  AveragePeriod = 'byAveragePeriod',
  Schedule = 'bySchedule',
  Weekly = 'byWeek'
}
