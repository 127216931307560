import { ScheduleValidationSeverity } from '..';

export function getSeverityColor(severity: ScheduleValidationSeverity) {
  switch (severity) {
    case ScheduleValidationSeverity.ERROR: {
      return 'red';
    }
    case ScheduleValidationSeverity.WARNING: {
      return 'orange';
    }
    case ScheduleValidationSeverity.INFO: {
      return 'blue';
    }
  }
}

export function getSeverityIcon(severity: ScheduleValidationSeverity) {
  switch (severity) {
    case ScheduleValidationSeverity.ERROR: {
      return 'error';
    }
    case ScheduleValidationSeverity.WARNING: {
      return 'warning';
    }
    case ScheduleValidationSeverity.INFO: {
      return 'info';
    }
  }
}
