import { CUSTOM_PERIOD_MODE, ICategoryClass, STATS_CATEGORY, STATS_MODE } from '@app/rostr/_shared';
import { EmployeeStatistic } from '@app/rostr/_shared/models/stats/statistic.class';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

export class DisplayedStatisticState {
  protected readonly _category$: BehaviorSubject<STATS_CATEGORY>;
  public readonly category$: Observable<STATS_CATEGORY>;

  protected readonly _mode$: BehaviorSubject<STATS_MODE | CUSTOM_PERIOD_MODE>;
  public readonly mode$: Observable<STATS_MODE | CUSTOM_PERIOD_MODE>;

  public statistics: { [employeeId: number]: BehaviorSubject<EmployeeStatistic> };

  protected readonly _startDate$ = new BehaviorSubject<Date>(new Date());
  public readonly startDate$ = this._startDate$.asObservable();

  protected readonly _endDate$ = new BehaviorSubject<Date>(new Date());
  public readonly endDate$ = this._endDate$.asObservable();

  private readonly _loading$ = new BehaviorSubject<boolean>(false);
  public readonly loading$ = this._loading$.asObservable();

  set endDate(value: Date) {
    this._endDate$.next(value);
  }

  get endDate(): Date {
    return this._endDate$.getValue();
  }

  set startDate(value: Date) {
    this._startDate$.next(value);
  }

  get startDate(): Date {
    return this._startDate$.getValue();
  }

  public set loading(value: boolean) {
    this._loading$.next(value);
  }

  public get loading(): boolean {
    return this._loading$.getValue();
  }

  get category(): STATS_CATEGORY {
    return this._category$.getValue();
  }

  set category(value: STATS_CATEGORY) {
    this._category$.next(value);
  }

  get mode(): STATS_MODE | CUSTOM_PERIOD_MODE {
    return this._mode$.getValue();
  }

  set mode(value: STATS_MODE | CUSTOM_PERIOD_MODE) {
    this._mode$.next(value);
  }

  constructor(mode: STATS_MODE) {
    this.statistics = {};
    this._category$ = new BehaviorSubject(STATS_CATEGORY.AssignedDuration);
    this.category$ = this._category$.asObservable();

    this._mode$ = new BehaviorSubject(mode);
    this.mode$ = this._mode$.asObservable();
  }

  public createEmptyIfNotExist(employeeId: number): void {
    if (!this.statistics[employeeId]) {
      this.statistics[employeeId] = new BehaviorSubject(new EmployeeStatistic());
    }
  }

  public loadData(categoryData: ICategoryClass, employeesIds?: number[]): void {
    const ids = employeesIds ?? Object.keys(this.statistics).map(id => parseInt(id));
    const modeStats = categoryData.getStatsForMode(this.mode);
    ids.forEach(employeeId => {
      const incomingValue = modeStats.getStatsForEmployee(employeeId);
      this.statistics[employeeId].next(incomingValue);
    });
  }

  public getStat$(employeeId: number, key?: string): Observable<string> {
    this.createEmptyIfNotExist(employeeId);
    return this.statistics[employeeId].pipe(
      map((employeeStat: EmployeeStatistic) => {
        return employeeStat.getStat(key);
      }),
      distinctUntilChanged()
    );
  }

  public cleanUp(): void {
    this.statistics = {};
    this._loading$.next(false);
  }
}
