import { Injectable } from '@angular/core';
import { ErrorService } from '@services';
import { Apollo } from 'apollo-angular';
import { Observable, of } from 'rxjs';
import { createAssignedActivities, deleteAssignedActivities, updateAssignedActivities, upsertAssignedActivity } from '../queries/vacay-request-actions.query';
import { VacayRequestModel } from '@models';
import { catchError } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class VacayRequestActionsService {
  constructor(
    private readonly apollo: Apollo,
    private readonly errorService: ErrorService
  ) {}

  public approveRequest(request: VacayRequestModel, scheduleId: number, isLocked = true) {
    return this.upsertAssignedActivity(request, scheduleId, isLocked);
  }

  public approveMultipleVacayRequests(requests: VacayRequestModel[], scheduleId: number, isLocked = true) {
    return this.apollo
      .mutate({
        mutation: createAssignedActivities,
        variables: {
          input: requests.map(vacayRequest => ({
            employee: vacayRequest.employee.id,
            parentType: vacayRequest.type,
            parentId: vacayRequest.id,
            code: vacayRequest.code,
            dateFrom: vacayRequest.dateFrom,
            dateTo: vacayRequest.dateTo,
            schedule: scheduleId,
            data: { isLocked }
          }))
        }
      })
      .pipe(
        catchError(err => {
          return this.errorService.handle(err);
        })
      ) as Observable<void>;
  }

  public toggleLockRequest(request: VacayRequestModel) {
    const activity = request.assignedActivity;

    if (!activity) {
      return;
    }

    return this.apollo
      .mutate({
        mutation: updateAssignedActivities,
        variables: {
          input: [
            {
              id: activity.id,
              data: {
                isLocked: !activity.isLocked,
                _partial: true
              }
            }
          ]
        }
      })
      .pipe(
        catchError(err => {
          return this.errorService.handle(err);
        })
      ) as Observable<void>;
  }

  public clearRequests(requests: VacayRequestModel[]) {
    const ids = requests.map(request => request.assignedActivity?.id).filter(Boolean);
    if (ids.length === 0) {
      return of();
    }

    return this.apollo
      .mutate({
        mutation: deleteAssignedActivities,
        variables: {
          input: ids
        }
      })
      .pipe(
        catchError(err => {
          return this.errorService.handle(err);
        })
      ) as Observable<void>;
  }

  public createLockedAssignedActivityForVacayRequest(request: VacayRequestModel, scheduleId: number, isLocked = true) {
    return this.apollo
      .mutate({
        mutation: createAssignedActivities,
        variables: {
          input: [
            {
              employee: request.employee.id,
              parentType: request.type,
              parentId: request.id,
              code: request.code,
              dateFrom: request.dateFrom,
              dateTo: request.dateTo,
              schedule: scheduleId,
              data: { isLocked }
            }
          ]
        }
      })
      .pipe(
        catchError(err => {
          return this.errorService.handle(err);
        })
      ) as Observable<void>;
  }

  public upsertAssignedActivity(request: VacayRequestModel, scheduleId: number, isLocked = true): Observable<void> {
    return this.apollo
      .mutate({
        mutation: upsertAssignedActivity,
        variables: {
          id: request.id,
          parentId: request.id,
          parentType: request.type,
          employee: request.employee.id,
          code: request.code,
          dateFrom: request.date,
          dateTo: request.date,
          schedule: scheduleId,
          data: { isLocked }
        }
      })
      .pipe(
        catchError(err => {
          return this.errorService.handle(err);
        })
      ) as Observable<void>;
  }
}
