import * as moment from 'moment-mini';
import { parseUsingUTCZeroOffsetFormat } from '../..';

export class ActivityDateModel {

  public from: moment.Moment;
  public to: moment.Moment;

  public date: moment.Moment;
  public start: number;
  public duration: number;

  public snapped: ActivityDateModel;

  constructor(data: any = {}) {
    this.from = (data.from && parseUsingUTCZeroOffsetFormat(data.from)) || undefined;
    this.to = (data.to && parseUsingUTCZeroOffsetFormat(data.to)) || undefined;

    this.date = (data.date && parseUsingUTCZeroOffsetFormat(data.date)) || undefined;
    this.start = data.start || 0;
    this.duration = data.duration || 0;

    this.snapped = (data.snapped && new ActivityDateModel(data.snapped)) || undefined;
  }

}
