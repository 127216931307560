export class UpdateCommentPayload {
  constructor(
    public id: number,
    public correspondingDay: string,
    public correspondingEmployeeId: number,
    public message: string,
    public scheduleId: number,
    public typeId: number
  ) {}
}
