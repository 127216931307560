import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';
import { EmployeeModel, Setting, SettingsKey } from '@models';
import {
  loadByIdsQuery,
  loadEntitySettings,
  loadAllQuery,
  loadWithNamesOnlyQuery,
  loadEmployeeSkillsQuery,
  employeesWithGroupAssignableRoles,
  loadWithNamesAndCurrentGroupQuery
} from './employee.queries';
import { Observable, of } from 'rxjs';
import { LoggingService } from '../logging/logging.service';
import { SkillModel } from '@model/profile/skill.model';
import { norwegianTextComparer } from '@helpers';

@Injectable()
export class EmployeeService {
  constructor(
    private apollo: Apollo,
    private logging: LoggingService
  ) {}

  public loadEmployeeData(): Observable<EmployeeModel[]> {
    return this.apollo.query<any>({ query: loadAllQuery }).pipe(
      map(employeesData => employeesData.data.fullEmployeeList.map(el => new EmployeeModel(el))),
      catchError(loadEmployeesError => {
        this.logging.error(loadEmployeesError);
        return of([]);
      })
    );
  }

  public loadEmployeeSkills(employee: number): Observable<SkillModel[]> {
    return this.apollo
      .query<any>({ query: loadEmployeeSkillsQuery, variables: { id: employee } })
      .pipe(map(skillsData => skillsData.data.employeeSkills.map(skill => new SkillModel(skill))));
  }

  public loadEmployeesWithNamesOnly(): Observable<EmployeeModel[]> {
    return this.apollo.query<any>({ query: loadWithNamesOnlyQuery }).pipe(
      map(employeesData => employeesData.data.employees.map(el => new EmployeeModel(el))),
      catchError(loadEmployeesError => {
        this.logging.error(loadEmployeesError);
        return of([]);
      })
    );
  }

  public loadEmployeesWithNameAndCurrentGroup(): Observable<EmployeeModel[]> {
    return this.apollo.query<any>({ query: loadWithNamesAndCurrentGroupQuery }).pipe(
      map(employeesData => employeesData.data.employees.map(el => new EmployeeModel(el))),
      catchError(loadEmployeesError => {
        this.logging.error(loadEmployeesError);
        return of([]);
      })
    );
  }

  public loadEmployeesDataByIds(ids: number[]): Observable<EmployeeModel[]> {
    return this.apollo.query<any>({ query: loadByIdsQuery, variables: { ids, _deleted: true } }).pipe(
      map(employeesData => employeesData.data.employees.map(el => new EmployeeModel(el))),
      catchError(loadEmployeesError => {
        this.logging.error(loadEmployeesError);
        return of([]);
      })
    );
  }

  public loadWorkPercentageForEmployees(employeeIds: number[]): Observable<Setting[]> {
    return this.apollo.query<any>({ query: loadEntitySettings, variables: { keys: [SettingsKey.EmployeeWorkPercentage] } }).pipe(
      map(request => {
        const workPercentage = request.data.settings[0];

        const entitySettingsDict = workPercentage.entitySettings.reduce((dictionaryUpdate, entitySetting) => {
          dictionaryUpdate.set(entitySetting.entityId, entitySetting);

          return dictionaryUpdate;
        }, new Map());

        const assignedWorkPercentage = employeeIds.map(employeeId => {
          const entitySetting = entitySettingsDict.get(employeeId) || { entityId: employeeId };

          return {
            key: workPercentage.key,
            name: workPercentage.name,
            defaultValue: workPercentage.defaultValue,
            entitySettings: [entitySetting]
          };
        });

        return assignedWorkPercentage.map(el => new Setting(el));
      }),
      catchError(loadEmployeesSettingsError => {
        console.log('error occured', loadEmployeesSettingsError);
        this.logging.error(loadEmployeesSettingsError);
        return of([]);
      })
    );
  }

  public loadEmployeesByRoles() {
    const mapAndSort = list => {
      return list.map(emp => new EmployeeModel(emp)).sort((a, b) => norwegianTextComparer(a.regularFullNameForSorting, b.regularFullNameForSorting, true));
    };
    return this.apollo
      .query<{ resourcePlanner: EmployeeModel[]; agendaAdmin: EmployeeModel[]; unionRepresentative: EmployeeModel[]; rostrReadAccess: EmployeeModel[] }>({
        query: employeesWithGroupAssignableRoles
      })
      .pipe(
        map(data => {
          return {
            resourcePlanner: mapAndSort(data.data.resourcePlanner),
            agendaAdmin: mapAndSort(data.data.agendaAdmin),
            unionRepresentative: mapAndSort(data.data.unionRepresentative),
            rostrReadAccess: mapAndSort(data.data.rostrReadAccess)
          };
        }),
        catchError(error => {
          this.logging.error(error);
          return of({ resourcePlanner: [], agendaAdmin: [], unionRepresentative: [], rostrReadAccess: [] });
        })
      );
  }
}
