import { Observable } from 'rxjs';
import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { ErrorService } from '@services';
import { catchError, map } from 'rxjs/operators';
import { ScheduleStatistic } from '../../dto/schedule-statistic';
import { scheduleStatisticsUpdatedSubscription } from '../queries/schedule-statistics.queries';

@Injectable({ providedIn: 'root' })
export class ScheduleStatisticsAPIService {
  constructor(
    private readonly apollo: Apollo,
    private readonly errorService: ErrorService
  ) {}

  public onScheduleStatisticsUpdated$(scheduleId: number): Observable<ScheduleStatistic[]> {
    return this.apollo
      .subscribe({
        query: scheduleStatisticsUpdatedSubscription,
        variables: {
          scheduleId
        }
      })
      .pipe(
        map(
          (response: { data: { scheduleStatisticsUpdated: { statistics: ScheduleStatistic[] } } }) =>
            response.data.scheduleStatisticsUpdated.statistics.map(x => {
              return { ...x };
            }),
          catchError((err: Error) => {
            return this.errorService.handle(err);
          })
        )
      );
  }
}
