<div class="agenda-info" *ngFor="let agenda of agendaList$ | async">
  <span class="shift">Shift: {{ agenda?.shiftCode }}</span>
  <p class="project">{{ agenda.project }}</p>
  <p class="objective">{{ agenda.objective }}</p>
  <small>{{ agenda.description }}</small>
  <small *ngIf="scheduleState === ScheduleStateEnum.DRAFT || scheduleState === ScheduleStateEnum.DISTRIBUTED">{{
    AgendaDateStatus[agenda.status] | titlecase
  }}</small>
  <small>Created at {{ agenda.createdAt | shMoment: 'DD.MM.YYYY' }} by {{ agenda.createdBy }}</small>
</div>

<div *ngIf="((agendaList$ | async)?.length || 0) === 0">
  <p class="no-agenda-text">No Agenda for a day</p>
</div>
