export const ROTATION_WEEKS: number[] = [1,2,3,4,5,6,7,8,9,10,11,12];

export const HOUR = 60;

export const preferredWorkDuration = [
  { title: 'No preference', min: 4 * HOUR, max: 13 * HOUR }, 
  { title: 'Short', min: 5 * HOUR, max: 7 * HOUR }, 
  { title: 'Medium', min: 7 * HOUR, max: 9 * HOUR }, 
  { title: 'Long', min: 9 * HOUR, max: 12 * HOUR }  
];

export const preferredWorkTime = [
  { title: 'No preference', min: 0 * HOUR, max: 24 * HOUR }, 
  { title: 'Morning', min: 5 * HOUR, max: 14 * HOUR }, 
  { title: 'Day', min: 9 * HOUR, max: 17 * HOUR }, 
  { title: 'Evening', min: 13 * HOUR, max: 21 * HOUR }, 
  { title: 'Night', min: 21 * HOUR, max: 24 * HOUR }
];
