import { InjectionToken, Type } from '@angular/core';


export const TooltipConfig = new InjectionToken<ITooltipConfig>('TooltipConfig');
export const TooltipContext = new InjectionToken('TooltipContext');


export type TooltipPlacement = (
  'top' | 'top-left' | 'top-right' |
  'right' | 'right-top' | 'right-bottom' |
  'bottom' | 'bottom-left' | 'bottom-right' |
  'left' | 'left-top' | 'left-bottom'
);


export interface ITargetElementRect {
  height: number;
  left: number;
  top: number;
  width: number;
}


export interface ITooltipStyles {
  backgroundColor?: string;
  borderColor?: string;
}


export interface  ITooltipConfig {
  targetElement: ITargetElementRect | ClientRect;
  placement: TooltipPlacement;

  width?: number;
  fixed?: boolean;
  styles?: ITooltipStyles;
  classes?: string;
  autoclose?: number;
  autoPlacement?: boolean;
  arrowFull?: boolean;
  offset?: number;
  zIndex?: number;
}

export interface ISimpleTooltipConfig extends ITooltipConfig {
  title?: string;
  message: string;
}

export interface ICustomTooltipConfig extends ITooltipConfig {
  component: Type<any>;
  context?: any;
}
