export enum SideBarWidgetName {
  EmployeeWidget = 'employee-widget',
  EmployeeEditorWidget = 'employee-editor-widget',
  ConstellationManagerWidget = 'constellations-widget',
  Notifications = 'notifications',
  AssignedActivityWidget = 'assigned-activity-widget',
  ScheduleHistoryWidget = 'schedule-history-widget',
  StatsCellWidget = 'stats-cell-widget',
  RequiredShiftWidget = 'required-shift-widget',
  MyPreferences = 'my-preferences',
  DayWidget = 'day-widget'
}
