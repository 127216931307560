import * as moment from 'moment-mini';

import { IBaseModel } from './interfaces';
import { IEmployee } from './auth/employee.interface';
import { Setting } from './settings';

export class BaseModel {

  get createdAt() {
    return this._createdAt;
  }

  set createdAt(value) {
    this._createdAt = (value && moment.utc(value, 'YYYY-MM-DDTHH:mm:ssZ')) || undefined;
  }

  get createdBy(): IEmployee {
    return this._createdBy;
  }

  set createdBy(value) {
    this._createdBy = value;
  }

  get updatedAt(): moment.Moment {
    return this._updatedAt;
  }

  set updatedAt(value) {
    this._updatedAt = (value && moment.utc(value, 'YYYY-MM-DDTHH:mm:ssZ')) || undefined;
  }

  get updatedBy(): IEmployee {
    return this._updatedBy;
  }

  set updatedBy(value: IEmployee) {
    this._updatedBy = value;
  }

  get deletedAt(): moment.Moment {
    return this._deletedAt;
  }

  set deletedAt(value) {
    this._deletedAt = (value && moment.utc(value, 'YYYY-MM-DDTHH:mm:ssZ')) || undefined;
  }
  public id: number;
  public readonly __typename: string;

  protected _createdAt: moment.Moment;
  protected _createdBy: IEmployee;
  protected _updatedAt: moment.Moment;
  protected _updatedBy: IEmployee;

  private _deletedAt: moment.Moment;

  constructor(
    data: IBaseModel = {
      id: undefined,
      createdAt: undefined,
      createdBy: undefined,
      updatedAt: undefined,
      updatedBy: undefined,
      deletedAt: undefined,
      __typename: undefined
    }
  ) {
    this.id = data.id;
    this.createdAt = data.createdAt;
    this.createdBy = data.createdBy;
    this.updatedAt = data.updatedAt;
    this.updatedBy = data.updatedBy;
    this.deletedAt = data.deletedAt;

    this.__typename = data.__typename;
  }

  public clone(twins = [], depth = 0): this {
    const cloneObj = new (this.constructor as any)();
    Object.keys(cloneObj).forEach((attribute) => {

      const twinIndex = twins.indexOf(this[attribute]);

      if (twinIndex === -1) {
        if (this[attribute] instanceof BaseModel) {
          twins.push(this[attribute]);
        }
        cloneObj[attribute] = this.modelCloneDeep(this[attribute], twins, depth);

      } else {
        cloneObj[attribute] = twins[twinIndex];
      }
    });

    if (depth === 0) {
      twins.length = 0;
    }

    return cloneObj;
  }

  /**
   * Needed for updating current object fields if they difference from newData object
   * (without missing links)
   * @param newData
   */
  public setChanges(newData) {
    Object.keys(newData).forEach((attribute) => {
      if (!!this[attribute] && this[attribute] !== newData[attribute]) {
        this[attribute] = this.modelCloneDeep(newData[attribute], [], 0);
      }
    });
  }

  private modelCloneDeep(value, twins, depth) {
    if (Array.isArray(value)) {
      return value.map(item => {
        return this.modelCloneDeep(item, twins, depth);
      });
    }
    if (value !== null && typeof value === 'object') {
      if (value instanceof BaseModel) {
        return value.clone(twins, ++depth);
      }

      if (value instanceof moment) {
        const momentValue = value as moment.Moment;
        return momentValue.clone();
      }

      if (value instanceof Date) {
        return new Date(value);
      }

      if (value instanceof Setting) {
        return new Setting({ ...value });
      }

      const nextValue = new value.constructor();
      Object.keys(value).forEach((attribute) => {
        nextValue[attribute] = this.modelCloneDeep(value[attribute], twins, depth);
      });
      return nextValue;
    }

    return value;
  }

}
