import { SOTRecord } from './sot.record.model';
import { ShiftrPluginConfigCellModel } from './shiftr-plugin-config-cell.model';

export class ReportPluginLineChartData {

  public disabledDates: Date[];
  public records: SOTRecord[];

  constructor(data: any = {}) {
    this.disabledDates = data.disabledDates && data.disabledDates.map(item => new Date(item)) || [];

    this.records = data.records && data.records.map(recordEl => {
      return {
        date: new Date(recordEl.date),
        sectors: recordEl.sectors,
        count: recordEl.count,
        combinations: recordEl.combinations,
        visible: (this.disabledDates.findIndex(el => el.getTime() === new Date(recordEl.date).getTime()) === -1)
      };
    }) || [];
  }

}

export class ReportPluginHeatmapData extends ReportPluginLineChartData {

  constructor(data: any = {}) {
    super(data);
  }

}

export class ReportPluginTextEditorData {

  public html: string;

  constructor(data: any = {}) {
    this.html = data.html || '';
  }

}

export class ReportPluginDemandData {

  public demand: number[];
  public demandLo: number[];

  constructor(data: any = {}) {
    this.demand = data.demand || Array.apply(null, Array(48)).map(() => 0);
    this.demandLo = data.demandLo || Array.apply(null, Array(48)).map(() => 0);
  }

}


export class ReportPluginShiftrData {
  public demand: number[];
  public delivery: string[][];

  public duration: { min: number, max: number };
  public idealShiftLength: number;
  public on: { min: number, max: number };
  public off: { min: number, max: number };
  public selfbrief: number;

  public night: {
    morning: {
      to: number,
      overlap: number,
      count: number
    },
    night: {
      from: number,
      overlap: number,
      count: number
    }
  };

  public algorithm: {
    end: {
      seconds: number,
      secondsUnimproved: number;
    }
  };

  public admin: {
    start: { from: number, to: number },
    on: { min: number, max: number },
    duration: number,
    maxStaffNumber: number
  };

  public configurationRow: ShiftrPluginConfigCellModel[];


  constructor(data: any = {}) {

    this.demand = data.demand || Array.apply(null, Array(48)).map(() => 0);
    this.delivery = data.delivery || [];
    this.duration = data.duration || { min: 12, max: 17 };
    this.idealShiftLength = data.idealShiftLength || 15;
    this.on = data.on || { min: 2, max: 4 };
    this.off = data.off || { min: 1, max: 4 };
    this.selfbrief = data.selfbrief || 20;

    this.night = data.night || {
      morning: {
        to: 12,
        overlap: 1,
        count: 1
      },
      night: {
        from: 44,
        overlap: 0,
        count: 1
      }
    };

    this.algorithm = data.algorithm || {
      end: {
        seconds: 300,
        secondsUnimproved: 180
      }
    };

    this.admin = data.admin || {
      start: { from: 14, to: 20 },
      on: { min: 0, max: 2 },
      duration: 12,
      maxStaffNumber: 2
    };

    this.configurationRow = data.configurationRow;
  }

}
