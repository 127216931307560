import { ApplicationInsights, SeverityLevel, ITelemetryItem } from '@microsoft/applicationinsights-web';
import { environment } from '@environment';
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, NavigationEnd } from '@angular/router';
import { filter, tap } from 'rxjs/operators';
import { ConfigService } from '@core';

@Injectable({
  providedIn: 'root'
})
export class AppInsightsService {
  appInsights: ApplicationInsights;

  constructor(
    private router: Router,
    private configService: ConfigService
  ) {}

  public start() {
    const connectionString = this.configService.config.appInsights || '';
    const instrumentationPart = connectionString.split(';')[0];
    const instrumentationKey = instrumentationPart.split('=')[1];

    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey,
        connectionString,
        enableAutoRouteTracking: true,
        disableTelemetry: instrumentationKey === ''
      }
    });

    this.appInsights.loadAppInsights();
    this.appInsights.addTelemetryInitializer(this.telemetryInitializer);
    this.createRouterSubscription();
  }

  public setUserId(userId: string) {
    this.appInsights.setAuthenticatedUserContext(userId);
  }

  public clearUserId() {
    this.appInsights.clearAuthenticatedUserContext();
  }

  public logPageView(name?: string, url?: string) {
    this.appInsights.trackPageView({ name, uri: url });
  }

  public logVerbose(message: string) {
    this.appInsights.trackTrace({ message, severityLevel: SeverityLevel.Verbose });
  }

  public logInformation(message: string) {
    this.appInsights.trackTrace({ message, severityLevel: SeverityLevel.Information });
  }

  public logWarning(message: string) {
    this.appInsights.trackTrace({ message, severityLevel: SeverityLevel.Warning });
  }

  public logError(message: string) {
    this.appInsights.trackTrace({ message, severityLevel: SeverityLevel.Error });
  }

  public logException(error: Error | HttpErrorResponse) {
    this.logError(error.message);

    if (error instanceof Error) {
      this.appInsights.trackException({ exception: error });
    }
  }

  private createRouterSubscription() {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        tap((event: NavigationEnd) => {
          this.logPageView(null, event.urlAfterRedirects);
        })
      )
      .subscribe();
  }

  private telemetryInitializer(envelope: ITelemetryItem) {
    envelope.tags['ai.cloud.role'] = 'Frontend';
    envelope.tags['ai.cloud.roleInstance'] = environment.env;
  }
}
