<form class="comment-form" [formGroup]="commentForm">
  <div class="author" *ngIf="comment.createdBy">{{ comment.createdBy.firstName }} {{ comment.createdBy.lastName }}</div>
  <textarea
    matInput
    cdkTextareaAutosize="true"
    #contentInput
    name="comment-text"
    [style.borderColor]="textColor"
    [style.color]="textColor"
    formControlName="message"
    rows="1"
  ></textarea>
  <div class="edit-options">
    <div [hidden]="!comment?.id">
      <span class="material-icons comment-icon-background" (click)="deleteComment()">delete</span>
    </div>
    <div #colorPicker [style.color]="textColor" [hidden]="!showColorSelection">
      <span class="clickable" (click)="colorsVisible = !colorsVisible">
        Color
        <span class="material-icons">color_lens</span>
      </span>
      <div class="pl-tooltip bottom colors-container" [class.open]="colorsVisible">
        <div class="arrow-container">
          <div class="arrow"></div>
        </div>
        <div class="mini-color-picker">
          <div class="color" *ngFor="let color of colors; let i = index">
            <input class="color-input" type="radio" formControlName="color" [value]="color" />
            <label [attr.for]="'color-' + i" [style.background]="color"></label>
          </div>
        </div>
      </div>
    </div>
    <div class="save">
      <button type="button" mat-flat-button (click)="closeEditing()" class="cancel-button">Cancel</button>
      <button type="button" mat-flat-button [disabled]="!commentForm.valid" (click)="updateComment()" class="primary-button">Save</button>
    </div>
  </div>
</form>
