import { Input, Component, ElementRef, Output, OnChanges, EventEmitter, forwardRef } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Renderer } from './renderer';
import { HolidaysService } from '@services';
import { TooltipReference, TooltipService, ITooltipConfig } from '../tooltip';
import * as moment from 'moment-mini';
import { DatepickerTooltipComponent } from './tooltip/datepicker.tooltip.component';
import { AgendaUnavailability } from '@app/agenda/_shared';
import { Day } from './day';
import { UnavailabilityTooltipEvent } from './unavailability-tooltip-event.interface';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DatepickerComponent),
  multi: true
};

@Component({
  selector: 'sh-datepicker',
  template: ` <svg></svg> `,
  styleUrls: ['datepicker.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class DatepickerComponent implements OnChanges, ControlValueAccessor {
  @Input() deleteDate;
  @Input() settings;
  @Input() controlEmiter;
  // Controls if the datepicker will display the temporary changes as blue tiles (true), or if it should be reset back to initial view (false)
  @Input() editMode = false;
  @Input() disableDayFn: (day: moment.Moment) => boolean;
  @Input() invalidSelectionFn: (day: Day) => boolean;
  @Output() resultDates = new EventEmitter();
  @Output() dateChanges = new EventEmitter();
  @Output() rendererReadyEvt = new EventEmitter();

  public helpers: any = {};
  public renderer: Renderer;
  public tooltip: TooltipReference;
  public unavailabilityTooltipEvt = new EventEmitter<UnavailabilityTooltipEvent>();

  private _value: any;
  private tooltipEvent = new EventEmitter<{ toggle: boolean; configs: ITooltipConfig }>();
  private onTouchedCallback: (t: any) => void;
  private onChangeCallback: (t: any) => void;

  get value(): any {
    return this._value;
  }

  set value(v: any) {
    if (v !== this.value) {
      this.settings = v;
    }
  }

  constructor(
    public el: ElementRef,
    private holidaysService: HolidaysService,
    private tooltipService: TooltipService
  ) {}

  public writeValue(value: any) {
    this.settings = value;
  }

  public registerOnChange(fn: any) {
    this.onChangeCallback = fn;
    this.render();

    return this.onChangeCallback;
  }

  public registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
    return this.onTouchedCallback;
  }

  public ngOnChanges() {
    if (this.settings) {
      this.render();
    }
  }

  public render() {
    if (this.renderer) {
      this.renderer.destroy();
    }

    this.renderer = new Renderer(
      this.el.nativeElement,
      this.settings,
      this.resultDates,

      this.dateChanges,
      this.tooltipEvent,
      this.disableDayFn,
      this.invalidSelectionFn
    );
    this.renderer.editMode = this.editMode;
    this.rendererReadyEvt.emit();

    this.deleteDate = this.renderer.dropSelectedDate;
    this.renderer.drawWeeksDay();
    this.renderer.drawWeeks();
    this.renderer.renderMonths();
    this.renderer.renderDaysGrid();
    this.renderer.drawControls();
    this.fetchHolidaysByYear();

    if (this.controlEmiter) {
      this.controlEmiter.pipe(takeUntil(this.renderer.onDestroy)).subscribe(event => {
        switch (event.type) {
          case 'showDayText':
            {
              this.renderer.isShowDayText = event.value;
              this.renderer.showDays();
            }
            break;
          case 'showHolidays':
            {
              this.renderer.toggleHolidays();
            }
            break;
          case 'updateDates':
            {
              this.renderer.updateDates(event.value);
            }
            break;
          case 'updateUnits':
            {
              this.renderer.updateUnits(event.value);
            }
            break;
          case 'setActiveUnit':
            {
              this.renderer.setActiveUnit(event.value);
            }
            break;
          case 'fetchDates':
            {
              this.renderer.fetchDates();
            }
            break;
          case 'removeRange':
            {
              this.renderer.removeRange(event.value);
            }
            break;
          case 'removeAllRanges':
            {
              this.renderer.removeAllRanges();
            }
            break;
          case 'setEditMode':
            {
              this.renderer.setEditMode(event.value);
            }
            break;
          case 'setLimit': {
            this.renderer.setLimits(event.value);
          }
        }
      });

      this.initTooltip();
    }

    this.renderer.yearChanged.pipe(takeUntil(this.renderer.onDestroy)).subscribe(year => this.fetchHolidaysByYear(year));
  }

  public fetchHolidaysByYear(year = this.settings.year || moment().year()) {
    if (!this.renderer.holidays[year]) {
      this.holidaysService.loadHolidaysByYear(year).subscribe(holidays => {
        this.renderer.setHolidays(
          holidays.filter(holiday => holiday.active === true),
          year
        );
      });
    } else {
      this.renderer.setHolidays(this.renderer.holidays[year], year);
    }
  }

  public showUnavailabilityTooltip(day: Day, agendaUnavailability: AgendaUnavailability) {
    this.tooltip = this.tooltipService.openCustomTooltip({
      component: DatepickerTooltipComponent,
      placement: 'right',
      autoPlacement: true,
      zIndex: 1500,
      targetElement: day.cellGroup.node().getBoundingClientRect(),
      context: {
        ...agendaUnavailability
      }
    });
  }

  /**
   * Hide tooltip
   */
  public hideTooltip() {
    if (this.tooltip) {
      this.tooltip.close();
    }
    this.tooltip = null;
  }

  /*
   * Init tooltip and subscribe to event for getting data
   */
  private initTooltip() {
    this.unavailabilityTooltipEvt.pipe(takeUntil(this.renderer.onDestroy)).subscribe(data => {
      data.toggle ? this.showUnavailabilityTooltip(data.day, data.agendaUnavailability) : this.hideTooltip();
    });
  }
}
