import { HOUR, preferredWorkDuration, preferredWorkTime } from '@shared/const/time.const';
import { BaseModel } from '../base.model';
import { EmployeeModel } from '@model/auth';

export class PreferenceModel extends BaseModel {
  public workDurationMin: number;
  public workDurationMax: number;
  public startWorktimeMin: number;
  public startWorktimeMax: number;
  public healthyRotation: boolean;
  public partner: EmployeeModel;
  public sameScheduleWithPartner: boolean;
  public afterShiftBreak: number;
  public hardNightShiftOK: boolean;
  public hardWeekdays: string[];
  public hardWorkDurationMin: number;
  public hardWorkDurationMax: number;
  public hardAfterShiftBreak: number;
  public hardConsecutiveWeeklyOff: number;
  public hardWeeklyWorkMax: number;
  public standardWorkingHoursPerWeek: number;
  public workingDaysInRow: number;
}

export class PreferenceDataModel {
  public id: number;
  public workDuration: string;
  public workTime: string;
  public healthyRotation: boolean;
  public partner: EmployeeModel;
  public sameScheduleWithPartner: boolean;
  public hardNightShiftOK: boolean;
  public hardWeekdays: string[];
  public hardWorkDurationMin: number;
  public hardWorkDurationMax: number;
  public hardAfterShiftBreak: number;
  public hardConsecutiveWeeklyOff: number;
  public hardWeeklyWorkMax: number;
  public standardWorkingHoursPerWeek: number;
  public workingDaysInRow: number;
}

export const hoursToMinutes = (hours: number) => (hours !== undefined ? hours * HOUR : undefined);
export const minutesToHours = (minutes: number) => (minutes !== undefined ? minutes / HOUR : undefined);

export function mapPreferencesToDataModel(data: PreferenceModel): PreferenceDataModel {
  return {
    workDuration:
      preferredWorkDuration.find(workDuration => data.workDurationMin === workDuration.min && data.workDurationMax === workDuration.max)?.title ||
      preferredWorkDuration[0].title,
    workTime:
      preferredWorkTime.find(workTime => data.startWorktimeMin === workTime.min && data.startWorktimeMax === workTime.max)?.title || preferredWorkTime[0].title,
    healthyRotation: data.healthyRotation || false,
    partner: data.partner || undefined,
    sameScheduleWithPartner: data.sameScheduleWithPartner,
    id: data.id || undefined,
    hardNightShiftOK: data.hardNightShiftOK || false,
    hardWeekdays: data.hardWeekdays || [],
    hardWorkDurationMin: minutesToHours(data.hardWorkDurationMin),
    hardWorkDurationMax: minutesToHours(data.hardWorkDurationMax),
    hardAfterShiftBreak: minutesToHours(data.hardAfterShiftBreak),
    hardConsecutiveWeeklyOff: minutesToHours(data.hardConsecutiveWeeklyOff),
    hardWeeklyWorkMax: minutesToHours(data.hardWeeklyWorkMax),
    standardWorkingHoursPerWeek: data.standardWorkingHoursPerWeek,
    workingDaysInRow: data.workingDaysInRow
  };
}

export function mapDataModelToPreferences(data: PreferenceDataModel) {
  const workDuration = preferredWorkDuration.find(duration => data.workDuration === duration.title);
  const workTime = preferredWorkTime.find(time => data.workTime === time.title);

  return {
    workDurationMin: workDuration.min,
    workDurationMax: workDuration.max,
    startWorktimeMin: workTime.min,
    startWorktimeMax: workTime.max,
    healthyRotation: data.healthyRotation || false,
    partner: data.partner || undefined,
    sameScheduleWithPartner: data.sameScheduleWithPartner,
    id: data.id || undefined,
    hardNightShiftOK: data.hardNightShiftOK || false,
    hardWeekdays: data.hardWeekdays || [],
    hardWorkDurationMin: hoursToMinutes(data.hardWorkDurationMin),
    hardWorkDurationMax: hoursToMinutes(data.hardWorkDurationMax),
    hardAfterShiftBreak: hoursToMinutes(data.hardAfterShiftBreak),
    hardConsecutiveWeeklyOff: hoursToMinutes(data.hardConsecutiveWeeklyOff),
    hardWeeklyWorkMax: hoursToMinutes(data.hardWeeklyWorkMax),
    standardWorkingHoursPerWeek: data.standardWorkingHoursPerWeek,
    workingDaysInRow: data.workingDaysInRow
  };
}
