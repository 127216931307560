import { EventEmitter } from '@angular/core';
import * as d3 from 'd3';
import { takeUntil} from 'rxjs/operators';

import { xmlns } from '@helpers';

import { IBaseTimeline , IEventable } from '../utils/interfaces';



export class GridBackground implements IEventable {

  // events
  public onDragStart: EventEmitter<any> = new EventEmitter();
  public onDrag: EventEmitter<any> = new EventEmitter();
  public onDragEnd: EventEmitter<any> = new EventEmitter();
  public onDoubleClick: EventEmitter<any> = new EventEmitter();

  private $dragArea: d3.Selection<SVGGElement, any, any, any>;
  private $background: d3.Selection<SVGRectElement, any, any, any>;

  private slider: d3.DragBehavior<any, any, any>;

  constructor(
    private timeline: IBaseTimeline
  ) {
    const dragArea = document.createElementNS(xmlns, 'g');
    this.$dragArea = d3.select(dragArea).classed('drag-area', true);

    const background = document.createElementNS(xmlns, 'rect');
    this.$background = d3.select(background).classed('main-bg', true);

    this.slider = d3.drag();
  }

  get dragArea() {
    return this.$dragArea;
  }

  get background() {
    return this.$background;
  }

  /**
   * Rendering functionality
   * @returns {Element}
   */
  public render() {
    const sizes = this.timeline.sizes();
    const width = sizes.width;
    let height = sizes.height;
    let yPos = 0;
    const menuHeight = sizes.menuHeight;

    const tabsHeader = this.timeline.header.tabsController;

    if (tabsHeader) {
      height -= tabsHeader.height;
      yPos += tabsHeader.height;
    }

    this.$background
      .attr('y', yPos)
      .attr('width', width)
      .attr('height', height);

    this.$dragArea
      .attr('cursor', 'ew-resize')
      .attr('height', menuHeight)
      .attr('width', width);

    this.$dragArea.call(this.slider);

    this.events();
  }

  /**
   * DOM event listeners
   */
  public events() {
    this.slider.on('start', () => {
      this.$dragArea.attr('cursor', 'move');
      this.onDragStart.next(d3.event);
    });

    this.slider.on('end', () => {
      this.$dragArea.attr('cursor', 'ew-resize');
      this.onDragEnd.next(d3.event);
    });

    this.slider.on('drag', () => {
      this.onDrag.next(d3.event);
    });

    this.$dragArea.on('dblclick', () => {
      this.onDoubleClick.next(d3.event);
    });
  }

  /**
   * Subscribe to events
   */
  public subscribe() {
    this.timeline.onResize
      .pipe(takeUntil(this.timeline.onDestroy))
      .subscribe(({ menuHeight, height }) => {
        this.$dragArea.attr('height', menuHeight);
        this.$background.attr('height', height);
      });
  }

}
