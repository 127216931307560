<div>
  <div class="widget-title">
    <div>
      <div>Week Validations</div>
    </div>
  </div>

  <app-week-charts [statistics]="statistics$ | async" [hidden]="(statistics$ | async) === undefined"></app-week-charts>

  <div class="no-items" *ngIf="(statistics$ | async) === undefined">
    <mat-card>
      <mat-card-content>
        <mat-icon>block</mat-icon>
        <span>No statistics for this week available</span>
      </mat-card-content>
    </mat-card>
  </div>

  <mat-card *ngFor="let validation of validations$ | async" (click)="toggleValidation(validation)">
    <mat-card-content>
      <mat-icon [style.color]="getSeverityColor(validation.severity)">{{ getSeverityIcon(validation.severity) }}</mat-icon>
      <span [class.confirmed]="validation.isAccepted">{{ validation.message }}</span>
      <div *ngIf="validation.isAccepted">
        Accepted by {{ validation.acceptedBy.firstName }} {{ validation.acceptedBy.lastName }} at {{ validation.updatedAt | date: 'dd-MM-YYYY HH:mm:ss' }}
      </div>
    </mat-card-content>
  </mat-card>

  <div class="no-items" *ngIf="(validations$ | async)?.length === 0">
    <mat-card>
      <mat-card-content>
        <mat-icon>done</mat-icon>
        <span>This week is valid</span>
      </mat-card-content>
    </mat-card>
  </div>
</div>
