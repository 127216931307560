<input #datepickerInput [disabled]="isDisabled" [value]="value" (change)="onInputValueChange($event)" />

<div class="mat-datepicker-toggle">
  <button
    #menuTrigger
    mat-icon-button
    class="menu-trigger"
    [matMenuTriggerFor]="calendarMenu"
    aria-label="Calendar icon to open calendar"
    [disabled]="isDisabled"
  >
    <mat-icon>today</mat-icon>
  </button>
</div>

<mat-menu #calendarMenu xPosition="before" yPosition="below" (closed)="onMenuClosed()">
  <div class="calendar-menu-wrapper" (click)="onCalendarWrapperClick($event)">
    <mat-calendar #matCalendar [startAt]="valueAsDate" [selected]="valueAsDate" [dateClass]="getClassForDay()" (selectedChange)="onCalendarValueChange($event)">
    </mat-calendar>
  </div>
</mat-menu>
