import { Activity } from './activity.model';
import { parseStringShiftType } from '../../helpers/shift-type-helper';
import { AssignActivityResult } from './assign-activity-result.model';
import { ISO_DATE_ONLY_FORMAT } from '@helpers';
import { UserBasic } from './user-basic';
import moment from 'moment-mini';
import { ShiftCodeTypeEnum } from '../../enums/shift-code-type.enum';

export class AssignedActivity extends Activity {
  data: { isLocked: boolean; type?: string };
  employeeId?: number;
  timestamp?: string;
  updatedBy?: UserBasic;
  avaiKey?: string;
  type: ShiftCodeTypeEnum;
  shiftCodeId: number;

  constructor(model: AssignActivityResult) {
    super();
    this.id = model.id;
    this.alias = model.shiftAlias.value;
    this.code = model.code;
    this.dateFrom = model.dateFrom;
    this.dateTo = model.dateTo;
    this.parentId = model.parentId;
    this.parentType = parseStringShiftType(model.parentType);
    this.type = model.type;
    this.data = model.data;
    this.employeeId = model.employeeId;
    this.timestamp = model.date ? moment.utc(model.date).format(ISO_DATE_ONLY_FORMAT) : undefined;
    this.updatedBy = model.updatedBy;
    this.avaiKey = model.avaiKey;
    this.type = model.type;
    this.shiftCodeId = model.shiftCodeId;
  }
}
