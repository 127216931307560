import { ReportTabModel } from './report-tab.model';
import { SummaryModel } from './summary.model';

export class ReportModel {
  public status = {
    summary: {
      loaded: false,
      error: false,
      message: ''
    },
    tabs: {
      loaded: false,
      error: false,
      message: ''
    }
  };
  public summary: SummaryModel = new SummaryModel();
  public tabs: ReportTabModel[];

  constructor(data: any = {}) {
    if (data && data.dateFrom) {
      this.summary = new SummaryModel(data);
    }

    this.tabs = (data.tabs && data.tabs.map(tab => new ReportTabModel(tab))) || [];
  }

  public setTabsData(report) {
    if (!this.summary.id) {
      this.summary.id = report.id;
    }
    this.tabs = (report.tabs && report.tabs.map(tab => new ReportTabModel(tab))) || [];
    this.tabs.map(tab => tab.setDates(this.summary.dateFrom, this.summary.dateTo));
  }

  public setSummary(reportData) {
    this.summary = new SummaryModel(reportData);
  }
}
