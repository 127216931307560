import { BaseModel } from '../base.model';

export class MasterplanShift extends BaseModel {
  public id: number;
  public shiftCode: string;
  public dayIndex: number;

  constructor(data: any = {}) {
    super(data);

    this.id = data.id;
    this.shiftCode = data?.shiftCode;
    this.dayIndex = data?.dayIndex;
  }
}
