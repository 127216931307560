import { Component, Input, forwardRef, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as moment from 'moment-mini';
import { formatDateToShortFormat, SHORT_DATE_FORMAT, ISO_DATE_ONLY_FORMAT } from '@helpers';
import { MatCalendar, MatCalendarCellCssClasses } from '@angular/material/datepicker';
import { MatMenuTrigger } from '@angular/material/menu';
import { getWeekNumberClasses } from '../datepicker-utils';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatePickerComponent),
      multi: true
    }
  ]
})
export class DatePickerComponent implements ControlValueAccessor {
  @Input() class: string;
  @Input() ariaLabel: string;
  @Input() required: boolean;
  @Input() value = '';
  @Input() disabled: boolean;
  @ViewChild(MatMenuTrigger) matMenuTrigger: MatMenuTrigger;
  @ViewChild('matCalendar') matCalendar: MatCalendar<Date>;
  public get valueAsDate() {
    return this.value ? moment(this.value, SHORT_DATE_FORMAT).toDate() : null;
  }
  public get valueAsIsoString() {
    const date = moment(this.value, SHORT_DATE_FORMAT);
    return date.isValid() ? date.format(ISO_DATE_ONLY_FORMAT) : '';
  }
  public onChangeRegisteredFunctions: ((value: string) => void)[] = [];
  public onTouchedRegisteredFunctions: (() => void)[] = [];
  public isDisabled: boolean;

  public onTouched() {
    this.onTouchedRegisteredFunctions.forEach(f => f());
  }

  writeValue(value: string | Date): void {
    const parsedValue = moment(value);

    if (parsedValue.isValid()) {
      const formattedValue = formatDateToShortFormat(parsedValue.toDate());
      this.updateValue(formattedValue, false);
    }
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChangeRegisteredFunctions.push(fn);
  }

  registerOnTouched(fn: () => void): void {
    this.onTouchedRegisteredFunctions.push(fn);
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  onCalendarValueChange(newValue: Date) {
    this.matMenuTrigger.closeMenu();
    this.updateValue(formatDateToShortFormat(newValue));
  }

  onInputValueChange(event: Event) {
    const element = event.target as HTMLInputElement;
    const newValue = element.value;

    if (this.isDateValid(newValue)) {
      this.updateValue(newValue);
    } else {
      this.updateValue(null);
      element.value = '';
    }
  }

  onCalendarWrapperClick(event: MouseEvent): void {
    // We stop propagation of event to be sure that if the datepicker
    // is embedded in tooltip, the tooltip won't close when date selected
    event.stopPropagation();
  }

  onMenuClosed() {
    this.scrollCalendarToSelectedDay();
  }

  getClassForDay() {
    return (date: Date): MatCalendarCellCssClasses => {
      return getWeekNumberClasses(date);
    };
  }

  private updateValue(newValue: string, notifyRegisteredListeners = true) {
    this.value = newValue;
    this.onChangeRegisteredFunctions.forEach(f => f(this.valueAsIsoString));
    this.scrollCalendarToSelectedDay();

    if (notifyRegisteredListeners) {
      this.onChangeRegisteredFunctions.forEach(f => f(this.valueAsIsoString));
    }
  }

  private scrollCalendarToSelectedDay() {
    if (this.matCalendar && this.value) {
      this.matCalendar.activeDate = this.valueAsDate;
      this.matCalendar.updateTodaysDate();
    }
  }

  private isDateValid(date: string) {
    return moment(date.trim(), SHORT_DATE_FORMAT, true).isValid();
  }
}
