import { Injectable } from '@angular/core';
import { ShiftsState } from '../state/shifts.state';
import { Activity, AssignedActivity, AssignedEmployee, VacayRequest } from '../dto';
import { ShiftType } from '../../../_shared/enums/shift-type';
import { PREDICT_KEY } from '@app/rostr/_shared/consts/map-keys.const';
import { ShiftCodeTypeEnum } from '../../../_shared/enums/shift-code-type.enum';
import { ShiftService } from '@services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ShiftStatus } from '@enums';

@Injectable({ providedIn: 'root' })
export class ShiftsFacade {
  constructor(
    private readonly shiftsState: ShiftsState,
    private readonly shiftService: ShiftService
  ) {}

  public cleanUp(): void {
    this.shiftsState.cleanUp();
  }

  public setAllTimestamps(allTimestamps: string[]): void {
    this.shiftsState.allTimestamps = allTimestamps;
  }

  public initializeAllStatuses(employee: AssignedEmployee): void {
    this.shiftsState.initializeStatusesForGivenEmployee(employee.id);

    const allTimestamps = this.shiftsState.allTimestamps;

    for (const key of allTimestamps) {
      this.calculateStateForGivenTimestamp(employee, key);
    }
  }

  public calculateStateForGivenTimestamp(employee: AssignedEmployee, timestamp: string): void {
    this.determineAvailabilityState(employee, timestamp);
    this.calculateMasterplanState(employee, timestamp);
    this.calculateVacayState(employee, timestamp);
    this.calculateVacayAvailableState(employee, timestamp);
    this.calculateAgendaRequestsState(employee, timestamp);
    this.shiftsState.assignedActivitiesStatuses.get(employee.id).set(timestamp, this.calculateAssignedActivityState(employee, timestamp));
  }

  public predictStateForGivenTimestamp(employee: AssignedEmployee, timestamp: string, activity: Activity): ShiftStatus {
    employee.masterplan.set(PREDICT_KEY, employee.masterplan.get(timestamp));
    employee.vacay.set(PREDICT_KEY, employee.vacay.get(timestamp));
    employee.vacayAvailable.set(PREDICT_KEY, employee.vacayAvailable.get(timestamp));
    employee.agenda.set(PREDICT_KEY, employee.agenda.get(timestamp) ? [...employee.agenda.get(timestamp)] : []);
    employee.assigned.set(PREDICT_KEY, activity as AssignedActivity);

    this.calculateMasterplanState(employee, PREDICT_KEY);
    this.calculateVacayState(employee, PREDICT_KEY);
    this.calculateVacayAvailableState(employee, PREDICT_KEY);
    this.calculateAgendaRequestsState(employee, PREDICT_KEY);

    return this.calculateAssignedActivityState(employee, PREDICT_KEY);
  }

  public loadShiftColors$(): Observable<void> {
    return this.shiftService.getShiftCodeColors().pipe(
      map(colorMap => {
        this.shiftsState.shiftCodeColors = colorMap;
      })
    );
  }

  private calculateAssignedActivityState(employee: AssignedEmployee, timestamp: string): ShiftStatus {
    if (this.hasAgendaConflict(employee, timestamp)) {
      return ShiftStatus.Error;
    } else if (this.hasAgendaSuccess(employee, timestamp)) {
      return ShiftStatus.Success;
    } else if (this.hasWarnInMasterplanOrVacay(employee, timestamp)) {
      return ShiftStatus.Warn;
    } else {
      return ShiftStatus.Success;
    }
  }

  private hasAgendaConflict(employee: AssignedEmployee, timestamp: string): boolean {
    const statuses = this.shiftsState.agendaStatuses.get(employee.id).get(timestamp);

    if (!statuses) {
      return this.isNotExistingAgendaAssigned(employee, timestamp);
    }

    const agendaRequestsWithError = Array.from(statuses.values()).filter(x => x === ShiftStatus.Error);
    return agendaRequestsWithError?.length > 0 || this.isNotExistingAgendaAssigned(employee, timestamp);
  }

  private hasAgendaSuccess(employee: AssignedEmployee, timestamp: string): boolean {
    const agenda = this.shiftsState.agendaStatuses.get(employee.id).get(timestamp);
    return agenda && Array.from(agenda.values())?.some(x => x === ShiftStatus.Success);
  }

  private hasWarnInMasterplanOrVacay(employee: AssignedEmployee, timestamp: string): boolean {
    const vacayValues = Array.from(this.shiftsState.vacayStatuses.get(employee.id).get(timestamp)?.values());
    const vacaySuccess = (vacayValues || []).some(status => status === ShiftStatus.Success);

    if (vacaySuccess) {
      return false;
    }

    return this.shiftsState.masterplanStatuses.get(employee.id).get(timestamp) === ShiftStatus.Warn || vacayValues.length > 0;
  }

  private isNotExistingAgendaAssigned(employee: AssignedEmployee, timestamp: string): boolean {
    return employee.assigned.get(timestamp)?.parentType === ShiftType.Agenda && !employee.agenda.get(timestamp)?.length;
  }

  private calculateMasterplanState(employee: AssignedEmployee, timestamp: string) {
    if (employee.masterplan.get(timestamp) && employee.assigned.get(timestamp)) {
      if (this.hasMasterplanConflict(employee, timestamp)) {
        this.shiftsState.masterplanStatuses.get(employee.id).set(timestamp, ShiftStatus.Warn);
      } else {
        this.shiftsState.masterplanStatuses.get(employee.id).set(timestamp, ShiftStatus.Success);
      }
    } else {
      this.shiftsState.masterplanStatuses.get(employee.id).set(timestamp, ShiftStatus.Unknown);
    }
  }

  private hasMasterplanConflict(employee: AssignedEmployee, timestamp: string): boolean {
    const isMasterplanDayOff = employee.masterplan.get(timestamp).type == ShiftCodeTypeEnum.TimeOff;
    const isAssignedDayOff = employee.assigned.get(timestamp).type === ShiftCodeTypeEnum.TimeOff;

    if (isMasterplanDayOff && isAssignedDayOff) {
      return false;
    }

    return (
      employee.masterplan.get(timestamp).code !== employee.assigned.get(timestamp).alias &&
      employee.masterplan.get(timestamp)?.code !== employee.assigned.get(timestamp)?.code
    );
  }

  private calculateVacayState(employee: AssignedEmployee, timestamp: string) {
    this.shiftsState.vacayStatuses.get(employee.id).set(timestamp, new Map<number, ShiftStatus>());
    const vacayCodes = employee.vacay.get(timestamp);
    if (vacayCodes) {
      const assigned = employee.assigned.get(timestamp);
      for (let i = 0; i < vacayCodes.length; i++) {
        const vacayCode = vacayCodes[i];
        if (vacayCode) {
          this.calculateVacayStatus(employee, timestamp, i, vacayCode, assigned);
        }
      }
    }
  }

  private calculateVacayStatus(employee: AssignedEmployee, timestamp: string, index: number, vacayRequest: VacayRequest, assigned: AssignedActivity) {
    const statuses = this.shiftsState.vacayStatuses.get(employee.id).get(timestamp);
    const isOperative = vacayRequest.type === ShiftCodeTypeEnum.Operative;
    const isCodeMatched = isOperative ? vacayRequest.code === assigned?.code : vacayRequest.type === assigned?.type;
    const isAliasMatched = vacayRequest.code === vacayRequest.alias && assigned?.alias === vacayRequest.alias;

    statuses.set(index, isCodeMatched || isAliasMatched ? ShiftStatus.Success : ShiftStatus.Warn);
  }

  private calculateVacayAvailableState(employee: AssignedEmployee, timestamp: string) {
    if (employee.vacayAvailable.get(timestamp)) {
      if (employee.vacayAvailable.get(timestamp)?.code !== employee.assigned.get(timestamp)?.alias) {
        this.shiftsState.vacayAvailableStatuses.get(employee.id).set(timestamp, ShiftStatus.Warn);
      } else {
        this.shiftsState.vacayAvailableStatuses.get(employee.id).set(timestamp, ShiftStatus.Success);
      }
    }
  }

  private calculateAgendaRequestsState(employee: AssignedEmployee, timestamp: string) {
    if (employee.agenda.get(timestamp)) {
      this.shiftsState.agendaStatuses.get(employee.id).set(timestamp, new Map<number, ShiftStatus>());
      const agendaStatuses = this.shiftsState.agendaStatuses.get(employee.id).get(timestamp);
      const employeeAgendaRequests = employee.agenda.get(timestamp);
      for (let i = 0; i < employeeAgendaRequests.length; i++) {
        const agendaRequest = employeeAgendaRequests[i];
        const agendaStatusesValues = Array.from(agendaStatuses.values());
        if (employee.assigned.get(timestamp)?.parentId === agendaRequest.parentId) {
          agendaStatuses.set(i, agendaStatusesValues.includes(ShiftStatus.Success) ? ShiftStatus.Error : ShiftStatus.Success);
        } else {
          agendaStatuses.set(i, ShiftStatus.Error);
        }
      }
    }
  }

  private determineAvailabilityState(employee: AssignedEmployee, timestamp: string): void {
    this.shiftsState.assignedActivitiesAvailability
      .get(employee.id)
      .set(timestamp, employee.assigned.get(timestamp) && employee.assigned.get(timestamp).type === ShiftCodeTypeEnum.Standby);
  }
}
