<div>
  <div class="widget-title">
    <div>
      <div>Employee Details</div>
    </div>
  </div>
  <mat-tab-group animationDuration="0ms" #tabGroup>
    <mat-tab label="Validations">
      <ng-template mat-tab-label>
        <mat-icon>assignment_turned_in</mat-icon>
      </ng-template>
      <div class="validations">
        <app-employee-charts
          *ngIf="tabGroup.selectedIndex === 0"
          [statistics]="statistics$ | async"
          [hidden]="(statistics$ | async) === undefined"
        ></app-employee-charts>

        <div class="no-items" *ngIf="(statistics$ | async) === undefined">
          <mat-card>
            <mat-card-content>
              <mat-icon>block</mat-icon>
              <span>No statistics for this employee available</span>
            </mat-card-content>
          </mat-card>
        </div>

        <mat-card *ngFor="let validation of validations$ | async" (click)="toggleValidation(validation)">
          <mat-card-content>
            <mat-icon [style.color]="getSeverityColor(validation.severity)">{{ getSeverityIcon(validation.severity) }}</mat-icon>
            <span [class.confirmed]="validation.isAccepted">{{ validation.message }}</span>
            <ng-container *ngIf="validation.isAccepted">
              Accepted by {{ validation.acceptedBy.firstName }} {{ validation.acceptedBy.lastName }} at
              {{ validation.updatedAt | date: 'dd-MM-YYYY HH:mm:ss' }}
            </ng-container>
          </mat-card-content>
        </mat-card>

        <div class="no-items" *ngIf="(validations$ | async)?.length === 0">
          <mat-card>
            <mat-card-content>
              <mat-icon>done</mat-icon>
              <span>This employee is valid</span>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Info">
      <ng-template mat-tab-label>
        <mat-icon>account_box</mat-icon>
      </ng-template>
      <mat-accordion multi *ngIf="!loading">
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title> <mat-icon>face</mat-icon> General properties </mat-panel-title>
          </mat-expansion-panel-header>

          <div class="label">Id:</div>
          <div class="value">{{ data.id }}</div>

          <div class="label">Username:</div>
          <div class="value">{{ data.username }}</div>

          <div class="label">First name:</div>
          <div class="value">{{ data.firstName }}</div>

          <div class="label">Middle name:</div>
          <div class="value">{{ data.middleName }}</div>

          <div class="label">Last name:</div>
          <div class="value">{{ data.lastName }}</div>
        </mat-expansion-panel>

        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title> <mat-icon>schedule</mat-icon> Work percentage </mat-panel-title>
          </mat-expansion-panel-header>

          <div *ngFor="let percentage of data.workPercentage">
            <span class="versioned-date versioned-date-label">{{ percentage.dateFrom | date: 'dd/MM/yyyy' }}:</span>
            <span class="versioned-date"> {{ percentage.value }}%</span>
          </div>

          <span *ngIf="!data.workPercentage.length" class="versioned-date">100%</span>
        </mat-expansion-panel>

        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title> <mat-icon>assignment_ind</mat-icon> Groups </mat-panel-title>
          </mat-expansion-panel-header>

          <div *ngFor="let group of editableGroups()">
            <span class="versioned-date versioned-date-label">{{ group.validFrom | date: 'dd/MM/yyyy' }} - {{ group.validTo | date: 'dd/MM/yyyy' }}</span>
            <span class="versioned-date"> {{ group.name }}</span>
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title> <mat-icon>assignment</mat-icon> Skills </mat-panel-title>
          </mat-expansion-panel-header>

          <div *ngFor="let skill of data.skills">
            <span class="versioned-date versioned-date-label">{{ skill.validFrom | date: 'dd/MM/yyyy' }} - {{ skill.validTo | date: 'dd/MM/yyyy' }}</span>
            <span class="versioned-date"> {{ skill.name }}</span>
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title> <mat-icon>reorder</mat-icon> Preferences </mat-panel-title>
          </mat-expansion-panel-header>

          <div class="label">Time off between shifts:</div>
          <div class="value">{{ data.preferences.hardAfterShiftBreak | humanizeDuration }}</div>

          <div class="label">Weekly time off:</div>
          <div class="value">{{ data.preferences.hardConsecutiveWeeklyOff | humanizeDuration }}</div>

          <div class="label">Night shift:</div>
          <div class="value">{{ data.preferences.hardNightShiftOK ? 'Yes' : 'No' }}</div>

          <div class="label">Weekly work time:</div>
          <div class="value">{{ data.preferences.hardWeeklyWorkMax | humanizeDuration }}</div>

          <div class="label">Min shift length:</div>
          <div class="value">{{ data.preferences.hardWorkDurationMin | humanizeDuration }}</div>

          <div class="label">Max shift length:</div>
          <div class="value">{{ data.preferences.hardWorkDurationMax | humanizeDuration }}</div>
        </mat-expansion-panel>
      </mat-accordion>
    </mat-tab>
  </mat-tab-group>
</div>
