import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { Config } from './config.interface';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  config: Config;
  private readonly ENVIRONMENTS_DATA_JSON = 'assets/config';

  constructor(private injector: Injector) {}

  public load(): Promise<Config> {
    const http = this.injector.get(HttpClient);
    return http
      .get<Config>(`${this.ENVIRONMENTS_DATA_JSON}/config.json`)
      .pipe(
        tap(data => (this.config = data)),
        catchError((error: any) => {
          console.error(error);
          return throwError(error.message || 'Server error');
        })
      )
      .toPromise();
  }
}
