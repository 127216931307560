import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { TooltipService } from '@components';
import { AuthenticationService } from '@auth';
import { EmployeeModel } from '@models';
import { IRouteModule, IRouteChild } from '@interfaces';
import { routeConfig } from '@shared/configs/route.config';
import { PlatformNavTooltipComponent } from '@app/_shared';
import { MODULES } from '@core';

@Component({
  selector: 'pl-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit, OnDestroy {
  activeModule: IRouteModule;
  activeView: IRouteChild;
  modules = routeConfig.getModules();
  moduleRoutes: IRouteChild[] = [];
  user: EmployeeModel;
  routerSub: Subscription;
  @ViewChild('moduleMenu') moduleMenu: ElementRef;
  @ViewChild('viewMenu') viewMenu: ElementRef;
  @ViewChild('searchMenu') searchMenu: ElementRef;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private readonly tooltipService: TooltipService
  ) {}

  public ngOnInit() {
    this.user = this.authenticationService.user;
    const userRoles = this.authenticationService.userRoles;
    this.setModuleRoutes(userRoles.roles);
    this.routerSub = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(_ => {
      this.setModuleRoutes(userRoles.roles);
    });

    this.modules = routeConfig.getPermittedModule(userRoles.roles);
  }

  ngOnDestroy(): void {
    this.routerSub.unsubscribe();
  }

  private setModuleRoutes(userRoles: string[]) {
    const urlParts = this.router.url.split('(')[0].split('?')[0].split('/');
    this.activeModule = routeConfig.getActiveModule(urlParts[1]);
    this.moduleRoutes = routeConfig.getChildren(this.activeModule.path, userRoles);

    let viewUrl = urlParts[urlParts.length - 1];

    if (this.activeModule.path === MODULES.PLATFORM && urlParts[1] === 'organization') {
      viewUrl = urlParts[1];
    } else if (this.activeModule.path === MODULES.PLATFORM && urlParts[1] === 'entity') {
      viewUrl = this.parsePlatformUrl(urlParts);
    } else if (this.activeModule.path === MODULES.PLATFORM && urlParts[1] !== 'entity') {
      viewUrl = urlParts[1];
    } else if (this.activeModule.path === MODULES.ARGUS && urlParts[2] === 'report') {
      viewUrl = urlParts[2];
    } else if (this.activeModule.path === MODULES.ARGUS && !urlParts[2]) {
      viewUrl = 'overview';
    } else if (this.activeModule.path === MODULES.ROSTR && urlParts[2].includes('planning')) {
      viewUrl = 'planning';
    } else if (this.activeModule.path === MODULES.ROSTR && urlParts[2].includes('schedules')) {
      viewUrl = 'schedules';
    } else if (this.activeModule.path === MODULES.ROSTR && urlParts[2].includes('shifts')) {
      viewUrl = 'shifts';
    } else if (this.activeModule.path === 'settings') {
      viewUrl = urlParts[2];
    } else if (this.activeModule.path === MODULES.AGENDA && urlParts[2] === 'projects') {
      viewUrl = urlParts[2];
    } else if (this.activeModule.path === MODULES.AGENDA && urlParts[2] === 'objectives') {
      viewUrl = urlParts[2];
    } else if (this.activeModule.path === MODULES.PLANNING && urlParts[2] === 'requirements' && urlParts[3] === 'editor') {
      viewUrl = 'requirements-editor';
    } else if (this.activeModule.path === MODULES.PLANNING && urlParts[3] === 'editor') {
      viewUrl = urlParts[3];
    }

    this.activeView = this.moduleRoutes.find(item => item.path === viewUrl);
  }

  public openNavMenu(isModule: boolean) {
    const element: ElementRef = isModule ? this.moduleMenu : this.viewMenu;
    const items = isModule ? this.modules : this.moduleRoutes;
    const activeModule = isModule ? this.activeModule : this.activeView;
    this.tooltipService.openCustomTooltip({
      targetElement: element.nativeElement.getBoundingClientRect(),
      component: PlatformNavTooltipComponent,
      placement: 'bottom',
      width: 175,
      styles: {
        borderColor: 'transparent',
        backgroundColor: 'transparent'
      },
      classes: 'navigation-tooltip',
      context: {
        activeModules: items,
        activeItem: activeModule,
        callback: () => {
          return;
        }
      }
    });
  }

  /**
   * Parse Platform Url
   * @desc an entity can be a site, company, unit, or employee so parse out
   * the URL based on if it is an overview or particular profile
   *
   */
  private parsePlatformUrl(urlParts: string[]): string {
    if (urlParts[urlParts.length - 1] === 'overview') {
      return urlParts[2];
    }

    if (urlParts[urlParts.length - 1] === 'profile') {
      // profile pages are under the parent entity over page
      return urlParts[2];
    }

    if (urlParts[2] === 'group') {
      return urlParts[2];
    }

    return urlParts[urlParts.length - 1];
  }
}
