import { map } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';
import { ApolloQueryResult } from '@apollo/client/core';
import { Injectable } from '@angular/core';
import { ScheduleModel } from '@shared/models';
import { getSchedule, updateEmployeeOrder, updateScheduleFromExcel } from '../queries/schedule-update.queries';

@Injectable({
  providedIn: 'root'
})
export class ScheduleUpdateService {
  constructor(private readonly apollo: Apollo) {}

  public loadSchedule(id: number) {
    return this.apollo
      .query({
        query: getSchedule,
        variables: {
          id
        }
      })
      .pipe(
        map((response: ApolloQueryResult<any>) => {
          return new ScheduleModel(response.data.schedule);
        })
      );
  }

  public updateScheduleFromExcel({ id, name, dateFrom, dateTo, lockedVacayDays, draftDeadline, publishDeadline, deadline, assignments }) {
    return this.apollo.mutate({
      mutation: updateScheduleFromExcel,
      context: {
        useMultipart: true
      },
      variables: {
        id,
        name,
        dateFrom,
        dateTo,
        assignments: assignments.map(assignment => ({
          group: Number(assignment.group)
        })),
        lockedVacayDays,
        draftDeadline: draftDeadline || null,
        publishDeadline: publishDeadline || null,
        deadline
      }
    });
  }

  public updateEmployeeOrder({ id, employeeOrdering }) {
    return this.apollo.mutate({
      mutation: updateEmployeeOrder,
      variables: {
        id,
        employeeOrdering
      }
    });
  }
}
