import * as moment from 'moment-mini';
import { BaseModel } from '../base.model';
import { ShiftAliasModel } from '../activities/shift-alias';
import { SkillModel } from '@model/profile/skill.model';
import { ShiftCodeType } from './shift-code-type.model';
import { ShiftCodeDetails } from './shift-code-details.model';
import { ShiftCodeTypeEnum } from '@shared/enums/shift-code-type.enum';
import { LicenseModel, ShiftType } from '@shared/index';
import { UnitEndorsementModel } from '@model/profile/unit-endorsement.model';
import { ManualShift } from '@app/rostr/overview/dto';

export class ManualShiftModel extends BaseModel {
  private _code: string;
  private _metacode: string;
  private _comment: string;
  private _start: number;
  private _duration: number;
  private _alias: ShiftAliasModel = null;
  private _skills: SkillModel[];
  private _licenses: LicenseModel[];
  private _unitEndorsements: UnitEndorsementModel[];
  private _workingTimeInMinutes: number;
  public type: ShiftCodeType;
  public workingTimePercentage: number;
  public typeId: ShiftCodeTypeEnum;
  public isComplex: boolean;
  public details?: ShiftCodeDetails[];
  public color: string;
  public isGlobal: boolean;
  public groupId: number;
  public isQuicklyAccessible: boolean;
  public shiftCodeId: number;

  constructor(data: any = {}) {
    super(data);

    this.code = data.code || void 0;
    this.metacode = data.metacode || void 0;
    this.comment = data.comment || void 0;

    if (typeof data.start === 'number') {
      this.start = data.start;
    } else if (data.dateFrom) {
      this.start = data.dateFrom;
    }

    if (typeof data.duration === 'number') {
      this.duration = data.duration;
    } else if (data.dateFrom) {
      this.duration = data.dateTo;
    }

    this.alias = data.shiftAlias;
    this.skills = data.skills?.map(skill => new SkillModel(skill));
    this.licenses = data.licenses?.map(license => new LicenseModel(license));
    this.unitEndorsements = data.unitEndorsements?.map(endorsement => new UnitEndorsementModel(endorsement));
    this.type = data.type;
    this.typeId = data.typeId || data.type?.id;
    this.workingTimePercentage = data.workingTimePercentage;
    this._workingTimeInMinutes = data.workingTimeInMinutes;
    this.isComplex = data.isComplex;
    this.details = data.details;
    this.color = data?.color || '#ffffff';
    this.isGlobal = data.isGlobal;
    this.groupId = data.groupId;
    this.isQuicklyAccessible = data.isQuicklyAccessible;
    this.shiftCodeId = data.shiftCodeId;
  }

  get workingTimeInMinutes() {
    return this._workingTimeInMinutes;
  }

  get code() {
    return this._code;
  }

  get metacode() {
    return this._metacode;
  }

  get comment() {
    return this._comment;
  }

  set code(value) {
    this._code = value;
  }

  set metacode(value) {
    this._metacode = value;
  }

  set comment(value) {
    this._comment = value;
  }

  public get skills(): SkillModel[] {
    return this._skills;
  }

  public set skills(value: SkillModel[]) {
    this._skills = value;
  }

  public get licenses(): LicenseModel[] {
    return this._licenses;
  }

  public set licenses(value: LicenseModel[]) {
    this._licenses = value;
  }

  public get unitEndorsements(): UnitEndorsementModel[] {
    return this._unitEndorsements;
  }

  public set unitEndorsements(value: UnitEndorsementModel[]) {
    this._unitEndorsements = value;
  }

  get start() {
    return this._start;
  }

  set start(value) {
    if (typeof value === 'number') {
      this._start = value;
    } else if (moment.isDate(value)) {
      const typedValue = value as Date;
      this._start = typedValue.getHours() * 60 + typedValue.getMinutes();
    } else if (moment.isMoment(value)) {
      const typedValue = value as moment.Moment;
      this._start = typedValue.hours() * 60 + typedValue.minutes();
    }
  }

  get duration() {
    return this._duration;
  }

  set duration(value) {
    if (typeof value === 'number') {
      this._duration = value;
    } else if (moment.isDate(value)) {
      const typedValue = value as Date;
      this._duration = typedValue.getHours() * 60 + typedValue.getMinutes();
    } else if (moment.isMoment(value)) {
      const typedValue = value as moment.Moment;
      this._duration = typedValue.hours() * 60 + typedValue.minutes();
    }
  }

  get alias() {
    return this._alias;
  }

  set alias(value) {
    this._alias = value;
  }

  toRostrManualShiftDto(): ManualShift {
    return {
      id: this.id,
      code: this.code,
      isGlobal: this.isGlobal,
      groupId: this.groupId,
      isQuicklyAccessible: this.isQuicklyAccessible,
      startTimeInMinutes: this.start,
      duration: this.duration,
      type: this.typeId,
      alias: this.alias.value,
      parentId: this.id,
      parentType: ShiftType.Manual,
      dateFrom: null,
      dateTo: null,
      shiftCodeId: this.shiftCodeId
    };
  }
}
