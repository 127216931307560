<div *ngIf="!loading; else loadingContent">
  <div class="widget-title">
    <div>
      <div>{{ scheduleDay?.date | shMoment: 'dddd' }} {{ scheduleDay?.date | shMoment: 'DD.MM.YYYY' }}</div>
    </div>
  </div>

  <div>
    <mat-accordion multi>
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header
          ><mat-panel-title> <mat-icon>date_range</mat-icon> {{ schedule.name }} </mat-panel-title></mat-expansion-panel-header
        >
        <p>
          State: <strong>{{ schedule.state }}</strong>
        </p>
      </mat-expansion-panel>
      <mat-expansion-panel [expanded]="true" *ngIf="!scheduleDay.isLocked && scheduleDay.scheduleState === ScheduleState.OPEN">
        <mat-expansion-panel-header
          ><mat-panel-title> <mat-icon>event_available</mat-icon> Wishes </mat-panel-title></mat-expansion-panel-header
        >
        <vc-schedule-wish-menu [day]="scheduleDay" [isInfo]="false"> </vc-schedule-wish-menu>
      </mat-expansion-panel>
      <mat-expansion-panel [expanded]="true" *ngIf="shiftInfoVisible">
        <mat-expansion-panel-header
          ><mat-panel-title> <mat-icon>dashboard</mat-icon> Shift Info </mat-panel-title></mat-expansion-panel-header
        >
        <vc-shift-info [shiftInput]="scheduleDay?.shift"></vc-shift-info>
      </mat-expansion-panel>
      <mat-expansion-panel [expanded]="true" *ngIf="scheduleDay">
        <mat-expansion-panel-header
          ><mat-panel-title> <mat-icon>chat</mat-icon> Comments </mat-panel-title></mat-expansion-panel-header
        >
        <sh-conversation-panel
          #conversationPanel
          (commentCreated)="createComment($event)"
          (commentDeleted)="deleteComment($event)"
          (commentUpdated)="updateComment($event)"
          (commentsRead)="markCommentsAsRead($event)"
          [comments]="scheduleDay.commentList.list$ | async"
          [timestamp]="scheduleDay.date | shMoment: 'YYYY-MM-DD'"
          [scheduleId]="scheduleId"
        >
        </sh-conversation-panel>
      </mat-expansion-panel>
      <mat-expansion-panel [expanded]="true" *ngIf="scheduleDay">
        <mat-expansion-panel-header
          ><mat-panel-title> <mat-icon>school</mat-icon> Agenda </mat-panel-title></mat-expansion-panel-header
        >
        <vc-agenda-info [day]="scheduleDay?.date" [scheduleState]="scheduleDay?.scheduleState"></vc-agenda-info>
      </mat-expansion-panel>

      <mat-expansion-panel [expanded]="true" *ngIf="scheduleDay">
        <mat-expansion-panel-header
          ><mat-panel-title> <mat-icon>school</mat-icon> Vacay </mat-panel-title></mat-expansion-panel-header
        >
        <vc-vacay-info [day]="scheduleDay?.date" [state]="schedule.state"></vc-vacay-info>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>

<ng-template #loadingContent>
  <mat-spinner [diameter]="80" color="accent"></mat-spinner>
</ng-template>
