import { gql } from 'apollo-angular';

export const agendaRequestWithShiftDetails = gql`
  query agendaBaselineRequest($id: Int!) {
    agendaBaselineRequest(id: $id) {
      createdBy {
        firstName
        lastName
      }
      description
      shiftCode {
        code
        start
        duration
        workingTimeInMinutes
        skills {
          id
          name
        }
        licenses {
          id
          name
        }
        unitEndorsements {
          id
          title
        }
      }
      objective {
        project {
          title
        }
      }
    }
  }
`;

export const requiredShiftWithSkills = gql`
  query requiredShift($id: Int!) {
    requiredShift(id: $id) {
      duration
      start
      code
      workingTimeInMinutes
      isGlobal
      group {
        name
      }
      shiftCode {
        skills {
          id
          name
        }
        licenses {
          id
          name
        }
        unitEndorsements {
          id
          title
        }
        comment
      }
      data {
        qualifications {
          skills
          licenses
          endorsements
        }
      }
    }
  }
`;
