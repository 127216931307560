import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ScheduleValidation } from '@app/rostr/overview/dto/schedule-validation';
import { RostrFacade } from '@app/rostr/overview/facades/rostr.facade';
import { ValidationsFacade } from '@app/rostr/overview/facades/validations.facade';
import { getSeverityColor, getSeverityIcon } from '@shared/helpers/validations-helper';
import { Observable, Subscription } from 'rxjs';
import { first, share } from 'rxjs/operators';
import { StatisticsFacade } from '@app/rostr/overview/facades/statistics.facade';
import { ScheduleStatistic } from '@app/rostr/overview/dto/schedule-statistic';

@Component({
  selector: 'app-stats-cell-sidebar-widget',
  templateUrl: './stats-cell-sidebar-widget.component.html',
  styleUrls: ['./stats-cell-sidebar-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StatsCellSidebarWidgetComponent implements OnInit, OnDestroy {
  routeSub: Subscription;
  validations$: Observable<ScheduleValidation[]>;
  statistics$: Observable<ScheduleStatistic>;

  public getSeverityColor = getSeverityColor;
  public getSeverityIcon = getSeverityIcon;

  // In every widget, constructors should not have complex dependencies as long
  // these would be loaded hierarchically from all modules that have widgets.
  // Copy this comment to every new widget
  constructor(
    private readonly rostrFacade: RostrFacade,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly validationsFacade: ValidationsFacade,
    private readonly statisticsFacade: StatisticsFacade,
    private readonly route: ActivatedRoute
  ) {}

  public ngOnInit(): void {
    this.routeSub = this.route.params.subscribe(paramMap => {
      const week = Number(paramMap.week);
      const employeeId = Number(paramMap.employee);
      this.validations$ = this.validationsFacade.getFilteredValidationsByWeekForEmployee$(week, employeeId).pipe(share());
      this.statistics$ = this.statisticsFacade.getStatisticsByWeekForEmployee$(week, employeeId).pipe(share());
      this.changeDetector.markForCheck();
    });
  }

  public ngOnDestroy(): void {
    this.routeSub?.unsubscribe();
  }

  public toggleValidation(validation: ScheduleValidation): void {
    this.rostrFacade.schedule$.pipe(first()).subscribe(schedule => {
      this.validationsFacade.toggleValidation(validation.checksum, schedule.id).pipe(first()).subscribe();
    });
  }
}
