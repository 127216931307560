export enum AvaiJobStatus {
  PENDING = 'PENDING', // When Solver is in progress, and also when AVAI service is down (potential Horizon bug)
  RUNNING = 'RUNNING',
  FAILED = 'FAILED', // When got response from AVAI that a solution was not found
  STOPPED = 'STOPPED',
  FINISHED = 'FINISHED'
}

export const AVAI_JOB_STATUS_TO_LABEL_MAP = {
  [AvaiJobStatus.PENDING]: 'Queued',
  [AvaiJobStatus.RUNNING]: 'Solving',
  [AvaiJobStatus.FAILED]: 'Error',
  [AvaiJobStatus.STOPPED]: 'Stopped',
  [AvaiJobStatus.FINISHED]: 'Finished',
};
