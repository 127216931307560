import { Injectable } from '@angular/core';
import { ScheduleValidation } from '../dto/schedule-validation';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ScheduleValidationLevel } from '@enums';

@Injectable({
  providedIn: 'root'
})
export class ValidationsState {
  private readonly validationsSource$ = new BehaviorSubject<ScheduleValidation[]>([]);
  private readonly areValidationsLoadingSubject$ = new BehaviorSubject<boolean>(false);
  private readonly excludedValidationsSubject$ = new BehaviorSubject<string[]>([]);

  public readonly validations$ = this.validationsSource$.asObservable();
  public readonly areValidationsLoading$ = this.areValidationsLoadingSubject$.asObservable();
  public readonly excludedValidations$ = this.excludedValidationsSubject$.asObservable();

  public setValidations(validations: ScheduleValidation[]) {
    this.validationsSource$.next(validations);
    this.areValidationsLoadingSubject$.next(false);
  }

  public setAreValidationsLoading(areLoading: boolean): void {
    this.areValidationsLoadingSubject$.next(areLoading);
  }

  public setExcludedValidations(excludedValidations: string[]): void {
    this.excludedValidationsSubject$.next(excludedValidations);
  }

  getValidationsByDay(day: string) {
    return this.validations$.pipe(map(x => x.filter(validation => validation.level === ScheduleValidationLevel.DAY && validation.day === day)));
  }

  getValidationsByEmployee(employeeId: number) {
    return this.validations$.pipe(
      map(x => x.filter(validation => validation.level === ScheduleValidationLevel.EMPLOYEE && validation.employee === employeeId))
    );
  }

  getValidationsByWeekAndEmployee(week: number, employeeId: number) {
    return this.validations$.pipe(
      map(x => x.filter(validation => validation.level === ScheduleValidationLevel.WEEK && validation.week === week && validation.employee === employeeId))
    );
  }

  getValidationsByShift(parentId: string) {
    return this.validations$.pipe(
      map(x => x.filter(validation => validation.level === ScheduleValidationLevel.SHIFTS && validation.shifts.includes(parentId)))
    );
  }

  public cleanUp(): void {
    this.validationsSource$.next([]);
    this.areValidationsLoadingSubject$.next(false);
  }
}
