import { ManualShiftResult } from './manual-shift-result';

export class RequiredShiftResult {
  duration: number;
  workingTimeInMinutes: number;
  isGlobal: boolean;
  start: number;
  code: string;
  group: {
    name: string;
  };
  shiftCode: ManualShiftResult;
  data: {
    qualifications: {
      skills: string[];
      endorsements: string[];
      licenses: string[];
    };
  };

  skills: string[];
  unitEndorsements: string[];
  licenses: string[];

  constructor(data: Partial<RequiredShiftResult>) {
    this.duration = data.duration;
    this.workingTimeInMinutes = data.workingTimeInMinutes;
    this.isGlobal = data.isGlobal;
    this.start = data.start;
    this.code = data.code;
    this.group = data.group;
    this.shiftCode = data.shiftCode;
    this.data = data.data;

    this.skills = data.data?.qualifications?.skills ?? data.shiftCode?.skills.map(skill => skill.name) ?? [];
    this.unitEndorsements = data.data?.qualifications?.endorsements ?? data.shiftCode?.unitEndorsements.map(endorsement => endorsement.title) ?? [];
    this.licenses = data.data?.qualifications?.licenses ?? data.shiftCode?.licenses.map(license => license.name) ?? [];
  }
}
