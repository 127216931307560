import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatNativeDateModule, DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MatMenuModule } from '@angular/material/menu';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {
  ButtonComponent,
  MultipleDatePickerComponent,
  DatePickerComponent,
  TimePickerComponent,
  PageBrowserComponent,
  FileUploadComponent,
  CustomDateAdapter,
  CheckboxComponent,
  LoadingComponent,
  BadgeComponent
} from '.';
import { MatSelectModule } from '@angular/material/select';
import { ScheduleStateIconComponent } from './components/schedule-state-icon/schedule-state-icon.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [
    ButtonComponent,
    DatePickerComponent,
    MultipleDatePickerComponent,
    TimePickerComponent,
    PageBrowserComponent,
    FileUploadComponent,
    CheckboxComponent,
    BadgeComponent,
    LoadingComponent,
    ScheduleStateIconComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatButtonModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatIconModule,
    MatNativeDateModule,
    MatMenuModule,
    MatCheckboxModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatTooltipModule
  ],
  exports: [
    ButtonComponent,
    DatePickerComponent,
    MultipleDatePickerComponent,
    TimePickerComponent,
    PageBrowserComponent,
    FileUploadComponent,
    CheckboxComponent,
    BadgeComponent,
    LoadingComponent,
    ScheduleStateIconComponent
  ],
  providers: [
    { provide: DateAdapter, useClass: CustomDateAdapter },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }
  ]
})
export class CommonComponentsModule {}
