<div *ngIf="!loading; else loadingContent">
  <ng-container *ngIf="!errorMessage; else error">
    <!-- <p>
      Date: <strong>{{ shift?.date | slice: 0 : 10 }}</strong>
    </p> -->
    <p *ngIf="data?.origin">
      Origin: <strong>{{ data?.origin }}</strong>
    </p>
    <p>
      Time: <strong>{{ data?.start | minutesToTime }} - {{ data?.end | minutesToTime }} ({{ data?.duration | humanizeDuration }})</strong>
    </p>
    <p>
      Working Time: <strong> {{ data?.workingTimeInMinutes | humanizeDuration }} </strong>
    </p>
    <p *ngIf="shift?.type">
      Type: <strong>{{ ShiftCodeType[shift.type] }}</strong>
    </p>
    <p>
      Alias: <strong>{{ shift?.shiftAlias.alias }}</strong>
    </p>
    <p *ngIf="data?.group">
      Group: <strong>{{ data?.group }}</strong>
    </p>
    <p *ngIf="data?.skills?.length">
      Skills: <strong> {{ data?.skills.join(', ') }} </strong>
    </p>
    <p *ngIf="data?.creator">
      Creator: <strong> {{ data?.creator }} </strong>
    </p>
    <p *ngIf="data?.project">
      Project: <strong> {{ data?.project }} </strong>
    </p>
    <p *ngIf="data?.description">{{ data?.description }}</p>
  </ng-container>
</div>

<ng-template #loadingContent>
  <mat-spinner [diameter]="80" color="accent"></mat-spinner>
</ng-template>

<ng-template #error>
  <div class="error">{{ errorMessage }}</div>
</ng-template>
