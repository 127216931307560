import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { ScheduleStatistic, StatisticName } from '@app/rostr/overview/dto/schedule-statistic';
import { ChartsService } from '@shared/services/charts/charts.service';
import { Chart } from 'chart.js/auto';

@Component({
  selector: 'app-employee-charts',
  templateUrl: './employee-charts.component.html',
  styleUrls: ['./employee-charts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmployeeChartsComponent implements OnInit, OnChanges, OnDestroy {
  agendaChart: Chart;
  vacayChart: Chart;
  distributionChart: Chart;
  displayAgendaChart = true;
  displayVacayChart = true;

  @Input() statistics: ScheduleStatistic;

  constructor(
    private readonly chartsService: ChartsService,
    private readonly changeDetector: ChangeDetectorRef
  ) {}

  private plotCharts(statistics: ScheduleStatistic) {
    this.plotAgendaChart(statistics);
    this.plotVacayChart(statistics);
    this.plotDistributionChart(statistics);
  }

  public ngOnInit(): void {
    this.agendaChart = this.chartsService.createDoughnutChart(
      'agendaChartCanvas',
      'Agenda shifts',
      ['Assigned', 'Pending', 'Unassigned'],
      ['#80ff7b', '#7bbdff', '#fda5e2']
    );

    this.vacayChart = this.chartsService.createDoughnutChart(
      'vacayChartCanvas',
      'Vacay shifts',
      ['Assigned', 'Declined', 'Pending', 'Unassigned'],
      ['#80ff7b', '#fdc5a5', '#7bbdff', '#fda5e2']
    );

    this.distributionChart = this.chartsService.createDoughnutChart(
      'distributionChartCanvas',
      'Distribution',
      ['Day', 'Evening', 'Morning', 'Night', 'Off'],
      ['#ffc792', '#92beff', '#ffeb92', '#655afc', '#bb5afc']
    );

    this.plotCharts(this.statistics);
  }

  public ngOnChanges(): void {
    if (this.agendaChart && this.vacayChart && this.distributionChart) {
      this.plotCharts(this.statistics);
      this.changeDetector.detectChanges();
    }
  }

  public ngOnDestroy() {
    this.agendaChart?.destroy();
    this.vacayChart?.destroy();
    this.distributionChart?.destroy();
  }

  private plotAgendaChart(statistics: ScheduleStatistic) {
    const agendaAssigned = statistics?.values[StatisticName.AgendaAssigned] || 0;
    const agendaPending = statistics?.values[StatisticName.AgendaPending] || 0;
    const agendaUnassigned = statistics?.values[StatisticName.AgendaUnassigned] || 0;

    const data = [agendaAssigned, agendaPending, agendaUnassigned];

    if (data.every(x => x === 0)) {
      this.displayAgendaChart = false;
      return;
    } else {
      this.displayAgendaChart = true;
    }

    this.chartsService.plotDoughnutChart(this.agendaChart, data);
  }

  private plotVacayChart(statistics: ScheduleStatistic) {
    const vacayAssigned = statistics?.values[StatisticName.VacayAssigned] || 0;
    const vacayDeclined = statistics?.values[StatisticName.VacayDeclined] || 0;
    const vacayPending = statistics?.values[StatisticName.VacayPending] || 0;
    const vacayUnassigned = statistics?.values[StatisticName.VacayUnassigned] || 0;

    const data = [vacayAssigned, vacayDeclined, vacayPending, vacayUnassigned];

    if (data.every(x => x === 0)) {
      this.displayVacayChart = false;
      return;
    } else {
      this.displayVacayChart = true;
    }

    this.chartsService.plotDoughnutChart(this.vacayChart, data);
  }

  private plotDistributionChart(statistics: ScheduleStatistic) {
    const dayShifts = statistics?.values[StatisticName.Day] || 0;
    const eveningShifts = statistics?.values[StatisticName.Evening] || 0;
    const morningsShifts = statistics?.values[StatisticName.Morning] || 0;
    const nightShifts = statistics?.values[StatisticName.Night] || 0;
    const offShifts = statistics?.values[StatisticName.Off] || 0;

    const data = [dayShifts, eveningShifts, morningsShifts, nightShifts, offShifts];

    this.chartsService.plotDoughnutChart(this.distributionChart, data);
  }
}
