import { AbstractControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';

// custom validator to check if string is empty
export function nonWhitespaceValue(controlName: string) {
  return (group: UntypedFormGroup) => {
    // considering that whitespace is validated on text then we may use FormGroup<FormControl<string>>
    const control = group.controls[controlName];

    if (!/\S/.test(control.value)) {
      control.setErrors({ hasOnlyWhitespaceValues: true });
    } else {
      control.setErrors(null);
    }
  };
}

export function noWhitespaceValidator(control: AbstractControl): ValidationErrors {
  if (!/\S/.test(control.value)) {
    return { hasOnlyWhitespaceValues: true };
  } else {
    return null;
  }
}
