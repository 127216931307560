import * as d3 from 'd3';
import { IMatrix } from '../matrix.interfaces';
import { Col } from '../cols/col';
import { Row } from '../rows/row';

export class Cell {

  public cell: d3.Selection<any, any, any, any>;

  public col: Col;
  public row: Row;

  public isSelected = false;
  public isVisible = false;

  private offsetX = 0;
  private offsetY = 0;

  public constructor(private matrix: IMatrix,
    private configs: {
      colKey: any,
      rowKey: any,
      colIndex: number,
      rowIndex: number
    },
    private _options?: any) {

    this.matrix.grid.cells.push(this);
    this.render();
    this.updatePosition(this.configs.colIndex, this.configs.rowIndex);
    this.setRelations();
  }

  get colKey() {
    return this.configs.colKey;
  }

  get rowKey() {
    return this.configs.rowKey;
  }

  get rowIndex() {
    return this.configs.rowIndex;
  }

  get colIndex() {
    return this.configs.colIndex;
  }

  get translateX() {
    return this.configs.colIndex * this.matrix.cellSize + this.offsetX;
  }

  get translateY() {
    return this.configs.rowIndex * this.matrix.cellSize + this.offsetY;
  }

  public render() {
    this.cell = this.matrix.grid.container
      .append('g')
      .attr('col-key', this.configs.colKey)
      .attr('row-key', this.configs.rowKey)
      .classed('hide', !this.isVisible)
      .on('click', () => this.setSelection(!this.isSelected));

    this.cell
      .append('rect')
      .attr('width', this.matrix.cellSize)
      .attr('height', this.matrix.cellSize);
  }

  public setRelations() {
    const col = this.matrix.cols.cols.find((el: Col) => el.key === this.configs.colKey);
    if (col) {
      col.addRelatedCell(this);
      this.col = col;
    }

    const row = this.matrix.rows.rows.find((el: Row) => el.key === this.configs.rowKey);
    if (row) {
      row.addRelatedCell(this);
      this.row = row;
    }
  }

  public setSelection(status: boolean) {

    if (this.matrix.selectType === 'single') {
      this.removeOtherSelections();
    }

    this.setSelectionClass(status);
    this._options.click.emit(this);
  }

  public setSelectionClass(status: boolean) {
    this.isSelected = !!status;
    this.cell.classed('selected', this.isSelected);
  }

  public setVisible() {
    this.isVisible = this.col.isVisible && this.row.isVisible;
    this.cell.classed('hide', !this.isVisible);
  }

  public updatePosition(colIndex: number, rowIndex: number) {
    this.configs.colIndex = colIndex;
    this.configs.rowIndex = rowIndex;

    this.cell
      .attr('class', `matrix-cell cell-${this.configs.colIndex}-${this.configs.rowIndex}`)
      .classed('selected', this.isSelected)
      .attr('transform', `translate(${this.translateX}, ${this.translateY})`);

    this.cell.classed('hide', !this.isVisible);

  }

  public destroy() {
    this.matrix.grid.container.select(`.cell-${this.configs.colIndex}-${this.configs.rowIndex}`).remove();
    this.matrix.grid.cells.splice(this.matrix.grid.cells.indexOf(this), 1);
  }

  public destroyWithRelations() {
    this.row.cells.splice(this.row.cells.indexOf(this), 1);
    this.col.cells.splice(this.col.cells.indexOf(this), 1);

    this.destroy();
  }

  public moveBy(offsetXToAdd: number, offsetYToAdd: number) {
    this.offsetX += offsetXToAdd;
    this.offsetY += offsetYToAdd;

    this.cell.attr('transform', `translate(${this.translateX}, ${this.translateY})`);
  }

  /*
   * Destroy other relations if selectedType = 'single'
   * It means we have one-to-many relation
   */
  private removeOtherSelections() {
    const element = this.matrix.relationPosition === 'row' ? this.col : this.row;
    const cells = element.cells.filter((el: Cell) => el !== this);
    cells.forEach((cell: Cell) => cell.destroyWithRelations());
  }

}

