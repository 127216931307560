<div class="error-container">
  <div *ngIf="icon; else defaultIcon">
    <i class="material-icons">{{ icon }}</i>
  </div>

  <h1>
    <ng-container *ngIf="title; else defaultTitle">
      {{ title }}
    </ng-container>
  </h1>

  <div #content>
    <ng-content></ng-content>
  </div>
  <div *ngIf="!hasContent">
    <ng-container *ngTemplateOutlet="defaultMessage"></ng-container>
  </div>
</div>

<!-- Default values configured below -->

<ng-template #defaultIcon>
  <img src="/assets/images/contrails.svg" />
  <img class="plane" src="/assets/images/plane.svg" />
</ng-template>

<ng-template #defaultTitle> Oooops… something went wrong. </ng-template>

<ng-template #defaultMessage>
  <div *ngIf="!hideSorry">
    <p>Sorry for the inconvenience, we´re working on it.</p>
  </div>
  <ng-container *ngTemplateOutlet="defaultContact"></ng-container>
  <ng-container *ngTemplateOutlet="defaultButton"></ng-container>
</ng-template>

<ng-template #defaultContact>
  <p>
    If you experience this again, please contact Avinor Flysikring at <br />
    <a href="mailto:support.horizon@avinor.no">support.horizon&#64;avinor.no</a>
  </p>
</ng-template>

<ng-template #defaultButton>
  <p>
    <app-button class="btn btn-default btn-primary" (onclickEvent)="backHome()" value="Home"> </app-button>
  </p>
</ng-template>
