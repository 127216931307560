import * as moment from 'moment-mini';

import { BaseInterval } from './base.interval';
import { Timeline } from '../timeline';
import { HourInterval } from './hour.interval';
import { IBaseGrid } from '../grid/base-grid.interface';
import { IBaseMenu } from '../menu/base-menu.interface';


export class HalfHourInterval extends BaseInterval {

  constructor(protected _timeline: Timeline,
    protected _grid: IBaseGrid,
    protected _menu: IBaseMenu,
    protected _dateFrom: Date,
    protected _x: number) {
    super(_timeline, _grid, _menu, _dateFrom, _x);
  }

  static get type() {
    return HalfHourInterval.TYPES.HalfHour;
  }

  get title() {
    return moment(this._dateFrom).format('HH:mm');
  }

  getTitleForTab(dateFrom: Date, isActive?: boolean) {
    return HourInterval.hoursBasedIntervalTitleForTab(dateFrom, isActive);
  }

  get isHalfHour() {
    return this._dateFrom.getMinutes() === 30;
  }

  public renderHeader() {
    super.renderHeader();

    this.$headerTitle
      .classed('hour', !this.isHalfHour)
      .classed('hour-half', this.isHalfHour);
  }

  /********************************* Dates ************************************************/
  /**
   * Setter for dates
   * @param dateFrom
   * @returns {DayInterval}
   */
  public setDates(dateFrom = this._dateFrom) {
    this._dateFrom = HalfHourInterval.precision(dateFrom);

    this._dateTo = new Date(this._dateFrom.getTime());
    this._dateTo.setMinutes(this._dateTo.getMinutes() + 30);

    this._dateFromUnix = this._dateFrom.getTime();
    this._dateToUnix = this._dateTo.getTime();

    return this;
  }
  /**
   * Date for next interval
   * @returns {Date}
   */
  public next() {
    const date = new Date(this._dateFrom.getTime());
    date.setMinutes(date.getMinutes() + 30);
    return date;
  }

  /**
   * Date for prev interval
   * @returns {Date}
   */
  public prev() {
    const date = new Date(this._dateFrom.getTime());
    date.setMinutes(date.getMinutes() - 30);
    return date;
  }

  /**
   * Will return count of intervals what will be overlaped by shift
   * @param from {Date}
   * @param to {Date}
   * @returns {number}
   */
  public getCountOfUsedIntervals(from, to) {
    return Math.ceil(Math.round((to.getTime() - from.getTime()) / 60000) / 30);
  }

  /**
   * Round up to start of day
   * @returns {Date}
   */
  public base(offsetHalfHours = 0, offsetDays = 0) {
    let date = this._dateFrom;
    date = new Date(date.getFullYear(), date.getMonth(), date.getDate() + offsetDays);
    date.setMinutes(date.getMinutes() + offsetHalfHours * 30);
    return date;
  }

  /*************************** static **************************************************/
  /**
   * Round to half of hour
   * @param date
   * @returns {Date}
   */
  static precision(date: Date): Date {

    let hours = date.getHours();
    let minutes = date.getMinutes();

    if (minutes < 15) {
      minutes = 0;
    } else if (minutes < 45) {
      minutes = 30;
    } else {
      minutes = 0;
      hours += 1;
    }

    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), hours, minutes);
  }

  static diff(from, to) {
    return moment(from).diff(moment(to), 'minutes') / 30;
  }

}
