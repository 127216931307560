import { Component, Input, ViewChild, TemplateRef, forwardRef, ChangeDetectorRef } from '@angular/core';
import { MatCalendarCellCssClasses, MatCalendar } from '@angular/material/datepicker';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { getWeekNumberClasses } from '../datepicker-utils';
import * as moment from 'moment-mini';
import { ISO_DATE_ONLY_FORMAT } from '@helpers';

@Component({
  selector: 'app-multiple-date-picker',
  templateUrl: './multiple-date-picker.component.html',
  styleUrls: ['./multiple-date-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultipleDatePickerComponent),
      multi: true
    }
  ]
})
export class MultipleDatePickerComponent implements ControlValueAccessor {
  @Input() class: string;
  @Input() ariaLabel: string;
  @ViewChild('matCalendar') matCalendar: MatCalendar<Date>;
  @ViewChild('calendarDialog') calendarDialog: TemplateRef<any>;

  public dates: string[] = [];
  public onChangeRegisteredFunctions: ((value: string[]) => void)[] = [];
  public onTouchedRegisteredFunctions: (() => void)[] = [];
  public isDisabled = false;
  private isCalendarVisible = false;
  private readonly selectedClass = 'selected-date';
  private readonly calendarDialogConfig: MatDialogConfig = {
    width: '400px'
  };

  constructor(
    private matDialog: MatDialog,
    private readonly cd: ChangeDetectorRef
  ) {}

  writeValue(dates: string[]): void {
    this.setDates(dates);
  }

  registerOnChange(fn: (value: string[]) => void): void {
    this.onChangeRegisteredFunctions.push(fn);
  }

  registerOnTouched(fn: () => void): void {
    this.onTouchedRegisteredFunctions.push(fn);
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  public onChange(newValue: string[]) {
    this.refreshCalendar();
    this.onChangeRegisteredFunctions.forEach(f => f(newValue));
  }

  public onTouched() {
    this.onTouchedRegisteredFunctions.forEach(f => f());
  }

  public onDateSelected(date: Date) {
    const stringDate = this.dateToString(date);
    this.updateDates(stringDate);
    this.onChange(this.dates);
    this.cd.markForCheck();
  }

  public onToggle() {
    if (!this.isCalendarVisible) {
      this.openDialog();
    }
  }

  public getClassForDay() {
    return (date: Date): MatCalendarCellCssClasses => {
      const classes: string[] = [];
      const stringDate = this.dateToString(date);

      if (this.isDateChosen(stringDate)) {
        classes.push(this.selectedClass);
      }

      classes.push(...getWeekNumberClasses(date));

      return classes;
    };
  }

  public removeDate(date: string) {
    const indexToRemove = this.dates.findIndex(d => d === date);
    this.dates.splice(indexToRemove, 1);
    this.onChange(this.dates);
  }

  private updateDates(date: string) {
    if (this.isDateChosen(date)) {
      this.removeDate(date);
    } else {
      this.addDate(date);
    }
  }

  private addDate(date: string) {
    this.dates.push(date);
    this.onChange(this.dates);
  }

  private isDateChosen(date: string): boolean {
    return this.dates.some(d => d === date);
  }

  private refreshCalendar() {
    if (this.matCalendar) {
      this.matCalendar.updateTodaysDate();
    }
  }

  private openDialog() {
    this.matDialog.open(this.calendarDialog, this.calendarDialogConfig);
  }

  private setDates(dates: string[]) {
    this.dates = dates;
    this.onChange(this.dates);
  }

  private dateToString(date: Date): string {
    return moment(date).format(ISO_DATE_ONLY_FORMAT);
  }
}
