import { Input, Output, EventEmitter, Directive } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

import { ITooltipStyles } from '../../tooltip';
import { ICommentsCallbackProtocol } from '../interfaces';

import { COLOR_PICKER_VALUES } from '@constants';
import { ServerStatus } from '@enums';

export interface CommentForm {
  color: FormControl<string | null>;
  message: FormControl<string | null>;
}
@Directive()
export class CommentInputComponent {
  @Input() callback: (response: ICommentsCallbackProtocol) => any;
  @Input() showColorSelection = true;
  @Input() textColor = 'black';

  @Output() colorChange = new EventEmitter<ITooltipStyles>();
  @Output() commentRemoved = new EventEmitter();
  @Output() editingFinished = new EventEmitter();

  public commentForm: FormGroup<CommentForm>;
  public colors = COLOR_PICKER_VALUES;
  public colorsVisible = false;
  public serverStatuses = ServerStatus;
  public colorSubscription: Subscription;

  constructor(public fb: FormBuilder) { }
}
