import { gql } from 'apollo-angular';

export const companySearch = gql`
    query companySearch($term: String!) {
        companySearch(term: $term) {
            companies {
                id
                name
            }
            divisions {
                id
                name
            }
            sites {
                id
                name
            }
            units {
                id
                name
            }
            groups {
                id
                name
                unit {
                    id
                    abbreviation
                }
            }
            teams {
                id
                name
            }
            employees {
                id
                firstName
                middleName
                lastName
                username
                email
            }
        }
    }
`;

export const searchEmployee = gql`
    query searchEmployee($term: String!) {
        companySearch(term: $term) {
            employees {
                id
                firstName
                lastName
                middleName
                group {
                    id
                    name
                    unit {
                        id
                        abbreviation
                    }
                }
            }
        }
    }
`;

export const employeesSearch = (entityType: string) => gql`
    query employeesSearch($id: Int!) {
        employees(${entityType} : $id) {
            id
            firstName
            lastName
            middleName
            group {
                id
                name
                unit {
                    id
                    abbreviation
                }
            }
        }
    }
`;

export const entityIdNameByEntityType = (entityType: string) => gql`
    query entityByTypeAndId($id: Int!) {
        ${entityType}(id: $id) {
            id
            name
        }
    }
`;

export const entityByEntityType = (entityType: string) => gql`
    query entityByTypeAndId($id: Int!) {
        ${entityType}(id: $id) {
            id
            firstName
            lastName
            middleName
        }
    }
`;
