import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TooltipContainerComponent } from './tooltip-container.component';
import { TooltipContainerService } from './tooltip-container.service';
import { TooltipDynamicComponent } from './tooltip-dynamic.component';
import { TooltipSimpleComponent } from './tooltip-simple.component';
import { TooltipComponent } from './tooltip.component';
import { TooltipService } from './tooltip.service';


@NgModule({
  imports: [
    CommonModule,
  ],
  exports: [
    TooltipComponent,
    TooltipContainerComponent
  ],
  declarations: [
    TooltipContainerComponent,
    TooltipDynamicComponent,
    TooltipSimpleComponent,
    TooltipComponent,
  ],
  providers: [
    TooltipContainerService,
    TooltipService,
  ],
})
export class TooltipModule {}
