import { OverlayRef, Overlay, OverlayPositionBuilder, ConnectedPosition, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ComponentRef, Directive, ElementRef, HostListener, Input, OnDestroy, TemplateRef } from '@angular/core';
import { CustomTooltipComponent } from './custom-tooltip.component';

const defaultPosition: ConnectedPosition[] = [
  {
    originX: 'end',
    originY: 'bottom',
    overlayX: 'start',
    overlayY: 'top'
  },
  {
    originX: 'start',
    originY: 'bottom',
    overlayX: 'end',
    overlayY: 'top'
  },
  {
    originX: 'end',
    originY: 'top',
    overlayX: 'start',
    overlayY: 'bottom'
  },
  {
    originX: 'start',
    originY: 'top',
    overlayX: 'end',
    overlayY: 'bottom'
  }
];
@Directive({
  selector: '[customTooltip]',
  exportAs: 'customTooltip'
})
export class ToolTipRendererDirective implements OnDestroy {
  @Input(`customTooltip`) text: string;
  @Input() contentTemplate: TemplateRef<any>;
  @Input() tooltipTitle: string;
  @Input() showDelay: number;
  @Input() inputData: unknown;
  @Input() positions = defaultPosition;
  @Input() maxWidth: string;
  @Input() viewPortMargin = 20;
  @Input() shouldGrowOnOpen = false;

  private _overlayRef: OverlayRef;
  private timer;
  constructor(
    private readonly _overlay: Overlay,
    private readonly _overlayPositionBuilder: OverlayPositionBuilder,
    private readonly _elementRef: ElementRef
  ) {}

  @HostListener('mouseenter')
  show() {
    this.timer = setTimeout(() => this.openTooltip(), this.showDelay);
  }

  openTooltip() {
    const positionStrategy = this._overlayPositionBuilder
      .flexibleConnectedTo(this._elementRef)
      .withGrowAfterOpen(this.shouldGrowOnOpen)
      .withPositions(this.positions)
      .withViewportMargin(this.viewPortMargin);

    const config: OverlayConfig = {
      positionStrategy
    };

    this._overlayRef = this._overlay.create(config);

    if (this._overlayRef && !this._overlayRef.hasAttached()) {
      const tooltipRef: ComponentRef<CustomTooltipComponent> = this._overlayRef.attach(new ComponentPortal(CustomTooltipComponent));
      tooltipRef.instance.text = this.text;
      tooltipRef.instance.contentTemplate = this.contentTemplate;
      tooltipRef.instance.tooltipTitle = this.tooltipTitle;
      tooltipRef.instance.inputData = this.inputData;
      tooltipRef.instance.maxWidth = this.maxWidth;
    }
  }

  public updatePosition() {
    this._overlayRef.updatePosition();
  }

  @HostListener('mouseleave')
  @HostListener('blur')
  hide() {
    clearTimeout(this.timer);
    this.closeToolTip();
  }

  ngOnDestroy() {
    clearTimeout(this.timer);
    this.closeToolTip();
  }

  private closeToolTip() {
    if (this._overlayRef) {
      this._overlayRef.detach();
    }
  }
}
